import React from "react";
import styled from "styled-components";
import EmployeesList from "./EmployeesList";
import AddUserModal from "./Modals/AddUserModal";
import EditUserModal from "./Modals/EditUserModal";
import Modal from '../Shared/Modals/Modal';

const EmployeesView = ({
  doctors,
  registries,
  nurses,
  isCreateModalOn,
  setCreateModal,
  isEditModalOn,
  setEditModal,
  registerEmployee,
  employeeType,
  setEmployeeType,
  userRole,
  currentUser,
  setCurrentUser,
  getEmployeeVacations,
  employeeVacations,
  updateDoctorDetailData,
  updateRegistryDetailData,
  updateNurseDetailData,
  updateUserBasicData,
  deleteUserVacations,
  addUserVacations,
  usersVacationsCount,
  errorModal,
  resetEmployeesError,
  healthcenters,
  uploadUserCertificates,
  deleteUserCertificates,
  restoreUser,
}) => {
  return (
    <ContentWrapper>
      <EmployeesList
        title="Pracownicy"
        employees={[...doctors, ...nurses ,...registries]}
        setCreateModal={setCreateModal}
        setEditModal={setEditModal}
        setEmployeeType={setEmployeeType}
        listType='doctor'
        setCurrentUser={setCurrentUser}
        usersVacationsCount={usersVacationsCount}
      />
      <AddUserModal
        isCreateModalOn={isCreateModalOn}
        setCreateModal={setCreateModal}
        userRole={userRole}
        registerEmployee={registerEmployee}
        employeeType={employeeType}
        restoreUser={restoreUser}
      />
      <EditUserModal
        isEditModalOn={isEditModalOn}
        setEditModal={setEditModal}
        currentUser={currentUser}
        getEmployeeVacations={getEmployeeVacations}
        employeeVacations={employeeVacations}
        updateDoctorDetailData={updateDoctorDetailData}
        updateRegistryDetailData={updateRegistryDetailData}
        updateNurseDetailData={updateNurseDetailData}
        updateUserBasicData={updateUserBasicData}
        deleteUserVacations={deleteUserVacations}
        addUserVacations={addUserVacations}
        healthcenters={healthcenters}
        uploadUserCertificates={uploadUserCertificates}
        deleteUserCertificates={deleteUserCertificates}
      />
      <Modal
        type="SimpleInfoModal"
        show={errorModal.show}
        info={errorModal.info}
        acceptText="Ok"
        onAccept={resetEmployeesError}
      />
    </ContentWrapper>
  );
};

export default EmployeesView;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
