import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import StatusPill from '../../Shared/StatusPill';
import { useHistory } from 'react-router-dom';
import dragIcon from '../../Shared/assets/drag.svg';
import { getShortenString } from '../../Shared/utils';
import { ReactComponent as EditIcon } from '../../Shared/assets/edit-pen-underline.svg';
import { ReactComponent as RemoveIcon } from '../../Shared/assets/grey-bin.svg';
import { ReactComponent as ExportIcon } from '../../Shared/assets/export.svg';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDragLayer } from 'react-dnd';

export const CustomDragLayer = () => {
    const {
        item,
        initialOffset,
        currentOffset,
    } = useDragLayer((monitor) => ({
        item: monitor.getItem() ?? {},
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    const getItemStyles = (initialOffset, currentOffset) => {
        if (!initialOffset || !currentOffset) {
            return {
                display: 'none',
            }
        }
        let { x, y } = currentOffset
        const transform = `translate(${x}px, ${y}px)`;
        return {
            transform,
            WebkitTransform: transform,
            width: '440px',
            display: 'grid',
            gridTemplateColumns: '1fr .5fr 1.5fr 1fr 1fr',
            alignItems: 'center',
            padding: '10px 0 10px 25px',
            margin: '5px',
            borderRadius: '10px',
            border: '2px solid #f0f0f0',
            background: `url(${dragIcon}) #fff center left 3px no-repeat`,
        }
    }
    return (
        <div
            style={{
                position: 'fixed',
                pointerEvents: 'none',
                zIndex: 100,
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
            }}
        >
            {item?.type && <div style={getItemStyles(initialOffset, currentOffset)}>
                <Name>{item.name}</Name>
                <ItemValue>{item.stage.toLowerCase()}</ItemValue>
                <Sets>
                    {item.sets.map(() => (
                        <SetItem key={item._id}>{getShortenString(item.name, 15)}</SetItem>
                    ))}
                </Sets>
                <Status>
                    <StyledStatusPill type={item.active ? 'positive' : 'negative'}>{item.active ? 'Aktywny' : 'Zablokowany'}</StyledStatusPill>
                </Status>
                <Actions>
                    <StyledExportIcon />
                    <StyledRemoveIcon />
                    <StyledEditIcon />
                </Actions>
            </div>}
        </div>
    )
}

const ElementItem = ({
    data: { _id, name, stage, active },
    sets,
    setWarningModal,
    deleteVisitElement,
    exportVisitElement,
    elIsDragging,
    setElIsDragging,
}) => {
    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: 'element', _id, stage, name, active, sets },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });
    const history = useHistory();
    useEffect(() => {
        if (isDragging && elIsDragging === null) {
            setElIsDragging('element')
        } else if (!isDragging && elIsDragging === 'element') {
            setElIsDragging(null)
        }
    }, [isDragging])
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, []);
    return (
        <Wrapper key={_id} ref={drag}>
            <Name>{name}</Name>
            <ItemValue>{stage.toLowerCase()}</ItemValue>
            <Sets>
                {sets.map(({ _id, name }) => (
                    <SetItem key={_id}>{name.length > 15 ? `${name.substring(0, 6)}...${name.substring(name.length - 5, name.length)}` : name}</SetItem>
                ))}
            </Sets>
            <Status>
                <StyledStatusPill type={active ? 'positive' : 'negative'}>{active ? 'Aktywny' : 'Zablokowany'}</StyledStatusPill>
            </Status>
            <Actions>
                <StyledExportIcon
                    title='Eksportuj element'
                    onClick={() => exportVisitElement(_id)}
                />
                <StyledRemoveIcon
                    title='Usuń element'
                    onClick={() => setWarningModal({
                        shown: true,
                        question: 'Czy na pewno chcesz usunąć ten element?',
                        onAccept: () => deleteVisitElement(_id),
                    })}
                />
                <StyledEditIcon
                    title='Edytuj element'
                    onClick={() => history.push(`/elements/${_id}`)}
                />
            </Actions>
        </Wrapper>
    )
}

export default ElementItem;

const Wrapper = styled.li`
    display:grid;
    grid-template-columns: 1fr .5fr 1fr 1fr;
    align-items:center;
    padding:10px 0 10px 25px;
    margin: 5px;
    border-radius:10px;
    border: 2px solid ${({ theme }) => theme.grey};
    background: url(${dragIcon}) center left 3px no-repeat;
    ${({ theme }) => `${theme.mq.desktop}{
        padding:10px 0 10px 35px;
        grid-template-columns: 1fr .5fr 1.5fr 1fr 1fr;
    }`}
`;

const Actions = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
`;

const Name = styled.span`
    font-weight:600;
    color:${({ theme }) => theme.black};
    text-align:left;
    font-size:14px;
`;

const ItemValue = styled.span`
    text-align:center;
    font-size:12px;
    text-transform:capitalize;
`;

const Sets = styled.div`
    justify-content:center;
    flex-wrap:wrap;
    display:none;
    ${({ theme }) => `${theme.mq.desktop}{
        font-size:12px;
        display:flex;
    }`}
`;

const SetItem = styled.span`
    color:#fff;
    background-color:${({ theme }) => theme.primary};
    font-size:10px;
    font-weight:600;
    padding:3px 5px;
    margin:1px;
    border-radius:5px;
    white-space: nowrap;
    ${({ theme }) => `${theme.mq.desktop}{
        font-size:12px;
    }`}
`;

const Status = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
`;

const StyledStatusPill = styled(StatusPill)`
    font-size:10px;
    ${({ theme }) => `${theme.mq.desktop}{
        font-size:12px;
    }`}
`;

const iconStyles = css`
    transition:300ms;
    margin:0;
    cursor:pointer;
    path{
        fill:#ccc;
        transition:300ms;
    }
    ${({ theme }) => `${theme.mq.desktop}{
        margin:0 2.5px;
    }`}
`;

const StyledEditIcon = styled(EditIcon)`
    ${iconStyles}
    &:hover path{
        fill:${({ theme }) => theme.lightBlack}
    }
`;
const StyledRemoveIcon = styled(RemoveIcon)`
    ${iconStyles}
    &:hover path{
        fill:${({ theme }) => theme.danger}
    }
`;
const StyledExportIcon = styled(ExportIcon)`
    ${iconStyles}
    &:hover path{
        fill:${({ theme }) => theme.primary}
    }
`;