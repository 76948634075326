export const medPriceOptions = [
    {
        label: "100%",
        value: "100%"
    },
    {
        label: "50%",
        value: "50%"
    },
    {
        label: "30%",
        value: "30%"
    },
    {
        label: "R",
        value: "R"
    },
    {
        label: "B",
        value: "B"
    }
];

export const additionalPowersOptions = [{
    label: 'brak',
    value: 'brak'
},
{
    label: 'X',
    value: 'X'
},
{
    label: 'IB',
    value: 'IB'
},
{
    label: 'IW',
    value: 'IW'
},
{
    label: 'ZK',
    value: 'ZK'
},
{
    label: 'AZ',
    value: 'AZ'
},
{
    label: 'WP',
    value: 'WP'
},
{
    label: 'PO',
    value: 'PO'
},
{
    label: 'CN',
    value: 'CN'
},
{
    label: 'DN',
    value: 'DN'
},
{
    label: 'IN',
    value: 'IN'
}];

export const documentsOptions = [
    { value: 'recepta', label: 'Recepta' }, 
    { value: 'receptaRpw', label: 'Recepta Rpw' },
    { value: 'podstawoweSkierowanie', label: 'Podstawowe skierowanie' }
];


export const mediaOptions = [
    { value: 'thisDevice', label: 'z tego urzadzenia' }, 
    { value: 'externalDevice', label: 'z zewnętrznego urządzenia' }
];