import React, { useState, useEffect } from 'react';
import Modal from '../../Shared/Modals/Modal';
import Select from '../../Shared/Select';
import Input from '../../Shared/Input';
import Button from '../../Shared/Button';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AvatarPlaceholder from "../../Shared/assets/avatar.svg";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nazwa grupy jest wymagana'),
});

const GroupsModal = ({
    groupsModalState: { shown, target },
    setGroupsModalState,
    updateHealthcenter,
    groups,
    selectedHealthcenterId,
    employeesOfSelectedHealthcenter
}) => {

    const targetGroup = target ? groups.find(({ _id }) => _id === target) : {};
    return (
        <Modal
            title={`${target ? 'Edycja' : 'Tworzenie'} grupy`}
            exitButton={true}
            onCancel={() => setGroupsModalState({ shown: false, target: null })}
            show={shown}
        >
            <Formik
                initialValues={{
                    name: target ? targetGroup.name : '',
                    doctors: target ? targetGroup.doctors : [],
                    nurses: target ? targetGroup.nurses : [],
                }}
                validationSchema={validationSchema}
                onSubmit={async ({ name, nurses, doctors }) => {
                    if (!target) {
                        await updateHealthcenter(selectedHealthcenterId, { groups: [...groups, { name, nurses, doctors }] });
                        setGroupsModalState({ shown: false, target: null });
                    } else {
                        const
                            targetIndex = groups.findIndex(({ _id }) => _id === target),
                            updatedGroups = [...groups];

                        updatedGroups[targetIndex] = {
                            name, nurses, doctors
                        };

                        updateHealthcenter(selectedHealthcenterId, { groups: updatedGroups });
                        setGroupsModalState({ shown: false, target: null });
                    }
                }}
            >
                {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            name="name"
                            label="Nazwa"
                            onChange={handleChange}
                            value={values.name}
                            error={errors.name}
                        />
                        <Select
                            value=''
                            placeholder='Dodaj pracownika z listy...'
                            noOptionsMessage={() => <p>Nie znaleziono pracownika</p>}
                            options={
                                employeesOfSelectedHealthcenter
                                    .filter(({ _id }) =>
                                        !values.nurses.includes(_id) && !values.doctors.includes(_id)
                                    )
                                    .map(
                                        ({ _id, userId: { name, surname, activeRole, avatar } }) => ({
                                            value: _id,
                                            activeRole,
                                            avatar,
                                            label: `${name} ${surname}`,
                                        })
                                    )
                            }
                            onChange={({ value, activeRole }) => {
                                if (activeRole === 'Doctor') {
                                    setFieldValue('doctors', [...values.doctors, value])
                                } else if (activeRole === 'Nurse') {
                                    setFieldValue('nurses', [...values.nurses, value])
                                }
                            }}
                        />
                        <List>
                            {[...values.doctors, ...values.nurses].map((employeeId) => {
                                const { userId: { avatar, name, surname, activeRole } = {} } = employeesOfSelectedHealthcenter.find(({ _id }) => _id === employeeId) ?? {};
                                return (
                                    <ListItem key={employeeId}>
                                        <Avatar src={!avatar ? AvatarPlaceholder : `${process.env.REACT_APP_SRV_URL}${avatar}`} />
                                        <Text>{`${name} ${surname}`}</Text>
                                        <Remove onClick={() => {
                                            if (activeRole === 'Doctor') {
                                                setFieldValue('doctors', values.doctors.filter((id) => id !== employeeId))
                                            } else if (activeRole === 'Nurse') {
                                                setFieldValue('nurses', values.nurses.filter((id) => id !== employeeId))
                                            }
                                        }} />
                                    </ListItem>
                                )
                            })}
                        </List>
                        <CenterBox>
                            <Button type='submit' variant='primary'>Zapisz zmiany</Button>
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal >
    )
};

export default GroupsModal;

const Form = styled.form`
    padding-top:20px;
    width:240px;
    max-height:600px;
`;
const CenterBox = styled.div`
    margin-top:40px;
    text-align:center;
`;

const List = styled.ul``;

const ListItem = styled.li`
    display: flex;
    align-items:center;
    max-width:240px;
    position:relative;
    border: 1px solid ${({ theme }) => theme.grey};
    padding: 5px 10px;
    border-radius:4px;
    margin: 5px auto;
`;

const Avatar = styled.img`
    width:30px;
    height:30px;
    margin-right:5px;
    border-radius: 50%;
`;

const Text = styled.p``;

const Remove = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 20px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;