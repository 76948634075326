import React, { useState, useEffect } from "react";
import EmployeesView from "./EmployeesView";
import { connect } from "react-redux";
import {
  registerEmployee,
  getEmployees,
  getEmployeeVacations,
  updateDoctorDetailData,
  updateNurseDetailData,
  updateRegistryDetailData,
  updateUserBasicData,
  deleteUserVacations,
  addUserVacations,
  getUserVacationsUsedDays,
  resetEmployeesError,
  uploadUserCertificates,
  deleteUserCertificates,
  restoreUser
} from "../../redux/modules/employees";
import { getHealthcenters } from '../../redux/modules/healthcenter';

const EmployeesContainer = ({
  registries,
  doctors,
  nurses,
  registerEmployee,
  error,
  userRole,
  getEmployees,
  getEmployeeVacations,
  employeeVacations,
  updateDoctorDetailData,
  updateRegistryDetailData,
  updateNurseDetailData,
  updateUserBasicData,
  deleteUserVacations,
  addUserVacations,
  getUserVacationsUsedDays,
  usersVacationsCount,
  resetEmployeesError,
  healthcenters,
  getHealthcenters,
  uploadUserCertificates,
  deleteUserCertificates,
  restoreUser
}) => {
  const [isCreateModalOn, setCreateModal] = useState(false);
  const [isEditModalOn, setEditModal] = useState(false);
  const [employeeType, setEmployeeType] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [errorModal, setErrorModal] = useState({ show: false, info: '' });

  useEffect(() => {
    if (userRole !== null) {
      getEmployees('doctor', userRole.companyId);
      getEmployees('registry', userRole.companyId);
      getEmployees('nurse', userRole.companyId);
      getHealthcenters(userRole.companyId);
    }
  }, [userRole])

  useEffect(() => {
    if ((registries.length + doctors.length + nurses.length) > 0) {
      getUserVacationsUsedDays([...registries, ...doctors, ...nurses]);
    }
  }, [registries, doctors, nurses])

  useEffect(() => {
    //Error handler
    if (error === 'Doctor has scheduled visits in this time range.') {
      setErrorModal({ show: true, info: "Nie można dodać urlopu, lekarz ma zaplanowane wizyty w tym czasie." })
    } else if (!error) {
      setErrorModal({ show: false, info: "" })
    }
  }, [error])

  return <EmployeesView
    userRole={userRole}
    error={error}
    employeeType={employeeType}
    setEmployeeType={setEmployeeType}
    registerEmployee={registerEmployee}
    registries={registries}
    doctors={doctors}
    nurses={nurses}
    isCreateModalOn={isCreateModalOn}
    setCreateModal={setCreateModal}
    isEditModalOn={isEditModalOn}
    setEditModal={setEditModal}
    currentUser={currentUser}
    setCurrentUser={setCurrentUser}
    getEmployeeVacations={getEmployeeVacations}
    employeeVacations={employeeVacations}
    updateDoctorDetailData={updateDoctorDetailData}
    updateRegistryDetailData={updateRegistryDetailData}
    updateNurseDetailData={updateNurseDetailData}
    updateUserBasicData={updateUserBasicData}
    deleteUserVacations={deleteUserVacations}
    addUserVacations={addUserVacations}
    usersVacationsCount={usersVacationsCount}
    errorModal={errorModal}
    resetEmployeesError={resetEmployeesError}
    healthcenters={healthcenters}
    uploadUserCertificates={uploadUserCertificates}
    deleteUserCertificates={deleteUserCertificates}
    restoreUser={restoreUser}
  />;
};

const mapStateToProps = state => ({
  doctors: state.employees.doctors,
  registries: state.employees.registries,
  nurses: state.employees.nurses,
  error: state.employees.error,
  userRole: state.authentication.userRole,
  employeeVacations: state.employees.employeeVacations,
  usersVacationsCount: state.employees.usersVacationsCount,
  healthcenters: state.healthcenter.data,
});


const mapDispatchToProps = dispatch => {
  return {
    registerEmployee: (employee, employeeType) => dispatch(registerEmployee(employee, employeeType)),
    getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
    getEmployeeVacations: (vacationsIds) => dispatch(getEmployeeVacations(vacationsIds)),
    updateDoctorDetailData: (data, id) => dispatch(updateDoctorDetailData(data, id)),
    updateRegistryDetailData: (data, id) => dispatch(updateRegistryDetailData(data, id)),
    updateNurseDetailData: (data, id) => dispatch(updateNurseDetailData(data, id)),
    updateUserBasicData: (data, id) => dispatch(updateUserBasicData(data, id)),
    deleteUserVacations: (vacations) => dispatch(deleteUserVacations(vacations)),
    addUserVacations: (vacations, userId) => dispatch(addUserVacations(vacations, userId)),
    getUserVacationsUsedDays: (users) => dispatch(getUserVacationsUsedDays(users)),
    resetEmployeesError: () => dispatch(resetEmployeesError()),
    getHealthcenters: (companyId) => dispatch(getHealthcenters(companyId)),
    uploadUserCertificates: (doctorId, certificates) => dispatch(uploadUserCertificates(doctorId, certificates)),
    deleteUserCertificates: (doctorId, certificates) => dispatch(deleteUserCertificates(doctorId, certificates)),
    restoreUser: (user) => dispatch(restoreUser(user)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesContainer);