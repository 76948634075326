import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../Shared/Modals/Modal';
import Button from '../../Shared/Button';
// import Input from '../../Shared/Input';

const NameTakenModal = ({
    modalState: { shown, target, onBoth, onReplace, onFinish },
    setModalState,
}) => {
    const [duplicateIndex, setDuplicateIndex] = useState(null),
        [duplicates, setDuplicates] = useState([]),
        [forAll, setForAll] = useState(null),
        [elName, setElName] = useState('');

    useEffect(() => {
        if (Array.isArray(target)) {
            setDuplicates(target);
            setDuplicateIndex(0);
        }
    }, [target]);
    useEffect(() => {
        if (duplicateIndex !== null) {
            if (forAll !== null) {
                return updateAllDuplicates();
            }
            if (duplicates[duplicateIndex]) {
                setElName(duplicates[duplicateIndex]?.name);
            } else {
                onFinish(duplicates);
                setDuplicateIndex(null);
                setDuplicates([]);
            }
        }
    }, [duplicateIndex]);

    const updateDuplicates = (overwrite) => {
        const newDuplicates = duplicates;
        if (overwrite) {
            newDuplicates[duplicateIndex] = { ...duplicates[duplicateIndex], overwrite: true };
            setDuplicates(newDuplicates);
        }
        setDuplicateIndex(prev => prev + 1);
    };
    const updateAllDuplicates = () => {
        let newDuplicates = duplicates;
        if (forAll) {
            newDuplicates = newDuplicates.map(el => ({ ...el, overwrite: true }));
        }
        onFinish(newDuplicates);
        setDuplicateIndex(null);
        setForAll(null);
        setDuplicates([]);
    };
    return (
        <Modal
            show={shown}
            onCancel={() => setModalState({ shown: false, target: null, onBoth: null, onReplace: null })}
        >
            <Form>
                <Info>Element o nazwie <span>{elName}</span> już istnieje,<br /> co&nbsp;chcesz zrobić? </Info>
                {duplicateIndex !== null && <StyledInput
                    variant='primary'
                    type='checkbox'
                    value={forAll ?? false}
                    onChange={() => setForAll(prev => !prev)}
                    checkboxText="Zastosuj dla wszystkich"
                />}
                <CenterBox>
                    <Button
                        onClick={onBoth ? onBoth : () => updateDuplicates(false)}
                        variant='primary'
                    >
                        Zachowaj oba
                    </Button>
                    <Button
                        onClick={onReplace ? onReplace : () => updateDuplicates(true)}
                        variant='primary'
                    >
                        Zastąp element
                    </Button>
                    <Button
                        onClick={() => setModalState({ shown: false, target: null, onBoth: null, onReplace: null })}
                        variant='danger'
                    >
                        Anuluj
                    </Button>
                </CenterBox>
            </Form>
        </Modal>
    )
}

export default NameTakenModal;

const Form = styled.div`
    width:400px;
`;

const CenterBox = styled.div`
    text-align:center;
`;

const Info = styled.h2`
    font-size: 18px;
    margin: 0;
    width: 100%;
    margin-bottom:30px;
    text-align:center;
    >span{
        font-size:inherit;
        font-weight:inherit;
        color:${({ theme }) => theme.primary};
    }
`;

const StyledInput = styled.div`
    width: 200px;
    margin-left: auto;
`;