import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import Input from '../../Shared/Input';
import { patternControlTypes } from '../../Shared/enums';
import { useDragLayer } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import rejectIcon from '../../Shared/assets/rejected-icon.svg';
import dragIcon from '../../Shared/assets/drag.svg';
import listIcon from '../../Shared/assets/list.svg';
import radioIcon from '../../Shared/assets/radio.svg';
import checkboxIcon from '../../Shared/assets/checkbox.svg';
import numericIcon from '../../Shared/assets/numeric.svg';
import calendarIcon from '../../Shared/assets/calendar.svg';
import timeIcon from '../../Shared/assets/time.svg';
import { ReactComponent as InfoIcon } from '../../Shared/assets/info-circle.svg';

const ListItem = ({ type, _id, title, subtitle, icon, setElIsDragging, removeHandler }) => {
    const [{ isDragging }, drag, preview] = useDrag({
        item: _id ? { type, _id, title, subtitle, icon } : { type, title, subtitle, icon },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });
    useEffect(() => {
        if (isDragging) {
            setElIsDragging({ drag: isDragging, el: 'item' });
        } else {
            setElIsDragging({ drag: isDragging, el: null });
        }
    }, [isDragging]);
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, []);
    return (
        <Item ref={drag} dragging={isDragging}>
            {icon && <ItemImg
                bgImg={icon}
                correctSize={type === 'timepicker' || type === 'datepicker'}
            />}
            <div>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </div>
            {removeHandler && <RoundDeleteBtn
                type='button'
                title="Usuń pole"
                onClick={removeHandler}
            />}
        </Item>
    )
}

const CustomDragLayer = () => {
    const {
        item,
        initialOffset,
        currentOffset,
    } = useDragLayer((monitor) => ({
        item: monitor.getItem() ?? {},
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    const getItemStyles = (initialOffset, currentOffset) => {
        if (!initialOffset || !currentOffset) {
            return {
                display: 'none',
            }
        }
        let { x, y } = currentOffset
        const transform = `translate(${x}px, ${y}px)`;
        return {
            transform,
            WebkitTransform: transform,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            cursor: 'grab',
            margin: '5px 0',
            opacity: '.7',
            padding: '5px',
            border: '2px solid transparent',
            borderRadius: '5px',
            width: 260,
            background: '#fff',
            '&::before': {
                content: `url(${dragIcon})`,
            },
            '>div': {
                paddingLeft: '15px',
            }
        }
    }
    return (
        <div
            style={{
                position: 'fixed',
                pointerEvents: 'none',
                zIndex: 100,
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
            }}
        >
            {item?.type && <div style={getItemStyles(initialOffset, currentOffset)}>
                {item.icon && <ItemImg
                    bgImg={item.icon}
                    correctSize={item.type === 'timepicker' || item.type === 'datepicker'}
                />}
                <div style={{ paddingLeft: '15px' }}>
                    <Title>{item.title}</Title>
                    <Subtitle>{item.subtitle}</Subtitle>
                </div>
            </div>}
        </div>
    )
}

const PatternSidePanel = ({
    currentElement,
    history,
    dragItemTypes,
    setElIsDragging,
    updateElementHandler,
    didChanges,
    setWarningModal,
    changeValuesHandler,
    doctorsFormControls,
    deleteDoctorsFormControls,
    sidebarActive,
}) => {
    return (
        <Wrapper active={sidebarActive} id='elementsSidePanel'>
            <CurrentBox>
                <DismissBtn
                    title='Wyjdź'
                    onClick={() => {
                        if (didChanges) {
                            setWarningModal({
                                shown: true,
                                question: 'Czy chcesz zapisać zmiany przed wyjściem?',
                                onAccept: () => {
                                    updateElementHandler();
                                    history.push('/elements');
                                },
                                onCancel: () => history.push('/elements')
                            })
                        } else {
                            history.push('/elements')
                        }
                    }}
                />
                <div>
                    <PatternName>{currentElement?.name}</PatternName>
                    <div className="check-wrapper">
                        <Input
                            type='checkbox'
                            checkboxText='Element aktywny'
                            value={currentElement?.active ?? false}
                            variant='primary'
                            hideError={true}
                            onChange={() => changeValuesHandler({ active: !currentElement.active }, 'element')}
                        />
                    </div>
                </div>
            </CurrentBox>
            <Items>
                <ItemsList>
                    {patternControlTypes.map(({ type, title, subtitle }, index) => {
                        const icon = type === 'select' ? listIcon :
                            type === 'radio' ? radioIcon :
                                type === 'checkbox' ? checkboxIcon :
                                    type === 'input' ? numericIcon :
                                        type === 'datepicker' ? calendarIcon :
                                            type === 'timepicker' ? timeIcon : ``;
                        return (
                            <ListItem
                                key={`ListItem_${type}_${index}`}
                                type={dragItemTypes[type]}
                                icon={icon}
                                title={title}
                                subtitle={subtitle}
                                setElIsDragging={setElIsDragging}
                            />
                        )
                    })}

                </ItemsList>
                {doctorsFormControls.length > 0 && <MyItemsList>
                    <Label>Twoje zapisane pola</Label>
                    {doctorsFormControls.map(({ name, formType, _id }) => (
                        <ListItem
                            key={_id}
                            _id={_id}
                            type={dragItemTypes[formType]}
                            title={name}
                            setElIsDragging={setElIsDragging}
                            removeHandler={() => deleteDoctorsFormControls(_id)}
                        />
                    ))}
                </MyItemsList>}
            </Items>
            <CustomDragLayer />
        </Wrapper >
    )
}

export default PatternSidePanel;

const Wrapper = styled.div`
    width:300px;
    border-right: 2px solid ${({ theme }) => theme.grey};
    background-color:#fff;
    padding:20px 10px;
    position:relative;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    height:100%;
    position:fixed;
    left:-300px;
    top:60px;
    z-index:10;
    transition:300ms;
    ${({ active }) => active && `
      left:0;
    `}
    ${({ theme }) => `${theme.mq.desktop}{
        position:relative;
        top:0;
        left:0;
        min-width:250px;
        width:250px;
      }`}
    ${({ theme }) => `${theme.mq.large}{
        min-width:300px;
        width:300px;
    }`}
`;

const CurrentBox = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    transition:300ms;
    /* &:hover{
        border-color:${({ theme }) => theme.primary};
    } */
    >div{
        padding-left:25px;
        width:70%;
        .check-wrapper{
            padding-top:5px;
            label{
                color:${({ theme }) => theme.lightBlack};
            }
        }
    }
`;

const Form = styled.form`
    border: 1px solid #DEDEDE;
    border-radius:2px;
    padding:13px;
`;

const PatternName = styled.p`
    color:${({ theme }) => theme.lightBlack};
    font-weight:700;
    line-height:1;
`;

const Title = styled.p`
    color:${({ theme }) => theme.lightBlack};
    font-weight: 600;
`;

const Subtitle = styled.span`
    font-size:12px;
    color:${({ theme }) => theme.lightBlack2};
`;

const DismissBtn = styled.button`
    height:26px;
    width:26px;
    position:relative;
    background-color:${({ theme }) => theme.danger};
    background-image: url(${rejectIcon});
    background-position:center;
    background-size:14px;
    background-repeat:no-repeat;
    border:none;
    border-radius:5px;
    margin-bottom:10px;
    cursor:pointer;
    &::after{
        content:'Wyjdź';
        color:${({ theme }) => theme.danger};
        font-size:10px;
        position:absolute;
        left:-1px;
        right:0;
        bottom:-12px;
        margin:auto;
    }
`;

const Items = styled.div`
    overflow-x:hidden;
    overflow-y:auto;
    max-height: calc(100vh - (60px + 40px + 100px));
    padding: 10px 10px 0;
`;

const ItemsList = styled.ul`
    margin:0;
`;

const Item = styled.li`
    position:relative;
    display:flex;
    align-items:center;
    cursor: grab;
    margin:5px 0;
    padding:5px;
    border: 2px solid transparent;
    border-radius:5px;
    transition:300ms;
    &::before{
        content: url(${dragIcon});
    }
    >div{
        padding-left:15px;
    }
    &:hover{
        border-color:${({ theme }) => theme.primary};
        transform:translate(2px, -2px);
    }
    ${({ dragging }) => dragging && `
        opacity:.5;
    `}
    ${({ transform }) => transform && `${transform}; transition:0;`}
`;

const ItemImg = styled.div`
    width:40px;
    height:40px;
    border-radius:5px;
    background-color:${({ theme }) => theme.grey};
    background-image:url(${({ bgImg }) => bgImg});
    background-position:center;
    background-repeat:no-repeat;
    margin-left:15px;
    ${({ correctSize }) => correctSize && 'background-size:17.5px;'};
`;

const InputRow = styled.div``;

const Info = styled.p`
    font-size:14px;
    font-weight:600;
    display:flex;
    align-items:center;
    margin-bottom:10px;
    color:${({ theme }) => theme.primary};
`

const CenterBox = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    position:absolute;
    left:0;
    right:0;
    bottom:20px;
`;

const BlueInfoIcon = styled(InfoIcon)`
    margin-right:3px;
    margin-bottom:1px;
    path{
        fill:${({ theme }) => theme.primary}
    }
`;

const PatternNameInput = styled(Input)`
    padding:2px 5px;
    margin:0;
    width:150px;
    min-height: 0;
    font-size: 15px;
    font-weight: 600;
    border-radius:0;
    border:none;
    border-bottom:1px solid #ccc;
    &:focus{
        padding:2px 5px;
        border-bottom:1px solid ${({ theme }) => theme.primary};
    }
`;

const MyItemsList = styled.ul``;

const Label = styled.p`
    margin-left:5px;
    font-size:12px;
    font-weight:600;
`;

const RoundDeleteBtn = styled.button`
    height:18px;
    width:18px;
    background-color:#d9d9d9;
    background-image: url(${rejectIcon});
    background-position:center;
    background-size:8px;
    background-repeat:no-repeat;
    border:none;
    border-radius:50%;
    cursor:pointer;
    position:absolute;
    right:6px;
    transition:300ms;
    &:hover{
        background-color:${({ theme }) => theme.danger};
    }
`;