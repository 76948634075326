import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import dashboardIcon from "../../Shared/assets/dashboard.svg";
import employeesIcon from "../../Shared/assets/employees.svg";
import paymentsIcon from "../../Shared/assets/payments.svg";
import healthcentersIcon from "../../Shared/assets/healthcenters.svg";
import calendarIcon from "../../Shared/assets/calendar-grey.svg";
import dollarIcon from "../../Shared/assets/dollar.svg";

const AdminSidebarView = ({ activeTab }) => {
  return (
    <div>
      <SidebarNavigation>
        <StyledLink to="/" isActive={activeTab === 0 && true}>
          <LinkIcon src={dashboardIcon} isActive={activeTab === 0 && true} />
          <Text>Panel</Text>
        </StyledLink>
        <SectionHeader>Dane</SectionHeader>
        <StyledLink
          to="/employees"
          isActive={activeTab === 1 && true}
        >
          <LinkIcon src={employeesIcon} isActive={activeTab === 1 && true} />
          <Text>Pracownicy</Text>
        </StyledLink>
        <StyledLink
          to="/vacations"
          isActive={activeTab === 2 && true}
        >
          <LinkIcon src={calendarIcon} isActive={activeTab === 2 && true} />
          <Text>Urlopy</Text>
        </StyledLink>
        <StyledLink to="/company" isActive={activeTab === 3 && true}>
          <LinkIcon src={paymentsIcon} isActive={activeTab === 3 && true} />
          <Text>Firma</Text>
        </StyledLink>
        <StyledLink to="/healthcenters" isActive={activeTab === 4 && true}>
          <LinkIcon
            src={healthcentersIcon}
            isActive={activeTab === 4 && true}
          />
          <Text>Placówki</Text>
        </StyledLink>
        <SectionHeader>Statystyki</SectionHeader>
        <StyledLink
          to="/income"
          isActive={activeTab === 5 && true}
        >
          <LinkIcon src={dollarIcon} isActive={activeTab === 5 && true} />
          <Text>Przychody</Text>
        </StyledLink>
        <StyledLink
          to="/visits"
          isActive={activeTab === 6 && true}
        >
          <LinkIcon src={employeesIcon} isActive={activeTab === 6 && true} />
          <Text>Wizyty</Text>
        </StyledLink>
      </SidebarNavigation>
    </div>
  );
};

export default AdminSidebarView;

const Text = styled.p`
  margin: 0 auto;
`;

const SidebarNavigation = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkIcon = styled.img`
  width: 30px;
  height: 30px;
  ${({ isActive }) =>
    isActive &&
    `
    filter: invert(40%) sepia(73%) saturate(1173%) hue-rotate(185deg) brightness(101%) contrast(105%);
`}
`;

const StyledLink = styled(({ isActive, ...props }) => <Link {...props} />)`
  text-decoration: none;
  color: ${({ theme }) => theme.black};
  margin: 20px 30px;
  font-family: ${({ theme }) => theme.font.family.openSans};
  transition: 0.25s;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  ${({ isActive, theme }) =>
    isActive &&
    `
font-weight: bold;
:before {
  content: '';
  width: 2px;
  height: 35px;
  background-color: ${theme.primary};
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
`}
`;

const SectionHeader = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 20px;
  color: ${({ theme }) => theme.darkGrey};
`;
