import axios from 'axios';

const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";
const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";

const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";
const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
const SET_INITIAL_COMPANY = "SET_INITIAL_COMPANY";

const GET_COMPANY_USERS_REQUEST = "GET_COMPANY_USERS_REQUEST";
const GET_COMPANY_USERS_FAIL = "GET_COMPANY_USERS_FAIL";
const GET_COMPANY_USERS_SUCCESS = "GET_COMPANY_USERS_SUCCESS";

const UPDATE_COMPANY_LOGO_REQUEST = "UPDATE_COMPANY_LOGO_REQUEST";
const UPDATE_COMPANY_LOGO_SUCCESS = "UPDATE_COMPANY_LOGO_SUCCESS";
const UPDATE_COMPANY_LOGO_FAIL = "UPDATE_COMPANY_LOGO_FAIL";

export const setInitialCompany = () => ({
  type: SET_INITIAL_COMPANY
})

export const getCompany = (id) => async dispatch => {
  dispatch(getCompanyRequest())
  try {
    const response = await axios.get(`/company/${id}`);
    dispatch(getCompanySuccess(response.data))
  } catch (error) {
    dispatch(getCompanyFail(error))
  }
}

const getCompanySuccess = company => ({
  type: GET_COMPANY_SUCCESS,
  payload: company
})

const getCompanyRequest = () => ({
  type: GET_COMPANY_REQUEST
})

const getCompanyFail = (error) => ({
  type: GET_COMPANY_FAIL,
  payload: error
})

export const updateCompany = (id, body) => async dispatch => {
  dispatch(updateCompanyRequest());
  try {
    const response = await axios.patch(`/company/${id}`,
      body)
    dispatch(updateCompanySuccess(response.data));
  } catch (error) {
    dispatch(updateCompanyFail(error))
  }
}

const updateCompanyRequest = () => ({
  type: UPDATE_COMPANY_REQUEST
})

const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company
})

const updateCompanyFail = (error) => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error
})

export const getCompanyUsers = companyId => async dispatch => {
  dispatch(getCompanyUsersRequest());
  try {
    const response = await axios.get(`/user/company/${companyId}`)
    dispatch(getCompanyUsersSuccess(response.data));
  } catch (error) {
    dispatch(getCompanyUsersFail(error))
  }
}

const getCompanyUsersRequest = () => ({
  type: GET_COMPANY_USERS_REQUEST
})

const getCompanyUsersSuccess = company => ({
  type: GET_COMPANY_USERS_SUCCESS,
  payload: company
})

const getCompanyUsersFail = (error) => ({
  type: GET_COMPANY_USERS_FAIL,
  payload: error
})

export const updateCompanyLogo = (companyId, body) => async dispatch => {
  dispatch(updateCompanyLogoRequest());
  try {
    if (body.logo) {
      const formData = new FormData();
      formData.append("companyLogo", body.logo);
      const response = await axios.post(`/company/${companyId}/logo`, formData);
      dispatch(updateCompanyLogoSuccess(response.data));
    } else {
      console.log('delete');
      await axios.delete(`/company/${companyId}/logo`);
      dispatch(updateCompanyLogoSuccess({ logo: '' }));
    }
  } catch (error) {
    dispatch(updateCompanyLogoFail(error))
  }
}

const updateCompanyLogoRequest = () => ({
  type: UPDATE_COMPANY_LOGO_REQUEST
})

const updateCompanyLogoSuccess = company => ({
  type: UPDATE_COMPANY_LOGO_SUCCESS,
  payload: company
})

const updateCompanyLogoFail = (error) => ({
  type: UPDATE_COMPANY_LOGO_FAIL,
  payload: error
})

const initialState = {
  isLoading: false,
  data: null,
  companyUsers: [],
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GET_COMPANY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      }
    case UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      }
    case SET_INITIAL_COMPANY:
      return {
        ...state,
        isLoading: false,
        data: null
      }
    case GET_COMPANY_USERS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GET_COMPANY_USERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case GET_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companyUsers: action.payload
      }
    case UPDATE_COMPANY_LOGO_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isLoading: false,
      }
    case UPDATE_COMPANY_LOGO_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    // case CHANGE_ISLOADED:
    //     return {
    //         ...state,
    //         isLoaded: !state.isLoaded
    //     }
    //authenticate user call

    default:
      return state;
  }
};