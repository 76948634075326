import React from 'react';
import Modal from '../../../Shared/Modals/Modal';
import Input from '../../../Shared/Input';
import Button from '../../../Shared/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

const DeviceModal = ({
    modalState,
    setModalState,
}) => {

    return (
        <Modal
            title=''
            show={modalState}
            exitButton={true}
            onCancel={() => setModalState(false)}
        >
            <Formik
                onSubmit={() => {
                    
                }}
            ></Formik>
        </Modal>
    )
}

export default DeviceModal;

const Form = styled.form`
    width:400px;
    margin-top:20px;
`;

const CenterBox = styled.div`
    text-align:center;
`;

const InputRow = styled.div`
    display:flex;
    justify-content:space-around;
`;