import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import FormControl from './FormControl'
import { useDrop } from 'react-dnd';

const CreationTab = ({
    dragItemTypes,
    currentElement: { formControls },
    addControlHandler,
    currentControl,
    setCurrentControl,
    currentSelectType,
    setCurrentSelectType,
    elIsDragging,
    setElIsDragging,
}) => {
    const fieldLimit = 20;
    const [highlight, setHighlight] = useState(null),
        [{ canDrop, isOver, dragItem }, drop] = useDrop({
            accept: [dragItemTypes.select, dragItemTypes.radio, dragItemTypes.checkbox, dragItemTypes.input, dragItemTypes.datepicker, dragItemTypes.timepicker],
            drop: (item) => { if (formControls?.length < fieldLimit) { addControlHandler(item) } },
            collect: mon => ({
                isOver: !!mon.isOver(),
                canDrop: !!mon.canDrop(),
                dragItem: mon.getItem(),
            }),
        });
    useEffect(() => {
        if (canDrop && isOver && highlight !== 'accept' && formControls?.length < fieldLimit) {
            setHighlight('accept');
        } else if (canDrop && isOver && highlight !== 'decline' && formControls?.length >= fieldLimit) {
            setHighlight('decline');
        } else if (highlight !== '') {
            setHighlight('');
        }
    }, [canDrop, isOver, dragItem]);
    return (
        <Wrapper>
            <Element
                active={currentSelectType === 'element'}
                onClick={(e) => {
                    if (currentSelectType !== 'element') {
                        setCurrentControl({ i: null, obj: null });
                        setCurrentSelectType('element');
                    }
                }}
            >
                <Body>
                    <Inputs id='inputsBox'>
                        {formControls && formControls.map((data, index) => {
                            return (
                                <FormControl
                                    key={`formControl_${index}`}
                                    data={data}
                                    controlIndex={index}
                                    active={
                                        currentControl.i === index &&
                                        currentSelectType === 'control'
                                    }
                                    setCurrentControl={setCurrentControl}
                                    setCurrentSelectType={setCurrentSelectType}
                                    dragItemTypes={dragItemTypes}
                                    setElIsDragging={setElIsDragging}
                                />
                            )
                        })}
                    </Inputs>
                    <DropZone
                        id='dropzone'
                        data-testid='dropzone'
                        disabled={formControls?.length >= fieldLimit}
                        active={elIsDragging.drag && elIsDragging.el === 'item' && formControls?.length < fieldLimit}
                        highlight={highlight}
                        ref={drop}
                    />
                </Body>
            </Element>
        </Wrapper>
    )
}

export default CreationTab;

const Wrapper = styled.div`
    padding:15px 10px 5px;
    height:100%;
`;

const Element = styled.div`
    position:relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: 2px solid ${({ theme }) => theme.grey};
    margin: 5px 0;
    height:100%;
    ${({ active, theme }) => active && `
        border-color: ${theme.primary};
        &::before{
            content:'Element';
            position:absolute;
            top: -20px;
            left: -2px;
            padding:3px 5px;
            font-size:9px;
            background: ${theme.primary};
            color:#fff;
        }
    `}
     ${({ dragging }) => dragging && `
            opacity:0.8;
        }
    `}
`;

const Body = styled.div`
    padding:7px;
    transition:500ms;
    height:100%;
    overflow:hidden;
`;

const Inputs = styled.div`
    display:grid;
    align-items:flex-start;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 130px;
    gap:10px;
    padding: 15px 10px 20px;
    height:calc(100% - 85px);
    overflow-y:auto;
    ${({ theme }) => `${theme.mq.desktop}{
        padding: 15px 30px 20px;
    }`}
`;

const blinkParent = keyframes`
  from {
      background-color:#f6f5f8;
      border-color:#D9D9D9;
    }
  to {
      background-color: #b9ddff;
      border-color:#1890FF;
  }
`;
const blinkAfter = keyframes`
  from {
      color:#D9D9D9;
    }
  to {
      color:#1890FF;
  }
`;

const animationParent = () =>
    css`
        ${blinkParent} .8s infinite alternate;
    `;

const animationAfter = () =>
    css`
        ${blinkAfter} .8s infinite alternate;
    `;

const DropZone = styled.div`
    background-color:${({ theme }) => theme.grey};
    border:2px dashed #D9D9D9;
    border-radius: 5px;
    height:85px;
    display:flex;
    justify-content:center;
    align-items:center;
    transition:300ms;
    z-index:9999999;
    animation:${animationParent};
    ${({ disabled }) => disabled && 'opacity:.5;'}
    &::after{
        content:'Przeciągnij obiekty z lewego panelu, aby dodać pola elementu';
        text-align:center;
        color:#D9D9D9;
        font-weight:600;
        transition:300ms;
        animation:${animationAfter};
        ${({ active }) => !active && `
            animation: null;
        `}
    }
    ${({ active }) => !active && `
        animation: null;
    `}
    ${({ highlight, theme }) => highlight === 'accept' ? `
        background-color:${theme.lightGreen}!important;
        border-color:${theme.green}!important;
        &::after{
            color:${theme.green}!important;
        }`
        : highlight === 'decline' ? `
            background-color:${theme.lightDanger}!important;
            border-color:${theme.danger}!important;
            &::after{
                color:${theme.danger}!important;
            }
        `
            : `
        
        `
    }
`;

const ParamPreview = styled.div`
    
`;