import React from 'react';
import Container from '../../Shared/Container';
import styled from 'styled-components';
import StatsHeader from '../../Shared/StatsHeader';
import { theme } from '../../Shared/theme';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import AvatarPlaceholder from "../../Shared/assets/avatar.svg";

const VisitsStatsView = ({
    currentView,
    currentDate,
    visitTimeData,
    visitsData,
    waitingTimeData,
    changeCurrentDateHandler,
    changeViewHandler,
    toMinutes,
    registryData
}) => {
    return (
        <Wrapper>
            <StatsHeader
                currentView={currentView}
                currentDate={currentDate}
                changeCurrentDateHandler={changeCurrentDateHandler}
                changeViewHandler={changeViewHandler}
            />
            <Content>
                <Col>
                    <StyledContainer title='Wizyty'>
                        <InfoGrid>
                            <div></div>
                            <InfoBox color={theme.primary}>
                                <Big>{visitsData.all}</Big>
                                <Small>Wszystkich <br />wizyt</Small>
                            </InfoBox>
                            <div></div>
                            <InfoBox color={theme.primary}>
                                <Big>{visitsData.planned}</Big>
                                <Small>Zaplanowanych <br />wizyt</Small>
                            </InfoBox>
                            <InfoBox color='#89e09b'>
                                <Big>{visitsData.confirmed}</Big>
                                <Small>Potwierdzonych <br />wizyt</Small>
                            </InfoBox>
                            <InfoBox color={theme.green}>
                                <Big>{visitsData.ended}</Big>
                                <Small>Zakończonych <br />wizyt</Small>
                            </InfoBox>
                            <InfoBox color={theme.secondary}>
                                <Big>{visitsData.started}</Big>
                                <Small>W trakcie <br />wizyt</Small>
                            </InfoBox>
                            <InfoBox color={theme.yellow}>
                                <Big>{visitsData.waiting}</Big>
                                <Small>Oczekujących <br />wizyt</Small>
                            </InfoBox>
                            <InfoBox color={theme.danger}>
                                <Big>{visitsData.rejected}</Big>
                                <Small>Odrzuconych <br />wizyt</Small>
                            </InfoBox>
                        </InfoGrid>
                    </StyledContainer>
                    <StyledContainer title='Rejestracja'>
                        {registryData.length > 0 ? <Table colsTemplate="1fr 1fr">
                            <TRow>
                                <THeading>Pracownik</THeading>
                                <THeading>Zarejestrowanych wizyt</THeading>
                            </TRow>
                            {registryData.map(({ count, userData }) => (
                                <TRow key={userData._id}>
                                    <TValue align='left'>
                                        <Avatar src={!userData.avatar ? AvatarPlaceholder : `${process.env.REACT_APP_SRV_URL}${userData.avatar}`} />
                                        {userData.name} {userData?.surname}
                                    </TValue>
                                    <TValue>{count}</TValue>
                                </TRow>
                            ))}
                        </Table>
                            :
                            <NoData>
                                Nie zajerestrowano żadnych wizyt
                        </NoData>
                        }
                    </StyledContainer>
                </Col>
                <Col>
                    <StyledContainer title='Czas trwania wizyty'>
                        {visitTimeData.perLength?.length > 0 ? <>
                            <ChartHeader>
                                <Heading>
                                    <Label>Średni</Label>
                                    <Value>{toMinutes(visitTimeData.all?.average, 'string')}</Value>
                                </Heading>
                                <Heading>
                                    <Label>Najdłuższy</Label>
                                    <Value>{toMinutes(visitTimeData.all?.highest, 'string')}</Value>
                                </Heading>
                                <Heading>
                                    <Label>Najkrótszy</Label>
                                    <Value>{toMinutes(visitTimeData.all?.lowest, 'string')}</Value>
                                </Heading>
                            </ChartHeader>
                            <ResponsiveContainer width='100%' height={200}>
                                <BarChart barSize={40} data={visitTimeData.perLength} margin={{ top: 5, right: 50, left: 0, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="label" />
                                    <YAxis domain={[0, 'dataMax + 5']} />
                                    <Tooltip
                                        content={CustomTooltip}
                                    />
                                    <Bar dataKey="visits" fill={theme.primary} />
                                </BarChart>
                            </ResponsiveContainer>
                        </>
                            :
                            <NoData>
                                Żadna wizyta nie posiada statusu "zakończona"
                            </NoData>
                        }
                    </StyledContainer>
                    <StyledContainer title='Czas oczekiwania na wizytę'>
                        {waitingTimeData.perLength?.length > 0 ? <>
                            <ChartHeader>
                                <Heading>
                                    <Label>Średni</Label>
                                    <Value>{toMinutes(waitingTimeData.all?.average, 'string')}</Value>
                                </Heading>
                                <Heading>
                                    <Label>Najdłuższy</Label>
                                    <Value>{toMinutes(waitingTimeData.all?.highest, 'string')}</Value>
                                </Heading>
                                <Heading>
                                    <Label>Najkrótszy</Label>
                                    <Value>{toMinutes(waitingTimeData.all?.lowest, 'string')}</Value>
                                </Heading>
                            </ChartHeader>
                            <ResponsiveContainer width='100%' height={200}>
                                <BarChart barSize={40} data={waitingTimeData.perLength} margin={{ top: 5, right: 50, left: 0, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="label" />
                                    <YAxis domain={[0, 'dataMax + 5']} />
                                    <Tooltip
                                        content={(props) => CustomTooltip(props, 'waiting')}
                                    />
                                    <Bar dataKey="visits" fill={theme.primary} />
                                </BarChart>
                            </ResponsiveContainer>
                        </>
                            :
                            <NoData>
                                Żadna wizyta nie posiada statusu oczekiwania
                            </NoData>
                        }
                    </StyledContainer>
                </Col>
            </Content>
        </Wrapper>
    )
}

export default VisitsStatsView;

const CustomTooltip = (props, type) => {
    const payload = props?.payload ? props?.payload[0]?.payload : {};
    const top = payload?.label?.replace('<', '')?.replace('min', '');
    const bottom = parseInt(top) - 5;
    return (
        <ToolTipWrapper>
            <TooltipInfo>
                <TooltipValue>{payload?.visits}</TooltipValue>
                <TooltipLabel> {type === 'waiting' ? 'pacjentów czekało' : 'wizyt trwało'} </TooltipLabel>
                <TooltipValue>między {bottom} - {top} min</TooltipValue>
            </TooltipInfo>
        </ToolTipWrapper>
    )
}

const StyledContainer = styled(Container)`
  margin:0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding:10px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content:space-between;
  flex-wrap:wrap;
  max-width:1365px;
  margin: 0 auto;
  >div{
    width: 100%;
    margin:5px 0;
  }
  ${({ theme }) => `${theme.mq.desktop}{
    height: calc(100vh - 240px);
    flex-wrap: nowrap;
    >div{
      width: calc(50% - 7.5px);
    }
  }`}
`;

const ChartHeader = styled.ul`
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin: 0 80px 20px;
`;

const Heading = styled.li`
  display:flex;
  flex-direction:column;
`;

const Label = styled.span`
  font-weight:300;
  font-size:12px;
  color:${({ theme }) => theme.lightBlack};
`;

const Value = styled.span`
  font-weight:600;
  font-size:20px;
  margin-left:10px;
  color:${({ theme }) => theme.primary};
`;

const Col = styled.div`
  display:flex;
  flex-direction:column;
  >div:first-child{
    margin-bottom: 10px;
  }
`;

const InfoGrid = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content:center;
  max-width: 450px;
  margin: 10px auto 30px;
`;

const InfoBox = styled.div`
  color:${({ color }) => color};
  text-align:center;
  margin:5px 0;
`;

const Big = styled.div`
  font-size:28px;
  font-weight:600;
  color:inherit;
`;

const Small = styled.div`
  font-size:14px;
  color:inherit;
`;

const ToolTipWrapper = styled.div`
  padding:5px;
  border: 1px solid #ccc;
  border-radius:4px;
  background-color:#fff;
  box-shadow:0 4px 6px #ccc;
`;

const TooltipLabel = styled.span`
  font-size:12px;
`;

const TooltipValue = styled.span`
  font-size:12px;
  font-weight:600;
`;

const TooltipInfo = styled.div``;

const Table = styled.div`
  min-height:${({ minHeight }) => minHeight ? minHeight : '0'};
  grid-template-columns: ${({ colsTemplate }) => colsTemplate};
`;

const TRow = styled.div`
  display:grid;
  align-items:center;
  grid-template-columns: inherit;
  padding: 5px;
  &:not(:first-child){
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const THeading = styled.span`
  font-size:14px;
  font-weight:600;
  text-align:center;
  color:${({ theme }) => theme.lightBlack};
`;

const TValue = styled.span`
  font-size:14px;
  display:flex;
  align-items:center;
  justify-content:${({ align }) => align ? align : 'center'};
`;

const NoData = styled.p`
  font-weight:600;
  font-size:18px;
  padding:15px 0;
  text-align:center;
`;

const Avatar = styled.img`
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 50%;
`;