import React from "react";
import styled from "styled-components";
import Button from "../Button";

const SimpleInfoModal = ({
  info,
  acceptText,
  onAccept,
}) => (
  <Container>
    <Info>{info}</Info>
    <ButtonsContainer>
    <Button onClick={onAccept} variant="primary">{acceptText}</Button>
    </ButtonsContainer>
  </Container>
);

export default SimpleInfoModal;

const Container = styled.div`
  width: 100%;
  background-color: #fff;
`;

const Info = styled.h2`
  font-size: 18px;
  margin: 0;
  width: 100%;
`;

const ButtonsContainer = styled.div`
display: flex;
justify-content: flex-end;
margin-top: 2rem;
`