import React, { useState, useEffect } from "react";
import ReceptionistPatientsSidebarView from "./ReceptionistPatientsSidebarView";
import ReceptionistCalendarSidebarView from "./ReceptionistCalendarSidebarView";
import { connect } from "react-redux";
import { getEmployeeHealthcenters } from "../../../redux/modules/healthcenter";
import { calendarPageLoaded, patientsPageLoaded } from "../../../redux/modules/application";
import {
  addPatient,
  getCompanyPatients,
  setCurrentPatient,
  setPatientAvatar,
} from "../../../redux/modules/patients";
import { getEmployees } from "../../../redux/modules/employees";
import { withRouter, useHistory, useParams } from "react-router-dom";
import {
  updateCalendarDate,
  updateDoctorsList,
  updateNursesList,
  updateGroupsList
} from "../../../redux/modules/calendar";
import useDebouncer from '../../Shared/Hooks/useDebouncer';
import { nextTutorialStep } from '../../../redux/modules/tutorial';

const ReceptionistSidebarContainer = ({
  userRole,
  getEmployeeHealthcenters,
  getEmployees,
  addPatient,
  getCompanyPatients,
  patients,
  currentPatientId,
  setCurrentPatient,
  location: { pathname },
  doctors,
  nurses,
  calendarDate,
  updateCalendarDate,
  calendarDoctors,
  calendarNurses,
  updateDoctorsList,
  calendarGroups,
  updateGroupsList,
  updateNursesList,
  currentHealthcenter,
  localStorageReadFinished,
  calendarPageLoaded,
  patientsPageLoaded,
  currentPage,
  paginationMetadata,
  setPatientAvatar,
  nextTutorialStep,
}) => {
  const [patientModalState, setPatientModalState] = useState(false),
    [sidepanelFilter, setSidepanelFilter] = useState(""),
    [sidepanelPatients, setSidepanelPatients] = useState([]),
    [setDebounce, sidebarLoading] = useDebouncer();

  useEffect(() => {
    if (userRole?.companyId) {
      getEmployeeHealthcenters(userRole.healthCenters);
      getCompanyPatients(userRole.companyId, 10, 1, "");
      getEmployees("doctor", userRole.companyId);
      getEmployees("nurse", userRole.companyId);
    }
  }, [userRole]);

  useEffect(() => {
    setSidepanelPatients(patients);
  }, [patients]);

  useEffect(() => {
    if (
      localStorageReadFinished &&
      nurses &&
      doctors &&
      currentHealthcenter
    ) {
      // read selected doctors and groups from localStorage
      const currentDoctorsData = doctors.filter(doc =>
        currentHealthcenter.doctors.some(id => id === doc._id) ? doc : null
      );
      const currentNursesData = nurses.filter(doc =>
        currentHealthcenter.nurses.some(id => id === doc._id) ? doc : null
      );
      const doctorsWithChecked = currentDoctorsData.map(doctor => {
        return {
          ...doctor,
          checked: calendarDoctors.find(docLC => docLC._id === doctor._id)
            ? calendarDoctors.find(docLC => docLC._id === doctor._id).checked
            : false
        };
      });
      const nursesWithChecked = currentNursesData.map(nurse => {
        return {
          ...nurse,
          checked: calendarNurses.find(docLC => docLC._id === nurse._id)
            ? calendarNurses.find(docLC => docLC._id === nurse._id).checked
            : false
        };
      });
      const groupsWithChecked = currentHealthcenter.groups.map(group => {
        return {
          ...group,
          checked: calendarGroups.find(groupLC => groupLC.name === group.name)
            ? calendarGroups.find(groupLC => groupLC.name === group.name)
              .checked
            : false
        };
      });
      if (doctors.length > 0) {
        updateDoctorsList(doctorsWithChecked);
      }
      if (nurses.length > 0) {
        updateNursesList(nursesWithChecked);
      }
      updateGroupsList(groupsWithChecked);
    }
  }, [localStorageReadFinished, doctors, nurses, currentHealthcenter]);

  useEffect(() => {
    setDebounce(() =>
      getCompanyPatients(userRole?.companyId, 10, 1, sidepanelFilter)
    );
  }, [sidepanelFilter, userRole]);

  const { patientId: urlPatientId, currentTab: urlCurrentTab } = useParams(),
    history = useHistory();

  useEffect(() => {
    if (pathname.startsWith("/patients")) {
      if (patients && currentPatientId) {
        if (urlPatientId) {
          const patientIndex = patients.findIndex(el => el._id === urlPatientId);
          if (patientIndex !== -1 && currentPatientId !== urlPatientId) {
            setCurrentPatient(urlPatientId);
            if (!urlCurrentTab) {
              history.push(`/patients/${urlPatientId}/visits`);
            }
          } else if (patientIndex === -1) {
            history.push(`/patients/${currentPatientId}/${urlCurrentTab ? urlCurrentTab : 'visits'}`);
          } else if (!urlCurrentTab && currentPatientId === urlPatientId) {
            history.push(`/patients/${urlPatientId}/${urlCurrentTab ? urlCurrentTab : 'visits'}`);
          }
        }
        else if (!urlPatientId) {
          history.push(`/patients/${currentPatientId}/${urlCurrentTab ? urlCurrentTab : 'visits'}`);
        }
      }
    }
  }, [patients, pathname]);

  const addPatientHandler = async patient => {
    const res = await addPatient(userRole.companyId, patient);
    return res;
  };

  const prepareDoctorCollapseListData = data => {
    const mappedData = data.map(item => {
      return {
        ...item,
        name: item.userId.name,
        surname: item.userId?.surname,
        avatar: item.userId.avatar,
        checked: item.checked !== undefined ? item.checked : false
      };
    });
    return mappedData;
  };

  const prepareGroupCollapseListData = data => {
    const mappedData = data.map(item => {
      return {
        ...item,
        checked: item.checked !== undefined ? item.checked : false
      };
    });
    return mappedData;
  };

  const onPatientPageChange = (pageChangeDirection) => {
    let pageNumber = 1;
    if (pageChangeDirection === "PREV_PAGE") {
      pageNumber = paginationMetadata.currentPage - 1
    } else if (pageChangeDirection === "NEXT_PAGE") {
      pageNumber = paginationMetadata.currentPage + 1
    }
    getCompanyPatients(userRole?.companyId, 10, pageNumber, sidepanelFilter);
  }

  return (
    <>
      {pathname.startsWith("/patients") ? (
        <ReceptionistPatientsSidebarView
          addPatientHandler={addPatientHandler}
          patientModalState={patientModalState}
          setPatientModalState={setPatientModalState}
          patients={patients}
          currentPatientId={currentPatientId}
          setCurrentPatient={setCurrentPatient}
          sidepanelPatients={sidepanelPatients}
          setSidepanelPatients={setSidepanelPatients}
          sidepanelFilter={sidepanelFilter}
          setSidepanelFilter={setSidepanelFilter}
          urlCurrentTab={urlCurrentTab}
          sidebarLoading={sidebarLoading}
          patientsPageLoaded={patientsPageLoaded}
          currentPage={currentPage}
          paginationMetadata={paginationMetadata}
          onPatientPageChange={onPatientPageChange}
          setPatientAvatar={setPatientAvatar}
          nextTutorialStep={nextTutorialStep}
        />
      ) : pathname === "/" ? (
        <ReceptionistCalendarSidebarView
          calendarDoctors={prepareDoctorCollapseListData(calendarDoctors)}
          calendarNurses={prepareDoctorCollapseListData(calendarNurses)}
          calendarGroups={prepareGroupCollapseListData(calendarGroups)}
          calendarDate={calendarDate}
          updateCalendarDate={updateCalendarDate}
          updateGroupsList={updateGroupsList}
          updateDoctorsList={updateDoctorsList}
          updateNursesList={updateNursesList}
          calendarPageLoaded={calendarPageLoaded}
        />
      ) : (
            ""
          )}
    </>
  );
};

const mapStateToProps = state => ({
  userRole: state.authentication.userRole,
  patients: state.patients.data,
  patientsLoading: state.patients.loading,
  visitsLoading: state.visits.loading,
  currentPatientId: state.patients.currentPatient._id,
  doctors: state.employees.doctors,
  nurses: state.employees.nurses,
  calendarDate: state.calendar.calendarDate,
  calendarDoctors: state.calendar.doctors,
  calendarNurses: state.calendar.nurses,
  calendarGroups: state.calendar.groups,
  currentHealthcenter: state.healthcenter.currentHealthcenter,
  localStorageReadFinished: state.calendar.localStorageReadFinished,
  currentPage: state.application.currentPage,
  paginationMetadata: state.patients.paginationMetadata,
  tutorialActive: state.tutorial.active,
});

const mapDispatchToProps = dispatch => {
  return {
    getEmployeeHealthcenters: healthcenters =>
      dispatch(getEmployeeHealthcenters(healthcenters)),
    getEmployees: (employeeType, companyId) =>
      dispatch(getEmployees(employeeType, companyId)),
    addPatient: (companyId, patient) =>
      dispatch(addPatient(companyId, patient)),
    getCompanyPatients: (companyId, pageSize, pageNumber, searchTerm) => dispatch(getCompanyPatients(companyId, pageSize, pageNumber, searchTerm)),
    setCurrentPatient: patientId => dispatch(setCurrentPatient(patientId)),
    updateCalendarDate: date => dispatch(updateCalendarDate(date)),
    updateNursesList: nurses => dispatch(updateNursesList(nurses)),
    updateDoctorsList: doctors => dispatch(updateDoctorsList(doctors)),
    updateGroupsList: groups => dispatch(updateGroupsList(groups)),
    patientsPageLoaded: () => dispatch(patientsPageLoaded()),
    calendarPageLoaded: () => dispatch(calendarPageLoaded()),
    setPatientAvatar: (patientId, data) => dispatch(setPatientAvatar(patientId, data)),
    nextTutorialStep: () => dispatch(nextTutorialStep()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReceptionistSidebarContainer));
