import React, { useState, useEffect } from "react";
import AdminSidebarView from "./AdminSidebarView";
import {withRouter} from 'react-router-dom';

const AdminSidebarContainer = ({location}) => {
  const [activeTab, setActiveTab] = useState(null);

  //set Active Tab depends on route
    useEffect(() => {
      const currentRoute = location.pathname.replace("/","");
      if (currentRoute === "") {
        setActiveTab(0);
      } else if (currentRoute === "employees") {
        setActiveTab(1);
      } else if (currentRoute === "vacations") {
        setActiveTab(2);
      } else if (currentRoute === "company") {
        setActiveTab(3);
      } else if (currentRoute === "healthcenters") {
        setActiveTab(4);
      } else if (currentRoute === "income") {
        setActiveTab(5);
      }else if (currentRoute === "visits") {
        setActiveTab(6);
      }
      
    }, [location]);
    
  return <AdminSidebarView activeTab={activeTab} />;
};

export default withRouter(AdminSidebarContainer);
