import React from "react";
import styled from "styled-components";
import EditPenIcon from './assets/edit-pen.svg';
import AddPlusIcon from './assets/add-plus.svg';
import CheckIcon from './assets/check-white.svg';
import RefreshIcon from './assets/refresh.svg';
import RemoveIcon from './assets/remove-bin.svg';
import PrintIcon from './assets/print.svg';

const Button = ({ id, type, variant, size, onClick, children, className, plusIcon, disabled, title }) => (
    <StyledButton id={id} title={title} disabled={disabled} className={className} type={type ? type : "button"} variant={variant ? variant : "default"} size={size} onClick={onClick}>
        {plusIcon && <Plus src={AddPlusIcon} />} {children}
    </StyledButton>
);

export default Button;

const StyledButton = styled.button`
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 12px;
    cursor: pointer;
    margin: 0 2px;
    transition:200ms;
    font-weight: 600;
    ${({ theme }) => `${theme.mq.desktop}{
        padding: 12px 14px;
        font-size: 14px;
    }`}
${({ variant, theme, disabled }) => variant === "default" ? `
    background-color: #fff;
    border: 1px solid #cccccc;
    color: ${theme.lightBlack};
    &:hover {
        background-color: #e2e6ea;
        border: 1px solid #e2e6ea;
    }
` : variant === "primary" ? `
    background-color: ${theme.primary};
    border: 1px solid ${theme.primary};
    color: #fff;
    &:hover {
        background-color: #2898ff;
        border: 1px solid #2898ff;
    }
` : variant === "secondary" ? `
    background-color: ${theme.secondary};
    border: 1px solid ${theme.secondary};
    color: #fff;
    &:hover {
        background-color: #5173ff;
        border: 1px solid #5173ff;
    }
` : variant === "danger" ? `
    background-color: ${theme.danger};
    border: 1px solid ${theme.danger};
    color: #fff;
    &:hover {
        background-color: #cb6161;
        border: 1px solid #cb6161;
    }
` : variant === "dark" ? `
    background-color: ${theme.darkGrey};
    border: 1px solid ${theme.darkGrey};
    color: #fff; 
` : variant === "edit" ? `
    position: absolute;
    right:10px;
    top:10px;
    padding:0;
    background-color:#E8E8E8;
    background-image:url(${EditPenIcon});
    background-position: center;
    background-repeat: no-repeat;
    border:none;
    border-radius:5px;
    width:25px;
    height:25px;
    transition:300ms;
    cursor: pointer;
    &:hover{
      background-color:#E1E1E1;
    } 
` : variant === "add" ? `
    position: absolute;
    right:10px;
    top:10px;
    padding:0;
    background-color:#E8E8E8;
    background-image:url(${AddPlusIcon});
    background-position: center;
    background-repeat: no-repeat;
    border:none;
    border-radius:5px;
    width:25px;
    height:25px;
    transition:300ms;
    cursor: pointer;
    &:hover{
      background-color:#E1E1E1;
    }
` : variant === "check" ? `
    position: absolute;
    right:40px;
    top:10px;
    padding:0;
    background-color:${theme.green};
    background-image:url(${CheckIcon});
    background-position: center;
    background-repeat: no-repeat;
    border:none;
    border-radius:5px;
    width:25px;
    height:25px;
    transition:300ms;
    cursor: pointer;
` : variant === "refresh" ? `
    position: absolute;
    right:40px;
    top:10px;
    padding:0;
    background-color:#E8E8E8;
    background-image:url(${RefreshIcon});
    background-position: center;
    background-repeat: no-repeat;
    border:none;
    border-radius:5px;
    width:25px;
    height:25px;
    transition:300ms;
    cursor: pointer;
    &:hover{
      background-color:#E1E1E1;
    }
` : variant === "delete" ? `
    position: absolute;
    right:0;
    top:-5px;
    padding:0;
    background-color:#E8E8E8;
    background-image:url(${RemoveIcon});
    background-position: center;
    background-repeat: no-repeat;
    border:none;
    border-radius:5px;
    width:25px;
    height:25px;
    transition:300ms;
    cursor: pointer;
    &:hover{
      background-color:#E1E1E1;
    }
` : variant === "close" ? `
    position: absolute;
    right:10px;
    top:10px;
    padding:0;
    background-color:#E8E8E8;
    background-position: center;
    background-repeat: no-repeat;
    border:none;
    border-radius:5px;
    width:25px;
    height:25px;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      height: 15px;
      width: 2px;
      background-color: #000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 5px;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    &:hover{
      background-color:#E1E1E1;
    }
` : variant === "print" ? `
    position: absolute;
    right:40px;
    top:10px;
    padding:0;
    background-image:url(${PrintIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
    border:none;
    border-radius:5px;
    width:30px;
    height:30px;
    transition:300ms;
    cursor: pointer;
    &:hover{
      background-color:#E1E1E1;
    }
`: ``}
${({ size }) => size === "small" ? `
    padding: 6px 8px!important;
` : size === "big" ? `
    padding: 16px 20px!important;
` : ``};
${({ disabled }) => disabled && `
    user-select:none;
    opacity:.3;
    cursor: not-allowed;
    border: 1px solid #f6f5f8;
    background-color: #cccccc;
    border: 1px solid #cccccc;
    color: #fff;
    &:hover{
        background-color:#cccccc;
        border: 1px solid #cccccc;
    }
`};
`;

const Plus = styled.img`
    padding-right:5px;
    line-height: 0;
    width:17px;
    height:17px;
    transform: translateY(3px);
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(103deg) brightness(105%) contrast(105%);
`;

const StyledPrintIcon = styled(PrintIcon)`

`;