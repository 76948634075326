import React from "react";
import styled from "styled-components";
import Container from "../../../../Shared/Container";
import Select from "../../../../Shared/Select";
import DocumentItem from "../../../../Shared/DocumentItem";
import DocumentsModal from "../../modals/DocumentsModal";
import DeleteModal from "../../modals/DeleteModal";
import Gallery from '../../../../Shared/Gallery';
import moment from 'moment';

const DocumentsTabView = ({
  setDocumentsModal,
  documentsList,
  addPatientDocumentHandler,
  updatePatientDocumentHandler,
  deleteModalHandler,
  documentsModal,
  deleteModal,
  setDeleteModal,
  patientMedicalVisits,
  medicalVisitId,
  setMedicalVisitId,
  queryDocumentId,
  patientDocuments,
  nextTutorialStep,
}) => {
  const visitSelectOptions = [{ value: '', label: 'Wszystkie' }, ...patientMedicalVisits.map(({
    visit = {}, _id }) => ({ value: _id, label: moment(visit?.start).format('DD.MM.YYYY HH:mm') }))];

  const pdfs = documentsList.filter(({ path }) => !path.includes('photos')),
    imgs = documentsList.filter(({ path }) => path.includes('photos')),
    galleryImages = imgs.map(({ path, _id, originalName }) => ({ name: originalName, src: `${process.env.REACT_APP_SRV_URL}${path}`, _id }));

  return (
    <Wrapper>
      <DocumentsModal
        modalState={documentsModal}
        setModalState={setDocumentsModal}
        addDocumentHandler={addPatientDocumentHandler}
        patientDocuments={patientDocuments}
        deleteModalHandler={deleteModalHandler}
        updateDocumentHandler={updatePatientDocumentHandler}
      />
      <DeleteModal
        modalState={deleteModal.shown}
        setModalState={setDeleteModal}
        onAccept={deleteModal.onAccept}
        question={deleteModal.question}
      />
      <StyledContainer
        actionButton='add'
        actionButtonTitle='Dodaj dokument'
        actionButtonOnClick={() => {
          setDocumentsModal({ shown: true, editId: null })
          if (nextTutorialStep) {
            nextTutorialStep();
          }
        }}
        title="Wszystkie dokumenty">
        <SelectBox>
          <Select
            label="Dokumentacja z wizyty"
            placeholder='Wybierz wizytę...'
            onChange={({ value }) => setMedicalVisitId(value)}
            value={visitSelectOptions.find(({ value }) => value === medicalVisitId)}
            options={visitSelectOptions}
          />
        </SelectBox>
        <ListHeading>Dokumenty</ListHeading>
        <List>
          {pdfs?.length > 0 ? (
            <>
              <Header>
                <Heading>Nazwa</Heading>
                <Heading>Data dodania</Heading>
              </Header>
              {pdfs &&
                pdfs.map((doc) =>
                  <DocumentItem
                    key={doc._id}
                    documentData={doc}
                    setModalState={setDocumentsModal}
                  />
                )}
            </>
          ) : (
              <p>Brak dokumentów dla tego pacjenta lub wizyty</p>
            )}
        </List>
        <ListHeading>Multimedia</ListHeading>
        <List>
          {imgs?.length > 0 ?
            <Gallery
              deleteModalHandler={deleteModalHandler}
              images={galleryImages}
              queryDocumentId={queryDocumentId}
            />
            :
            <p>Brak multimediów dla tego pacjenta lub wizyty</p>
          }
        </List>
      </StyledContainer>
    </Wrapper>
  );
};

export default DocumentsTabView;

const Wrapper = styled.div`
  height: 100%;
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const List = styled.ul`
  margin: 0 20px;
  max-height: calc(50vh - 205px);
  min-height:60px;
  overflow: scroll;
`;

const ListHeading = styled.p`
  font-size:16px;
  font-weight:600;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px 0 0;
  > p:not(:first-child) {
    text-align: center;
  }
`;

const Heading = styled.p`
  font-weight: 600;
  font-size:14px;
`;

const SelectBox = styled.div`
  position:absolute;
  right:50px;
  top:10px;
  width:225px;
`;