import React from "react";
import styled from "styled-components";
import EditPenIcon from "../Shared/assets/edit-pen-underline.svg";
import StatusPill from "../Shared/StatusPill";
import AvatarPlaceholder from "../Shared/assets/avatar.svg";
import { userRolesDisc } from '../Shared/enums';

const EmployeeCard = ({ employee, vacationsCount, setEditModal, setCurrentUser }) => {
  return (
    <Wrapper>
      <SingleInfo>
        {userRolesDisc[employee.userId.activeRole]}
      </SingleInfo>
      <SingleInfo>
        <Avatar src={!employee.userId.avatar ? AvatarPlaceholder : `${process.env.REACT_APP_SRV_URL}${employee.userId.avatar}`} />
        <SubInfoWrapper>
          <SubInfo employeeName>{`${employee.userId.name} ${employee.userId?.surname}`}</SubInfo>
          <SubInfo>{employee.userId.email}</SubInfo>
          <SubInfo>{employee.userId.tel}</SubInfo>
        </SubInfoWrapper>
      </SingleInfo>
      <SingleInfo>{employee.callendarSettings?.step ? employee.callendarSettings?.step + ` min` : '-'}</SingleInfo>
      <SingleInfo>
        {employee.isActive ?
          <StatusPill type="positive">Aktywny</StatusPill>
          :
          <StatusPill type="negative">Nieaktywny</StatusPill>
        }
      </SingleInfo>
      <SingleInfo>{vacationsCount === 1 ? `${vacationsCount} dzień` : `${vacationsCount} dni`}</SingleInfo>

      <SingleInfo>
        {employee.visitColor && <ColorSquare color={employee.visitColor}></ColorSquare>}
      </SingleInfo>

      <SingleInfo>
        <EditPen src={EditPenIcon} onClick={() => {
          setEditModal(true);
          setCurrentUser(employee);
        }} />
      </SingleInfo>
    </Wrapper>
  );
};

export default EmployeeCard;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  border: 2px solid #f6f5f8;
  border-radius: 5px;
  margin: 10px auto;
  padding: 11px 0;
`;
const SingleInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:nth-of-type(2){
    justify-content: flex-start;
  }
`;

const SubInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SubInfo = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxxs};
  ${({ employeeName, theme }) => employeeName && `
    font-weight: bold;
    color: ${theme.lightBlack};
  `}
`;

const ColorSquare = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => `${color}`};
  border-radius: 4px;
`
const Avatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
`;

const EditPen = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  filter: invert(81%) sepia(0%) saturate(51%) hue-rotate(195deg) brightness(86%)
    contrast(81%);
  &:hover {
    filter: invert(98%) sepia(0%) saturate(476%) hue-rotate(144deg)
      brightness(89%) contrast(81%);
  }
`;
