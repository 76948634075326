import React from 'react';
import styled from 'styled-components';
import arrowImg from "../Shared/assets/arrow-down.svg";
import Button from '../Shared/Button';

const Toolbar = ({
    onNavigate,
    onView,
    label,
    userFilters,
    usersFiltersHandler,
    ...props
}) => {
    return (
        <ToolbarContainer>
            <Actions>
                <Button onClick={() => onNavigate("TODAY")}>Dzisiaj</Button>
            </Actions>
            <NavArrows>
                <ToolbarArrowButton left onClick={() => onNavigate("PREV")}>
                    <ArrowImage left src={arrowImg} />
                </ToolbarArrowButton>
                <CurrentMonth>{label}</CurrentMonth>
                <ToolbarArrowButton right onClick={() => onNavigate("NEXT")}>
                    <ArrowImage src={arrowImg} />
                </ToolbarArrowButton>
            </NavArrows>
        </ToolbarContainer>
    )
}

export default Toolbar;

const ToolbarContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`;

const ToolbarArrowButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0;
`;

const ArrowImage = styled.img`
  display: block;
  padding: 7px;
  width: 30px;
  height: 30px;
  transition: 0.25s;
  transform: rotate(-90deg);
  ${({ left }) =>
        left &&
        `
  transform: rotate(90deg);
  `}
  &:hover {
    background-color: #e3e3e3;
    border-radius: 50%;
  }
`;

const Actions = styled.div`
    width:400px;
    position:absolute;
    left:20px;
`;

const NavArrows = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
`;

const CurrentMonth = styled.p`
    text-transform:capitalize;
    font-weight:600;
    color:${({ theme }) => theme.lightBlack};
`;
