import React from "react";
import NotFound from '../Shared/assets/page-not-found.svg';
import styled from 'styled-components';
import Button from '../Shared/Button';
import { Link } from "react-router-dom";

const NotFoundPageView = () => {
  return (
    <Wrapper>
      <Image src={NotFound} />
      <h1>Strona nie znaleziona</h1>
      <StyledLink to="/">
      <Button variant="primary">Wróć do strony głównej</Button>
      </StyledLink>
    </Wrapper>
  );
};

export default NotFoundPageView;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const Image = styled.img`
width: 25%;
max-width: 600px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;