import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Input from '../../../Shared/Input';
import Button from '../../../Shared/Button';
import Textarea from '../../../Shared/Textarea';
import Select from '../../../Shared/Select';
import DatePicker from '../../../Shared/DatePicker';
import * as Yup from 'yup';
import { Formik } from 'formik';

const HospitalizationModal = ({
    modalState: { shown: modalShown, editId: modalEditId },
    setModalState,
    diagnosis,
    hospitals,
    wards,
    getMedicalDataHandler,
    updatePatientHospitalizationHandler,
    patientHospitalizations,
    setDeleteModal
}) => {
    const initalValuesSchema = {
        hospital: null,
        ward: null,
        admissionDate: Date.now(),
        dischargeDate: Date.now(),
        additionalNotes: '',
    }

    const [initalValues, setInitialValues] = useState(initalValuesSchema),
        [diagnosisItems, setDiagnosisItems] = useState([]);

    const addItemHandler = (val) => {
        setDiagnosisItems(prev => ([...prev, { ...val }]));
    },
        removeItemHandler = (targetId) => {
            setDiagnosisItems(prev => prev.filter(el => el._id !== targetId));
        },
        resetModalHandler = () => {
            setDiagnosisItems([]);
            setInitialValues(initalValuesSchema);
        }

    const diagnosisFormatOptionLabel = ({ category, subcategory, subcategoryCode, label }) => (
        <Option>
            <OptionPlaceholder>{label}</OptionPlaceholder>
            <OptionCode>{subcategoryCode}</OptionCode>
            <OptionLabel>{category} - {subcategory}</OptionLabel>
        </Option>
    );

    useEffect(() => {
        if (modalEditId) {
            const { hospital, ward, diagnosis, ...rest } = patientHospitalizations.hospitalizations.find(el => el._id === modalEditId);
            setInitialValues({
                hospital: { value: hospital?.name, label: hospital?.name, name: hospital?.name },
                ward: { value: ward?.name, label: ward?.name, name: ward?.name },
                ...rest
            });
            setDiagnosisItems(diagnosis);
        }
    }, [modalEditId]);

    return (
        <Modal
            title={modalEditId ? 'Edycja hospitalizacji' : 'Dodawanie hospitalizacji'}
            exitButton={true}
            onCancel={() => { setModalState({ shown: false, editId: null }); resetModalHandler(); }}
            show={modalShown}
        >
            <Formik
                // validationSchema={validationSchema}
                initialValues={initalValues}
                enableReinitialize
                onSubmit={(values) => {
                    const hospitalizations = patientHospitalizations.hospitalizations ?? [];
                    if (modalEditId) {
                        updatePatientHospitalizationHandler({
                            hospitalizations: [...hospitalizations.map(el => el._id === modalEditId ? { ...values, diagnosis: [...diagnosisItems], hospital: { name: values.hospital?.nazwa } } : el)]
                        });
                    } else {
                        updatePatientHospitalizationHandler({
                            hospitalizations: [...hospitalizations, { ...values, diagnosis: [...diagnosisItems], hospital: { name: values.hospital?.nazwa } }]
                        });
                    }
                    setModalState({ shown: false, editId: null });
                    resetModalHandler();
                }}
            >
                {({ values, errors, setFieldValue, handleChange, handleSubmit }) =>
                    <Form onSubmit={handleSubmit}>
                        <InputRow>
                            <StyledSelect
                                label='Szpital'
                                value={values?.hospital}
                                placeholder="Wybierz szpital"
                                onChange={(val) => { setFieldValue('hospital', val) }}
                                onInputChange={(val) => { getMedicalDataHandler('hospitals', val) }}
                                options={hospitals.map(el => ({ label: el.nazwa, value: el.nazwa, ...el }))}
                                // isLoading={medsdiagnosisLoading}
                                name='hospital'
                                noOptionsMessage={() => <p>Nie znaleziono takiego szpitala</p>}
                            />
                            <StyledSelect
                                label='Oddział'
                                value={values?.ward}
                                placeholder="Wybierz oddział"
                                onChange={(val) => { setFieldValue('ward', val) }}
                                onInputChange={(val) => { getMedicalDataHandler('wards', val) }}
                                options={wards.map(el => ({ label: el.name, value: el.name, ...el }))}
                                // isLoading={medsdiagnosisLoading}
                                name='ward'
                                noOptionsMessage={() => <p>Nie znaleziono takiego oddziału</p>}
                            />
                        </InputRow>
                        <InputRow>
                            <DatePicker
                                label="Data wpisania"
                                dateFormat="dd.MM.yyyy"
                                selected={values?.admissionDate ? new Date(values?.admissionDate) : Date.now()}
                                onChange={(value) => setFieldValue('admissionDate', value)}
                            />
                            <DatePicker
                                label="Data wypisania"
                                dateFormat="dd.MM.yyyy"
                                selected={values?.dischargeDate ? new Date(values?.dischargeDate) : Date.now()}
                                onChange={(value) => setFieldValue('dischargeDate', value)}
                            />
                        </InputRow>
                        <StyledSelect
                            label='Rozpoznania'
                            options={diagnosis}
                            // isLoading={medsdiagnosisLoading}
                            name='diagnosisSearch'
                            placeholder="Dodaj rozpoznanie z listy"
                            onChange={(val) => addItemHandler(val)}
                            onInputChange={(val) => { getMedicalDataHandler('icd10s', val) }}
                            value={{ label: 'Dodaj rozpoznanie z listy' }}
                            searchInputWithoutFilter={true}
                            customFormatOptionLabel={diagnosisFormatOptionLabel}
                            noOptionsMessage={() => <p>Nie znaleziono takiego rozpoznania</p>}
                        />
                        <DiagnosisList>
                            {diagnosisItems.length > 0 ?
                                diagnosisItems.map(({ category, subcategory, subcategoryCode, relationshipKind, _id }) => (
                                    <DiagnosisItem key={_id}>
                                        <Code>{subcategoryCode}</Code>
                                        <Name>{category} - {subcategory}</Name>
                                        <RemoveBtn type='button' onClick={() => removeItemHandler(_id)} />
                                    </DiagnosisItem>
                                ))
                                :
                                <NoItems>Brak rozpoznań</NoItems>
                            }
                        </DiagnosisList>
                        <Textarea
                            name="additionalNotes"
                            label="Dodatkowe informacje"
                            onChange={handleChange}
                            value={values.additionalNotes}
                            error={errors.additionalNotes}
                        />
                        <CenterBox>
                            <Button onClick={handleSubmit} type='submit' variant='primary'>{modalEditId ? 'Zapisz zmiany' : 'Dodaj'}</Button>
                            {modalEditId && <Button onClick={() => setDeleteModal({
                                question: 'Czy na pewno chcesz usunąć tą hospitalizację?',
                                shown: true,
                                onAccept: () => {
                                    updatePatientHospitalizationHandler({
                                        hospitalizations: patientHospitalizations.hospitalizations.filter(({ _id }) => _id !== modalEditId)
                                    });
                                    setModalState({ shown: false, editId: null });
                                    resetModalHandler();
                                }
                            })
                            } type='button' variant='danger'>Usuń hospitalizację </Button>}
                        </CenterBox>
                    </Form>
                }
            </Formik>
        </Modal >
    )
};

export default HospitalizationModal;

const Form = styled.form`
    width:450px;
    padding-top:10px;
`;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;

const StyledSelect = styled(Select)`
    >div{
        margin: 2px 0;
    }
`;

const InputRow = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:10px;
`;

const Option = styled.div`
    display:flex;
`;

const OptionCode = styled.span`
    font-weight:600;
    padding-right:5px;
`;

const OptionLabel = styled.span`
   font-weight:normal;
`;

const OptionPlaceholder = styled.span`
   color:#ccc;
`;

const DiagnosisList = styled.ul`
    margin:0 0 20px;
    max-height:135px;
    overflow-y:auto;
    overflow-x: hidden;
`;

const DiagnosisItem = styled.li`
    position:relative;
    font-size:14px;
    padding:5px 25px 5px 5px;
    &:not(:last-child){
        border-bottom: 1px solid ${({ theme }) => theme.grey};
    }
`;

const NoItems = styled.p`
    font-size:14px;
    text-align:center;
`

const Code = styled.span`
    font-size:12px;
    font-weight:600;
    margin-right:5px;
`;

const Name = styled.span`
    font-size:12px;
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;