import React, { useState, useEffect } from 'react';
import Modal from '../../Shared/Modals/Modal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Input from '../../Shared/Input';
import Button from '../../Shared/Button';
import styled from 'styled-components';

const ServicesSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nazwa jest wymagany'),
    price: Yup.number()
        .required('Cena jest wymagana'),
});

const ServicesModal = ({
    servicesModalState: { edit, shown, targetName },
    setServicesModalState,
    updateHealthcenter,
    selectedHealthcenterId,
    services
}) => {
    const [editedServiceData, setEditedServiceData] = useState([]);
    const createHandler = (value) => {
        updateHealthcenter(selectedHealthcenterId, { services: [...services, value] });
        setServicesModalState({ edit: false, shown: false, targetName: null })
    },
        editHandler = (value) => {
            const targetIndex = services.findIndex(el => el.name === targetName),
                updatedServices = [...services];

            updatedServices[targetIndex] = value;

            updateHealthcenter(selectedHealthcenterId, { services: updatedServices });
            setServicesModalState({ edit: false, shown: false, targetName: null })
        };

    let initialValues = {
        name: '',
        price: 0,
        cost: 0
    };
    if (edit && targetName) {
        const {
            name,
            cost,
            price,
        } = editedServiceData;
        initialValues = {
            name,
            cost,
            price,
        };
    }
    useEffect(() => {
        if (services && targetName) {
            setEditedServiceData(services.find(el => el.name === targetName));
        }
    }, [targetName]);
    return (
        <Modal
            title={edit ? 'Edycja procedur' : 'Dodawanie procedury'}
            exitButton={true}
            onCancel={() => setServicesModalState({ edit: false, shown: false, targetName: null })}
            show={shown}>
            <Formik
                initialValues={initialValues}
                validationSchema={ServicesSchema}
                onSubmit={(values) => edit ? editHandler(values) : createHandler(values)}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <InputRow colsTemplate="3fr 1fr 1fr">
                            <Input
                                type="text"
                                name="name"
                                label="Nazwa"
                                onChange={handleChange}
                                value={values.name}
                                error={errors.name}
                            />
                            <Input
                                type="text"
                                name="cost"
                                label="Koszt własny"
                                onChange={(e) => setFieldValue('cost', e.target.value.replace(',', '.'))}
                                value={values.cost}
                                error={errors.cost}
                            />
                            <Input
                                type="text"
                                name="price"
                                label="Cena"
                                onChange={(e) => setFieldValue('price', e.target.value.replace(',', '.'))}
                                value={values.price}
                                error={errors.price}
                            />
                        </InputRow>
                        <CenterBox>
                            <Button type='submit' variant='primary'>{edit ? 'Zapisz zmiany' : 'Dodaj procedure'}</Button>
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default ServicesModal;

const Form = styled.form`
    padding-top:20px;
    width:500px;
    max-height:600px;
`;
const InputRow = styled.div`
    display:grid;
    grid-template-columns: ${({ colsTemplate }) => colsTemplate};
    justify-content:space-between;
    &>div{
        padding: 0 5px;
    }
`;
const CenterBox = styled.div`
    text-align:center;
`;
