import React, { useState, useEffect } from "react";
import DoctorCalendarSidebarView from "./DoctorCalendarSidebarView";
import moment from 'moment';
import { connect } from "react-redux";
import {
  updateCalendarDate,
  updateDoctorsList,
  updateNursesList,
  updateGroupsList
} from "../../../../redux/modules/calendar";
import { addPatient, getCompanyPatients } from "../../../../redux/modules/patients";
import { addVisit, setCurrentVisit } from "../../../../redux/modules/visits";
import { nextTutorialStep } from "../../../../redux/modules/tutorial";

const DoctorCalendarSidebarContainer = ({
  calendarDate,
  updateCalendarDate,
  updateDoctorsList,
  updateNursesList,
  updateGroupsList,
  user,
  patients,
  doctors,
  nurses,
  userRole,
  currentHealthcenter,
  addVisit,
  addPatient,
  visits,
  getCompanyPatients,
  setCurrentVisit,
  currentVisit,
  currentView,
  calendarDoctors,
  calendarNurses,
  calendarGroups,
  localStorageReadFinished,
  nextTutorialStep,
  tutorialActive,
}) => {
  const selectOptions = [{ label: 'Wszystkie', value: 'all' }, { label: 'Potwierdzone', value: 'CONFIRMED' }, { label: 'Niepotwierdzone', value: 'PLANNED' }, { label: 'W poczekalni', value: 'WAITING' }, { label: 'Odwołane', value: 'REJECTED' }]
  const [isCreateModalOn, setCreateModal] = useState();
  const [selectValue, setSelectValue] = useState(selectOptions[0].value ?? 'all')
  const [warningModal, setWarningModal] = useState({ shown: false, question: null, onAccept: null });
  const [sidebarSwitch, setSidebarSwitch] = useState(true);

  const getCompanyPatientsHandler = (searchTerm) => {
    getCompanyPatients(userRole.companyId, 20, 1, searchTerm);
  }
  const [listVisits, setListVisits] = useState([]);

  const filterVisits = (state) => {
    let visitsToSet = visits.filter(({ start }) => {
      if (currentView === 'day') {
        return moment(start).isSame(calendarDate, 'day')
      } else if (currentView === 'week' || currentView === 'work_week') {
        return moment(start).isSame(calendarDate, 'week')
      } else if (currentView === 'month') {
        return moment(start).isSame(calendarDate, 'month')
      } else {
        return false;
      }
    })
    visitsToSet = visitsToSet.sort((a, b) => new Date(a.start) - new Date(b.start))
    visitsToSet.forEach((visit, i, sortedVisits) => {
      if (i + 1 < sortedVisits.length) {
        visit.timeToNext = moment(sortedVisits[i + 1].start).diff(moment(visit.end), 'minutes')
      } else {
        visit.timeToNext = '';
      }
    });

    if (state !== 'all') {
      setListVisits(visitsToSet.filter(visit => visit.state === state))
    } else {
      setListVisits(visitsToSet)
    }
  };

  const prepareDoctorCollapseListData = data => {
    const mappedData = data?.map(item => {
      return {
        ...item,
        name: item.userId.name,
        surname: item.userId?.surname,
        avatar: item.userId.avatar,
        checked: item.checked !== undefined ? item.checked : false
      };
    });
    return mappedData;
  };

  const prepareGroupCollapseListData = data => {
    const mappedData = data?.map(item => {
      return {
        ...item,
        checked: item.checked !== undefined ? item.checked : false
      };
    });
    return mappedData;
  };

  useEffect(() => {
    filterVisits(selectValue);
  }, [visits, calendarDate, selectValue, currentView]);

  useEffect(() => {
    if (
      localStorageReadFinished &&
      doctors &&
      nurses &&
      currentHealthcenter
    ) {
      // read selected doctors and groups from localStorage
      const currentDoctorsData = doctors.filter(doc =>
        currentHealthcenter.doctors.some(id => id === doc._id) ? doc : null
      );
      const currentNursesData = nurses.filter(doc =>
        currentHealthcenter.nurses.some(id => id === doc._id) ? doc : null
      );
      const doctorsWithChecked = currentDoctorsData.map(doctor => {
        return {
          ...doctor,
          checked: calendarDoctors.find(docLC => docLC._id === doctor._id)
            ? calendarDoctors.find(docLC => docLC._id === doctor._id).checked
            : false
        };
      });
      const nursesWithChecked = currentNursesData.map(nurse => {
        return {
          ...nurse,
          checked: calendarNurses.find(docLC => docLC._id === nurse._id)
            ? calendarNurses.find(docLC => docLC._id === nurse._id).checked
            : false
        };
      });
      const groupsWithChecked = currentHealthcenter.groups.map(group => {
        return {
          ...group,
          checked: calendarGroups.find(groupLC => groupLC.name === group.name)
            ? calendarGroups.find(groupLC => groupLC.name === group.name)
              .checked
            : false
        };
      });
      if (doctors.length > 0) {
        updateDoctorsList(doctorsWithChecked);
      }
      if (nurses.length > 0) {
        updateNursesList(nursesWithChecked);
      }
      updateGroupsList(groupsWithChecked);
    }
  }, [localStorageReadFinished, doctors, nurses, currentHealthcenter]);

  useEffect(() => {
    if (nextTutorialStep && tutorialActive && !sidebarSwitch) {
      nextTutorialStep();
    }
  }, [sidebarSwitch]);

  return (
    <DoctorCalendarSidebarView
      calendarDate={calendarDate}
      updateCalendarDate={updateCalendarDate}
      updateDoctorsList={updateDoctorsList}
      updateNursesList={updateNursesList}
      updateGroupsList={updateGroupsList}
      isCreateModalOn={isCreateModalOn}
      setCreateModal={setCreateModal}
      user={user}
      userRole={userRole}
      patients={patients}
      doctors={[...doctors, ...nurses]}
      currentHealthcenter={currentHealthcenter}
      addVisit={addVisit}
      addPatient={addPatient}
      visits={visits}
      setWarningModal={setWarningModal}
      warningModal={warningModal}
      getCompanyPatientsHandler={getCompanyPatientsHandler}
      listVisits={listVisits}
      filterVisits={filterVisits}
      selectOptions={selectOptions}
      selectValue={selectValue}
      setCurrentVisit={setCurrentVisit}
      currentVisit={currentVisit}
      setSelectValue={setSelectValue}
      sidebarSwitch={sidebarSwitch}
      setSidebarSwitch={setSidebarSwitch}
      calendarDoctors={prepareDoctorCollapseListData(calendarDoctors)}
      calendarNurses={prepareDoctorCollapseListData(calendarNurses)}
      calendarGroups={prepareGroupCollapseListData(calendarGroups)}
    />
  );
};

const mapStateToProps = state => ({
  calendarDate: state.calendar.calendarDate,
  user: state.authentication.user,
  patients: state.patients.data,
  doctors: state.employees.doctors,
  nurses: state.employees.nurses,
  currentHealthcenter: state.healthcenter.currentHealthcenter,
  visits: state.visits.data,
  currentVisit: state.visits.currentVisit,
  currentView: state.calendar.currentView,
  calendarDoctors: state.calendar.doctors,
  calendarNurses: state.calendar.nurses,
  calendarGroups: state.calendar.groups,
  localStorageReadFinished: state.calendar.localStorageReadFinished,
  tutorialActive: state.tutorial.active
});

const mapDispatchToProps = dispatch => {
  return {
    updateCalendarDate: date => dispatch(updateCalendarDate(date)),
    addVisit: (visit, force) => dispatch(addVisit(visit, force)),
    addPatient: (companyId, patient) => dispatch(addPatient(companyId, patient)),
    getCompanyPatients: (companyId, pageSize, pageNumber, searchTerm) =>
      dispatch(getCompanyPatients(companyId, pageSize, pageNumber, searchTerm)),
    setCurrentVisit: (visit) => dispatch(setCurrentVisit(visit)),
    updateDoctorsList: doctors => dispatch(updateDoctorsList(doctors)),
    updateNursesList: nurses => dispatch(updateNursesList(nurses)),
    updateGroupsList: groups => dispatch(updateGroupsList(groups)),
    nextTutorialStep: () => dispatch(nextTutorialStep()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorCalendarSidebarContainer);
