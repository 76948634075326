import React, { useState } from "react";
import styled from "styled-components";
import Select from "../Select";
import arrowImg from "../assets/arrow-down.svg";

const Toolbar = ({
  getCalendarLocalStorage,
  onNavigate,
  onView,
  view,
  currentHealthcenter,
  healthcenters,
  setCurrentView,
  setCurrentHealthcenter,
  updateResourceStatus,
  updateRoomsStatus,
  roomsStatus,
  resourceStatus,
  user,
  userRole
}) => {
  const [
    internalCurrentHealthcenter,
    setInternalCurrentHealthcenter
  ] = useState(currentHealthcenter);
  const [internalViewType, setInternalViewType] = useState(resourceStatus);
  const [internalRoomActive, setInternalRoomActive] = useState(roomsStatus);
  const handleChange = event => {
    setCurrentView(event.value);
    onView(event.value);
  };
  const returnWeek = () => {
    if (user?.activeRole === "Doctor") {
      return userRole?.weekendWorker ? "week" : "work_week";
    } else {
      return "week";
    }
  }

  const getLabel = () => {
    return view === "day"
      ? "Dzień"
      : view === "week" || view === "work_week"
        ? "Tydzień"
        : "Miesiąc";
  };

  const customFormatGroupLabel = ({ label }) => (
    <GroupLabel>
      <span>{label}</span>
    </GroupLabel>
  );
  const customOptionLabel = ({ label }) => (
    <Option>
      <span>{label}</span>
    </Option>
  );

  return (
    <ToolbarContainer view={view} id="calendarToolbar">
      <ToolbarSection>
        <ToolbarTodayButton onClick={() => onNavigate("TODAY")}>
          Dzisiaj
        </ToolbarTodayButton>
        <Select
          defaultValue={{ value: "day", label: "Dzień" }}
          value={{ value: view, label: getLabel() }}
          name="viewSelect"
          isSearchable={false}
          options={[
            { value: "day", label: "Dzień" },
            { value: returnWeek(), label: "Tydzień" },
            { value: "month", label: "Miesiąc" }
          ]}
          onChange={handleChange}
        />
      </ToolbarSection>
      <ToolbarNavigation id='calendarNavArrows'>
        <ToolbarArrowButton left onClick={() => onNavigate("PREV")}>
          <ArrowImage left src={arrowImg} />
        </ToolbarArrowButton>

        <ToolbarArrowButton right onClick={() => onNavigate("NEXT")}>
          <ArrowImage src={arrowImg} />
        </ToolbarArrowButton>
      </ToolbarNavigation>
      <ToolbarSection>
        {/* {user.activeRole !== "Doctor" && <ToolbarSelect
          value={
            internalViewType
              ? { value: true, label: "Rozdzielony" }
              : { value: false, label: "Łączony" }
          }
          name="viewTypeSelect"
          isSearchable={false}
          isDisabled={view === "month" || internalRoomActive ? true : false}
          options={[
            { value: false, label: "Łączony" },
            { value: true, label: "Rozdzielony" }
          ]}
          onChange={event => {
            updateResourceStatus(event.value);
            setInternalViewType(event.value);
          }}
        />} */}
        {user.activeRole !== "Doctor" ? <ToolbarSelect
          value={
            internalViewType
              ? internalRoomActive ? { value: "Gabinet", label: "Roździelony - Gabinet" } : { value: "Lekarz", label: "Roździelony - Lekarz" }
              : { value: "Łączony", label: "Łączony" }
          }
          name="viewTypeSelect"
          isSearchable={false}
          options={[
            { label: 'Łączony', value: 'Łączony' },
            {
              label: "Rozdzielony", options: [
                { label: 'Gabinet', value: 'Gabinet' },
                { label: 'Lekarz', value: 'Lekarz' },
              ]
            }
          ]}
          onChange={({ value }) => {
            if (value === "Łączony") {
              updateResourceStatus(false);
              setInternalViewType(false);
            } else if (value === "Gabinet" || value === "Lekarz") {
              updateResourceStatus(true);
              setInternalViewType(true);
              if (value === "Gabinet") {
                updateRoomsStatus(true);
                setInternalRoomActive(true);
              } else if (value === "Lekarz") {
                updateRoomsStatus(false);
                setInternalRoomActive(false);
              }
            }
          }}
          customFormatGroupLabel={customFormatGroupLabel}
          customFormatOptionLabel={customOptionLabel}
        />
          :
          <ToolbarSelect
            defaultValue={{ value: false, label: "Lekarz" }}
            value={
              internalRoomActive
                ? { value: true, label: "Gabinet" }
                : { value: false, label: "Lekarz" }
            }
            name="viewTypeSelect"
            isSearchable={false}
            isDisabled={user.activeRole !== "Doctor" ? !internalViewType || view === "month" ? true : false : false}
            options={[
              { value: false, label: "Lekarz" },
              { value: true, label: "Gabinet" }
            ]}
            onChange={e => {
              updateRoomsStatus(e.value);
              setInternalRoomActive(e.value);
              if (user.activeRole === "Doctor") {
                updateResourceStatus(e.value);
                setInternalViewType(e.value);
              }
            }}
          />
        }
        {/*  */}
        <ToolbarSelect
          value={{
            label: internalCurrentHealthcenter?.name,
            value: internalCurrentHealthcenter?._id
          }}
          name="currentHealthcenter"
          placeholder="Wybierz placówkę..."
          options={healthcenters?.map(hc => {
            return { label: hc.name, value: hc._id };
          })}
          onChange={({ value }) => {
            const currentHC = healthcenters.find(hc => hc._id === value);
            setCurrentHealthcenter(currentHC);
            setInternalCurrentHealthcenter(currentHC);
            getCalendarLocalStorage(userRole.userId);
          }}
          noOptionsMessage={() => "Nie znaleziono placówki"}
        />
      </ToolbarSection>
    </ToolbarContainer>
  );
};

export default Toolbar;

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.background};
  padding: 5px 0 20px;
  ${({ view }) => (view === "day" || view === "month") && `
  padding: 5px 0 5px;
  `}
`;

const ToolbarTodayButton = styled.button`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0.6em 1.5em;
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  user-select: none;
  height: 38px;
  width: 100px;
  margin-right: 10px;
`;

const ToolbarArrowButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  border: none;
  z-index:9;
  position:relative;
  padding: 0;
`;

const ToolbarSection = styled.div`
  display: flex;
`;

const ArrowImage = styled.img`
  display: block;
  padding: 7px;
  width: 30px;
  height: 30px;
  transition: 0.25s;
  transform: rotate(-90deg);
  ${({ left }) =>
    left &&
    `
  transform: rotate(90deg);
  `}
  &:hover {
    background-color: #e3e3e3;
    border-radius: 50%;
  }
`;

const ToolbarSelect = styled(Select)`
  width: 150px;
  margin-left: 10px;
`;

const GroupLabel = styled.div`
  text-transform:none;
  font-size:14px;
  font-weight:normal;
  border-bottom:1px solid #ccc;
  margin: 0 -2px;
  padding: 5px 2px;
`;

const Option = styled.div`
  span{
    font-size:14px;
    color:inherit;
  }
`;

const ToolbarNavigation = styled.div`
  width:200px;
  display:flex;
  justify-content:space-between;
`;