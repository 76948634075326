import React, { useState } from 'react';
import styled from 'styled-components';
import { GithubPicker } from "react-color";
import OutsideAlerter from './OutsideAlerter';

const ColorPicker = ({
    currentColor,
    colors,
    onChange,
    label,
    width,
}) => {
    const [isColorPickerActive, setColorPickerActive] = useState(false);
    return (
        <ColorSectionWrapper
            isModalAlerterOpen={isColorPickerActive}
            setModalAlerterOpen={setColorPickerActive}
        >
            <Label>{label}</Label>
            <OutsideAlerter
                isModalAlerterOpen={isColorPickerActive}
                setModalAlerterOpen={setColorPickerActive}
            >
                <ColorSquare
                    color={currentColor}
                    onClick={() => setColorPickerActive(true)}
                    width={width}
                />
                {isColorPickerActive && (
                    <StyledGithubPicker
                        colors={colors}
                        onChangeComplete={(event) => {
                            onChange(event);
                            setColorPickerActive(false);
                        }}
                    />
                )}
            </OutsideAlerter>
        </ColorSectionWrapper>
    )
}

export default ColorPicker;

const StyledGithubPicker = styled(GithubPicker)`
  position: absolute !important;
  z-index: 999;
  margin-left: 2px;
`;

const ColorSectionWrapper = styled.div`
  position: relative;
`;

const ColorSquare = styled.div`
  width: ${({ width }) => width ? width : '30px'};
  height: 30px;
  background-color: ${({ color }) => `${color}`};
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
`;

const Label = styled.p`
    margin-left: 2px;
    display: block;
    color: ${({ theme }) => theme.black};
    font-size: 14px;
`;