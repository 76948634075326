import React from "react";
import styled from "styled-components";
import Container from "../../../../Shared/Container";
import Button from "../../../../Shared/Button";
import moment from "moment";
import ExaminationModal from "../../modals/ExaminationModal";
import CheckIcon from "../../../../Shared/assets/check.svg";
import RejectIcon from "../../../../Shared/assets/reject.svg";
import EditPenIconUnderline from "../../../../Shared/assets/edit-pen-underline.svg";
import DeleteModal from "../../modals/DeleteModal";

const ExaminationsTabView = ({
  currentTab,
  patientExaminations,
  setExaminationModal,
  examinationModal,
  addExaminationHandler,
  updateExaminationHandler,
  deleteModalHandler,
  factorsSearchByNameHandler,
  examinationFactors,
  examinationPatterns,
  factorsLoading,
  deleteModal,
  setDeleteModal,
  deleteExaminationPattern,
  nextTutorialStep
}) => {
  const factors = patientExaminations.map((exam) =>
    exam.factors.map(({ factor: { name, _id } }) => ({ name, _id }))
  );
  const merged = [].concat.apply([], factors);
  const allFactors = merged.filter(
    (el, i) => merged.findIndex((it) => it.name === el.name) === i
  );

  return (
    <Wrapper>
      <ExaminationModal
        modalState={examinationModal}
        setModalState={setExaminationModal}
        examinationFactors={examinationFactors}
        addExaminationHandler={addExaminationHandler}
        updateExaminationHandler={updateExaminationHandler}
        deleteModalHandler={deleteModalHandler}
        examinationPatterns={examinationPatterns}
        patientExaminations={patientExaminations}
        factorsSearchByNameHandler={factorsSearchByNameHandler}
        factorsLoading={factorsLoading}
        deleteExaminationPattern={deleteExaminationPattern}
        nextTutorialStep={nextTutorialStep}
      />
      <DeleteModal
        modalState={deleteModal.shown}
        setModalState={setDeleteModal}
        onAccept={deleteModal.onAccept}
        question={deleteModal.question}
      />
      <StyledContainer title="Wszystkie badania">
        <Button
          variant="add"
          title='Dodaj badanie'
          onClick={() => {
            setExaminationModal({ shown: true, editId: null })
            if (nextTutorialStep) {
              nextTutorialStep();
            }
          }}
        />
        {patientExaminations?.length > 0 ? (
          <ExamiantionsList>
            <Factors>
              {allFactors.map(({ name }) => {
                return <Factor key={name}>{name}</Factor>;
              })}
            </Factors>
            {patientExaminations.map(({ examinationDate, factors, _id }) => {
              const momentDate = moment(examinationDate, moment.ISO_8601);
              return (
                <ExamiantionItem key={_id}>
                  <EditPenUnderline
                    src={EditPenIconUnderline}
                    onClick={() =>
                      setExaminationModal({ shown: true, editId: _id })
                    }
                  />
                  <ExaminationHead>
                    <p>{momentDate.format("YYYY-MM-DD")}</p>
                    <p>{momentDate.format("HH:mm")}</p>
                    <p>{momentDate.fromNow()}</p>
                  </ExaminationHead>
                  <ExaminationResults>
                    {allFactors.map(({ _id }) => {
                      const result = factors.find(
                        (factor) => factor.factor._id === _id
                      );
                      if (result) {
                        const {
                          quantity,
                          lowerRange,
                          upperRange,
                          unit,
                        } = result;
                        return (
                          <ResultBox key={_id}>
                            <Result
                              variant={
                                quantity >= lowerRange && quantity <= upperRange
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {`${quantity} ${unit}`}
                            </Result>
                          </ResultBox>
                        );
                      } else {
                        return <ResultBox key={_id}>---</ResultBox>;
                      }
                    })}
                  </ExaminationResults>
                </ExamiantionItem>
              );
            })}
          </ExamiantionsList>
        ) : (
            <p>Brak badań dla tego pacjenta</p>
          )}
      </StyledContainer>
    </Wrapper>
  );
};

export default ExaminationsTabView;

const Wrapper = styled.div`
  height: 100%;
`;

const EditPenUnderline = styled.img`
  transition: 300ms;
  position: absolute;
  top: 7.5px;
  right: 7.5px;
  width: 17.5px;
  height: 17.5px;
  cursor: pointer;
  filter: invert(81%) sepia(0%) saturate(51%) hue-rotate(195deg) brightness(86%)
    contrast(81%);
  &:hover {
    filter: invert(98%) sepia(0%) saturate(476%) hue-rotate(144deg)
      brightness(89%) contrast(81%);
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const Factors = styled.ul`
  padding-top: 70px;
`;

const Factor = styled.li`
  height: 35px;
  font-size: 14px;
  display: flex;
  max-width: 300px;
  align-items: center;
  padding-right: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const ExamiantionsList = styled.ul`
  display: flex;
  margin: 0;
  height: calc(100vh - 365px);
  overflow: scroll;
`;

const ExamiantionItem = styled.li`
  position: relative;
  width: 150px;
  padding: 10px 0;
  transition: 300ms;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const ExaminationHead = styled.div`
  padding: 0 10px 10px;
  height: 60px;
  width: 100%;
  text-align: center;
  > p {
    font-weight: 600;
    font-size: 12px;
    color: ${({ theme }) => theme.lightBlack};
  }
`;

const ExaminationResults = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ResultBox = styled.li`
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const Result = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding: 7px;
  padding: 0 10px;
  line-height: 0;
  width: auto;
  font-size: 14px;
  font-weight: 600;
  margin: 7px;
  border-radius: 15px;
  ${({ theme, variant }) =>
    variant === "success"
      ? `color:${theme.green}; background-color:${theme.lightGreen};`
      : variant === "danger"
        ? `color:${theme.danger}; background-color:${theme.lightDanger};`
        : ``}
  &::before {
    ${({ variant }) =>
    variant === "success"
      ? `content: url(${CheckIcon}); filter: invert(60%) sepia(90%) saturate(330%) hue-rotate(70deg) brightness(95%) contrast(86%);`
      : variant === "danger"
        ? `content: url(${RejectIcon}); filter: invert(61%) sepia(27%) saturate(7147%) hue-rotate(323deg) brightness(81%) contrast(90%);`
        : ``};
    margin-right: 3px;
  }
`;
