import io from "socket.io-client";
import userEventsHandler from './user/eventsHandler'
import calendarEventsHandler from './calendar/eventsHandler'
import patientsEventsHandler from './patients/eventsHandler'
import chatEventsHandler from './chat/eventsHandler'
import {setCalendarSocket} from '../redux/modules/application'

import userEventsEmitters from './user/eventsEmitters'
import calendarEventsEmitters from './calendar/eventsEmitters'
import patientEventsEmitters from './patients/eventsEmitters'

export const socketMiddleware = url => {
    return storeAPI => {
        const socket = io(url);
        userEventsHandler(socket,storeAPI)
        calendarEventsHandler(socket,storeAPI)
        patientsEventsHandler(socket,storeAPI)
        chatEventsHandler(socket,storeAPI)
        //Middleware
        return next => action => {
            switch(action.type){
                //user event emitters
                case "USER_ROLE_SUCCESS" : {
                    const currentCompanyId = action.payload.companyId
                    const currentHealthcentersIds = action.payload.healthCenters
                    socket.emit('join', `patients-${currentCompanyId}`)
                    socket.emit('join',`chat-${currentCompanyId}`)
                    if(currentHealthcentersIds){
                        currentHealthcentersIds.forEach(healthCenterId => socket.emit('join',`chat-${healthCenterId}`))
                    }
                    return next(action);
                }
                case "SET_CURRENT_HEALTHCENTER":{
                    const currentState = storeAPI.getState();
                    const currentCalendarSocket = currentState.application.calendarSocket
                    const currentHealthcenterId = action.payload._id
                    socket.emit('join', `calendar-${currentHealthcenterId}`)
                    if (currentCalendarSocket){
                        socket.emit('leave',`${currentCalendarSocket}`)
                    }
                    storeAPI.dispatch(setCalendarSocket(`calendar-${currentHealthcenterId}`))
                    return next(action);
                }
                case "LOGOUT_USER" : {
                    const currentState = storeAPI.getState();
                    const currentCompanyId = currentState.authentication.userRole?.companyId;
                    const currentHealthcentersIds = currentState.authentication.userRole?.healthCenters
                    const currentRole = currentState.authentication.user?.activeRole
                    const currentPatientId = storeAPI.getState().patients.currentPatient._id
                    if(currentHealthcentersIds){
                        currentHealthcentersIds.forEach(healthCenterId => socket.emit('leave',`chat-${healthCenterId}`))
                    }
                    socket.emit('leave',`chat-${currentCompanyId}`)
                    socket.emit('leave', `patients-${currentCompanyId}`)
                    socket.emit('leave', `calendar-${currentPatientId}`)
                    if(currentRole === 'Doctor'){
                        const userId = currentState.authentication.userRole._id
                        socket.emit('leave', `calendar-${userId}`)
                    }
                    if(currentRole === 'Registry'){
                        const currentHealthcenterId = currentState.healthcenter.currentHealthcenter?._id
                        socket.emit('leave', `calendar-${currentHealthcenterId}`)
                    }
                    return next(action);
                }
                case "SET_CURRENT_PATIENT" : {
                    const currentState = storeAPI.getState();
                    const currentCalendarSocket = currentState.application.calendarSocket
                    socket.emit('join', `calendar-${action.payload}`)
                    if (currentCalendarSocket){
                        socket.emit('leave',`${currentCalendarSocket}`)
                    }
                    storeAPI.dispatch(setCalendarSocket(`calendar-${action.payload}`))
                    return next(action)
                }
                case "CALENDAR_PAGE_LOADED" : {
                    const currentCalendarSocket = storeAPI.getState().application.calendarSocket
                    const currentHealthcenterId = storeAPI.getState().authentication.userRole.healthCenters[0]
                    socket.emit('join',`calendar-${currentHealthcenterId}`)
                    if (currentCalendarSocket){
                        socket.emit('leave',`${currentCalendarSocket}`)
                    }
                    storeAPI.dispatch(setCalendarSocket(`calendar-${currentHealthcenterId}`))
                    return next(action);
                }
                case "DASHBOARD_PAGE_LOADED" : {
                    const currentCalendarSocket = storeAPI.getState().application.calendarSocket
                    const currentDoctorId = storeAPI.getState().authentication.userRole._id
                    socket.emit('join',`calendar-${currentDoctorId}`)
                    if (currentCalendarSocket){
                        socket.emit('leave',`${currentCalendarSocket}`)
                    }
                    storeAPI.dispatch(setCalendarSocket(`calendar-${currentDoctorId}`))
                    return next(action);
                }
                case "PATIENTS_PAGE_LOADED" : {
                    const currentCalendarSocket = storeAPI.getState().application.calendarSocket
                    const currentPatient = storeAPI.getState().patients.currentPatient._id
                    socket.emit('join',`calendar-${currentPatient}`)
                    if (currentCalendarSocket){
                        socket.emit('leave',`${currentCalendarSocket}`)
                    }
                    storeAPI.dispatch(setCalendarSocket(`calendar-${currentPatient}`))
                    return next(action);
                }
                //calendar event emitters
                case "ADD_VISIT_SUCCESS" : {
                    socket.emit('visit-add',action.payload)
                    return next(action)
                }
                case "UPDATE_VISIT_SUCCESS" : {
                    const currentState = storeAPI.getState();
                    let updatedVisit = currentState.visits.data.find(visit => visit._id === action.payload._id)
                    console.log('To jest updatedVisit : ', updatedVisit)
                    if(!updatedVisit){
                        console.log('Wpadlo w ifa')
                        updatedVisit = currentState.visits.currentVisit
                    }
                    socket.emit('visit-edit', {visit: action.payload, oldDoctorId: updatedVisit.doctor, oldPatientId: updatedVisit.patient._id})
                    return next(action)
                }
                //patients event emitters
                case "ADD_PATIENT_SUCCESS" : {
                    const companyId = storeAPI.getState().authentication.userRole.companyId;
                    socket.emit('patient-add', {patient: action.payload, companyId: companyId})
                    return next(action);
                }
                case "UPDATE_PATIENT_SUCCESS" : {
                    const companyId = storeAPI.getState().authentication.userRole.companyId;
                    socket.emit('patient-edit', {patient: action.payload, companyId: companyId})
                    return next(action);
                }
                case "ADD_MESSAGE_SUCCESS": {
                    socket.emit('message-add', {message: action.payload, channel: action.payload.channel})
                    return next(action);
                }
            }
            // userEventsEmitters(next,action,storeAPI,socket)
            // calendarEventsEmitters(next,action,storeAPI,socket)
            // patientEventsEmitters(next,action,storeAPI,socket)
            return next(action);
        };
    };
};