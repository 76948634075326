import React, { useEffect } from "react";
import { connect } from "react-redux";
import AppRouter from "./features/AppRouter/AppRouter";
import { theme } from "./features/Shared/theme";
import { ThemeProvider } from "styled-components";
import { checkToken } from "./redux/modules/authentication";
import { Helmet } from "react-helmet";
import { DndProvider } from 'react-dnd';
import { Swipeable } from 'react-swipeable';
import { setSidebarActive } from './redux/modules/navigation';
import Tutorial from './features/Tutorial/Tutorial';

import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = ({ checkToken, sidebarActive, setSidebarActive }) => {
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MedManager</title>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700,800|Roboto:400,500,700&display=swap" rel="stylesheet" />
      </Helmet>
      <DndProvider options={HTML5toTouch} backend={MultiBackend}>
        <Swipeable
          onSwipedRight={({ event }) => {
            const pathClassNames = event.path.map(({ className }) => className).join(' ');
            if (!pathClassNames.includes('rbc-month-row') && !pathClassNames.includes('rbc-slot-selecting') && !sidebarActive) {
              setSidebarActive(true);
            }
          }}
          onSwipedLeft={() => sidebarActive && setSidebarActive(false)}
        >
          <Tutorial />
          <AppRouter
            sidebarActive={sidebarActive}
            setSidebarActive={setSidebarActive}
          />
          <ToastContainer
            position={"bottom-center"}
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            limit={3}
            closeButton={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Swipeable>
      </DndProvider>
    </ThemeProvider >
  );
};

const mapStateToProps = state => ({
  sidebarActive: state.navigation.sidebarActive,
});

const mapDispatchToProps = dispatch => {
  return {
    checkToken: () => dispatch(checkToken(sessionStorage.getItem("jwtToken"))),
    setSidebarActive: (payload) => dispatch(setSidebarActive(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);