import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DocumentsTabView from "./DocumentsTabView";
import { withRouter, useParams } from "react-router-dom";
import {
  getPatientDocuments,
  addPatientDocument,
  deletePatientDocument,
  updatePatientDocument,
} from "../../../../../redux/modules/documents";
import {
  getPatientMedicalVisits,
} from "../../../../../redux/modules/medicalVisit";

const DocumentsTabContainer = ({
  patientDocuments,
  patientMedicalVisits,
  getPatientDocuments,
  getPatientMedicalVisits,
  addPatientDocument,
  deletePatientDocument,
  updatePatientDocument,
  location: { pathname, search },
  history,
  currentPatient,
  user,
  nextTutorialStep
}) => {
  const [documentsModal, setDocumentsModal] = useState({
    shown: false,
    editId: null,
  });
  const [deleteModal, setDeleteModal] = useState({
    shown: false,
    question: null,
    onAccept: null,
  }),
    [documentsList, setDocumentsList] = useState([]),
    [medicalVisitId, setMedicalVisitId] = useState(''),
    [queryDocumentId, setQueryDocumentId] = useState(null);

  const { currentTab, patientId } = useParams();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const targetDocumentId = params.get('document');

    if (targetDocumentId && queryDocumentId !== targetDocumentId) {
      setQueryDocumentId(targetDocumentId);
    } else if (!targetDocumentId && queryDocumentId !== null) {
      setQueryDocumentId(null);
    }
  }, [patientDocuments, search, pathname])

  useEffect(() => {
    const target = patientDocuments.find(el => el._id === queryDocumentId);
    if (target && !target.path.includes('photos')) {
      window.open(`${process.env.REACT_APP_SRV_URL}${target.path}`, '_blank', 'noopener,noreferrer');
      history.push(pathname);
    }
  }, [queryDocumentId, patientDocuments]);

  useEffect(() => {
    if (currentTab === "documents" && currentPatient._id === patientId) {
      getPatientDocuments(currentPatient._id);
      getPatientMedicalVisits(currentPatient._id);
    }
  }, [currentPatient, pathname]);

  useEffect(() => {
    if (medicalVisitId) {
      setDocumentsList(patientDocuments.filter(el => el.medicalVisit === medicalVisitId));
    } else {
      setDocumentsList(patientDocuments);
    }
  }, [medicalVisitId, patientDocuments]);

  const deleteModalHandler = (targetId = documentsModal.editId) => {
    setDeleteModal({
      question: "Czy na pewno chcesz usunąć ten dokument?",
      shown: true,
      onAccept: () => deletePatientDocument(targetId),
    });
  };

  const addPatientDocumentHandler = (document) => {
    const formData = new FormData();
    formData.append("userId", user._id);
    formData.append("patientId", currentPatient._id);
    formData.append("originalName", document.originalName);
    formData.append("uploadDate", document.uploadDate);
    formData.append("description", document.description);
    formData.append("document", document.file);
    addPatientDocument(formData);
  };

  const updatePatientDocumentHandler = (document) => {
    updatePatientDocument(
      { description: document.description },
      documentsModal.editId
    );
  };

  return (
    <DocumentsTabView
      currentTab={currentTab}
      documentsModal={documentsModal}
      setDocumentsModal={setDocumentsModal}
      deleteModalHandler={deleteModalHandler}
      addPatientDocumentHandler={addPatientDocumentHandler}
      updatePatientDocumentHandler={updatePatientDocumentHandler}
      documentsList={documentsList}
      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      patientMedicalVisits={patientMedicalVisits}
      medicalVisitId={medicalVisitId}
      setMedicalVisitId={setMedicalVisitId}
      queryDocumentId={queryDocumentId}
      patientDocuments={patientDocuments}
      nextTutorialStep={nextTutorialStep}
    />
  );
};

const mapStateToProps = (state) => ({
  currentPatient: state.patients.currentPatient,
  user: state.authentication.user,
  patientDocuments: state.documents.patientDocuments,
  patientMedicalVisits: state.medicalVisit.patientMedicalVisits,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPatientDocuments: (patientId) =>
      dispatch(getPatientDocuments(patientId)),
    addPatientDocument: (document) => dispatch(addPatientDocument(document)),
    deletePatientDocument: (documentId) =>
      dispatch(deletePatientDocument(documentId)),
    updatePatientDocument: (document, documentId) =>
      dispatch(updatePatientDocument(document, documentId)),
    getPatientMedicalVisits: (patientId) =>
      dispatch(getPatientMedicalVisits(patientId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DocumentsTabContainer));
