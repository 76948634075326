import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { default as ReactSelect } from 'react-select';
import { theme } from '../Shared/theme'
import Label from "./Label";

const Select = forwardRef(({
  name,
  options,
  onChange,
  value,
  placeholder,
  className,
  isDisabled,
  isLoading,
  isClearable,
  isMulti,
  styles = {},
  error,
  label,
  noOptionsMessage,
  isSearchable,
  onInputChange,
  center,
  searchInputWithoutFilter,
  customFormatOptionLabel,
  customFilterOption,
  defaultValue,
  hideError,
  customFormatGroupLabel,
  menuPosition,
  inputValue,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const customStyles = {
    container: (provided, { isDisabled }) => ({
      ...provided,
      outline: 'none',
      transition: '300ms',
      opacity: isDisabled && '.8',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ...styles.container,
    }),
    control: (provided, { isFocused, isDisabled }) => ({
      ...provided,
      border: error ? `1px solid ${theme.danger}` : isFocused ? `1px solid ${theme.primary}` : `1px solid rgb(204, 204, 204)`,
      background: isDisabled ? '#fafafa' : '#fff',
      boxShadow: isFocused ? 'none' : 'none',
      fontSize: '14px',
      margin: '0 auto 2px',
      ...styles.control,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#aeaeae',
      ...styles.placeholder,
    }),
    input: (provided) => ({
      ...provided,
      ...styles.input,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
      ...styles.dropdownIndicator,
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      ...styles.loadingIndicator,
    }),
    menu: (provided) => ({
      ...provided,
      ...styles.menu,
      zIndex: 100,
    }),
    option: (provided, { isFocused, isSelected, isDisabled }) => ({
      ...provided,
      backgroundColor: isFocused ? '#D9D9D9' : null,
      fontWeight: isSelected ? '600' : 'normal',
      color: '#212121',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      opacity: isDisabled ? .5 : 1,
      ...styles.option,
    }),
    group: (provided) => ({
      ...provided,
      paddingBottom: '0',
      '& >div:last-child': {
        padding: '2px 20px',
        '& >div': {
          fontSize: '12px',
          padding: '5px',
        }
      },
      ...styles.menu,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1,
        transition = 'opacity 300ms',
        color = "#aeaeae",
        fontWeight = "600";
      return { ...provided, opacity, transition, color, fontWeight };
    }
  };

  const formatGroupLabel = data => (
    <GroupLabel>
      <span>{data.label}</span>
    </GroupLabel>
  );

  const searchWithoutFilter = () => {
    return true;
  };

  const [inputValueState, setInputValueState] = useState('');

  const onInputChangeHandler = (value, { action }) => {
    if (onInputChange && action !== 'input-blur' && value !== inputValueState) {
      onInputChange(value);
      setInputValueState(value)
    }
  }

  return (
    <SelectWrapper className={className} label={label} center={center}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <ReactSelect
        ref={ref}
        escapeClearsValue={false}
        id={name}
        name={name}
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        closeMenuOnScroll={(e) => {
          if (!e.target.className?.includes('-MenuList') && menuPosition === 'fixed') {
            setIsOpen(false);
          }
        }}
        options={options}
        onChange={onChange}
        value={value}
        placeholder={placeholder ? placeholder : 'Wybierz...'}
        className={className}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={isMulti}
        menuPosition={menuPosition}
        styles={{ ...customStyles, ...styles }}
        noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => 'Nie znaleziono takiej opcji'}
        isSearchable={isSearchable}
        inputValue={inputValue}
        onInputChange={onInputChangeHandler}
        formatGroupLabel={customFormatGroupLabel ? customFormatGroupLabel : formatGroupLabel}
        loadingMessage={() => <p>Wczytywanie...</p>}
        filterOption={customFilterOption ? customFilterOption : searchInputWithoutFilter && searchWithoutFilter}
        formatOptionLabel={customFormatOptionLabel && customFormatOptionLabel}
        defaultValue={defaultValue}
      />
      {!hideError && <ErrorInfo error={error}>{error}</ErrorInfo>}
    </SelectWrapper>
  )
})

export default Select;

const SelectWrapper = styled.div`
    display:block;
    flex-direction:row;
    min-width: 100px;
    ${({ label }) => !label && `
      height: 38px;`
  }
    ${({ center }) => center && `
      margin: 0 auto;
    `}
`;

const ErrorInfo = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.danger};
  text-align: right;
  margin-bottom: 5px;
  visibility: hidden;
  ${({ error }) =>
    error
      ? `
    visibility: visible;`
      : `
    height: 13px;
    `}
`;


const GroupLabel = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-bottom:1px solid #ccc;
  padding:5px 0;
`;