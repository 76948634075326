import React from 'react';
import Modal from '../../Shared/Modals/Modal';

const WarningModal = ({
    modalState: { onAccept, question, shown },
    setModalState,
}) => {
    return (
        <Modal
            type="SimpleQuestionModal"
            show={shown}
            question={question}
            acceptText="Tak"
            cancelText="Nie"
            onAccept={() => {
                onAccept();
                setModalState({ shown: false, question: null, onAccept: null });
            }}
            onCancel={() => setModalState({ shown: false, question: null, onAccept: null })}
        />
    )
}

export default WarningModal;