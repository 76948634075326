import React from 'react';
import styled from 'styled-components';

const Spinner = ({ size, minHeight }) => {
  return (
    <SpinnerWrapper minHeight={minHeight}>
      <Loader size={size}>Loading...</Loader>
    </SpinnerWrapper>
  )
}

export default Spinner;

const Loader = styled.div`
    font-size: 10px;
    margin: auto;
    text-indent: -9999em;
    width: ${({ size }) => size ? size : '11em'};
    height: ${({ size }) => size ? size : '11em'};
    border-radius: 50%;
    background: ${({ theme }) => theme.primary};
    background: -moz-linear-gradient(left, ${({ theme }) => theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, ${({ theme }) => theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, ${({ theme }) => theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, ${({ theme }) => theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, ${({ theme }) => theme.primary} 10%, rgba(255, 255, 255, 0) 42%);
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    &:before {
      width: 50%;
      height: 50%;
      background: ${({ theme }) => theme.primary};
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }
    &:after {
      background:#fff;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    @-webkit-keyframes load3 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load3 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
`;

const SpinnerWrapper = styled.div`
    width:100%;
    min-height:${({ minHeight }) => minHeight ? minHeight : '200px'};
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
`;