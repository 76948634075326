import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/pl";
import { withRouter } from 'react-router-dom';
import DoctorCalendarSidebarContainer from './Calendar/DoctorCalendarSidebarContainer';
import DoctorDashboardSidebarContainer from './Dashboard/DoctorDashboardSidebarContainer';
import DoctorPatientsSidebarContainer from './Patients/DoctorPatientsSidebarContainer';



const DoctorSidebarContainer = ({ 
  userRole,
  location: { pathname }
 }) => {

  const [currentDate, setCurrentDate] = useState({
    weekStart: moment().startOf('week').toDate(),
    weekEnd: moment().endOf('week').toDate(),
    monthStart: moment().startOf('month').toDate(),
    monthEnd: moment().endOf('month').toDate(),
    dayStart: moment().startOf('day').toDate(),
    dayEnd: moment().endOf('day').toDate()
  });



  return (
    <>
    {pathname.startsWith('/patients') ?
      <DoctorPatientsSidebarContainer />
      : pathname === '/' ?
        <DoctorDashboardSidebarContainer userRole={userRole} currentDate={currentDate} />
        : pathname.startsWith('/calendar') ? 
          <DoctorCalendarSidebarContainer userRole={userRole}/> : ''
    }
  </>
  )
};

const mapStateToProps = state => ({
  userRole: state.authentication.userRole,

});

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DoctorSidebarContainer));
