import React from 'react'
import Modal from '../../../Shared/Modals/Modal';

const DeleteModal = ({
    modalState,
    setModalState,
    onAccept,
    question
}) => {

    return (
        <Modal
            type='SimpleQuestionModal'
            reverseButtonColors={true}
            show={modalState}
            question={question}
            acceptText='Usuń'
            cancelText='Anuluj'
            onAccept={() => { onAccept(); setModalState({ shown: false, question: null, onAccept: null })}}
            onCancel={() => setModalState(false)}
        />
    )
}

export default DeleteModal;