import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Input from '../../../Shared/Input';
import Button from '../../../Shared/Button';
import Textarea from '../../../Shared/Textarea';
import DatePicker from '../../../Shared/DatePicker';
import * as Yup from 'yup';
import { Formik } from 'formik';

const ProceduresModal = ({
    modalState: { shown: modalShown },
    setModalState,
    updatePatientSurveysHandler,
    patientSurveys,
    setSurveysModal
}) => {
    const validationSchema = Yup.object().shape({
        pediatricSurvey: Yup.object().shape({
            apgarScore: Yup.number()
                .typeError('Wynik musi być numerem')
                .min(0, 'Skala Apgar musi być między 0 - 10')
                .max(10, 'Skala Apgar musi być między 0 - 10'),
            birthMass: Yup.number().typeError('Masa musi być numerem')
            .max(10000, 'Masa urodzeniowa jest za duża')
            .min(0, 'Masa urodzeniowa nie może być mniejsza niż 0'),
            birthLength: Yup.number().typeError('Długość musi być numerem')
            .max(1000, 'Długość ciała jest za duża')
            .min(0, 'Długość ciała nie może być mniejsza niż 0'),
            birthHeadCircuit: Yup.number().typeError('Obwód musi być numerem')
            .max(100, 'Obwód głowy jest za duży')
            .min(0, 'Obwód głowy nie może być mniejszy niż 0'),
        }),
        environmentalSurvey: Yup.object().shape({
            barthelScore: Yup.number().typeError('Wynik musi być numerem')
                .min(0, 'Skala Barthel musi być między 0 - 100')
                .max(100, 'Skala Barthel musi być między 0 - 100'),
        }),
    });
    const { environmentalSurvey, pediatricSurvey, perinatalSurvey, familySurvey } = patientSurveys;
    return (
        <Modal
            title='Edycja wywiadów'
            exitButton={true}
            onCancel={() => { setModalState(false) }}
            show={modalShown === 'surveys'}
        >
            <Formik
                onSubmit={(values) => {
                    updatePatientSurveysHandler(values)
                    setSurveysModal({ shown: false, editId: null })
                }}
                validationSchema={validationSchema}
                initialValues={{
                    pediatricSurvey: {
                        apgarScore: pediatricSurvey?.apgarScore ?? '',
                        birthMass: pediatricSurvey?.birthMass ?? '',
                        birthLength: pediatricSurvey?.birthLength ?? '',
                        birthHeadCircuit: pediatricSurvey?.birthHeadCircuit ?? '',
                        foodIntoleranceCheck: pediatricSurvey?.foodIntolerance ? true : false,
                        foodIntolerance: pediatricSurvey?.foodIntolerance ?? '',
                        developmentalDisordersCheck: pediatricSurvey?.developmentalDisorders ? true : false,
                        developmentalDisorders: pediatricSurvey?.developmentalDisorders ?? '',
                        feedingCheck: pediatricSurvey?.feeding ? true : false,
                        feeding: pediatricSurvey?.feeding ?? '',
                        additionalNotesCheck: pediatricSurvey?.additionalNotes ? true : false,
                        additionalNotes: pediatricSurvey?.additionalNotes ?? '',
                    },
                    environmentalSurvey: {
                        environmentalCare: environmentalSurvey?.environmentalCare ?? false,
                        careEndDate: environmentalSurvey?.careEndDate ? environmentalSurvey?.careEndDate : Date.now(),
                        barthelScore: environmentalSurvey?.barthelScore ?? '',
                        additionalNotes: environmentalSurvey?.additionalNotes ?? '',
                    },
                    familySurvey: familySurvey ?? '',
                    perinatalSurvey: perinatalSurvey ?? '',
                }}
            >
                {({ values, errors, handleChange, handleSubmit, setFieldValue, setValues }) => (
                    <Form onSubmit={handleSubmit}>
                        <Section>
                            <InputRow>
                                <Input
                                    type="text"
                                    name='pediatricSurvey.apgarScore'
                                    value={values.pediatricSurvey.apgarScore}
                                    label="Wynik w skali Apgar"
                                    onChange={handleChange}
                                    error={errors?.pediatricSurvey?.apgarScore}
                                />
                                <Input
                                    type="text"
                                    name='pediatricSurvey.birthMass'
                                    value={values.pediatricSurvey.birthMass}
                                    label="Masa urodzeniowa (g)"
                                    onChange={handleChange}
                                    error={errors?.pediatricSurvey?.birthMass}
                                />
                            </InputRow>
                            <InputRow>
                                <Input
                                    type="text"
                                    name='pediatricSurvey.birthLength'
                                    value={values.pediatricSurvey.birthLength}
                                    label="Długość ciała (cm)"
                                    onChange={handleChange}
                                    error={errors?.pediatricSurvey?.birthLength}
                                />
                                <Input
                                    type="text"
                                    name='pediatricSurvey.birthHeadCircuit'
                                    value={values.pediatricSurvey.birthHeadCircuit}
                                    label="Obwód głowy (cm)"
                                    onChange={handleChange}
                                    error={errors?.pediatricSurvey?.birthHeadCircuit}
                                />
                            </InputRow>
                            <InputRow>
                                <Fieldset active={values.pediatricSurvey.foodIntoleranceCheck}>
                                    <Input
                                        type="checkbox"
                                        variant={values.pediatricSurvey.foodIntoleranceCheck && 'primary'}
                                        hideError={true}
                                        name='pediatricSurvey.foodIntoleranceCheck'
                                        value={values.pediatricSurvey.foodIntoleranceCheck}
                                        checkboxText="Nietolerancja pokarmowa"
                                        onChange={() => setValues({
                                            ...values,
                                            pediatricSurvey: {
                                                ...values.pediatricSurvey,
                                                foodIntolerance: '',
                                                foodIntoleranceCheck: !values.pediatricSurvey.foodIntoleranceCheck
                                            }
                                        })}
                                    />
                                    <CheckboxHiddenInput
                                        type="text"
                                        name='pediatricSurvey.foodIntolerance'
                                        value={values.pediatricSurvey.foodIntolerance}
                                        onChange={handleChange}
                                        error={errors?.pediatricSurvey?.foodIntolerance}
                                        active={values.pediatricSurvey.foodIntoleranceCheck}
                                    />
                                </Fieldset>
                                <Fieldset active={values.pediatricSurvey.developmentalDisordersCheck}>
                                    <Input
                                        type="checkbox"
                                        variant={values.pediatricSurvey.developmentalDisordersCheck && 'primary'}
                                        hideError={true}
                                        name='pediatricSurvey.developmentalDisordersCheck'
                                        value={values.pediatricSurvey.developmentalDisordersCheck}
                                        checkboxText="Zaburzenia rozwojowe"
                                        onChange={() => setValues({
                                            ...values,
                                            pediatricSurvey: {
                                                ...values.pediatricSurvey,
                                                developmentalDisorders: '',
                                                developmentalDisordersCheck: !values.pediatricSurvey.developmentalDisordersCheck
                                            }
                                        })}
                                    />
                                    <CheckboxHiddenInput
                                        type="text"
                                        name='pediatricSurvey.developmentalDisorders'
                                        value={values.pediatricSurvey.developmentalDisorders}
                                        onChange={handleChange}
                                        error={errors?.pediatricSurvey?.developmentalDisorders}
                                        active={values.pediatricSurvey.developmentalDisordersCheck}
                                    />
                                </Fieldset>
                            </InputRow>
                            <InputRow>
                                <Fieldset active={values.pediatricSurvey.feedingCheck}>
                                    <Input
                                        type="checkbox"
                                        variant={values.pediatricSurvey.feedingCheck && 'primary'}
                                        hideError={true}
                                        name='pediatricSurvey.feedingCheck'
                                        value={values.pediatricSurvey.feedingCheck}
                                        checkboxText="Karmienie"
                                        onChange={() => setValues({
                                            ...values,
                                            pediatricSurvey: {
                                                ...values.pediatricSurvey,
                                                feeding: '',
                                                feedingCheck: !values.pediatricSurvey.feedingCheck
                                            }
                                        })}
                                    />
                                    <CheckboxHiddenInput
                                        type="text"
                                        name='pediatricSurvey.feeding'
                                        value={values.pediatricSurvey.feeding}
                                        onChange={handleChange}
                                        error={errors?.pediatricSurvey?.feeding}
                                        active={values.pediatricSurvey.feedingCheck}
                                    />
                                </Fieldset>
                                <Fieldset active={values.pediatricSurvey.additionalNotesCheck}>
                                    <Input
                                        type="checkbox"
                                        variant={values.pediatricSurvey.additionalNotesCheck && 'primary'}
                                        hideError={true}
                                        name='pediatricSurvey.additionalNotesCheck'
                                        value={values.pediatricSurvey.additionalNotesCheck}
                                        checkboxText="Inne"
                                        onChange={() => setValues({
                                            ...values,
                                            pediatricSurvey: {
                                                ...values.pediatricSurvey,
                                                additionalNotes: '',
                                                additionalNotesCheck: !values.pediatricSurvey.additionalNotesCheck
                                            }
                                        })}
                                    />
                                    <CheckboxHiddenInput
                                        type="text"
                                        name='pediatricSurvey.additionalNotes'
                                        value={values.pediatricSurvey.additionalNotes}
                                        onChange={handleChange}
                                        error={errors?.pediatricSurvey?.additionalNotes}
                                        active={values.pediatricSurvey.additionalNotesCheck}
                                    />
                                </Fieldset>
                            </InputRow>
                        </Section>
                        <Section active={values.environmentalSurvey.environmentalCare}>
                            <Input
                                type="checkbox"
                                variant={values.environmentalSurvey.environmentalCare && 'primary'}
                                hideError={true}
                                name='environmentalSurvey.environmentalCare'
                                value={values.environmentalSurvey.environmentalCare}
                                checkboxText="Pacjent zakwalifikowany do objęcia opieką środowiskową"
                                onChange={() => setFieldValue('environmentalSurvey.environmentalCare', !values.environmentalSurvey.environmentalCare)}
                            />
                            <HiddenBox active={values.environmentalSurvey.environmentalCare}>
                                <InputRow>
                                    <DatePicker
                                        label="Opieka do"
                                        dateFormat="dd.MM.yyyy"
                                        selected={Date.parse(values?.environmentalSurvey?.careEndDate)}
                                        onChange={val => setFieldValue("environmentalSurvey.careEndDate", val)}
                                    />
                                    <Input
                                        type="text"
                                        label="Wynik skali Barthel"
                                        name='environmentalSurvey.barthelScore'
                                        value={values.environmentalSurvey.barthelScore}
                                        error={errors?.environmentalSurvey?.barthelScore}
                                        onChange={handleChange}
                                    />
                                </InputRow>
                                <StyledTextarea
                                    hideError={true}
                                    name="environmentalSurvey.additionalNotes"
                                    label="Dodatkowe informacje"
                                    onChange={handleChange}
                                    value={values.environmentalSurvey.additionalNotes}
                                />
                            </HiddenBox>
                        </Section>
                        <Section>
                            <StyledTextarea
                                hideError={true}
                                name="familySurvey"
                                label="Wywiad rodzinny"
                                onChange={handleChange}
                                value={values.familySurvey}
                            />
                        </Section>
                        <Section>
                            <StyledTextarea
                                hideError={true}
                                name="perinatalSurvey"
                                label="Wywiad Poporodowy"
                                onChange={handleChange}
                                value={values.perinatalSurvey}
                            />
                        </Section>
                        <CenterBox>
                            <Button type='submit' variant='primary'>Zapisz zmiany</Button>
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal >
    )
};

export default ProceduresModal;

const Section = styled.div`
    width:450px;
    padding:10px 0;
    position:relative;
    transition:300ms;
    &:not(:nth-last-child(2)){
        border-bottom: 1px solid #ccc;
    }
    ${({ active }) => active && `
        padding-bottom:220px;
    `}
`;

const Form = styled.form`
    max-height:85vh;
    overflow:auto;
`;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;

const InputRow = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:10px;
`;

const Fieldset = styled.div`
    position:relative;
    transition:300ms;
    margin-top:10px;
    ${({ active }) => (active && 'padding-bottom:30px;')}
`;

const CheckboxHiddenInput = styled(Input)`
    position:absolute;
    bottom:0;
    opacity:0;
    z-index:-1;
    left:0;
    transition:300ms;
    ${({ active }) => (active && `
        bottom:-25px;
        opacity:1;
        z-index:1;
    ` )}
`;

const HiddenBox = styled.div`
    position:absolute;
    bottom:10px;
    opacity:0;
    z-index:-1;
    width:100%;
    left:0;
    transition:300ms;
    ${({ active }) => (active && `
        opacity:1;
        z-index:1;
    ` )}
`;

const StyledTextarea = styled(Textarea)`
    min-height: 50px;
`;