import { useState } from 'react';

const useDebouncer = (loadingInital = false) => {
    const [debouceTimeout, setDebounceTimeout] = useState(),
        [loading, setLoading] = useState(loadingInital);

    const setDebounce = (cb, timeout = 500) => {
        setLoading(true);
        clearInterval(debouceTimeout);
        setDebounceTimeout(setTimeout(() => {
            cb();
            setLoading(false);
        }, timeout));
    }

    return [setDebounce, loading];
}

export default useDebouncer;