import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import ExaminationsTabView from "./ExaminationsTabView";
import { withRouter, useParams } from 'react-router-dom';
import { getPatientExaminations, getExaminationFactors, addPatientExamination, getHealthcentersExaminationPatterns, addExaminationPattern, updatePatientExamination, deletePatientExamination, deleteExaminationPattern } from "../../../../../redux/modules/examinations";
import useDebouncer from '../../../../Shared/Hooks/useDebouncer';

const ExaminationsTabContainer = ({ user, pathname, userRole, currentPatient, examinationFactors, patientExaminations, examinationPatterns, getPatientExaminations, getExaminationFactors, addPatientExamination, getHealthcentersExaminationPatterns, addExaminationPattern, updatePatientExamination, deletePatientExamination, factorsLoading, deleteExaminationPattern, nextTutorialStep }) => {
  const [examinationModal, setExaminationModal] = useState({ shown: false, editId: null });
  const [deleteModal, setDeleteModal] = useState({ shown: false, question: null, onAccept: null });
  const [setDebouncer] = useDebouncer();
  const { currentTab, patientId } = useParams();

  useEffect(() => {
    if (userRole) {
      if (currentTab === "examinations" && patientId === currentPatient._id) {
        getExaminationFactors();
        getPatientExaminations(currentPatient._id);
        getHealthcentersExaminationPatterns(userRole.healthCenters[0]);
      }
    }
  }, [currentPatient, pathname, currentTab]);

  const addExaminationHandler = (date, currentPattern, patternName, factors) => {
    const newExamination = {
      patient: currentPatient._id,
      executor: user._id,
      examinationDate: date,
      factors: Object.values(factors).filter(el => Object.values(el).length > 0)
    };
    addPatientExamination(newExamination);
    if (currentPattern === 'createNew') {
      const newPattern = {
        healthcenter: userRole.healthCenters[0],
        executor: user._id,
        name: patternName,
        factors: Object.values(factors).filter(el => Object.values(el).length > 0).map(({ quantity, ...el }) => el),
      };
      addExaminationPattern(newPattern);
    }
  };

  const updateExaminationHandler = (date, factors) => {
    const newExamination = {
      patient: currentPatient._id,
      executor: user._id,
      examinationDate: date,
      factors: Object.values(factors).filter(el => Object.values(el).length > 0)
    };
    updatePatientExamination(newExamination, examinationModal.editId);
  };

  const deleteModalHandler = (target, payload, cb) => {
    if (target === 'examination') {
      setDeleteModal({
        question: 'Czy na pewno chcesz usunąć to badanie?',
        shown: true,
        onAccept: () => {
          deletePatientExamination(examinationModal.editId)
          setExaminationModal({ shown: false, editId: null });
        }
      })
    } else if (target === 'examinationPattern') {
      setDeleteModal({
        question: 'Czy na pewno chcesz usunąć ten szablon?',
        shown: true,
        onAccept: () => {
          deleteExaminationPattern(payload);
          cb();
        }
      })
    }
  };

  const factorsSearchByNameHandler = (value) => {
    setDebouncer(() => getExaminationFactors(value));
  };

  return (
    <ExaminationsTabView
      currentTab={currentTab}
      patientExaminations={patientExaminations}
      setExaminationModal={setExaminationModal}
      examinationModal={examinationModal}
      addExaminationHandler={addExaminationHandler}
      updateExaminationHandler={updateExaminationHandler}
      deleteModalHandler={deleteModalHandler}
      factorsSearchByNameHandler={factorsSearchByNameHandler}
      examinationFactors={examinationFactors}
      examinationPatterns={examinationPatterns}
      setDeleteModal={setDeleteModal}
      deleteModal={deleteModal}
      factorsLoading={factorsLoading}
      deleteExaminationPattern={deleteExaminationPattern}
      nextTutorialStep={nextTutorialStep}
    />
  )
}



const mapStateToProps = state => ({
  user: state.authentication.user,
  userRole: state.authentication.userRole,
  currentPatient: state.patients.currentPatient,
  examinationFactors: state.examinations.examinationFactors,
  patientExaminations: state.examinations.patientExaminations,
  examinationPatterns: state.examinations.examinationPatterns,
  factorsLoading: state.examinations.factorsLoading,
});

const mapDispatchToProps = dispatch => {
  return {
    getPatientExaminations: (patientId) => dispatch(getPatientExaminations(patientId)),
    getExaminationFactors: (searchQuery) => dispatch(getExaminationFactors(searchQuery)),
    addPatientExamination: (examination) => dispatch(addPatientExamination(examination)),
    getHealthcentersExaminationPatterns: (healthcenterId) => dispatch(getHealthcentersExaminationPatterns(healthcenterId)),
    addExaminationPattern: (pattern) => dispatch(addExaminationPattern(pattern)),
    updatePatientExamination: (examination, examinationId) => dispatch(updatePatientExamination(examination, examinationId)),
    deletePatientExamination: (examinationId) => dispatch(deletePatientExamination(examinationId)),
    deleteExaminationPattern: (patternId) => dispatch(deleteExaminationPattern(patternId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ExaminationsTabContainer));
