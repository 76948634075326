import React, { useEffect, useState, useCallback } from "react";
import VisitView from "./VisitView";
import Loader from '../Shared/Loader';
import useDebouncer from '../Shared/Hooks/useDebouncer';
import { connect } from "react-redux";
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { updateVisit, getCurrentVisitById, removeActiveVisit } from "../../redux/modules/visits";
import { fullStageNameEnum, stagesTextsName } from '../Shared/enums';
import { convertSchemaToText, convertTextToSchema, parseValue } from './utils';
import { getDoctorsVisitElements } from "../../redux/modules/visitElement";
import { getDoctorsElementsSet } from "../../redux/modules/visitElementsSet";
import { getPatientById, getPatientVisitHistory } from "../../redux/modules/patients";
import { getEmployees } from '../../redux/modules/employees';
import { deletePatientDocument } from '../../redux/modules/documents';
import { getMedicalData } from '../../redux/modules/medicalRest';
import { getPatientMedsDiagnosis } from '../../redux/modules/medsdiagnosis';
import { getPatientHospitalizations } from '../../redux/modules/hospitalizations';
import { getCurrentHealthcenter } from '../../redux/modules/healthcenter';
import { setNavBlock, setSidebarActive } from '../../redux/modules/navigation';
import { addMedicalVisit, getMedicalVisitByVisitId, updateMedicalVisit, getPatientMedicalVisits, getHistoryMedicalVisit } from '../../redux/modules/medicalVisit';
import { nextTutorialStep, prevTutorialStep } from '../../redux/modules/tutorial';

const VisitContainer = ({
  userRole,
  match: { params: { id: visitId } },
  currentVisit,
  currentPatient,
  doctorsElements,
  elementsLoading,
  currentMedicalVisit,
  diagnosis,
  procedures,
  meds,
  patientMedsDiagnosis,
  patientHospitalizations,
  patientMedicalVisits,
  historyMedicalVisit,
  currentHealthcenter,
  doctorsElementsSets,
  navBlock,
  sidebarActive,

  historyLoading,
  medsLoading,
  diagnosisLoading,
  proceduresLoading,

  getCurrentVisitById,
  getPatientById,
  getDoctorsVisitElements,
  updateVisit,
  addMedicalVisit,
  getMedicalVisitByVisitId,
  updateMedicalVisit,
  deletePatientDocument,
  getPatientHospitalizations,
  getPatientMedsDiagnosis,
  getPatientVisitHistory,
  getPatientMedicalVisits,
  getHistoryMedicalVisit,
  getMedicalData,
  getCurrentHealthcenter,
  removeActiveVisit,
  getDoctorsElementsSet,
  setNavBlock,
  setSidebarActive,
  nextTutorialStep,
  prevTutorialStep,
}) => {
  //Page states
  const [currentStage, setCurrentStage] = useState({ i: 0, obj: {} }),
    [currentElement, setCurrentElement] = useState(0),
    [elIsDragging, setElIsDragging] = useState(false),
    [stagesCollectedData, setStagesCollectedData] = useState([]),
    [otherDiagnosis, setOtherDiagnosis] = useState([]),
    [otherMeds, setOtherMeds] = useState([]),
    [medicalProcedures, setMedicalProcedures] = useState([]),
    [stagesTexts, setStagesTexts] = useState({}),
    [stagesTextsSchemas, setStagesTextsSchemas] = useState({}),
    [documents, setDocuments] = useState({ photos: [], documents: [] }),
    [visitHistory, setVisitHistory] = useState({ all: [], i: 0 }),
    [services, setServices] = useState({ total: 0, totalWithDiscount: 0, discount: 0, list: [], added: [], discountError: null }),
    [medsSwitch, setMedsSwitch] = useState(true),
    [readMode, setReadMode] = useState(false),
    [editMode, setEditMode] = useState(false),
    [changes, setChanges] = useState({
      medicalProcedures: false,
      otherDiagnosis: false,
      otherMeds: false,
    }),
    [preventSwipe, setPreventSwipe] = useState(false);

  const [setDebounce] = useDebouncer();
  const history = useHistory();
  const swipeHandlers = useSwipeable({
    onSwiping: () => {
      if (!preventSwipe && elIsDragging !== false) {
        setPreventSwipe(true);
      }
    },
    onSwipedRight: ({ event }) => {
      event.stopPropagation();
      if (!elIsDragging && !preventSwipe && !sidebarActive) {
        setSidebarActive(true);
      } else if (preventSwipe) {
        setPreventSwipe(false);
      };
    },
    onSwipedLeft: ({ event }) => {
      event.stopPropagation();
      if (!elIsDragging && !preventSwipe && sidebarActive) {
        setSidebarActive(false);
      } else if (preventSwipe) {
        setPreventSwipe(false);
      };
    }
  });

  //Modals states
  const [documentsModal, setDocumentsModal] = useState(false),
    [warningModal, setWarningModal] = useState({ shown: false, question: null, onAccept: null }),
    [prescriptionModalOn, setPrescriptionModal] = useState({ isOpen: false, type: undefined });

  //Elements functionalities
  const dropElementHandler = ({ type, ...item }) => {
    if (readMode) {
      return false;
    }
    if (!navBlock) {
      setNavBlock(true);
    }
    const targetTextName = stagesTextsName[currentStage.i];
    let text = currentStage[targetTextName] ? `${item.text}${item._id}|${item._id}|` : " " + `${item.text}|${item._id}|`;
    switch (type) {
      case 'element': {
        item.formControls = item?.formControls.map(control => control.defaultValue !== '' ? { ...control, value: control.defaultValue, parsedValue: parseValue(control.defaultValue, control) } : control);
        setCurrentStage(prev => ({
          ...prev,
          obj: {
            ...prev.obj,
            elements: [...prev.obj.elements, item],
          }
        }));
      }
        break;
      case 'procedure':
        if (editMode) {
          setChanges(prev => ({ ...prev, medicalProcedures: true }))
        }
        text = `{list[${medicalProcedures.length}]}`;
        if (medicalProcedures.length === 0) {
          text = `{defaultListHeading}${text}`;
        }
        item.generatedTextName = `${item.name}`;
        setMedicalProcedures(prev => ([
          ...prev,
          item
        ]))
        break;
      case 'diagnosis':
        if (editMode) {
          setChanges(prev => ({ ...prev, otherDiagnosis: true }))
        }
        text = `{list[${otherDiagnosis.length}]}`;
        if (otherDiagnosis.length === 0) {
          text = `{defaultListHeading}${text}`;
        }
        item.name = `${item.category}, ${item.subcategory}`;
        item.generatedTextName = `${item.category}, ${item.subcategory}`;
        setOtherDiagnosis(prev => ([
          ...prev,
          item
        ]))
        break;
      case 'med':
        if (editMode) {
          setChanges(prev => ({ ...prev, otherMeds: true }))
        }
        const alreadyAdded = otherMeds.findIndex(el => el.nazwaProduktu === item.nazwaProduktu && el.moc === item.moc);
        if (alreadyAdded !== -1) {
          const checkIfElMedNameIsEdited = otherMeds[alreadyAdded]?.name !== otherMeds[alreadyAdded]?.generatedTextName;
          if (checkIfElMedNameIsEdited) {
            const newQuantity = otherMeds[alreadyAdded].ilosc + 1;
            return warningModalHandler('changeMedDataValue', { targetIndex: alreadyAdded, value: newQuantity, target: 'quantity' })
          };
          text = '';
          const newOtherMeds = [...otherMeds];
          newOtherMeds[alreadyAdded].ilosc = parseInt(newOtherMeds[alreadyAdded].ilosc) + 1;
          const newName = `${item.nazwaProduktu}, ${item.moc ?? ''}, ${newOtherMeds[alreadyAdded].ilosc} x ${newOtherMeds[alreadyAdded].wielkosc} ${newOtherMeds[alreadyAdded].jednostkaWielkosci} do ${moment(item.dataKoncaPrzyjmowania).format('DD.MM.YYYY')}`;
          newOtherMeds[alreadyAdded].name = newName;
          newOtherMeds[alreadyAdded].generatedTextName = newName;
          setOtherMeds(newOtherMeds);
          refreshGeneratedTextHandler(currentStage.obj.elements, null, alreadyAdded);
        } else {
          text = `{list[${otherMeds.length}]}`;
          if (otherMeds.length === 0) {
            text = `{defaultListHeading}${text}`;
          }
          item.opakowania = item.opakowania.map(({ _attributes: { wielkosc, jednostkaWielkosci, kategoriaDostepnosci } }) => (
            { wielkosc, jednostkaWielkosci, kategoriaDostepnosci }
          ));
          item.ilosc = 1;
          item.wielkosc = item.opakowania[0].wielkosc;
          item.dataKoncaPrzyjmowania = moment().add(2, 'weeks')._d;
          item.jednostkaWielkosci = item.opakowania[0].jednostkaWielkosci;
          const newName = `${item.nazwaProduktu}, ${item.moc ?? ''}, ${item.ilosc} x ${item.wielkosc} ${item.jednostkaWielkosci} do ${moment(item.dataKoncaPrzyjmowania).format('DD.MM.YYYY')}`;
          item.name = newName
          item.generatedTextName = newName;
          setOtherMeds(prev => ([
            ...prev,
            item
          ]));
        }
        break;
      case 'service':
        setServices(prev => ({
          ...prev,
          added: [...prev.added, item],
        }))
        break;
      case 'set':
        const elementsToAdd = item.elements.filter(({ _id: elId }) => currentStage.obj.elements.findIndex(({ _id }) => _id === elId) === -1);
        text = elementsToAdd.map(({ text }) => text).join(' ');
        setCurrentStage(prev => ({
          ...prev,
          obj: {
            ...prev.obj,
            elements: [...prev.obj.elements, ...elementsToAdd]
          }
        }));
        break;
    };
    if (type !== 'service') {
      console.log(text);
      setStagesTextsSchemas(prev => ({
        ...prev,
        [targetTextName]: `${prev[targetTextName] ? prev[targetTextName] : ""}${text}`
      }))
    }
    setElIsDragging(false);
  },
    changeElementValueHandler = (controlIndex, control, value, optionIndex, force = false) => {
      if (!navBlock) {
        setNavBlock(true);
      }
      if (control.parsedValue && parseValue(control.value, control) !== control.parsedValue && !force) {
        return warningModalHandler('changeElementValue', { controlIndex, control, value, optionIndex })
      }
      const newCurrentStage = { ...currentStage.obj };
      if (optionIndex || optionIndex === 0) {
        newCurrentStage.elements[currentElement].formControls[controlIndex].checkboxOptions.options[optionIndex].value = value;
        const parsedValue = parseValue('', newCurrentStage.elements[currentElement].formControls[controlIndex]);
        newCurrentStage.elements[currentElement].formControls[controlIndex].parsedValue = parsedValue;
      } else {
        const parsedValue = parseValue(value, control);
        newCurrentStage.elements[currentElement].formControls[controlIndex].value = value;
        newCurrentStage.elements[currentElement].formControls[controlIndex].parsedValue = parsedValue;
      }
      setCurrentStage(prev => ({ ...prev, obj: newCurrentStage }))
    },
    removeElementHandler = (elementId) => {
      if (readMode) {
        return false;
      }
      const newCurrentStage = { ...currentStage.obj };
      newCurrentStage.elements = newCurrentStage.elements.filter(el => el._id !== elementId);
      setCurrentStage(prev => ({ ...prev, obj: newCurrentStage }));
      refreshGeneratedTextHandler(newCurrentStage.elements);
    },
    changeIndexHandler = (dragIndex, hoverIndex, target) => {
      if (readMode) {
        return false;
      }
      if (editMode) {
        setChanges(prev => ({ ...prev, [target]: true }))
      }
      if (!navBlock) {
        setNavBlock(true);
      }
      switch (target) {
        case 'elements':
          const newCurrentStage = { ...currentStage.obj };
          [newCurrentStage.elements[dragIndex], newCurrentStage.elements[hoverIndex]] = [newCurrentStage.elements[hoverIndex], newCurrentStage.elements[dragIndex]];
          setCurrentStage(prev => ({ ...prev, obj: newCurrentStage }));
          break;
        case 'medicalProcedures':
          const newMedicalProcedures = [...medicalProcedures];
          [newMedicalProcedures[dragIndex], newMedicalProcedures[hoverIndex]] = [newMedicalProcedures[hoverIndex], newMedicalProcedures[dragIndex]];
          setMedicalProcedures(newMedicalProcedures);
          break;
        case 'otherDiagnosis':
          const newOtherDiagnosis = [...otherDiagnosis];
          [newOtherDiagnosis[dragIndex], newOtherDiagnosis[hoverIndex]] = [newOtherDiagnosis[hoverIndex], newOtherDiagnosis[dragIndex]];
          setOtherDiagnosis(newOtherDiagnosis);
          break;
        case 'otherMeds':
          const newOtherMeds = [...otherMeds];
          [newOtherMeds[dragIndex], newOtherMeds[hoverIndex]] = [newOtherMeds[hoverIndex], newOtherMeds[dragIndex]];
          setOtherMeds(newOtherMeds);
          break;
      }
    },
    changeTextsSchemasHandler = (value) => {
      if (!navBlock) {
        setNavBlock(true);
      }
      const stageName = stagesTextsName[currentStage.i];
      const currentList = currentStage.i === 2 ? medicalProcedures : currentStage.i === 3 ? otherDiagnosis : currentStage.i === 4 ? otherMeds : [];
      const { newSchema = '', parsedValuesToChange = [] } = convertTextToSchema(value, currentStage.obj.elements, currentList) ?? {};
      if (parsedValuesToChange.length > 0) {
        const newCurrentStage = { ...currentStage.obj };
        parsedValuesToChange.forEach(({ newParsedValue, controlIndex, elIndex, type, tagIndex }) => {
          if (type === 'element' && newCurrentStage.elements[elIndex].formControls[controlIndex]) {
            newCurrentStage.elements[elIndex].formControls[controlIndex].parsedValue = newParsedValue;
          } else if (type === 'listItem' && currentList[tagIndex]) {
            currentList[tagIndex].generatedTextName = newParsedValue;
          }
        });
        if (parsedValuesToChange.filter(({ type }) => type === 'listItem').length > 0) {
          switch (currentStage.i) {
            case 2:
              setMedicalProcedures(currentList);
              break;
            case 3:
              setOtherDiagnosis(currentList);
              break;
            case 4:
              setOtherMeds(currentList);
              break;
          }
        }
        if (parsedValuesToChange.filter(({ type }) => type === 'element').length > 0) {
          setCurrentStage(prev => ({ ...prev, obj: newCurrentStage }));
        }
      }
      setStagesTextsSchemas(prev => ({ ...prev, [stageName]: newSchema }));
    };

  //List functionalities
  const removeListItemHandler = ({ itemIndex, targetList }) => {
    if (!navBlock) {
      setNavBlock(true);
    }
    let newList;
    if (editMode) {
      setChanges(prev => ({ ...prev, [targetList]: true }))
    }
    switch (targetList) {
      case 'medicalProcedures':
        newList = medicalProcedures.filter((el, index) => index !== itemIndex);
        setMedicalProcedures(newList);
        break;
      case 'otherDiagnosis':
        newList = otherDiagnosis.filter((el, index) => index !== itemIndex);
        setOtherDiagnosis(prev => prev.filter((el, index) => index !== itemIndex))
        break;
      case 'otherMeds':
        newList = otherMeds.filter((el, index) => index !== itemIndex);
        setOtherMeds(prev => prev.filter((el, index) => index !== itemIndex))
        break;
    };
    if (targetList === 'otherMeds') {
      refreshGeneratedTextHandler(currentStage.obj.elements, newList);
    } else {
      refreshGeneratedTextHandler([], newList);
    }
  },
    changeMedDataHandler = (targetIndex, value, target, force = false) => {
      const checkIfElMedNameIsEdited = otherMeds[targetIndex]?.name !== otherMeds[targetIndex]?.generatedTextName;
      if (checkIfElMedNameIsEdited && !force) {
        return warningModalHandler('changeMedDataValue', { targetIndex, value, target })
      }
      if (!navBlock) {
        setNavBlock(true);
      }
      const newOtherMeds = [...otherMeds];
      const targetEl = newOtherMeds[targetIndex];
      let newName;
      if (target === 'package') {
        newName = `${targetEl.nazwaProduktu}, ${targetEl.moc ?? ''}, ${targetEl.ilosc} x ${targetEl.opakowania[value].wielkosc} ${targetEl.opakowania[value].jednostkaWielkosci} do ${moment(targetEl.dataKoncaPrzyjmowania).format('DD.MM.YYYY')}`;
        newOtherMeds[targetIndex] = {
          ...targetEl,
          wielkosc: targetEl.opakowania[value].wielkosc,
          jednostkaWielkosci: targetEl.opakowania[value].jednostkaWielkosci,
          name: newName,
          generatedTextName: newName,
        };
      } else if (target === 'quantity' && value > -1 && value < 1000) {
        newOtherMeds[targetIndex].ilosc = value;
        newName = `${targetEl.nazwaProduktu}, ${targetEl.moc ?? ''}, ${targetEl.ilosc} x ${targetEl.wielkosc} ${targetEl.jednostkaWielkosci} do ${moment(targetEl.dataKoncaPrzyjmowania).format('DD.MM.YYYY')}`;
        newOtherMeds[targetIndex].name = newName;
        newOtherMeds[targetIndex].generatedTextName = newName;
      } else if (target === 'date') {
        newOtherMeds[targetIndex].dataKoncaPrzyjmowania = value;
        newName = `${targetEl.nazwaProduktu}, ${targetEl.moc ?? ''}, ${targetEl.ilosc} x ${targetEl.wielkosc} ${targetEl.jednostkaWielkosci} do ${moment(newOtherMeds[targetIndex].dataKoncaPrzyjmowania).format('DD.MM.YYYY')}`;
        newOtherMeds[targetIndex].name = newName;
        newOtherMeds[targetIndex].generatedTextName = newName;
      }
      setOtherMeds(newOtherMeds);
      if (checkIfElMedNameIsEdited && force) {
        refreshGeneratedTextHandler(currentStage.obj.elements, null, targetIndex);
      }
    };

  //MedicalVisit functionalities
  const getMedicalVisitHandler = async () => {
    const medicalVisit = await getMedicalVisitByVisitId(currentVisit._id);
    if (medicalVisit === '404' && !readMode) {
      //Create new
      const newMedicalVisit = {
        doctor: userRole._id,
        visit: currentVisit._id,
        patient: currentVisit.patient,
        healthcenter: currentVisit.healthcenterId,
      }
      addMedicalVisit(newMedicalVisit);
    }
    else if (medicalVisit !== '404') {
      //Load data
      let stagesTextsSchemas = medicalVisit.stagesTextsSchemas ?? {};
      const mapedStagesCollectedData = medicalVisit.stagesCollectedData.map(({ stageName, elements }) => ({
        stageName,
        elements: elements?.map(({ visitElement, elementValues }) => {
          visitElement.formControls = visitElement?.formControls?.map(control => {
            const values = elementValues.find(({ name }) => name === control.name);
            return {
              ...control,
              ...values,
              checkboxOptions: {
                ...values.checkboxOptions,
                options: values.options
              }
            };
          });
          visitElement._id = visitElement.elementId;
          return visitElement;
        })
      }));
      const mapedOtherMeds = medicalVisit?.otherMeds.map(el => ({ ...el, name: `${el.nazwaProduktu}, ${el.nazwaPowszechnieStosowana}, ${el.moc ?? ''}, ${el.ilosc} x ${el.wielkosc} ${el.jednostkaWielkosci}` }));
      const mapedOtherDiagnosis = medicalVisit.otherDiagnosis.map(el => ({ ...el, name: `${el.category}, ${el.subcategory}` }));
      setOtherDiagnosis(mapedOtherDiagnosis);
      setOtherMeds(mapedOtherMeds);
      setMedicalProcedures(medicalVisit.medicalProcedures);
      setStagesCollectedData(mapedStagesCollectedData);
      setStagesTextsSchemas(stagesTextsSchemas);
    }
  },
    addDocumentHandler = (document) => {
      const formData = new FormData();
      formData.append("userId", currentVisit.patient);
      formData.append("patientId", currentPatient._id);
      formData.append("medicalVisit", currentMedicalVisit._id);
      formData.append("description", document.description);
      formData.append("document", document.file);
      updateMedicalVisit(currentMedicalVisit._id, formData, currentPatient._id, 'document');
    },
    updateMedicalVisitHandler = () => {
      if (readMode) {
        return false;
      }
      setNavBlock(false);
      const mapedStagesCollectedData = stagesCollectedData.map(({ stageName, elements = [] }) => (
        {
          stageName,
          elements: elements.map(({ _id, name, formControls, text }) => ({
            visitElement: {
              elementId: _id,
              name,
              text,
              formControls
            },
            elementValues: formControls.map(({ name, value, parsedValue, unit, checkboxOptions, previousValues }) => ({
              name,
              value: value,
              parsedValue: parsedValue,
              unit,
              options: checkboxOptions.options,
              previousValues
            }))
          }))
        }
      ));
      const mapedStagesText = {};
      for (const [key, value] of Object.entries(stagesTextsSchemas)) {
        const targetStageName = fullStageNameEnum[stagesTextsName.indexOf(key)];
        const stageObj = stagesCollectedData.find(({ stageName }) => stageName === targetStageName) ?? { stageName: targetStageName };
        const sourceList = targetStageName === 'BADANIE DODATKOWE' ? medicalProcedures :
          targetStageName === 'ROZPOZNANIE' ? otherDiagnosis :
            targetStageName === 'ZALECENIA' ? otherMeds : [];

        mapedStagesText[key] = convertSchemaToText(value, stageObj, sourceList, true);
      }
      const mapedMedicalProcedures = medicalProcedures.map(({ _id, ...el }) => ({ ...el, executionDate: Date.now() }));
      const mapedOtherMeds = otherMeds.map(({ _id, name, ...el }) => ({ ...el, dataWystawieniaRecepty: Date.now() }));
      const mapedOtherDiagnosis = otherDiagnosis.map(({ _id, name, ...el }) => ({ ...el, diagnosisDate: Date.now() }));
      const updateBody = {
        patientId: currentMedicalVisit.patient,
        stagesTexts: mapedStagesText,
        stagesTextsSchemas: stagesTextsSchemas,
        stagesCollectedData: mapedStagesCollectedData,
        medicalProcedures: mapedMedicalProcedures,
        otherMeds: mapedOtherMeds,
        otherDiagnosis: mapedOtherDiagnosis,
      };
      if (editMode) {
        const mapedChanges = {
          stagesTexts: currentMedicalVisit.changes?.stagesTexts ?? {},
        };
        mapedChanges.stagesCollectedData = stagesCollectedData.map(({ stageName, elements = [] }, stageIndex) => (
          {
            stageName,
            elements: elements.map(({ formControls }, elIndex) => ({
              previousValues: formControls.map(({ value, parsedValue, formType }, valueIndex) => {
                let prev, currentValue, prevValue = value;
                try {
                  prev = currentMedicalVisit.changes.stagesCollectedData[stageIndex].elements[elIndex].previousValues[valueIndex];
                } catch {
                  prev = [];
                }
                try {
                  if (formType === 'checkbox') {
                    currentValue = currentMedicalVisit.stagesCollectedData[stageIndex].elements[elIndex].elementValues[valueIndex].parsedValue;
                    if (currentValue === '') {
                      currentValue = 'Nie zaznaczony';
                    }
                    prevValue = parsedValue;
                  } else {
                    currentValue = currentMedicalVisit.stagesCollectedData[stageIndex].elements[elIndex].elementValues[valueIndex].value;
                  }
                } catch {
                  currentValue = 'Dodane';
                }
                if (!currentValue) {
                  currentValue = 'Pole puste';
                }
                if (prevValue !== currentValue) {
                  return [
                    {
                      changeDate: Date.now(),
                      value: currentValue,
                    },
                    ...prev
                  ]
                } else {
                  return prev;
                }
              })
            }))
          }
        ));
        for (const [key, text] of Object.entries(mapedStagesText)) {
          if (currentMedicalVisit.stagesTexts[key] !== text) {
            mapedChanges.stagesTexts[key] = [
              {
                changeDate: Date.now(),
                value: currentMedicalVisit.stagesTexts[key]
              },
              ...currentMedicalVisit.changes.stagesTexts[key] ?? [],
            ]
          }
        }
        if (changes.medicalProcedures) {
          mapedChanges.medicalProcedures = [
            {
              changeDate: Date.now(),
              items: currentMedicalVisit.medicalProcedures
            },
            ...currentMedicalVisit.changes.medicalProcedures,
          ];
        } else {
          mapedChanges.medicalProcedures = currentMedicalVisit.changes.medicalProcedures
        }
        if (changes.otherDiagnosis) {
          mapedChanges.otherDiagnosis = [
            {
              changeDate: Date.now(),
              items: currentMedicalVisit.otherDiagnosis
            },
            ...currentMedicalVisit.changes.otherDiagnosis,
          ];
        } else {
          mapedChanges.otherDiagnosis = currentMedicalVisit.changes.otherDiagnosis
        }
        if (changes.otherMeds) {
          mapedChanges.otherMeds = [
            {
              changeDate: Date.now(),
              items: currentMedicalVisit.otherMeds
            },
            ...currentMedicalVisit.changes.otherMeds,
          ];
        } else {
          mapedChanges.otherMeds = currentMedicalVisit.changes.otherMeds
        }
        setChanges({
          medicalProcedures: false,
          otherDiagnosis: false,
          otherMeds: false,
        });
        updateBody.changes = mapedChanges
      }

      if (currentMedicalVisit._id) {
        updateMedicalVisit(currentMedicalVisit._id, updateBody);
      }
    };

  //Other functionalities
  const refreshGeneratedTextHandler = (elementsArr = currentStage.obj.elements, listArr = null, targetIndex) => {
    if (!navBlock) {
      setNavBlock(true);
    }
    const targetTextName = stagesTextsName[currentStage.i];
    if (listArr === null) {
      switch (targetTextName) {
        case 'additionalExaminations':
          listArr = medicalProcedures;
          break;
        case 'diagnosis':
          listArr = otherDiagnosis;
          break;
        case 'recommendations':
          listArr = otherMeds;
          break;
        default:
          listArr = [];
          break;
      }
    }
    let newSchema = '';
    if (elementsArr.length > 0) {
      newSchema = elementsArr.map(({ text, _id }) => `${text}|${_id}|`).join(' ');
    }
    if (listArr.length > 0) {
      newSchema = newSchema !== '' ? `${newSchema}\n{defaultListHeading}` : '{defaultListHeading}';
      newSchema += listArr.map((el, index) => `{list[${index}]}`).join('');
    }
    const newCurrentStage = { ...currentStage.obj };
    elementsArr.forEach(({ formControls }, index) => {
      elementsArr[index].formControls = formControls.map(control => {
        return { ...control, parsedValue: parseValue(control.value, control) }
      })
    });
    newCurrentStage.elements = elementsArr;
    if (medicalProcedures.length > 0 && currentStage.i === 2) {
      setMedicalProcedures(prev => prev.map(el => ({ ...el, generatedTextName: el.name })))
    }
    if (otherDiagnosis.length > 0 && currentStage.i === 3) {
      setOtherDiagnosis(prev => prev.map(el => ({ ...el, generatedTextName: el.name })))
    }
    if (otherMeds.length > 0 && currentStage.i === 4) {
      if (typeof targetIndex !== 'undefined') {
        setOtherMeds(prev => prev.map((el, index) => index === targetIndex ? { ...el, generatedTextName: el.name } : el))
      } else {
        setOtherMeds(prev => prev.map(el => ({ ...el, generatedTextName: el.name })))
      }
    }
    setCurrentStage(prev => ({ ...prev, obj: newCurrentStage }));
    setStagesTextsSchemas(prev => ({
      ...prev,
      [targetTextName]: newSchema
    }));
  };

  //Modal functionalities
  const warningModalHandler = (action, payload) => {
    switch (action) {
      case 'removeDoc':
        setWarningModal({
          shown: true,
          question: 'Czy na pewno chcesz usunąć ten dokument?',
          onAccept: () => {
            deletePatientDocument(payload);
            setDocuments(prev => ({
              photos: prev.photos.filter(el => el._id !== payload),
              documents: prev.documents.filter(el => el._id !== payload),
            }))
          },
        });
        break;
      case 'removeEl':
        setWarningModal({
          shown: true,
          question: 'Usunięcie elementu spowoduje utracenie wprowadzonych do niego wartości oraz zresetowanie wygenerowanego sprawozdania. Czy na pewno chcesz kontynuować?',
          onAccept: () => {
            removeElementHandler(payload);
          },
        });
        break;
      case 'removeItem':
        setWarningModal({
          shown: true,
          question: 'Usunięcie elementu listy spowoduje zresetowanie wygenerowanego sprawozdania. Czy na pewno chcesz kontynuować?',
          onAccept: () => {
            removeListItemHandler(payload);
          },
        });
        break;
      case 'refreshText':
        setWarningModal({
          shown: true,
          question: 'Zresetowanie wygenerowanego sprawozdania spowoduje utratę zmian wprowadzonych ręcznie do niego. Czy na pewno chcesz kontynuować?',
          onAccept: refreshGeneratedTextHandler,
        });
        break;
      case 'changeMedDataValue':
        setWarningModal({
          shown: true,
          question: 'Zmiana wartości elementu spowoduje spowoduje zresetowanie wygenerowanego sprawozdania. Czy na pewno chcesz kontynuować? ',
          onAccept: () => {
            const { targetIndex, value, target } = payload;
            changeMedDataHandler(targetIndex, value, target, true);
          },
        });
        break;
      case 'changeElementValue':
        setWarningModal({
          shown: true,
          question: 'Zmiana wartości pól spowoduje spowoduje zresetowanie wygenerowanego sprawozdania. Czy na pewno chcesz kontynuować? ',
          onAccept: () => {
            const { controlIndex, control, value, optionIndex } = payload;
            changeElementValueHandler(controlIndex, control, value, optionIndex, true);
          },
        });
        break;
      case 'endVisit':
        if (readMode) {
          return history.push('/');
        }
        if (editMode) {
          updateMedicalVisitHandler();
          updateVisit(currentVisit._id, 'ended', {
            services: services.added.map(({ _id }) => ({ _id })),
            cost: services.totalWithDiscount,
            discount: services.discount
          });
          history.push('/');
        } else {
          setWarningModal({
            shown: true,
            question: 'Czy na pewno chcesz zakończyć tę wizytę',
            onAccept: async () => {
              updateMedicalVisitHandler();
              const resp = await updateVisit(currentVisit._id, 'ended', {
                services: services.added.map(({ _id }) => ({ _id })),
                cost: services.totalWithDiscount,
                discount: services.discount
              });
              if (resp.status === 200) {
                removeActiveVisit(currentVisit._id);
                history.push('/');
              }
            },
          });
        }
        break;
    }
  };

  //History functionalities
  const changeCurrentHistoryVisitHandler = (direction) => {
    if (direction === 'prev' && visitHistory.i > 0) {
      const { _id, visit } = visitHistory.all[visitHistory.i - 1] ?? {};
      setVisitHistory(prev => ({ ...prev, i: --prev.i, currentDate: visit?.start }))
      getHistoryMedicalVisit(_id);
    } else if (direction === 'next' && visitHistory.i < visitHistory.all.length - 1) {
      const { _id, visit } = visitHistory.all[visitHistory.i + 1] ?? {};
      setVisitHistory(prev => ({ ...prev, i: ++prev.i, currentDate: visit?.start }))
      getHistoryMedicalVisit(_id);
    }
  }

  //useEffects
  useEffect(() => {
    // GET Visit & doctor data
    if (visitId && userRole) {
      getCurrentVisitById(visitId);
      getDoctorsVisitElements(userRole.userId);
      getDoctorsElementsSet(userRole.userId);
    }
  }, [visitId, userRole]);

  useEffect(() => {
    if (currentVisit?.patient && currentVisit?.healthcenterId && currentVisit?._id && !elementsLoading) {
      // GET Patient data
      getPatientById(currentVisit.patient, true);
      getPatientMedsDiagnosis(currentVisit.patient);
      getPatientHospitalizations(currentVisit.patient);
      getPatientVisitHistory(currentVisit.patient);
      getPatientMedicalVisits(currentVisit.patient);
      getCurrentHealthcenter(currentVisit.healthcenterId);
      getMedicalData('icd9s');
      getMedicalData('icd10s');
      getMedicalData('medicalProducts');
      const editModeQuery = new URLSearchParams(history.location.search).get("edit");
      if ((currentVisit.state === 'ENDED' && editModeQuery === null) || (currentVisit?.doctor !== userRole?._id)) {
        setReadMode(true);
      } else if (currentVisit.state === 'ENDED' && editModeQuery !== null) {
        setEditMode(true);
      } else {
        setReadMode(false);
        setEditMode(false);
      }
      // GET MedicalVisitData and if undefined create it
      getMedicalVisitHandler();
    }
  }, [currentVisit, elementsLoading]);

  useEffect(() => {
    if (!currentMedicalVisit?._id && currentVisit?._id) {
      getMedicalVisitHandler();
    }
  }, [currentMedicalVisit]);

  useEffect(() => {
    // SET current stage, if undefined create it
    const newCurrentStage =
      stagesCollectedData.find(({ stageName }) => stageName === fullStageNameEnum[currentStage.i])
      ?? {
        stageName: fullStageNameEnum[currentStage.i],
        elements: [],
      };
    setCurrentStage(prev => ({ ...prev, obj: newCurrentStage }));
  }, [currentStage.i, stagesCollectedData, currentMedicalVisit]);

  useEffect(() => {
    //SET documents
    if (currentMedicalVisit.documents) {
      const documents = currentMedicalVisit.documents.filter(({ path }) => !path.includes('photos')),
        photos = currentMedicalVisit.documents.filter(({ path }) => path.includes('photos'));
      setDocuments({ photos, documents })
    }
  }, [currentMedicalVisit]);

  useEffect(() => {
    // UPDATE StagesCollectedData on every currentStageObj change to have actual object with all data
    let newStagesCollectedData = stagesCollectedData;
    const targetIndex = newStagesCollectedData.findIndex(({ stageName }) => stageName === fullStageNameEnum[currentStage.i]);
    if (currentStage.i === 0 || currentStage.i === 1 || currentStage.i === 4) {
      if (targetIndex === -1 && Object.keys(currentStage.obj).length > 0) {
        newStagesCollectedData = [...newStagesCollectedData, currentStage.obj];
      } else {
        newStagesCollectedData[targetIndex] = currentStage.obj;
      }
    }
    setStagesCollectedData(newStagesCollectedData);
    const targetTextName = stagesTextsName[targetIndex !== -1 ? targetIndex : currentStage.i];
    if (typeof stagesTexts[targetTextName] === undefined) {
      setStagesTextsSchemas(prev => ({
        ...prev,
        [targetTextName]: '',
      }))
    }
  }, [currentStage.obj]);

  useEffect(() => {
    // Map text schemas to texts 
    // if (!navBlock) {
    //   setNavBlock(true);
    // }
    const mapedStagesText = {};
    const newStagesTextsSchemas = stagesTextsSchemas;
    for (const stage of stagesTextsName) {
      if (!newStagesTextsSchemas[stage]) {
        newStagesTextsSchemas[stage] = '';
      }
    }
    for (const [key, value] of Object.entries(newStagesTextsSchemas)) {
      const targetStageName = fullStageNameEnum[stagesTextsName.indexOf(key)];
      const stageObj = stagesCollectedData.find(({ stageName }) => stageName === targetStageName) ?? { stageName: targetStageName };
      const sourceList = targetStageName === 'BADANIE DODATKOWE' ? medicalProcedures :
        targetStageName === 'ROZPOZNANIE' ? otherDiagnosis :
          targetStageName === 'ZALECENIA' ? otherMeds : [];
      mapedStagesText[key] = convertSchemaToText(value, stageObj, sourceList);
    }
    setStagesTexts(mapedStagesText);
  }, [stagesTextsSchemas, currentStage.i, currentStage.obj, otherMeds, otherDiagnosis, medicalProcedures]);

  useEffect(() => {
    //UPDATE visit trigger 
    if (currentStage.obj.stageName && !readMode) {
      setDebounce(() => {
        if (navBlock) {
          updateMedicalVisitHandler();
        }
      }, 1000);
    }
  }, [currentStage.i])

  useEffect(() => {
    //SET history
    if (patientMedicalVisits?.length > 0 && currentMedicalVisit._id) {
      const historyVisits = patientMedicalVisits.filter(({ _id }) => _id !== currentMedicalVisit._id);
      const newCurrentIndex = historyVisits.length - 1 === 0 ? 0 : historyVisits.length - 1;
      getHistoryMedicalVisit(historyVisits[newCurrentIndex]?._id);
      if (historyVisits[newCurrentIndex] && historyVisits[newCurrentIndex].visit) {
        setVisitHistory({ i: newCurrentIndex, all: historyVisits, currentDate: historyVisits[newCurrentIndex].visit.start });
      }
    }
  }, [patientMedicalVisits, currentMedicalVisit._id]);

  useEffect(() => {
    if (currentHealthcenter?._id && currentVisit?._id) {
      setServices(prev => ({
        ...prev,
        added: currentVisit?.services.map(servId => currentHealthcenter?.services.find(({ _id }) => _id === servId)),
        list: currentHealthcenter?.services,
        total: currentVisit.cost * 100 / (currentVisit.discount ?? 0 - 100),
        totalWithDiscount: currentVisit.cost ?? 0,
        discount: currentVisit.discount ?? 0,
      }))
    }
  }, [currentHealthcenter]);

  useEffect(() => {
    //Calculate price on discount change
    if (!services.discountError) {
      const newTotal = services.added.reduce((a, b) => a + (b['price'] || 0), 0);
      const newTotalWithDiscount = newTotal - ((newTotal / 100) * services.discount);
      setServices(prev => ({
        ...prev,
        total: newTotal,
        totalWithDiscount: newTotalWithDiscount.toFixed(2)
      }))
    }
  }, [services.added, services.discount]);

  // Tutorial handlers START
  useEffect(() => {
    if (elIsDragging === 'element') {
      nextTutorialStep();
    } else if (elIsDragging === false) {
      if (currentStage.obj.elements?.length < 1) {
        prevTutorialStep();
      } else if (currentStage.obj.elements?.length === 1) {
        nextTutorialStep();
      }
    }
  }, [elIsDragging]);
  // Tutorial handlers END

  return (
    <>
      <Loader hide={currentPatient?._id === currentMedicalVisit.patient && currentVisit?._id && currentVisit?._id === currentMedicalVisit.visit} />
      <VisitView
        currentPatient={currentPatient}
        doctorsElements={doctorsElements}
        currentElement={currentElement}
        currentStage={currentStage}
        setCurrentStage={setCurrentStage}
        elIsDragging={elIsDragging}
        setElIsDragging={setElIsDragging}
        elementsLoading={elementsLoading}
        setCurrentElement={setCurrentElement}
        stagesTexts={stagesTexts}
        setStagesTexts={setStagesTexts}
        dropElementHandler={dropElementHandler}
        changeElementValueHandler={changeElementValueHandler}
        addDocumentHandler={addDocumentHandler}
        documentsModal={documentsModal}
        setDocumentsModal={setDocumentsModal}
        documents={documents}
        warningModal={warningModal}
        setWarningModal={setWarningModal}
        warningModalHandler={warningModalHandler}
        stagesTextsSchemas={stagesTextsSchemas}
        setStagesTextsSchemas={setStagesTextsSchemas}
        getMedicalData={getMedicalData}
        patientMedsDiagnosis={patientMedsDiagnosis}
        patientHospitalizations={patientHospitalizations}
        updateMedicalVisitHandler={updateMedicalVisitHandler}
        visitHistory={visitHistory}
        changeCurrentHistoryVisitHandler={changeCurrentHistoryVisitHandler}
        historyMedicalVisit={historyMedicalVisit}
        historyLoading={historyLoading}
        medsLoading={medsLoading}
        diagnosisLoading={diagnosisLoading}
        proceduresLoading={proceduresLoading}
        diagnosis={diagnosis}
        procedures={procedures}
        meds={meds}
        otherDiagnosis={otherDiagnosis}
        otherMeds={otherMeds}
        medicalProcedures={medicalProcedures}
        services={services}
        setServices={setServices}
        prescriptionModalOn={prescriptionModalOn}
        setPrescriptionModal={setPrescriptionModal}
        changeMedDataHandler={changeMedDataHandler}
        medsSwitch={medsSwitch}
        setMedsSwitch={setMedsSwitch}
        readMode={readMode}
        doctorsElementsSets={doctorsElementsSets}
        changeIndexHandler={changeIndexHandler}
        currentVisit={currentVisit}
        currentMedicalVisit={currentMedicalVisit}
        navBlock={navBlock}
        editMode={editMode}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
        swipeHandlers={swipeHandlers}
        changeTextsSchemasHandler={changeTextsSchemasHandler}
        updateVisit={updateVisit}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.authentication.userRole,
  currentVisit: state.visits.currentVisit,
  currentPatient: state.patients.currentPatient,
  doctorsElements: state.visitElement.doctorsElements,
  doctorsElementsSets: state.visitElementsSet.doctorsElementsSets,
  elementsLoading: state.visitElement.loading,
  currentMedicalVisit: state.medicalVisit.currentMedicalVisit,
  diagnosis: state.medicalRest.diagnosis,
  procedures: state.medicalRest.procedures,
  meds: state.medicalRest.meds,
  patientMedsDiagnosis: state.medsdiagnosis.patientMedsDiagnosis,
  patientHospitalizations: state.hospitalizations.patientHospitalizations,
  patientMedicalVisits: state.medicalVisit.patientMedicalVisits,
  historyMedicalVisit: state.medicalVisit.historyMedicalVisit,
  historyLoading: state.medicalVisit.historyLoading,
  medsLoading: state.medicalRest.medsLoading,
  diagnosisLoading: state.medicalRest.diagnosisLoading,
  proceduresLoading: state.medicalRest.proceduresLoading,
  currentHealthcenter: state.healthcenter.currentHealthcenter,
  navBlock: state.navigation.navBlock,
  sidebarActive: state.navigation.sidebarActive,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateVisit: (visitId, newState, body) => dispatch(updateVisit(visitId, newState, body)),
    getCurrentVisitById: (visitId) => dispatch(getCurrentVisitById(visitId)),
    getPatientById: (patientId, setAsCurrent) => dispatch(getPatientById(patientId, setAsCurrent)),
    getEmployees: (type, id) => dispatch(getEmployees(type, id)),
    getDoctorsVisitElements: (doctorId) => dispatch(getDoctorsVisitElements(doctorId)),
    getDoctorsElementsSet: (doctorId) => dispatch(getDoctorsElementsSet(doctorId)),
    addMedicalVisit: (newMedicalVisit) => dispatch(addMedicalVisit(newMedicalVisit)),
    updateMedicalVisit: (medicalVisitId, body, patientId, target) => dispatch(updateMedicalVisit(medicalVisitId, body, patientId, target)),
    getMedicalVisitByVisitId: (visitId) => dispatch(getMedicalVisitByVisitId(visitId)),
    deletePatientDocument: (documentId) => dispatch(deletePatientDocument(documentId)),
    getMedicalData: (target, settings, loadMore) => dispatch(getMedicalData(target, settings, loadMore)),
    getPatientMedsDiagnosis: (patientId) => dispatch(getPatientMedsDiagnosis(patientId)),
    getPatientHospitalizations: (patientId) => dispatch(getPatientHospitalizations(patientId)),
    getPatientVisitHistory: patientId => dispatch(getPatientVisitHistory(patientId)),
    getPatientMedicalVisits: (patientId) => dispatch(getPatientMedicalVisits(patientId)),
    getHistoryMedicalVisit: (medicalVisitId) => dispatch(getHistoryMedicalVisit(medicalVisitId)),
    getCurrentHealthcenter: (healthcenterId) => dispatch(getCurrentHealthcenter(healthcenterId)),
    removeActiveVisit: visitId => dispatch(removeActiveVisit(visitId)),
    setNavBlock: payload => dispatch(setNavBlock(payload)),
    setSidebarActive: payload => dispatch(setSidebarActive(payload)),
    nextTutorialStep: () => dispatch(nextTutorialStep()),
    prevTutorialStep: () => dispatch(prevTutorialStep()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitContainer);
