import moment from 'moment';

export const sortArrayHandler = (arr, field, sortType = 'string') => {
    // sortType bez - rosnąco, z - malejąco np. -date -> sort wg daty malejąco; date -> sort wg daty rozsnąco itd 
    let direction = true;
    if (sortType[0] === '-') {
        direction = false;
        sortType = sortType.slice(1);
    }
    return [...arr].sort((a, b) => {
        switch (sortType) {
            case 'boolean':
                return direction ? a[field] ? -1 : 1 : a[field] ? 1 : -1;
            case 'number':
                return direction ? a[field] > b[field] : a[field] < b[field];
            case 'string':
                return direction ? a[field] - b[field] : b[field] - a[field];
            case 'date':
                return direction ? new Date(a[field]) - new Date(b[field]) : new Date(b[field]) - new Date(a[field]);
        }
    })
};

export const generateText = (values) => {
    let newText = '';
    Object.values(values).map(({ text, formControls }) => {
        const tags = findAllTags(text);
        tags.map(tag => {
            let { formType, value, displayFormat, selectOptions, checkboxOptions, rangeDictionary, unit, emptyFieldFormat, emptyFieldText } = formControls.find(el => el.name === tag.tagName) ?? {};
            if (!value) {
                value = '';
            }
            switch (formType) {
                case 'input':
                    if (value !== '' && unit) {
                        value = `${value} ${unit}`;
                    }
                    if (rangeDictionary.length > 0) {
                        for (const condition of rangeDictionary) {
                            if (parseFloat(value.replace(',', '.')) >= condition.lowerRange.replace(',', '.') && parseFloat(value.replace(',', '.')) < condition.upperRange.replace(',', '.')) {
                                value = condition.text.replace('{pole}', `${value}`);
                            }
                        }
                    }
                    break;
                case 'checkbox':
                    if (checkboxOptions.options.length === 0) {
                        if (displayFormat === 'custom') {
                            if (value) {
                                value = checkboxOptions.ifTrue;
                            } else {
                                value = checkboxOptions.ifFalse;
                            }
                        } else {
                            if (value) {
                                value = tag.tagName;
                            } else {
                                value = '';
                            }
                        }
                    } else {
                        for (const option of checkboxOptions.options) {
                            if (option.value) {
                                if (displayFormat === 'custom') {
                                    if (value === '') {
                                        value = `${option.text}`;
                                    } else {
                                        value = `${value}, ${option.text}`;
                                    }
                                } else {
                                    if (value === '') {
                                        value = `${option.name}`;
                                    } else {
                                        value = `${value}, ${option.name}`;
                                    }
                                }
                            }
                        }
                    }
                    break;
                case 'select':
                    if (displayFormat === 'custom') {
                        const options = selectOptions.find(el => el.name === value);
                        if (options) {
                            value = options.text;
                        }
                    }
                    break;
                case 'radio':
                    if (displayFormat === 'custom') {
                        const options = selectOptions.find(el => el.name === value);
                        if (options) {
                            value = options.text;
                        }
                    }
                    break;
                case 'datepicker':
                    if (value === '' && emptyFieldFormat === 'normal') {
                        value = Date.now();
                    } else if (value === '' && emptyFieldFormat === 'custom') {
                        value = emptyFieldText;
                    }
                    if (value !== emptyFieldText) {
                        if (displayFormat === 'daysFromNow') {
                            if (new Date(value) > Date.now()) {
                                value = 'Data musi być przeszła';
                            } else {
                                const diffTime = Math.abs(new Date(value) - Date.now());
                                let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                diffDays = diffDays > 0 ? diffDays - 1 : diffDays;
                                value = diffDays === 0 ? `od dzisiaj` : diffDays === 1 ? `od wczoraj` : `od ${diffDays} dni`;
                            }
                        } else if (displayFormat === 'weeks') {
                            if (new Date(value) > Date.now()) {
                                value = 'Data musi być przeszła';
                            } else {
                                const diffTime = Math.abs(new Date(value) - Date.now());
                                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
                                if (diffDays < 7) {
                                    value = 'od niecałego tygodnia';
                                } else if (diffDays >= 7 && diffDays < 14) {
                                    value = 'od tygodnia';
                                } else if (diffDays >= 14) {
                                    let weeks = Math.floor(diffDays / 7);
                                    const months = Math.floor(diffDays / 30);
                                    weeks -= (months * 4);
                                    if (months < 1) {
                                        value = `od ${weeks} tygodnii`;
                                    } else if (months >= 1) {
                                        const weekText = weeks === 0 ? '' : weeks < 2 ? 'i tygodnia' : `i ${weeks} tygodnii`;
                                        const monthText = months < 2 ? 'miesiąca' : `${months} miesięcy`;
                                        value = `od ${monthText}  ${weekText}`;
                                    }
                                }
                            }
                        } else {
                            value = moment(value).format('DD.MM.YYYY');
                        }
                    }
                    break;
                case 'timepicker':
                    if (value === '') {
                        value = Date.now();
                    }
                    if (displayFormat === 'timeFromNow') {
                        value = moment(value).fromNow();
                    } else {
                        if (moment(value).format('HH') === '00') {
                            value = `${moment(value).format('mm')} min`;
                        } else {
                            value = `${moment(value).format('HH:mm')}`;
                        }
                    }
                    break;
                default:
                    value = '{Coś poszło nie tak z szablonem, sprawdź czy nazwy pól się zgadzają we wzorze}'
            }
            text = text.replace(`{${tag.tagName}}`, `<b>${value}</b>`);
        })
        newText = newText + text + ' ';
    });
    return newText;
};

export const findAllTags = (string) => {
    //Finding Tags {} in text
    let startIndexes = [], i = -1;
    while ((i = string.indexOf('{', i + 1)) != -1) {
        startIndexes.push(i);
    }
    let endIndexes = [], j = -1;
    while ((j = string.indexOf('}', j + 1)) != -1) {
        endIndexes.push(j);
    }
    
    let arr = [];
    for (const index in startIndexes) {
        const elemIdStart = string.indexOf('|', endIndexes[index] + 1) + 1;
        const elemIdEnd = string.indexOf('|', elemIdStart + 1);
        arr.push({
            start: startIndexes[index],
            end: endIndexes[index],
            tagName: string.substring(startIndexes[index] + 1, endIndexes[index]),
            elemId: string.substring(elemIdStart, elemIdEnd),
        })
    }
    return arr;
};

export const getFreeVisitSlot = (visits, date, settings) => {
    const momentFormat = 'HH:mm'
    const dateVisits = visits.filter(({ start }) => moment(start).format('DD.MM.YYYY') === moment(date).format('DD.MM.YYYY')).map(({ start, end }) => ({ start: moment(start).format(momentFormat), end: moment(end).format(momentFormat) }));
    let free = false;
    const now = moment();
    let roundTo5 = 5 - (now.minute() % 5);
    if (roundTo5 === 5) {
        roundTo5 = 0;
    }
    let freeStart = (moment(settings?.min).isBefore(now) && moment(date).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) ? now.add(roundTo5, 'minutes') : moment(settings?.min),
        freeSlot;
    while (free == false) {
        freeSlot = {
            start: freeStart.format(momentFormat),
            end: freeStart.add(settings?.step, 'minutes').format(momentFormat)
        };
        free = true;
        dateVisits.forEach(({ start, end }) => {
            if (freeSlot.start === start) {
                free = false;
            } else if (moment(freeSlot.start, momentFormat).isBetween(moment(start, momentFormat), moment(end, momentFormat))) {
                free = false;
            } else if (moment(freeSlot.end, momentFormat).isBetween(moment(start, momentFormat), moment(end, momentFormat))) {
                free = false;
            }
        })
    };
    const start = freeSlot.start.split(':'),
        end = freeSlot.end.split(':');
    const mapedFreeSlot = {
        start: moment(date).set({ 'hour': start[0], 'minute': start[1] }).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(date).set({ 'hour': end[0], 'minute': end[1] }).format('YYYY-MM-DD HH:mm:ss'),
    }
    return mapedFreeSlot;
}

export const getShortenString = (value, maxLength = 15, stringEnding = true) => {
    const startLength = stringEnding ? maxLength - 8 : maxLength - 3;
    if (value.length > maxLength) {
        return `${value.substring(0, startLength)}...${stringEnding ? value.substring(value.length - 5, value.length) : ''}`
    } else {
        return value;
    }
}

export const mapVisitTypesToOptions = (userRole) => {
    if (userRole?.visitTypes) {
        return [
            { label: `Domyślna`, value: 'Domyślna', length: userRole?.callendarSettings?.step },
            ...userRole?.visitTypes.map(({ name, length }) => ({
                label: `${name} - ${length} min`, value: name, length,
            })) ?? []
        ];
    } else {
        return [{ label: `Domyślna`, value: 'Domyślna', length: userRole?.callendarSettings?.step }];
    }
};

export const parseAddress = ({ street = '', city = '', houseNumber = '', apartmentNumber = '', postalCode = '' } = {}, noDataMessage = 'Brak adresu!') => {
    let patientAddress = `${street} ${houseNumber}`;
    if (apartmentNumber !== '') {
        patientAddress += `/${apartmentNumber}`;
    }
    if (city !== '' || postalCode !== '') {
        patientAddress += ',';
    }
    if (city !== '') {
        patientAddress += ` ${city}`;
    }
    if (postalCode !== '') {
        patientAddress += ` ${postalCode}`;
    }
    if (patientAddress === ' ') {
        patientAddress = noDataMessage;
    }
    return patientAddress;
}

export const truncateText = (source, size = 15, stringEnding = false) => {
    const startLength = stringEnding ? size - 10 : size - 3;
    if (source?.length > size) {
      return `${source.substring(0, startLength)}...${
        stringEnding ? source.substring(source.length - 7, source.length) : ''
      }`;
    } else {
      return source;
    }
  };