import React from 'react';
import styled from 'styled-components';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import calendarIcon from './assets/calendar-mouth.svg';
import Label from "./Label";
import { Portal } from 'react-overlays';
registerLocale('pl', pl);

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return (
    <Portal container={el}>
      <StyledPortal>
        {children}
      </StyledPortal>
    </Portal>
  )
}

const DatePicker = ({
  selected,
  name,
  onChange,
  timeFormat,
  label,
  timeIntervals,
  timeCaption,
  dateFormat,
  showTimeSelect,
  disabled,
  error,
  hideError,
  className,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  minDate,
  showBackgroundImage = true,
  popperPlacement,
  preventOverflow = false,
}) => (
    <DatePickerWrapper className={className}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <StyledDatePicker
        id={name}
        name={name}
        selected={selected}
        onChange={date => onChange(date)}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        dateFormat={dateFormat}
        locale={'pl'}
        disabled={disabled}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        showBackgroundImage={showBackgroundImage}
        popperPlacement={popperPlacement}
        popperContainer={preventOverflow && CalendarContainer}
      />
      {!hideError && <ErrorInfo error={error}>{error}</ErrorInfo>}
    </DatePickerWrapper>
  );

export default DatePicker;


const DatePickerWrapper = styled.div`
    width:100%;
    .react-datepicker-wrapper{
      width:100%;
    }
    .react-datepicker__day--selected{
    background-color: ${({ theme }) => theme.primary};
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  border-radius: 4px;
  font-size: 14px;
  margin: 0 2px;
  min-height: 38px;
  padding-left: 10px;
  outline: none;
  -webkit-transition: all 100ms ease 0s;
  transition: all 100ms ease 0s;
  width: 100%;
  margin: 2px 0 2px 0;
  border: 1px solid rgb(204, 204, 204);
  background-size:22px;
  background-position: center right 10px;
  background-repeat:no-repeat;
  ${({ showBackgroundImage }) => showBackgroundImage && `
    background-image: url(${calendarIcon});
  `}
  &:focus {
    border-color: #1890FF;
    border-width: 2px;
    padding-left: 9px;
  }
  ${({ disabled }) => disabled && `
        user-select:none;
        opacity:.8;
        cursor: not-allowed; 
    `}
`;

const ErrorInfo = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.danger};
  text-align: right;
  margin-bottom: 5px;
  visibility: hidden;
  ${({ error }) =>
    error
      ? `
    visibility: visible;`
      : `
    height: 13px;
    `}
`;

const StyledPortal = styled.div`
  .react-datepicker-popper{
    z-index:999;
  }
`;