import React, { useEffect, useState } from "react";
import CompanyView from "./CompanyView";
import { connect } from "react-redux";
import { getCompany, updateCompany, updateCompanyLogo } from "../../redux/modules/company";

const CompanyContainer = ({ company, userRole, getCompany, updateCompany, updateCompanyLogo }) => {
  const [editBasicInfoModal, setEditBasicInfoModal] = useState(false),
    [personalizationModal, setPersonalizationModal] = useState(false);

  useEffect(() => {
    if (userRole !== null) {
      getCompany(userRole.companyId);
    }
  }, [getCompany, userRole]);

  return (
    <CompanyView
      company={company}
      editBasicInfoModal={editBasicInfoModal}
      setEditBasicInfoModal={setEditBasicInfoModal}
      updateCompany={updateCompany}
      personalizationModal={personalizationModal}
      setPersonalizationModal={setPersonalizationModal}
      updateCompanyLogo={updateCompanyLogo}
    />
  );
};

const mapStateToProps = (state) => ({
  company: state.company.data,
  userRole: state.authentication.userRole,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCompany: (companyId) => dispatch(getCompany(companyId)),
    updateCompany: (companyId, newData) => dispatch(updateCompany(companyId, newData)),
    updateCompanyLogo: (companyId, logo) => dispatch(updateCompanyLogo(companyId, logo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainer);
