import axios from "axios";

const GET_PATIENT_SURVEYS_REQUEST = "GET_PATIENT_SURVEYS_REQUEST";
const GET_PATIENT_SURVEYS_SUCCESS = "GET_PATIENT_SURVEYS_SUCCESS";
const GET_PATIENT_SURVEYS_FAIL = "GET_PATIENT_SURVEYS_FAIL";

const UPDATE_PATIENT_SURVEYS_REQUEST = "UPDATE_PATIENT_SURVEYS_REQUEST";
const UPDATE_PATIENT_SURVEYS_SUCCESS = "UPDATE_PATIENT_SURVEYS_SUCCESS";
const UPDATE_PATIENT_SURVEYS_FAIL = "UPDATE_PATIENT_SURVEYS_FAIL";

export const getPatientSurveys = (patientId) => async dispatch => {
    dispatch(getPatientSurveysRequest())
    try {
        const response = await axios.get(`surveys/patient/${patientId}`);
        dispatch(getPatientSurveysSuccess(response.data))
    }
    catch (error) {
        dispatch(getPatientSurveysFail(error))
    }
};

const getPatientSurveysRequest = () => ({
    type: GET_PATIENT_SURVEYS_REQUEST,
});

const getPatientSurveysSuccess = (surveys) => ({
    type: GET_PATIENT_SURVEYS_SUCCESS,
    payload: surveys
});

const getPatientSurveysFail = (error) => ({
    type: GET_PATIENT_SURVEYS_FAIL,
    payload: error
});

export const updatePatientSurveys = (patientId, newSurveys) => async dispatch => {
    dispatch(updatePatientSurveysRequest())
    console.log(newSurveys);
    try {
        const response = await axios.patch(`surveys/patient/${patientId}`, newSurveys)
        dispatch(updatePatientSurveysSuccess(response.data))
    }
    catch (error) {
        dispatch(updatePatientSurveysFail(error))
    }
};

const updatePatientSurveysRequest = () => ({
    type: UPDATE_PATIENT_SURVEYS_REQUEST,
});

const updatePatientSurveysSuccess = (surveys) => ({
    type: UPDATE_PATIENT_SURVEYS_SUCCESS,
    payload: surveys
});

const updatePatientSurveysFail = (error) => ({
    type: UPDATE_PATIENT_SURVEYS_FAIL,
    payload: error
});

const initialState = {
    patientSurveys: {
        anthropometry: [],
        environmentalSurvey: {},
        familySurvey: '',
        pediatricSurvey: {},
        perinatalSurvey: '',
    },
    loading: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT_SURVEYS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_PATIENT_SURVEYS_SUCCESS:
            return {
                ...state,
                patientSurveys: action.payload,
                loading: false
            }
        case GET_PATIENT_SURVEYS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_PATIENT_SURVEYS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_PATIENT_SURVEYS_SUCCESS:
            return {
                ...state,
                patientSurveys: action.payload,
                loading: false
            }
        case UPDATE_PATIENT_SURVEYS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};