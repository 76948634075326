import axios from "axios";

const GET_EXAMINATIONS_FACTORS_REQUEST = "GET_EXAMINATIONS_FACTORS_REQUEST";
const GET_EXAMINATIONS_FACTORS_SUCCESS = "GET_EXAMINATIONS_FACTORS_SUCCESS";
const GET_EXAMINATIONS_FACTORS_FAIL = "GET_EXAMINATIONS_FACTORS_FAIL";

const GET_PATIENT_EXAMINATIONS_REQUEST = "GET_PATIENT_EXAMINATIONS_REQUEST";
const GET_PATIENT_EXAMINATIONS_SUCCESS = "GET_PATIENT_EXAMINATIONS_SUCCESS";
const GET_PATIENT_EXAMINATIONS_FAIL = "GET_PATIENT_EXAMINATIONS_FAIL";

const ADD_PATIENT_EXAMINATION_REQUEST = "ADD_PATIENT_EXAMINATION_REQUEST";
const ADD_PATIENT_EXAMINATION_SUCCESS = "ADD_PATIENT_EXAMINATION_SUCCESS";
const ADD_PATIENT_EXAMINATION_FAIL = "ADD_PATIENT_EXAMINATION_FAIL";

const UPDATE_PATIENT_EXAMINATION_REQUEST = "UPDATE_PATIENT_EXAMINATION_REQUEST";
const UPDATE_PATIENT_EXAMINATION_SUCCESS = "UPDATE_PATIENT_EXAMINATION_SUCCESS";
const UPDATE_PATIENT_EXAMINATION_FAIL = "UPDATE_PATIENT_EXAMINATION_FAIL";

const DELETE_PATIENT_EXAMINATION_REQUEST = "DELETE_PATIENT_EXAMINATION_REQUEST";
const DELETE_PATIENT_EXAMINATION_SUCCESS = "DELETE_PATIENT_EXAMINATION_SUCCESS";
const DELETE_PATIENT_EXAMINATION_FAIL = "DELETE_PATIENT_EXAMINATION_FAIL";

const GET_HEALTHCENTERS_EXAMINATION_PATTERNS_REQUEST = "GET_HEALTHCENTERS_EXAMINATION_PATTERNS_REQUEST";
const GET_HEALTHCENTERS_EXAMINATION_PATTERNS_SUCCESS = "GET_HEALTHCENTERS_EXAMINATION_PATTERNS_SUCCESS";
const GET_HEALTHCENTERS_EXAMINATION_PATTERNS_FAIL = "GET_HEALTHCENTERS_EXAMINATION_PATTERNS_FAIL";

const ADD_EXAMINATION_PATTERN_REQUEST = "ADD_EXAMINATION_PATTERN_REQUEST";
const ADD_EXAMINATION_PATTERN_SUCCESS = "ADD_EXAMINATION_PATTERN_SUCCESS";
const ADD_EXAMINATION_PATTERN_FAIL = "ADD_EXAMINATION_PATTERN_FAIL";

const DELETE_EXAMINATION_PATTERN_REQUEST = "DELETE_EXAMINATION_PATTERN_REQUEST";
const DELETE_EXAMINATION_PATTERN_SUCCESS = "DELETE_EXAMINATION_PATTERN_SUCCESS";
const DELETE_EXAMINATION_PATTERN_FAIL = "DELETE_EXAMINATION_PATTERN_FAIL";

export const getExaminationFactors = (searchQuery = "") => async dispatch => {
    dispatch(getExaminationFactorsRequest())
    try {
        const response = await axios.get(`factor/?search=${searchQuery}`);
        dispatch(getExaminationFactorsSuccess(response.data))
    }
    catch (error) {
        dispatch(getExaminationFactorsFail(error))
    }
}

const getExaminationFactorsRequest = () => ({
    type: GET_EXAMINATIONS_FACTORS_REQUEST,
});

const getExaminationFactorsSuccess = (factors) => ({
    type: GET_EXAMINATIONS_FACTORS_SUCCESS,
    payload: factors
});

const getExaminationFactorsFail = (error) => ({
    type: GET_EXAMINATIONS_FACTORS_FAIL,
    payload: error
});

export const getPatientExaminations = (patientId) => async dispatch => {
    dispatch(getPatientExaminationsRequest())
    try {
        const response = await axios.post(`examination/patient/${patientId}`)
        dispatch(getPatientExaminationsSuccess(response.data))
    }
    catch (error) {
        dispatch(getPatientExaminationsFail(error))
    }
}

const getPatientExaminationsRequest = () => ({
    type: GET_PATIENT_EXAMINATIONS_REQUEST,
});

const getPatientExaminationsSuccess = (examinations) => ({
    type: GET_PATIENT_EXAMINATIONS_SUCCESS,
    payload: examinations
});

const getPatientExaminationsFail = (error) => ({
    type: GET_PATIENT_EXAMINATIONS_FAIL,
    payload: error
});

export const addPatientExamination = (examination) => async dispatch => {
    addPatientExaminationRequest();
    try {
        const response = await axios.post(`examination/`, examination);
        dispatch(addPatientExaminationSuccess(response.data))
    }
    catch (error) {
        dispatch(addPatientExaminationFail(error))
    }
}

const addPatientExaminationRequest = () => ({
    type: ADD_PATIENT_EXAMINATION_REQUEST,
});

const addPatientExaminationSuccess = (examination) => ({
    type: ADD_PATIENT_EXAMINATION_SUCCESS,
    payload: examination
});

const addPatientExaminationFail = (error) => ({
    type: ADD_PATIENT_EXAMINATION_FAIL,
    payload: error
});

export const updatePatientExamination = (examination, examinationId) => async dispatch => {
    updatePatientExaminationRequest();
    try {
        const response = await axios.patch(`examination/${examinationId}`, examination);
        dispatch(updatePatientExaminationSuccess(response.data))
    }
    catch (error) {
        dispatch(updatePatientExaminationFail(error))
    }
}

const updatePatientExaminationRequest = () => ({
    type: UPDATE_PATIENT_EXAMINATION_REQUEST,
});

const updatePatientExaminationSuccess = (examination) => ({
    type: UPDATE_PATIENT_EXAMINATION_SUCCESS,
    payload: examination
});

const updatePatientExaminationFail = (error) => ({
    type: UPDATE_PATIENT_EXAMINATION_FAIL,
    payload: error
});

export const deletePatientExamination = (examinationId) => async dispatch => {
    deletePatientExaminationRequest();
    try {
        const response = await axios.delete(`examination/${examinationId}`);
        dispatch(deletePatientExaminationSuccess(examinationId))
    }
    catch (error) {
        dispatch(deletePatientExaminationFail(error))
    }
}

const deletePatientExaminationRequest = () => ({
    type: DELETE_PATIENT_EXAMINATION_REQUEST,
});

const deletePatientExaminationSuccess = (examinationId) => ({
    type: DELETE_PATIENT_EXAMINATION_SUCCESS,
    payload: examinationId
});

const deletePatientExaminationFail = (error) => ({
    type: DELETE_PATIENT_EXAMINATION_FAIL,
    payload: error
});

export const addExaminationPattern = (pattern) => async dispatch => {
    addExaminationPatternRequest();
    try {
        const response = await axios.post(`examinationpattern/`, pattern);
        dispatch(addExaminationPatternSuccess(response.data))
    }
    catch (error) {
        dispatch(addExaminationPatternFail(error))
    }
}

const addExaminationPatternRequest = () => ({
    type: ADD_EXAMINATION_PATTERN_REQUEST,
});

const addExaminationPatternSuccess = (pattern) => ({
    type: ADD_EXAMINATION_PATTERN_SUCCESS,
    payload: pattern
});

const addExaminationPatternFail = (error) => ({
    type: ADD_EXAMINATION_PATTERN_FAIL,
    payload: error
});

export const deleteExaminationPattern = (patternId) => async dispatch => {
    deleteExaminationPatternRequest();
    try {
        const response = await axios.delete(`examinationpattern/${patternId}`);
        dispatch(deleteExaminationPatternSuccess(patternId))
    }
    catch (error) {
        dispatch(deleteExaminationPatternFail(error))
    }
}

const deleteExaminationPatternRequest = () => ({
    type: DELETE_EXAMINATION_PATTERN_REQUEST,
});

const deleteExaminationPatternSuccess = (patternId) => ({
    type: DELETE_EXAMINATION_PATTERN_SUCCESS,
    payload: patternId
});

const deleteExaminationPatternFail = (error) => ({
    type: DELETE_EXAMINATION_PATTERN_FAIL,
    payload: error
});

export const getHealthcentersExaminationPatterns = (healthcenterId) => async dispatch => {
    getHealthcentersExaminationPatternsRequest();
    try {
        const response = await axios.get(`examinationpattern/healthcenter/${healthcenterId}`);
        dispatch(getHealthcentersExaminationPatternsSuccess(response.data))
    }
    catch (error) {
        dispatch(getHealthcentersExaminationPatternsFail(error))
    }
}

const getHealthcentersExaminationPatternsRequest = () => ({
    type: GET_HEALTHCENTERS_EXAMINATION_PATTERNS_REQUEST,
});

const getHealthcentersExaminationPatternsSuccess = (patterns) => ({
    type: GET_HEALTHCENTERS_EXAMINATION_PATTERNS_SUCCESS,
    payload: patterns
});

const getHealthcentersExaminationPatternsFail = (error) => ({
    type: GET_HEALTHCENTERS_EXAMINATION_PATTERNS_FAIL,
    payload: error
});

const initialState = {
    examinationFactors: [],
    examinationPatterns: [],
    patientExaminations: [],
    loading: false,
    factorsLoading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT_EXAMINATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_PATIENT_EXAMINATIONS_SUCCESS:
            return {
                ...state,
                patientExaminations: action.payload,
                loading: false
            }
        case GET_PATIENT_EXAMINATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_EXAMINATIONS_FACTORS_REQUEST:
            return {
                ...state,
                factorsLoading: true,
            }
        case GET_EXAMINATIONS_FACTORS_SUCCESS:
            return {
                ...state,
                examinationFactors: action.payload,
                factorsLoading: false
            }
        case GET_EXAMINATIONS_FACTORS_FAIL:
            return {
                ...state,
                factorsLoading: false,
                error: action.payload
            }
        case ADD_PATIENT_EXAMINATION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADD_PATIENT_EXAMINATION_SUCCESS:
            return {
                ...state,
                patientExaminations: [...state.patientExaminations, action.payload],
                loading: false
            }
        case ADD_PATIENT_EXAMINATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_PATIENT_EXAMINATION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_PATIENT_EXAMINATION_SUCCESS:
            return {
                ...state,
                patientExaminations: state.patientExaminations.map(el => el._id === action.payload._id ? action.payload : el),
                loading: false
            }
        case UPDATE_PATIENT_EXAMINATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_PATIENT_EXAMINATION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DELETE_PATIENT_EXAMINATION_SUCCESS:
            return {
                ...state,
                patientExaminations: state.patientExaminations.filter(el => el._id !== action.payload),
                loading: false
            }
        case DELETE_PATIENT_EXAMINATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_HEALTHCENTERS_EXAMINATION_PATTERNS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_HEALTHCENTERS_EXAMINATION_PATTERNS_SUCCESS:
            return {
                ...state,
                examinationPatterns: action.payload,
                loading: false
            }
        case GET_HEALTHCENTERS_EXAMINATION_PATTERNS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ADD_EXAMINATION_PATTERN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADD_EXAMINATION_PATTERN_SUCCESS:
            return {
                ...state,
                examinationPatterns: [...state.examinationPatterns, action.payload],
                loading: false
            }
        case ADD_EXAMINATION_PATTERN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_EXAMINATION_PATTERN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DELETE_EXAMINATION_PATTERN_SUCCESS:
            return {
                ...state,
                examinationPatterns: state.examinationPatterns.filter(el => el._id !== action.payload),
                patientExaminations: state.patientExaminations.filter(el => el._id !== action.payload),
                loading: false
            }
        case DELETE_EXAMINATION_PATTERN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};