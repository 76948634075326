import React from "react";
import styled from "styled-components";
import Label from "./Label";

const Input = ({
  type,
  name,
  variant,
  label,
  size,
  onChange,
  onBlur,
  value,
  placeholder,
  error,
  className,
  checkboxText,
  radioOptions,
  checkboxOptions,
  disabled,
  direction,
  wrapperHeight,
  hideError,
  color,
  autoComplete,
  noWrapError = false,
}) => (
    <InputWrapper direction={direction} label={label}>
      {label && <Label htmlFor={name}>{label}</Label>}
      {type === 'radio' && radioOptions ?
        <RadioWrapper wrapperHeight={wrapperHeight}>
          {radioOptions.map(({ label, value: radioVal, disabled: RadioDisabled = disabled }, index) => (
            <RadioItem size={size} key={`RadioItem_${index}`}>
              <StyledInput
                name={name}
                type={type ? type : "text"}
                variant={error ? "danger" : variant ? variant : "default"}
                size={size}
                onChange={() => onChange(name, radioVal)}
                value={value}
                placeholder={placeholder}
                className={className}
                disabled={RadioDisabled}
              />
              <RadioLabel onClick={() => onChange(name, radioVal)} disabled={RadioDisabled} htmlFor={radioVal}>{label}</RadioLabel>
              <RadioOverlay
                disabled={RadioDisabled}
                size={size ? size : '16px'}
                variant={error ? "danger" : variant ? variant : "default"}
                checked={radioVal === value}
              />
            </RadioItem>
          ))}
        </RadioWrapper>
        : type === 'checkbox' ?
          checkboxOptions?.length > 0 ?
            <CheckboxsWrapper wrapperHeight={wrapperHeight} className={className}>
              {checkboxOptions.map(({ label, value, disabled: CheckDisabled = disabled }, index) => (
                <CheckboxItem key={`CheckboxItem_${index}`}>
                  <StyledInput
                    onBlur={onBlur}
                    name={name}
                    type={type ? type : "text"}
                    variant={error ? "danger" : variant ? variant : "default"}
                    size={size}
                    onChange={() => onChange(index)}
                    value={value}
                    placeholder={placeholder}
                    disabled={CheckDisabled}
                    autoComplete={autoComplete ? autoComplete : "on"}
                  />
                  <CheckboxOverlay color={color} size={size} variant={error ? "danger" : variant ? variant : "default"} checked={value} disabled={disabled} />
                  <CheckboxText size={size} onClick={() => onChange(index)} disabled={CheckDisabled}>
                    {label}
                  </CheckboxText>
                </CheckboxItem>
              ))}
            </CheckboxsWrapper>
            :
            <CheckboxsWrapper className={className}>
              <StyledInput
                onBlur={onBlur}
                name={name}
                id={name}
                type={type ? type : "text"}
                variant={error ? "danger" : variant ? variant : "default"}
                size={size}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                className={className}
                disabled={disabled}
                autoComplete={autoComplete ? autoComplete : "on"}
              />
              <CheckboxOverlay color={color} size={size} variant={error ? "danger" : variant ? variant : "default"}
                checked={value} disabled={disabled} />
              <CheckboxText size={size} onClick={onChange} disabled={disabled}>
                {checkboxText}
              </CheckboxText>
            </CheckboxsWrapper>
          :
          <>
            <StyledInput
              onBlur={onBlur}
              name={name}
              id={name}
              type={type ? type : "text"}
              variant={error ? "danger" : variant ? variant : "default"}
              size={size}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              className={className}
              disabled={disabled}
              autoComplete={autoComplete ? autoComplete : "on"}
            />
          </>
      }
      {!hideError && <ErrorInfo noWrapError={noWrapError} error={error}>{error}</ErrorInfo>}
    </InputWrapper >
  );

export default Input;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  ${({ direction }) => direction == "row" && `
    align-items: center;
  `};
`;

const StyledInput = styled.input`
      border-radius: 4px;
      font-size: 14px;
      min-height: ${({ size, type }) =>
    (type !== "checkbox" && type !== "radio")
      ? size
        ? size
        : "38px"
      : "auto"};
      padding-left: 10px;
      outline: none;
      transition: all 100ms ease 0s;
      width: ${({ size }) => (size ? size : "100%")};
      height: ${({ size }) => size};
      margin: 2px 0 2px 0;
  &:focus {
      border-color: ${({ theme }) => theme.primary};
      border-width: 2px;
      padding-left: 9px;
    }
  ${({ variant, theme }) =>
    variant === "default"
      ? `
      border: 1px solid rgb(204, 204, 204);
`
      : variant === "primary"
        ? `
    border: 1px solid ${theme.primary};
`
        : variant === "danger"
          ? `
    border: 2px solid ${theme.danger}!important;
`
          : variant === "dark"
            ? `
    border: 1px solid ${theme.darkGrey};
`
            : ``}
      ${({ size }) =>
    size === "small"
      ? `
    min-height: 28px;
`
      : size === "big"
        ? `
    min-height: 46px;
`
        : ``}
        ${({ disabled }) =>
    disabled &&
    `
        user-select:none;
        color:#ccc;0
        background-color:#f7f7f7;
        cursor: not-allowed; 
      `}

      ${({ type, size }) =>
    type === "radio" &&
    (size === "big"
      ? `
          height:32px;
          width:32px;
          `
      : size === "medium"
        ? `
          height:24px;
          width:24px;
          `
        : `
          height:16px;
          width:16px;
          `)}

      &[type='checkbox'], &[type='radio']{
        opacity: 0;
        width: ${({ size }) => (size ? size : "18px")};
        height: ${({ size }) => (size ? size : "18px")};
        position:absolute;
        top:2px;
        margin:auto;
        cursor:${({ variant }) => variant !== "default" && "pointer"};
        z-index: 9;
        ${({ disabled }) =>
    disabled &&
    `
            user-select:none;
            opacity:.3;
            cursor: not-allowed; 
          `}
    }
    &[type='radio']{
        top: auto;
        left:0;
      ${({ disabled }) =>
    disabled &&
    `
        user-select:none;
        opacity:.3;
        cursor: not-allowed; 
      `}
    }
    
`;
const CheckboxText = styled.label`
  padding: 1.5px 10px 0 23px;
  font-size: 14px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled && `
      user-select:none;
      pointer-events:none;
    `}
`;

const CheckboxOverlay = styled.div`
      width: ${({ size }) => (size ? size : "18px")};
      height: ${({ size }) => (size ? size : "18px")};
      border-radius: 4px;
      transition:300ms;
      position: absolute;
      top:2px;
    ${({ variant, theme, checked, color }) =>
    color
      ? checked
        ? `border: 1px solid ${color}; background-color: ${color};`
        : `border: 1px solid ${color};`
      : variant === "default"
        ? checked
          ? ` border: 1px solid rgb(204, 204, 204); background-color:rgb(204,204,204);`
          : ` border: 1px solid rgb(204, 204, 204);`
        : variant === "primary"
          ? checked
            ? ` border: 1px solid ${theme.primary};
          background-color: ${theme.primary};
      `
            : `
          border: 1px solid ${theme.primary};
      `
          : variant === "secondary"
            ? checked
              ? ` border: 1px solid ${theme.secondary};
          background-color: ${theme.secondary};
      `
              : `
          border: 1px solid ${theme.secondary};
      `
            : variant === "danger"
              ? checked
                ? `border: 1px solid ${theme.danger};
           background-color: ${theme.danger};
        `
                : `
           border: 1px solid ${theme.danger};
        `
              : variant === "dark"
                ? checked
                  ? `
            border: 1px solid ${theme.darkGrey};
            background-color: ${theme.darkGrey};
      `
                  : `
            border: 1px solid ${theme.darkGrey};
      `
                : ``}
    ${({ disabled }) =>
    disabled &&
    `
        user-select:none;
        opacity:.3;
        cursor: not-allowed;
      `}
      &::after{
        content:'';
        position: absolute;
        left: 23%;
        top: 57%;
        margin: auto;
        display:block;
        width:25%;
        height:50%;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: scaleX(-1) rotate(140deg);
        transform-origin: left top;
        ${({ disabled }) =>
    disabled &&
    `
            user-select:none;
            cursor: not-allowed; 
          `};
          ${({ checked }) => checked && "animation: check 800ms"};
        @keyframes check {
          0% {
            height: 0;
            width: 0;
          }
          25% {
             height: 0;
             width: 25%;
          }
          50% {
             height: 50%;
             width: 25%;
          }
        }
      }
`;


const RadioWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  justify-content: space-around;
  ${({ wrapperHeight }) => wrapperHeight && `max-height:${wrapperHeight};`};
  flex-wrap:wrap;
`;

const RadioItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 0;
  padding-left: ${({ size }) =>
    size === "big"
      ? `
        32px
        `
      : size === "medium"
        ? `
          24px
        `
        : `
          16px;
        `};
`;

const RadioLabel = styled.label`
  display: flex;
  padding: 0 2.5px;
  font-size: 12px;
  cursor: pointer;
  ${({ disabled }) => disabled && `
    user-select:none;
    opacity:.3;
    cursor: not-allowed; 
  `};
`;

const RadioOverlay = styled.div`
  ${({ size }) =>
    size === "big"
      ? `
          width: 32px; 
          height: 32px; 
        `
      : size === "medium"
        ? `
          width:24px;
          height:24px;
        `
        : `
          width:16px;
          height:16px;
        `};
  border-radius: 50%;
  transition: 300ms;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    `
      user-select:none;
      opacity:.3;
      cursor: not-allowed; 
    `};
  ${({ variant, theme }) =>
    variant === "default"
      ? `
      border: 2px solid rgb(204, 204, 204);
`
      : variant === "primary"
        ? `
    border: 2px solid ${theme.primary};
`
        : variant === "secondary"
          ? `
    border: 2px solid ${theme.secondary};
`
          : variant === "danger"
            ? `
    border: 2px solid ${theme.danger};
`
            : variant === "dark"
              ? `
    border: 2px solid ${theme.darkGrey};
`
              : ``}
  &::after {
    ${({ size }) =>
    size === "big"
      ? `
                width: 16px; 
                height: 16px; 
              `
      : size === "medium"
        ? `
                width:12px;
                height:12px;
              `
        : `
                width:8px;
                height:8px;
              `};
    position: absolute;
    margin: auto;
    content: "";
    transition: 300ms;
    display: block;
    border-radius: 50%;
    transform-origin: center;
    transform: scale(0);
    ${({ variant, theme }) =>
    variant === "default"
      ? `
          background-color: rgb(204, 204, 204);
            `
      : variant === "primary"
        ? `
            background-color: ${theme.primary};
            `
        : variant === "secondary"
          ? `
            background-color: ${theme.secondary};
            `
          : variant === "danger"
            ? `
              background-color: ${theme.danger};
            `
            : variant === "dark"
              ? `
                background-color: ${theme.darkGrey};
            `
              : ``}
    ${({ checked }) =>
    checked &&
    `
        transform: scale(1);
        animation: grow 400ms;
      `};
  }
  @keyframes grow {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;


const ErrorInfo = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.danger};
  text-align: right;
  margin-bottom: 5px;
  visibility: hidden;
  ${({ noWrapError }) => noWrapError && 'white-space:nowrap;'}
  ${({ error }) =>
    error
      ? `
  visibility: visible;`
      : `
  height: 13px;
`}
`;

const CheckboxsWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  justify-content: space-around;
  ${({ wrapperHeight }) => wrapperHeight && `max-height:${wrapperHeight};`};
  flex-wrap:wrap;
`;

const CheckboxItem = styled.div`
  position:relative;
  margin:5px;
  display:flex;
  align-items:center;
  label{
    top:auto;
  }
`