import React, { useState, useEffect } from "react";
import VisitTabView from "./VisitsTabView";
import { connect } from "react-redux";
import { withRouter, useParams } from 'react-router-dom';
import { getVisitsByPatientId, updateVisit,addActiveVisit } from '../../../../../redux/modules/visits';
import moment from 'moment';

const VisitTabContainer = ({ currentPatient, currentPatientVisits, doctors, nurses, updateVisit, getVisitsByPatientId, pathname, healthcenters, history, user, paginationMetadata, patients,addActiveVisit }) => {

  const [visitViewValue, setVisitViewValue] = useState({ value: 'all', label: 'Wszystkie' });
  const [visitList, setVisitList] = useState(currentPatientVisits);
  const [sortValue, setSortValue] = useState({ value: 'start desc', label: 'Data - malejąco', field: 'start' });
  const { currentTab } = useParams();

  useEffect(() => {
    if (currentTab === "visits" && currentPatient._id) {
      switch (visitViewValue.value) {
        case 'all':
          getVisitsByPatientId(currentPatient._id);
          break;
        case 'range':
          getVisitsByPatientId(currentPatient._id, visitViewValue?.rangeStart, visitViewValue?.rangeEnd);
          break;
        case 'month':
          getVisitsByPatientId(currentPatient._id, moment().subtract(1, 'month'), moment());
          break;
      }
    }
  }, [currentPatient, pathname, currentTab, patients, visitViewValue, sortValue]);

  const onPatientVisitsPageChange = (pageChangeDirection) => {
    let pageNumber = 1;
    if (pageChangeDirection === "PREV_PAGE") {
      pageNumber = paginationMetadata.currentPage - 1
    } else if (pageChangeDirection === "NEXT_PAGE") {
      pageNumber = paginationMetadata.currentPage + 1
    }
    switch (visitViewValue.value) {
      case 'init':
      case 'all':
        getVisitsByPatientId(currentPatient._id, undefined, undefined, sortValue.value, pageNumber);
        break;
      case 'range':
        getVisitsByPatientId(currentPatient._id, visitViewValue?.rangeStart, visitViewValue?.rangeEnd, sortValue.value, pageNumber);
        break;
      case 'month':
        getVisitsByPatientId(currentPatient._id, moment().subtract(1, 'month'), moment(), sortValue.value, pageNumber);
        break;
    }
  }

  return <VisitTabView
    setVisitViewValue={setVisitViewValue}
    visitViewValue={visitViewValue}
    visitList={currentPatientVisits}
    setVisitList={setVisitList}
    sortValue={sortValue}
    setSortValue={setSortValue}
    doctors={[...doctors, ...nurses]}
    updateVisit={updateVisit}
    currentTab={currentTab}
    healthcenters={healthcenters}
    history={history}
    user={user}
    paginationMetadata={paginationMetadata}
    onPatientVisitsPageChange={onPatientVisitsPageChange}
    addActiveVisit={addActiveVisit}
  />;
};


const mapStateToProps = state => ({
  currentPatient: state.patients.currentPatient,
  currentPatientVisits: state.visits.data,
  doctors: state.employees.doctors,
  nurses: state.employees.nurses,
  healthcenters: state.healthcenter.data,
  user: state.authentication.user,
  patients: state.patients.data,
  paginationMetadata: state.visits.paginationMetadata,
});

const mapDispatchToProps = dispatch => {
  return {
    updateVisit: (visitId, newState, body) =>
      dispatch(updateVisit(visitId, newState, body)),
      addActiveVisit: visit => dispatch(addActiveVisit(visit)),
    getVisitsByPatientId: (
      patientId,
      start,
      end,
      orderBy,
      pageNumber,
      pageSize,
      doctorFlag
    ) =>
      dispatch(
        getVisitsByPatientId(
          patientId,
          start,
          end,
          orderBy,
          pageNumber,
          pageSize,
          doctorFlag
        )
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VisitTabContainer));
