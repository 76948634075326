import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../Shared/assets/upload.svg';

const FileUpload = ({
    onDrop,
    name,
    label,
    value,
    error,
    hideError,
    placeholder = 'Dodaj lub upuść plik',
    disabled = false,
    children,
    testId,
    className
}) => {
    const { getRootProps, getInputProps } = useDropzone({ onDrop })
    return (
        <FileUploadWrapper className={className}>
            {!children && <Label>{label}</Label>}
            <FileUploadBox data-testid={testId} {...getRootProps()} disabled={disabled} hide={children !== undefined ? true : false}>
              <StyledInput
                    name={name}
                    {...getInputProps()}
                />
                 {!children && <><InputValue>
                    {value ?
                        value.name?.length > 35 ?
                            `Dodano ${value.name.substring(0, 15)}...${value.name.substring(value.name.length - 10, value.name.length)}`
                            : value.name
                        : placeholder}
                </InputValue></>}
                {children}
            </FileUploadBox>
            {!hideError && <ErrorInfo error={error}>{error}</ErrorInfo>}
                      
        </FileUploadWrapper>
    )
}

export default FileUpload;
const FileUploadWrapper = styled.div`
    display:flex;
    flex-direction:column;
`;

const FileUploadBox = styled.div`
    padding:20px;
    margin:2px 0;
    border:1px solid rgb(204,204,204);
    border-radius: 4px;
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    outline: none;
    cursor: pointer;
    &:focus{
        border-color: #1890FF;
        border-width: 2px;
        padding: 19px;
        &::before{
            left:14px;
        }
    }
    &::before{
        content:url(${UploadIcon});
        display:block;
        height:25px;
        width:25px;
        filter: invert(43%) sepia(46%) saturate(3326%) hue-rotate(191deg) brightness(102%) contrast(101%);
        position:absolute;
        left:15px;
    }
    ${({disabled})=>disabled && `
        pointer-events:none;
        user-select:none;
        opacity:.3;
        cursor: not-allowed; 
    `}
    ${({hide}) => hide && `
    border: none;
    border-width: 0;
    padding: 0!important;
    &::before {
      display: none;
    }
    &::focus {
        border-width: 0;
        padding: 0!important;
    }
    `}
`;

const StyledInput = styled.input`

`;

const Label = styled.label`
 margin-left: 5px;
`;

const InputValue = styled.p`
    text-align:center;
    font-size:14px;
    color: #ccc;
`;

const ErrorInfo = styled.p`
 font-size: 10px;
  color: ${({ theme }) => theme.danger};
      text-align: right;
      margin-bottom: 5px;
      visibility: hidden;
  ${({ error }) =>
        error ? ` visibility: visible;`
            : `height: 13px;`}
`;