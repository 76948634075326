const stepConfig = {
    floaterProps: {
        disableAnimation: true,
    }
}

export const tutorialsNames = {
    'Registry/': 'calendar',
    'Registry/patients/visits': 'patients',
    'Registry/profile': 'profile',
    'Doctor|Nurse/': 'dashboard',
    'Doctor|Nurse/calendar': 'calendar',
    'Doctor|Nurse/profile': 'profile',
    'Doctor|Nurse/patients/visits': 'patients',
    'Doctor|Nurse/patients/examinations': 'examinations',
    'Doctor|Nurse/patients/documents': 'documents',
    'Doctor|Nurse/patients/medsdiagnosis': 'medsdiagnosis',
    'Doctor|Nurse/patients/riskfactors': 'riskfactors',
    'Doctor|Nurse/patients/hospitalizations': 'hospitalizations',
    'Doctor|Nurse/patients/surveys': 'surveys',
    'Doctor|Nurse/elementsList': 'elementsList',
    'Doctor|Nurse/elements': 'elements',
    'Doctor|Nurse/visit': 'visit',
}

export const steps = {
    //Name pattern is "${user.activeRole}${pathname}"
    'Registry/': [
        {
            ...stepConfig,
            title: 'Nawigacja',
            target: '#menu',
            content: 'Aby zmienić podstronę użyj tego panelu',
            placement: 'bottom',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Nawigacja',
            target: 'div[title="Wiadomości"]',
            content: 'Czat pozwala na komunikację z pozostałymi pracownikami firmy. Aby otworzyć czat firmy oraz placówek, kliknij w kopertę. Gdy będziesz miał jakieś nieodczytane wiadomości w rogu koperty pojawi się czerwona kropka.',
            placement: 'bottom',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Nawigacja',
            target: '#menuDropdown',
            content: 'Aby przejść do swojego profilu lub wylogować się, otwórz menu przy pomocy tego przycisku i wybierz daną opcję.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '.react-calendar',
            content: 'Ten kalendarz pozwala na wybór aktywnej daty, aby zmień datę kliknij na konkretny dzień w kalendarzu.',
            placement: 'right',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '.react-calendar .react-calendar__navigation',
            content: 'Aby szybko zmienić miesiąc lub rok użyj tego panelu.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#backToToday',
            content: 'Aby wrócić do dnia dzisiejszego, kliknij tutaj.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarFilters',
            content: 'Ten panel pozwala na zarządzanie wyświetlaniem wizyt konkretnych lekarzy i grup.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '.collapse-list-actions',
            content: 'Możesz zwinąć listę lub zaznaczyć i odznaczyć wszystkie pozycje na raz.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarToolbar',
            content: 'Z tego panelu możesz zarządzać widokami oraz datą aktywną kalendarza.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarToolbar div:nth-child(1) >div',
            content: 'Tu możesz zmienić zakres kalendarza na dzień, tydzień lub miesiąc.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarNavArrows',
            content: 'Tu możesz nawigować aktywną datą.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarToolbar div:nth-child(3)',
            content: 'Tu możesz zmienić aktywną placówkę i widok kalendarza.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '.rbc-day-slot .rbc-timeslot-group:nth-child(3)',
            content: 'Aby dodać wizytę kliknij i przeciągnij na miejscu godziny która Cię interesuje.',
            placement: 'top',
        },
    ],
    'Registry/patients/visits': [
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: '#addNewPatientBtn',
            content: 'Tutaj możesz uzupełnić dane lub awatar pacjenta, aby go dodać kliknij "Dodaj pacjenta" po uzupełnieniu niezbędnych pól.',
            continueInfo: 'Kliknij w przycisk "Dodaj pacjenta" lub zamknij okno klikająć w "X" aby kontynuować',
            placement: 'right',
            disableBeacon: true,
            hideBtns: true,
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: 'div[title="Dodawanie pacjenta"]',
            content: 'Tutaj możesz uzupełnić dane lub awatar pacjenta, aby go dodać kliknij "Dodaj pacjenta" po uzupełnieniu niezbędnych pól.',
            continueInfo: 'Kliknij w przycisk "Dodaj pacjenta" lub zamknij okno klikająć w "X" aby kontynuować',
            placement: 'right',
            hideBtns: true,
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: '#patientsList',
            content: 'Tutaj wyświetla się lista wszystkich pacjentów, klikając w pacjenta ustawisz go jako aktywnego. Dzięki temu wyświetlą się jego szczegółowe dane.',
            hideBackBtn: true,
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: 'div[data-testid="patientInfoHeader"]',
            content: 'Tutaj wyświetlają się dane szczegółowe aktywnego pacjenta',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: 'button[title="Edytuj pacjenta"]',
            content: 'Aby edytować dane pacjenta lub go usunąć, kliknij tutaj.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: '#visits ul',
            content: 'Tutaj jest lista wszystkich wizyt aktywnego pacjenta wraz z szczegółowymi danymi na temat tej wizyty. Aby wyświelić szczegółowe dane rozwiń wybrany element za pomocą niebieskiej strzałki.',
            placement: 'top',
        }
    ],
    'Registry/profile': [
        {
            ...stepConfig,
            title: 'Profil',
            target: 'button[title="Edytuj profil"]',
            content: 'Aby edytować Twoje dane takie jak imię, nazwisko, email lub czas trwania wizyty w kalendarzu oraz godziny pracy kliknij tutaj.',
            placement: 'right',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: 'button[title="Edytuj ustawienia"]',
            content: 'Jeżeli chcesz wyłączyć lub powtórzyć któryś z samouczków, możesz to zrobić tutaj.',
            placement: 'right',
        },
    ],
    'Doctor|Nurse/': [
        {
            ...stepConfig,
            title: 'Nawigacja',
            target: '#menu',
            content: 'Aby zmienić podstronę użyj tego panelu.',
            placement: 'bottom',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Nawigacja',
            target: 'div[title="Wiadomości"]',
            content: 'Czat pozwala na komunikację z pozostałymi pracownikami firmy. Aby otworzyć czat firmy oraz placówek, kliknij w kopertę. Gdy będziesz miał jakieś nieodczytane wiadomości w rogu koperty pojawi się czerwona kropka.',
            placement: 'bottom',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Nawigacja',
            target: '#menuDropdown',
            content: 'Aby przejść do swojego profilu, zarządzania elementami wizyt lub wylogować się, otwórz menu przy pomocy tego przycisku i wybierz daną opcję.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '.react-calendar',
            content: 'Ten kalendarz pozwala na wybór aktywnej daty, aby zmień datę kliknij na konkretny dzień w kalendarzu.',
            placement: 'right',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '.react-calendar .react-calendar__navigation',
            content: 'Aby szybko zmienić miesiąc lub rok użyj tego panelu.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#backToToday',
            content: 'Aby wrócić do dnia dzisiejszego, kliknij tutaj.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#dashboardAddNewVisit',
            content: 'Ten przycisk po kliknięciu otwiera okno z formularzem dodawania wizyty.',
            continueInfo: 'Kliknij w przycisk "Dodaj nową wizytę" aby kontynuować',
            hideNextBtn: true,
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: 'div[title="Dodawanie wydarzenia"]',
            content: 'Wypełniając ten formularz zdefinujesz ramy czasowe wizyty, lokalizację, osoby biorące w niej udział oraz opcjonalnie dodatkowe informacje i typ wizyty. Typy wizyt są definiowane przez wybranego doktora lub pielęgniarkę, każdy typ posiada czas trawania który po wybraniu automatycznie ustawia długość wizyty.',
            continueInfo: 'Kliknij w przycisk "Dodaj wydarzenie" lub zamknij okno klikająć w "X" aby kontynuować',
            hideBtns: true,
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#dashboardVisitsList',
            content: 'Tutaj wyświetlają się wizyty przypisane do Ciebie z wybranego dnia',
            hideBackBtn: true,
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#dashboardVisitsList div:first-child',
            content: 'To jest wizyta, mogą one występować w 6 stanach (Zaplanowana, Potwierdzona, W poczekalni, W trakcie, Zakończona, Odwołana). Aby wybrać wizytę jako aktywną kliknij w nią.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: 'div[data-testid="patientInfoHeader"]',
            content: 'Tutaj wyświetlają dane pacjenta aktywnej wizyty',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: 'button[title="Edytuj pacjenta"]',
            content: 'Jeżeli chcesz edytować dane pacjenta, możesz to zrobić klikając tutaj.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#visitDataContainer',
            content: 'Tutaj wyświetlają się dane aktywnej wizyty.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: 'button[title="Edytuj wizytę"]',
            content: 'Jeżeli chcesz edytować dane wizyty lub usunąć ją, możesz to zrobić klikając tutaj.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#visitActions',
            content: 'Tutaj znajdują się przyciski akcji aktywnej wizyty, pozwalają one na zmianę stanu wizyty. Przyciski te różnią się w zależności od stanu wizyty.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#statsContainer',
            content: 'Możesz na bieżąco monitorować statystki z dzisiejszego dnia korzystając z tego panelu.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#visitsHistoryContainer',
            content: 'Możesz również wygodnie prześledzić historię pacjenta aktywnej wizyty w tej placówce.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#visitsHistoryTimelineContainer',
            content: 'Tutaj widnieje lista wszystkich wizyt pacjenta w tej placówce w kolejności chronologicznej. Aby wyświetlić dane zebrane podczas danej wizyty, kliknij w nią.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Panel',
            target: '#visitsHistoryMedicalDataContainer',
            content: 'Dane zebrane podczas danej wizyty takie jak wygenerowane sprawozdanie, dodane dokumenty czy wystawione recepty, wyświetlą się tutaj.',
            placement: 'top',
        },
    ],
    'Doctor|Nurse/calendar': [
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarToolbar',
            content: 'Z tego panelu możesz zarządzać widokami oraz datą aktywną kalendarza.',
            placement: 'bottom',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarToolbar div:nth-child(1) >div',
            content: 'Tu możesz zmienić zakres kalendarza na dzień, tydzień lub miesiąc.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarNavArrows',
            content: 'Tu możesz nawigować aktywną datą.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarToolbar div:nth-child(3)',
            content: 'Tu możesz zmienić aktywną placówkę i widok kalendarza.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarVisitsList',
            content: 'Tutaj wyświetlają się Twoje oraz wybranych współpracowników wizyty z wybranego zakresu czasowego.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#SidebarCalendarSwitch',
            content: 'Tutaj możesz przełączać z listy wizyt na filtry wizyt do wyświetlenia.',
            continueInfo: 'Kliknij w "Filtry" aby kontynuować',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '#calendarFilters',
            content: 'Ten panel pozwala na zarządzanie wyświetlaniem wizyt konkretnych lekarzy i grup.',
            placement: 'right',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '.collapse-list-actions',
            content: 'Możesz zwinąć listę lub zaznaczyć i odznaczyć wszystkie pozycje na raz.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Kalendarz',
            target: '.rbc-day-slot .rbc-timeslot-group:nth-child(3)',
            content: 'Aby dodać wizytę kliknij i przeciągnij na miejscu godziny która Cię interesuje lub skorzystaj z przycisku "Dodaj nową wizytę" z lewego panelu.',
            placement: 'top',
        },
    ],
    'Doctor|Nurse/profile': [
        {
            ...stepConfig,
            title: 'Profil',
            target: 'button[title="Edytuj profil"]',
            content: 'Aby edytować Twoje dane takie jak imię, nazwisko, email lub czas trwania wizyty w kalendarzu oraz godziny pracy kliknij tutaj.',
            placement: 'right',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: 'button[title="Edytuj ustawienia"]',
            content: 'Ten przycisk po kliknięciu otworzy okno ustawień dzięki któremu możesz zarządzać samouczkami, domyślnymi gabienetami dla placówek oraz Twoimi typami wizyt.',
            continueInfo: 'Kliknij w przycisk z "Długopisem" aby kontynuować',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: '#tutorialsCheckboxes',
            content: 'Jeżeli chcesz pominąć lub powtórzyć któryś z samouczków możesz to zrobić tutaj. Zaznaczone pole oznacza że samouczek został ukończony i nie wyświetli się już na odpowiedniej dla niego stronie, tak więc jeżeli chcesz powtórzyć któryś z nich, odznacz pole.',
            placement: 'right',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: '#defaultRoomsSelects',
            content: 'Tutaj możesz wybrać gabinet który będzie domyślnie wybrany przy dodawaniu wizyty w konkretnej placówce.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: '#visitTypes',
            content: 'Typ wizyty usprawnia definiowanie długości wizyty przy dodawaniu jej, automatycznie ustawiając czas na przypisany konkretemu typowi. Każdy z nich musi mieć unikalną nazwę oraz czas trwania podany w minutach.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: 'div[title="Edycja ustawień"]',
            content: 'Dobrze skonfigurowane ustawienia mogą usprawnić pracę przy dodawaniu i edycji wizyt.',
            continueInfo: 'Kliknij w przycisk "Zapisz zmiany" lub zamknij okno klikająć w "X" aby kontynuować',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: 'button[title="Edytuj urlopy"]',
            content: 'Ten przycisk po kliknięciu otworzy okno zarządzania Twoimi urlopami.',
            continueInfo: 'Kliknij w przycisk z "Długopisem" aby kontynuować',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: '#addVacationForm',
            content: 'Aby dodać urlop należy wybrać datę która Cię interesuje i klinąć przycisk "Dodaj urlop".',
            placement: 'left',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: 'div[title="Edycja urlopów"] form ul',
            content: 'Tutaj możesz zobaczyć wszystkie swoje urlopy oraz je usunąć korzystając z przycisku "X" przy pozycji którą chcesz usunąć. ',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: 'div[title="Edycja urlopów"]',
            content: 'Jeśli chcesz zapisać dodane i usunięte urlopy, kliknij "Zapisz zmiany". Każdy dodany urlop musi być zaakceptowany przez administratora firmy.',
            continueInfo: 'Kliknij w przycisk "Zapisz zmiany" lub zamknij okno klikająć w "X" aby kontynuować',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: '.scheduleContainer',
            content: 'To jest "Harmonogram pracy". Możesz go użyć do określenia godzin pracy w danej placówce w określony dzień tygodnia. Dodawanie wizyt poza godzinami pracy nadal będzie możliwe ale będzie poprzedzone oknem ostrzegawczym.',
            placement: 'left',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: 'input[name="useCalendar"]',
            content: 'Harmonogram możesz aktywować zaznaczając to pole',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: '.scheduleHealthcenterSelect .scheduleHealthcenterSelect',
            content: 'Tutaj możesz wybrać aktywną placówkę dla której chcesz ustawić harmonogram',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Profil',
            target: '.rbc-calendar',
            content: 'Aby dodać godziny pracy kliknij i przeciągnij od godziny rozpoczęcia do godziny końca pracy w kolumnie dnia który Cię interesuje (harmonogram musi być aktywny w tym czasie). Aby usunąć zakres, skieruj na niego kursor i kliknij w przycisk "X" w prawym górym rogu zakresu. Czas pracy danego dnia nie musi być ciągły i może zawierać wiele zakresów.',
            placement: 'left',
        },
    ],
    'Doctor|Nurse/patients/visits': [
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: '#addNewPatientBtn',
            content: 'Ten przycisk po kliknięciu otwiera okno z formularzem dodawania pacjenta.',
            continueInfo: 'Kliknij w przycisk "Dodaj nowego pacjenta" aby kontynuować',
            placement: 'right',
            disableBeacon: true,
            hideBtns: true,
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: 'div[title="Dodawanie pacjenta"]',
            content: 'Tutaj możesz uzupełnić dane lub awatar pacjenta, aby go dodać kliknij "Dodaj pacjenta" po uzupełnieniu niezbędnych pól.',
            continueInfo: 'Kliknij w przycisk "Dodaj pacjenta" lub zamknij okno klikająć w "X" aby kontynuować',
            placement: 'right',
            hideBtns: true,
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: '#patientsList',
            content: 'Tutaj wyświetla się lista wszystkich pacjentów, klikając w pacjenta ustawisz go jako aktywnego. Dzięki temu wyświetlą się jego szczegółowe dane.',
            hideBackBtn: true,
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: 'div[data-testid="patientInfoHeader"]',
            content: 'Tutaj wyświetlają się dane szczegółowe aktywnego pacjenta',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: 'button[title="Edytuj pacjenta"]',
            content: 'Aby edytować dane pacjenta lub go usunąć, kliknij tutaj.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Pacjenci',
            target: '#visits ul',
            content: 'Tutaj jest lista wszystkich wizyt aktywnego pacjenta wraz z szczegółowymi danymi na temat tej wizyty. Aby wyświelić szczegółowe dane rozwiń wybrany element za pomocą niebieskiej strzałki.',
            placement: 'top',
        }
    ],
    'Doctor|Nurse/patients/examinations': [
        {
            ...stepConfig,
            title: 'Badania',
            target: '#examinations',
            content: 'Tutaj znajdują się badania aktywnego pacjenta.',
            placement: 'top',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Badania',
            target: 'button[title="Dodaj badanie"]',
            content: 'Ten przycisk po kliknięciu otworzy okno z formularzem dodawania badań.',
            continueInfo: 'Kliknij w przycisk z "+" aby kontynuować',
            placement: 'left',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Badania',
            target: 'div[title="Dodawanie badania"] div[label="Szablon"]',
            content: 'Możesz tworzyć szablony badań aby ułatwić przyszłe dodawanie badań. Aby utworzyć szablon wybierz opcję "Utwórz nowy szablon" i wypełnij pole "Nazwa nowego szablonu". Szablon zostanie utworzony po kliknięciu w przycisk "Dodaj badanie i zapisz szablon". Szablon zapisuje zestaw czynników wraz z jednostą i zakresami, tak więc po wybraniu zapisanego szablonu wystarczy wypełnić tylko wyniki.',
            placement: 'right',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Badania',
            target: 'div[title="Dodawanie badania"] ul',
            content: 'Badania mogą składać się z wielu czynników. Aby dodać kolejny czynnik użyj niebieskiego przycisku "+" po prawej',
            placement: 'right',
            hideBackBtn: true,
        },
    ],
    'Doctor|Nurse/patients/documents': [
        {
            ...stepConfig,
            title: 'Dokumenty',
            target: '#documents',
            content: 'Tutaj znajdują się wszystkie dokumenty i zdjęcia aktywnego pacjenta dodane podczas wizyt oraz z tego miejsca.',
            placement: 'top',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Dokumenty',
            target: 'div[label="Dokumentacja z wizyty"]',
            content: 'Używając tej listy możesz wyświetlić pliki dodane podczas konkretnej wizyty.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Dokumenty',
            target: 'button[title="Dodaj dokument"]',
            content: 'Ten przycisk po kliknięciu otworzy okno z formularzem umożliwiającym dodawnie plików i zdjęć.',
            continueInfo: 'Kliknij w przycisk z "+" aby kontynuować',
            placement: 'left',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Dokumenty',
            target: 'div[data-testid="tabs_dropfile"]',
            content: 'Aby dodać plik upuść go nad tym polem lub kliknij na nie i wybierz plik z listy.',
            placement: 'left',
            hideBackBtn: true,
        },
    ],
    'Doctor|Nurse/patients/medsdiagnosis': [
        {
            ...stepConfig,
            title: 'Rozpoznania i leki',
            target: '#medsdiagnosis',
            content: 'Tutaj znajdują się informacje na temat leków i chorób pacjenta.',
            placement: 'top',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Rozpoznania i leki',
            target: 'button[title="Edytuj rozpoznania"]',
            content: 'Przyciski z "Długopisem" po kliknięciu otworzą okno edycji skąd możesz zarządzać zawartością poniższych list.',
            continueInfo: 'Kliknij w przycisk z "Długopisem" aby kontynuować',
            placement: 'left',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Rozpoznania i leki',
            target: '#chooseSelectConstant',
            content: 'Aby dodać rozpoznanie wybierz je z listy lub wpisz nazwę szukanego rozpoznania.',
            placement: 'right',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Rozpoznania i leki',
            target: 'div[title="Edycja rozpoznań"] ul',
            content: 'Możesz usunąć rozpoznanie klikająć przycisk "X" koło wybranego rozpoznania.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Rozpoznania i leki',
            target: 'div[title="Edycja rozpoznań"]',
            // content: 'Aby kontynuować zapisz zmiany lub zamknij okno.',
            continueInfo: 'Kliknij w przycisk "Zapisz zmiany" lub zamknij okno klikająć w "X" aby kontynuować.',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Rozpoznania i leki',
            target: 'button[title="Edytuj leki"]',
            content: 'Dodawanie leków odbywa się w analogiczny do rozpoznań sposób, przy pomocy tego przycisku.',
            placement: 'left',
            hideBackBtn: true,
        },
    ],
    'Doctor|Nurse/patients/riskfactors': [
        {
            ...stepConfig,
            title: 'Czynniki ryzyka',
            target: '#riskfactors',
            content: 'Tutaj znajdują się informacje na temat nałogów, alergii i innych czynników ryzyka.',
            placement: 'top',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Czynniki ryzyka',
            target: 'button[title="Edytuj czynniki ryzyka"]',
            content: 'Ten przycisk po kliknięciu otworzy okno umożliwiające dodawanie lub usuwanie czynników ryzyka pacjenta.',
            continueInfo: 'Kliknij w przycisk z "Długopisem" aby kontynuować.',
            placement: 'left',
            hideNextBtn: true,
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Czynniki ryzyka',
            target: 'div[title="Edycja czynników ryzyka"] form div:first-child',
            content: 'Aby dodać czynnik wpisz jego nazwę i kliknij dodaj aby pojawił się na liście.',
            placement: 'right',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Czynniki ryzyka',
            target: 'div[title="Edycja czynników ryzyka"]',
            content: 'Gdy czynnik znajdzie się już na liście możesz go usunąć klikająć "X" przy jego nazwie.',
            continueInfo: 'Kliknij w przycisk "Zapisz zmiany" lub zamknij okno klikająć w "X" aby kontynuować.',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Czynniki ryzyka',
            target: 'button[title="Edytuj nałogi"]',
            content: 'Aby edytować listę wielokrotnego wyboru, musisz ją najpierw "aktywować" przy pomocy tego przycisku.',
            continueInfo: 'Kliknij w przycisk z "Długopisem" aby kontynuować',
            placement: 'right',
            hideBtns: true,
        },
        {
            ...stepConfig,
            title: 'Czynniki ryzyka',
            target: 'button[title="Zapisz nałogi"] + div',
            content: 'Gdy pola są aktywne (niebieskie) możesz dokonać zmian. Aby zapisać zmiany kliknij w zielony przycisk w górnym prawym rogu tego panelu.',
            placement: 'right',
            hideBackBtn: true,
        },
    ],
    'Doctor|Nurse/patients/hospitalizations': [
        {
            ...stepConfig,
            title: 'Hospitalizacje',
            target: '#hospitalizations',
            content: 'Tutaj znajdują się informacje na temat przeszłych zabiegów pacjenta oraz pobytów w szpitalach. Mechanizmy wprowadzania danych są analogiczne do poprzednich zakładek.',
            placement: 'top',
            popperMaxWidth: 700,
            disableBeacon: true,
        },
    ],
    'Doctor|Nurse/patients/surveys': [
        {
            ...stepConfig,
            title: 'Wywiady',
            target: '#surveys',
            content: 'Tutaj znajdują się pozostałe informacje na temat pacjenta. Mechanizmy wprowadzania danych są analogiczne do poprzednich zakładek.',
            placement: 'top',
            popperMaxWidth: 700,
            disableBeacon: true,
        },
    ],
    'Doctor|Nurse/elementsList': [
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#elementsList >div',
            content: 'Tutaj znajdują się Twoje elementy wizyt posortowane według kategorii i subkategorii które zostały nadane elementom.',
            placement: 'left',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#elementsList >div div:first-child',
            content: 'Elementy są przypisywane do kategorii aby ułatwić ich identyfikacje i odnalezienie.',
            continueInfo: 'Kliknij w kategorię aby kontynuować',
            placement: 'left',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#elementsList >div div:first-child div:last-child div:first-child',
            content: 'Każda kategoria składa się z podkategorii do któych przypisujemy elementy.',
            continueInfo: 'Kliknij w podkategorię aby kontynuować',
            placement: 'left',
            hideBtns: true,
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#subcategoryElements li:first-child',
            content: 'To jest element wizyty. Możesz je konfigurować według potrzeb aby potem podczas wizyty budować z nich automatyczne sprawozdanie.',
            placement: 'left',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#subcategoryElements li:first-child div:last-child svg:nth-child(1)',
            content: 'Elementy możesz eksportować do pliku .json aby potem móc je zaimportować.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#subcategoryElements li:first-child div:last-child svg:nth-child(2)',
            content: 'Jeżeli chcesz usunąć element, kliknij tutaj.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#subcategoryElements li:first-child div:last-child svg:nth-child(3)',
            content: 'Jeżeli chcesz edytować element, kliknij tutaj a zostaniesz przeniesony do kreatora gdzie będziesz mógł dostosować element.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '.importElement',
            content: 'Jeśli posiadasz plik .json elementu możesz go zaimportować klikając tutaj.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '.addNewElementBtn',
            content: 'Po kliknięciu w ten przycisk nowy element utworzy się i zostaniesz przeniesiony do kreatora elementów gdzie będziesz mógł go dostosować.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#setSide >div:last-child',
            content: 'Zestawy pozwalają na dodanie wielu elementów na raz podczas wizyty. Możesz je dowolnie komponować dodając do nich elementy. Jeden element może być w wielu zestawach naraz ale nie dwa razy w tym samym zestawie.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '.addNewSetBtn',
            content: 'Zestaw możesz utworzyć klikając tutaj. Jeśli nie posiadasz żadnego zestawu utwórz go aby kontynuować.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#setSide div:last-child div:first-child',
            content: 'Rozwiń zestaw w analogiczny sposób jak w przypadku elementu.',
            continueInfo: 'Kliknij w podświetlony zestaw aby kontynuować',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#setSide div:last-child div:first-child form div:first-child',
            content: 'Tutaj możesz nadać zestawowi nazwę oraz zdefinować etap w którym ma być użyty podczas wizyty.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#setSide div:last-child div:first-child form div:first-child .remove-btn',
            content: 'Aby usunąć zestaw kliknij tutaj.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Lista elementów wizyt',
            target: '#setSide div:last-child div:first-child form ul',
            content: 'Aby dodać element wybierz go z listy lub przeciągnij z prawego panelu na pole z napisem "Przeciągnij element z prawego panelu, aby go dodać".',
            placement: 'right',
        },
    ],
    'Doctor|Nurse/elements': [
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: 'div[label="Nazwa elementu"]',
            content: 'Tutaj możesz zmienić nazwę elementu. Nazwa musi być unikalna.',
            placement: 'left',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: 'div[label="Etap"]',
            content: 'Etap definuje w jakim etapie wizyty ten element może być użyty.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: 'div[label="Kategoria"]',
            content: 'Kategoria definuje umiejscowienie na liście elementów.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#elementsSidePanel ul',
            content: 'Aby dodać pole do elementu, kliknij i przytrzymaj lewy przycisk myszy nad elementem który Cię interesuje.',
            continueInfo: 'Kliknij i przytrzymaj dowolny element aby kontynuować.',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#dropzone',
            content: 'Następnie najedź elementem nad to pole i puść lewy przycisk myszy.',
            continueInfo: 'Upuść element w tym polu aby kontynuować.',
            placement: 'top',
            hideBtns: true,
            disableOverlay: true,
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '.DraftEditor-root',
            content: 'Wygenerowany tekst jest tekstem który będzie dodany do wygenerowanego sprawozdania w wizycie po dodaniu tego elementu z podstawionymi wartościami pól. ',
            placement: 'left',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '.Draft-fields',
            content: 'Jeśli chcesz wstawić wartość danego pola do tekstu, kliknij w odpowiedni przycisk z tego panelu.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#inputsBox',
            content: 'Dodane pola wyświetlają się tutaj.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#inputsBox > div:first-child',
            content: 'Aby dostosować pole do swoich potrzeb musisz najpiew je zaznaczyć poprzez kliknęcie.',
            continueInfo: 'Kliknij w pole aby kontynuować.',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#settingsPanel',
            content: 'Gdy pole jest zaznaczone w tym panelu wyświetlają się jego ustawienia w zależności od jego typu, które pozwolą Ci dostosować je do swoich potrzeb.',
            placement: 'left',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#settingsPanel .fieldDropdown svg',
            content: 'Z poziomu tego menu możesz usunąć pole, sklonować je lub zapisać do użytku w innych elementach.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#inputsBox',
            content: 'Aby wrócić do ustawień całego elementu zamiast konkretnego pola kliknij w tło w tym panelu.',
            placement: 'right',
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#previewTab',
            content: 'Jeżeli chcesz podejrzeć jak ten element będzie wyglądał i działał przejdź do zakładki "Podgląd".',
            continueInfo: 'Kliknij w "Podgląd" aby kontynuować.',
            placement: 'bottom',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#previewTabWrapper',
            content: 'Tutaj możesz przetestować swój element i upewnić się czy generowany tekst jest zgodny z oczekiwaniami.',
            placement: 'bottom',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: '#saveBtn',
            content: 'Aby zapisać zmiany w elemencie. Kliknij w ten przycisk. Wyszarzony przycisk oznacza brak zmian do zapisania.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Tworzenie elementu',
            target: 'button[title="Wyjdź"]',
            content: 'Jeśli chcesz zakończyć edycję i wyjść. Kliknij w ten przycisk',
            placement: 'bottom',
        },
    ],
    'Doctor|Nurse/visit': [
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '#visitSidepanel >div:first-child',
            content: 'Tutaj znajdują się informacje na temat obecnego pacjenta. Możesz przejść na jego profil aby zobaczyć pełne dane na jego temat.',
            placement: 'right',
            disableBeacon: true,
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '#visitSidepanel >div:nth-child(2)',
            content: 'Tutaj znajdują się Twoje elementy wizyt przypisane do aktualnego etapu. Aby dodać element do wizyty, kliknij i przytrzymaj lewy przycisk myszy nad elementem który Cię interesuje.',
            continueInfo: 'Kliknij i przytrzymaj dowolny element wywiadu aby kontynuować.',
            placement: 'right',
            hideNextBtn: true,
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '#dropzone',
            content: 'Następnie najedź elementem nad to pole i puść lewy przycisk myszy.',
            continueInfo: 'Upuść element w tym polu aby kontynuować.',
            placement: 'bottom',
            hideBtns: true,
            disableOverlay: true,
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '#dropzone + div',
            content: 'Tutaj wyświetlają się dodane elementy. Klikając w dany element ustawisz go jako aktualny dzięki czemu będziesz mógł wprowadzić dane do jego pól.',
            placement: 'bottom',
            hideBackBtn: true,
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '#elementFields',
            content: 'W tym panelu wyświetlają się pola aktulanie zaznaczonego elementu które możesz uzupełnić.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '.remove-element-btn',
            content: 'Jeżeli chcesz usunąć aktualnie zaznaczony element, kliknij tutaj.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: 'div[contenteditable="true"]',
            content: 'W tym miejscu wyświetla się wygenerowane sprawozdanie. Każdy etap poza podsumowaniem posiada własny tekst. Składa się on ze złączonych tekstów dodanych elementów uzupełnionych o dane wprowadzone do pól. Z tego miejsca możesz dowolnie go modyfikować ręcznie. Należy pamiętać że zmiany ręczne mogą zostać nadpisane przy usunięciu elementu z wizyty lub zmiany wartości pola przed czym zostaniesz ostrzeżony gdy taka sytuacja zajdzie.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: 'button[title="Odśwież tekst"]',
            content: 'Jeżeli chcesz cofnąć zmiany ręczne i wygenerować sprawozdanie na nowo dla tego etapu, kliknij tutaj.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '.history-wrapper',
            content: 'Jeżeli pacjent miał przeprowadzone wizyty w tej placówce wcześniej w tym miejscu wyświetlą się wygenerowane sprawozdania dla aktywnego etapu z poprzednich wizyt. Możesz nawigować historią przy pomocy przycisków na dole tego panelu.',
            placement: 'left',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: 'button[title="Dodaj zdjęcie"]',
            content: 'Do wizyty możesz załączyć dokumenty lub zdjęcia. Przy pomocy tego przycisku dodasz zdjęcia.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: 'button[title="Dodaj dokument"]',
            content: 'Przy pomocy tego przycisku dodasz dokumenty.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: 'button[title="Dodaj receptę"]',
            content: 'Ten przycisk natomiast umożliwia dodawanie recept do wizyty.',
            placement: 'top',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '#visitStageNav',
            content: 'Wizyta podzielona jest na 6 etapów. Dzięki temu panelowi możesz swobodnie nawigować między nimi. Aby zmienić etap kliknij na jego nazwę lub skorzystaj z przycisków "Cofnij" lub "Dalej".',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '#visitStageNav >div:last-child button:first-child',
            content: 'Zapis wprowadzonych zmian odbywa się automatycznie przy zmianie etapu. Możesz również zapisać go ręcznie przy każdej wprowadzonej zmianie. Wyszarzony przycisk oznacza brak zmian do zapisania.',
            placement: 'bottom',
        },
        {
            ...stepConfig,
            title: 'Wizyta',
            target: '#menu a:last-child',
            content: 'W razie potrzeby możesz w każdym momencie opuścić ekran wizyty i wrócić do niego klikając na ten odnośnik. Jeżeli rozpoczętych wizyt jest więcej niż jedna po kliknięciu w niego pojawi się lista wizyt z której będziesz mógł wbyrać do której wizyty chcesz wrócić. Należy pamiętać o zapisaniu wprowadzonych zmian przed opuszczeniem tego ekranu aby zapobiec utracie ich.',
            placement: 'bottom',
        },
    ],
} 