import axios from 'axios';

const ADD_VISIT_ELEMENT_REQUEST = "ADD_VISIT_ELEMENT_REQUEST";
const ADD_VISIT_ELEMENT_SUCCESS = "ADD_VISIT_ELEMENT_SUCCESS";
const ADD_VISIT_ELEMENT_FAIL = "ADD_VISIT_ELEMENT_FAIL";

const GET_DOCTORS_VISIT_ELEMENTS_REQUEST = "GET_DOCTORS_VISIT_ELEMENTS_REQUEST";
const GET_DOCTORS_VISIT_ELEMENTS_SUCCESS = "GET_DOCTORS_VISIT_ELEMENTS_SUCCESS";
const GET_DOCTORS_VISIT_ELEMENTS_FAIL = "GET_DOCTORS_VISIT_ELEMENTS_FAIL";

const UPDATE_VISIT_ELEMENT_REQUEST = "UPDATE_VISIT_ELEMENT_REQUEST";
const UPDATE_VISIT_ELEMENT_SUCCESS = "UPDATE_VISIT_ELEMENT_SUCCESS";
const UPDATE_VISIT_ELEMENT_FAIL = "UPDATE_VISIT_ELEMENT_FAIL";

const DELETE_VISIT_ELEMENT_REQUEST = "DELETE_VISIT_ELEMENT_REQUEST";
const DELETE_VISIT_ELEMENT_SUCCESS = "DELETE_VISIT_ELEMENT_SUCCESS";
const DELETE_VISIT_ELEMENT_FAIL = "DELETE_VISIT_ELEMENT_FAIL";

const IMPORT_VISIT_ELEMENT_REQUEST = "IMPORT_VISIT_ELEMENT_REQUEST";
const IMPORT_VISIT_ELEMENT_SUCCESS = "IMPORT_VISIT_ELEMENT_SUCCESS";
const IMPORT_VISIT_ELEMENT_FAIL = "IMPORT_VISIT_ELEMENT_FAIL";

const EXPORT_VISIT_ELEMENT_REQUEST = "EXPORT_VISIT_ELEMENT_REQUEST";
const EXPORT_VISIT_ELEMENT_SUCCESS = "EXPORT_VISIT_ELEMENT_SUCCESS";
const EXPORT_VISIT_ELEMENT_FAIL = "EXPORT_VISIT_ELEMENT_FAIL";

export const addVisitElement = (newElement) => async dispatch => {
    dispatch(addVisitElementRequest())
    try {
        const response = await axios.post(`/visitelem/`, newElement);
        const { data } = response;
        dispatch(addVisitElementSuccess(data))
        return data;
    } catch (error) {
        dispatch(addVisitElementFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const addVisitElementRequest = () => ({
    type: ADD_VISIT_ELEMENT_REQUEST,
});

const addVisitElementSuccess = (element) => ({
    type: ADD_VISIT_ELEMENT_SUCCESS,
    payload: element
});

const addVisitElementFail = (error) => ({
    type: ADD_VISIT_ELEMENT_FAIL,
    payload: error
});

export const getDoctorsVisitElements = (doctorId) => async dispatch => {
    dispatch(getDoctorsVisitElementsRequest())
    try {
        const response = await axios.get(`/visitelem/doctor/${doctorId}`);
        const { data } = response;
        dispatch(getDoctorsVisitElementsSuccess(data))
    } catch (error) {
        dispatch(getDoctorsVisitElementsFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const getDoctorsVisitElementsRequest = () => ({
    type: GET_DOCTORS_VISIT_ELEMENTS_REQUEST,
});

const getDoctorsVisitElementsSuccess = (elements) => ({
    type: GET_DOCTORS_VISIT_ELEMENTS_SUCCESS,
    payload: elements
});

const getDoctorsVisitElementsFail = (error) => ({
    type: GET_DOCTORS_VISIT_ELEMENTS_FAIL,
    payload: error
});

export const updateVisitElement = (elementId, newElement) => async dispatch => {
    dispatch(updateVisitElementRequest())
    try {
        const response = await axios.patch(`/visitelem/${elementId}`, newElement);
        const { data } = response;
        dispatch(updateVisitElementSuccess(data))
    } catch (error) {
        dispatch(updateVisitElementFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const updateVisitElementRequest = () => ({
    type: UPDATE_VISIT_ELEMENT_REQUEST,
});

const updateVisitElementSuccess = (element) => ({
    type: UPDATE_VISIT_ELEMENT_SUCCESS,
    payload: element
});

const updateVisitElementFail = (error) => ({
    type: UPDATE_VISIT_ELEMENT_FAIL,
    payload: error
});

export const deleteVisitElement = (elementId) => async dispatch => {
    dispatch(deleteVisitElementRequest())
    try {
        const response = await axios.delete(`/visitelem/${elementId}`);
        dispatch(deleteVisitElementSuccess(elementId))
    } catch (error) {
        dispatch(deleteVisitElementFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const deleteVisitElementRequest = () => ({
    type: DELETE_VISIT_ELEMENT_REQUEST,
});

const deleteVisitElementSuccess = (elementId) => ({
    type: DELETE_VISIT_ELEMENT_SUCCESS,
    payload: elementId
});

const deleteVisitElementFail = (error) => ({
    type: DELETE_VISIT_ELEMENT_FAIL,
    payload: error
});

export const importVisitElement = (doctorId, file) => async dispatch => {
    dispatch(importVisitElementRequest())
    console.log(file);
    try {
        const response = await axios.post(`/visitelem/doctor/${doctorId}/import`, file);
        const { data } = response;
        dispatch(importVisitElementSuccess(data));
    } catch (error) {
        dispatch(importVisitElementFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const importVisitElementRequest = () => ({
    type: IMPORT_VISIT_ELEMENT_REQUEST,
});

const importVisitElementSuccess = (element) => ({
    type: IMPORT_VISIT_ELEMENT_SUCCESS,
    payload: element
});

const importVisitElementFail = (error) => ({
    type: IMPORT_VISIT_ELEMENT_FAIL,
    payload: error
});

export const exportVisitElement = (elementId) => async dispatch => {
    dispatch(exportVisitElementRequest())
    try {
        const response = await axios.get(`/visitelem/${elementId}/export`);
        const { data } = response;
        const fileName = data.name;
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.log(error);
        dispatch(exportVisitElementFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const exportVisitElementRequest = () => ({
    type: EXPORT_VISIT_ELEMENT_REQUEST,
});


const exportVisitElementFail = (error) => ({
    type: EXPORT_VISIT_ELEMENT_FAIL,
    payload: error
});

const initialState = {
    doctorsElements: [],
    loading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_VISIT_ELEMENT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADD_VISIT_ELEMENT_SUCCESS:
            return {
                ...state,
                doctorsElements: [...state.doctorsElements, action.payload],
                loading: false,
            }
        case ADD_VISIT_ELEMENT_FAIL:
            return {
                ...state,
                loading: false,
            }
        case GET_DOCTORS_VISIT_ELEMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_DOCTORS_VISIT_ELEMENTS_SUCCESS:
            return {
                ...state,
                doctorsElements: action.payload,
                loading: false,
            }
        case GET_DOCTORS_VISIT_ELEMENTS_FAIL:
            return {
                ...state,
                loading: false,
            }
        case UPDATE_VISIT_ELEMENT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case UPDATE_VISIT_ELEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                doctorsElements: state.doctorsElements.map(el => el._id === action.payload._id ? action.payload : el)
            }
        case UPDATE_VISIT_ELEMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case IMPORT_VISIT_ELEMENT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case IMPORT_VISIT_ELEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                doctorsElements: [...state.doctorsElements, action.payload]
            }
        case IMPORT_VISIT_ELEMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_VISIT_ELEMENT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_VISIT_ELEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                doctorsElements: state.doctorsElements.filter(el => el._id !== action.payload)
            }
        case DELETE_VISIT_ELEMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};