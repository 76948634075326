import React from "react";
import styled from 'styled-components';
import { Tabs } from '../../Shared/Tabs';
import PatientInfoHeader from "../../Shared/PatientInfoHeader";
import VisitsTabContainer from './Tabs/Visits/VisitsTabContainer';
import ExaminationsTabContainer from './Tabs/Examinations/ExaminationsTabContainer';
import DocumentsTabContainer from './Tabs/Documents/DocumentsTabContainer';
import MedsdiagnosisTabContainer from './Tabs/Medsdiagnosis/MedsdiagnosisTabContainer';
import RiskfactorsTabContainer from './Tabs/Riskfactors/RiskfactorsTabContainer';
import HospitalizationsTabContainer from './Tabs/Hospitalizations/HospitalizationsTabContainer';
import SurveysTabContainer from './Tabs/Surveys/SurveysTabContainer';
import Modal from '../../Shared/Modals/Modal';

const DoctorPatientInfoView = ({
  currentPatient,
  setRiskFactorsCheckboxEdit,
  saveModal,
  setSaveModal,
  nextTutorialStep,
}) => {
  const { _id } = currentPatient,
    tabsHeaders = [
      {
        label: 'Historia wizyt',
        id: 'visits'
      },
      {
        label: 'Badania',
        id: 'examinations'
      },
      {
        label: 'Dokumentacja',
        id: 'documents'
      },
      {
        label: 'Rozpoznania i leki',
        id: 'medsdiagnosis'
      },
      {
        label: 'Czynniki ryzyka',
        id: 'riskfactors'
      },
      {
        label: 'Hospitalizacje i zabiegi',
        id: 'hospitalizations'
      },
      {
        label: 'Wywiady',
        id: 'surveys'
      },
    ];

  return (
    <Wrapper>
      <Modal
        show={saveModal}
        type="SimpleQuestionModal"
        question='Masz niezapisane zmiany. Czy na pewno chcesz wyjść?'
        acceptText='Zapisz'
        cancelText='Odrzuć'
        onAccept={() => console.log('abc')}
        onCancel={() => { setSaveModal(false); setRiskFactorsCheckboxEdit(null); }}
      />
      {_id ?
        <>
          <PatientInfoHeader
            patientInfo={currentPatient}
          />
          <Tabs header={tabsHeaders} height={'calc(100% - 230px)'}>
            <VisitsTabContainer
              key="VisitTab"
              id='visits'
            />
            <ExaminationsTabContainer
              key="ExaminationsTab"
              id='examinations'
              nextTutorialStep={nextTutorialStep}
            />
            <DocumentsTabContainer
              key="DocumentsTab"
              id='documents'
              nextTutorialStep={nextTutorialStep}
            />
            <MedsdiagnosisTabContainer
              key="MedsDiagnosisTab"
              id='medsdiagnosis'
              nextTutorialStep={nextTutorialStep}
            />
            <RiskfactorsTabContainer
              key="RiskFactorsTab"
              id='riskfactors'
              nextTutorialStep={nextTutorialStep}
            />
            <HospitalizationsTabContainer
              key="HospitalizationsTab"
              id='hospitalizations'
              nextTutorialStep={nextTutorialStep}
            />
            <SurveysTabContainer
              key="SurveysTab"
              id='surveys'
              nextTutorialStep={nextTutorialStep}
            />
          </Tabs>
        </> :
        <ChoosePatient>Wybierz pacjenta lub dodaj go za pomocą panelu po lewej</ChoosePatient>
      }
    </Wrapper >
  )
};

export default DoctorPatientInfoView;

const Wrapper = styled.div`
  padding: 0 0 20px;
  width: 100%;
`;

const ChoosePatient = styled.div`
  text-align:center;
  padding:100px 0;
  font-weight:600;
  font-size:24px;
  color:${({ theme }) => theme.primary};
`;
