import React, { useState, useRef, forwardRef, useEffect } from "react";
import Modal from "../../../../Shared/Modals/Modal";
import styled from "styled-components";
import Input from "../../../../Shared/Input";
import Textarea from "../../../../Shared/Textarea";
import DatePicker from "../../../../Shared/DatePicker";
import Button from "../../../../Shared/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import TimePicker from "../../../../Shared/TimePicker";
import moment from "moment";
import Select from "../../../../Shared/Select";
import { calculateDisabledHours } from "../../../../CalendarPage/Modals/utils";
import { getFreeVisitSlot, mapVisitTypesToOptions } from "../../../../Shared/utils";
import { visitModalErrorsDisc } from "../../../../Shared/enums";
import { toast } from 'react-toastify';

const defaultValidationSchema = Yup.object().shape({
  start: Yup.date()
    .max(Yup.ref("end"), "Większa niż końcowa")
    .required("Pole wymagane"),
  end: Yup.date()
    .min(Yup.ref("start"), "Mniejsza niż początkowa")
    .required("Pole wymagane"),
  doctorId: Yup.string().required("Pole wymagane"),
  patient: Yup.string().required("Pole wymagane"),
  room: Yup.string().required("Pole wymagane"),
  healthcenter: Yup.string().required("Pole wymagane"),
});
const addingNewPatientValidationSchema = Yup.object().shape({
  start: Yup.date()
    .max(Yup.ref("end"), "Większa niż końcowa")
    .required("Pole wymagane"),
  end: Yup.date()
    .min(Yup.ref("start"), "Mniejsza niż początkowa")
    .required("Pole wymagane"),
  name: Yup.string().required("Pole wymagane"),
  surname: Yup.string().required("Pole wymagane"),
  doctorId: Yup.string().required("Pole wymagane"),
  tel: Yup.string().required("Pole wymagane"),
  room: Yup.string().required("Pole wymagane"),
  healthcenter: Yup.string().required("Pole wymagane"),
});

const AddEventModal = forwardRef(
  ({
    isCreateModalOn,
    setCreateModal,
    patients,
    doctors,
    addPatient,
    companyId,
    addVisit,
    healthcenters,
    user,
    userRole,
    selectedEvent,
    calendarDate,
    visits,
    setWarningModal,
    getCompanyPatientsHandler,
    nextTutorialStep
  }) => {
    const
      [isAddingNewPatient, setAddingNewPatient] = useState(false),
      [visitTypeOptions, setVisitTypeOptions] = useState([]),
      [errors, setErrors] = useState({ array: [], data: {} }),
      [dashboardCurrentHealthcenter, setCurrentDashboardCurrentHealthcenter] = useState(null);

    const patientInput = useRef();
    useEffect(() => {
      setCurrentDashboardCurrentHealthcenter(healthcenters[0] ?? {})
    }, [healthcenters]);

    const patientNoOption = (setFieldValue) => (
      <AddPatientNoOption>
        Dodaj pacjenta
        <AddPatientButton
          type="button"
          onClick={() => {
            const [name, ...surname] = patientInput.current.state.inputValue.split(' ');
            setFieldValue("name", name);
            setFieldValue("surname", surname.join(' '));
            setAddingNewPatient(true);
          }}
        >
          {" "}
          +{" "}
        </AddPatientButton>
      </AddPatientNoOption>
    );

    const disabledHours = calculateDisabledHours(
      userRole?.callendarSettings.min,
      userRole?.callendarSettings.max
    );
    const initialValues = {
      date: moment(calendarDate)._d,
      patient: "",
      visitType: "",
      start: getFreeVisitSlot(visits, calendarDate, userRole?.callendarSettings).start,
      end: getFreeVisitSlot(visits, calendarDate, userRole?.callendarSettings).end,
      doctorId:
        (user.activeRole === "Doctor" || user.activeRole === "Nurse")
          ? userRole?._id
          : !selectedEvent.roomsStatus && selectedEvent.resourceStatus
            ? selectedEvent.resourceId
            : "",
      room:
        ((user.activeRole === "Doctor" || user.activeRole === "Nurse") && userRole?.defaultRooms) ?
          userRole?.defaultRooms.find(({ healthcenter }) => healthcenter === healthcenters[0]?._id)?.room
          :
          selectedEvent.roomsStatus && selectedEvent.resourceStatus
            ? selectedEvent.resourceId
            : "",
      description: "",
      isConfirmed: false,
      healthcenter: { label: healthcenters[0]?.name, value: healthcenters[0] }
    };

    const handleAddVisitSuccess = () => {
      setCreateModal(false);
      setAddingNewPatient(false);
      setWarningModal({ shown: false, question: null, onAccept: null, questionTitle: null })
      toast('Wizyta została zaplanowana.', {
        type: 'success'
      })
    };

    useEffect(() => {
      if (errors.array.length > 0) {
        setWarningModal({
          shown: true,
          questionTitle: "Czy jesteś pewien?",
          question: visitModalErrorsDisc[errors.array[0]],
          preventClosing: true,
          onAccept: errors.array.length > 1 ? (
            () => {
              setErrors(({ array, data }) => ({
                array: array.filter((_, i) => i !== 0),
                data
              })
              );
            }) : (async () => {
              const { status } = await addVisit(errors.data, true);
              if (status === 200) {
                handleAddVisitSuccess();
              } else if (status === 409 && errors.array.length > 0) {
                setErrors(errors)
              }
            }),
        })
      }
    }, [errors]);

    useEffect(() => {
      if (userRole) {
        setVisitTypeOptions(mapVisitTypesToOptions(userRole));
      }
    }, [userRole]);

    return (
      <Modal
        title="Dodawanie wydarzenia"
        show={isCreateModalOn}
        exitButton={true}
        onCancel={() => {
          setCreateModal(false);
          setAddingNewPatient(false);
          nextTutorialStep();
        }}
      >
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={
            isAddingNewPatient
              ? addingNewPatientValidationSchema
              : defaultValidationSchema
          }
          onSubmit={async ({ date, ...values }) => {
            let patientData;
            if (isAddingNewPatient) {
              const userData = {
                companyId: companyId,
                name: values.name,
                surname: values?.surname,
                tel: values.tel,
              };

              patientData = await addPatient(companyId, userData);
            }

            const visitData = {
              patient: isAddingNewPatient ? patientData._id : values.patient,
              visitType: values.visitType,
              doctor: values.doctorId,
              healthcenterId: dashboardCurrentHealthcenter._id,
              companyId: dashboardCurrentHealthcenter.companyId,
              start: moment(values.start),
              end: moment(values.end),
              description: values.description,
              room: values.room,
              registeringPerson: user._id,
              state: "PLANNED",
              timeLogs: [{ type: "PLANNED", time: new Date().toISOString() }],
            };
            console.log(visitData);
            const { status, data: errors } = await addVisit(visitData);
            if (status === 200) {
              handleAddVisitSuccess();
            } else if (status === 409 && errors.length > 0) {
              setErrors({
                array: errors,
                data: visitData,
              });
            }
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            setFieldError,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
              <Form onSubmit={handleSubmit}>
                <Select
                  label="Typ wizyty"
                  value={visitTypeOptions.find(({ value }) => value === values.visitType?.name) ?? visitTypeOptions[0]}
                  name="visitType"
                  placeholder="Wybierz typ..."
                  options={visitTypeOptions}
                  onChange={({ value, length }) => {
                    setFieldValue("visitType", { name: value, length });
                    setFieldValue("end", moment(values.start).add(length, 'minutes'));
                  }}
                  noOptionsMessage={() => <p>Nie znaleziono takiego typu</p>}
                />
                <InputRow>
                  <StyledDatePicker
                    selected={values.date}
                    dateFormat='dd.MM.yyyy'
                    label="Data"
                    onChange={(value) => {
                      const startTime = moment(values.start).format('HH:mm:ss');
                      const endTime = moment(values.end).format('HH:mm:ss');
                      const newDate = moment(value).format('YYYY-MM-DD');
                      setFieldValue("start", `${newDate} ${startTime}`);
                      setFieldValue("end", `${newDate} ${endTime}`);
                      setFieldValue("date", value);
                    }}
                  />
                  <StyledTimePicker
                    label="Początek"
                    allowEmpty={false}
                    value={moment(values.start)}
                    onChange={(e) => {
                      if (e !== null) {
                        const lengthToAdd = values.visitType ? visitTypeOptions.find(({ value }) => value === values.visitType.name).length : userRole.callendarSettings.step
                        setFieldValue("start", e.format("YYYY-MM-DD HH:mm:ss"))
                        setFieldValue("end", e.add(lengthToAdd, 'm').format("YYYY-MM-DD HH:mm:ss"))
                      } else {
                        setFieldError("start", "Pole wymagane")
                      }
                    }}
                    onBlur={handleBlur}
                    name="start"
                    showSecond={false}
                    minuteStep={5}
                    disabledMinutes={() => [0]}
                    disabledHours={() => disabledHours}
                    hideDisabledOptions={true}
                    error={errors.start}
                  />
                  <StyledTimePicker
                    label="Koniec"
                    allowEmpty={false}
                    value={moment(values.end)}
                    onChange={(e) =>
                      e !== null
                        ? setFieldValue("end", e.format("YYYY-MM-DD HH:mm:ss"))
                        : setFieldError("end", "Pole wymagane")
                    }
                    onBlur={handleBlur}
                    name="end"
                    showSecond={false}
                    minuteStep={5}
                    disabledMinutes={() => [0]}
                    disabledHours={() => disabledHours}
                    hideDisabledOptions={true}
                    error={errors.end}
                  />
                </InputRow>
                <InputRow colsNumber={isAddingNewPatient ? 3 : 2}>
                  {!isAddingNewPatient ? (
                    <Select
                      ref={patientInput}
                      label="Pacjent"
                      defaultValue={values.patient}
                      name="patient"
                      placeholder="Wybierz pacjenta..."
                      options={patients.map((patient) => {
                        return { label: `${patient.name} ${patient?.surname}`, value: patient._id };
                      })}
                      onChange={({ value }) => setFieldValue("patient", value)}
                      onInputChange={(value) => { getCompanyPatientsHandler(value) }}
                      noOptionsMessage={() => patientNoOption(setFieldValue)}
                      error={errors.patient}
                    />
                  ) : (
                      <>
                        <Input
                          type="text"
                          name="name"
                          label="Imię"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          error={errors.name}
                        />
                        <Input
                          type="text"
                          name="surname"
                          label="Nazwisko"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.surname}
                          error={errors?.surname}
                        />
                        <Input
                          type="text"
                          name="tel"
                          label="Numer kontaktowy"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tel}
                          error={errors.tel}
                        />
                      </>
                    )}
                  <Select
                    label="Lekarz"
                    value={
                      doctors
                        .filter((doctor) => doctor._id === values.doctorId)
                        .map((doctor) => {
                          return {
                            label: `${doctor.userId.name} ${doctor.userId?.surname}`,
                            value: doctor._id,
                            color: doctor.visitColor,
                          };
                        })[0]
                    }
                    name="doctorId"
                    placeholder="Wybierz lekarza..."
                    options={doctors.map((doctor) => {
                      return {
                        label: `${doctor.userId.name} ${doctor.userId?.surname}`,
                        value: doctor._id,
                        color: doctor.visitColor,
                      };
                    })}
                    onChange={({ value }) => setFieldValue("doctorId", value)}
                    noOptionsMessage={() => "Nie znaleziono lekarza"}
                    styles={dotColorStyles}
                    error={errors.doctorId}
                    isDisabled={(user.activeRole === "Doctor" || user.activeRole === "Nurse") ? true : false}
                  />
                  <Select
                    label="Placówka"
                    name="healthcenter"
                    placeholder="Wybierz placówke..."
                    options={healthcenters.map((healthcenter) => {
                      return { label: healthcenter.name, value: healthcenter };
                    })}
                    onChange={({ value }) => {
                      setCurrentDashboardCurrentHealthcenter(value);
                      setFieldValue("healthcenter", value);
                      setFieldValue(
                        'room',
                        userRole?.defaultRooms.find(({ healthcenter }) => healthcenter === value._id)?.room ?? ''
                      );
                    }}
                    noOptionsMessage={() => "Nie znaleziono placówki"}
                    error={errors.healthcenter}
                    defaultValue={values.healthcenter}
                  />
                  <Select
                    label="Gabinet"
                    name="room"
                    placeholder="Wybierz gabinet..."
                    options={dashboardCurrentHealthcenter?.rooms?.map((room) => {
                      return { label: room.name, value: room._id };
                    })}
                    onChange={({ value }) => setFieldValue("room", value)}
                    noOptionsMessage={() => "Nie znaleziono gabinetu"}
                    value={dashboardCurrentHealthcenter?.rooms ?
                      dashboardCurrentHealthcenter?.rooms
                        .filter((room) => room._id === values.room)
                        .map((room) => {
                          return {
                            label: room.name,
                            value: room._id,
                          };
                        })
                      :
                      { value: '', label: '' }
                    }
                    error={errors.room}
                  />
                </InputRow>
                <Textarea
                  name="description"
                  label="Dodatkowe informacje"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  error={errors.description}
                />
                <CenterBox>
                  <Button type="submit" variant="primary">
                    Dodaj wydarzenie
                </Button>
                </CenterBox>
              </Form>
            )}
        </Formik>
      </Modal>
    );
  }
);

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});
const dotColorStyles = {
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  option: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

export default AddEventModal;

const Form = styled.form`
  padding-top: 20px;
  width: 450px;
`;

const CenterBox = styled.div`
  text-align: center;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    width: ${({ colsNumber }) => colsNumber === 2 ? '48%;' : '31%;'};
  }
`;

const TodayDate = styled.p`
  font-size: 13px;
  color: #666;
  margin-bottom: 22px;
  width: 48%;
  text-align: center;
  align-self: flex-end;
`;

const AddPatientNoOption = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const AddPatientButton = styled.button`
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  padding: 0.25em 0.65em;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDatePicker = styled(DatePicker)`
  width:100%;
  >label{
    display:block;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  input{
    width:100%!important;
  }
`;