import React from 'react';
import styled, { css } from 'styled-components';
import Button from '../Shared/Button';
import sharpArrowIcon from '../Shared/assets/sharp-arrow.svg';
import saveIcon from '../Shared/assets/save.svg';
import { ReactComponent as ExaminationIcon } from '../Shared/assets/stethoscope.svg';
import { ReactComponent as SummaryIcon } from '../Shared/assets/summary.svg';
import { ReactComponent as CheckIcon } from '../Shared/assets/check-filled.svg';
import { ReactComponent as ConversationIcon } from '../Shared/assets/conversation.svg';
import { ReactComponent as RecommendationsIcon } from '../Shared/assets/recommendations.svg';
import { ReactComponent as DiagnosisIcon } from '../Shared/assets/diagnosis.svg';
import { ReactComponent as AdditionalExaminationsIcon } from '../Shared/assets/additional-examinations.svg';

const StageNav = ({
    currentStageIndex,
    setCurrentStage,
    warningModalHandler,
    navBlock,
    updateMedicalVisitHandler,
    readMode,
    services,
    updateVisit,
    currentVisit
}) => {
    return (
        <Nav id='visitStageNav'>
            <ArrowBtn
                variant='secondary'
                disabled={currentStageIndex <= 0}
                onClick={() => setCurrentStage(prev => ({ ...prev, i: --prev.i }))}
            >Cofnij</ArrowBtn>
            <Stages>
                <ActiveMarker
                    position={
                        currentStageIndex === 0 ? '0%' :
                            currentStageIndex === 1 ? '16.6%' :
                                currentStageIndex === 2 ? '33.2%' :
                                    currentStageIndex === 3 ? '49.8%' :
                                        currentStageIndex === 4 ? '66.4%' :
                                            currentStageIndex === 5 ? '83.4%' :
                                                '0%'
                    }
                    bendCorner={currentStageIndex === 0 ? 'left' : currentStageIndex === 5 ? 'right' : 'bend'}
                />
                <Stage
                    onClick={() => setCurrentStage(prev => ({ ...prev, i: 0 }))}
                    active={currentStageIndex === 0}
                >
                    {currentStageIndex > 0 ?
                        <StyledCheckIcon />
                        :
                        <StyledConversationIcon />
                    }
                    <StageLabel>Wywiad</StageLabel>
                </Stage>
                <Stage
                    onClick={() => setCurrentStage(prev => ({ ...prev, i: 1 }))}
                    active={currentStageIndex === 1}
                >
                    {currentStageIndex > 1 ?
                        <StyledCheckIcon />
                        :
                        <StyledExaminationIcon />
                    }
                    <StageLabel>Badanie<br /> fizykalne</StageLabel>
                </Stage>
                <Stage
                    onClick={() => setCurrentStage(prev => ({ ...prev, i: 2 }))}
                    active={currentStageIndex === 2}
                >
                    {currentStageIndex > 2 ?
                        <StyledCheckIcon />
                        :
                        <StyledAdditionalExaminationsIcon />
                    }
                    <StageLabel>Badania<br /> dodatkowe</StageLabel>
                </Stage>
                <Stage
                    onClick={() => setCurrentStage(prev => ({ ...prev, i: 3 }))}
                    active={currentStageIndex === 3}
                >
                    {currentStageIndex > 3 ?
                        <StyledCheckIcon />
                        :
                        <StyledDiagnosisIcon />
                    }
                    <StageLabel>Rozpoznanie</StageLabel>
                </Stage>
                <Stage
                    onClick={() => setCurrentStage(prev => ({ ...prev, i: 4 }))}
                    active={currentStageIndex === 4}
                >
                    {currentStageIndex > 4 ?
                        <StyledCheckIcon />
                        :
                        <StyledRecommendationsIcon />
                    }
                    <StageLabel>Zalecenia</StageLabel>
                </Stage>
                <Stage
                    onClick={() => setCurrentStage(prev => ({ ...prev, i: 5 }))}
                    active={currentStageIndex === 5}
                >
                    <StyledSummaryIcon />
                    <StageLabel>Podsumowanie</StageLabel>
                </Stage>
            </Stages>
            <Buttons>
                <ArrowBtn
                    variant='primary'
                    disabled={!navBlock || readMode}
                    onClick={() => {
                        updateMedicalVisitHandler();
                        updateVisit(currentVisit._id, 'STARTED', {
                            services: services.added.map(({ _id }) => ({ _id })),
                            cost: services.totalWithDiscount,
                            discount: services.discount
                          });
                    }}
                    type="save"
                />
                <ArrowBtn
                    variant='primary'
                    onClick={() => {
                        if (currentStageIndex >= 5) {
                            warningModalHandler('endVisit');
                        } else {
                            setCurrentStage(prev => ({ ...prev, i: ++prev.i }))
                        }
                    }}
                    type="right"
                >{currentStageIndex >= 5 ? readMode ? 'Wyjdź' : 'Zakończ' : 'Dalej'}</ArrowBtn>
            </Buttons>
        </Nav >
    )
}

export default StageNav;

const Nav = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
    padding:0 5px;
    ${({ theme }) => `${theme.mq.large}{
        padding:0 10px;
    }`}
`;

const ArrowBtn = styled(Button)`
    display:flex;
    align-items:center;
    justify-content:space-between;
    font-size:14px; 
    font-weight:600;
    min-width:72.5px;
    padding:6px;
    ${({ variant, theme }) => variant === "primary" && `
    box-shadow: 0 1px 6px ${theme.primary};
    `}
    ${({ theme }) => `${theme.mq.large}{
        min-width:95px;
    }`}
    ${({ type }) => type === 'right' && `
        flex-direction: row-reverse;
        &::before{
            margin-left:5px;
        }
    `}
    ${({ disabled }) => disabled && `
        opacity:1;
        background-color:#D9D9D9;
        box-shadow: 0 1px 6px #eee;
    `}
    ${({ type }) => type === 'save' && `
        margin-right:5px;
        padding:6px 8px;
        min-width:0!important;
    `}
    &::before{
        ${({ type }) => type === 'save' ? `
            content:url(${saveIcon});
            height:17px;
            width:17px;
            margin-right:0;
        ` : `
            content:url(${sharpArrowIcon});
            height:20px;
            width:20px;
        `}
        display:inline-block;
        ${({ type }) => type === 'right' && `
            transform: rotate(180deg);
        `}
    }
`;

const ActiveMarker = styled.div`
    position:absolute;
    top:0;
    left:${({ position }) => position};
    height:100%;
    width:16.6%;
    background: linear-gradient(90deg, rgba(126,213,246,1) 0%, rgba(126,213,246,1) 55%, rgba(24,144,255,1) 100%);
    z-index:1;
    transition:500ms;
    ${({ bendCorner }) => bendCorner === 'bend' ?
        'clip-path: polygon(93% 0%, 100% 50%, 93% 100%, 0% 100%, 5% 50%, 0% 0%);'
        : bendCorner === 'left' ?
            'clip-path: polygon(93% 0%, 100% 50%, 93% 100%, 0% 100%, 0% 50%, 0% 0%);'
            : bendCorner === 'right' ?
                'clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);'
                : ''
    }
`;

const Stages = styled.div`
    position:relative;
    border: 2px solid ${({ theme }) => theme.grey};
    border-radius:10px;
    background-color:#fff;
    margin:5px 5px 0;
    width:85%;
    height:72px;
    display:flex;
    overflow:hidden;
`;

const Stage = styled.div`
    cursor:pointer;
    width:30%;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    z-index:2;
    transition:300ms;
    ${({ active }) => active && `
        >p{
            color:#fff;
        }
        >svg path{
            fill:#fff;
        }
    `}
    &:first-child{
        clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 0% 50%, 0% 0%);
    }
`;

const StageLabel = styled.p`
    color: ${({ theme }) => theme.lightBlack};
    font-weight:600;
    transition:300ms;
    font-size:13px;
    ${({ theme }) => `${theme.mq.large}{
        font-size:15px;
    }`}
`;

const Buttons = styled.div`
    display:flex;
`

const iconStyles = css`
    margin-right:10px;
    transition:300ms;
    height: 25px;
    width: 25px;
    display:none;
    ${({ theme }) => `${theme.mq.large}{
        display:block;
    }`}
    path{
        fill: #cccccc;
        transition:300ms;
    }
`;

const StyledExaminationIcon = styled(ExaminationIcon)`
    ${iconStyles}
`;

const StyledSummaryIcon = styled(SummaryIcon)`
    ${iconStyles}
`;

const StyledCheckIcon = styled(CheckIcon)`
        margin-right:10px;
    transition:300ms;
    height: 25px;
    width: 25px;
    display:none;
    ${({ theme }) => `${theme.mq.large}{
        display:block;
    }`}
    path{
        transition:300ms;
    }
`;

const StyledConversationIcon = styled(ConversationIcon)`
    ${iconStyles}
`;

const StyledRecommendationsIcon = styled(RecommendationsIcon)`
    ${iconStyles}
`;

const StyledDiagnosisIcon = styled(DiagnosisIcon)`
    ${iconStyles}
`;

const StyledAdditionalExaminationsIcon = styled(AdditionalExaminationsIcon)`
    ${iconStyles}
`;
