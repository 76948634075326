import {addVisitFromSocket,updateVisitFromSocket,deleteVisitFromSocket} from './reduxActions'

export default (socket,storeAPI) => {

    socket.on('visit-add-calendarPage', visit => {
        const currentState = storeAPI.getState();
        switch(currentState.authentication.user.activeRole){
            case "Registry" : {
                const activeDoctorsIds = currentState.calendar.doctors.filter(doctor => doctor.checked).map(doc => doc._id)
                if(activeDoctorsIds.findIndex(activeDoctor => activeDoctor === visit.doctor)> -1){
                    storeAPI.dispatch(addVisitFromSocket(visit))
                }
            }
            case "Doctor" : {
                const doctorId = currentState.authentication.userRole._id
                if(doctorId === visit.doctor){
                    storeAPI.dispatch(addVisitFromSocket(visit))
                }
            }
        }
    })
    socket.on('visit-add-patientPage', visit => {
        const currentState = storeAPI.getState();
        const currentPatientId = currentState.patients.currentPatient._id
        if(currentPatientId === visit.patient._id){
            storeAPI.dispatch(addVisitFromSocket(visit))
        }
    })
    socket.on('visit-add-dashboardPage', visit => {
        storeAPI.dispatch(addVisitFromSocket(visit))
    })

    socket.on('visit-edit-calendarPage', visit => {
        console.log('Jest ten event update : ', visit)
        const currentState = storeAPI.getState();
        const currentVisits = currentState.visits.data
        if(currentState.authentication.user.activeRole === "Registry"){
                const activeDoctorsIds = currentState.calendar.doctors.filter(doctor => doctor.checked).map(doc => doc._id)
                console.log('Aktywni Lekarze : ', activeDoctorsIds, visit)
                if(activeDoctorsIds.findIndex(activeDoctor => activeDoctor === visit.doctor)> -1){
                    if(currentVisits.findIndex(currentVisit => currentVisit._id === visit._id)> -1){
                        storeAPI.dispatch(updateVisitFromSocket(visit))
                    } else {
                        storeAPI.dispatch(addVisitFromSocket(visit))
                    }
                } else {
                    storeAPI.dispatch(deleteVisitFromSocket(visit._id))
                }
        }
        else if(currentState.authentication.user.activeRole ==="Doctor"){
                const doctorId = currentState.authentication.userRole._id
                if(doctorId === visit.doctor){
                    if(currentVisits.findIndex(currentVisit => currentVisit._id === visit._id)> -1){
                        storeAPI.dispatch(updateVisitFromSocket(visit))
                    } else {
                        storeAPI.dispatch(addVisitFromSocket(visit))
                    }
                } else {
                    storeAPI.dispatch(deleteVisitFromSocket(visit._id))
                }
            }
        }
    )
    socket.on('visit-edit-patientPage', visit => {
        const currentState = storeAPI.getState();
        const currentPatientId = currentState.patients.currentPatient._id
        const currentVisits = currentState.visits.data
        console.log('Wydarzenie sie dzieje : xdd')
        if(currentPatientId === visit.patient._id){
            if(currentVisits.findIndex(currentVisit => currentVisit._id === visit._id)> -1){
                storeAPI.dispatch(updateVisitFromSocket(visit))
            } else {
                storeAPI.dispatch(addVisitFromSocket(visit))
            }
        } else {
            storeAPI.dispatch(deleteVisitFromSocket(visit._id))
        }
    })
    socket.on('visit-edit-dashboardPage', visit => {
        const currentState = storeAPI.getState()
        const doctorId = currentState.authentication.userRole._id
        const currentVisits = currentState.visits.data
        if(visit.doctor === doctorId){
            if(currentVisits.findIndex(currentVisit => currentVisit._id === visit._id)> -1){
                storeAPI.dispatch(updateVisitFromSocket(visit))
            } else {
                storeAPI.dispatch(addVisitFromSocket(visit))
            }
        } else {
            storeAPI.dispatch(deleteVisitFromSocket(visit._id))
        }
    })
}

