import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import RiskfactorsTabView from "./RiskfactorsTabView";
import { withRouter, useParams } from "react-router-dom";
import useDebouncer from "../../../../Shared/Hooks/useDebouncer";
import {
  getPatientRiskFactors,
  updatePatientRiskFactors,
  getCancers,
} from "../../../../../redux/modules/riskfactors";
import { setNavBlock } from '../../../../../redux/modules/navigation';

const RiskfactorsTabContainer = ({
  cancers,
  location: { pathname },
  currentPatient,
  updatePatientRiskFactors,
  getCancers,
  getPatientRiskFactors,
  patientRiskFactors,
  navBlock,
  setNavBlock,
  nextTutorialStep,
  tutorialActive,
  tutorialStep,
}) => {
  const [riskFactorsCheckboxEdit, setRiskFactorsCheckboxEdit] = useState(null);
  const [riskFactorsTextModal, setRiskFactorsTextModal] = useState({
    shown: false,
    target: null,
  });
  const [
    riskFactorsFamilyCancersModal,
    setRiskFactorsFamilyCancersModal,
  ] = useState({ shown: false });
  const [setMedsDiagnosisDebouncer] = useDebouncer();
  const [setRiskFactorsDebouncer] = useDebouncer();
  const { currentTab } = useParams();

  useEffect(() => {
    if (currentTab === "riskfactors") {
      getPatientRiskFactors(currentPatient._id);
      getCancers("");
    }
  }, [currentPatient, pathname]);

  const getCancersHandler = (searchValue) => {
    setMedsDiagnosisDebouncer(() => getCancers(searchValue));
  };

  const updatePatientRiskFactorsHandler = (newRiskFactors) => {
    if (navBlock) {
      setNavBlock(false);
    }
    setRiskFactorsDebouncer(() =>
      updatePatientRiskFactors(currentPatient._id, newRiskFactors)
    );
  };

  const checkboxSubmitHandler = (values, target) => {
    const newRiskFactors = { [target]: Object.entries(values).filter(el => el[1] !== false).map(el => el[0]) };
    updatePatientRiskFactorsHandler(newRiskFactors);
    setRiskFactorsCheckboxEdit(null);
  };

  useEffect(() => {
    if (riskFactorsCheckboxEdit !== null && !navBlock) {
      setNavBlock(true);
    }
  }, [riskFactorsCheckboxEdit]);

  //Tutorial handler
  useEffect(() => {
    if (tutorialActive) {
      switch (tutorialStep) {
        case 1:
          if (riskFactorsTextModal.shown) {
            nextTutorialStep();
          }
          break;
        case 3:
          if (!riskFactorsTextModal.shown) {
            nextTutorialStep();
          }
          break;
        case 4:
          if (riskFactorsCheckboxEdit === "addictions") {
            nextTutorialStep();
          }
          break;
      }
    }
  }, [tutorialActive, riskFactorsTextModal, riskFactorsCheckboxEdit]);

  return (
    <RiskfactorsTabView
      currentTab={currentTab}
      getCancersHandler={getCancersHandler}
      updatePatientRiskFactorsHandler={updatePatientRiskFactorsHandler}
      riskFactorsTextModal={riskFactorsTextModal}
      setRiskFactorsTextModal={setRiskFactorsTextModal}
      riskFactorsFamilyCancersModal={riskFactorsFamilyCancersModal}
      setRiskFactorsFamilyCancersModal={setRiskFactorsFamilyCancersModal}
      patientRiskFactors={patientRiskFactors}
      cancers={cancers}
      riskFactorsCheckboxEdit={riskFactorsCheckboxEdit}
      setRiskFactorsCheckboxEdit={setRiskFactorsCheckboxEdit}
      checkboxSubmitHandler={checkboxSubmitHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  currentPatient: state.patients.currentPatient,
  cancers: state.riskfactors.cancers,
  patientRiskFactors: state.riskfactors.patientRiskFactors,
  navBlock: state.navigation.navBlock,
  tutorialActive: state.tutorial.active,
  tutorialStep: state.tutorial.stepIndex,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPatientRiskFactors: (patientId) =>
      dispatch(getPatientRiskFactors(patientId)),
    updatePatientRiskFactors: (patientId, newRiskFactors) =>
      dispatch(updatePatientRiskFactors(patientId, newRiskFactors)),
    getCancers: (query) => dispatch(getCancers(query)),
    setNavBlock: (payload) => dispatch(setNavBlock(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RiskfactorsTabContainer));
