import axios from 'axios';

const GET_USERS_VACATIONS_BY_COMPANY_ID_REQUEST = "GET_USERS_VACATIONS_BY_COMPANY_ID_REQUEST";
const GET_USERS_VACATIONS_BY_COMPANY_ID_SUCCESS = "GET_USERS_VACATIONS_BY_COMPANY_ID_SUCCESS";
const GET_USERS_VACATIONS_BY_COMPANY_ID_FAIL = "GET_USERS_VACATIONS_BY_COMPANY_ID_FAIL";

const ADD_VACATION_REQUEST = "ADD_VACATION_REQUEST";
const ADD_VACATION_SUCCESS = "ADD_VACATION_SUCCESS";
const ADD_VACATION_FAIL = "ADD_VACATION_FAIL";

const UPDATE_VACATION_REQUEST = "UPDATE_VACATION_REQUEST";
const UPDATE_VACATION_SUCCESS = "UPDATE_VACATION_SUCCESS";
const UPDATE_VACATION_FAIL = "UPDATE_VACATION_FAIL";

const ACCEPT_VACATION_REQUEST = "ACCEPT_VACATION_REQUEST";
const ACCEPT_VACATION_SUCCESS = "ACCEPT_VACATION_SUCCESS";
const ACCEPT_VACATION_FAIL = "ACCEPT_VACATION_FAIL";

const DELETE_VACATION_REQUEST = "DELETE_VACATION_REQUEST";
const DELETE_VACATION_SUCCESS = "DELETE_VACATION_SUCCESS";
const DELETE_VACATION_FAIL = "DELETE_VACATION_FAIL";

const RESET_VACATION_ERROR = "RESET_VACATION_ERROR";


export const getUsersVacationsByCompanyId = (companyId, start, end) => async dispatch => {
    dispatch(getUsersVacationsByCompanyIdRequest())
    try {
        const response = await axios.get(`/vacation/company/${companyId}?start=${start}&end=${end}`)
        let { data } = response;
        if (data === '') {
            data = [];
        }
        dispatch(getUsersVacationsByCompanyIdSuccess(data))
    } catch (error) {
        dispatch(getUsersVacationsByCompanyIdFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const getUsersVacationsByCompanyIdRequest = () => ({
    type: GET_USERS_VACATIONS_BY_COMPANY_ID_REQUEST
})

const getUsersVacationsByCompanyIdSuccess = (vacations) => ({
    type: GET_USERS_VACATIONS_BY_COMPANY_ID_SUCCESS,
    payload: vacations
})

const getUsersVacationsByCompanyIdFail = (error) => ({
    type: GET_USERS_VACATIONS_BY_COMPANY_ID_FAIL,
    payload: error
})

export const addVacation = (vacation) => async (dispatch, getState) => {
    dispatch(addVacationRequest())
    try {
        const response = await axios.post(`/vacation/`, vacation);
        const { data } = response;
        const state = getState();
        let newUsersVacations = state.vacations.usersVacations;
        const targetUserIndex = newUsersVacations.findIndex(user => user._id === vacation.userId);
        if (targetUserIndex === -1) {
            let { userId } = [...state.employees.doctors, ...state.employees.registries, ...state.employees.nurses].find(({ userId }) => userId._id === vacation.userId);
            userId.vacations = [data];
            newUsersVacations = [...newUsersVacations, userId];
        } else {
            newUsersVacations[targetUserIndex].vacations = [...newUsersVacations[targetUserIndex].vacations, data]
        }
        dispatch(addVacationSuccess(newUsersVacations))
    } catch (error) {
        dispatch(addVacationFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const addVacationRequest = () => ({
    type: ADD_VACATION_REQUEST,
})

const addVacationSuccess = (newUsersVacations) => ({
    type: ADD_VACATION_SUCCESS,
    payload: newUsersVacations
})

const addVacationFail = (error) => ({
    type: ADD_VACATION_FAIL,
    payload: error
})

export const updateVacation = (vacationId, newVacation) => async (dispatch, getState) => {
    dispatch(updateVacationRequest())
    try {
        const response = await axios.patch(`/vacation/${vacationId}`, newVacation);
        const { data } = response;
        let newUsersVacations = getState().vacations.usersVacations;
        newUsersVacations = newUsersVacations.map(({ vacations, ...el }) => ({ ...el, vacations: vacations.map((el) => el._id === vacationId ? data : el) }));
        dispatch(updateVacationSuccess(newUsersVacations))
    } catch (error) {
        dispatch(updateVacationFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const updateVacationRequest = () => ({
    type: UPDATE_VACATION_REQUEST,
})

const updateVacationSuccess = (newUsersVacations) => ({
    type: UPDATE_VACATION_SUCCESS,
    payload: newUsersVacations
})

const updateVacationFail = (error) => ({
    type: UPDATE_VACATION_FAIL,
    payload: error
})

export const acceptVacation = (vacationId, body) => async (dispatch, getState) => {
    console.log(body);
    dispatch(acceptVacationRequest())
    try {
        const response = await axios.patch(`/vacation/accept/${vacationId}`, body);
        console.log(response);
        let newUsersVacations = getState().vacations.usersVacations;
        newUsersVacations = newUsersVacations.map(({ vacations, ...el }) => ({ ...el, vacations: vacations.map((el) => el._id === vacationId ? { ...el, state: "ACCEPTED" } : el) }));
        dispatch(acceptVacationSuccess(newUsersVacations))
    } catch (error) {
        dispatch(acceptVacationFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const acceptVacationRequest = () => ({
    type: ACCEPT_VACATION_REQUEST,
})

const acceptVacationSuccess = (newUsersVacations) => ({
    type: ACCEPT_VACATION_SUCCESS,
    payload: newUsersVacations
})

const acceptVacationFail = (error) => ({
    type: ACCEPT_VACATION_FAIL,
    payload: error
})

export const deleteVacation = (vacationId, userId) => async (dispatch, getState) => {
    dispatch(deleteVacationRequest())
    try {
        const response = await axios.delete(`/vacation/${vacationId}`, { data: { userId: userId } });
        let newUsersVacations = getState().vacations.usersVacations;
        newUsersVacations = newUsersVacations.map(({ vacations, ...el }) => ({ ...el, vacations: vacations.filter((el) => el._id !== vacationId) }));
        dispatch(deleteVacationSuccess(newUsersVacations))
    } catch (error) {
        dispatch(deleteVacationFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const deleteVacationRequest = () => ({
    type: DELETE_VACATION_REQUEST,
})

const deleteVacationSuccess = (newUsersVacations) => ({
    type: DELETE_VACATION_SUCCESS,
    payload: newUsersVacations
})

const deleteVacationFail = (error) => ({
    type: DELETE_VACATION_FAIL,
    payload: error
})

export const resetVacationsError = () => ({
    type: RESET_VACATION_ERROR,
})

const initialState = {
    usersVacations: [],
    loading: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_VACATIONS_BY_COMPANY_ID_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_USERS_VACATIONS_BY_COMPANY_ID_SUCCESS:
            return {
                ...state,
                usersVacations: action.payload,
                loading: false,
            }
        case GET_USERS_VACATIONS_BY_COMPANY_ID_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ADD_VACATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ADD_VACATION_SUCCESS:
            return {
                ...state,
                usersVacations: [...action.payload],
                loading: false,
            }
        case ADD_VACATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_VACATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case UPDATE_VACATION_SUCCESS:
            return {
                ...state,
                usersVacations: action.payload,
                loading: false,
            }
        case UPDATE_VACATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ACCEPT_VACATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ACCEPT_VACATION_SUCCESS:
            return {
                ...state,
                usersVacations: action.payload,
                loading: false,
            }
        case ACCEPT_VACATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_VACATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_VACATION_SUCCESS:
            return {
                ...state,
                usersVacations: action.payload,
                loading: false,
            }
        case DELETE_VACATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case RESET_VACATION_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}