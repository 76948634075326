import React from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import styled from 'styled-components';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from 'moment';
import Toolbar from './Toolbar';
import CustomEvent from './CustomEvent';
import Input from '../Shared/Input';
import Dropdown from '../Shared/Dropdown';
import avatarPlaceholder from '../Shared/assets/avatar.svg';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const formats = {
  timeGutterFormat: (date) => {
    return localizer.format(date, "HH:mm");
  },
  eventTimeRangeFormat: (date) => {
    let timeRangeFormat = `${localizer.format(
      date.start,
      "HH:mm"
    )} - ${localizer.format(date.end, "HH:mm")}`;
    return timeRangeFormat;
  },
  dayFormat: (date, culture) => {
    return localizer.format(date, "dddd DD", culture).toUpperCase();
  },
};

const VacationsCalendar = ({
  setCurrentCalendarDates,
  setVacationModal,
  events,
  setCurrentDate,
  updateVacationHandler,
  setQuestionModal,
  userFilters,
  usersFiltersHandler,
}) => {
  return (
    <>
      <Filters>
        <Dropdown label='Wyświetlaj pracowników...'>
          <OptionLabel>Lekarze</OptionLabel>
          {userFilters.doctors.map(({ _id, name, avatar, checked }) => (
            <Option key={_id}>
              <div>
                {_id !== 'all' && <Avatar src={avatar ? process.env.REACT_APP_SRV_URL + avatar : avatarPlaceholder} />}
                <Name>
                  {name}
                </Name>
              </div>
              <Input
                type='checkbox'
                hideError={true}
                variant='primary'
                value={checked}
                onChange={() => usersFiltersHandler(_id, 'doctors')}
              />
            </Option>
          ))}
          <OptionLabel>Pielęgniarki</OptionLabel>
          {userFilters.nurses.map(({ _id, name, avatar, checked }) => (
            <Option key={_id}>
              <div>
                {_id !== 'all' && <Avatar src={avatar ? process.env.REACT_APP_SRV_URL + avatar : avatarPlaceholder} />}
                <Name>
                  {name}
                </Name>
              </div>
              <Input
                type='checkbox'
                hideError={true}
                variant='primary'
                value={checked}
                onChange={() => usersFiltersHandler(_id, 'nurses')}
              />
            </Option>
          ))}
          <OptionLabel>Rejestratorzy</OptionLabel>
          {userFilters.registries.map(({ _id, name, avatar, checked }) => (
            <Option key={_id}>
              <div>
                {_id !== 'all' && <Avatar src={avatar ? process.env.REACT_APP_SRV_URL + avatar : avatarPlaceholder} />}
                <Name>
                  {name}
                </Name>
              </div>
              <Input
                type='checkbox'
                hideError={true}
                variant='primary'
                value={checked}
                onChange={() => usersFiltersHandler(_id, 'registries')}
              />
            </Option>
          ))}
        </Dropdown>
      </Filters>
      <StyledCalendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={events}
        selectable
        resizable
        popup={true}
        defaultView={"month"}
        views={["month"]}
        formats={formats}
        messages={{ showMore: (e) => `+${e} więcej` }}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: event.color,
            opacity: event.state === 'PLANNED' && 0.5,
          },
        })}
        components={{
          event: ({ event }) => {
            return <CustomEvent event={event} />;
          },
          toolbar: (props) => {
            return (
              <Toolbar
                {...props}
                userFilters={userFilters}
                usersFiltersHandler={usersFiltersHandler}
              />
            )
          }
        }}
        onSelectEvent={(event) => {
          setVacationModal({ shown: true, editId: event._id })
        }}
        onSelectSlot={({ start, end }) => {
          setCurrentCalendarDates({ start, end });
          setVacationModal({ shown: true, editId: null });
        }}
        onEventResize={(event) => {
          setQuestionModal({
            shown: true,
            question: 'Czy na pewno zmienić daty tego urlopu?',
            acceptText: 'Zmień',
            onAccept: () => updateVacationHandler({
              start: event.start,
              end: event.end !== event.event.end ? moment(event.end).subtract(1, 'days')._d : event.end,
              userId: event.event.userId,
            }, event.event._id),
          })
        }}
        onEventDrop={(event) => {
          setQuestionModal({
            shown: true,
            question: 'Czy na pewno zmienić daty tego urlopu?',
            acceptText: 'Zmień',
            onAccept: () => updateVacationHandler({
              start: event.start,
              end: event.end,
              userId: event.event.userId,
            }, event.event._id),
          })
        }}
        onNavigate={(currentDate) => {
          setCurrentDate(currentDate);
        }}
      />
    </>
  );
}

export default VacationsCalendar;

const StyledCalendar = styled(DragAndDropCalendar)`
  transition: transform 0.5s;
  width: 100%;
  min-height: calc(100vh - 200px);
  ${({ theme }) => `${theme.mq.desktop}{
      width: calc(100vw - 400px);
  }`}
  .rbc-calendar {
    background-color: ${({ theme }) => theme.background};
  }
  .rbc-show-more {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #fafafa;
    border-radius: 5px;
  }

  .rbc-allday-cell {
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD;
    ${({ view }) =>
    view === "week" || view === "work_week"
      ? `
      display: block;
      border-right: none;
      margin-top: 15px;
      `
      : `display: none;`}
  }
  .rbc-time-header-content{
    border-left: none;
  }
  .rbc-time-header-cell-single-day {
    display: flex;
  }
  .rbc-time-view,
  .rbc-month-view {
    border: none;
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: none;
  }
  .rbc-row-resource > .rbc-header {
    border-bottom: none;
    min-height: auto;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd0;
}
  .rbc-row-content {
    z-index: 0;
  }
  .rbc-time-content,
  .rbc-month-view {
    background: #fff;
  }
  .rbc-allday-cell > .rbc-row-content {
    margin-bottom: -25px;
}
  .rbc-time-content > .rbc-time-gutter {
    background-color: ${({ theme }) => theme.background};
  }
 .rbc-day-slot .pastTimeSlot {
    background: repeating-linear-gradient(
      -14deg,
      #eceef2,
      #eceef2 10px,
      #f5f7f9 10px,
      #f5f7f9 20px
    );
  }
  .rbc-label {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.lightBlack};
    top: -9px;
    position: relative;
    z-index: 5;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rbc-time-header-gutter {
    margin-right: 0;
    border-right: none;
  }
  .rbc-current-time-indicator {
    background-color: #ff6565;
    height: 2px;
    z-index: 0;
    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: #ff6565;
      border-radius: 50%;
      top: -7px;
      left: -8px;
    }
  }
  .rbc-day-slot {
    .rbc-event {
      background-color: #4564dd;
      border: 1px solid #6780e6;
      border-radius: 4px;
    }
    .rbc-addons-dnd-dragged-event {
      opacity: 0.5;
    }
  }
  .rbc-time-column .rbc-timeslot-group:last-of-type {
    border-bottom: none;
  }
  .rbc-row-resource > .rbc-header {
    margin: 0 0 5px;
  }
`;

const Filters = styled.div`
  display:flex;
  justify-content:space-between;
  position: absolute;
  right: 55px;
  top: 60px;
  >div{
      width: 250px;
  }
`;

const OptionLabel = styled.p`
  font-weight:700;
  font-size:14px;
  padding:2.5px 10px;
  &:not(:first-child){
    margin-top:7.5px;
    border-top:2px solid #ccc;
  }
`;

const Option = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 7.5px 15px;
    &:not(:last-child){
        border-bottom:1px solid ${({ theme }) => theme.grey};
    }
    >div{
        padding-right:5px;
        display:flex;
        align-items:center;
    }
`;

const Avatar = styled.img`
    width:20px;
    height:20px;
    min-width:20px;
    min-height:20px;
    border-radius:50%;
    margin-right:5px;
`;

const Name = styled.p`
font-size: 12px;
font-weight: 600;
`;