import React from 'react'
import Modal from '../../../Shared/Modals/Modal';

const DeleteModal = ({
    modalState,
    setModalState,
    onAccept,
}) => {

    return (
        <Modal
            type='SimpleQuestionModal'
            show={modalState}
            question={'Czy na pewno chcesz usunąć tego pacjenta'}
            acceptText='Usuń'
            cancelText='Anuluj'
            reverseButtonColors={true}
            onAccept={onAccept}
            onCancel={()=>setModalState(false)}
        />
    )
}

export default DeleteModal;