import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { elementsCategories } from '../../Shared/enums';
import { ReactComponent as ArrowIcon } from '../../Shared/assets/arrow-down.svg';
import ElementItem from './ElementItem';

const ElementsList = ({
    elements,
    doctorsElementsSets,
    setWarningModal,
    deleteVisitElement,
    exportVisitElement,
    elIsDragging,
    setElIsDragging,
    nextTutorialStep,
}) => {
    const
        [currentCategory, setCurrentCategory] = useState(null),
        [currentSubcategory, setCurrentSubcategory] = useState(null),
        [subcategoryElements, setSubcategoryElements] = useState([]),
        [elementsListActive, setElementsListActive] = useState(false);

    const toggleCollapsed = (name) => {
        if (currentCategory === null) {
            setCurrentCategory(name)
        } else if (currentCategory !== name && currentCategory !== null && currentCategory !== 'timeout') {
            setCurrentCategory('timeout')
            setTimeout(() => setCurrentCategory(name), 300)
        } else if (currentCategory === name) {
            setCurrentCategory(null)
        }
    };

    useEffect(() => {
        if (currentSubcategory !== null && currentCategory !== null) {
            setSubcategoryElements(elements.filter(({ subcategory, category }) => (subcategory === currentSubcategory && category === currentCategory)));
        } else {
            setSubcategoryElements([]);
        }
    }, [currentSubcategory, currentCategory, elements]);

    useEffect(() => {
        if (currentCategory && currentSubcategory) {
            setElementsListActive(true);
        } else {
            setElementsListActive(false);
        }
    }, [subcategoryElements]);

    return (
        <Wrapper elementsListActive={elementsListActive} id='elementsList'>
            <CategoriesList>
                {elementsCategories.map(({ name, subcategories }) => (
                    <CategoryItem collapsed={currentCategory !== name}>
                        <Top
                            active={currentCategory === name}
                            onClick={() => {
                                setCurrentSubcategory(null);
                                toggleCollapsed(name)
                                nextTutorialStep(1);
                            }}
                        >
                            <Label>{name}</Label>
                            <StyledArrowIcon className='arrow' />
                        </Top>
                        <Body className='body'>
                            {subcategories.map((subcat, index) => {
                                const elementsLength = elements.filter(({ subcategory, category }) => (subcategory === subcat && category === name))?.length;
                                return (
                                    <SubcategoryItem key={`${subcat}_${index}`} onClick={() => {
                                        setCurrentSubcategory(subcat);
                                        nextTutorialStep(2);
                                    }}>
                                        <span>
                                            {subcat}
                                        </span>
                                        <div>
                                            <Length>
                                                {elementsLength}
                                            </Length>
                                            <StyledArrowIcon />
                                        </div>
                                    </SubcategoryItem>
                                )
                            })}
                        </Body>
                    </CategoryItem>
                ))}
            </CategoriesList>
            <Elements>
                <ElementsLabel>
                    <span
                        onClick={() => {
                            setElementsListActive(false);
                            setTimeout(() => setCurrentSubcategory(null), 200)
                        }}
                    >
                        <StyledArrowIcon />
                        {`${currentCategory}/${currentSubcategory}`}
                    </span>
                </ElementsLabel>
                <ElementsWrapper id='subcategoryElements'>
                    {subcategoryElements.length > 0 ?
                        subcategoryElements.map(el => {
                            const sets = [];
                            for (const { elements, _id, name } of doctorsElementsSets) {
                                if (elements.indexOf(el._id) !== -1) {
                                    sets.push({ _id, name })
                                }
                            }
                            return (
                                <ElementItem
                                    key={el._id}
                                    data={el}
                                    sets={sets}
                                    setWarningModal={setWarningModal}
                                    deleteVisitElement={deleteVisitElement}
                                    exportVisitElement={exportVisitElement}
                                    elIsDragging={elIsDragging}
                                    setElIsDragging={setElIsDragging}
                                />
                            )
                        })
                        :
                        <NoItems>
                            Do tej podkategorii nie jest przypisany żaden element.
                    </NoItems>
                    }
                </ElementsWrapper>
            </Elements>
        </Wrapper>
    )
}

export default ElementsList;

const Wrapper = styled.div`
    position:relative;
    overflow-x:hidden;
    height:100%;
    >div{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        transition:.2s;
        &:last-child{
            left:100%;
        }
    }
    ${({ elementsListActive }) => elementsListActive && `
      >div{
        left:-100%;
        &:last-child{
            left:0%;
        }
    }
    `}
`;

const CategoriesList = styled.div``;

const Elements = styled.div`
    padding:10px;
`;

const CategoryItem = styled.div`
  ${({ collapsed }) => collapsed && `
        .arrow{
            transform:rotate(0);
        }
        .body{
            max-height:0;
        }
    `}
`;

const Top = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    cursor:pointer;
    transition: .3s;
    ${({ active, theme }) => active && `
        background-color: ${theme.grey};
        color: ${theme.secondary};
    `}
    &:hover{
        background-color: ${({ theme }) => theme.grey};
        color: ${({ theme }) => theme.secondary};
    }
`;

const Body = styled.div`
    transition:500ms;
    max-height:999px;
    overflow:hidden;
`;

const Label = styled.span`
    color:inherit;
    font-weight:600;
`;

const SubcategoryItem = styled.div`
    padding: 6px 50px;
    font-size:13px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    cursor:pointer;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    transition:.3s;
    &:last-child{
        border-color: #ddd;
    }
    svg{
        transform: rotate(-90deg);
    }
    >div{
        display:flex;
        width:50px;
        align-items:center;
    }
    &:hover{
        background-color:#f6f6f6;
        svg{
            transform: translateX(5px) rotate(-90deg);
        }
    }
`;

const StyledArrowIcon = styled(ArrowIcon)`
    transition:.3s;
    transform:rotate(180deg);
    path{
        fill:${({ theme }) => theme.lightBlack};
    }
`;

const ElementsLabel = styled.div`
    display:flex;
    span{
        font-size:18px;
        display:flex;
        align-items:center;
        margin-bottom:10px;
        cursor:pointer;
        &:hover{
            svg{
                transform: translateX(-5px) rotate(90deg);
            }
        }
    }
    svg{
        margin-right:3px;
        transform: rotate(90deg);
    }
`;

const NoItems = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    color:${({ theme }) => theme.secondary};
    padding:30px 0;
    font-weight: 600;
`;

const Length = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:${({ theme }) => theme.secondary};
    color:#fff;
    font-weight:600;
    font-size:14px;
    width:20px;
    height:20px;
    border-radius:50%;
    margin-right:10px;
    line-height: 0;
`;

const ElementsWrapper = styled.div`
    max-height:calc(100% - 30px);
    overflow-x:hidden;
    overflow-y:auto;
`;