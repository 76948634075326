import React, { useEffect, useState } from "react";
import { getDoctorDashbordVisits } from "../../../../redux/modules/visits";
import { connect } from 'react-redux';
import DoctorDashboardSidebarView from "./DoctorDashboardSidebarView";
import { setCurrentVisitPatient } from '../../../../redux/modules/patients';
import { setCurrentVisit } from "../../../../redux/modules/visits";
import { updateCalendarDate } from '../../../../redux/modules/calendar';
import { dashboardPageLoaded } from '../../../../redux/modules/application';
import { addPatient, getCompanyPatients } from "../../../../redux/modules/patients";
import { addVisit } from "../../../../redux/modules/visits";
import { getEmployees } from "../../../../redux/modules/employees";
import { nextTutorialStep } from "../../../../redux/modules/tutorial";
import useDebouncer from '../../../Shared/Hooks/useDebouncer';

import "moment/locale/pl";
import moment from "moment";

const DoctorDashboardSidebarContainer = ({ healthcenters, user, doctors, nurses, patients, addVisit, addPatient, currentPage, loading, updateCalendarDate, calendarDate, currentPatientPreviousVisit, setCurrentVisitPatient, setCurrentVisit, userRole, getVisitsByDoctorId, visits, currentVisit, dashboardPageLoaded, getEmployees, getCompanyPatients, nextTutorialStep
}) => {
  const selectOptions = [
    { label: 'Wszystkie', value: 'all' },
    { label: 'Potwierdzone', value: 'CONFIRMED' },
    { label: 'Niepotwierdzone', value: 'PLANNED' },
    { label: 'W poczekalni', value: 'WAITING' },
    { label: 'Odwołane', value: 'REJECTED' }
  ];
  const [listVisits, setListVisits] = useState([]);
  const [selectValue, setSelectValue] = useState(selectOptions[0]);
  const [isCreateModalOn, setCreateModal] = useState();
  const [actionsOpen, setActionsOpen] = useState(false);
  const [warningModal, setWarningModal] = useState({ shown: false, question: null, onAccept: null });

  const [setDebounce] = useDebouncer();

  const chooseMostImportantVisit = (visits) => {
    const firstStartedVisit = visits.find(visit => visit.state === "STARTED")
    if (firstStartedVisit) {
      return firstStartedVisit
    }
    const firstWaitingVisit = visits.find(visit => visit.state === "WAITING")
    if (firstWaitingVisit) {
      return firstWaitingVisit
    }
    const firstConfirmedVisit = visits.find(visit => visit.state === "CONFIRMED")
    if (firstConfirmedVisit) {
      return firstConfirmedVisit
    }
    const firstPlannedVisit = visits.find(visit => visit.state === "PLANNED")
    if (firstPlannedVisit) {
      return firstPlannedVisit
    }
    const firstEndedVisit = visits.find(visit => visit.state === "ENDED")
    if (firstEndedVisit) {
      return firstEndedVisit
    }
    const firstRejectedVisit = visits.find(visit => visit.state === "REJECTED")
    if (firstRejectedVisit) {
      return firstRejectedVisit
    }
  }

  const filterVisits = (state) => {
    const visitsToSet = visits.sort((a, b) => new Date(a.start) - new Date(b.start))
    visitsToSet.forEach((visit, i, sortedVisits) => {
      if (i + 1 < sortedVisits.length) {
        visit.timeToNext = moment(sortedVisits[i + 1].start).diff(moment(visit.end), 'minutes')
      } else {
        visit.timeToNext = '';
      }
    });
    if (state !== 'all') {
      setListVisits(visitsToSet.filter(visit => visit.state === state))
    } else {
      setListVisits(visitsToSet)
    }
    setSelectValue(selectOptions.find(option => option.value === state))
  }

  const getCompanyPatientsHandler = (searchTerm) => {
    setDebounce(() => {
      getCompanyPatients(userRole.companyId, 20, 1, searchTerm);
    })
  }

  useEffect(() => {
    if (userRole) {
      getVisitsByDoctorId(moment(calendarDate).startOf('day').toDate(), moment(calendarDate).endOf('day').toDate(), userRole._id)
      if (doctors.length === 0) {
        getEmployees('doctor', userRole.companyId);
      }
      if (nurses.length === 0) {
        getEmployees('nurse', userRole.companyId);
      }
      if (currentPage !== "DASHBOARD") {
        dashboardPageLoaded()
      }
    }
  }, [calendarDate, userRole])

  useEffect(() => {
    const visitsToSet = visits.filter(({ start }) => moment(start).date() === moment().date()).sort((a, b) => new Date(a.start) - new Date(b.start))
    visitsToSet.forEach((visit, i, sortedVisits) => {
      if (i + 1 < sortedVisits.length) {
        visit.timeToNext = moment(sortedVisits[i + 1].start).diff(moment(visit.end), 'minutes')
      } else {
        visit.timeToNext = 0;
      }
    });
    setListVisits(visitsToSet)
    filterVisits(selectValue.value)

    if (visitsToSet.length > 0) {
      setCurrentVisit(chooseMostImportantVisit(visitsToSet))
      setCurrentVisitPatient(chooseMostImportantVisit(visitsToSet).patient)
    }

  }, [visits])

  return (
    <DoctorDashboardSidebarView actionsOpen={actionsOpen} setActionsOpen={setActionsOpen} healthcenters={healthcenters} userRole={userRole} user={user} doctors={[...doctors, ...nurses]} patients={patients} addVisit={addVisit} addPatient={addPatient} isCreateModalOn={isCreateModalOn} setCreateModal={setCreateModal} updateCalendarDate={updateCalendarDate} calendarDate={calendarDate} selectValue={selectValue} selectOptions={selectOptions} filterVisits={filterVisits} visits={listVisits} currentVisit={currentVisit} setCurrentVisitPatient={setCurrentVisitPatient} setCurrentVisit={setCurrentVisit} warningModal={warningModal} setWarningModal={setWarningModal} getCompanyPatientsHandler={getCompanyPatientsHandler} nextTutorialStep={nextTutorialStep} />
  );
};

const mapStateToProps = state => ({
  visits: state.visits.data,
  loading: state.visits.loading,
  currentVisit: state.visits.currentVisit,
  currentPatientPreviousVisit: state.patients.currentPatientPreviousVisit,
  calendarDate: state.calendar.calendarDate,
  currentPage: state.application.currentPage,
  user: state.authentication.user,
  patients: state.patients.data,
  doctors: state.employees.doctors,
  nurses: state.employees.nurses,
  healthcenters: state.healthcenter.data
});

const mapDispatchToProps = dispatch => {
  return {
    getVisitsByDoctorId: (start, end, id) => dispatch(getDoctorDashbordVisits(start, end, id)),
    setCurrentVisitPatient: (patient) => dispatch(setCurrentVisitPatient(patient)),
    setCurrentVisit: (visit) => dispatch(setCurrentVisit(visit)),
    updateCalendarDate: date => dispatch(updateCalendarDate(date)),
    dashboardPageLoaded: () => dispatch(dashboardPageLoaded()),
    addVisit: (visit, force) => dispatch(addVisit(visit, force)),
    addPatient: (companyId, patient) => dispatch(addPatient(companyId, patient)),
    getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
    getCompanyPatients: (companyId, pageSize, pageNumber, searchTerm) =>
      dispatch(getCompanyPatients(companyId, pageSize, pageNumber, searchTerm)),
    nextTutorialStep: () => dispatch(nextTutorialStep()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorDashboardSidebarContainer);