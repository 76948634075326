import axios from 'axios';

const GET_HEALTHCENTERS_REQUEST = 'GET_HEALTHCENTERS_REQUEST';
const GET_HEALTHCENTERS_SUCCESS = 'GET_HEALTHCENTERS_SUCCESS';
const GET_HEALTHCENTERS_FAIL = 'GET_HEALTHCENTERS_FAIL';

const UPDATE_HEALTHCENTER_REQUEST = 'UPDATE_HEALTHCENTER_REQUEST';
const UPDATE_HEALTHCENTER_SUCCESS = 'UPDATE_HEALTHCENTER_SUCCESS';
const UPDATE_HEALTHCENTER_FAIL = 'UPDATE_HEALTHCENTER_FAIL';

const ADD_HEALTHCENTER_REQUEST = 'ADD_HEALTHCENTER_REQUEST';
const ADD_HEALTHCENTER_SUCCESS = 'ADD_HEALTHCENTER_SUCCESS';
const ADD_HEALTHCENTER_FAIL = 'ADD_HEALTHCENTER_FAIL';

const SET_CURRENT_HEALTHCENTER = 'SET_CURRENT_HEALTHCENTER';
const GET_CURRENT_HEALTHCENTER_LOCAL_STORAGE = 'GET_CURRENT_HEALTHCENTER_LOCAL_STORAGE';

const GET_CURRENT_HEALTHCENTER_REQUEST = 'GET_CURRENT_HEALTHCENTER_REQUEST';
const GET_CURRENT_HEALTHCENTER_SUCCESS = 'GET_CURRENT_HEALTHCENTER_SUCCESS';
const GET_CURRENT_HEALTHCENTER_FAIL = 'GET_CURRENT_HEALTHCENTER_FAIL';

const HEALTHCENTER_SET_INITIAL = "HEALTHCENTER_SET_INITIAL";

const RESTORE_HEALTHCENTER_REQUEST = "RESTORE_HEALTHCENTER_REQUEST";
const RESTORE_HEALTHCENTER_SUCCESS = "RESTORE_HEALTHCENTER_SUCCESS";
const RESTORE_HEALTHCENTER_FAIL = "RESTORE_HEALTHCENTER_FAIL";

export const setInitialHealthcenter = () => ({
    type: HEALTHCENTER_SET_INITIAL
})

export const getEmployeeHealthcenters = (healthcentersArray) => async dispatch => {
    dispatch(getHealthcentersRequest())
    try {
        const response = await axios.post(`/healthcenter/list`, { healthcenterIds: healthcentersArray });
        const { data } = response;
        dispatch(getHealthcentersSuccess(data))
    } catch (error) {
        dispatch(getHealthcentersFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

export const getHealthcenters = (id) => async dispatch => {
    dispatch(getHealthcentersRequest());
    try {
        const response = await axios.get(`/healthcenter/companyId/${id}`);
        dispatch(getHealthcentersSuccess(response.data));
    } catch (error) {
        dispatch(getHealthcentersFail(error))
    }
}

const getHealthcentersRequest = () => ({
    type: GET_HEALTHCENTERS_REQUEST,
})

const getHealthcentersSuccess = (healthcenters) => ({
    type: GET_HEALTHCENTERS_SUCCESS,
    payload: healthcenters
})

const getHealthcentersFail = (error) => ({
    type: GET_HEALTHCENTERS_FAIL,
    payload: error
})

export const updateHealthcenter = (id, body) => async dispatch => {
    dispatch(updateHealthcenterRequest());
    try {
        const response = await axios.patch(`/healthcenter/${id}`,
            body);
        dispatch(updateHealthcenterSuccess(response.data));
    } catch (error) {
        dispatch(updateHealthcenterFail(error))
    }
}

const updateHealthcenterRequest = () => ({
    type: UPDATE_HEALTHCENTER_REQUEST,
})

const updateHealthcenterSuccess = (healthcenters) => ({
    type: UPDATE_HEALTHCENTER_SUCCESS,
    payload: healthcenters
})

const updateHealthcenterFail = (error) => ({
    type: UPDATE_HEALTHCENTER_FAIL,
    payload: error
})

export const addHealthcenter = (body) => async dispatch => {
    dispatch(addHealthcenterRequest());
    try {
        const response = await axios.post(`/healthcenter/`, body, {
            validateStatus: function (status) {
                return status <= 300; 
            }
        });
        if (response.status === 300) {
            return response.data.data;
        }
        dispatch(addHealthcenterSuccess(response.data));
    } catch (error) {
        dispatch(addHealthcenterFail(error))
    }
}

const addHealthcenterRequest = () => ({
    type: ADD_HEALTHCENTER_REQUEST,
})

const addHealthcenterSuccess = (healthcenters) => ({
    type: ADD_HEALTHCENTER_SUCCESS,
    payload: healthcenters
})

const addHealthcenterFail = (error) => ({
    type: ADD_HEALTHCENTER_FAIL,
    payload: error
})

export const setCurrentHealthcenter = (healthcenter) => async dispatch => {
    setToLocalStorage("currentHealthcenter", healthcenter);
    dispatch({
        type: SET_CURRENT_HEALTHCENTER,
        payload: healthcenter
    })
}

export const getCurrentHealthcenterLocalStorage = () => ({
    type: GET_CURRENT_HEALTHCENTER_LOCAL_STORAGE
});

export const getFromLocalStorage = (itemName) =>
    JSON.parse(localStorage.getItem(itemName))

export const setToLocalStorage = (itemName, payload) =>
    localStorage.setItem(itemName, JSON.stringify(payload));

export const getCurrentHealthcenter = (healthcenterId) => async dispatch => {
    dispatch(getCurrentHealthcenterRequest());
    try {
        const response = await axios.get(`/healthcenter/${healthcenterId}`);
        dispatch(getCurrentHealthcenterSuccess(response.data));
    } catch (error) {
        dispatch(getCurrentHealthcenterFail(error))
    }
}

const getCurrentHealthcenterRequest = () => ({
    type: GET_CURRENT_HEALTHCENTER_REQUEST,
})

const getCurrentHealthcenterSuccess = (healthcenter) => ({
    type: GET_CURRENT_HEALTHCENTER_SUCCESS,
    payload: healthcenter
})

const getCurrentHealthcenterFail = (error) => ({
    type: GET_CURRENT_HEALTHCENTER_FAIL,
    payload: error
})

export const restoreHealthcenter = ({ id, ...hc }) => async (dispatch) => {
    dispatch(restoreHealthcenterRequest());
    try {
        const response = await axios.patch(`healthcenter/${id}`, { deleted: false, ...hc });
        const { data } = response;
        dispatch(restoreHealthcenterSuccess(data));
    } catch (error) {
        dispatch(restoreHealthcenterFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
};

const restoreHealthcenterRequest = () => ({
    type: RESTORE_HEALTHCENTER_REQUEST,
});
const restoreHealthcenterSuccess = (healthcenter) => ({
    type: RESTORE_HEALTHCENTER_SUCCESS,
    payload: healthcenter
});
const restoreHealthcenterFail = (error) => ({
    type: RESTORE_HEALTHCENTER_FAIL,
    payload: error,
});

const initialState = {
    isLoading: true,
    data: [],
    currentHealthcenter: null,
    error: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_HEALTHCENTERS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case GET_HEALTHCENTERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }
        case GET_HEALTHCENTERS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case UPDATE_HEALTHCENTER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_HEALTHCENTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: state.data.filter(el => {
                    if (action.payload.deleted && el._id === action.payload._id) {
                        return false;
                    }
                    return true;
                }).map(el => el._id === action.payload._id ? action.payload : el)
            }
        case UPDATE_HEALTHCENTER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case ADD_HEALTHCENTER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case ADD_HEALTHCENTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: [...state.data, action.payload]
            }
        case ADD_HEALTHCENTER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SET_CURRENT_HEALTHCENTER:
            return {
                ...state,
                currentHealthcenter: action.payload
            }
        case GET_CURRENT_HEALTHCENTER_LOCAL_STORAGE:
            return {
                ...state,
                currentHealthcenter: state.data[0],
            }
        case HEALTHCENTER_SET_INITIAL:
            return {
                isLoading: true,
                data: [],
                currentHealthcenter: null
            }
        case GET_CURRENT_HEALTHCENTER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CURRENT_HEALTHCENTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                currentHealthcenter: action.payload,
                data: [...state.data, action.payload]
            }
        case GET_CURRENT_HEALTHCENTER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case RESTORE_HEALTHCENTER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case RESTORE_HEALTHCENTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: [...state.data, action.payload]
            }
        case RESTORE_HEALTHCENTER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        default:
            return state;
    }
};