import React, { useEffect } from "react";
import styled from "styled-components";
import Button from '../../Shared/Button';
import Input from '../../Shared/Input';
import Spinner from '../../Shared/Spinner';
import SearchIcon from '../../Shared/assets/search.svg';
import AvatarPlaceholder from '../../Shared/assets/avatar.svg';
import PatientModal from '../../Shared/Modals/PatientModal';
import { Link } from 'react-router-dom';
import PaginationContainer from "../../Shared/PaginationContainer"

const ReceptionistPatientsSidebarView = ({
  patientModalState,
  setPatientModalState,
  addPatientHandler,
  sidepanelPatients,
  sidepanelFilter,
  setSidepanelFilter,
  setCurrentPatient,
  currentPatientId,
  urlCurrentTab,
  sidebarLoading,
  patientsPageLoaded,
  currentPage,
  paginationMetadata,
  onPatientPageChange,
  setPatientAvatar,
  nextTutorialStep,
}) => {
  const calculateAge = (birthDate) => {
    if (birthDate && birthDate !== "") {
      const date = birthDate.split('-', 3);
      date[2] = date[2].replace('r.', '');
      const birthDateUTC = Date.UTC(date[2], date[1], date[0], '0', '0', '0');
      var ageDifMs = Date.now() - birthDateUTC;
      var ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    } else {
      return '';
    }
  }
  useEffect(() => {
    setSidepanelFilter("")
  }, [])

  useEffect(() => {
    if (currentPatientId && currentPage !== "PATIENTS") {
      patientsPageLoaded()
    }
  }, [currentPatientId]);

  return (
    <>
      <PatientModal
        modalState={patientModalState}
        setModalState={setPatientModalState}
        onSubmit={addPatientHandler}
        setPatientAvatar={setPatientAvatar}
        nextTutorialStep={nextTutorialStep}
      />
      <SidebarWrapper>
        <TopPanel>
          <Button
            id='addNewPatientBtn'
            plusIcon={true}
            variant="primary"
            onClick={() => {
              setPatientModalState(true)
              nextTutorialStep();
            }}
          >Dodaj nowego pacjenta</Button>
          <SearchInput name='searchPatientInput' placeholder="Szukaj pacjenta..." onChange={({ target: { value } }) => setSidepanelFilter(value)} hideError />
          <PaginationContainer
            isVisitsPagination={false}
            currentPage={paginationMetadata?.currentPage}
            totalPages={paginationMetadata?.totalPages}
            onPageChange={onPatientPageChange}
          />
        </TopPanel>
        <PatientsList id='patientsList'>
          {sidepanelPatients &&
            sidepanelPatients.length === 0 && sidepanelFilter !== '' && !sidebarLoading ?
            <SearchError>Nie znaleziono pacjenta:<br /> <span>{sidepanelFilter}</span></SearchError>
            : sidebarLoading ?
              <Spinner />
              :
              sidepanelPatients.map(({ name, surname, tel, birthDate, _id, avatar }) => {
                const age = calculateAge(birthDate);
                return (
                  <PatientItem key={_id} to={({ pathname }) => pathname.includes(_id) ? pathname : `/patients/${_id}/${urlCurrentTab ? urlCurrentTab : 'visits'}`} onClick={() => setCurrentPatient(_id)} active={_id === currentPatientId ? 'true' : 'false'}>
                    <Avatar src={avatar ? `${process.env.REACT_APP_SRV_URL}${avatar}` : AvatarPlaceholder} />
                    <Details>
                      <Name>{name} {surname}</Name>
                      <Number>{`${age ? `${age} lat,` : ''}  ${tel}`}</Number>
                    </Details>
                  </PatientItem>
                )
              })
          }
        </PatientsList>
      </SidebarWrapper>
    </>
  );
};

export default ReceptionistPatientsSidebarView;

const SidebarWrapper = styled.div`
  display:flex;
  flex-direction:column;
  max-height: calc(100vh - 60px);
`;

const SearchInput = styled(Input)`
  position: relative;
  left: 2px;
  background-image: url(${SearchIcon});
  background-position: center right 15px;
  background-repeat: no-repeat;
  margin: 15px 0 6px 0;
  &:focus {
    background-position: center right 14px;
  }
`;

const TopPanel = styled.div`
  padding: 14px 30px 0px 30px;
  >button{
    width:100%;
  }
`;

const PatientsList = styled.ul`
  max-height: calc(100vh - 209px);
  height: 100%;
  overflow: auto;
  margin: 0;
`;


const PatientItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 40px;
  width: 100%;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  &:hover{
    background-color: #f0f5ff; 
  }
  ${({ active }) => (
    active === 'true' && `background-color: #f0f5ff;`
  )}
`;

const SearchError = styled.p`
  font-size: 18px;
  max-width:80%;
  margin:auto;
  text-align:center;
  >span{
    padding-top:5px;
    color:${({ theme }) => theme.primary};
    font-size:24px;
    word-break: break-all;
  }
`

const Avatar = styled.img`
  border-radius: 50%;
  width:40px;
  height:40px;
`;

const Details = styled.div`
  text-align:center;
  padding-left:5px;
  width:calc(100% - 40px);
`;

const Name = styled.p`
  font-weight: 600;
  font-size: 15px;
`;

const Number = styled.p`
  color:#939393;
  font-size: 14px;
`;
