import React, { useEffect, useState } from "react";
import HealthcentersView from "./HealthcentersView";
import { connect } from "react-redux";
import { getCompany } from '../../redux/modules/company';
import { getHealthcenters, updateHealthcenter, addHealthcenter, restoreHealthcenter } from '../../redux/modules/healthcenter';
import { getEmployees } from '../../redux/modules/employees';

const HealthcentersContainer = ({
  isLoading,
  company,
  doctors,
  registries,
  nurses,
  getCompany,
  getEmployees,
  userRole,
  healthcenters,
  getHealthcenters,
  updateHealthcenter,
  addHealthcenter,
  restoreHealthcenter
}) => {

  const
    [pageInitialized, setPageInitialized] = useState(false),
    [healthcentersModalState, setHealthcentersModalState] = useState({ edit: false, shown: false }),
    [groupsModalState, setGroupsModalState] = useState({ shown: false, target: null }),
    [servicesModalState, setServicesModalState] = useState({ edit: false, shown: false, targetName: null }),
    [removeModalState, setRemoveModalState] = useState({ onAccept: null, onCancel: null, question: '', shown: false }),
    [employeesModalState, setEmployeesModalState] = useState({ target: null, shown: false }),
    [roomsModalState, setRoomsModalState] = useState(false),
    [currentEditedHealthcenter, setCurrentEditedHealthcenter] = useState(null),
    [selectedHealthcenter, setSelectedHealthcenter] = useState(null),
    [selectOptions, setSelectOptions] = useState([]),
    [selectValue, setSelectValue] = useState(null),
    [doctorsOfSelectedHealthcenter, setDoctorsOfSelectedHealthcenter] = useState([]),
    [registriesOfSelectedHealthcenter, setRegistriesOfSelectedHealthcenter] = useState([]),
    [nursesOfSelectedHealthcenter, setNursesOfSelectedHealthcenter] = useState([]);

  const editHealthcenterHandler = (id) => {
    const target = healthcenters.filter(hc => (
      hc._id === id
    ));
    setCurrentEditedHealthcenter(target[0]);
    setHealthcentersModalState({ edit: true, shown: true });
  },
    healthcenterChangeHandler = (targetId) => {
      setSelectedHealthcenter(healthcenters.find(hc => (
        hc._id === targetId
      )));
      setSelectValue(selectOptions.find(option => option.value === targetId))
    },
    removeHandler = (name, target) => {
      const updated = selectedHealthcenter[target].filter(el => (
        el.name !== name
      ))
      updateHealthcenter(selectedHealthcenter._id, { [target]: updated });
      setRemoveModalState({ shown: false });
    }
  useEffect(() => {
    if (userRole !== null) {
      getCompany(userRole.companyId);
      getHealthcenters(userRole.companyId);
      getEmployees('doctor', userRole.companyId);
      getEmployees('registry', userRole.companyId);
      getEmployees('nurse', userRole.companyId);
    }
  }, [getCompany, getHealthcenters, getEmployees, userRole]);

  useEffect(() => {
    if (healthcenters?.length > 0 && !pageInitialized) {
      const queryId = new URLSearchParams(window.location.search).get("id")
      const initalHc = healthcenters.find(({ _id }) => _id === queryId) ?? healthcenters[0];
      setSelectedHealthcenter(initalHc);
      const options = healthcenters.map(hc => (
        { value: hc._id, label: hc.name }
      )) ?? [];
      const initalOption = options.find(({ value }) => value === queryId) ?? options[0];
      setSelectOptions(options);
      setSelectValue(initalOption);
      setPageInitialized(true);
    } else {
      if (healthcenters && healthcenters?.length > selectOptions?.length) {
        const options = healthcenters.map(hc => (
          { value: hc._id, label: hc.name }
        )) ?? [];
        setSelectOptions(options);
      }
      if (selectOptions && selectValue) {
        setSelectedHealthcenter(healthcenters[selectOptions.findIndex(el => el.label === selectValue.label)]);
      }
    }
  }, [healthcenters]);

  useEffect(() => {
    if (selectedHealthcenter) {
      const doctorsIds = selectedHealthcenter.doctors.map(docId => (docId));
      setDoctorsOfSelectedHealthcenter(doctors.filter(doc => {
        if (doctorsIds.indexOf(doc._id) !== -1) {
          return doc;
        }
      }));
    }
  }, [selectedHealthcenter, doctors]);

  useEffect(() => {
    if (selectedHealthcenter) {
      const registriesIds = selectedHealthcenter.registries.map(regId => (regId));
      setRegistriesOfSelectedHealthcenter(registries.filter(reg => {
        if (registriesIds.indexOf(reg._id) !== -1) {
          return reg;
        }
      }));
    }
  }, [selectedHealthcenter, registries]);

  useEffect(() => {
    if (selectedHealthcenter) {
      const nursesIds = selectedHealthcenter.nurses.map(regId => (regId));
      setNursesOfSelectedHealthcenter(nurses.filter(nur => {
        if (nursesIds.indexOf(nur._id) !== -1) {
          return nur;
        }
      }));
    }
  }, [selectedHealthcenter, nurses]);

  return <HealthcentersView
    isLoading={isLoading}
    doctors={doctors}
    registries={registries}
    nurses={nurses}
    company={company}
    healthcenters={healthcenters}
    updateHealthcenter={updateHealthcenter}
    addHealthcenter={addHealthcenter}
    currentEditedHealthcenter={currentEditedHealthcenter}
    editHealthcenterHandler={editHealthcenterHandler}
    healthcentersModalState={healthcentersModalState}
    setHealthcentersModalState={setHealthcentersModalState}
    employeesModalState={employeesModalState}
    setEmployeesModalState={setEmployeesModalState}
    selectedHealthcenter={selectedHealthcenter}
    selectOptions={selectOptions}
    setSelectOptions={setSelectOptions}
    selectValue={selectValue}
    setSelectValue={setSelectValue}
    healthcenterChangeHandler={healthcenterChangeHandler}
    doctorsOfSelectedHealthcenter={doctorsOfSelectedHealthcenter}
    registriesOfSelectedHealthcenter={registriesOfSelectedHealthcenter}
    nursesOfSelectedHealthcenter={nursesOfSelectedHealthcenter}
    roomsModalState={roomsModalState}
    setRoomsModalState={setRoomsModalState}
    groupsModalState={groupsModalState}
    setGroupsModalState={setGroupsModalState}
    servicesModalState={servicesModalState}
    setServicesModalState={setServicesModalState}
    removeModalState={removeModalState}
    setRemoveModalState={setRemoveModalState}
    removeHandler={removeHandler}
    restoreHealthcenter={restoreHealthcenter}
  />;
};



const mapStateToProps = state => ({
  isLoading: state.company.isLoading,
  company: state.company.data,
  userRole: state.authentication.userRole,
  healthcenters: state.healthcenter.data,
  doctors: state.employees.doctors,
  registries: state.employees.registries,
  nurses: state.employees.nurses,
});


const mapDispatchToProps = dispatch => {
  return {
    getCompany: (companyId) => dispatch(getCompany(companyId)),
    getHealthcenters: (companyId) => dispatch(getHealthcenters(companyId)),
    updateHealthcenter: (healthcenterId, newData) => dispatch(updateHealthcenter(healthcenterId, newData)),
    addHealthcenter: (newData) => dispatch(addHealthcenter(newData)),
    getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
    restoreHealthcenter: (healthcenter) => dispatch(restoreHealthcenter(healthcenter)),
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthcentersContainer);