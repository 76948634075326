import axios from "axios";

const GET_PATIENT_RISKFACTORS_REQUEST = "GET_PATIENT_RISKFACTORS_REQUEST";
const GET_PATIENT_RISKFACTORS_SUCCESS = "GET_PATIENT_RISKFACTORS_SUCCESS";
const GET_PATIENT_RISKFACTORS_FAIL = "GET_PATIENT_RISKFACTORS_FAIL";

const UPDATE_PATIENT_RISKFACTORS_REQUEST = "UPDATE_PATIENT_RISKFACTORS_REQUEST";
const UPDATE_PATIENT_RISKFACTORS_SUCCESS = "UPDATE_PATIENT_RISKFACTORS_SUCCESS";
const UPDATE_PATIENT_RISKFACTORS_FAIL = "UPDATE_PATIENT_RISKFACTORS_FAIL";

const GET_CANCERS_REQUEST = "GET_CANCERS_REQUEST";
const GET_CANCERS_SUCCESS = "GET_CANCERS_SUCCESS";
const GET_CANCERS_FAIL = "GET_CANCERS_FAIL";

export const getPatientRiskFactors = (patientId) => async dispatch => {
    dispatch(getPatientRiskFactorsRequest())
    try {
        const response = await axios.get(`riskfactors/patient/${patientId}`);
        dispatch(getPatientRiskFactorsSuccess(response.data))
    }
    catch (error) {
        dispatch(getPatientRiskFactorsFail(error))
    }
};

const getPatientRiskFactorsRequest = () => ({
    type: GET_PATIENT_RISKFACTORS_REQUEST,
});

const getPatientRiskFactorsSuccess = (examinations) => ({
    type: GET_PATIENT_RISKFACTORS_SUCCESS,
    payload: examinations
});

const getPatientRiskFactorsFail = (error) => ({
    type: GET_PATIENT_RISKFACTORS_FAIL,
    payload: error
});

export const updatePatientRiskFactors = (patientId, newRiskFactors) => async dispatch => {
    dispatch(updatePatientRiskFactorsRequest())
    try {
        const response = await axios.patch(`riskfactors/patient/${patientId}`, newRiskFactors)
        dispatch(updatePatientRiskFactorsSuccess(response.data))
    }
    catch (error) {
        dispatch(updatePatientRiskFactorsFail(error))
    }
};

const updatePatientRiskFactorsRequest = () => ({
    type: UPDATE_PATIENT_RISKFACTORS_REQUEST,
});

const updatePatientRiskFactorsSuccess = (riskFactor) => ({
    type: UPDATE_PATIENT_RISKFACTORS_SUCCESS,
    payload: riskFactor
});

const updatePatientRiskFactorsFail = (error) => ({
    type: UPDATE_PATIENT_RISKFACTORS_FAIL,
    payload: error
});

export const getCancers = (value) => async dispatch => {
    dispatch(getCancersRequest())
    try {
        const response = await axios.get(`${process.env.REACT_APP_MED_REGISTER_URL}icd10s?value=${value}&rangeStart=C&rangeEnd=D`);
        dispatch(getCancersSuccess(response.data.data));
    }
    catch (error) {
        dispatch(getCancersFail(error))
    }
};

const getCancersRequest = () => ({
    type: GET_CANCERS_REQUEST,
});

const getCancersSuccess = (cancers) => ({
    type: GET_CANCERS_SUCCESS,
    payload: cancers
});

const getCancersFail = (error) => ({
    type: GET_CANCERS_FAIL,
    payload: error
});

const initialState = {
    patientRiskFactors: [],
    cancers: [],
    loading: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT_RISKFACTORS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_PATIENT_RISKFACTORS_SUCCESS:
            return {
                ...state,
                patientRiskFactors: action.payload,
                loading: false
            }
        case GET_PATIENT_RISKFACTORS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_PATIENT_RISKFACTORS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_PATIENT_RISKFACTORS_SUCCESS:
            return {
                ...state,
                patientRiskFactors: action.payload,
                loading: false
            }
        case UPDATE_PATIENT_RISKFACTORS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_CANCERS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_CANCERS_SUCCESS:
            return {
                ...state,
                cancers: action.payload,
                loading: false
            }
        case GET_CANCERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};