import React, { useState, useEffect } from 'react';
import Modal from '../../../Shared/Modals/Modal';
import DatePicker from '../../../Shared/DatePicker';
import Button from '../../../Shared/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import moment from 'moment';
import AddIcon from "../../../Shared/assets/add-plus.svg";

const VacationModal = ({
    modalState,
    setModalState,
    vacations,
    userVacationsCount,
    userVacationsHandler,
    nextTutorialStep,
}) => {
    const [listItems, setListItems] = useState([]),
        [initialValues, setInitialValues] = useState({
            start: Date.now(),
            end: Date.now(),
        }),
        [vacDays, setVacDays] = useState(0);

    const addItemHandler = ({ start, end }) => {
        const diffTime = Math.abs(start - end);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setListItems(prev => ([
            ...prev,
            {
                start: start,
                end: end,
                workDaysCount: diffDays
            }
        ]));
    },
        removeItemHandler = (targetIndex) => {
            setListItems(prev => prev.filter((el, index) => index !== targetIndex));
        }

    useEffect(() => {
        setListItems(vacations);
        setVacDays(userVacationsCount);
    }, [vacations, userVacationsCount]);

    useEffect(() => {
        const newDays = listItems.reduce((a, b) => a + (b.workDaysCount || 0), 0);
        setVacDays(newDays);
    }, [listItems]);

    return (
        <Modal
            title='Edycja urlopów'
            show={modalState}
            exitButton={true}
            onCancel={() => {
                setModalState(false);
                if (nextTutorialStep) {
                    nextTutorialStep();
                }
            }}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={() => {
                    const vacationsToDelete = vacations.filter(vacation => !listItems.find(vac => vacation._id === vac._id));
                    const vacationsToAdd = listItems.filter(vac => !vac._id);
                    userVacationsHandler(vacationsToAdd, vacationsToDelete);
                    setModalState(false);
                    if (nextTutorialStep) {
                        nextTutorialStep();
                    }
                }}
            >
                {({ handleSubmit, values, errors, setFieldValue, resetForm }) => (
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <div id='addVacationForm'>
                            <InputRow>
                                <DatePicker
                                    label="Początek"
                                    name="start"
                                    dateFormat="dd.MM.yyyy"
                                    selected={new Date(values.start)}
                                    selectsStart
                                    startDate={values.start}
                                    endDate={values.end}
                                    onChange={value =>
                                        setFieldValue(
                                            "start",
                                            new Date(value)
                                        )
                                    }
                                />
                                <DatePicker
                                    label="Koniec"
                                    name="end"
                                    dateFormat="dd.MM.yyyy"
                                    selected={new Date(values.end)}
                                    selectsEnd
                                    startDate={values.start}
                                    endDate={values.end}
                                    onChange={value =>
                                        setFieldValue(
                                            "end",
                                            new Date(value)
                                        )
                                    }
                                />
                            </InputRow>
                            <AddVacationButton title='Dodaj urlop' type='button' onClick={() => {
                                addItemHandler({
                                    start: values.start,
                                    end: values.end,
                                });
                                resetForm();
                            }}>
                                Dodaj urlop
                            <AddVacationIcon src={AddIcon} />
                            </AddVacationButton>
                        </div>
                        <ListLabel>Twoje urlopy:</ListLabel>
                        <List>
                            {listItems.length > 0 ? listItems.map(({ start, end, _id }, index) => (
                                <ListItem key={`vacations_${index}`}>
                                    {moment(start).format("DD.MM.YYYY")} -{" "}
                                    {moment(end).format("DD.MM.YYYY")}
                                    <RemoveBtn type='button' onClick={() => removeItemHandler(index)} />
                                </ListItem>
                            )) : <NoItems>Brak zaplanowanych urlopów</NoItems>}
                        </List>
                        <VactaionsDaysCount>Wykorzystane dni urlopu: {vacDays}</VactaionsDaysCount>
                        <CenterBox pt>
                            <Button type='submit' variant="primary">
                                Zapisz zmiany
                            </Button>
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default VacationModal;

const Form = styled.form`
    width:400px;
    margin-top:20px;
`;

const CenterBox = styled.div`
    text-align:center;
    ${({ pt }) => pt && 'padding-top:30px;'}
`;

const InputRow = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:10px;
`;

const List = styled.ul`
    margin-top:5px;
`;

const ListItem = styled.li`
    position:relative;
    padding: 5px;
    cursor:pointer;
    &:not(:last-child){
        border-bottom: 1px solid ${({ theme }) => theme.grey};
    }
`;

const NoItems = styled.p`
    text-align: center;
    font-size: 14px;
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const VactaionsDaysCount = styled.p`
    text-align:right;
    font-size:14px;
`;

const AddVacationButton = styled.button`
  border-radius: 5px;
  cursor: pointer;
  margin: 0 0 15px auto;
  background-color: ${({ theme }) => theme.green};
  border: 1px solid ${({ theme }) => theme.green};
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  color: #fff;
  max-width: 115px;
  height: 25px;
`;

const AddVacationIcon = styled.img`
  margin-left:5px;
  width: 12px;
  height: 12px;
  filter: invert(98%) sepia(5%) saturate(532%) hue-rotate(92deg)
    brightness(116%) contrast(100%);
`;

const ListLabel = styled.p`
    margin-left:5px;
`;