import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Input from '../../../Shared/Input';
import Textarea from '../../../Shared/Textarea';
import FileUpload from '../../../Shared/FileUpload';
import DatePicker from '../../../Shared/DatePicker';
import Button from '../../../Shared/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';

const DocumentsModal = ({
    modalState: { shown: modalShown, editId: modalEditId },
    setModalState,
    addDocumentHandler,
    patientDocuments,
    deleteModalHandler,
    updateDocumentHandler
}) => {
    const initalValuesSchema = {
        originalName: "",
        description: "",
        uploadDate: Date.now(),
    };
    const [file, setFile] = useState(null),
        [initalValues, setInitalValues] = useState(initalValuesSchema);

    const validationSchema = Yup.object().shape({
        originalName: Yup.string().required('Nazwa dokumentu jest wymagana'),
        uploadDate: Yup.string(),
        description: Yup.string(),
    }),
        resetModalHandler = () => {
            setFile(null);
            setInitalValues(initalValuesSchema);
        };

    useEffect(() => {
        if (modalEditId) {
            const { originalName, description, uploadDate } = patientDocuments.find(el => el._id === modalEditId);
            setInitalValues(prev => {
                return {
                    ...prev,
                    originalName,
                    description,
                    uploadDate: new Date(uploadDate),
                }
            });
            setFile({ name: originalName })
        };
    }, [modalEditId]);

    return (
        <Modal
            title={modalEditId ? 'Edycja dokumentu' : 'Dodawanie dokumentu'}
            exitButton={true}
            onCancel={() => { setModalState({ shown: false, editId: null, }); resetModalHandler(); }}
            show={modalShown}
        >
            <Formik
                validationSchema={validationSchema}
                initialValues={initalValues}
                enableReinitialize
                onSubmit={(values) => {
                    if (modalEditId) {
                        updateDocumentHandler(values);
                    } else {
                        addDocumentHandler({ ...values, file });
                    }
                    resetModalHandler();
                    setModalState({ shown: false, editId: null });
                }}
            >
                {({ handleSubmit, values, setFieldValue, handleChange, errors }) => (
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <FileUpload
                            testId="tabs_dropfile"
                            disabled={modalEditId}
                            label="Plik dokumentu"
                            onDrop={(files) => {
                                setFile(files[0]);
                                if (values.originalName === "") {
                                    const fileName = files[0].name.substring(0, files[0].name.lastIndexOf('.'));
                                    setFieldValue('originalName', fileName);
                                }
                            }}
                            value={file}
                        />
                        <InputRow>
                            <Input
                                disabled={modalEditId}
                                name="originalName"
                                type="text"
                                label="Nazwa dokumentu"
                                onChange={handleChange}
                                value={values.originalName}
                                error={errors.originalName}
                            />
                            <DatePicker
                                disabled={modalEditId}
                                label="Data dodania"
                                showTimeSelect
                                timeFormat="HH:mm"
                                dateFormat="dd.MM.yyyy HH:mm"
                                selected={values.uploadDate}
                                timeCaption="Godzina"
                                onChange={(value) => setFieldValue('uploadDate', value)}
                            />
                        </InputRow>
                        <Textarea
                            name="description"
                            label="Opis dokumentu"
                            value={values.description}
                            error={errors.description}
                            onChange={handleChange}
                        />
                        <CenterBox>
                            <Button onClick={handleSubmit} type='submit' variant='primary'>{modalEditId ? 'Zapisz zmiany' : values.currentPattern === 'createNew' ? 'Dodaj dokument i zapisz szablon' : 'Dodaj dokument'}</Button>
                            {modalEditId && <Button onClick={() => { deleteModalHandler(); setModalState({ shown: false, editId: null, }); resetModalHandler(); }} type='button' variant='danger'>Usuń dokument</Button>}
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default DocumentsModal;

const Form = styled.form`
    width:350px;
    padding-top:10px;
`;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;

const InputRow = styled.div`
    display:grid;
    gap:10px;
    grid-template-columns: 1fr 1fr;
`;

const StyledInput = styled(Input)`
    width:100%;
`;