import React from 'react';
import styled from 'styled-components';
import Container from '../Shared/Container';
import moment from 'moment';
import Button from '../Shared/Button';
import sharpArrowIcon from '../Shared/assets/sharp-arrow.svg';
import { stagesTextsName } from '../Shared/enums';
import Spinner from '../Shared/Spinner';
import Gallery from '../Shared/Gallery';

const History = ({
    visitHistory,
    historyMedicalVisit,
    currentStageIndex,
    changeCurrentHistoryVisitHandler,
    historyLoading,
}) => {
    const stageName = stagesTextsName[currentStageIndex];
    const documents = historyMedicalVisit.documents?.filter(({ path }) => !path.includes('photos')),
        photos = historyMedicalVisit.documents?.filter(({ path }) => path.includes('photos'));
    return (
        <StyledContainer
            title="Historia wizyt"
            className='history-wrapper'
        >
            {historyLoading ?
                <Spinner />
                :
                historyMedicalVisit?._id ? <>
                    <CurrentDate>{moment(visitHistory.currentDate).format('DD.MM.YYYY HH:mm')}</CurrentDate>
                    <StageText
                        dangerouslySetInnerHTML={{ __html: historyMedicalVisit?.stagesTexts ? historyMedicalVisit?.stagesTexts[stageName]?.replace(/(^[ \t]*\n)/gm, "") : '' }}
                    ></StageText>
                    <Files>
                        <FilesCol>
                            <Label>Dokumenty</Label>
                            <FilesItems>
                                {documents?.length > 0 ? documents.map(({ originalName, path, _id }) => (
                                    <DocLink
                                        key={_id}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={`${process.env.REACT_APP_SRV_URL}${path}`}
                                    >
                                        {originalName}
                                    </DocLink>
                                ))
                                    : <NoItems>W tej wizycie nie dodano żadnych dokumentów</NoItems>
                                }
                            </FilesItems>
                        </FilesCol>
                        <FilesCol>
                            <Label>Zdjęcia</Label>
                            <FilesItems>
                                {photos?.length > 0 ? <Gallery
                                    images={photos?.map(({ path, _id, originalName }) => ({ name: originalName, src: `${process.env.REACT_APP_SRV_URL}${path}`, _id }))}
                                />
                                    : <NoItems>W tej wizycie nie dodano żadnych zdjęć</NoItems>
                                }
                            </FilesItems>
                        </FilesCol>
                    </Files>
                    <HisNav>
                        <ArrowBtn
                            variant='secondary'
                            onClick={() => changeCurrentHistoryVisitHandler('prev')}
                            disabled={visitHistory.i === 0}
                        >
                            Poprzednia
                        </ArrowBtn>
                        <ArrowBtn
                            type='right'
                            variant='secondary'
                            onClick={() => changeCurrentHistoryVisitHandler('next')}
                            disabled={visitHistory.i >= visitHistory.all.length - 1}
                        >
                            Następna
                        </ArrowBtn>
                    </HisNav>
                </> :
                    <NoData>Brak historii wizyt dla tego pacjenta</NoData>
            }
        </StyledContainer >
    )
}

export default History;

const StyledContainer = styled(Container)`
    position:relative;
    margin:0 0 10px;
    height: calc(50% - 5px);
`;

const CurrentDate = styled.p`
    font-size:14px;
    font-weight:600;
    position: absolute;
    right: 15px;
    top: 12px;
`;

const HisNav = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    position: absolute;
    left: 15px;
    bottom: 20px;
    width: calc(100% - 30px);
`;

const ArrowBtn = styled(Button)`
    display:flex;
    align-items:center;
    justify-content:space-between;
    font-size:14px; 
    font-weight:600;
    min-width:95px;
    padding:6px;
    ${({ type }) => type === 'right' && `
        flex-direction: row-reverse;
        &::before{
            margin-left:5px;
        }
    `}
    ${({ disabled }) => disabled && `
        opacity:1;
        background-color:#D9D9D9;
    `}
    ${({ type }) => type === 'save' && `
        margin-right:10px;
        min-width:0;
    `}
    &::before{
        content:url(${sharpArrowIcon});
        height:20px;
        width:20px;
        display:inline-block;
        ${({ type }) => type === 'right' && `
            transform: rotate(180deg);
        `}
    }
`;

const Files = styled.div`
  display:flex;
  justify-content: space-between;
  max-height:55%;
  >div{
      width: calc(50% - 5px);
  }
  padding:10px 0;
`;

const FilesCol = styled.div`
  position:relative;
  padding-top:10px;
  max-height:100%;
  overflow:auto;
`;

const StageText = styled.p`
  font-size:14px;
  white-space: pre-wrap;
  height:30%;
  overflow-y: auto;
  b{
      color:${({ theme }) => theme.primary};
  }
`;

const DocLink = styled.a`
  font-size:12px;
  color:#000;
  text-decoration:none;
  transition:300ms;
  &:hover{
    color:${({ theme }) => theme.primary};
  }
`;

const Label = styled.p`
    font-size:14px;
    font-weight:600;
    text-transform:uppercase;
    margin: 0 0 10px 5px;
    color:${({ theme }) => theme.black};
`;

const NoData = styled.p`
    text-align:center;
    padding: 30px 0;
`;

const NoItems = styled.p`
    font-size:13px;
`;

const FilesItems = styled.div`
  max-height:80%;
  min-height: 100px;
  overflow-y:auto;
`;