import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import HospitalizationsTabView from "./HospitalizationsTabView";
import useDebouncer from "../../../../Shared/Hooks/useDebouncer";
import { getPatientHospitalizations, updatePatientHospitalizations } from '../../../../../redux/modules/hospitalizations';
import { getMedicalData } from '../../../../../redux/modules/medicalRest';

const HospitalizationsTabContainer = ({
  location: { pathname },
  currentPatient,
  getPatientHospitalizations,
  updatePatientHospitalizations,
  meds,
  diagnosis,
  hospitals,
  wards,
  procedures,
  vaccinations,
  patientHospitalizations,
  getMedicalData,
}) => {
  const [hospitalizationModal, setHospitalizationModal] = useState({
    shown: false,
    editId: null,
  });
  const [proceduresModal, setProceduresModal] = useState({ shown: false, target: null, editId: null });
  const [setMedsDiagnosisDebouncer] = useDebouncer();
  const [setHospitalizationDebouncer] = useDebouncer();
  // const [debouncerLoading, setDebouncer] = useDebouncer();
  const { currentTab, patientId } = useParams();
  const [deleteModal, setDeleteModal] = useState({
    shown: false,
    question: null,
    onAccept: null,
  });

  useEffect(() => {
    if (currentTab === "hospitalizations" && currentPatient._id === patientId) {
      getPatientHospitalizations(currentPatient._id);
      getMedicalData("wards");
      getMedicalData("vaccinations");
      getMedicalData("hospitals");
      getMedicalData("icd9s");
      if (meds.length === 0) {
        getMedicalData("icd10s");
      }
    }
  }, [currentPatient, pathname]);

  const getMedicalDataHandler = (target, searchValue) => {
    setMedsDiagnosisDebouncer(() => getMedicalData(target, { value: searchValue }));
  };

  const updatePatientHospitalizationHandler = (newHospitalization) => {
    setHospitalizationDebouncer(() =>
      updatePatientHospitalizations(currentPatient._id, newHospitalization)
    );
  };
  return (
    <HospitalizationsTabView
      currentTab={currentTab}
      updatePatientHospitalizationHandler={updatePatientHospitalizationHandler}
      patientHospitalizations={patientHospitalizations}
      setProceduresModal={setProceduresModal}
      proceduresModal={proceduresModal}
      setHospitalizationModal={setHospitalizationModal}
      hospitalizationModal={hospitalizationModal}
      getMedicalDataHandler={getMedicalDataHandler}
      diagnosis={diagnosis}
      hospitals={hospitals}
      wards={wards}
      procedures={procedures}
      vaccinations={vaccinations}
      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currentPatient: state.patients.currentPatient,
  user: state.authentication.user,
  meds: state.medicalRest.meds,
  diagnosis: state.medicalRest.diagnosis,
  hospitals: state.medicalRest.hospitals,
  wards: state.medicalRest.wards,
  procedures: state.medicalRest.procedures,
  vaccinations: state.medicalRest.vaccinations,
  patientHospitalizations: state.hospitalizations.patientHospitalizations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPatientHospitalizations: (patientId) => dispatch(getPatientHospitalizations(patientId)),
    updatePatientHospitalizations: (patientId, newHospitalization) => dispatch(updatePatientHospitalizations(patientId, newHospitalization)),
    getMedicalData: (target, settings) => dispatch(getMedicalData(target, settings)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HospitalizationsTabContainer));
