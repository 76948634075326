import React from 'react';
import styled from 'styled-components';
import Container from '../../../../Shared/Container';
import Input from '../../../../Shared/Input';
import moment from 'moment';
import EditPenIconUnderline from '../../../../Shared/assets/edit-pen-underline.svg';
import SurveysModal from "../../modals/SurveysModal";
import AnthropometryModal from "../../modals/AnthropometryModal";
import DeleteModal from "../../modals/DeleteModal";

const SurveysTabView = ({
  setSurveysModal,
  patientSurveys,
  updatePatientSurveysHandler,
  deleteModalHandler,
  surveysModal,
  deleteModal,
  setDeleteModal
}) => {
  const { environmentalSurvey, pediatricSurvey, perinatalSurvey, familySurvey, anthropometry } = patientSurveys;
  return (
    <Wrapper>
      <SurveysModal
        modalState={surveysModal}
        setModalState={setSurveysModal}
        patientSurveys={patientSurveys}
        updatePatientSurveysHandler={updatePatientSurveysHandler}
        setSurveysModal={setSurveysModal}
      />
      <AnthropometryModal
        modalState={surveysModal}
        setModalState={setSurveysModal}
        patientSurveys={patientSurveys}
        updatePatientSurveysHandler={updatePatientSurveysHandler}
        deleteModalHandler={deleteModalHandler}
      />
      <DeleteModal
        modalState={deleteModal.shown}
        setModalState={setDeleteModal}
        onAccept={deleteModal.onAccept}
        question={deleteModal.question}
      />
      <Section>
        <StyledContainer
          actionButton='edit'
          actionButtonOnClick={() => setSurveysModal({ shown: 'surveys', editId: false })}
          actionButtonTitle='Edytuj wywiad'
          title="Wywiad pediatryczny">
          <Top>
            <List>
              <ListItem>
                <Label>Wynik w skali Apgar</Label>
                <Value>{pediatricSurvey?.apgarScore ?? 'Brak'}</Value>
              </ListItem>
              <ListItem>
                <Label>Masa urodzeniowa</Label>
                <Value>{pediatricSurvey?.birthMass ? `${pediatricSurvey?.birthMass}g` : 'Brak'}</Value>
              </ListItem>
              <ListItem>
                <Label>Długość ciała</Label>
                <Value>{pediatricSurvey?.birthLength ? `${pediatricSurvey?.birthLength}cm` : 'Brak'}</Value>
              </ListItem>
              <ListItem>
                <Label>Obwód głowy</Label>
                <Value>{pediatricSurvey?.birthHeadCircuit ? `${pediatricSurvey?.birthHeadCircuit}cm` : 'Brak'}</Value>
              </ListItem>
            </List>
          </Top>
          <Bot>
            <List>
              <CheckboxListItem>
                <Input
                  type="checkbox"
                  checkboxText='Nietolerancja pokarmowa'
                  value={pediatricSurvey?.foodIntolerance ? true : false}
                  name='foodIntolerance'
                />
                {pediatricSurvey?.foodIntolerance && <CheckboxListItemText>
                  {pediatricSurvey?.foodIntolerance}
                </CheckboxListItemText>}
              </CheckboxListItem>
              <CheckboxListItem>
                <Input
                  type="checkbox"
                  checkboxText='Zaburzenia rozwojowe'
                  value={pediatricSurvey?.developmentalDisorders ? true : false}
                  name='developmentalDisorders'
                />
                {pediatricSurvey?.developmentalDisorders && <CheckboxListItemText>
                  {pediatricSurvey?.developmentalDisorders}
                </CheckboxListItemText>}
              </CheckboxListItem>
              <CheckboxListItem>
                <Input
                  type="checkbox"
                  checkboxText={`Karmienie`}
                  value={pediatricSurvey?.feeding ? true : false}
                  name='feeding'
                />
                {pediatricSurvey?.feeding && <CheckboxListItemText>
                  {pediatricSurvey?.feeding}
                </CheckboxListItemText>}
              </CheckboxListItem>
              <CheckboxListItem>
                <Input
                  type="checkbox"
                  checkboxText='Inne'
                  value={pediatricSurvey?.additionalNotes ? true : false}
                  name='additionalNotes'
                />
                {pediatricSurvey?.additionalNotes && <CheckboxListItemText>
                  {pediatricSurvey?.additionalNotes}
                </CheckboxListItemText>}
              </CheckboxListItem>
            </List>
          </Bot>
        </StyledContainer>
        <StyledContainer
          actionButton='edit'
          actionButtonOnClick={() => setSurveysModal({ shown: 'surveys', editId: false })}
          title="Wywiad rodzinny">
          {familySurvey && familySurvey !== '' ?
            <SurveyText>{familySurvey}</SurveyText>
            :
            <NoItems>Brak</NoItems>
          }
        </StyledContainer>
      </Section>
      <Section>
        <StyledContainer
          actionButton='edit'
          actionButtonOnClick={() => setSurveysModal({ shown: 'surveys', editId: false })}
          title="Wywiad środowiskowy">
          <Input
            type="checkbox"
            checkboxText='Pacjent zakwalifikowany do objęcia opieką środowiskową'
            value={environmentalSurvey?.environmentalCare}
            name='environmentalCare'
          />
          <List>
            {environmentalSurvey?.environmentalCare && environmentalSurvey?.careEndDate && <ListItem>
              <Label>Opieka do</Label>
              <Value>{moment(new Date(environmentalSurvey?.careEndDate)).format('DD.MM.YYYY')}</Value>
            </ListItem>}
            {environmentalSurvey?.environmentalCare && environmentalSurvey?.barthelScore && <ListItem>
              <Label>Wynik skali Barthel</Label>
              <Value>{environmentalSurvey?.barthelScore}</Value>
            </ListItem>}
            {environmentalSurvey?.environmentalCare && environmentalSurvey?.additionalNotes && <ListItem>
              <Label>Dodatkowe informacje</Label>
              <Value>{environmentalSurvey?.additionalNotes}</Value>
            </ListItem>}
          </List>
        </StyledContainer>
        <StyledContainer
          actionButton='edit'
          actionButtonOnClick={() => setSurveysModal({ shown: 'surveys', editId: false })}
          title="Wywiad okołoporodowy">
          {perinatalSurvey && perinatalSurvey !== '' ?
            <SurveyText>{perinatalSurvey}</SurveyText>
            :
            <NoItems>Brak</NoItems>
          }
        </StyledContainer>
        <StyledContainer
          actionButton='add'
          actionButtonOnClick={() => setSurveysModal({ shown: 'anthropometry', editId: false })}
          actionButtonTitle='Dodaj antropometrie'
          title="Antropometria">
          {anthropometry?.length > 0 ?
            <Antropometria>
              <AntropometriaHeader>
                <p>Data</p>
                <p>Wzorst</p>
                <p>Waga</p>
                <p>Ciśnienie</p>
                <p>Tętno</p>
              </AntropometriaHeader>
              {anthropometry.map(({ _id, date, weight, height, pulse, pressure }) => (
                <AntropometriaItem key={_id}>
                  <EditPenUnderline src={EditPenIconUnderline} onClick={() => setSurveysModal({ shown: 'anthropometry', editId: _id })} />
                  <p>{moment(new Date(date)).format('DD.MM.YYYY')}</p>
                  <p>{height} cm</p>
                  <p>{weight} kg</p>
                  <p>{pressure}</p>
                  <p>{pulse} BPM</p>
                </AntropometriaItem>
              ))}
            </Antropometria>
            :
            <NoItems>Brak</NoItems>
          }
        </StyledContainer>
      </Section>
    </Wrapper>
  )
}

export default SurveysTabView;

const StyledContainer = styled(Container)`
  position:relative;
`;

const Section = styled.div``;

const Wrapper = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  width:100%;
`;

const Top = styled.div`
  margin: 0 -20px;
  padding: 0 20px 20px;
  border-bottom:1px solid ${({ theme }) => theme.grey};
`;

const Bot = styled.div`
  padding:20px 0;
`;

const List = styled.ul`
  margin:0;
`;

const ListItem = styled.li`
  display:flex;
  align-items:center;
  font-size:14px;
  padding:5px 0;
`;

const CheckboxListItem = styled.li`
  display:flex;
  position:relative;
  align-items:center;
  label{
    width:200px;
  }
`;

const Label = styled.p`
  width:150px;
  font-weight:600;
`;

const Value = styled.p``;

const CheckboxListItemText = styled.div`
  margin:30px 0 0 5px;
  font-size:14px;
  color:#aaa;
`;

const SurveyText = styled.p`
  font-size:14px;
  margin:10px 0;
`;

const NoItems = styled.p`
  font-size:14px;
  margin:10px 0;
  ${({ center }) => center && 'text-align:center;'}
`;

const Antropometria = styled.div`
  margin: 0 -10px;
  max-height:175px;
  overflow-y:auto;
`;

const AntropometriaHeader = styled.div`
  display:flex;
  justify-content:space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grey};
  padding:5px 20px 5px 15px;
  >p{
    width:75px;
    font-weight:600;
    font-size:14px;
    text-align:center;
  }
`;

const AntropometriaItem = styled.div`
  position:relative;
  padding:5px 20px 5px 15px;
  display:flex;
  justify-content:space-between;
  &:not(:last-child){
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
  >p{
    width:75px;
    font-size:14px;
    text-align:center;
  }
  &:hover{
    background-color:#f9f9f9;
  }
`;

const EditPenUnderline = styled.img`
  transition:300ms;
  position:absolute;
  top:5px;
  right:5px;
  width: 17.5px;
  height: 17.5px;
  cursor: pointer;
  filter: invert(81%) sepia(0%) saturate(51%) hue-rotate(195deg) brightness(86%) contrast(81%);
  &:hover {
    filter: invert(98%) sepia(0%) saturate(476%) hue-rotate(144deg) brightness(89%) contrast(81%);
  }
`;