import React from 'react';
import styled from 'styled-components';
import Container from "../Shared/Container";
import StatusPill from '../Shared/StatusPill';
import EditPenIconUnderline from '../Shared/assets/edit-pen-underline.svg';
import Button from '../Shared/Button';

const HealthcentersList = ({ healthcenters,selectedHealthcenter, EditPenUnderline, editHealthcenterHandler, setHealthcentersModalState, healthcenterChangeHandler }) => {

  return (
    <StyledContainer healthCenter title="Placówki">
      <HealthcentersListContainer colsTemplate="1fr 1fr 1fr 1fr">
        <HealthcentersListHeader>
          <HealthcentersListHeaderItem>
            Nazwa
                  </HealthcentersListHeaderItem>
          <HealthcentersListHeaderItem>
            Adres
                  </HealthcentersListHeaderItem>
          <HealthcentersListHeaderItem>
            Status
                  </HealthcentersListHeaderItem>
          <HealthcentersListHeaderItem>
            Akcje
                  </HealthcentersListHeaderItem>
        </HealthcentersListHeader>
        {
          healthcenters && healthcenters.map(({ _id, name, address: { City, Street, houseNumber }, isActive }) => {
            return (
              <HealthcentersListItem isSelected={selectedHealthcenter?._id === _id ? true : false} key={_id} onClick={() => healthcenterChangeHandler(_id)}>
                <HealthcentersListCol>
                  <Text>
                    {name}
                  </Text>
                </HealthcentersListCol>
                <HealthcentersListCol>
                  <Text>
                    {`${Street} ${houseNumber}, ${City}`}
                  </Text>
                </HealthcentersListCol>
                <HealthcentersListCol>
                  {isActive ?
                    <StatusPill type="positive">Aktywny</StatusPill>
                    :
                    <StatusPill type="negative">Zablokowany</StatusPill>
                  }
                </HealthcentersListCol>
                <HealthcentersListCol>
                  <EditPenUnderline src={EditPenIconUnderline} onClick={() => editHealthcenterHandler(_id)} />
                </HealthcentersListCol>
              </HealthcentersListItem>
            )
          })
        }
      </HealthcentersListContainer>
      <StyledButton onClick={() => setHealthcentersModalState({ edit: false, shown: true })}>Dodaj...</StyledButton>
    </StyledContainer>
  )
}

export default HealthcentersList;

const Text = styled.p`
  font-size:${({ theme }) => theme.font.size.xxxs};
  ${({ bold }) => bold && 'font-weight:600'};
`
const StyledContainer = styled(Container)`
position:relative;
`;

const HealthcentersListContainer = styled.ul`
  ${({ colsTemplate }) => colsTemplate && `grid-template-columns: ${colsTemplate}`}
`;

const HealthcentersListHeader = styled.li`
  display:grid;
  grid-template-columns:inherit;
  align-items: center;
`

const HealthcentersListHeaderItem = styled.p`
  font-weight:600;
  text-align:center;
`

const HealthcentersListItem = styled.li`
  display:grid;
  grid-template-columns:inherit;
  align-items: center;
  padding:7.5px 5px;
  border-bottom:1px solid ${({ theme }) => theme.grey};
  transition:300ms;
  cursor:pointer;
  &:not(:first-child) > div:first-child{
    text-align:left;
  }
  &:hover{
    background-color: ${({ theme }) => theme.background};
    border-radius: 4px;
  }
  ${({isSelected}) => isSelected && `
  background-color: #e2eeff!important;
    border-radius: 4px;
  `}
`;

const HealthcentersListCol = styled.div`
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display:inline-block;
  }
`;

const StyledButton = styled(Button)`
    margin-left: auto;
    margin-right: 0;
    display: block;
`;