import React from "react";
import styled from "styled-components";
import UnderConstructionImg from "../../Shared/assets/under-construction.svg";

const AdminProfileView = () => {
  return (
    <Wrapper>
    <UnderConstruction src={UnderConstructionImg} />
    <p>Podstrona w trakcie budowy, zapraszamy w przyszłości!</p>
  </Wrapper>
  );
};

export default AdminProfileView;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const UnderConstruction = styled.img`
  max-width: 400px;
  margin-bottom: 25px;
`;
