import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Input from '../../../Shared/Input';
import Button from '../../../Shared/Button';
import Select from '../../../Shared/Select';
import { Formik } from 'formik';
import DatePicker from '../../../Shared/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';

import AddIcon from '../../../Shared/assets/add-plus.svg';

const ExaminationModal = ({
    modalState: { shown: modalShown, editId: modalEditId },
    setModalState,
    examinationFactors,
    addExaminationHandler,
    updateExaminationHandler,
    deleteModalHandler,
    examinationPatterns,
    patientExaminations,
    factorsSearchByNameHandler,
    factorsLoading,
    deleteExaminationPattern,
    nextTutorialStep
}) => {
    const initalValuesSchema = {
        examinationDate: Date.now(),
        patternName: "",
        currentPattern: null,
    };
    const addFactorHandler = () => {
        setFactors(prev => (
            [...prev, generateId()]
        ));
    },
        removeFactorHandler = (id) => {
            setFactors(prev => (prev.filter(el => el !== id)))
        },
        loadPatternHandler = (patternId) => {
            if (patternId !== 'createNew' && patternId) {
                const { factors } = examinationPatterns.find(el => el._id === patternId),
                    values = {};
                for (let i = 0; i < factors.length; i++) {
                    values[factors[i]._id] = { ...factors[i] };
                    values[factors[i]._id].factor = { value: values[factors[i]._id].factor._id, label: values[factors[i]._id].factor.name };
                }
                setFactors(factors.map(({ _id }) => (_id)));
                setInitialValues((prev) => ({ ...prev, factors: values, currentPattern: patternId }));
            } else if (!patternId) {
                setFactors([generateId()]);
                setInitialValues(initalValuesSchema);
            }
        },
        resetModalHandler = () => {
            setFactors([generateId()]);
            setInitialValues(initalValuesSchema);
        },
        generateId = () => {
            return Math.random().toString(36).substr(2, 9);
        };

    const [factors, setFactors] = useState([generateId()]),
        [initalValues, setInitialValues] = useState(initalValuesSchema);
    const factorOptions = examinationFactors.map(({ _id, name }) => ({ value: _id, label: name })),
        unitOptions = [
            { value: 'liczba', label: 'liczba' },
            { value: `liczba^n/l`, label: `liczba^n/l` },
            { value: 'liczba^n/ml', label: 'liczba^n/ml' },
            { value: 'sek', label: 'sek' },
            { value: 'promil', label: 'promil' },
            { value: 'g', label: 'g' },
            { value: 'ng', label: 'ng' },
            { value: 'pg', label: 'pg' },
            { value: 'fg', label: 'fg' },
            { value: 'ml', label: 'ml' },
            { value: 'fl', label: 'fl' },
            { value: 'mm/h', label: 'mm/h' },
            { value: 'g/g', label: 'g/g' },
            { value: 'g/dl', label: 'g/dl' },
            { value: 'g/l', label: 'g/l' },
            { value: 'mg/l', label: 'mg/l' },
            { value: 'mg/dl', label: 'mg/dl' },
            { value: 'mg/ml', label: 'mg/ml' },
            { value: 'µg/l', label: 'µg/l' },
            { value: 'µg/dl', label: 'µg/dl' },
            { value: 'µg/ml', label: 'µg/ml' },
            { value: 'ng/l', label: 'ng/l' },
            { value: 'ng/dl', label: 'ng/dl' },
            { value: 'ng/ml', label: 'ng/ml' },
            { value: 'mol/l', label: 'mol/l' },
            { value: 'mmol/l', label: 'mmol/l' },
            { value: 'mmol/dl', label: 'mmol/dl' },
            { value: 'mmol/ml', label: 'mmol/ml' },
            { value: 'nmol/l', label: 'nmol/l' },
            { value: 'pmol/l', label: 'pmol/l' },
            { value: 'µg/24h', label: 'µg/24h' },
            { value: 'mmol/24h', label: 'mmol/24h' },
            { value: 'µmol/24h', label: 'µmol/24h' },
            { value: 'µmol/mmol', label: 'µmol/mmol' },
            { value: 'U/l', label: 'U/l' },
            { value: 'mU/l', label: 'mU/l' },
            { value: 'U/ml', label: 'U/ml' },
        ],
        patternOptions = [{ label: 'Utwórz nowy szabkon', value: 'createNew' }, ...examinationPatterns.map(({ _id, name }) => ({ value: _id, label: name }))];

    const factorsSchema = {};
    for (const factor of factors) {
        factorsSchema[factor] = Yup.object().shape({
            factor: Yup.string().required('Czynnik jest wymagany'),
            unit: Yup.string().required('Jednostka jest wymagana'),
            lowerRange: Yup.number().typeError('Minimum musi być numerem').required('Minimum jest wymagany'),
            upperRange: Yup.number().typeError('Maksimum musi być numerem').required('Maksimum jest wymagana'),
            quantity: Yup.number().typeError('Wynik musi być numerem').required('Wynik jest wymagany'),
        })
    };
    const validationSchema = Yup.object().shape({
        examinationDate: Yup.string().required(),
        patternName: Yup.string(),
        factors: Yup.object().shape(factorsSchema)
    });
    useEffect(() => {
        if (modalEditId) {
            const { factors, examinationDate } = patientExaminations.find(el => el._id === modalEditId),
                values = {};
            for (let i = 0; i < factors.length; i++) {
                values[factors[i]._id] = { ...factors[i] };
                values[factors[i]._id].factor = { value: values[factors[i]._id].factor._id, label: values[factors[i]._id].factor.name };
            }
            setFactors(factors.map(({ _id }) => (_id)));
            setInitialValues({ factors: values, examinationDate: new Date(examinationDate) });
        }
    }, [modalEditId]);

    return (
        <Modal
            title={modalEditId ? 'Edycja badania' : 'Dodawanie badania'}
            exitButton={true}
            onCancel={() => {
                setModalState({ shown: false, editId: null, });
                resetModalHandler();
                if (nextTutorialStep) {
                    nextTutorialStep();
                }
            }}
            show={modalShown}
        >
            <Formik
                validationSchema={validationSchema}
                initialValues={initalValues}
                enableReinitialize
                onSubmit={({ examinationDate, patternName, currentPattern, factors }) => {
                    const mapedFactors = Object.values(factors).filter(fac => fac.quantity).map(el => ({ ...el, factor: Object.values(el.factor)[0] }));
                    if (modalEditId) {
                        updateExaminationHandler(examinationDate, mapedFactors);
                    } else {
                        addExaminationHandler(examinationDate, currentPattern, patternName, mapedFactors);
                    }
                    resetModalHandler();
                    setModalState({ shown: false, editId: null });
                    if (nextTutorialStep) {
                        nextTutorialStep();
                    }
                }}
            >
                {({ handleSubmit, values, setFieldValue, handleChange, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <InputRow>
                            <DatePicker
                                label="Data badania"
                                showTimeSelect
                                timeFormat="HH:mm"
                                dateFormat="dd.MM.yyyy HH:mm"
                                selected={values.examinationDate}
                                timeCaption="Godzina"
                                onChange={(value) => setFieldValue('examinationDate', value)}
                            />
                            {!modalEditId && <StyledSelect
                                placeholder="Wybierz szablon szablon"
                                onChange={(val) => {
                                    loadPatternHandler(val?.value);
                                    setFieldValue('currentPattern', val?.value);
                                }}
                                options={patternOptions}
                                value={patternOptions.find(({ value }) => value === values.currentPattern) ?? null}
                                name='pattern'
                                label='Szablon'
                                isClearable={true}
                            />}
                            {values.currentPattern === 'createNew' && <Input
                                type="text"
                                label="Nazwa nowego szablonu"
                                name='patternName'
                                onChange={handleChange}
                                value={values.patternName}
                            />}
                        </InputRow>
                        <Factors>
                            {factors.map((factorId, index) => (
                                <Factor key={factorId}>
                                    <StyledSelect
                                        placeholder="Wybierz czynnik"
                                        onChange={(value) => setFieldValue(`factors.${factorId}.factor`, value)}
                                        onInputChange={factorsSearchByNameHandler}
                                        options={factorOptions}
                                        noOptionsMessage={() => 'Nie znaleziono czynnika'}
                                        name={`factor_${factorId}`}
                                        isLoading={factorsLoading}
                                        value={values?.factors?.[factorId]?.factor}
                                        label="Czynnik"
                                        error={errors?.factors?.[factorId]?.factor}
                                    />
                                    <StyledSelect
                                        placeholder="Wybierz jednostkę"
                                        name={`unit_${factorId}`}
                                        label="Jednostka"
                                        noOptionsMessage={() => 'Nie znaleziono jednostki'}
                                        onChange={({ value }) => setFieldValue(`factors.${factorId}.unit`, value)}
                                        value={unitOptions.find(el => el.value === values?.factors?.[factorId]?.unit)}
                                        options={unitOptions}
                                        error={errors?.factors?.[factorId]?.unit}
                                    />
                                    <Input
                                        type="text"
                                        name={`lowerRange_${factorId}`}
                                        value={values?.factors?.[factorId]?.lowerRange}
                                        label="Minimum"
                                        onChange={({ target: { value } }) => setFieldValue(`factors.${factorId}.lowerRange`, value)}
                                        error={errors?.factors?.[factorId]?.lowerRange}
                                    />
                                    <Input
                                        type="text"
                                        name={`upperRange_${factorId}`}
                                        value={values?.factors?.[factorId]?.upperRange}
                                        label="Maksimum"
                                        onChange={({ target: { value } }) => setFieldValue(`factors.${factorId}.upperRange`, value)}
                                        error={errors?.factors?.[factorId]?.upperRange}
                                    />
                                    <Input
                                        type="text"
                                        name={`quantity_${factorId}`}
                                        label="Wynik"
                                        value={values?.factors?.[factorId]?.quantity}
                                        onChange={({ target: { value } }) => setFieldValue(`factors.${factorId}.quantity`, value)}
                                        error={errors?.factors?.[factorId]?.quantity}
                                    />
                                    <Actions>
                                        {factors.length > 1 && <RemoveFactorBtn title="Usuń czynnik" onClick={() => { removeFactorHandler(factorId); setFieldValue(`factors.${factorId}`, {}) }} />}
                                        {factors.length - 1 === index && <AddFactorBtn title="Dodaj czynnik" onClick={addFactorHandler} type='button' />}
                                    </Actions>
                                </Factor>
                            ))}
                        </Factors>
                        <CenterBox>
                            <Button onClick={handleSubmit} type='submit' variant='primary'>{modalEditId ? 'Zapisz zmiany' : values.currentPattern === 'createNew' ? 'Dodaj badanie i zapisz szablon' : 'Dodaj badanie'}</Button>
                            {modalEditId && <Button onClick={() => { deleteModalHandler('examination'); }} type='button' variant='danger'>Usuń badanie</Button>}
                            {(values.currentPattern !== null && values.currentPattern !== 'createNew' && !modalEditId) && <Button
                                onClick={() => {
                                    deleteModalHandler('examinationPattern', values.currentPattern, () => setFieldValue('currentPattern', null));
                                }}
                                type='button'
                                variant='danger'
                            >Usuń szablon</Button>}
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal >
    )
}

export default ExaminationModal;

const Form = styled.form`
    width:650px;
    padding-top:10px;
    ${({ theme }) => `${theme.mq.desktop}{
        width:750px;
    }`}
    &>div:first-child{
        max-width:225px;
    }
`;

const CenterBox = styled.div`
    margin-top:40px;
    text-align:center;
`;

const InputRow = styled.div`
    display:grid;
    gap:10px;
    grid-template-columns: 215px 215px 215px;
`

const Factors = styled.ul`
    position: relative;
    max-height: calc(90vh - 200px - 2rem);
    margin: 0;
`;

const StyledSelect = styled(Select)`
    >div{
        margin:2px 0 0;
    }
`

const Factor = styled.li`
    position:relative;
    padding:10px 0 0;
    display:grid;
    grid-template-columns:215px 102.5px 85.5px 85.5px 85.5px 65px;
    gap:10px;
    &:not(:last-child){
        border-bottom:1px solid ${({ theme }) => theme.grey};
    }
    ${({ theme }) => `${theme.mq.desktop}{
        grid-template-columns:215px 102.5px 102.5px 102.5px 102.5px 65px;
    }`}
`;

const Actions = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
`

const AddFactorBtn = styled.button`
    background-color:transparent;
    height:30px;
    width:30px;
    border: 2px solid ${({ theme }) => theme.primary};
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    padding:0;
    margin:0;
    z-index:2;
    cursor: pointer;
    &::before{
        display:block;
        content: url(${AddIcon});
        filter: invert(42%) sepia(91%) saturate(2161%) hue-rotate(190deg) brightness(101%) contrast(101%);
        width: 15px;
        height: 15px;
    }
`;

const RemoveFactorBtn = styled.button`
    background-color:transparent;
    height:30px;
    width:30px;
    border: 2px solid ${({ theme }) => theme.danger};
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    padding:0;
    margin:0;
    z-index:2;
    cursor: pointer;
    &::before{
        display:block;
        content: url(${AddIcon});
        filter: invert(54%) sepia(63%) saturate(606%) hue-rotate(312deg) brightness(76%) contrast(106%);
        transform:rotate(45deg);
        width: 15px;
        height: 15px;
    }
`;