import axios from 'axios';

const GET_DOCTORS_FORM_CONTROLS_REQUEST = "GET_DOCTORS_FORM_CONTROLS_REQUEST";
const GET_DOCTORS_FORM_CONTROLS_SUCCESS = "GET_DOCTORS_FORM_CONTROLS_SUCCESS";
const GET_DOCTORS_FORM_CONTROLS_FAIL = "GET_DOCTORS_FORM_CONTROLS_FAIL";

const ADD_DOCTORS_FORM_CONTROLS_REQUEST = "ADD_DOCTORS_FORM_CONTROLS_REQUEST";
const ADD_DOCTORS_FORM_CONTROLS_SUCCESS = "ADD_DOCTORS_FORM_CONTROLS_SUCCESS";
const ADD_DOCTORS_FORM_CONTROLS_FAIL = "ADD_DOCTORS_FORM_CONTROLS_FAIL";

const DELETE_DOCTORS_FORM_CONTROLS_REQUEST = "DELETE_DOCTORS_FORM_CONTROLS_REQUEST";
const DELETE_DOCTORS_FORM_CONTROLS_SUCCESS = "DELETE_DOCTORS_FORM_CONTROLS_SUCCESS";
const DELETE_DOCTORS_FORM_CONTROLS_FAIL = "DELETE_DOCTORS_FORM_CONTROLS_FAIL";

export const getDoctorsFormControls = (doctorId) => async dispatch => {
    dispatch(getDoctorsFormControlsRequest())
    try {
        const response = await axios.get(`/pattern-form-control/doctor/${doctorId}`);
        dispatch(getDoctorsFormControlsSuccess(response.data))
    } catch (error) {
        dispatch(getDoctorsFormControlsFail(error))
    }
}

const getDoctorsFormControlsRequest = () => ({
    type: GET_DOCTORS_FORM_CONTROLS_REQUEST
});

const getDoctorsFormControlsSuccess = user => ({
    type: GET_DOCTORS_FORM_CONTROLS_SUCCESS,
    payload: user
});

const getDoctorsFormControlsFail = (error) => ({
    type: GET_DOCTORS_FORM_CONTROLS_FAIL,
    payload: error
});

export const addDoctorsFormControls = (newFormControl) => async dispatch => {
    dispatch(addDoctorsFormControlsRequest())
    try {
        const response = await axios.post(`/pattern-form-control/`, newFormControl);
        dispatch(addDoctorsFormControlsSuccess(response.data))
    } catch (error) {
        dispatch(addDoctorsFormControlsFail(error))
    }
}

const addDoctorsFormControlsRequest = () => ({
    type: ADD_DOCTORS_FORM_CONTROLS_REQUEST
});

const addDoctorsFormControlsSuccess = formControl => ({
    type: ADD_DOCTORS_FORM_CONTROLS_SUCCESS,
    payload: formControl
});

const addDoctorsFormControlsFail = (error) => ({
    type: ADD_DOCTORS_FORM_CONTROLS_FAIL,
    payload: error
});

export const deleteDoctorsFormControls = (formControlId) => async dispatch => {
    dispatch(deleteDoctorsFormControlsRequest())
    try {
        const response = await axios.delete(`/pattern-form-control/${formControlId}`);
        dispatch(deleteDoctorsFormControlsSuccess(formControlId))
    } catch (error) {
        dispatch(deleteDoctorsFormControlsFail(error))
    }
}

const deleteDoctorsFormControlsRequest = () => ({
    type: DELETE_DOCTORS_FORM_CONTROLS_REQUEST
});

const deleteDoctorsFormControlsSuccess = formControlId => ({
    type: DELETE_DOCTORS_FORM_CONTROLS_SUCCESS,
    payload: formControlId
});

const deleteDoctorsFormControlsFail = (error) => ({
    type: DELETE_DOCTORS_FORM_CONTROLS_FAIL,
    payload: error
});

const initialState = {
    doctorsFormControls: [],
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCTORS_FORM_CONTROLS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_DOCTORS_FORM_CONTROLS_SUCCESS:
            return {
                ...state,
                doctorsFormControls: action.payload,
                loading: false
            }
        case GET_DOCTORS_FORM_CONTROLS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ADD_DOCTORS_FORM_CONTROLS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_DOCTORS_FORM_CONTROLS_SUCCESS:
            return {
                ...state,
                doctorsFormControls: [...state.doctorsFormControls, action.payload],
                loading: false
            }
        case ADD_DOCTORS_FORM_CONTROLS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_DOCTORS_FORM_CONTROLS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_DOCTORS_FORM_CONTROLS_SUCCESS:
            return {
                ...state,
                doctorsFormControls: state.doctorsFormControls.filter(el => el._id !== action.payload),
                loading: false
            }
        case DELETE_DOCTORS_FORM_CONTROLS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};