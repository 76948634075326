import React from "react";
import styled from "styled-components";
import plannedIcon from "../assets/planned-icon.svg";
import confirmedIcon from "../assets/confirmed-icon.svg";
import rejectedIcon from "../assets/rejected-icon.svg";
import waitingIcon from "../assets/waiting-icon.svg";
import endedIcon from "../assets/ended-icon.svg";
import startedIcon from "../assets/started-icon.svg";
import Tooltip from "../Tooltip";

const CustomEvent = ({ event }) => {
  const { patient } = event;
  const visitStatePolishDict = (visitState) => {
    switch (visitState) {
      case "PLANNED":
        return "Zaplanowana";
      case "CONFIRMED":
        return "Potwierdzona";
      case "WAITING":
        return "W poczekalni";
      case "STARTED":
        return "W trakcie";
      case "ENDED":
        return "Zakończona";
      case "REJECTED":
        return "Odwołana";
    }
  };
  return (
    <MainWrapper>
      {patient && event.patient ? (
        <Tooltip
          name={event._id}
          content={`${visitStatePolishDict(
            event.state
          )}, ${event.start.getHours()}:${
            event.start.getMinutes() === 0
              ? event.start.getMinutes() + "0"
              : event.start.getMinutes()
            } - ${event.end.getHours()}:${event.end.getMinutes() === 0
              ? event.end.getMinutes() + "0"
              : event.end.getMinutes()}, ${
            event.patient.name
            }, ${event.patient.tel}`}
        >
          <EventWrapper>
            <EventPatientName>{patient.name} {patient?.surname}, </EventPatientName>
            <EventPatientTel>{patient.tel} </EventPatientTel>
            <IconWrapper>
              <EventStatusIcon
                src={
                  event.state === "PLANNED"
                    ? plannedIcon
                    : event.state === "CONFIRMED"
                      ? confirmedIcon
                      : event.state === "REJECTED"
                        ? rejectedIcon
                        : event.state === "WAITING"
                          ? waitingIcon
                          : event.state === "ENDED"
                            ? endedIcon
                            : event.state === "STARTED"
                              ? startedIcon
                              : null
                }
                state={event.state}
              />
            </IconWrapper>
          </EventWrapper>
        </Tooltip>
      ) : (
          <>
            <EventPatientName>{event.title}</EventPatientName>
          </>
        )}
    </MainWrapper>
  );
};

export default CustomEvent;
const MainWrapper = styled.div`
height: 100%;
width: 100%;
`;

const EventWrapper = styled.div`
  height: 100%;
  width: 100%;
  
`;

const EventPatientName = styled.span`
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`;

const EventPatientTel = styled.span`
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`;

const EventStatusIcon = styled.img`
  width: 13px;
  height: 13px;
  ${({ state }) =>
    state === "PLANNED"
      ? `
  filter: invert(40%) sepia(52%) saturate(1686%) hue-rotate(186deg) brightness(104%) contrast(101%);`
      : state === "CONFIRMED"
        ? `
      filter: invert(61%) sepia(52%) saturate(471%) hue-rotate(70deg) brightness(96%) contrast(101%);
`
        : state === "STARTED"
          ? `
filter: invert(31%) sepia(93%) saturate(2126%) hue-rotate(221deg) brightness(100%) contrast(109%);
`
          : state === "ENDED"
            ? `
filter: invert(57%) sepia(48%) saturate(447%) hue-rotate(104deg) brightness(92%) contrast(84%);
`
            : state === "REJECTED"
              ? `
filter: invert(55%) sepia(41%) saturate(1743%) hue-rotate(318deg) brightness(79%) contrast(100%);
`
              : state === "WAITING"
                ? `filter: invert(63%) sepia(92%) saturate(527%) hue-rotate(329deg) brightness(103%) contrast(101%);
`
                : ``}
`;

const IconWrapper = styled.div`
  width: 23px;
  height: 23px;
  background-color: #ffffffa1;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popout = styled.div`
  background-color: #000000;
  position: absolute;
  top: 0;
`;
