import React from 'react';
import styled from 'styled-components';
import Container from '../../../../Shared/Container';
import moment from 'moment';
import MedsDiagnosisModal from "../../modals/MedsDiagnosisModal";

const MedsDiagnosisTabView = ({ setMedsDiagnosisModal, patientMedsDiagnosis: { constantDiagnosis, constantMeds, otherMeds, otherDiagnosis }, meds, diagnosis, medsdiagnosisLoading, getMedicalData, patientMedsDiagnosis, updatePatientMedsDiagnosisHandler, medsDiagnosisModal, nextTutorialStep }) => {
  return (
    <Wrapper>
      <MedsDiagnosisModal
        modalState={medsDiagnosisModal}
        setModalState={setMedsDiagnosisModal}
        getMedicalData={getMedicalData}
        diagnosis={diagnosis}
        meds={meds}
        patientMedsDiagnosis={patientMedsDiagnosis}
        updatePatientMedsDiagnosisHandler={updatePatientMedsDiagnosisHandler}
        medsdiagnosisLoading={medsdiagnosisLoading}
        nextTutorialStep={nextTutorialStep}
      />
      <StyledContainer
        title="Rozpoznania"
        actionButton='edit'
        actionButtonTitle='Edytuj rozpoznania'
        actionButtonOnClick={() => {
          setMedsDiagnosisModal({ shown: true, target: 'icd10s' })
          if (nextTutorialStep) {
            nextTutorialStep();
          }
        }}
      >
        <List title="Przewlekłe">
          {constantDiagnosis && constantDiagnosis.length > 0 ?
            constantDiagnosis.map(({ subcategory, subcategoryCode, _id }, index) => (
              <ListItem key={`${_id}_${index}`}>
                <Left>
                  <Code>{subcategoryCode}</Code>
                  <Name>{subcategory}</Name>
                </Left>
              </ListItem>
            ))
            :
            <NoItems>
              Brak
              </NoItems>
          }
        </List>
        <List title="Pozostałe">
          {otherDiagnosis && otherDiagnosis?.length > 0 ?
            otherDiagnosis.map(({ subcategory, subcategoryCode, diagnosisDate, _id }, index) => (
              <ListItem key={`${_id}_${index}`} flexWrap>
                <Left>
                  <Code>{subcategoryCode}</Code>
                  <Name>{subcategory}</Name>
                </Left>
                <Dates>
                  <span>
                    Od: <b>{moment(diagnosisDate).format('DD.MM.YYYY')}</b>
                  </span>
                </Dates>
              </ListItem>
            ))
            :
            <NoItems>
              Brak
              </NoItems>
          }
        </List>
      </StyledContainer>
      <StyledContainer
        title="Leki"
        actionButton='edit'
        actionButtonTitle='Edytuj leki'
        actionButtonOnClick={() => setMedsDiagnosisModal({ shown: true, target: 'medicalProducts' })}
      >
        <List title="Stale przyjmowane">
          {constantMeds && constantMeds.length > 0 ?
            constantMeds.map(({ nazwaProduktu, nazwaPowszechnieStosowana, moc, wielkosc, jednostkaWielkosci, _id, dataRozpoczeciaPrzyjmowania }, index) => (
              <ListItem key={`${_id}_${index}`} flexWrap>
                <Name>{`${nazwaProduktu}, ${nazwaPowszechnieStosowana}, ${moc}, ${wielkosc} ${jednostkaWielkosci}`}</Name>
                <Dates>
                  <span>
                    Od: <b>{moment(dataRozpoczeciaPrzyjmowania).format('DD.MM.YYYY')}</b>
                  </span>
                </Dates>
              </ListItem>
            ))
            :
            <NoItems>
              Brak
              </NoItems>
          }
        </List>
        <List title="Pozostałe">
          {otherMeds && otherMeds.length > 0 ?
            otherMeds.map(({ nazwaProduktu, nazwaPowszechnieStosowana, moc, wielkosc, jednostkaWielkosci, dataWystawieniaRecepty, _id, dataKoncaPrzyjmowania }, index) => (
              <ListItem key={`${_id}_${index}`} flexWrap>
                <Name>{`${nazwaProduktu}, ${nazwaPowszechnieStosowana}, ${moc}, ${wielkosc} ${jednostkaWielkosci}`}</Name>
                <Dates>
                  <span>
                    Od: <b>{moment(dataWystawieniaRecepty).format('DD.MM.YYYY')}</b>
                  </span>
                  <span>
                    Do: <b>{moment(dataKoncaPrzyjmowania).format('DD.MM.YYYY')}</b>
                  </span>
                </Dates>
              </ListItem>
            ))
            :
            <NoItems>
              Brak
              </NoItems>
          }
        </List>
      </StyledContainer>
    </Wrapper>
  )
}

export default MedsDiagnosisTabView;

const StyledContainer = styled(Container)`
  position:relative;
  width:50%;
  height:calc(100vh - 290px);
`;

const Wrapper = styled.div`
  display:flex;
  justify-content:space-between;
`;

const List = styled.ul`
  max-height:48%;
  overflow: scroll;
  min-height:200px;
  margin:0;
  width:100%;
  &:not(:last-child){
    margin-bottom:10px;
  }
  ${({ title }) => (
    `&::before{
      content:'${title}';
      font-size:14px;
      font-weight:600;
    }`
  )}
`;

const ListItem = styled.li`
  padding: 5px;
  font-size:14px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  ${({ flexWrap }) => flexWrap && `
    flex-wrap:wrap;
  `}
  &:not(:last-child){
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const Left = styled.div`
  display:flex;
  align-items:center;
`;

const Name = styled.p`
  
`;
const Code = styled.p`
  font-weight:600;
  min-width:45px;
`;

const Date = styled.p`
 padding-left:5px;
`;

const NoItems = styled.p``;

const Dates = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    span{
      display:flex;
      align-items:center;
      b{
        font-weight:600;
        padding-left:4px;
      }
    }
    p{
      margin-left: 5px;
    }
`;