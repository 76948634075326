import React from 'react';
import { default as ReactSwitch } from 'react-switch';
import styled from 'styled-components';
import { theme } from './theme';
import Label from "./Label";

const Switch = ({ checked, onChange, label, height = 28, width = 56, className, disabled }) => {
    return (
        <SwitchContainer >
            {label &&
                <Label>{label}</Label>
            }
            <SwitchWrapper className={className} height={height} width={width}>
                <ReactSwitch
                    checked={checked}
                    onChange={onChange}
                    onColor={theme.green}
                    offColor={theme.danger}
                    height={height}
                    width={width}
                    checkedIcon={false}
                    disabled={disabled}
                    uncheckedIcon={false}
                />
            </SwitchWrapper>
        </SwitchContainer>
    )
}

export default Switch;

const SwitchContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const SwitchWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    margin: 2px 0;
    height: ${({height}) => `${height}px`};
    width: ${({width}) => `${width}px`};
`;