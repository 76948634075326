import axios from 'axios';

const ADD_MEDICAL_VISIT_PATTERN_REQUEST = "ADD_MEDICAL_VISIT_PATTERN_REQUEST";
const ADD_MEDICAL_VISIT_PATTERN_SUCCESS = "ADD_MEDICAL_VISIT_PATTERN_SUCCESS";
const ADD_MEDICAL_VISIT_PATTERN_FAIL = "ADD_MEDICAL_VISIT_PATTERN_FAIL";

const GET_DOCTORS_MEDICAL_VISIT_PATTERNS_REQUEST = "GET_DOCTORS_MEDICAL_VISIT_PATTERNS_REQUEST";
const GET_DOCTORS_MEDICAL_VISIT_PATTERNS_SUCCESS = "GET_DOCTORS_MEDICAL_VISIT_PATTERNS_SUCCESS";
const GET_DOCTORS_MEDICAL_VISIT_PATTERNS_FAIL = "GET_DOCTORS_MEDICAL_VISIT_PATTERNS_FAIL";

const UPDATE_MEDICAL_VISIT_PATTERN_REQUEST = "UPDATE_MEDICAL_VISIT_PATTERN_REQUEST";
const UPDATE_MEDICAL_VISIT_PATTERN_SUCCESS = "UPDATE_MEDICAL_VISIT_PATTERN_SUCCESS";
const UPDATE_MEDICAL_VISIT_PATTERN_FAIL = "UPDATE_MEDICAL_VISIT_PATTERN_FAIL";

const DELETE_MEDICAL_VISIT_PATTERN_REQUEST = "DELETE_MEDICAL_VISIT_PATTERN_REQUEST";
const DELETE_MEDICAL_VISIT_PATTERN_SUCCESS = "DELETE_MEDICAL_VISIT_PATTERN_SUCCESS";
const DELETE_MEDICAL_VISIT_PATTERN_FAIL = "DELETE_MEDICAL_VISIT_PATTERN_FAIL";

const IMPORT_MEDICAL_VISIT_PATTERN_REQUEST = "IMPORT_MEDICAL_VISIT_PATTERN_REQUEST";
const IMPORT_MEDICAL_VISIT_PATTERN_SUCCESS = "IMPORT_MEDICAL_VISIT_PATTERN_SUCCESS";
const IMPORT_MEDICAL_VISIT_PATTERN_FAIL = "IMPORT_MEDICAL_VISIT_PATTERN_FAIL";

const EXPORT_MEDICAL_VISIT_PATTERN_REQUEST = "EXPORT_MEDICAL_VISIT_PATTERN_REQUEST";
const EXPORT_MEDICAL_VISIT_PATTERN_SUCCESS = "EXPORT_MEDICAL_VISIT_PATTERN_SUCCESS";
const EXPORT_MEDICAL_VISIT_PATTERN_FAIL = "EXPORT_MEDICAL_VISIT_PATTERN_FAIL";

export const addMedicalVisitPattern = (newPattern) => async dispatch => {
    dispatch(addMedicalVisitPatternRequest())
    try {
        const response = await axios.post(`/medvisitpattern/`, newPattern);
        const { data } = response;
        dispatch(addMedicalVisitPatternSuccess(data))
    } catch (error) {
        dispatch(addMedicalVisitPatternFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const addMedicalVisitPatternRequest = () => ({
    type: ADD_MEDICAL_VISIT_PATTERN_REQUEST,
});

const addMedicalVisitPatternSuccess = (pattern) => ({
    type: ADD_MEDICAL_VISIT_PATTERN_SUCCESS,
    payload: pattern
});

const addMedicalVisitPatternFail = (error) => ({
    type: ADD_MEDICAL_VISIT_PATTERN_FAIL,
    payload: error
});

export const getDoctorsMedicalVisitPatterns = (doctorId) => async dispatch => {
    dispatch(getDoctorsMedicalVisitPatternsRequest())
    try {
        const response = await axios.get(`/medvisitpattern/doctor/${doctorId}`);
        const { data } = response;
        dispatch(getDoctorsMedicalVisitPatternsSuccess(data))
    } catch (error) {
        dispatch(getDoctorsMedicalVisitPatternsFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const getDoctorsMedicalVisitPatternsRequest = () => ({
    type: GET_DOCTORS_MEDICAL_VISIT_PATTERNS_REQUEST,
});

const getDoctorsMedicalVisitPatternsSuccess = (patterns) => ({
    type: GET_DOCTORS_MEDICAL_VISIT_PATTERNS_SUCCESS,
    payload: patterns
});

const getDoctorsMedicalVisitPatternsFail = (error) => ({
    type: GET_DOCTORS_MEDICAL_VISIT_PATTERNS_FAIL,
    payload: error
});

export const updateMedicalVisitPattern = (patternId, newPattern) => async dispatch => {
    dispatch(updateMedicalVisitPatternRequest())
    try {
        const response = await axios.patch(`/medvisitpattern/${patternId}`, newPattern);
        const { data } = response;
        dispatch(updateMedicalVisitPatternSuccess(data))
    } catch (error) {
        dispatch(updateMedicalVisitPatternFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const updateMedicalVisitPatternRequest = () => ({
    type: UPDATE_MEDICAL_VISIT_PATTERN_REQUEST,
});

const updateMedicalVisitPatternSuccess = (pattern) => ({
    type: UPDATE_MEDICAL_VISIT_PATTERN_SUCCESS,
    payload: pattern
});

const updateMedicalVisitPatternFail = (error) => ({
    type: UPDATE_MEDICAL_VISIT_PATTERN_FAIL,
    payload: error
});

export const deleteMedicalVisitPattern = (patternId) => async dispatch => {
    dispatch(deleteMedicalVisitPatternRequest())
    try {
        const response = await axios.delete(`/medvisitpattern/${patternId}`);
        dispatch(deleteMedicalVisitPatternSuccess(patternId))
    } catch (error) {
        dispatch(deleteMedicalVisitPatternFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const deleteMedicalVisitPatternRequest = () => ({
    type: DELETE_MEDICAL_VISIT_PATTERN_REQUEST,
});

const deleteMedicalVisitPatternSuccess = (patternId) => ({
    type: DELETE_MEDICAL_VISIT_PATTERN_SUCCESS,
    payload: patternId
});

const deleteMedicalVisitPatternFail = (error) => ({
    type: DELETE_MEDICAL_VISIT_PATTERN_FAIL,
    payload: error
});

export const importMedicalVisitPattern = (doctorId, file) => async dispatch => {
    dispatch(importMedicalVisitPatternRequest())
    console.log(file);
    try {
        const response = await axios.post(`/medvisitpattern/doctor/${doctorId}/import`, file);
        const { data } = response;
        dispatch(importMedicalVisitPatternSuccess(data));
    } catch (error) {
        dispatch(importMedicalVisitPatternFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const importMedicalVisitPatternRequest = () => ({
    type: IMPORT_MEDICAL_VISIT_PATTERN_REQUEST,
});

const importMedicalVisitPatternSuccess = (pattern) => ({
    type: IMPORT_MEDICAL_VISIT_PATTERN_SUCCESS,
    payload: pattern
});

const importMedicalVisitPatternFail = (error) => ({
    type: IMPORT_MEDICAL_VISIT_PATTERN_FAIL,
    payload: error
});

export const exportMedicalVisitPattern = (patternId) => async dispatch => {
    dispatch(exportMedicalVisitPatternRequest())
    try {
        const response = await axios.get(`/medvisitpattern/${patternId}/export`);
        const { data } = response;
        const fileName = data.name;
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.log(error);
        dispatch(exportMedicalVisitPatternFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const exportMedicalVisitPatternRequest = () => ({
    type: EXPORT_MEDICAL_VISIT_PATTERN_REQUEST,
});

const exportMedicalVisitPatternSuccess = (file) => ({
    type: EXPORT_MEDICAL_VISIT_PATTERN_SUCCESS,
    payload: file
});

const exportMedicalVisitPatternFail = (error) => ({
    type: EXPORT_MEDICAL_VISIT_PATTERN_FAIL,
    payload: error
});

const initialState = {
    doctorsPatterns: [],
    loading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_MEDICAL_VISIT_PATTERN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADD_MEDICAL_VISIT_PATTERN_SUCCESS:
            return {
                ...state,
                doctorsPatterns: [...state.doctorsPatterns, action.payload],
                loading: true,
            }
        case ADD_MEDICAL_VISIT_PATTERN_FAIL:
            return {
                ...state,
                loading: true,
            }
        case GET_DOCTORS_MEDICAL_VISIT_PATTERNS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_DOCTORS_MEDICAL_VISIT_PATTERNS_SUCCESS:
            return {
                ...state,
                doctorsPatterns: action.payload,
                loading: true,
            }
        case GET_DOCTORS_MEDICAL_VISIT_PATTERNS_FAIL:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_MEDICAL_VISIT_PATTERN_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_MEDICAL_VISIT_PATTERN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                doctorsPatterns: state.doctorsPatterns.map(el => el._id === action.payload._id ? action.payload : el)
            }
        case UPDATE_MEDICAL_VISIT_PATTERN_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case IMPORT_MEDICAL_VISIT_PATTERN_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case IMPORT_MEDICAL_VISIT_PATTERN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                doctorsPatterns: [...state.doctorsPatterns, action.payload]
            }
        case IMPORT_MEDICAL_VISIT_PATTERN_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case DELETE_MEDICAL_VISIT_PATTERN_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case DELETE_MEDICAL_VISIT_PATTERN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                doctorsPatterns: state.doctorsPatterns.filter(el => el._id !== action.payload)
            }
        case DELETE_MEDICAL_VISIT_PATTERN_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        default:
            return state;
    }
};