import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../Shared/assets/arrow-down.svg';
import { ReactComponent as RemoveIcon } from '../../Shared/assets/grey-bin.svg';
import { ReactComponent as ExportIcon } from '../../Shared/assets/export.svg';
import useDebouncer from '../../Shared/Hooks/useDebouncer';
import Input from '../../Shared/Input';
import Select from '../../Shared/Select';
import { Formik } from 'formik';
import { useDrop } from 'react-dnd';

const SetItem = ({
    setData: { name, stage, _id: setId, elements },
    length,
    populatedElements,
    doctorsElements,
    updateSetHandler,
    setWarningModal,
    deleteElementsSet,
    elIsDragging,
    currentCollapse,
    setCurrentCollapse,
    exportElementsSet,
}) => {
    const [highlight, setHighlight] = useState(''),
        [setDebounce] = useDebouncer(),
        [dropError, setDropError] = useState(null),
        [{ canDrop, isOver, dragItem }, drop] = useDrop({
            accept: ['element'],
            drop: ({ _id }) => { if (highlight !== 'decline') { updateSetHandler(setId, _id, 'add') } },
            hover: ({ _id, stage: itemStage }) => {
                if (elements.indexOf(_id) !== -1) {
                    setHighlight('decline');
                    setDropError('Ten element jest już dodany do tego zestawu!');
                } else if (itemStage !== stage) {
                    setHighlight('decline');
                    setDropError('Ten element jest przeznaczony dla innego etapu niż ten zestaw!');
                }
            },
            collect: mon => ({
                isOver: !!mon.isOver(),
                canDrop: !!mon.canDrop(),
                dragItem: mon.getItem(),
            }),
        }),
        [{ }, dropWrapper] = useDrop({
            accept: ['element'],
            hover: () => {
                if (currentCollapse.set !== setId && currentCollapse.set !== 'timeout') {
                    setCurrentCollapse(prev => ({ ...prev, set: 'timeout' }))
                    setTimeout(() => setCurrentCollapse(prev => ({ ...prev, set: setId })), 100)
                }
            },
        });

    const stageOptions = [
        { value: 'WYWIAD', label: 'Wywiad' },
        { value: 'BADANIA FIZYKALNE', label: 'Badania fizykalne' },
        { value: 'ZALECENIA', label: 'Zalecenia' },
    ],
        elementsOptions = doctorsElements.map(({ _id, name, stage }) => ({
            label: name,
            value: _id,
            stage,
            isDisabled: elements.indexOf(_id) !== -1,
        }));

    useEffect(() => {
        if (canDrop && isOver && highlight !== 'accept') {
            setHighlight('accept');
            setDropError(null);
        } else if (canDrop && isOver && highlight !== 'decline') {
            setHighlight('decline');
        } else if (highlight !== '') {
            setHighlight('');
            setDropError(null);
        }
    }, [canDrop, isOver, dragItem]);

    const toggleCollapsed = () => {
        if (currentCollapse.set === null) {
            setCurrentCollapse(prev => ({ ...prev, set: setId }))
        } else if (currentCollapse.set !== setId && currentCollapse.set !== null && currentCollapse.set !== 'timeout') {
            setCurrentCollapse(prev => ({ ...prev, set: 'timeout' }))
            setTimeout(() => setCurrentCollapse(prev => ({ ...prev, set: setId })), 300)
        } else if (currentCollapse.set === setId) {
            setCurrentCollapse(prev => ({ ...prev, set: null }))
        }
    };
    return (
        <Wrapper collapsed={currentCollapse.set !== setId} ref={dropWrapper}>
            <Header onClick={toggleCollapsed}>
                <Name>{name}</Name>
                <div>
                    <Length>{length}</Length>
                    <StyledArrowIcon className='arrow' />
                </div>
            </Header>
            <Body className='body'>
                <Formik
                    onSubmit={(values) => {
                        setDebounce(() => {
                            updateSetHandler(setId, null, 'change', values);
                        })
                    }}
                    enableReinitialize
                    initialValues={{
                        name,
                        stage,
                        elements,
                    }}
                >
                    {({ values, errors, handleChange, handleSubmit, setFieldValue, setFieldError }) => (
                        <Form onSubmit={handleSubmit}>
                            <Settings>
                                <Input
                                    type="text"
                                    name="name"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleSubmit();
                                    }}
                                    value={values.name}
                                    error={errors.name}
                                    hideError={true}
                                />
                                <StyledSelect
                                    type="text"
                                    name="stage"
                                    onChange={({ value }) => {
                                        if (values.elements.length > 0) {
                                            setWarningModal({
                                                shown: true,
                                                question: 'Zmiana etapu tego zestawu spowoduje usunięcie jego elementów. Czy na pewno chcesz kontynułować?',
                                                onAccept: () => {
                                                    setFieldValue('stage', value);
                                                    setFieldValue('elements', []);
                                                    handleSubmit();
                                                },
                                            })
                                        } else {
                                            setFieldValue('stage', value)
                                            handleSubmit();
                                        }
                                    }}
                                    value={stageOptions.find(({ value }) => value === values.stage)}
                                    options={stageOptions}
                                    menuPosition='fixed'
                                />

                                <Actions>
                                    {/* <StyledExportIcon
                                        title='Eksportuj zestaw'
                                        onClick={() => exportElementsSet(setId)}
                                    /> */}
                                    <StyledRemoveIcon
                                        className='remove-btn'
                                        title='Usuń zestaw'
                                        onClick={() => setWarningModal({
                                            shown: true,
                                            question: 'Czy na pewno chcesz usunąć ten zestaw?',
                                            onAccept: () => deleteElementsSet(setId),
                                        })}
                                    />
                                </Actions>
                            </Settings>
                            <Label>Elementy</Label>
                            <Elements>
                                <ElementSelect
                                    type="text"
                                    name="elements"
                                    onChange={({ value }) => {
                                        if (values.elements.indexOf(value) === -1) {
                                            setFieldValue('elements', [...values.elements, value]);
                                            handleSubmit();
                                        } else {
                                            setFieldError('elements', 'To pole jest już dodane')
                                        }
                                    }}
                                    options={elementsOptions.filter(({ stage }) => stage === values?.stage)}
                                    value={{ label: 'Dodaj element z listy...', value: '' }}
                                    error={errors?.elements}
                                    menuPosition='fixed'
                                />
                                {populatedElements.length > 0 ? populatedElements.map(({ name, _id }) => (
                                    <ElementItem key={_id}>
                                        <ElementName>{name}</ElementName>
                                        <RemoveBtn type='button' onClick={() => updateSetHandler(setId, _id, 'remove')} />
                                    </ElementItem>
                                ))
                                    :
                                    <NoData>Brak elementów, możesz je dodać z listy powyżej lub przeciągnąć element</NoData>
                                }
                                <DropZone
                                    // disabled={formControls?.length >= fieldLimit}
                                    data-testid="set_dropzone"
                                    error={dropError}
                                    active={elIsDragging}
                                    highlight={highlight}
                                    ref={drop}
                                >
                                    <span>{dropError ? dropError : 'Przeciągnij element z prawego panelu, aby go dodać'}</span>
                                </DropZone>
                            </Elements>
                        </Form>
                    )}
                </Formik>
            </Body>
        </Wrapper>
    )
}

export default React.memo(SetItem);

const Wrapper = styled.div`
    margin-bottom:5px;
    border: 2px solid ${({ theme }) => theme.grey};
    ${({ collapsed }) => collapsed ? `
        .arrow{
            transform:rotate(0);
        }
        .body{
            max-height:0;
        }
    `: `
        .body{
            min-height:100px;
        }
    `}
`;

const Header = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    background: ${({ theme }) => theme.grey};
    padding: 10px 15px;
    cursor:pointer;
    transition:300ms;
    &:hover{
        background: #efefef;

    }
    >div{
        display:flex;
        align-items:center;
    }
`;

const Body = styled.div`
    transition:500ms;
    max-height:999px;
    min-height:0;
    overflow:hidden;
    ${({ highlight, theme }) => highlight === 'accept' ? `
        background-color:${theme.lightGreen}!important;
        border-color:${theme.green}!important;
        &::after{
            color:${theme.green}!important;
        }`
        : highlight === 'decline' ? `
            background-color:${theme.lightDanger}!important;
            >div{
                border-color:${theme.danger}!important;
            }
        `
            : `
        
        `
    }
`;

const Name = styled.span`
    font-weight: 600;
    color: ${({ theme }) => theme.lightBlack};
    text-align: center;
    text-transform: capitalize;
`;

const Label = styled.p`
    margin-left:15px;
    margin-bottom:3px;
    font-size:14px;
    font-weight:600;
    color:${({ theme }) => theme.lightBlack};
`;

const NoData = styled.p`
    font-size:14px;
    font-weight:600;
    color:${({ theme }) => theme.lightBlack};
    text-align:center;
    max-width: 350px;
    padding: 20px 20px 10px;
    margin: auto;
`;

const StyledArrowIcon = styled(ArrowIcon)`
    transition:300ms;
    transform:rotate(180deg);
    path{
        fill:${({ theme }) => theme.lightBlack};
    }
`;

const Actions = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
`;

const Settings = styled.div`
    display:grid;
    grid-template-columns: 5fr 5fr 1fr;
    gap:10px;
    align-items:center;
    padding:5px 15px;
    margin:10px 0;
`;

const Form = styled.form`
    /* display:flex;
    align-items:center;
    justify-content:space-between;
    width: calc(100% - 50px); */
    /* >div{
        width:30%;
    } */
`;

const Elements = styled.ul`
    margin:0;
`;

const StyledSelect = styled(Select)`
`;
const ElementSelect = styled(Select)`
    width: 100%;
    padding: 0 7px;
`;

const Length = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:${({ theme }) => theme.secondary};
    color:#fff;
    font-weight:600;
    font-size:14px;
    width:20px;
    height:20px;
    border-radius:50%;
    margin-right:10px;
    line-height: 0;
`;

const blinkParent = keyframes`
  from {
      background-color:#f6f5f8;
      border-color:#D9D9D9;
    }
  to {
      background-color: #b9ddff;
      border-color:#1890FF;
  }
`;
const blinkAfter = keyframes`
  from {
      color:#D9D9D9;
    }
  to {
      color:#1890FF;
  }
`;

const animationParent = () =>
    css`
        ${blinkParent} .8s infinite alternate;
    `;

const animationAfter = () =>
    css`
        ${blinkAfter} .8s infinite alternate;
    `;

const ElementItem = styled.div`
    position:relative;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:10px 0 10px 35px;
    margin: 5px 14px;
    border-radius:10px;
    transition:300ms;
    border: 2px solid ${({ theme }) => theme.grey};
`;

const ElementName = styled.span`
    font-size:14px;
    font-weight:600;
    color:${({ theme }) => theme.primary};
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const iconStyles = css`
    transition:300ms;
    margin:0 2.5px;
    cursor:pointer;
    path{
        fill:#ccc;
        transition:300ms;
    }
`;

const StyledRemoveIcon = styled(RemoveIcon)`
    ${iconStyles}
    &:hover path{
        fill:${({ theme }) => theme.danger}
    }
`;
const StyledExportIcon = styled(ExportIcon)`
    ${iconStyles}
    &:hover path{
        fill:${({ theme }) => theme.primary}
    }
`;

const DropZone = styled.div`
    background-color:${({ theme }) => theme.grey};
    border:2px dashed #D9D9D9;
    border-radius: 5px;
    height:50px;
    margin: 10px 14px;
    display:flex;
    justify-content:center;
    align-items:center;
    transition:300ms;
    animation:${animationParent};
    ${({ theme }) => `${theme.mq.desktop}{
        height:45px;
    }`}
    ${({ disabled }) => disabled && 'opacity:.5;'}
    >span{
        color:#D9D9D9;
        font-weight:600;
        transition:300ms;
        animation:${animationAfter};
        text-align:center;
        ${({ active }) => !active && `
            animation: null;
        `}
    }
    ${({ active }) => !active && `
        animation: null;
    `}
    ${({ highlight, theme }) => highlight === 'accept' ? `
        background-color:${theme.lightGreen}!important;
        border-color:${theme.green}!important;
        >span{
            color:${theme.green}!important;
        }`
        : highlight === 'decline' ? `
            background-color:${theme.lightDanger}!important;
            border-color:${theme.danger}!important;
            >span{
                color:${theme.danger}!important;
            }
        `
            : `
        
        `
    }
`;