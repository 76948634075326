import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MedsdiagnosisTabView from "./MedsdiagnosisTabView";
import { withRouter, useParams } from "react-router-dom";
import {
  getMedsDiagnosis,
  getPatientMedsDiagnosis,
  updatePatientMedsDiagnosis,
} from "../../../../../redux/modules/medsdiagnosis";
import { getMedicalData } from "../../../../../redux/modules/medicalRest";

const MedsdiagnosisTabContainer = ({
  getMedsDiagnosis,
  getPatientMedsDiagnosis,
  updatePatientMedsDiagnosis,
  diagnosis,
  meds,
  medsdiagnosisLoading,
  patientMedsDiagnosis,
  getMedicalData,
  currentPatient,
  location: { pathname },
  nextTutorialStep,
}) => {
  const [medsDiagnosisModal, setMedsDiagnosisModal] = useState({
    shown: false,
    target: null,
  });

  const { currentTab } = useParams();

  useEffect(() => {
    if (currentTab === "medsdiagnosis") {
      getPatientMedsDiagnosis(currentPatient._id);
      if (diagnosis.length === 0) {
        getMedicalData("icd10s");
      }
      if (meds.length === 0) {
        getMedicalData("medicalProducts");
      }
    }
  }, [currentPatient, pathname]);

  const updatePatientMedsDiagnosisHandler = (newMedsDiagnosis) => {
    updatePatientMedsDiagnosis(currentPatient._id, newMedsDiagnosis);
  };

  return (
    <MedsdiagnosisTabView
      currentTab={currentTab}
      medsDiagnosisModal={medsDiagnosisModal}
      setMedsDiagnosisModal={setMedsDiagnosisModal}
      patientMedsDiagnosis={patientMedsDiagnosis}
      meds={meds}
      diagnosis={diagnosis}
      medsdiagnosisLoading={medsdiagnosisLoading}
      getMedicalData={getMedicalData}
      updatePatientMedsDiagnosisHandler={updatePatientMedsDiagnosisHandler}
      nextTutorialStep={nextTutorialStep}
    />
  );
};

const mapStateToProps = (state) => ({
  diagnosis: state.medicalRest.diagnosis,
  meds: state.medicalRest.meds,
  medsdiagnosisLoading: state.medsdiagnosis.loading,
  patientMedsDiagnosis: state.medsdiagnosis.patientMedsDiagnosis,
  currentPatient: state.patients.currentPatient,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getMedicalData: (target, settings) => dispatch(getMedicalData(target, settings)),
    getMedsDiagnosis: (target, query) =>
      dispatch(getMedsDiagnosis(target, query)),
    getPatientMedsDiagnosis: (patientId) =>
      dispatch(getPatientMedsDiagnosis(patientId)),
    updatePatientMedsDiagnosis: (patientId, newMedsdiagnosis) =>
      dispatch(updatePatientMedsDiagnosis(patientId, newMedsdiagnosis)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MedsdiagnosisTabContainer));
