import React from "react";
import { BrowserRouter as Router, Switch, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import GlobalStyle from '../Shared/GlobalStyle';
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import NavigationContainer from "../Navigation/NavigationContainer";
import DoctorDashboardContainer from "../DashboardPage/DoctorDashboard/DoctorDashboardContainer";
import AdminDashboardContainer from "../DashboardPage/AdminDashboard/AdminDashboardContainer";
import ReceptionistCalendarContainer from "../CalendarPage/ReceptionistCalendar/ReceptionistCalendarContainer";
import DoctorPatientInfoContainer from "../PatientInfoPage/DoctorPatientInfo/DoctorPatientInfoContainer";
import ReceptionistPatientInfoContainer from "../PatientInfoPage/ReceptionistPatientInfo/ReceptionistPatientInfoContainer";
import DoctorCalendarContainer from "../CalendarPage/DoctorCalendar/DoctorCalendarContainer";
import DoctorProfileContainer from "../ProfilePage/DoctorProfile/DoctorProfileContainer";
import AdminProfileContainer from "../ProfilePage/AdminProfile/AdminProfileContainer";
import ReceptionistProfileContainer from "../ProfilePage/ReceptionistProfile/ReceptionistProfileContainer";
import VisitContainer from "../VisitPage/VisitContainer";
import NotFoundPageContainer from "../NotFoundPage/NotFoundPageContainer";
import AdminSidebarContainer from "../Sidebar/AdminSidebar/AdminSidebarContainer";
import DoctorSidebarContainer from "../Sidebar/DoctorSidebar/DoctorSidebarContainer";
import ReceptionistSidebarContainer from "../Sidebar/ReceptionistSidebar/ReceptionistSidebarContainer";
import EmployeesContainer from "../EmployeesPage/EmployeesContainer";
import HealthcentersContainer from "../HealthcentersPage/HealthcentersContainer";
import VacationsContainer from "../VacationsPage/VacationsContainer";
import ElementsListContainer from "../ElementsPage/ElementsListPage/ElementsListContainer";
import ElementEditorPageContainer from "../ElementsPage/ElementEditorPage/ElementEditorPageContainer";
import CompanyContainer from "../CompanyPage/CompanyContainer";
import IncomeContainer from "../StatisticsPage/Income/IncomeContainer";
import VisitsStatsContainer from "../StatisticsPage/Visits/VisitsStatsContainer";
import LoginContainer from "../LoginPage/LoginContainer";
import LogoutContainer from "../LogoutPage/LogoutContainer";
import ErrorBoundary from "../Shared/ErrorBoundary";
import ReportErrorContainer from '../ReportError/ReportErrorContainer';

function AppRouter({ isAuthenticated, user, loading, userRole, sidebarActive, setSidebarActive }) {
  return (
    <Router>
      <ErrorBoundary>
        <GlobalStyle />
        <NavigationContainer
          userRole={userRole}
          user={user}
          isAuthenticated={isAuthenticated}
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        <AppContent>
          <Prompt
            message={() => {
              if (sidebarActive) {
                setSidebarActive(false)
              }
              return true
            }}
          />
          {(user.activeRole === "Doctor" || user.activeRole === "Nurse") ? <AuthenticatedRoute path="/(|calendar|patients)/:patientId?/:currentTab?" isAuthenticated={isAuthenticated} loading={loading}>
            <>
              <SidebarMobileMask active={sidebarActive} onClick={() => sidebarActive && setSidebarActive(false)} />
              <SidebarWrapper active={sidebarActive}>
                <DoctorSidebarContainer />
              </SidebarWrapper>
            </>
          </AuthenticatedRoute> : user.activeRole === "Registry" ? <AuthenticatedRoute path="/(|patients)/:patientId?/:currentTab?" isAuthenticated={isAuthenticated} loading={loading}>
            <>
              <SidebarMobileMask active={sidebarActive} onClick={() => sidebarActive && setSidebarActive(false)} />
              <SidebarWrapper active={sidebarActive}>
                <ReceptionistSidebarContainer />
              </SidebarWrapper>
            </>
          </AuthenticatedRoute> :
              <AuthenticatedRoute path="/(|employees|company|vacations|payments|healthcenters|income|profile|visits)/" isAuthenticated={isAuthenticated} loading={loading}>
                <>
                  <SidebarMobileMask active={sidebarActive} onClick={() => sidebarActive && setSidebarActive(false)} />
                  <SidebarWrapper active={sidebarActive}>
                    <AdminSidebarContainer />
                  </SidebarWrapper>
                </>
              </AuthenticatedRoute>}

          <Switch>
            <AuthenticatedRoute path="/report-error" exact component={ReportErrorContainer} isAuthenticated={isAuthenticated} loading={loading} />
            <AuthenticatedRoute path="/" exact component={(user.activeRole === "Doctor" || user.activeRole === "Nurse") ? DoctorDashboardContainer : user.activeRole === "Admin" ? AdminDashboardContainer : ReceptionistCalendarContainer} isAuthenticated={isAuthenticated} loading={loading} />
            <AuthenticatedRoute path="/calendar" exact component={(user.activeRole === "Doctor" || user.activeRole === "Nurse") ? DoctorCalendarContainer : ReceptionistCalendarContainer} isAuthenticated={isAuthenticated} loading={loading} />
            <AuthenticatedRoute path="/patients/:patientId?/:currentTab?" exact component={(user.activeRole === "Doctor" || user.activeRole === "Nurse") ? DoctorPatientInfoContainer : ReceptionistPatientInfoContainer} isAuthenticated={isAuthenticated} loading={loading} />
            <AuthenticatedRoute path="/profile" exact component={(user.activeRole === "Doctor" || user.activeRole === "Nurse") ? DoctorProfileContainer : user.activeRole === "Admin" ? AdminProfileContainer : ReceptionistProfileContainer} isAuthenticated={isAuthenticated} loading={loading} />
            {(user.activeRole === "Doctor" || user.activeRole === "Nurse") && <AuthenticatedRoute path="/visit/:id/" exact component={VisitContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            {(user.activeRole === "Doctor" || user.activeRole === "Nurse") && <AuthenticatedRoute path="/elements" exact component={ElementsListContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            {(user.activeRole === "Doctor" || user.activeRole === "Nurse") && <AuthenticatedRoute path="/elements/:elementId" exact component={ElementEditorPageContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            {user.activeRole === "Admin" && <AuthenticatedRoute path="/employees" exact component={EmployeesContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            {user.activeRole === "Admin" && <AuthenticatedRoute path="/company" exact component={CompanyContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            {user.activeRole === "Admin" && <AuthenticatedRoute path="/healthcenters" exact component={HealthcentersContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            {user.activeRole === "Admin" && <AuthenticatedRoute path="/vacations" exact component={VacationsContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            {user.activeRole === "Admin" && <AuthenticatedRoute path="/income" exact component={IncomeContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            {user.activeRole === "Admin" && <AuthenticatedRoute path="/visits" exact component={VisitsStatsContainer} isAuthenticated={isAuthenticated} loading={loading} />}
            <UnauthenticatedRoute path="/logout" exact component={LogoutContainer} loading={loading} />
            <UnauthenticatedRoute path="/login" exact component={LoginContainer} loading={loading} />
            <UnauthenticatedRoute exact component={NotFoundPageContainer} loading={loading} />
          </Switch>
        </AppContent>
      </ErrorBoundary>
    </Router>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.authentication.isAuthenticated,
  user: state.authentication.user,
  loading: state.authentication.loading,
  userRole: state.authentication.userRole
});

export default connect(
  mapStateToProps,
  null
)(AppRouter);


const SidebarWrapper = styled.div`
    width:300px;
    min-width: 300px;
    border-right: 1px solid ${({ theme }) => theme.grey};
    max-height: calc(100vh - 60px);
    background-color: #fff;
    overflow: hidden;
    position:fixed;
    left:-300px;
    top:60px;
    bottom:0;
    z-index:10;
    transition:300ms;
    ${({ active }) => active && `
      left:0;
    `}
    ${({ theme }) => `
      ${theme.mq.desktop}{
        position:relative;
        top:0;
        left:0;
      }`
  }
`;

const SidebarMobileMask = styled.div`
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color:#000;
  opacity:0;
  z-index: -1;
  transition:300ms opacity;
  ${({ active }) => active && `
    opacity:.3;
    z-index: 9;
  `}
  ${({ theme }) => `${theme.mq.desktop}{
    opacity:0;
    z-index:-1;
  }`}
`;

const AppContent = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #f2f5f8;
    min-height: calc(100vh - 60px);
`;