import React from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import styled from 'styled-components';

const Calendar = ({
    onChange,
    value,
    className
}) => {
    return (
        <StyledCalendar
            value={value}
            onChange={onChange}
            className={className}
        />
    )
}

export default Calendar;

const StyledCalendar = styled(ReactCalendar)`
    border:none;
    margin-bottom:20px;
    height:auto;
    min-height:235px;
    .react-calendar__navigation {
      height: 34px;
      margin-bottom: 0;
    }
    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight:normal;
      font-size: 0.75em;
    }
    .react-calendar__month-view__weekdays abbr{
        text-decoration:none;
    }
    .react-calendar__month-view__days__day--weekend {
      color: ${({theme})=>theme.lightBlack};
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: #bfbfbf;
    }
    .react-calendar__tile {
      max-width: 100%;
      text-align: center;
      padding: 0.5em;
      background: none;
    }
    .react-calendar__tile:disabled {
      background-color: #f0f0f0;
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: #e6e6e6;
    }
    .react-calendar__tile--hasActive {
      background: #76baff;
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: #a9d4ff;
    }
    .react-calendar__tile--now {
      background:#eaf6ff;
      border-radius:4px;
    }
    .react-calendar__tile--active {
      background: ${({ theme }) => theme.primary};
      border-radius:4px;
      color: white;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: #1087ff;
    }
    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #e6e6e6;
    }
    .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button{
      display:none;
    }
`;