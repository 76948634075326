import React from 'react';
import styled from 'styled-components';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import { theme } from '../Shared/theme';
import { setTutorial } from '../../redux/modules/tutorial';
import { updateAuthenticatedUser } from '../../redux/modules/authentication';
import Button from '../Shared/Button';
import { ReactComponent as InfoIcon } from '../Shared/assets/info-circle.svg';

const Tutorial = ({
    tutorialActive,
    tutorialCurrentStep,
    tutorialSteps,
    setTutorial,
    updateAuthenticatedUser,
    currentTutorial,
    user,
}) => {
    const callbackHandler = ({ action, lifecycle, status, index }) => {
        switch (action) {
            case 'next': {
                if (status === 'running' && lifecycle === 'complete') {
                    setTutorial({ stepIndex: index + 1 })
                } else if (status === 'finished') {
                    updateAuthenticatedUser({
                        tutorials: {
                            ...user.tutorials,
                            [currentTutorial]: true,
                        }
                    }, user._id);
                    setTutorial({ stepIndex: 0, active: false, current: null });
                }
            }
                break;
            case 'prev': {
                if (status === 'running' && lifecycle === 'complete') {
                    setTutorial({ stepIndex: index - 1 })
                }
            }
                break;
            case 'skip': {
                updateAuthenticatedUser({
                    tutorials: {
                        ...user.tutorials,
                        [currentTutorial]: true,
                    }
                }, user._id);
                setTutorial({ stepIndex: 0, active: false, current: null });
            }
                break;
            case 'close': {
                setTutorial({ stepIndex: 0, active: false, current: null });
            }
                break;
            default:
                return false;
        }
    };
    return (
        <Joyride
            continuous={true}
            run={tutorialActive}
            stepIndex={tutorialCurrentStep}
            callback={callbackHandler}
            tooltipComponent={TutorialPopup}
            steps={tutorialSteps}
            disableOverlay={tutorialCurrentStep[tutorialSteps]?.disableOverlay}
            disableScrollParentFix={true}
            spotlightClicks={true}
            disableOverlayClose={true}
            locale={{
                back: 'Wróć',
                close: 'Zamknij',
                last: 'Zamknij',
                next: 'Następny',
                skip: 'Pomiń'
            }}
            styles={{
                options: {
                    primaryColor: theme.primary,
                    textColor: theme.lightBlack,
                    overlayColor: 'rgba(0, 0, 0, 0.35)',
                    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                    zIndex: 1000,
                },
                overlay: {
                    cursor: 'default',
                }
            }}
        />
    )
}

const TutorialPopup = ({
    index,
    step,
    size,
    backProps,
    skipProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep
}) => {
    return (
        <Wrapper {...tooltipProps} maxWidth={step.popperMaxWidth}>
            <Header>
                <Title>
                    {step.title} ({index + 1}/{size})
                </Title>
                <RemoveBtn {...closeProps} />
            </Header>
            <Body>
                {step.content}
                {step.continueInfo && <ContinueInfo>
                    <StyledInfoIcon />
                    {step.continueInfo}
                </ContinueInfo>}
            </Body>
            <Footer>
                <Button
                    size='small'
                    variant='danger'
                    {...skipProps}
                >Pomiń</Button>
                <span>
                    {index > 0 && <Button
                        size='small'
                        variant='secondary'
                        disabled={(step.hideBtns || step.hideBackBtn)}
                        {...backProps}
                    >Wróć</Button>}
                    <Button
                        size='small'
                        variant='primary'
                        disabled={(step.hideBtns || step.hideNextBtn)}
                        {...primaryProps}
                    >{isLastStep ? 'Zakończ' : 'Dalej'}</Button>
                </span>
            </Footer>
        </Wrapper>
    )
}

const mapStateToProps = state => ({
    tutorialActive: state.tutorial.active,
    tutorialCurrentStep: state.tutorial.stepIndex,
    tutorialSteps: state.tutorial.steps,
    currentTutorial: state.tutorial.current,
    user: state.authentication.user,
});

const mapDispatchToProps = dispatch => {
    return {
        setTutorial: (payload) => dispatch(setTutorial(payload)),
        updateAuthenticatedUser: (userData, id) => dispatch(updateAuthenticatedUser(userData, id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);

const Wrapper = styled.div`
    background-color:#fff;
    border-radius: 4px;
    box-shadow:0 0 15px rgba(0, 0, 0, 0.1);
    min-width:300px;
    max-width:${({ maxWidth }) => maxWidth ? `${maxWidth}px` : '350px'};
    position:relative;
`;

const Header = styled.div`
    padding: 10px;
`;

const Title = styled.span`
    font-weight:600;
    font-size:18px;
    color:${theme.lightBlack};
`;

const Body = styled.div`
    padding: 10px 15px;
    font-size:14px;
    min-height:50px;
    color:${theme.lightBlack};
`;

const Footer = styled.div`
    padding: 10px;
    border-top: 1px solid ${theme.grey};
    display:flex;
    justify-content:space-between;
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:5px;
  &::before,
  &::after {
    content: "";
    height: 15px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const ContinueInfo = styled.p`
    margin-top:15px;
    color: ${({ theme }) => theme.primary};
    font-size: 12px;
    font-weight: 600;
    padding-left:3px;
    display:flex;
    align-items:flex-start;
    justify-content:flex-end;
`;

const StyledInfoIcon = styled(InfoIcon)`
    width:14px;
    min-width:14px;
    height:14px;
    margin-top: 2px;
    margin-right: 3px;
    path{
        fill: ${({ theme }) => theme.primary};
    }
`;