import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Select from '../../../Shared/Select';
import Button from '../../../Shared/Button';
import { relationshipKindsEnum } from '../Tabs/tabsEnums';

const RiskFactorsModal = ({
    modalState: { shown: modalShown },
    setModalState,
    familyCancers,
    cancers,
    getCancersHandler,
    updatePatientRiskFactorsHandler
}) => {
    const [listItems, setListItems] = useState([]);

    const resetModalHandler = () => {
        setListItems([]);
    },
        addItemHandler = (val) => {
            setListItems([...listItems, { ...val, relationshipKind: null }]);
        },
        removeItemHandler = (targetId) => {
            setListItems(prev => prev.filter(el => el._id !== targetId));
        },
        chooseRelationship = ({ value }, index) => {
            let newItems = [...listItems];
            newItems[index].relationshipKind = value
            setListItems(newItems);
        },
        submitHandler = () => {
            updatePatientRiskFactorsHandler({ familyCancers: listItems });
            setModalState({ shown: false });
            resetModalHandler();
        }

    const cancersFormatOptionLabel = ({ category, subcategory, subcategoryCode, label }) => (
        <Option>
            <OptionPlaceholder>{label}</OptionPlaceholder>
            <OptionCode>{subcategoryCode}</OptionCode>
            <OptionLabel>{category} - {subcategory}</OptionLabel>
        </Option>
    );

    useEffect(() => {
        if (familyCancers) {
            setListItems(familyCancers);
        }
    }, [familyCancers, modalShown]);

    return (
        <Modal
            title='Edycja nowotworów w rodzinie'
            exitButton={true}
            onCancel={() => { setModalState({ shown: false, target: null }); resetModalHandler(); }}
            show={modalShown}
        >
            <CancerSelect
                placeholder="Dodaj rozpoznanie z listy"
                onChange={addItemHandler}
                options={cancers}
                onInputChange={getCancersHandler}
                name='cancer'
                value={{ label: 'Dodaj nowotwór z listy' }}
                searchInputWithoutFilter={true}
                customFormatOptionLabel={cancersFormatOptionLabel}
                noOptionsMessage={() => <p>Nie znaleziono takiego rozpoznania</p>}
            />
            <List>
                {listItems.map(({ category, subcategory, subcategoryCode, relationshipKind, _id }, index) => (
                    <ListItem key={_id}>
                        <ItemLabel>
                            <span>
                                <Code>{subcategoryCode}</Code>
                                <Name>{category} - {subcategory}</Name>
                            </span>
                            <FamilyMemberSelect
                                placeholder="Relacja"
                                value={relationshipKind && { value: relationshipKind, label: relationshipKind }}
                                onChange={(val) => chooseRelationship(val, index)}
                                options={relationshipKindsEnum.map(el => ({ value: el, label: el, targetId: _id }))}
                                name={'pattern'}
                            />
                        </ItemLabel>
                        <RemoveBtn onClick={() => removeItemHandler(_id)} />
                    </ListItem>
                ))}
            </List>
            <CenterBox>
                <Button onClick={submitHandler} type='submit' variant='primary'>Zapisz zmiany</Button>
            </CenterBox>
        </Modal>
    )
}

export default RiskFactorsModal;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;

const CancerSelect = styled(Select)`
    margin-top:15px;
`;

const FamilyMemberSelect = styled(Select)`
    >div{
        border:none;
        margin:0;
    }
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const List = styled.ul`
    width:400px;
    margin: 10px 0;
`;

const ListItem = styled.li`
    position:relative;
    font-size:12px;
    padding:5px 15px 5px 5px;
    &:not(:last-child){
        border-bottom: 1px solid ${({ theme }) => theme.grey};
    }
`;

const Code = styled.span`
    font-size:12px;
    font-weight:600;
    margin-right:5px;
`;

const Name = styled.span`
    font-size:12px;
    padding-right:10px;
`;

const ItemLabel = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    >span{
        display:flex;

    }
`;

const Option = styled.div`
    display:flex;
`;

const OptionCode = styled.span`
    font-weight:600;
    padding-right:5px;
`;

const OptionLabel = styled.span`
   font-weight:normal;
`;

const OptionPlaceholder = styled.span`
   color:#ccc;
`;