import React, { useState, useEffect } from "react";
import DoctorPatientInfoView from "./DoctorPatientInfoView";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { nextTutorialStep, setTutorial } from '../../../redux/modules/tutorial';

const DoctorPatientInfoContainer = ({
  company,
  currentPatient,
  nextTutorialStep,
  tutorialCurrent,
  setTutorial,
  tutorialActive,
}) => {
  const [saveModal, setSaveModal] = useState(false),
    [riskFactorsCheckboxEdit, setRiskFactorsCheckboxEdit] = useState(null);

  //Tutorial handler
  useEffect(() => {
    if (currentPatient?._id && tutorialCurrent !== 'patients' && tutorialActive) {
      setTutorial({
        active: false,
      })
      setTimeout(() => {
        setTutorial({
          active: true,
        })
      }, 200)
    }
  }, [currentPatient])

  return <DoctorPatientInfoView
    currentPatient={{ ...currentPatient, companyName: company?.name }}
    setRiskFactorsCheckboxEdit={setRiskFactorsCheckboxEdit}
    saveModal={saveModal}
    setSaveModal={setSaveModal}
    nextTutorialStep={nextTutorialStep}
  />;
};

const mapStateToProps = state => ({
  currentPatient: state.patients.currentPatient,
  tutorialCurrent: state.tutorial.current,
  tutorialActive: state.tutorial.active,
});

const mapDispatchToProps = dispatch => {
  return {
    nextTutorialStep: () => dispatch(nextTutorialStep()),
    setTutorial: (payload) => dispatch(setTutorial(payload))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DoctorPatientInfoContainer));
