import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDrag, useDragLayer } from 'react-dnd';
import Button from '../Shared/Button';
import Input from '../Shared/Input';
import InfoPill from '../Shared/InfoPill';
import Spinner from '../Shared/Spinner';
import { fullStageNameEnum } from '../Shared/enums';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useHistory } from 'react-router-dom';
import useDebouncer from '../Shared/Hooks/useDebouncer';
import moment from 'moment';
import InfiniteScrollList from '../Shared/InfiniteScrollList';
import searchIcon from '../Shared/assets/search.svg';
import avatarPlaceholder from '../Shared/assets/avatar.svg';
import dragIcon from '../Shared/assets/drag.svg';
import { truncateText } from "../Shared/utils";

const SidePanel = ({
    currentPatient: { avatar, name, surname, _id, additionalInfo },
    currentStageIndex,
    doctorsElements,
    elIsDragging,
    setElIsDragging,
    currentStageObj,
    diagnosis,
    procedures,
    meds,
    getMedicalData,
    elementsLoading,
    medsLoading,
    diagnosisLoading,
    proceduresLoading,
    services,
    medsSwitch,
    setMedsSwitch,
    readMode,
    updateMedicalVisitHandler,
    doctorsElementsSets,
    currentVisit,
    otherDiagnosis,
    medicalProcedures,
    editMode,
    sidebarActive,
}) => {
    const [searchValue, setSearchValue] = useState(""),
        [elements, setElements] = useState([]),
        [loading, setLoading] = useState(true),
        [setDebouncer, debounceLoading] = useDebouncer();

    const patientInfoBoxRef = useRef(null);

    const history = useHistory();
    const sectionNames = [
        "Elementy wywiadu",
        "Elementy badań fizykalnych",
        "Zabiegi",
        "Choroby",
        "Leki",
        "Usługi",
    ];
    const itemNames = [
        "elementu",
        "elementu",
        "zabiegu",
        "choroby",
        "leku",
        "usługi",
    ];
    if (!medsSwitch) {
        sectionNames[4] = "Elementy zaleceń";
    }
    useEffect(() => {
        if (currentStageIndex < 2 || (currentStageIndex === 4 && !medsSwitch)) {
            const mapedSets = doctorsElementsSets
                .map((set) => ({
                    ...set,
                    type: "set",
                    elements: set.elements
                        ?.map((elId) => doctorsElements.find(({ _id }) => _id === elId))
                        ?.filter((el) => typeof el !== "undefined")
                        ?.filter(
                            ({ active, _id: elId }) =>
                                active &&
                                currentStageObj.elements?.findIndex(
                                    ({ _id }) => _id === elId
                                ) === -1
                        ),
                }))
                .filter(({ elements }) => elements.length > 0);
            const activeElements = doctorsElements.filter(({ active }) => active);
            setElements(
                [...activeElements, ...mapedSets]
                    .filter(({ stage }) => stage === fullStageNameEnum[currentStageIndex])
                    .filter(
                        (el) =>
                            currentStageObj?.elements?.findIndex(
                                ({ _id }) => el._id === _id
                            ) === -1
                    )
                    .filter(({ name }) =>
                        name
                            .replace(/ /g, "")
                            .toUpperCase()
                            .includes(searchValue.replace(/ /g, "").toUpperCase())
                    )
            );
        } else if (currentStageIndex === 2) {
            const notUsedProcedures = procedures.filter(
                ({ code }) =>
                    medicalProcedures.findIndex((el) => el.code === code) === -1
            );
            setElements(notUsedProcedures);
        } else if (currentStageIndex === 3) {
            const notUsedDiagnosis = diagnosis.filter(
                ({ subcategoryCode }) =>
                    otherDiagnosis.findIndex(
                        (el) => el.subcategoryCode === subcategoryCode
                    ) === -1
            );
            setElements(notUsedDiagnosis);
        } else if (currentStageIndex === 4) {
            setElements(meds);
        } else if (currentStageIndex === 5) {
            setElements(
                services.list.filter(({ name }) =>
                    name
                        .replace(/ /g, "")
                        .toUpperCase()
                        .includes(searchValue.replace(/ /g, "").toUpperCase())
                )
            );
        }
    }, [
        doctorsElements,
        searchValue,
        currentStageObj,
        diagnosis,
        procedures,
        meds,
        getMedicalData,
        elementsLoading,
        medsLoading,
        diagnosisLoading,
        proceduresLoading,
        services,
        medsSwitch,
        medicalProcedures,
        otherDiagnosis,
    ]);

    useEffect(() => {
        setSearchValue("");
    }, [currentStageIndex]);

    useEffect(() => {
        setDebouncer(() => {
            if (currentStageIndex === 2) {
                getMedicalData("icd9s", { value: searchValue });
            } else if (currentStageIndex === 3) {
                getMedicalData("icd10s", { value: searchValue });
            } else if (currentStageIndex === 4) {
                getMedicalData("medicalProducts", { value: searchValue });
            }
        });
    }, [searchValue]);

    useEffect(() => {
        switch (currentStageIndex) {
            case 0:
                setLoading(elementsLoading);
                break;
            case 1:
                setLoading(elementsLoading);
                break;
            case 2:
                setLoading(proceduresLoading);
                break;
            case 3:
                setLoading(diagnosisLoading);
                break;
            case 4:
                if (medsSwitch) {
                    setLoading(medsLoading);
                } else {
                    setLoading(elementsLoading);
                }
                break;
            case 5:
                setLoading(false);
                break;
        }
    }, [
        elementsLoading,
        medsLoading,
        diagnosisLoading,
        proceduresLoading,
        currentStageIndex,
        medsSwitch,
    ]);

    return (
        <Wrapper active={sidebarActive} id="visitSidepanel">
            <CurrentPatientBox ref={patientInfoBoxRef}>
                <PatientData>
                    <Avatar
                        src={
                            avatar
                                ? `${process.env.REACT_APP_SRV_URL}${avatar}`
                                : avatarPlaceholder
                        }
                    />
                    <div>
                        <PatientName>
                            {name}
                            <span>{surname}</span>
                        </PatientName>
                        <VisitDate>
                            {editMode && "Edycja wizyty: "}
                            {moment(currentVisit?.start).format("DD.MM.YYYY HH:mm")}
                        </VisitDate>
                    </div>
                </PatientData>
                <PatientDataMore>
                    <PatientInfoPill variant="yellow">{truncateText(additionalInfo,115)}</PatientInfoPill>
                    <ProfileBtn
                        onClick={() => {
                            updateMedicalVisitHandler();
                            history.push(`/patients/${_id}/visits`);
                        }}
                        variant="secondary"
                    >
                        Zobacz profil
                    </ProfileBtn>
                </PatientDataMore>
            </CurrentPatientBox>
            <ListsWrapper disabled={readMode}>
                {currentStageIndex === 4 && (
                    <Switch>
                        <SwitchItem active={medsSwitch} onClick={() => setMedsSwitch(true)}>
                            Leki
                        </SwitchItem>
                        <SwitchItem
                            active={!medsSwitch}
                            onClick={() => setMedsSwitch(false)}
                        >
                            Elementy
                        </SwitchItem>
                        <SwitchHr active={medsSwitch === false} />
                    </Switch>
                )}
                <Label>{sectionNames[currentStageIndex]}</Label>
                <Items>
                    <SearchBox active={searchValue !== ""}>
                        <SearchInput
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder={`Szukaj ${itemNames[currentStageIndex]}...`}
                            hideError={true}
                        />
                        <RemoveBtn onClick={() => setSearchValue("")} />
                    </SearchBox>
                    <InfiniteScrollList
                        onScrollBottom={() => {
                            if (!loading) {
                                if (currentStageIndex === 2) {
                                    getMedicalData("icd9s", { value: searchValue, skip: procedures.length }, true);
                                }
                                else if (currentStageIndex === 3) {
                                    getMedicalData("icd10s", { value: searchValue, skip: diagnosis.length }, true);
                                }
                                else if (currentStageIndex === 4 && medsSwitch) {
                                    getMedicalData("medicalProducts", { value: searchValue, skip: meds.length }, true);
                                }
                            }
                        }}
                        bottomOffset={500}
                        maxHeight={`calc(100vh - 160px - ${patientInfoBoxRef.current?.offsetHeight ?? 0}px)`}>
                        {(loading || debounceLoading) ? (
                            <Spinner />
                        ) : elements.length > 0 ? (
                            elements.map((el) => {
                                let type =
                                    currentStageIndex < 2 ||
                                        (currentStageIndex === 4 && !medsSwitch)
                                        ? "element"
                                        : currentStageIndex === 2
                                            ? "procedure"
                                            : currentStageIndex === 3
                                                ? "diagnosis"
                                                : currentStageIndex === 4
                                                    ? "med"
                                                    : currentStageIndex === 5
                                                        ? "service"
                                                        : "";
                                type = el.type === "set" ? "set" : type;
                                return (
                                    <ElementItem
                                        key={el._id}
                                        el={el}
                                        elIsDragging={elIsDragging}
                                        setElIsDragging={setElIsDragging}
                                        type={type}
                                    />
                                );
                            })
                        ) : searchValue !== "" ? (
                            <NoElements>
                                Nie znaleziono {itemNames[currentStageIndex]} o&nbsp;nazwie{" "}
                                <span>{searchValue}</span>
                            </NoElements>
                        ) : searchValue === "" && elements.length === 0 ? (
                            <NoElements>
                                {currentStageIndex === 0 ||
                                    currentStageIndex === 1 ||
                                    (currentStageIndex === 4 && !medsSwitch)
                                    ? "Brak elementów dla tego etapu"
                                    : "Brak elementów dla tej listy"}
                            </NoElements>
                        ) : (
                                            <></>
                                        )}
                    </InfiniteScrollList>
                </Items>
            </ListsWrapper>
            <CustomDragLayer />
        </Wrapper>
    );
};

export default SidePanel;

const ElementItem = ({ el, type, elIsDragging, setElIsDragging }) => {
    const [{ isDragging }, drag, preview] = useDrag({
        item: { type, ...el },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);
    useEffect(() => {
        if (elIsDragging !== type && isDragging) {
            setElIsDragging(type);
        } else if (elIsDragging === type && !isDragging) {
            setElIsDragging(false);
        }
    }, [isDragging]);
    return (
        <Element transition ref={drag} dragging={isDragging}>
            {type === "element" ? (
                <Name size="big">{el.name}</Name>
            ) : type === "procedure" ? (
                <Details>
                    <Code>{el.code}</Code>
                    <Name>{el.name}</Name>
                </Details>
            ) : type === "diagnosis" ? (
                <Details>
                    <Code>{el.subcategoryCode}</Code>
                    <Name>{el.category}, {el.subcategory}</Name>
                </Details>
            ) : type === "med" ? (
                <Details>
                    <Code>{el.nazwaProduktu}</Code>
                    <Name>{`${el.nazwaPowszechnieStosowana}, ${el.moc ?? ""}`}</Name>
                </Details>
            ) : type === "service" ? (
                <Details>
                    <Name>
                        <span>{el.name}</span>
                        <span>{el.price} zł</span>
                    </Name>
                </Details>
            ) : type === "set" ? (
                <Details>
                    <Name>{el.name}</Name>
                    <Elements>
                        {(() => {
                            let names = el.elements.map(({ name }) => name).join(", ");
                            if (names.length > 31) {
                                names = names.substring(0, 30) + "...";
                            }
                            return <span>{names}</span>;
                        })()}
                    </Elements>
                    <Counter>{el.elements.length}</Counter>
                </Details>
            ) : (
                                        <p>Wrong item type</p>
                                    )}
        </Element>
    );
};

const CustomDragLayer = () => {
    const { itemType, item, initialOffset, currentOffset } = useDragLayer(
        (monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        })
    );
    const getItemStyles = (initialOffset, currentOffset) => {
        if (itemType === "dropedElement") {
            return {
                display: "none",
            };
        }
        if (!initialOffset || !currentOffset) {
            return {
                display: "none",
            };
        }
        let { x, y } = currentOffset;
        const transform = `translate(${x}px, ${y}px)`;
        return {
            transform,
            WebkitTransform: transform,
            width: "300px",
            backgroundColor: "#fff",
            border: "2px solid #f6f5f8",
            opacity: 0.9,
            boxShadow: "0 4px 6px #ccc",
            trasition: "none",
        };
    };
    return (
        <div
            style={{
                position: "fixed",
                pointerEvents: "none",
                zIndex: 100,
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
            }}
        >
            <Element style={getItemStyles(initialOffset, currentOffset)}>
                {itemType === "element" ? (
                    <Name size="big">{item?.name}</Name>
                ) : itemType === "procedure" ? (
                    <Details>
                        <Code>{item?.code}</Code>
                        <Name>{item?.name}</Name>
                    </Details>
                ) : itemType === "diagnosis" ? (
                    <Details>
                        <Code>{item?.subcategoryCode}</Code>
                        <Name>{item?.subcategory}</Name>
                    </Details>
                ) : itemType === "med" ? (
                    <Details>
                        <Code>{item?.nazwaProduktu}</Code>
                        <Name>{`${item?.nazwaPowszechnieStosowana}, ${item?.moc}`}</Name>
                    </Details>
                ) : itemType === "service" ? (
                    <Details>
                        <Name>
                            <span>{item?.name}</span>
                            <span>{item?.price} zł</span>
                        </Name>
                    </Details>
                ) : itemType === "set" ? (
                    <Details>
                        <Name>{item.name}</Name>
                        <Elements>
                            {(() => {
                                let names = item.elements.map(({ name }) => name).join(", ");
                                if (names.length > 31) {
                                    names = names.substring(0, 30) + "...";
                                }
                                return <span>{names}</span>;
                            })()}
                        </Elements>
                        <Counter>{item.elements.length}</Counter>
                    </Details>
                ) : (
                                            <p>Wrong item type</p>
                                        )}
            </Element>
        </div>
    );
};

const Wrapper = styled.div`
    min-width:300px;
    width:300px;
    border-right: 2px solid ${({ theme }) => theme.grey};
    background-color:#fff;
    position:relative;
    max-height: calc(100vh - 60px);
    height:100%;
    overflow-y: auto;
    position:fixed;
    left:-300px;
    top:60px;
    z-index:10;
    transition:300ms;
    ${({ active }) =>
        active &&
        `
      left:0;
    `}
    ${({ theme }) => `
      ${theme.mq.desktop}{
        position:relative;
        top:0;
        left:0;
        min-width:250px;
        width:250px;
      }`}
    ${({ theme }) => `${theme.mq.large}{
        min-width:300px;
        width:300px;
    }`}
`;

const CurrentPatientBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 13px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.grey};
  transition: 300ms;
`;

const PatientData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
`;

const PatientName = styled.p`
  font-size: 18px;
  line-height: 1;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.lightBlack};
  > span {
    color: inherit;
    font-size: 36px;
  }
`;

const VisitDate = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 2.5px 0 0 10px;
  color: ${({ theme }) => theme.lightBlack};
`;

const ProfileBtn = styled(Button)`
  padding: 6px 7px;
  margin-top: 5px;
`;

const Avatar = styled.img`
  width: 62px;
  height: 62px;
  background-color: #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 4px #eee;
`;

const Name = styled.p`
  color: ${({ theme }) => theme.lightBlack};
  font-size: ${({ size }) => (size === "big" ? "16px" : "12px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Code = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

const SearchBox = styled.div`
  position: relative;
  overflow: hidden;
  ${({ active }) =>
        active &&
        `
        button{
            right:6px;
        }
        input{
            background-position:center right -14px!important;
        }
    `}
`;

const SearchInput = styled(Input)`
  background-image: url(${searchIcon});
  background-position: center right 10px;
  background-repeat: no-repeat;
  margin: 5px 0;
  &:focus {
    border-width: 1px;
    padding-left: 10px;
  }
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: 0;
  bottom: 0;
  transition: 300ms;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const Label = styled.p`
  font-size: 19px;
  font-weight: 600;
  padding-left: 5px;
  margin-top: 10px;
`;

const Items = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - (60px + 40px + 100px));
  padding: 10px 2px 0;
  ${({ theme }) => `${theme.mq.large}{
        padding: 10px 10px 0;
    }`}
`;

const Element = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  cursor: grab;
  margin: 5px;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 5px;
  ${({ transition }) => transition && "transition:300ms;"}
  &::before {
    content: url(${dragIcon});
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.primary};
    transform: translate(2px, -2px);
  }
  ${({ dragging }) =>
        dragging &&
        `
        opacity:.5;
    `}
`;
const NoItems = styled.p`
  font-size: 12px;
  margin-left: 10px;
`;
const NoElements = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.lightBlack};
  text-align: center;
  max-width: 200px;
  margin: 15px auto;
  > span {
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
  }
`;

const Details = styled.div`
  width: 100%;
`;

const Switch = styled.div`
  display: flex;
  position: absolute;
  right: 15px;
  top: 6px;
`;

const SwitchItem = styled.div`
  font-size: 12px;
  cursor: pointer;
  transition: 300ms;
  opacity: 0.5;
  margin: 0 2.5px;
  ${({ active }) => active && `opacity:1;`}
`;

const SwitchHr = styled.div`
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 1px;
  width: 25px;
  transition: 300ms;
  background: ${({ theme }) => theme.primary};
  ${({ active }) => active && "left:28px; width:55px;"}
`;

const ListsWrapper = styled.div`
  position: relative;
  ${({ disabled }) =>
        disabled && `opacity:.7;cursor:not-allowed;pointer-events:none;`}
`;

const Elements = styled.div`
  display: flex;
  padding-right: 20px;
  > span {
    font-size: 12px;
    margin-right: 2px;
    color: #d9d9d9;
  }
`;

const Counter = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  font-size: 12px;
  color: #fff;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto 0;
`;

const PatientDataMore = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
`;

const PatientInfoPill = styled(InfoPill)`
    display: block;
    max-height: 74px;
    overflow-x: hidden;
    overflow-y: auto;
    word-break: break-word;
`;