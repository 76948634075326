import React from 'react';
import styled from "styled-components";
import AvatarPlaceholder from "../assets/avatar.svg";

const ResourceHeader = ({ resource }) => {
    return (
      <ResourceHeaderContainer>
        {resource.userId ? (
          <>
            <ResourceAvatar src={!resource.userId?.avatar ? AvatarPlaceholder : `${process.env.REACT_APP_SRV_URL}${resource.userId?.avatar}`} />
            <ResourceName>{resource.userId?.name}</ResourceName>
          </>
        ) : (
          <ResourceName>{resource.name}</ResourceName>
        )}
      </ResourceHeaderContainer>
    );
  };


  export default ResourceHeader;


  const ResourceHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResourceAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
`;

const ResourceName = styled.h4``;
