import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

const Tooltip = ({
  name,
  content,
  type = "dark",
  effect = "solid",
  place = "top",
  children,
  className,
}) => {
  return (
    <div className={className}>
      <a data-tip data-for={name}>
        {children}
      </a>
      <ReactTooltip id={name} type={type} effect={effect} place={place} multiline={true}>
        <Content>{content}</Content>
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;


const Content = styled.div`
color: #fff;
`;