import React, { useState } from 'react';
import styled from 'styled-components';
import StatusPill from './StatusPill';
import { getShortenString, truncateText } from './utils';
import Button from './Button';
import moment from 'moment';
import AvatarImg from "./assets/avatar.svg";
import CollapseIcon from './assets/angle-down.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import Gallery from './Gallery';

const VisitListItem = ({
  visitData: {
    _id,
    paid,
    state,
    start,
    cost,
    room,
    description,
    services,
    discount,
    medicalVisit: { documents = [], documentsNumber = 0 } = {},
    healthcenterId: { name: healthcenterName = 'Brak placówki', services: healthcenterServices, rooms }
  },
  visitData,
  doctor: { userId: { _id: doctorUserId, name: docName = 'Brak doktora', surname: docSurname, avatar: docAvatar = '' } = {} },
  updateVisit,
  newHealthcenterName,
  user,
  history,
  addActiveVisit
}) => {
  const [collapsed, setCollapsed] = useState(true),
    [servicesList, setServicesList] = useState(false),
    momentStart = moment(start);
  const pdfs = documents?.filter(
    ({ path }) => !path.includes("photos")
  ),
    galleryImages = documents?.filter(({ path }) =>
      path.includes("photos")
    ).map(({ path, _id, originalName }) => ({
      name: originalName,
      src: `${process.env.REACT_APP_SRV_URL}${path}`,
      _id,
    }));

  return (
    <VisitItem key={_id}>
      <VisitItemTop collapsed={collapsed}>
        <Doctor>
          <DocAvatar src={!docAvatar ? AvatarImg : `${process.env.REACT_APP_SRV_URL}${docAvatar}`} />
          <DocName>
            {docName} {docSurname}
          </DocName>
        </Doctor>
        <DateTime>
          <StyledCalendarIcon variant='black' />
          {momentStart.format('DD.MM.YYYY HH:mm')}
        </DateTime>
        <PillWrapper>
          {state === 'PLANNED' ?
            <StatusPill type='default'>Niepotwierdzona</StatusPill>
            : state === 'CONFIRMED' ?
              <StatusPill type="positive">Potwierdzona</StatusPill>
              : state === 'WAITING' ?
                <StatusPill type="yellow">W poczekalni</StatusPill>
                : state === 'STARTED' ?
                  <StatusPill type="positive">W trakcie</StatusPill>
                  : state === 'ENDED' ?
                    <StatusPill type="positive">Zakończona</StatusPill>
                    : state === 'REJECTED' ?
                      <StatusPill type="negative">Odrzucona</StatusPill>
                      : ''
          }
        </PillWrapper>
        <PillWrapper>
          {paid ?
            <StatusPill type="positive">Opłacona</StatusPill>
            :
            <StatusPill type="negative">Nieopłacona</StatusPill>
          }
        </PillWrapper>
        <CollapseBtn collapsed={collapsed} onClick={() => setCollapsed(prev => !prev)} />
      </VisitItemTop>
      <VisitItemDetails collapsed={collapsed}>
        <VisitItemDetailsBody >
          <DataBox
            label="Kwota"
            imgActive={servicesList}
            labelOnClick={((user.activeRole === 'Doctor' || user.activeRole === 'Nurse') && state === 'ENDED') ? () => setServicesList(prev => !prev) : null}
          >
            {(user.activeRole === 'Registry' && state === 'ENDED') && <Text>{`${cost} zł`}</Text>}
            {(user.activeRole === 'Doctor' || user.activeRole === 'Nurse') && (
              state === 'ENDED' ?
                <ServicesBox active={servicesList}>
                  <List>
                    {services.length > 0 ?
                      services?.map(id => {
                        const service = healthcenterServices?.find(({ _id }) => _id === id);
                        return (
                          <ServicesItem key={_id}>
                            <span>{service?.name}</span>
                            <span>{service?.price} zł</span>
                          </ServicesItem>
                        )
                      })
                      :
                      <ServicesItem>Brak procedur</ServicesItem>
                    }
                  </List>
                  <SevicesBottom>
                    <span>Zniżka: {discount ? discount : 0} %</span>
                    <span>{cost} zł</span>
                  </SevicesBottom>
                </ServicesBox>
                :
                <Text>Jeszcze się nie zakończyła</Text>
            )}
          </DataBox>
          <DataBox label='Placówka'>
            <Text>{healthcenterName === 'Brak placówki' ? newHealthcenterName : healthcenterName}</Text>
          </DataBox>
          <DataBox label='Gabinet'>
            <Text>{rooms?.find(el => el._id === room)?.name}</Text>
          </DataBox>
          <DataBox label='Opis'>
            <Text>{description ? truncateText(description,60) : 'Brak'}</Text>
          </DataBox>
        </VisitItemDetailsBody>
        {state === 'ENDED' && <VisitItemDetailsBody>
          <DataBox label={(user.activeRole === 'Doctor' || user.activeRole === 'Nurse') ? 'Dokumenty' : 'Załączone pliki'}>
            {(user.activeRole === 'Doctor' || user.activeRole === 'Nurse') ?
              <List>
                {pdfs.length > 0 ? pdfs.map(({ originalName, path, _id }) => (
                  <DocLink
                    key={_id}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_SRV_URL}${path}`}
                  >
                    {getShortenString(originalName, 35)}
                  </DocLink>
                )) :
                  <NoItems>Brak</NoItems>
                }
              </List>
              :
              <NoItems>{documentsNumber}</NoItems>
            }
          </DataBox>
          <DataBox label='Recepty'>
            <List>
              {[].length > 0 ? [].map(({ originalName, path }) => (
                <DocLink
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_SRV_URL}${path}`}
                >
                  {getShortenString(originalName, 35)}
                </DocLink>
              )) :
                <NoItems>Brak</NoItems>
              }
            </List>
          </DataBox>
          {(user.activeRole === 'Doctor' || user.activeRole === 'Nurse') && <DataBox label='Zdjęcia' width="50%">
            {galleryImages.length > 0 ? <Gallery
              images={galleryImages}
              variant="dashboard"
            />
              :
              <NoItems>Brak</NoItems>
            }
          </DataBox>}
        </VisitItemDetailsBody>}
        <VisitItemDetailsActions>
          {((user.activeRole === 'Doctor' || user.activeRole === 'Nurse') && state === 'CONFIRMED') &&
            <Button onClick={() => {
              updateVisit(_id, 'started');
              history.push(`/visit/${_id}/`);
              addActiveVisit(visitData)
            }} variant="primary">Rozpocznij wizytę</Button>
          }
          {((user.activeRole === 'Doctor' || user.activeRole === 'Nurse') && state === 'STARTED') && doctorUserId === user._id &&
            <Button onClick={() => {
              history.push(`/visit/${_id}/`);
            }} variant="primary">Kontynuuj wizytę</Button>
          }
          {((user.activeRole === 'Doctor' || user.activeRole === 'Nurse') && state === 'ENDED') &&
            <Button onClick={() => {
              history.push(`/visit/${_id}/`);
            }} variant="secondary">Zobacz wizytę</Button>
          }
          {(user.activeRole === 'Registry' && state === 'CONFIRMED') &&
            <Button onClick={() => updateVisit(_id, 'waiting')} variant="primary">Pacjent w poczekalni</Button>
          }
          {(state === 'PLANNED' || state === 'REJECTED') &&
            <Button onClick={() => updateVisit(_id, 'confirmed')} variant="secondary">Potwierdź wizytę</Button>
          }
          {(state !== 'REJECTED' && state !== 'ENDED' && state !== 'STARTED') &&
            <Button onClick={() => updateVisit(_id, 'rejected')} variant="danger">Odwołaj wizytę</Button>
          }
          {(state === 'ENDED' && !paid) &&
            <Button onClick={() => updateVisit(_id, 'ended', { paid: true })} variant="primary">Opłacono wizytę</Button>
          }

        </VisitItemDetailsActions>
      </VisitItemDetails>
    </VisitItem>
  )
}

export default VisitListItem;

const DataBox = ({ label, children, labelOnClick, imgActive, width }) => {
  return (
    <DataBoxWrapper width={width}>
      <DataBoxLabel onClick={labelOnClick}>
        {label}{labelOnClick && <StyledImg active={imgActive} src={CollapseIcon} />}
      </DataBoxLabel>
      <DataBoxBody>
        {children}
      </DataBoxBody>
    </DataBoxWrapper>
  )
}

const VisitItem = styled.li`
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  margin:10px 0;
`;

const VisitItemTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding:10px 30px;
  transition:300ms;
  border-bottom: 1px solid ${({ theme, collapsed }) => collapsed ? 'transparent' : theme.grey};
  >div{
    width:25%;
    display:flex;
    justify-content:center;
  }
`;

const VisitItemDetails = styled.div`
  max-height:0;
  overflow:hidden;
  padding:0;
  transition:600ms;
  border-top: 1px solid #fff;
  ${({ collapsed }) =>
    !collapsed && `
        border-color: ${({ theme }) => theme.grey};
        max-height:559px;
        padding-bottom: 10px;
    `
  }
`;

const PillWrapper = styled.div``;

const VisitItemDetailsBody = styled.div`
  display:flex;
  flex-wrap:wrap;
  padding: 15px 20px;
  /* &:nth-child(2){
    border-top:1px solid ${({ theme }) => theme.grey};
  } */
    border-bottom:1px solid ${({ theme }) => theme.grey};
  & > p:first-child{
    text-align:left;
  }
`;

const VisitItemDetailsActions = styled.div`
  display:flex;
  justify-content:flex-end;
  padding-top:10px;
  >button{
    margin: 0 12px;
  }
`;

const Text = styled.p`
  text-align:center;
  word-break: break-word;
`;

const ServicesBox = styled.div`
  width:180px;
  margin:0 auto;
  position:relative;
  ul{
    max-height:0;
    overflow:hidden;
    transition:300ms;
  }
  >div{
    border-top:0;
    position:relative;
    transition:300ms;
    span{
      font-size:16px;
      position:absolute;
      right:0;
      left:0;
      bottom:3px;
      width:60px;
      margin:auto;
      transition:300ms;
      &:first-child{
        opacity:0;
        width:85px;
        z-index:-1;
      }
      &:last-child{
        text-align:center;
      }
    }
  }
  ${({ active }) => active && `
    ul{
      max-height:100px;
    }
    >div{
      border-top:1px solid #ccc;
      span{
        font-size:14px;
        left:65%;
        text-align:left;
        &:first-child{
          opacity:1;
          z-index:1;
          left:0;
          right:65%;
        }
        &:last-child{
          text-align:right;
        }
      }
    }
  `}
`;

const List = styled.ul``;

const ServicesItem = styled.li`
  display:flex;
  justify-content:space-between;
  font-size:12px;
  padding:5px;
`;

const SevicesBottom = styled.div`
  display:flex;
  padding: 5px;
  height:22.5px;
  span{
    font-size:14px;
  }
`;

const DateTime = styled.div`
  display:flex;
  align-items:center;
  svg{
    transform: translateY(-2px);
  }
`;

const Doctor = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start!important;
`;

const DocAvatar = styled.img`
  border-radius: 50%;
  min-width: 40px;
  width:40px;
  height:40px;
  margin-right: 10px;
`;

const DocName = styled.p`
  font-size:18px;
`;

const CollapseBtn = styled.button`
  background-color:#f0f5ff;
  border: none;
  border-radius: 50%;
  width:33px;
  height:33px;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  transition:600ms;
  ${({ collapsed }) =>
    !collapsed && `transform:rotate(180deg);`
  }
  &:hover{
    background-color: #e6ebf5;
  }
  &::before{
    content: url(${CollapseIcon});
    width:16px;
    line-height: 0;
    height:14px;
    filter: invert(46%) sepia(76%) saturate(2961%) hue-rotate(190deg) brightness(100%) contrast(102%);
  }
`;

const StyledImg = styled.img`
  width:15px;
  height:10px;
  transition:300ms;
  ${({ active }) => active ? `
    transform: rotate(180deg);
  `
    :
    `
    transform: rotate(0);
  `}
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 3px;
  path{fill:${({ theme }) => theme.lightBlack}};
`;

const DataBoxWrapper = styled.div`
  padding: 0 10px;
  width: ${({ width }) => width ? width : '25%'};
`;

const DataBoxLabel = styled.div`
  font-weight:700;
  margin-bottom:5px;
  font-size: 14px;
  text-align:center;
  ${({ onClick }) => onClick && 'cursor:pointer;'}
`;

const DataBoxBody = styled.div``;

const DocLink = styled.a`
  font-size:12px;
  color:#000;
  text-decoration:none;
  transition:300ms;
  display:inline-block;
  &:hover{
    color:${({ theme }) => theme.primary};
  }
`;

const NoItems = styled.p`
  font-size:12px;
  text-align:center;
`;