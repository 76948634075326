import React from "react";
import styled from 'styled-components';
import DeleteModal from './modals/DeleteModal';
import { Tabs } from '../../Shared/Tabs';
import PatientInfoHeader from "../../Shared/PatientInfoHeader";
import VisitsTabContainer from "../DoctorPatientInfo/Tabs/Visits/VisitsTabContainer";

const ReceptionistPatientInfoView = ({
  currentPatient,
  deletePatientHandler,
  deleteModalState,
  setDeleteModalState
}) => {
  return (
    <Wrapper>
      <DeleteModal
        modalState={deleteModalState}
        setModalState={setDeleteModalState}
        onAccept={deletePatientHandler}
      />
      {currentPatient?._id ? (
        <>
          <PatientInfoHeader
            patientInfo={currentPatient}
          />
          <TabsWrapper>
            <Tabs header={[
              {
                label: "Historia wizyt",
                id: "visits"
              }
            ]}>
              <VisitsTabContainer
                key="VisitTab"
              />
            </Tabs>
          </TabsWrapper>

        </>) :
        <ChoosePatient>Wybierz pacjenta lub dodaj go za pomocą panelu po lewej</ChoosePatient>
      }
    </Wrapper >
  )
};

export default ReceptionistPatientInfoView;

const Wrapper = styled.div`
  width: 100%;
`;

const ChoosePatient = styled.div`
  text-align: center;
  padding: 100px 0;
  font-weight: 600;
  font-size: 24px;
  color: ${({ theme }) => theme.primary};
`;


const TabsWrapper = styled.div`
  height: calc(100vh - 172px);
`;
