import React from 'react';
import styled from 'styled-components';
import Input from '../../Shared/Input';
import Select from '../../Shared/Select';
import Textarea from '../../Shared/Textarea';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { units, patternControlTypes, elementsCategories } from '../../Shared/enums';
import Button from '../../Shared/Button';
import Dropdown from '../../Shared/Dropdown';
import DraftEditor from './DraftEditor';
import useDebouncer from '../../Shared/Hooks/useDebouncer';

import rejectIcon from '../../Shared/assets/rejected-icon.svg';
import { ReactComponent as MoreIcon } from '../../Shared/assets/more.svg';
import listIcon from '../../Shared/assets/list.svg';
import radioIcon from '../../Shared/assets/radio.svg';
import checkboxIcon from '../../Shared/assets/checkbox.svg';
import numericIcon from '../../Shared/assets/numeric.svg';
import calendarIcon from '../../Shared/assets/calendar.svg';
import timeIcon from '../../Shared/assets/time.svg';
import { ReactComponent as CopyIcon } from '../../Shared/assets/copy.svg';
import { ReactComponent as AddIcon } from '../../Shared/assets/plus-circle-filled.svg';
import { ReactComponent as SaveIcon } from '../../Shared/assets/save.svg';

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
        if (list) {
            list.forEach(({ name }, index) => {
                const subList = list.filter((_, i) => i !== index);
                if (subList.findIndex(el => el.name === name) !== -1) {
                    throw this.createError({
                        path: `${this.path}[${index}].name`,
                        message,
                    });
                }
            })
        }
        return true;
    });
});

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Nazwa musi mieć minimum 3 znaki.')
        .required("Nazwa jest wymagana."),
    selectOptions: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string()
                    .min(3, 'Nazwa musi mieć minimum 3 znaki.')
                    .required("Nazwa jest wymagana."),
            })
        )
        .unique('Nazwa musi być unikalna.', a => a.name),
    checkboxOptions: Yup.object().shape({
        options: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string()
                        .min(3, 'Nazwa musi mieć minimum 3 znaki.')
                        .required("Nazwa jest wymagana."),
                })
            )
            .unique('Nazwa musi być unikalna.', a => a.name),
    })
});

const SettingsPanel = ({
    currentElement,
    removeControlHandler,
    changeValuesHandler,
    currentControl: { obj: controlObj, i: controlIndex },
    setCurrentControl,
    currentSelectType,
    setCurrentSelectType,
    currentTab,
    generatedText,
    cloneControlHandler,
    saveFormControlHandler,
    doctorsFormControls,
    elementError,
}) => {
    const [setDebounce] = useDebouncer();
    const datePickerFormats = [
        { value: 'normal', label: 'Pełna data' },
        { value: 'daysFromNow', label: 'Ilość dni od dziś' },
        { value: 'weeks', label: 'Ilość tygodnii i miesięcy od dziś' },
    ],
        timePickerFormats = [
            { value: 'normal', label: 'Pełna godzina' },
            { value: 'timeFromNow', label: 'Czas od teraz' },
        ],
        datePickerEmptyFieldFormats = [
            { value: 'normal', label: 'Dzisiejsza data' },
            { value: 'custom', label: 'Własny tekst' },
        ],
        optionsFormats = [
            { value: 'normal', label: 'Teksty takie jak nazwy opcji' },
            { value: 'custom', label: 'Własne teksty' },
        ],
        stageOptions = [
            { value: 'WYWIAD', label: 'Wywiad' },
            { value: 'BADANIA FIZYKALNE', label: 'Badania fizykalne' },
            { value: 'ZALECENIA', label: 'Zalecenia' },
        ],
        categoryOptons = elementsCategories.map(({ name, subcategories }) => ({ value: name, label: name, firstSubcategories: subcategories[0] })),
        currentSubcategories = elementsCategories.find(({ name }) => name === currentElement.category)?.subcategories ?? [],
        subcategoryOptons = currentSubcategories.map((subcat) => ({ value: subcat, label: subcat })),
        unitsOptions = [{ label: 'Brak', value: '' }, ...units.map(unit => ({ label: unit, value: unit }))],
        typesOptions = patternControlTypes.map(({ type, title }) => ({ label: title, value: type }));

    return (
        <Wrapper>
            <Content id='settingsPanel'>
                {(currentElement && currentTab === 0) ?
                    <Formik
                        enableReinitialize
                        initialValues={currentSelectType === 'element' ? { ...currentElement } : { ...controlObj }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            changeValuesHandler(values, currentSelectType)
                        }}
                    >
                        {({ values, errors, handleChange, handleSubmit, setFieldValue, setFieldError }) => (
                            <Form onSubmit={handleSubmit}>
                                <NameRow>
                                    <NameInput
                                        type="text"
                                        name="name"
                                        label={currentSelectType === 'element' ? 'Nazwa elementu' : 'Nazwa pola'}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setDebounce(() => {
                                                handleSubmit();
                                            }, 300)
                                        }}
                                        value={values?.name}
                                        error={elementError ? elementError : errors.name}
                                    />
                                </NameRow>
                                {currentSelectType === 'control' &&
                                    <StyledSelect
                                        menuPosition='fixed'
                                        label="Typ pola"
                                        options={typesOptions}
                                        value={typesOptions.find(({ value }) => value === values.formType)}
                                        name='formType'
                                        onChange={({ value }) => {
                                            const current = values.formType;
                                            if ((current === 'radio' || current === 'select') && value === 'checkbox') {
                                                setFieldValue(
                                                    `checkboxOptions.options`,
                                                    values.selectOptions.map(({ name, text }) => ({ name, text, value: false }))
                                                );
                                            }
                                            else if ((value === 'radio' || value === 'select') && current === 'checkbox') {
                                                setFieldValue(
                                                    `selectOptions`,
                                                    values.checkboxOptions.options.map(({ name, text }) => ({ name, text }))
                                                );
                                            }
                                            setFieldValue(`formType`, value);
                                            handleSubmit();
                                        }}
                                    />
                                }
                                {currentSelectType === 'element' ?
                                    <>
                                        <StyledSelect
                                            menuPosition='fixed'
                                            label='Etap'
                                            options={stageOptions}
                                            value={stageOptions.find(({ value }) => value === values?.stage)}
                                            onChange={({ value }) => {
                                                setFieldValue(`stage`, value);
                                                handleSubmit();
                                            }}
                                        />
                                        <StyledSelect
                                            menuPosition='fixed'
                                            label='Kategoria'
                                            options={categoryOptons}
                                            value={categoryOptons.find(({ value }) => value === values?.category)}
                                            onChange={({ value, firstSubcategories }) => {
                                                if (value !== values.category) {
                                                    setFieldValue(`category`, value);
                                                    setFieldValue(`subcategory`, firstSubcategories);
                                                    handleSubmit();
                                                }
                                            }}
                                        />
                                        <StyledSelect
                                            menuPosition='fixed'
                                            label='Podkategoria'
                                            options={subcategoryOptons}
                                            value={subcategoryOptons.find(({ value }) => value === values?.subcategory)}
                                            onChange={({ value }) => {
                                                setFieldValue(`subcategory`, value);
                                                handleSubmit();
                                            }}
                                        />
                                        {currentElement?.formControls?.length > 0 && <>
                                            <Label>Pola elementu</Label>
                                            <FieldList>
                                                {currentElement.formControls.map(({ name, formType }, index) => {
                                                    const icon = formType === 'select' ? listIcon :
                                                        formType === 'radio' ? radioIcon :
                                                            formType === 'checkbox' ? checkboxIcon :
                                                                formType === 'input' ? numericIcon :
                                                                    formType === 'datepicker' ? calendarIcon :
                                                                        formType === 'timepicker' ? timeIcon : ``;

                                                    return (
                                                        <FieldItem
                                                            key={`${formType}_${index}`}
                                                            onClick={() => {
                                                                setCurrentControl(prev => ({ ...prev, i: index }));
                                                                setCurrentSelectType('control')
                                                            }}
                                                            active={controlIndex === index && currentSelectType === 'control'}
                                                        >
                                                            <ItemImg
                                                                bgImg={icon}
                                                                correctSize={formType === 'timepicker' || formType === 'datepicker'}
                                                            />
                                                            <Texts>
                                                                <Title>{patternControlTypes.find(({ type }) => type === formType).title}</Title>
                                                                <Subtitle>{name}</Subtitle>
                                                            </Texts>
                                                            <RoundDeleteBtn
                                                                type='button'
                                                                title="Usuń pole"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    removeControlHandler(index)
                                                                }}
                                                            />
                                                            {currentElement.formControls.length < 6 && <CopyBtn
                                                                type='button'
                                                                title="Kopiuj pole"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    cloneControlHandler(index);
                                                                }}
                                                            />}
                                                        </FieldItem>
                                                    )
                                                })}
                                            </FieldList>
                                        </>}
                                    </>
                                    :
                                    <>
                                        {values?.formType !== 'input' &&
                                            <StyledSelect
                                                menuPosition='fixed'
                                                label='Format wyświetlania'
                                                name='displayFormat'
                                                options={values?.formType === 'datepicker' ?
                                                    datePickerFormats
                                                    :
                                                    values?.formType === 'timepicker' ?
                                                        timePickerFormats
                                                        :
                                                        optionsFormats
                                                }
                                                value={
                                                    values?.formType === 'datepicker' ?
                                                        datePickerFormats.find(({ value }) => value === values.displayFormat)
                                                        :
                                                        values?.formType === 'timepicker' ?
                                                            timePickerFormats.find(({ value }) => value === values.displayFormat)
                                                            :
                                                            optionsFormats.find(({ value }) => value === values.displayFormat)
                                                }
                                                onChange={({ value }) => {
                                                    setFieldValue(`displayFormat`, value);
                                                    handleSubmit();
                                                }}
                                            />
                                        }
                                        {(values?.formType === 'select' || values?.formType === 'radio') &&
                                            (values?.selectOptions.length > 0 ?
                                                <>
                                                    <Label>Opcje wyboru</Label>
                                                    {values?.selectOptions && values?.selectOptions.map((option, index) => (
                                                        <OptionItem key={`option_${index}`}>
                                                            <OptionIndex>{index + 1}.</OptionIndex>
                                                            <div>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Nazwa opcji"
                                                                    name={`selectOptions[${index}].name`}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        setDebounce(() => {
                                                                            handleSubmit();
                                                                        }, 300)
                                                                    }}
                                                                    value={values?.selectOptions[index]?.name}
                                                                    error={errors?.selectOptions && errors?.selectOptions[index]?.name}
                                                                // hideError={true}
                                                                />
                                                                {values?.displayFormat === 'custom' &&
                                                                    <OptionTextarea
                                                                        name={`selectOptions[${index}].text`}
                                                                        label="Tekst opcji"
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            setDebounce(() => {
                                                                                handleSubmit();
                                                                            }, 300)
                                                                        }}
                                                                        value={values?.selectOptions[index]?.text}
                                                                    />
                                                                }
                                                            </div>
                                                            <RoundDeleteBtn
                                                                type='button'
                                                                title='Usuń opcję'
                                                                onClick={() => {
                                                                    setFieldValue(`selectOptions`, [...values.selectOptions].filter((el, i) => i !== index));
                                                                    handleSubmit();
                                                                }}
                                                            />
                                                            {(index + 1 === values.selectOptions.length && (values.selectOptions.length < 9 || values?.formType === 'select')) &&
                                                                <AddBtn
                                                                    type='button'
                                                                    title='Dodaj opcję'
                                                                    onClick={() => {
                                                                        setFieldValue(`selectOptions`, [...values.selectOptions, { name: `Opcja ${values.selectOptions.length + 1}`, text: '' }]);
                                                                        handleSubmit();
                                                                    }}
                                                                />
                                                            }
                                                        </OptionItem>
                                                    ))}
                                                </>
                                                :
                                                <Button
                                                    variant='primary'
                                                    onClick={() => {
                                                        setFieldValue(`selectOptions`, [...values.selectOptions, { name: `Opcja ${values.selectOptions.length + 1}`, text: '' }]);
                                                        handleSubmit();
                                                    }}
                                                >Dodaj opcje wyboru</Button>)
                                        }
                                        {(values.formType === 'checkbox' && values.displayFormat === 'custom' && values.checkboxOptions.options.length === 0) &&
                                            <>
                                                <OptionTextarea
                                                    name="text"
                                                    label="Jeśli zaznaczony"
                                                    name='checkboxOptions.ifTrue'
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setDebounce(() => {
                                                            handleSubmit();
                                                        }, 300)
                                                    }}
                                                    value={values?.checkboxOptions.ifTrue}
                                                />
                                                <OptionTextarea
                                                    name="text"
                                                    label="Jeśli odznaczony"
                                                    name='checkboxOptions.ifFalse'
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setDebounce(() => {
                                                            handleSubmit();
                                                        }, 300)
                                                    }}
                                                    value={values?.checkboxOptions.ifFalse}
                                                />
                                            </>
                                        }
                                        {(values?.formType === 'checkbox') &&
                                            (values?.checkboxOptions.options.length > 0 ?
                                                <>
                                                    <Label>Opcje wyboru</Label>
                                                    {values?.checkboxOptions && values?.checkboxOptions.options.map((option, index) => (
                                                        <OptionItem key={`option_${index}`}>
                                                            <span>{index + 1}.</span>
                                                            <div>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Nazwa opcji"
                                                                    name={`checkboxOptions.options[${index}].name`}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        setDebounce(() => {
                                                                            handleSubmit();
                                                                        }, 300)
                                                                    }}
                                                                    value={values?.checkboxOptions.options[index]?.name}
                                                                    error={errors?.checkboxOptions?.options && errors?.checkboxOptions?.options[index]?.name}
                                                                />
                                                                {values?.displayFormat === 'custom' && <OptionTextarea
                                                                    name={`checkboxOptions.options[${index}].text`}
                                                                    label="Tekst opcji"
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        setDebounce(() => {
                                                                            handleSubmit();
                                                                        }, 300)
                                                                    }}
                                                                    value={values?.checkboxOptions.options[index]?.text}
                                                                />}
                                                            </div>
                                                            <RoundDeleteBtn
                                                                type='button'
                                                                onClick={() => {
                                                                    setFieldValue(`checkboxOptions.options`, [...values.checkboxOptions.options].filter((el, i) => i !== index));
                                                                    handleSubmit();
                                                                }}
                                                            />
                                                            {(index + 1 === values.checkboxOptions.options.length && values.checkboxOptions.options.length < 9) &&
                                                                <AddBtn
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setFieldValue(`checkboxOptions.options`, [...values.checkboxOptions.options, { name: `Opcja ${values.checkboxOptions.options.length + 1}`, text: '', value: false }]);
                                                                        handleSubmit();
                                                                    }}
                                                                />
                                                            }
                                                        </OptionItem>
                                                    ))}
                                                </>
                                                :
                                                <Button
                                                    variant='primary'
                                                    onClick={() => {
                                                        setFieldValue(`checkboxOptions.options`, [...values.checkboxOptions.options, { name: `Opcja ${values.checkboxOptions.options.length + 1}`, text: '' }]);
                                                        handleSubmit();
                                                    }}
                                                >Dodaj opcje wyboru</Button>)
                                        }
                                        {values?.formType === 'input' &&
                                            <>
                                                <StyledSelect
                                                    menuPosition='fixed'
                                                    label='Jednostka'
                                                    name='unit'
                                                    options={unitsOptions}
                                                    value={unitsOptions.find(({ value }) => value === values.unit)}
                                                    onChange={({ value }) => {
                                                        setFieldValue(`unit`, value);
                                                        handleSubmit();
                                                    }}
                                                />
                                                <StyledInput
                                                    label='Wartość domyślna'
                                                    name='defaultValue'
                                                    value={values?.defaultValue}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setDebounce(() => {
                                                            handleSubmit();
                                                        }, 300)
                                                    }}
                                                />
                                                {values?.rangeDictionary?.length > 0 ? <>
                                                    <Label>Funkcje warunkowe</Label>
                                                    {values?.rangeDictionary.map((dictionary, index) => (
                                                        <OptionItem key={`dictionary_${index}`}>
                                                            <span>{index + 1}.</span>
                                                            <div>
                                                                <RangeRow>
                                                                    <Input
                                                                        placeholder="Większy/równy"
                                                                        type="text"
                                                                        name={`rangeDictionary[${index}].lowerRange`}
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            setDebounce(() => {
                                                                                handleSubmit();
                                                                            }, 300)
                                                                        }}
                                                                        value={values?.rangeDictionary[index]?.lowerRange}
                                                                        hideError={true}
                                                                    />
                                                                    <Input
                                                                        placeholder="Mniejszy"
                                                                        type="text"
                                                                        name={`rangeDictionary[${index}].upperRange`}
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            setDebounce(() => {
                                                                                handleSubmit();
                                                                            }, 300)
                                                                        }}
                                                                        value={values?.rangeDictionary[index]?.upperRange}
                                                                        hideError={true}
                                                                    />
                                                                </RangeRow>
                                                                <DraftEditor
                                                                    name={`rangeDictionary[${index}].text`}
                                                                    placeholder="Generowany tekst gdy warunek jest spełniony, dla wartości użyj {pole} z panelu poniżej"
                                                                    label="Tekst opcji"
                                                                    height='75px'
                                                                    bottomPanelLabel='Pola elementu'
                                                                    onChange={(value) => {
                                                                        setFieldValue(`rangeDictionary[${index}].text`, value)
                                                                        handleSubmit();
                                                                    }}
                                                                    value={values?.rangeDictionary[index]?.text}
                                                                    parameters={[{ tagName: 'pole' }]}
                                                                    bottomPanelLabel={'Dodaj wartość pola:'}
                                                                />
                                                            </div>
                                                            <RoundDeleteBtn
                                                                type='button'
                                                                onClick={() => {
                                                                    setFieldValue(`rangeDictionary`, [...values.rangeDictionary].filter((el, i) => i !== index));
                                                                    handleSubmit();
                                                                }}
                                                            />
                                                            {index + 1 === values.rangeDictionary.length &&
                                                                <AddBtn
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setFieldValue(
                                                                            `rangeDictionary`,
                                                                            [...values.rangeDictionary,
                                                                            { lowerRange: ``, upperRange: ``, text: '' }
                                                                            ]
                                                                        );
                                                                    }}
                                                                />
                                                            }
                                                        </OptionItem>
                                                    ))}
                                                </>
                                                    :
                                                    <Button
                                                        variant='primary'
                                                        onClick={() => {
                                                            setFieldValue(
                                                                `rangeDictionary`,
                                                                [...values.rangeDictionary,
                                                                { lowerRange: ``, upperRange: ``, text: '' }
                                                                ]
                                                            );
                                                            handleSubmit();
                                                        }}
                                                    >Dodaj funkcje warunkowe</Button>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {currentSelectType === 'control' &&
                                    <ActionDropdown
                                        className='fieldDropdown'
                                        headerComponent={(toggleMenu) => <StyledMoreIcon title='Więcej...' onClick={toggleMenu} />}
                                        closeOnClick={true}
                                        width="100px;"
                                    >
                                        <ActionItem
                                            disabled={doctorsFormControls.findIndex(el => el.name === values.name) !== -1}
                                            onClick={() => {
                                                if (doctorsFormControls.findIndex(el => el.name === values.name) !== -1) {
                                                    setFieldError('name', 'Pole o takiej nazwie jest już zapisane!');
                                                } else {
                                                    saveFormControlHandler(controlIndex)
                                                }
                                            }}
                                        >Zapisz pole</ActionItem>
                                        <ActionItem
                                            disabled={currentElement?.formControls.length >= 6}
                                            onClick={() => {
                                                if (currentElement?.formControls.length >= 6) {
                                                    setFieldError('name', 'Osiągnieto maksymalną ilość pól na parametr!');
                                                } else {
                                                    cloneControlHandler(controlIndex)
                                                }
                                            }}
                                        >Skopiuj pole</ActionItem>
                                        <ActionItem
                                            onClick={() => removeControlHandler(controlIndex)}
                                        >Usuń pole</ActionItem>
                                    </ActionDropdown>
                                }
                                {currentSelectType === 'element' &&
                                    <>
                                        <Label>Generowany tekst</Label>
                                        {(values.text || values.text === '') && <DraftEditor
                                            name="text"
                                            label="Generowany tekst"
                                            onChange={(value) => {
                                                setFieldValue('text', value)
                                                handleSubmit();
                                            }}
                                            placeholder="Wpisz wzór tekstu wraz z dostępnymi wartościami pól z menu poniżej"
                                            currentTab={currentTab}
                                            value={values.text}
                                            parameters={currentElement?.formControls.map(({ name }) => ({ tagName: name }))}
                                            loadIndexes={`0`}
                                        />}
                                    </>
                                }
                                {(currentSelectType === 'control' && values?.formType === 'datepicker') &&
                                    <>
                                        <StyledSelect
                                            menuPosition='fixed'
                                            name='emptyFieldFormat'
                                            label="Gdy pole puste"
                                            value={datePickerEmptyFieldFormats.find(({ value }) => value === values?.emptyFieldFormat)}
                                            onChange={({ value }) => {
                                                setFieldValue('emptyFieldFormat', value);
                                                handleSubmit();
                                            }}
                                            options={datePickerEmptyFieldFormats}
                                        />
                                        {values?.emptyFieldFormat === 'custom' &&
                                            <OptionTextarea
                                                name={`emptyFieldText`}
                                                label="Tekst gdy pole jest puste"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setDebounce(() => {
                                                        handleSubmit();
                                                    }, 300)
                                                }}
                                                value={values?.emptyFieldText}
                                            />
                                        }
                                    </>
                                }

                            </Form>
                        )}
                    </Formik>
                    :
                    <>
                        <Label>Generowany tekst</Label>
                        <GeneratedText
                            dangerouslySetInnerHTML={{ __html: generatedText.replace(/&nbsp;/g, ' ') }}
                        />
                    </>
                }
            </Content>
        </Wrapper >
    )
}

export default SettingsPanel;

const Wrapper = styled.div`
    position:relative;
    margin-top:35px;
    height:calc(100vh - 260px);
    max-height:100%;
    overflow-y:auto;
`;

const Content = styled.div`
    background-color:#fff;
    border: 2px solid ${({ theme }) => theme.grey};
    border-radius:10px;
    padding:13px;
`;

const Form = styled.form`
    max-height:calc(100vh - 290px);
    overflow-y:auto;
    padding:0;
    ${({ theme }) => `${theme.mq.large}{
        padding:0 13px;
    }`}
`;

const Label = styled.p`
    margin-left: 2px;
    display: block;
    color: ${({ theme }) => theme.lightBlack};
    font-size: 14px;
`;

const NameRow = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-right:10px;
    >div{
        width:220px;
    }
`;

const RangeRow = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:10px;
`;

const FieldList = styled.ul`
    margin:10px 0 20px;
`;

const FieldItem = styled.li`
    display:flex;
    align-items:center;
    margin: 6px 0;
    position:relative;
    border-radius:5px;
    padding:6px;
    border: 2px solid transparent;
    cursor: pointer;
    transition:300ms;
    ${({ active, theme }) => active && `
        border-color:${theme.primary};
    `}
    &:hover{
        border-color:${({ theme }) => theme.primary};
    }
`;

const Texts = styled.div`
    width:60%;
`;

const Title = styled.p`
    color:${({ theme }) => theme.lightBlack};
`;

const Subtitle = styled.span`
    font-size:12px;
    color:#7a7a7a;
`;

const ItemImg = styled.div`
    width:40px;
    height:40px;
    border-radius:5px;
    background-color:${({ theme }) => theme.grey};
    background-image:url(${({ bgImg }) => bgImg});
    background-position:center;
    background-repeat:no-repeat;
    margin-right:10px;
    ${({ correctSize }) => correctSize && 'background-size:17.5px;'};
`;

const DeleteBtn = styled.button`
    height:26px;
    width:26px;
    position:relative;
    background-color:#d9d9d9;
    background-image: url(${rejectIcon});
    background-position:center;
    background-size:14px;
    background-repeat:no-repeat;
    border:none;
    border-radius:5px;
    cursor:pointer;
    transition:300ms;
    &:hover{
        background-color:${({ theme }) => theme.danger};
    }
`;

const RoundDeleteBtn = styled.button`
    height:20px;
    width:20px;
    background-color:#d9d9d9;
    background-image: url(${rejectIcon});
    background-position:center;
    background-size:10px;
    background-repeat:no-repeat;
    border:none;
    border-radius:50%;
    cursor:pointer;
    position:absolute;
    right:6px;
    transition:300ms;
    &:hover{
        background-color:${({ theme }) => theme.danger};
    }
`;

const CopyBtn = styled(CopyIcon)`
    width:17.5px;
    height:17.5px;
    cursor:pointer;
    position:absolute;
    right:30px;
    ${({ size }) => size === 'big' && `
        position:relative;
        width:22.5px;
        height:22.5px;
        right:0;
    `}
    path {
        transition:300ms;
    }
    &:hover path{
        fill:${({ theme }) => theme.primary};
    }
`;

const SaveBtn = styled(SaveIcon)`
    width:20px;
    height:20px;
    cursor:pointer;
    path, rect{
        fill:#ccc;
        transition:300ms;
    } 
    &:hover{
        path, rect{
            fill:${({ theme }) => theme.primary};
        }
    }
`;

const OptionItem = styled.div`
    display:flex;
    position:relative;
    margin-top:5px;
    padding-right:45px;
    max-width:235px;
    >div{
        margin-left:10px;
        width: 100%;
    }
    >span{
        font-size:14px;
        margin-top:13px;
        color:${({ theme }) => theme.lightBlack};
    }
    >button{
        top:10px;
        right:20px;
    }
    ${({ theme }) => `${theme.mq.desktop}{
        max-width:265px;
    }`}
`;

const OptionTextarea = styled(Textarea)`
    margin-top:5px;
    textarea{
        min-height:65px;
    }
`;

const AddBtn = styled(AddIcon)`
    cursor:pointer;
    position:absolute;
    right:-13px;
    top:3px;
    path{
        fill:${({ theme }) => theme.primary};
    }
`;

const StyledSelect = styled(Select)`
    max-width:220px;
`;

const StyledInput = styled(Input)`
    max-width:220px;
`;

const GeneratedText = styled.div`
    width:100%;
    min-height:200px;
    border: 1px solid #D9D9D9;
    border-radius:4px;
    margin-top: 2px;
    font-size:14px;
    padding:7.5px;
    color:#BDBDBD;
    b{
        color:${({ theme }) => theme.primary};
    }
`;

// const Actions = styled.div`
//     padding-top:20px;
//     display:flex;
//     align-items:center;
//     flex-direction: column;
//     position: absolute;
//     right: 17px;
//     top: 60px;
//     height: 77px;
//     justify-content: space-between;
// `;

const ActionDropdown = styled(Dropdown)`
    position: absolute;
    right: 7px;
    top: 38px;
    ${({ theme }) => `${theme.mq.large}{
        right: 17px;
    }`}
`;

const ActionItem = styled.div`
    padding:5px;
    transition:300ms;
    font-size:14px;
    cursor:pointer;
    font-weight:600;
    color:${({ theme }) => theme.lightBlack};
    &:hover{
        background-color:${({ theme }) => theme.grey};
    }
    &:not(:last-child){
        border-bottom:1px solid ${({ theme }) => theme.grey};
    }
    ${({ disabled }) => disabled && `
        opacity:.5;
    `}
`

const NameInput = styled(Input)`
    position:relative;
    z-index: 2;
`

const StyledMoreIcon = styled(MoreIcon)`
    width:20px;
    height:30px;
    margin-left: auto;
    display: block;
    cursor:pointer;
    path{
        transition:300ms;
        fill:#ccc;
    }
    &:hover{
        path{
            fill:${({ theme }) => theme.lightBlack};
        }
    }
`;

const OptionIndex = styled.span`
    width:20px;
    text-align:right;
    margin-left:-5px;
`;