import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Input from '../../Shared/Input';
import Select from '../../Shared/Select';
import DatePicker from '../../Shared/DatePicker';
import TimePicker from '../../Shared/TimePicker';
import moment from 'moment';
import { Formik } from 'formik';
import { generateText } from '../../Shared/utils';

const PreviewTab = ({
    currentElement,
    setGeneratedText,
}) => {
    const [initalValues, setInitialValues] = useState({});
    useEffect(() => {
        const newValues = {};
        if (currentElement.formControls) {
            const id = currentElement._id ?? Math.random().toString(36).substr(2, 9);
            newValues[id] = {
                ...currentElement,
                checked: true,
                _id: id,
                formControls: currentElement.formControls.map(({ defaultValue, formType, emptyFieldFormat, ...el }) => ({
                    ...el,
                    value: formType === 'datepicker' ? emptyFieldFormat === 'normal' ? Date.now() : '' : defaultValue,
                    formType,
                    defaultValue,
                    emptyFieldFormat,
                }))
            };
        }
        setInitialValues(newValues);
    }, [currentElement]);

    return (
        <Wrapper id='previewTabWrapper'>
            {
                currentElement?.formControls?.length > 0 ?
                    <Formik
                        enableReinitialize
                        initialValues={initalValues}
                    >
                        {({ values, handleChange, setFieldValue }) =>
                            Object.keys(values).length > 0 && Object.values(values).map((paramData, index) => (
                                <ParameterItem
                                    key={`${paramData.name}_${index}`}
                                    values={values}
                                    data={paramData}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    setGeneratedText={setGeneratedText}
                                />
                            ))
                        }
                    </Formik>
                    :
                    <NoItems>Brak pól dla tego etapu</NoItems>
            }
        </Wrapper>
    )
}

export default PreviewTab;

const ParameterItem = ({
    data: { name, _id },
    values,
    handleChange,
    setFieldValue,
    setGeneratedText,
}) => {
    setGeneratedText(generateText(values))
    const renderControl = ({ formType, name: controlName, selectOptions, checkboxOptions, unit }, controlIndex) => {
        const optionsSelect = selectOptions.map(({ name, ...el }) => ({ value: name, label: name, ...el }));
        const optionsCheckbox = checkboxOptions.options.map(({ name, value, ...el }) => ({ value, label: name, ...el }));
        switch (formType) {
            case 'select':
                return (
                    <StyledSelect
                        key={`${controlName}_${controlIndex}`}
                        menuPosition='fixed'
                        label={controlName}
                        name={`${_id}.formControls[${controlIndex}].value`}
                        options={optionsSelect}
                        value={optionsSelect.find(({ value }) => value === values[_id].formControls[controlIndex].value)}
                        onChange={({ value }) => setFieldValue(
                            `${_id}.formControls[${controlIndex}].value`,
                            value
                        )}
                    />
                )
            case 'input':
                return (
                    <StyledInput
                        key={`${controlName}_${controlIndex}`}
                        label={`${controlName} ${(unit !== '') ? `(${unit})` : ''}`}
                        name={`${_id}.formControls[${controlIndex}].value`}
                        value={values[_id].formControls[controlIndex].value}
                        onChange={handleChange}
                    />
                )
            case 'checkbox':
                return (
                    <StyledInput
                        type='checkbox'
                        variant='primary'
                        key={`${controlName}_${controlIndex} `}
                        label={values[_id].formControls[controlIndex].checkboxOptions.options.length > 0 && controlName}
                        checkboxText={`${controlName} `}
                        name={`${_id}.formControls[${controlIndex}].value`}
                        value={values[_id].formControls[controlIndex].value}
                        checkboxOptions={optionsCheckbox}
                        wrapperHeight='100px'
                        onChange={
                            values[_id].formControls[controlIndex].checkboxOptions.options.length > 0 ?
                                (index) => setFieldValue(
                                    `${_id}.formControls[${controlIndex}].checkboxOptions.options[${index}].value`,
                                    !values[_id].formControls[controlIndex].checkboxOptions.options[index].value
                                )
                                :
                                () => setFieldValue(
                                    `${_id}.formControls[${controlIndex}].value`,
                                    !values[_id].formControls[controlIndex].value
                                )
                        }
                    />
                )
            case 'radio':
                return (
                    <StyledInput
                        type='radio'
                        key={`${controlName}_${controlIndex}`}
                        label={controlName}
                        variant='primary'
                        wrapperHeight='100px'
                        name={`${_id}.formControls[${controlIndex}].value`}
                        radioOptions={optionsSelect}
                        value={values[_id].formControls[controlIndex].value}
                        onChange={(field, value) => setFieldValue(field, value)}
                    />
                )
            case 'datepicker':
                return (
                    <StyledDatePicker
                        key={`${controlName}_${controlIndex}`}
                        name={`${controlName}_${controlIndex}`}
                        label={controlName}
                        dateFormat='dd.MM.yyyy'
                        selected={values[_id].formControls[controlIndex].value}
                        onChange={(value) => setFieldValue(
                            `${_id}.formControls[${controlIndex}].value`,
                            value
                        )}
                        preventOverflow={true}
                    />
                )
            case 'timepicker':
                return (
                    <StyledTimePicker
                        key={`${controlName}_${controlIndex}`}
                        name={`${controlName}_${controlIndex}`}
                        label={controlName}
                        onChange={(value) => setFieldValue(
                            `${_id}.formControls[${controlIndex}].value`,
                            value
                        )}
                        allowEmpty={false}
                        defaultValue={moment(Date.now())}
                        value={values[_id].formControls[controlIndex].value}
                        showSecond={false}
                    />
                )
            default:
                return <p>Wrong formType</p>
        }
    }
    return (
        <Element>
            {values[_id].formControls.map((control, index) => (
                renderControl(control, index)
            ))}
        </Element>
    )
}

const StyledSelect = styled(Select)`
        max-width: 270px;
        label{
            display: block;
            margin-bottom: 2px;
        }
        opacity: 1!important;
`;

const StyledInput = styled(Input)`
        max-width: 270px;
        ${({ disabled, type }) => (disabled && type !== 'radio') && `
            opacity:1!important;
            `}
`;

const StyledDatePicker = styled(DatePicker)`
        max-width: 270px;
        input{
            ${ ({ disabled }) => disabled && `opacity:1!important;`}
        }
`;

const StyledTimePicker = styled(TimePicker)`
        max-width: 270px;
`;

const Wrapper = styled.div`
    padding:20px 10px;
    ${({ theme }) => `${theme.mq.desktop}{
        padding:20px;
    }`}
`;

const Element = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    gap:10px;
    padding:10px 20px;
`;

const NoItems = styled.p`

`;