import React, { useState, useEffect } from 'react';
import Modal from '../../Shared/Modals/Modal';
import Input from '../../Shared/Input';
import Button from '../../Shared/Button';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

const RoomsModal = ({
    roomsModalState,
    setRoomsModalState,
    selectedHealthcenterId,
    updateHealthcenter,
    rooms,
}) => {
    const [listContent, setListContent] = useState([]),
        validationSchema = Yup.object().shape({
            roomName: Yup.string()
                .test({
                    message: 'Taki gabinet już istnieje',
                    test: val => checkIfAlready(val)
                }),
        });

    const removeFromListHandler = (roomName) => {
        setListContent((prev) => (
            prev.filter(el => (
                el.name !== roomName
            ))
        ));
    },
        checkIfAlready = (val) => {
            if (listContent.findIndex((el) => el.name === val) === -1) {
                return true;
            } else {
                return false;
            }
        },
        submitHandler = () => {
            updateHealthcenter(selectedHealthcenterId, { rooms: listContent });
            setRoomsModalState(false);
        };


    useEffect(() => {
        setListContent(rooms);
    }, [rooms])

    return (
        <Modal
            show={roomsModalState}
            exitButton={true}
            onCancel={() => setRoomsModalState(false)}
            title={'Edycja gabinetów'}>
            <Wrapper>
                <Formik
                    initialValues={{ roomName: '' }}
                    validationSchema={validationSchema}
                    onSubmit={({ roomName }, { resetForm }) => {
                        if (checkIfAlready(roomName)) {
                            resetForm();
                            setListContent(prev => (
                                [...prev, { name: roomName, status: false }]
                            ))
                        }
                    }}
                >
                    {({ values, errors, handleSubmit, handleChange, handleBlur }) => (
                        <Form onSubmit={handleSubmit}>
                            <Input
                                placeholder='Nazwa gabinetu'
                                name='roomName'
                                value={values.roomName}
                                error={errors.roomName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Button type='submit'>Dodaj</Button>
                        </Form>
                    )}
                </Formik>
                <List>
                    {listContent &&
                        listContent.map(({ name }) => {
                            return <ListItem key={name}>
                                <Text>{name}</Text>
                                <Remove onClick={() => removeFromListHandler(name)} />
                            </ListItem>
                        })
                    }
                </List>
                <CenterBox>
                    <Button onClick={submitHandler} type='submit' variant='primary'>Zapisz zmiany</Button>
                </CenterBox>
            </Wrapper>
        </Modal >
    )
}

export default RoomsModal;

const Wrapper = styled.div`
    width:300px;
    padding-top:5px;
`;
const CenterBox = styled.div`
    margin-top:40px;
    text-align:center;
`;

const List = styled.ul``;

const ListItem = styled.li`
    display: flex;
    align-items:center;
    max-width:240px;
    position:relative;
    border: 1px solid ${({ theme }) => theme.grey};
    padding: 5px 10px;
    border-radius:4px;
    margin: 5px auto;
`;

const Form = styled.form`
    display:flex;
    justify-content:center;
    align-items:flex-start;
    >button{
        padding: 8.5px 15px;
        margin: 0;
        border-top-left-radius:0;
        border-bottom-left-radius:0;
        border-color:rgb(204,204,204);
        border-left:0;
    }
    & input{
        border-top-right-radius:0;
        border-bottom-right-radius:0;
        margin:0;
    }
`;

const Text = styled.p``;

const Remove = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 20px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;