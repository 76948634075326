import React from "react";
import styled from "styled-components";
import AddPlusIcon from '../Shared/assets/add-plus.svg';

import HealthcentersDetails from './HealthcentersDetails';
import HealthcentersList from './HealthcentersList';
// import HealthcentersQuickLinks from './HealthcentersQuickLinks';

import HealthcentersModal from './modals/HealthcentersModal';
import EmployeesModal from './modals/EmployeesModal';
import RoomsModal from './modals/RoomsModal';
import GroupsModal from './modals/GroupsModal';
import ServicesModal from './modals/ServicesModal';
import Modal from '../Shared/Modals/Modal';

const HealthcentersView = ({
  company,
  doctors,
  registries,
  nurses,
  healthcenters,
  updateHealthcenter,
  addHealthcenter,
  editHealthcenterHandler,
  currentEditedHealthcenter,
  healthcentersModalState,
  setHealthcentersModalState,
  employeesModalState,
  setEmployeesModalState,
  selectedHealthcenter,
  selectOptions,
  setSelectOptions,
  selectValue,
  setSelectValue,
  healthcenterChangeHandler,
  doctorsOfSelectedHealthcenter,
  registriesOfSelectedHealthcenter,
  nursesOfSelectedHealthcenter,
  roomsModalState,
  setRoomsModalState,
  groupsModalState,
  setGroupsModalState,
  servicesModalState,
  setServicesModalState,
  removeModalState,
  setRemoveModalState,
  removeHandler,
  restoreHealthcenter,
}) => {
  return (
    <Wrapper>
      {company && (
        <>
          <HealthcentersModal
            companyId={company._id}
            addHealthcenter={addHealthcenter}
            updateHealthcenter={updateHealthcenter}
            modalState={healthcentersModalState}
            setModalShown={setHealthcentersModalState}
            healthcenterData={currentEditedHealthcenter}
            restoreHealthcenter={restoreHealthcenter}
          />
          <EmployeesModal
            updateHealthcenter={updateHealthcenter}
            employeesModalState={employeesModalState}
            setEmployeesModalState={setEmployeesModalState}
            selectedHealthcenter={selectedHealthcenter}
            doctors={doctors}
            registries={registries}
            nurses={nurses}
            doctorsOfSelectedHealthcenter={doctorsOfSelectedHealthcenter}
            registriesOfSelectedHealthcenter={registriesOfSelectedHealthcenter}
            nursesOfSelectedHealthcenter={nursesOfSelectedHealthcenter}
            groups={selectedHealthcenter?.groups}
          />
          <RoomsModal
            updateHealthcenter={updateHealthcenter}
            roomsModalState={roomsModalState}
            setRoomsModalState={setRoomsModalState}
            selectedHealthcenterId={selectedHealthcenter?._id}
            rooms={selectedHealthcenter?.rooms}
          />
          <GroupsModal
            employeesOfSelectedHealthcenter={[...doctorsOfSelectedHealthcenter, ...nursesOfSelectedHealthcenter]}
            companyId={company._id}
            updateHealthcenter={updateHealthcenter}
            groupsModalState={groupsModalState}
            setGroupsModalState={setGroupsModalState}
            selectedHealthcenterId={selectedHealthcenter?._id}
            groups={selectedHealthcenter?.groups}
          />
          <ServicesModal
            servicesModalState={servicesModalState}
            setServicesModalState={setServicesModalState}
            updateHealthcenter={updateHealthcenter}
            services={selectedHealthcenter?.services}
            selectedHealthcenterId={selectedHealthcenter?._id}
          />
          <Modal
            type='SimpleQuestionModal'
            show={removeModalState.shown}
            question={removeModalState.question}
            questionTitle={removeModalState.questionTitle}
            acceptText='Usuń'
            cancelText='Anuluj'
            onAccept={removeModalState.onAccept}
            onCancel={removeModalState.onCancel}
          />
          <Section>
            <HealthcentersList
              setHealthcentersModalState={setHealthcentersModalState}
              editHealthcenterHandler={editHealthcenterHandler}
              healthcenters={healthcenters}
              EditPenUnderline={EditPenUnderline}
              healthcenterChangeHandler={healthcenterChangeHandler}
              selectedHealthcenter={selectedHealthcenter}
            />
            {/* <HealthcentersQuickLinks /> */}
          </Section>
          <Section>
            <HealthcentersDetails
              AddBtn={AddBtn}
              selectedHealthcenter={selectedHealthcenter}
              selectOptions={selectOptions}
              setSelectOptions={setSelectOptions}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
              healthcenterChangeHandler={healthcenterChangeHandler}
              setEmployeesModalState={setEmployeesModalState}
              doctorsOfSelectedHealthcenter={doctorsOfSelectedHealthcenter}
              registriesOfSelectedHealthcenter={registriesOfSelectedHealthcenter}
              nursesOfSelectedHealthcenter={nursesOfSelectedHealthcenter}
              setRoomsModalState={setRoomsModalState}
              setGroupsModalState={setGroupsModalState}
              setServicesModalState={setServicesModalState}
              setRemoveModalState={setRemoveModalState}
              removeHandler={removeHandler}
            />
          </Section>
        </>
      )}
    </Wrapper>
  );
};

export default HealthcentersView;

const Wrapper = styled.div`
width: 100%;
display: flex;
`;

const Section = styled.div`
width: 50%;
`;

const AddBtn = styled.button`
  position: absolute;
  right:15px;
  top:-5px;
  background-color:#E8E8E8;
  background-image:url(${AddPlusIcon});
  background-position: center;
  background-repeat: no-repeat;
  border:none;
  border-radius:5px;
  width:25px;
  height:25px;
  transition:300ms;
  cursor: pointer;
  &:hover{
    background-color:#E1E1E1;
  }
`;

const EditPenUnderline = styled.img`
  transition:300ms;
  width: 25px;
  height: 25px;
  cursor: pointer;
  filter: invert(81%) sepia(0%) saturate(51%) hue-rotate(195deg) brightness(86%) contrast(81%);
  &:hover {
    filter: invert(98%) sepia(0%) saturate(476%) hue-rotate(144deg) brightness(89%) contrast(81%);
  }
`;

