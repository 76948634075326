import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import SurveysTabView from "./SurveysTabView";
import {
  getPatientSurveys,
  updatePatientSurveys,
} from "../../../../../redux/modules/surveys";

const SurveysContainer = ({
  location: { pathname },
  currentPatient,
  user,
  patientSurveys,
  getPatientSurveys,
  updatePatientSurveys,
}) => {
 const [surveysModal, setSurveysModal] = useState({ shown: false, editId: null });
 const [deleteModal, setDeleteModal] = useState({ shown: false, question: null, onAccept: null });
  const { currentTab } = useParams();

  useEffect(() => {
    if(currentTab === "surveys") {
    getPatientSurveys(currentPatient._id);
    }
  }, [currentPatient, pathname]);

 const updatePatientSurveysHandler = (newSurveys) => {
    updatePatientSurveys(currentPatient._id, newSurveys)
  };

  const deletePatientSurveysHandler = (targetId) => {
    const newSurveys = { anthropometry: patientSurveys.anthropometry.filter(el => el._id !== targetId) };
    updatePatientSurveys(currentPatient._id, newSurveys);
  };

  const deleteModalHandler = () => {
        setDeleteModal({
          question: 'Czy na pewno chcesz usunąć tę antropometrię?',
          shown: true,
          onAccept: () => deletePatientSurveysHandler(surveysModal.editId)
        })
  };

  return (
    <SurveysTabView currentTab={currentTab}
     patientSurveys={patientSurveys} 
     updatePatientSurveysHandler={updatePatientSurveysHandler}
     surveysModal={surveysModal}
     setSurveysModal={setSurveysModal}
     deleteModalHandler={deleteModalHandler}
     deleteModal={deleteModal}
     setDeleteModal={setDeleteModal}
     />
  );
};

const mapStateToProps = (state) => ({
  currentPatient: state.patients.currentPatient,
  user: state.authentication.user,
  patientSurveys: state.surveys.patientSurveys,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPatientSurveys: (patientId) => dispatch(getPatientSurveys(patientId)),
    updatePatientSurveys: (patientId, newSurveys) =>
      dispatch(updatePatientSurveys(patientId, newSurveys)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SurveysContainer));
