import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../Shared/Modals/Modal';
import Select from '../../Shared/Select';
import Button from '../../Shared/Button';
import DatePicker from '../../Shared/DatePicker';
import StatusPill from '../../Shared/StatusPill';
import avatarPlaceholder from '../../Shared/assets/avatar.svg';
import { Formik } from 'formik'

const VacationModal = ({
    modalState: { shown, editId },
    setModalState,
    doctors,
    registries,
    nurses,
    currentCalendarDates: { start, end },
    events,
    addVacationHandler,
    updateVacationHandler,
    deleteVacationHandler,
    setQuestionModal,
    acceptVacation,
}) => {
    const [initialValues, setInitalValues] = useState({
        userId: null,
        start: start ?? Date.now(),
        end: end ?? Date.now(),
        state: false,
    }),
        [currentEvent, setCurrentEvent] = useState({});

    const employeesSelectOptions = [doctors, nurses, registries].map((el, index) => ({
        label: ['Lekarze', 'Pielęgniarki', 'Rejestratorzy'][index],
        options: el.map(({ userId: { _id, name, surname, avatar }, ...data }) => ({
            value: _id,
            label: <Option><Avatar src={avatar ? process.env.REACT_APP_SRV_URL + avatar : avatarPlaceholder} /> {name} {surname}</Option>,
            filterData: { name, surname },
        }))
    }));

    useEffect(() => {
        if (editId && events) {
            const target = events.find(el => el._id === editId);
            setInitalValues({
                userId: target.userId,
                start: Date.parse(target.start),
                end: Date.parse(target.end),
                state: target.state,
            });
            setCurrentEvent(target);
        } else if (!editId) {
            setInitalValues({
                userId: null,
                start: start ?? Date.now(),
                end: end ?? Date.now(),
            });
            setCurrentEvent({});
        }
    }, [editId, shown, events]);

    return (
        <Modal
            show={shown}
            exitButton={true}
            onCancel={() => {
                setModalState({ shown: false, editId: null });
            }}
            title={editId ? "Edycja urlopu" : "Dodawanie urlopu"}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                    if (editId) {
                        updateVacationHandler(values);
                    } else {
                        addVacationHandler(values);
                    }
                    setModalState({ shown: false, editId: null });
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <Select
                            isDisabled={editId !== null}
                            label="Pracownik"
                            name="userId"
                            placeholder="Wybierz pracownika..."
                            customFilterOption={({ data: { filterData } }, inputValue) => {
                                const fullName = `${filterData.name} ${filterData.surname}`.toLowerCase()
                                return fullName.includes(inputValue.toLowerCase());
                            }}
                            options={employeesSelectOptions}
                            value={[...employeesSelectOptions[0].options, ...employeesSelectOptions[1].options, ...employeesSelectOptions[2].options].find(({ value }) => value === values.userId)}
                            onChange={({ value }) => setFieldValue('userId', value)}
                            noOptionsMessage={() => <p>Nie znaleziono takiego pracownika</p>}
                        />
                        <InputRow>
                            <DatePicker
                                label='Początek'
                                dateFormat="dd.MM.yyyy"
                                selected={values.start}
                                name='start'
                                selectsStart
                                startDate={values.start}
                                endDate={values.end}
                                onChange={(value) => setFieldValue('start', value)}
                            />
                            <DatePicker
                                label='Koniec'
                                dateFormat="dd.MM.yyyy"
                                selected={values.end}
                                name='end'
                                selectsEnd
                                startDate={values.start}
                                endDate={values.end}
                                onChange={(value) => setFieldValue('end', value)}
                            />
                        </InputRow>
                        {editId && <InputRow>
                            <div>
                                <Label>Status urlopu</Label>
                                <StatusPill type={values.state === 'ACCEPTED' && 'positive'}>
                                    {
                                        values.state === 'ACCEPTED' ?
                                            'Zaakceptowana'
                                            :
                                            'Nie zaakceptowana'
                                    }
                                </StatusPill>
                            </div>
                            <CenterBox>
                                {values.state === 'PLANNED' &&
                                    <Button variant="secondary" onClick={() => acceptVacation(editId, {
                                        userId: currentEvent.userId,
                                        start: currentEvent.start,
                                        end: currentEvent.end,
                                    })}>
                                        Zaakceptuj urlop
                                    </Button>
                                }
                            </CenterBox>
                        </InputRow>}
                        <CenterBox pt>
                            <Button type='submit' variant="primary" disabled={values.userId === null}>{editId ? "Zapisz zmiany" : "Dodaj urlop"}</Button>
                            {editId &&
                                <Button
                                    onClick={() => setQuestionModal({
                                        shown: true,
                                        question: 'Czy na pewno chcesz usunąć ten urlop?',
                                        acceptText: 'Usuń',
                                        onAccept: () => deleteVacationHandler(values.userId),
                                    })}
                                    type='button'
                                    variant="danger"
                                >Usuń urlop
                                </Button>
                            }
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal >
    )
}

export default VacationModal;

const Form = styled.form`
    width:400px;
`;

const InputRow = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:10px;
`;

const Option = styled.div`
    display:flex;
    align-items:center;
`;

const Avatar = styled.img`
    width:20px;
    height:20px;
    min-width:20px;
    min-height:20px;
    border-radius:50%;
    margin-right:5px;
`;

const CenterBox = styled.div`
    text-align:center;
    ${({ pt }) => pt && 'padding-top:30px;'}
`;

const Label = styled.p`
    margin-left:5px;
`;