import React, { useState, useEffect, useRef } from 'react';
import {
    Editor,
    EditorState,
    convertToRaw,
    Modifier,
    convertFromRaw,
    CompositeDecorator,
} from 'draft-js';
import styled from 'styled-components';
import removeIcon from '../../Shared/assets/rejected-icon.svg';
import { findAllTags } from '../../Shared/utils';
import useDebouncer from '../../Shared/Hooks/useDebouncer';

const DraftEditor = ({
    parameters = [],
    value = '',
    onChange,
    loadIndexes,
    height = '150px',
    placeholder = 'Wpisz tekst...',
    bottomPanelLabel = 'Pola parametru:',
}) => {
    const mapStringToRaw = (string) => {
        const tagsInValue = findAllTags(string);
        const initalEntityRanges = tagsInValue.map(({ start, tagName }, index) => ({
            key: `${index}`,
            offset: start,
            length: tagName.length + 2,
        }));
        let initalEntityMap = {};
        let i = 0;
        for (const tag of tagsInValue) {
            initalEntityMap[i] = {
                type: 'PARAMETER',
                mutability: 'IMMUTABLE',
                data: {
                    index: i
                },
            }
            i++;
        }
        const raw = {
            blocks: [
                {
                    text: string,
                    type: 'unstyled',
                    entityRanges: initalEntityRanges,
                },
            ],
            entityMap: initalEntityMap,
        };
        return raw;
    };

    const ParameterComponent = ({ decoratedText, start, contentState, entityKey, ...props }) => {
        let tagName = decoratedText.replace('{', '').replace('}', '');
        return (
            <Tag
                data-offset-key={tagName.length + 2}
                contentEditable={false}
                readOnly
            >
                {tagName}
                <RemoveBtn
                    contentEditable={false}
                    readOnly
                    onClick={(e) => {
                        e.preventDefault();
                        removeTag(start, decoratedText, contentState, entityKey)
                    }}
                />
            </Tag>
        )
    }

    const findParameters = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'PARAMETER'
            );
        }, callback);
    };

    const decorator = new CompositeDecorator([{
        strategy: findParameters,
        component: ParameterComponent,
    }]);

    const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(mapStringToRaw(value)), decorator)),
        [dataLoadedFor, setDataLoadedFor] = useState(null),
        [setDebounce] = useDebouncer(),
        editorRef = useRef(null);

    const focusEditor = () => {
        editorRef.current.focus();
    }

    const addTag = (target) => {
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const contentStateWithEntity = contentState.createEntity('PARAMETER', 'IMMUTABLE', {
            index: Object.keys(convertToRaw(editorState.getCurrentContent()).entityMap).length,
            selectionState: selection,
            target,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const textWithEntity = Modifier.insertText(
            contentState,
            selection,
            `{${target}}`,
            null,
            entityKey);
        let editorStateAfterEntity = EditorState.push(
            editorState,
            textWithEntity,
            'insert-characters'
        );
        const textWithEntityAndSpace = Modifier.insertText(
            textWithEntity,
            editorStateAfterEntity.getSelection(),
            ' ',
        );
        const editorStateAfterEntityAndSpace = EditorState.push(
            editorStateAfterEntity,
            textWithEntityAndSpace,
            'insert-characters'
        );
        setEditorState(editorStateAfterEntityAndSpace);
        setTimeout(() => focusEditor(), 100);
    };
    const removeTag = (start, targetTag, contentState) => {
        let newText = contentState.getPlainText();
        newText = newText.substr(0, start) + newText.substr(start + targetTag.length + 1, newText.length);
        const newRaw = mapStringToRaw(newText);
        const newContentState = convertFromRaw(newRaw);
        let newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
        newEditorState = EditorState.moveFocusToEnd(newEditorState);
        setEditorState(newEditorState);
    };
    const changeHandler = (newState) => {
        setEditorState(newState);
        setDebounce(() => {
            const newText = newState.getCurrentContent().getPlainText();
            if (value !== newText) {
                onChange(newText);
            }
        }, 350);
    };
    useEffect(() => {
        //Load inital Data
        if (loadIndexes !== dataLoadedFor) {
            const initalContent = mapStringToRaw(value);
            setEditorState(EditorState.createWithContent(convertFromRaw(initalContent), decorator));
            setDataLoadedFor(loadIndexes);
        }
    }, [value])

    const BlockStyleControls = ({ editorState, addTag, ...props }) => {
        return (
            <EditorControls className='Draft-fields'>
                {parameters.map(({ tagName }, index) =>
                    <TagButton
                        key={`tagButton_${tagName}_${index}`}
                        type='button'
                        onMouseDown={(e) => {
                            e.preventDefault();
                            addTag(tagName)
                        }}
                    >{tagName}</TagButton>
                )}
            </EditorControls>
        );
    };

    return (
        <EditorWrapper height={height} onClick={focusEditor}>
            <Editor
                ref={editorRef}
                editorState={editorState}
                onChange={changeHandler}
                placeholder={placeholder}
            />
            <Label>{bottomPanelLabel}</Label>
            <BlockStyleControls
                editorState={editorState}
                addTag={addTag}
                removeTag={removeTag}
            />
        </EditorWrapper>
    )
}

export default DraftEditor;

// STYLES
const EditorWrapper = styled.div`
    div.DraftEditor-root {
    border: 1px solid #ccc;
    margin:5px 0;
    border-radius: 5px;
    height: ${({ height }) => height};
    width: 100%;
    overflow-y: auto;
    position: relative;
  }
  div.DraftEditor-editorContainer,
  div.public-DraftEditor-content {
    height: 100%;
    padding:5px;
    font-size:14px;
  }
  .public-DraftEditorPlaceholder-inner{
    position: absolute;
    top:10px;
    left:10px;
    color:#ccc;
    font-size: 14px;
  }
`;

const Tag = styled.span`
    background-color:${({ theme }) => theme.primary};
    padding:2px 3px;
    color:#fff;
    border-radius:2px;
    white-space:nowrap;
    display:inline-block;
    user-select:none;
    position:relative;
    margin: 2px 0;
    i{
        opacity:0;
    }
`;

const RemoveBtn = styled.span`
    display:inline-block;
    cursor:pointer;
    width:13px;
    height:10px;
    background:url(${removeIcon}) no-repeat;
    background-size:8px;
    background-position:center;  
    user-select:none;
`;

const EditorControls = styled.div`
    padding:3px;
`;

const TagButton = styled.button`
    background-color:${({ theme }) => theme.primary};
    padding:2px 3px;
    color:#fff;
    border-radius:2px;
    white-space:nowrap;
    display:inline-block;
    user-select:none;
    position:relative;
    margin: 0 3px 5px;
    border:none;
    cursor:pointer;
    text-transform:capitalize;
`;

const Label = styled.p`
    margin-left:5px;
    font-size:14px;
`;