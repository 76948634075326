import React from 'react';
import styled from 'styled-components';
import Button from '../../Shared/Button';
import Input from '../../Shared/Input';
import searchIcon from '../../Shared/assets/search.svg';
import ElementsList from './ElementsList';
import SetItem from './SetItem';
import { CustomDragLayer } from './ElementItem'

import NameTakenModal from '../Modals/NameTakenModal';
import ImportModal from '../Modals/ImportModal';
import WarningModal from '../Modals/WarningModal';

import importIcon from '../../Shared/assets/import.svg';
import plusIcon from '../../Shared/assets/add-plus-thin.svg';

const ElementsListView = ({
    doctorsElements,
    nameTakenModal,
    setNameTakenModal,
    warningModal,
    setWarningModal,
    addElementHandler,
    deleteVisitElement,
    exportVisitElement,
    importModal,
    setImportModal,
    importFileHandler,
    addSetHandler,
    doctorsElementsSets,
    updateSetHandler,
    deleteElementsSet,
    elIsDragging,
    setElIsDragging,
    currentCollapse,
    setCurrentCollapse,
    exportElementsSet,
    elementsSearch,
    setElementsSearch,
    elements,
    nextTutorialStep,
}) => {
    return (
        <Wrapper>
            <NameTakenModal
                modalState={nameTakenModal}
                setModalState={setNameTakenModal}
            />
            <ImportModal
                modalState={importModal}
                setModalState={setImportModal}
                importFileHandler={importFileHandler}
            />
            <WarningModal
                show={warningModal.shown}
                question={warningModal.question}
                onAccept={warningModal.onAccept}
                setModalState={setWarningModal}
                reverseButtonColors={true}
            />
            <Column id='setSide'>
                <TopPanel>
                    <Heading>Zestawy</Heading>
                    <Actions>
                        {/* <StyledButton
                            bgImg={importIcon}
                            variant='secondary'
                            onClick={() => setImportModal({ shown: true, target: 'set' })}
                        >Importuj zestaw</StyledButton> */}
                        <StyledButton
                            className="addNewSetBtn"
                            bgImg={plusIcon}
                            variant='secondary'
                            onClick={() => addSetHandler({ name: `Nowy zestaw ${doctorsElementsSets?.length + 1}`, stage: 'WYWIAD', elements: [] })}
                        >Dodaj nowy
                        </StyledButton>
                    </Actions>
                </TopPanel>
                <List fullHeight>
                    {doctorsElementsSets.length > 0 ? doctorsElementsSets.map(set => {
                        const filtered = doctorsElements.filter(el => set.elements.indexOf(el._id) !== -1);
                        return (
                            <SetItem
                                key={set._id}
                                setData={set}
                                length={filtered.length}
                                doctorsElements={doctorsElements}
                                updateSetHandler={updateSetHandler}
                                populatedElements={filtered}
                                setWarningModal={setWarningModal}
                                deleteElementsSet={deleteElementsSet}
                                elIsDragging={elIsDragging}
                                currentCollapse={currentCollapse}
                                setCurrentCollapse={setCurrentCollapse}
                                exportElementsSet={exportElementsSet}
                            />
                        )
                    }) :
                        <NoItems size='big'>Brak zestawów</NoItems>
                    }
                </List>
            </Column>
            <Column id='elementsSide'>
                <TopPanel>
                    <Heading>Elementy</Heading>
                    <Actions>
                        {/* <SearchWrapper>
                            <SearchInput
                                value={elementsSearch}
                                onChange={(e) => setElementsSearch(e.target.value)}
                                placeholder="Szukaj..."
                                hideError={true}
                            />
                        </SearchWrapper> */}
                        <StyledButton
                            bgImg={importIcon}
                            variant='secondary'
                            onClick={() => setImportModal({ shown: true, target: 'element' })}
                            className="importElement"
                        >Importuj</StyledButton>
                        <StyledButton
                            className="addNewElementBtn"
                            bgImg={plusIcon}
                            variant='primary'
                            onClick={() => addElementHandler()}
                        >Dodaj nowy
                        </StyledButton>
                    </Actions>
                </TopPanel>
                <List fullHeight>
                    <ElementsList
                        elements={elements}
                        doctorsElementsSets={doctorsElementsSets}
                        setWarningModal={setWarningModal}
                        deleteVisitElement={deleteVisitElement}
                        exportVisitElement={exportVisitElement}
                        elIsDragging={elIsDragging}
                        setElIsDragging={setElIsDragging}
                        nextTutorialStep={nextTutorialStep}
                    />
                </List>
            </Column>
            <CustomDragLayer />
        </Wrapper>
    )
}

export default ElementsListView;

const Wrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:10px;
    width:100%;
    max-width:1400px;
    margin:0 auto;
    padding: 0 10px;
    ${({ theme }) => `${theme.mq.desktop}{
        grid-template-columns: 3fr 4fr;
    }`}
`;

const Column = styled.div`
    width:100%;
    margin: 15px auto;
`;

const TopPanel = styled.div`
    display:flex;
    justify-content:space-between;
    align-items: center;
    padding-bottom:5px;
`;

const Heading = styled.h2`
    font-size:16px;
    font-weight:600;
    margin:0 0 0 10px;
    color:#000;
    ${({ theme }) => `${theme.mq.desktop}{
        font-size:20px;
    }`}
`;

const Actions = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
`;

const List = styled.div`
    background-color:#fff;
    border: 2px solid ${({ theme }) => theme.grey};
    border-radius: 10px;
    grid-template-columns: ${({ colsTemplate }) => colsTemplate};
    ${({ fullHeight }) => fullHeight && 'height:calc(100vh - 200px); overflow-y:auto;'};
`;

const ListHeader = styled.div`
    display:grid;
    grid-template-columns: inherit;
    padding: 11px 0;
    border-bottom: 2px solid ${({ theme }) => theme.grey};
    >span{
        text-align:center;
    }
`;

const ListBody = styled.ul`
    margin:0;
    max-height: 100%;
    overflow-y: auto;
    grid-template-columns: inherit;
`;

const NoItems = styled.p`
    text-align:center;
    padding:15px 0;
    ${({ size }) => size === 'big' && 'font-size:20px;padding:30px 0;'}
`;

const StyledButton = styled(Button)`
    padding-left:35px!important;
    background-image:url(${({ bgImg }) => bgImg});
    background-position:center;
    background-repeat: no-repeat;
    background-size:17.5px;
    white-space: nowrap;
    padding: 8px 5px;
    min-height:35px;
    font-size:0;
    ${({ theme }) => `${theme.mq.desktop}{
        min-height:0;
        font-size:12px;
        background-size:20px;
        padding: 8px 10px;
        background-position:center left 10px;
    }`}
`;

const SearchWrapper = styled.div`
    margin-right:5px;
    ${({ theme }) => `${theme.mq.desktop}{
        margin-right:15px;
    }`}
`;

const SearchInput = styled(Input)`
  background-image: url(${searchIcon});
  background-position: center right 10px;
  background-repeat: no-repeat;
  margin:0;
  min-height:36px;
  ${({ theme }) => `${theme.mq.desktop}{
      min-height:38px;
      margin:2px 0 2px 0;
  }`}
  &:focus{
    border-width: 1px;
  }
`;