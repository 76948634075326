import React, { useState, useEffect } from "react";
import ReceptionistCalendarView from "./ReceptionistCalendarView";
import { connect } from "react-redux";
import { getEmployees } from "../../../redux/modules/employees";
import { addPatient, getCompanyPatients } from "../../../redux/modules/patients";
import {
  setCurrentHealthcenter,
  getCurrentHealthcenterLocalStorage
} from "../../../redux/modules/healthcenter";
import {
  getVisitsByDoctorsIds,
  addVisit,
  deleteVisit,
  updateVisit
} from "../../../redux/modules/visits";
import {
  updateCalendarDate,
  updateCurrentView,
  updateResourceStatus,
  updateRoomsStatus,
  getCalendarLocalStorage
} from "../../../redux/modules/calendar";
import {
  addAllDayEvent,
  getAllDayEventsByHealthcenterId,
  updateAllDayEventById
} from "../../../redux/modules/events";
import { withRouter } from "react-router";
import { lightenColor, setSlotStyleGetter } from "../utils";
import moment from "moment";
import "moment/locale/pl";
import { calendarPageLoaded } from "../../../redux/modules/application";
import { toast } from 'react-toastify';
import useDebouncer from '../../Shared/Hooks/useDebouncer';

const ReceptionistCalendarContainer = ({
  user,
  patients,
  doctors,
  getEmployees,
  userRole,
  addPatient,
  getVisitsByDoctorsIds,
  addVisit,
  deleteVisit,
  currentHealthcenter,
  healthcenters,
  healthcenterLoading,
  visits,
  updateVisit,
  setCurrentHealthcenter,
  updateCalendarDate,
  calendarDate,
  currentView,
  updateCurrentView,
  updateResourceStatus,
  updateRoomsStatus,
  resourceStatus,
  roomsStatus,
  getCalendarLocalStorage,
  calendarDoctors,
  getCurrentHealthcenterLocalStorage,
  addAllDayEvent,
  allDayEvents,
  registries,
  getAllDayEventsByHealthcenterId,
  updateAllDayEventById,
  calendarPageLoaded,
  getCompanyPatients,
  nurses,
  calendarNurses,
}) => {
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isCreateModalOn, setCreateModal] = useState(false);
  const [isEditModalOn, setEditModal] = useState(false);
  const [isAddAlldayEventModalOn, setAddAllDayModal] = useState(false);
  const [isEditAlldayEventModalOn, setEditAllDayModal] = useState(false);
  const [isDragModalOn, setDragModal] = useState(false);
  const [currentDate, setCurrentDate] = useState({
    weekStart: moment()
      .startOf("week")
      .toDate(),
    weekEnd: moment()
      .endOf("week")
      .toDate(),
    monthStart: moment()
      .startOf("month")
      .toDate(),
    monthEnd: moment()
      .endOf("month")
      .toDate()
  });
  const [warningModal, setWarningModal] = useState({ shown: false, question: null, onAccept: null });
  const [setDebounce] = useDebouncer();

  useEffect(() => {
    if (userRole) {
      getEmployees("registry", userRole?.companyId);
      getEmployees("nurse", userRole?.companyId);
      calendarPageLoaded()
      getCompanyPatients(userRole?.companyId, 20, 1, "");
    }
  }, [userRole]);

  useEffect(() => {
    if (!healthcenterLoading && userRole) {
      getCalendarLocalStorage(userRole.userId);
      getCurrentHealthcenterLocalStorage();
    }
  }, [healthcenters, userRole]);

  useEffect(() => {
    onNavigate(calendarDate);
  }, [calendarDate]);

  useEffect(() => {
    if (calendarDoctors.length > 0 && currentHealthcenter) {
      const employeesIds = [...calendarDoctors, ...calendarNurses]
        .filter(emp => emp.checked)
        .map(emp => emp._id);
      if (currentView === "month") {
        getVisitsByDoctorsIds(
          currentDate.monthStart,
          currentDate.monthEnd,
          employeesIds,
          undefined,
          false,
          true,
          currentHealthcenter?._id
        );
        getAllDayEventsByHealthcenterId(
          currentHealthcenter._id,
          currentDate.monthStart,
          currentDate.monthEnd
        );
      } else {
        getVisitsByDoctorsIds(
          currentDate.weekStart,
          currentDate.weekEnd,
          employeesIds,
          undefined,
          false,
          true,
          currentHealthcenter?._id
        );
        getAllDayEventsByHealthcenterId(
          currentHealthcenter._id,
          currentDate.weekStart,
          currentDate.weekEnd
        );
      }

    }
  }, [calendarDoctors, currentHealthcenter, calendarNurses]);

  const setEventStyleGetter = ({ doctor }) => {
    const slotColor = [...doctors, ...nurses].find(doc => doc._id === doctor)?.visitColor;
    var style = {
      backgroundColor: slotColor ? slotColor : "#1890FF",
      border: `1px solid ${lightenColor(slotColor ? slotColor : "#1890FF", 20)}`
    };
    return {
      style: style
    };
  };

  const createNewEvent = event => {
    const date = new Date();
    if (
      event.end - event.start >= 86340000 ||
      event.end - event.start === 0 ||
      event.end === event.start
    ) {
      console.log("allday event");
      setSelectedEvent({ startDate: event.start, endDate: event.end });
      setAddAllDayModal(true);
    } else {
      console.log("simple visit");
      setSelectedEvent({
        start: event.start,
        end: event.end,
        resourceStatus,
        roomsStatus,
        resourceId: event.resourceId
      });
      setCreateModal(true);
    }
  };

  const onNavigate = date => {
    const now = moment(date);
    const doctorsIds = calendarDoctors
      .filter(doctor => doctor.checked)
      .map(doctor => doctor._id);
    const nursesIds = calendarNurses
      .filter(nurse => nurse.checked)
      .map(nurse => nurse._id);
    if (
      now.isBefore(currentDate.weekStart) ||
      now.isAfter(currentDate.weekEnd) ||
      now.isBefore(currentDate.monthStart) ||
      now.isAfter(currentDate.monthEnd)
    ) {
      const startOfWeek = moment(date)
        .startOf("week")
        .toDate();
      const endOfWeek = moment(date)
        .endOf("week")
        .toDate();
      const startOfMonth = moment(date)
        .startOf("month")
        .toDate();
      const endOfMonth = moment(date)
        .endOf("month")
        .toDate();
      //currentView
      setCurrentDate({
        weekStart: startOfWeek,
        weekEnd: endOfWeek,
        monthStart: startOfMonth,
        monthEnd: endOfMonth
      });

      if (currentView === "month") {
        getVisitsByDoctorsIds(
          startOfMonth,
          endOfMonth,
          [...doctorsIds, ...nursesIds],
          undefined,
          false,
          true,
          currentHealthcenter._id
        );
        getAllDayEventsByHealthcenterId(
          currentHealthcenter._id,
          startOfMonth,
          endOfMonth
        );
      } else {
        getVisitsByDoctorsIds(
          startOfWeek,
          endOfWeek,
          [...doctorsIds, ...nursesIds],
          undefined,
          false,
          true,
          currentHealthcenter._id
        );
        getAllDayEventsByHealthcenterId(
          currentHealthcenter._id,
          startOfWeek,
          endOfWeek
        );
      }
    }
    updateCalendarDate(date);
  };

  const editEventDateTime = async () => {
    let updateBody = {
      start: selectedEvent.start,
      end: selectedEvent.end,
      doctor: selectedEvent.event.doctor,
      healthcenterId: selectedEvent.event.healthcenterId,
    };
    if (selectedEvent.event.isAllDay) {
      updateBody.start = moment(updateBody.start).set({ hour: 0, minute: 1, second: 0, millisecond: 0 })._d;
      updateBody.end = moment(updateBody.end).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })._d;
      updateAllDayEventById(selectedEvent.event._id, updateBody);
      toast('Czas wydarzenia długoterminowego został zmieniony.', {
        type: 'success'
      })
    } else {
      if (resourceStatus && !roomsStatus) {
        updateBody = {
          start: selectedEvent.start,
          end: selectedEvent.end,
          doctor: selectedEvent.resourceId,
        };
      } else if (resourceStatus && roomsStatus) {
        updateBody = {
          start: selectedEvent.start,
          end: selectedEvent.end,
          room: selectedEvent.resourceId,
        };
      }
      if (updateBody.start.toString() === updateBody.end.toString()) {
        updateBody.end = moment(updateBody.end).add(userRole.callendarSettings.step ?? 30, 'minutes')._d;
      }

      const resp = await updateVisit(
        selectedEvent.event._id,
        selectedEvent.event.state,
        updateBody
      );
      toast('Czas wizyty został zmieniony.', {
        type: 'success'
      })
      if (!resp.status || resp.status !== 200) {
        setWarningModal({
          shown: true,
          questionTitle: "Czy jesteś pewien?",
          question: resp === "Visit time is not in line with the doctor's schedule." ? "Ten doktor nie przyjmuje w tych godzinach. Czy chcesz mimo to kontynuować?" : "Ten doktor ma zaplanowy urlop w tym czasie. Czy chcesz mimo to kontynuować?",
          onAccept: async () => {
            await updateVisit(
              selectedEvent.event._id,
              selectedEvent.event.state,
              updateBody,
              true
            );
          }
        })
      }
    }
    setDragModal(false);
  };

  const getCompanyPatientsHandler = (searchTerm) => {
    if (userRole) {
      setDebounce(() => {
        getCompanyPatients(userRole?.companyId, 20, 1, searchTerm);
      });
    }
  }

  return (
    <ReceptionistCalendarView
      setSlotStyleGetter={setSlotStyleGetter}
      setEventStyleGetter={setEventStyleGetter}
      user={user}
      createNewEvent={createNewEvent}
      isCreateModalOn={isCreateModalOn}
      setCreateModal={setCreateModal}
      patients={patients}
      addPatient={addPatient}
      userRole={userRole}
      addVisit={addVisit}
      currentHealthcenter={currentHealthcenter}
      healthcenters={healthcenters}
      visits={visits}
      selectedEvent={selectedEvent}
      updateCalendarDate={updateCalendarDate}
      setCurrentView={updateCurrentView}
      isEditModalOn={isEditModalOn}
      setEditModal={setEditModal}
      setSelectedEvent={setSelectedEvent}
      updateVisit={updateVisit}
      setDragModal={setDragModal}
      isDragModalOn={isDragModalOn}
      setCurrentHealthcenter={setCurrentHealthcenter}
      roomsStatus={roomsStatus}
      updateRoomsStatus={updateRoomsStatus}
      resourceStatus={resourceStatus}
      updateResourceStatus={updateResourceStatus}
      calendarDate={calendarDate}
      currentView={currentView}
      calendarDoctors={calendarDoctors}
      calendarNurses={calendarNurses}
      getCalendarLocalStorage={getCalendarLocalStorage}
      isAddAlldayEventModalOn={isAddAlldayEventModalOn}
      setAddAllDayModal={setAddAllDayModal}
      addAllDayEvent={addAllDayEvent}
      allDayEvents={allDayEvents}
      registries={registries}
      isEditAlldayEventModalOn={isEditAlldayEventModalOn}
      setEditAllDayModal={setEditAllDayModal}
      updateAllDayEventById={updateAllDayEventById}
      editEventDateTime={editEventDateTime}
      warningModal={warningModal}
      setWarningModal={setWarningModal}
      deleteVisit={deleteVisit}
      getCompanyPatientsHandler={getCompanyPatientsHandler}
    />
  );
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  patients: state.patients.data,
  doctors: state.employees.doctors,
  nurses: state.employees.nurses,
  registries: state.employees.registries,
  userRole: state.authentication.userRole,
  currentHealthcenter: state.healthcenter.currentHealthcenter,
  healthcenters: state.healthcenter.data,
  healthcenterLoading: state.healthcenter.isLoading,
  visits: state.visits.data,
  calendarDate: state.calendar.calendarDate,
  currentView: state.calendar.currentView,
  resourceStatus: state.calendar.resourceStatus,
  roomsStatus: state.calendar.roomsStatus,
  calendarDoctors: state.calendar.doctors,
  calendarNurses: state.calendar.nurses,
  allDayEvents: state.events.data
});

const mapDispatchToProps = dispatch => {
  return {
    getEmployees: (employeeType, companyId) =>
      dispatch(getEmployees(employeeType, companyId)),
    addPatient: (companyId, patient) =>
      dispatch(addPatient(companyId, patient)),
    getVisitsByDoctorsIds: (
      start,
      end,
      doctorsIds,
      roomsIds,
      doctorFlag,
      patientFlag,
      healthcenterId
    ) =>
      dispatch(
        getVisitsByDoctorsIds(
          start,
          end,
          doctorsIds,
          roomsIds,
          doctorFlag,
          patientFlag,
          healthcenterId
        )
      ),
    addVisit: (visit, force) => dispatch(addVisit(visit, force)),
    deleteVisit: (visitId) => dispatch(deleteVisit(visitId)),
    updateVisit: (visitId, newState, body, force) =>
      dispatch(updateVisit(visitId, newState, body, force)),
    setCurrentHealthcenter: healthcenter =>
      dispatch(setCurrentHealthcenter(healthcenter)),
    updateCalendarDate: date => dispatch(updateCalendarDate(date)),
    updateCurrentView: view => dispatch(updateCurrentView(view)),
    updateResourceStatus: status => dispatch(updateResourceStatus(status)),
    updateRoomsStatus: status => dispatch(updateRoomsStatus(status)),
    getCalendarLocalStorage: (userId) => dispatch(getCalendarLocalStorage(userId)),
    getCurrentHealthcenterLocalStorage: () =>
      dispatch(getCurrentHealthcenterLocalStorage()),
    addAllDayEvent: data => dispatch(addAllDayEvent(data)),
    getAllDayEventsByHealthcenterId: (healthcenterId, start, end) =>
      dispatch(getAllDayEventsByHealthcenterId(healthcenterId, start, end)),
    updateAllDayEventById: (allDayEventId, data) =>
      dispatch(updateAllDayEventById(allDayEventId, data)),
    calendarPageLoaded: () => dispatch(calendarPageLoaded()),
    getCompanyPatients: (companyId, pageSize, pageNumber, searchTerm) =>
      dispatch(getCompanyPatients(companyId, pageSize, pageNumber, searchTerm)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReceptionistCalendarContainer)
);
