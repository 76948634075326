import React, { useState, useEffect } from "react";
import NavigationView from "./NavigationView";
import { withRouter } from 'react-router-dom';
import { setChatChannelOpen } from '../../redux/modules/application';
import { clearUnreadMessages } from '../../redux/modules/chat';
import { getActiveVisits } from '../../redux/modules/visits';
import { setNavBlock } from '../../redux/modules/navigation';
import { connect } from 'react-redux';
import { setTutorial } from '../../redux/modules/tutorial';
import { tutorialsNames } from '../Tutorial/tutorialEnums';

const NavigationContainer = ({
  userRole,
  user,
  location,
  history,
  isAuthenticated,
  setChatChannelOpen,
  currentChannel,
  clearUnreadMessages,
  unreadMessages,
  activeVisits,
  getActiveVisits,
  navBlock,
  setNavBlock,
  sidebarActive,
  setSidebarActive,
  tutorialActive,
  setTutorial,
  tutorialCurrent,
  company
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [isActiveVisitsOpen, setActiveVisitsOpen] = useState(false);
  const [warningModal, setWarningModal] = useState({ shown: false, link: null });
  const [isHealthcenterErrorOn, setHealthcenterError] = useState(false);

  useEffect(() => {
    if (!isNotificationOpen) {
      setChatChannelOpen('')
    } else if (currentChannel) {
      setChatChannelOpen(currentChannel)
      clearUnreadMessages(currentChannel)
    }
  }, [isNotificationOpen])

  useEffect(() => {
    if (userRole && (user.activeRole === "Doctor" || user.activeRole === "Nurse")) {
      getActiveVisits(userRole._id)
    }
  }, [userRole?._id])

  const handleChannelClick = (channelId) => {
    setChannelListOpen(false)
  }

  const handlePrev = () => {
    setChannelListOpen(true)
  }

  const [channelListOpen, setChannelListOpen] = useState(true)

  //set Active Tab depends on route
  useEffect(() => {
    const currentRoute = location.pathname.replace("/", "");
    if (currentRoute === "") {
      setActiveTab(0);
    } else if (currentRoute === "employees" || currentRoute === "calendar") {
      setActiveTab(1);
    } else if (currentRoute === "company" || currentRoute.startsWith("patients")) {
      setActiveTab(2);
    } else if (currentRoute === "healthcenters" || currentRoute.startsWith("visit")) {
      setActiveTab(3);
    } else if (currentRoute === "statistics") {
      setActiveTab(4);
    }
  }, [location]);

  useEffect(() => {
    if (userRole?.healthCenters?.length === 0 && !isHealthcenterErrorOn) {
      sessionStorage.removeItem("jwtToken");
      setHealthcenterError(true);
    }
  }, [userRole]);

  useEffect(() => {
    if (user?.activeRole && userRole?.healthCenters?.length > 0) {
      let pathname = location.pathname;
      const [_, page, patientId, tab] = location.pathname.split('/');
      if (pathname.includes('patients')) {
        pathname = `/${page}/${tab}`;
      }
      else if (pathname.includes('elements') && !patientId) {
        pathname = `/elementsList`;
      }
      else if (pathname.includes('elements') && patientId) {
        pathname = `/elements`;
      }
      else if (pathname.includes('visit') && !pathname.includes('visits')) {
        pathname = `/visit`;
      }
      
      if (tutorialActive === false && !isHealthcenterErrorOn) {
        const role = (user.activeRole === 'Doctor' || user.activeRole === 'Nurse') ? 'Doctor|Nurse' : user.activeRole;
        if (user.tutorials[tutorialsNames[`${role}${pathname}`]] === false) {
          setTutorial({
            active: true,
            page: `${role}${pathname}`,
          })
        }
      }
      else if (tutorialActive && tutorialCurrent !== user?.tutorials[tutorialsNames[`${user.activeRole}${pathname}`]] && user?.tutorials[tutorialsNames[`${user.activeRole}${pathname}`]] === false) {
        setTutorial({
          active: false,
        })
        setTimeout(() => {
          setTutorial({
            active: true,
            page: `${user.activeRole}${pathname}`,
          })
        }, 200)
      };
    }
  }, [location, user, userRole]);

  const healthcenterErrorHandler = () => {
    setHealthcenterError(false);
    history.push("/logout");
  };

  return (
    <NavigationView
      isProfileModalOpen={isProfileModalOpen}
      setIsProfileModalOpen={setIsProfileModalOpen}
      isNotificationOpen={isNotificationOpen}
      setNotificationOpen={setNotificationOpen}
      user={user}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      isAuthenticated={isAuthenticated}
      userRole={userRole}
      handleChannelClick={handleChannelClick}
      handlePrev={handlePrev}
      channelListOpen={channelListOpen}
      setChatChannelOpen={setChatChannelOpen}
      unreadMessages={unreadMessages}
      activeVisits={activeVisits}
      activeVisitsOpen={isActiveVisitsOpen}
      setActiveVisitsOpen={setActiveVisitsOpen}
      navBlock={navBlock}
      warningModal={warningModal}
      setWarningModal={setWarningModal}
      setNavBlock={setNavBlock}
      sidebarActive={sidebarActive}
      setSidebarActive={setSidebarActive}
      healthcenterErrorHandler={healthcenterErrorHandler}
      isHealthcenterErrorOn={isHealthcenterErrorOn}
      pathname={location.pathname}
      history={history}
      companyLogo={company?.logo}
    />
  );
};

const mapStateToProps = state => ({
  currentChannel: state.chat.currentChannel,
  unreadMessages: state.chat.unreadMessages,
  activeVisits: state.visits.activeVisits,
  navBlock: state.navigation.navBlock,
  tutorialActive: state.tutorial.active,
  tutorialSteps: state.tutorial.steps,
  tutorialStepIndex: state.tutorial.stepIndex,
  tutorialCurrent: state.tutorial.current,
  company: state.company.data,
});

const mapDispatchToProps = dispatch => {
  return {
    setChatChannelOpen: channelId => dispatch(setChatChannelOpen(channelId)),
    clearUnreadMessages: channelId => dispatch(clearUnreadMessages(channelId)),
    getActiveVisits: doctorId => dispatch(getActiveVisits(doctorId)),
    setNavBlock: payload => dispatch(setNavBlock(payload)),
    setTutorial: (payload) => dispatch(setTutorial(payload)),
  }
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationContainer));