import React from 'react';
import WrongImg from "../Shared/assets/under-construction.svg";
import styled from "styled-components";
import Button from '../Shared/Button';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // console.log('err', error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.hasError) {
            return <Wrapper>
                <ErrorImg src={WrongImg} />
                <p>Coś poszło nie tak, nie zapomnij zgłosić błędu odpowiednim osobom.</p>
                <Button variant="primary" onClick={() => window.location.reload()}>Odśwież stronę</Button>
            </Wrapper>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

const Wrapper = styled.div`
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ErrorImg = styled.img`
  max-width: 400px;
  margin-bottom: 25px;
`;
