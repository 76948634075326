import axios from 'axios';

const REPORT_ERROR_REQUEST = "REPORT_ERROR_REQUEST";
const REPORT_ERROR_SUCCESS = "REPORT_ERROR_SUCCESS";
const REPORT_ERROR_FAILED = "REPORT_ERROR_FAILED";

export const sendErrorReport = (reportBody) => async (dispatch) => {
    dispatch(sendErrorReportRequest());
    try {
        await axios.post(`/report-error`, reportBody);
        dispatch(sendErrorReportSuccess());
    } catch (error) {
        dispatch(sendErrorReportFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
};

const sendErrorReportRequest = () => ({
    type: REPORT_ERROR_REQUEST,
});

const sendErrorReportSuccess = () => ({
    type: REPORT_ERROR_SUCCESS,
});

const sendErrorReportFail = (error) => ({
    type: REPORT_ERROR_FAILED,
    payload: error,
});

const initialState = {
    loading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REPORT_ERROR_REQUEST:
            return {
                ...state,
                loading: true
            };
        case REPORT_ERROR_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case REPORT_ERROR_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
