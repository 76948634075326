import axios from "axios";
import { ADD_VISIT_FROM_SOCKET, UPDATE_VISIT_FROM_SOCKET, DELETE_VISIT_FROM_SOCKET } from '../../sockets/calendar/reduxActions';
import { sortArrayHandler } from '../../features/Shared/utils';
const GET_VISITS_BY_DOCTORS_IDS_REQUEST = "GET_VISITS_BY_DOCTORS_IDS_REQUEST";
const GET_VISITS_BY_DOCTORS_IDS_SUCCESS = "GET_VISITS_BY_DOCTORS_IDS_SUCCESS";
const GET_VISITS_BY_DOCTORS_IDS_FAIL = "GET_VISITS_BY_DOCTORS_IDS_FAIL";
const GET_VISITS_BY_COMPANY_ID_REQUEST = "GET_VISITS_BY_COMPANY_ID_REQUEST";
const GET_VISITS_BY_COMPANY_ID_SUCCESS = "GET_VISITS_BY_COMPANY_ID_SUCCESS";
const GET_VISITS_BY_COMPANY_ID_FAIL = "GET_VISITS_BY_COMPANY_ID_FAIL";
const GET_CURRENT_VISIT_BY_ID_REQUEST = "GET_CURRENT_VISIT_BY_ID_REQUEST";
const GET_CURRENT_VISIT_BY_ID_SUCCESS = "GET_CURRENT_VISIT_BY_ID_SUCCESS";
const GET_CURRENT_VISIT_BY_ID_FAIL = "GET_CURRENT_VISIT_BY_ID_FAIL";
const ADD_VISIT_REQUEST = "ADD_VISIT_REQUEST";
const ADD_VISIT_SUCCESS = "ADD_VISIT_SUCCESS";
const ADD_VISIT_FAIL = "ADD_VISIT_FAIL";
const GET_DOCTOR_VISITS_REQUEST = "GET_DOCTOR_VISITS_REQUEST";
const GET_DOCTOR_VISITS_SUCCESS = "GET_DOCTOR_VISITS_SUCCESS";
const GET_DOCTOR_VISITS_FAIL = "GET_DOCTOR_VISITS_FAIL";
// const GET_VISITS_REQUEST = "GET_VISITS_REQUEST"
// const GET_VISITS_SUCCESS = "GET_VISITS_SUCCESS"
// const GET_VISITS_FAIL = "GET_VISITS_FAIL"

const GET_VISITS_BY_PATIENT_ID_REQUEST = "GET_VISITS_REQUEST";
const GET_VISITS_BY_PATIENT_ID_SUCCESS = "GET_VISITS_SUCCESS";
const GET_VISITS_BY_PATIENT_ID_FAIL = "GET_VISITS_FAIL";

const UPDATE_VISIT_REQUEST = "UPDATE_VISIT_REQUEST";
const UPDATE_VISIT_SUCCESS = "UPDATE_VISIT_SUCCESS";
const UPDATE_VISIT_FAIL = "UPDATE_VISIT_FAIL";

const DELETE_VISIT_REQUEST = "DELETE_VISIT_REQUEST";
const DELETE_VISIT_SUCCESS = "DELETE_VISIT_SUCCESS";
const DELETE_VISIT_FAIL = "DELETE_VISIT_FAIL";

const SET_INITIAL_VISITS = "SET_INITIAL_VISITS";
const SET_CURRENT_VISIT = "SET_CURRENT_VISIT";

const UPDATE_VISITS_FROM_PATIENT = "UPDATE_VISITS_FROM_PATIENT";

export const setCurrentVisit = (visit) => ({
  type: SET_CURRENT_VISIT,
  payload: visit
})

export const setInitialVisits = () => ({
  type: SET_INITIAL_VISITS
})
export const getDoctorDashbordVisits = (start, end, doctorId) => async dispatch => {
  dispatch(getDoctorDashboardVisitsRequest())
  try {
    const response = await axios.post(`/visit/doctor`, {
      dateRangeBegin: start,
      dateRangeEnd: end,
      doctorId: doctorId,
      patientFlag: true,
      doctorFlag: false
    });
    dispatch(getDoctorDashBoardVisitsSuccess(response.data))
  } catch (err) {
    dispatch(getDoctorDashbordVisitsFail(err))
  }
}

const getDoctorDashboardVisitsRequest = () => ({
  type: GET_DOCTOR_VISITS_REQUEST
})

const getDoctorDashBoardVisitsSuccess = (visits) => ({
  type: GET_DOCTOR_VISITS_SUCCESS,
  payload: visits
})

const getDoctorDashbordVisitsFail = (error) => ({
  type: GET_DOCTOR_VISITS_FAIL
})
export const getVisitsByDoctorsIds = (
  start,
  end,
  doctorsIds,
  roomsIds,
  doctorFlag,
  patientFlag,
  healthcenterId
) => async dispatch => {
  dispatch(getVisitsByDoctorsIdsRequest());
  try {
    const response = await axios.post(`/visit/doctors`, {
      rooms: roomsIds,
      doctors: doctorsIds,
      dateRangeBegin: start,
      dateRangeEnd: end,
      doctorFlag: doctorFlag,
      patientFlag: patientFlag,
      healthcenterId: healthcenterId
    });
    const { data } = response;
    dispatch(getVisitsByDoctorsIdsSuccess(data));
  } catch (error) {
    dispatch(getVisitsByDoctorsIdsFail(error));
  }
};

const getVisitsByDoctorsIdsRequest = () => ({
  type: GET_VISITS_BY_DOCTORS_IDS_REQUEST
});

const getVisitsByDoctorsIdsSuccess = visits => ({
  type: GET_VISITS_BY_DOCTORS_IDS_SUCCESS,
  payload: visits
});

const getVisitsByDoctorsIdsFail = error => ({
  type: GET_VISITS_BY_DOCTORS_IDS_FAIL,
  payload: error
});

export const getVisitsByCompanyId = (companyId, start, end) => async dispatch => {
  dispatch(getVisitsByCompanyIdRequest());
  try {
    const response = await axios.post(`/visit/company`, {
      companyId,
      dateRangeBegin: start,
      dateRangeEnd: end,
    });
    const { data } = response;
    dispatch(getVisitsByCompanyIdSuccess(data));
  } catch (error) {
    dispatch(getVisitsByCompanyIdFail(error));
  }
};

const getVisitsByCompanyIdRequest = () => ({
  type: GET_VISITS_BY_COMPANY_ID_REQUEST
});

const getVisitsByCompanyIdSuccess = visits => ({
  type: GET_VISITS_BY_COMPANY_ID_SUCCESS,
  payload: visits
});

const getVisitsByCompanyIdFail = error => ({
  type: GET_VISITS_BY_COMPANY_ID_FAIL,
  payload: error
});

export const getCurrentVisitById = visitId => async dispatch => {
  dispatch(getCurrentVisitByIddRequest());
  try {
    const response = await axios.get(`/visit/${visitId}`);
    const { data } = response;
    dispatch(getCurrentVisitByIddSuccess(data));
  } catch (error) {
    dispatch(getCurrentVisitByIddFail(error.response?.data?.message));
  }
};

const getCurrentVisitByIddRequest = () => ({
  type: GET_CURRENT_VISIT_BY_ID_REQUEST
});

const getCurrentVisitByIddSuccess = visit => ({
  type: GET_CURRENT_VISIT_BY_ID_SUCCESS,
  payload: visit
});

const getCurrentVisitByIddFail = error => ({
  type: GET_CURRENT_VISIT_BY_ID_FAIL,
  payload: error
});

export const addVisit = (visit, force = false) => async dispatch => {
  dispatch(addVisitRequest());
  try {
    const response = await axios.post(`/visit${force ? `/force` : ''}`, visit);
    const { data } = response;
    dispatch(addVisitSuccess(data));
    return response;
  } catch (error) {
    dispatch(addVisitFail(error.response?.data?.message));
    return error.response
  }
};

const addVisitRequest = () => ({
  type: ADD_VISIT_REQUEST
});

const addVisitSuccess = visit => ({
  type: ADD_VISIT_SUCCESS,
  payload: visit
});

const addVisitFail = error => ({
  type: ADD_VISIT_FAIL,
  payload: error
});

// export const getVisitsByDoctorId = (doctorsIds, start, end) => async dispatch => {
//     dispatch(getVisitsByDoctorIdRequest())
//     try {
//         const response = await axios.post(`/visit/doctors`, { doctors: doctorsIds, dateRangeBegin: start, dateRangeEnd: end }, {
//             headers: {
//                 Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`
//             }
//         });
//         const { data } = response;
//         dispatch(getVisitsByDoctorIdSuccess(data))
//     } catch (error) {
//         dispatch(getVisitsByDoctorIdFail(error.response?.data?.message));
//         throw error.response?.data?.message;
//     }
// }

// const getVisitsByDoctorIdRequest = () => ({
//     type: GET_VISITS_REQUEST
// })

// const getVisitsByDoctorIdSuccess = (visits) => ({
//     type: GET_VISITS_SUCCESS,
//     payload: visits
// })

// const getVisitsByDoctorIdFail = (error) => ({
//     type: GET_VISITS_FAIL,
//     payload: error
// })

const GET_ACTIVE_VISITS_REQUEST = "GET_ACTIVE_VISITS_REQUEST"
const GET_ACTIVE_VISITS_SUCCESS = "GET_ACTIVE_VISITS_SUCCESS"
const GET_ACTIVE_VISITS_FAIL = "GET_ACTIVE_VISITS_FAIL"

const ADD_ACTIVE_VISIT = "ADD_ACTIVE_VISIT"
const REMOVE_ACTIVE_VISIT = "REMOVE_ACTIVE_VISIT"

export const addActiveVisit = (visit) => ({
  type: ADD_ACTIVE_VISIT,
  payload: visit
})

export const removeActiveVisit = (visitId) => ({
  type: REMOVE_ACTIVE_VISIT,
  payload: visitId
})

export const getActiveVisits = (doctorId) => async dispatch => {
  dispatch(getActiveVisitsRequest())
  try {
    const response = await axios.get(`/visit/active/${doctorId}`);
    const { data } = response;
    dispatch(getActiveVisitsSuccess(data));
  } catch (error) {
    dispatch(getActiveVisitsFail(error.response?.data?.message));
  }
}

const getActiveVisitsRequest = () => ({
  type: GET_ACTIVE_VISITS_REQUEST
})

const getActiveVisitsSuccess = (visits) => ({
  type: GET_ACTIVE_VISITS_SUCCESS,
  payload: visits
})

const getActiveVisitsFail = (error) => ({
  type: GET_ACTIVE_VISITS_FAIL,
  payload: error
})

export const updateVisitsFromPatient = (patient) => ({
  type: UPDATE_VISITS_FROM_PATIENT,
  payload: patient
})

export const getVisitsByPatientId = (
  patientId,
  start = "1901-01-31T23:36:00.000Z",
  end = "9999-02-01T00:00:00.000Z",
  orderBy = "start desc",
  pageNumber = 1,
  pageSize = 5,
  doctorFlag = false
) => async (dispatch) => {
  dispatch(getVisitsByPatientIdRequest());
  try {
    const response = await axios.get(
      `/visit/patient/${patientId}?pageSize=${pageSize}&pageNumber=${pageNumber}&dateRangeBegin=${start}&dateRangeEnd=${end}&doctorFlag=${doctorFlag}&orderBy=${orderBy}`
    );
    const { data } = response;

    let paginationMetadata;
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination);
    }
    dispatch(getVisitsByPatientIdSuccess(data, paginationMetadata));
  } catch (error) {
    dispatch(getVisitsByPatientIdFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const getVisitsByPatientIdRequest = () => ({
  type: GET_VISITS_BY_PATIENT_ID_REQUEST
});

const getVisitsByPatientIdSuccess = (visits, paginationMetadata) => ({
  type: GET_VISITS_BY_PATIENT_ID_SUCCESS,
  payload: visits,
  paginationMetadata: paginationMetadata
});

const getVisitsByPatientIdFail = error => ({
  type: GET_VISITS_BY_PATIENT_ID_FAIL,
  payload: error
});

export const updateVisit = (visitId, newState, body, force = false) => async dispatch => {
  //console.log('update body:', body)
  dispatch(updateVisitRequest());
  try {
    const response = await axios.patch(
      `/visit/${visitId}?state=${newState}${force ? `&force=true` : ''}`,
      body
    );
    const { data } = response;
    //console.log('update response:', data)
    dispatch(updateVisitSuccess(data));
    return response;
  } catch (error) {
    dispatch(updateVisitFail(error));
    return error.response?.data?.message;
  }
};

const updateVisitRequest = () => ({
  type: UPDATE_VISIT_REQUEST
});

const updateVisitSuccess = visits => ({
  type: UPDATE_VISIT_SUCCESS,
  payload: visits
});

const updateVisitFail = error => ({
  type: UPDATE_VISIT_FAIL,
  payload: error
});

export const deleteVisit = (visitId) => async dispatch => {
  dispatch(deleteVisitRequest());
  try {
    await axios.delete(`/visit/${visitId}`);
    dispatch(deleteVisitSuccess(visitId));
  } catch (error) {
    dispatch(deleteVisitFail(error));
    throw error;
  }
};

const deleteVisitRequest = () => ({
  type: DELETE_VISIT_REQUEST
});

const deleteVisitSuccess = visitId => ({
  type: DELETE_VISIT_SUCCESS,
  payload: visitId
});

const deleteVisitFail = error => ({
  type: DELETE_VISIT_FAIL,
  payload: error
});

const initialState = {
  loading: false,
  data: [],
  error: null,
  currentVisit: {},
  companyVisits: [],
  activeVisits: [],
  paginationMetadata: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_VISITS_BY_DOCTORS_IDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_VISITS_BY_DOCTORS_IDS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_VISITS_BY_DOCTORS_IDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_VISITS_BY_COMPANY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_VISITS_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        companyVisits: action.payload,
        loading: false
      };
    case GET_VISITS_BY_COMPANY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ADD_VISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_VISIT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      };
    case ADD_VISIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_CURRENT_VISIT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_CURRENT_VISIT_BY_ID_SUCCESS:
      return {
        ...state,
        currentVisit: action.payload,
        loading: false
      };
    case GET_CURRENT_VISIT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_VISITS_BY_PATIENT_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_VISITS_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paginationMetadata: action.paginationMetadata,
        loading: false
      };
    case GET_VISITS_BY_PATIENT_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_VISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_VISIT_SUCCESS:
      return {
        ...state,
        data: state.data.map(el =>
          el._id === action.payload._id ? action.payload : el
        ),
        currentVisit: state.currentVisit._id === action.payload._id ? action.payload : state.currentVisit,
        loading: false
      };
    case UPDATE_VISIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_VISIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_VISIT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(el => el._id !== action.payload),
        loading: false
      };
    case DELETE_VISIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_DOCTOR_VISITS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_DOCTOR_VISITS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_DOCTOR_VISITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SET_CURRENT_VISIT:
      return {
        ...state,
        currentVisit: action.payload
      }
    case SET_INITIAL_VISITS:
      return {
        loading: false,
        data: [],
        error: null,
        currentVisit: null
      }
    case UPDATE_VISITS_FROM_PATIENT:
      console.log('tu sie bedzie dziao')
      return {
        ...state,
        // data: state.data.map(visit => visit.patient._id === action.payload._id ? { ...visit, patient: action.payload } : visit),
        // currentVisit: state.currentVisit.patient._id === action.payload._id ? { ...state.currentVisit, patient: action.payload } : state.currentVisit,
        data: [...state.data.map(visit => {
          const targetIndex = action.payload.findIndex(({ _id }) => _id === visit._id);
          if (targetIndex !== -1) {
            return action.payload[targetIndex];
          } else {
            return visit;
          }
        })]
      }
    //SOCKET CASES
    case ADD_VISIT_FROM_SOCKET:
      const newData = sortArrayHandler([...state.data, action.payload], 'start', 'date')
      return {
        ...state,
        data: newData
      }
    case UPDATE_VISIT_FROM_SOCKET:
      return {
        ...state,
        data: sortArrayHandler(state.data.map(visit => visit._id === action.payload._id ? action.payload : visit), 'start', 'date')
      };
    case DELETE_VISIT_FROM_SOCKET:
      return {
        ...state,
        data: state.data.filter(visit => visit._id !== action.payload)
      }
    // case ADD_VISIT_FROM_SOCKET:
    //   return {
    //     ...state,
    //     data: [...state.data, action.payload]
    //   }
    case GET_ACTIVE_VISITS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_ACTIVE_VISITS_SUCCESS:
      return {
        ...state,
        loading: false,
        activeVisits: action.payload
      }
    case GET_ACTIVE_VISITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case ADD_ACTIVE_VISIT:
      return {
        ...state,
        activeVisits: [...state.activeVisits, action.payload]
      }
    case REMOVE_ACTIVE_VISIT:
      return {
        ...state,
        activeVisits: state.activeVisits.filter(activeVisit => activeVisit._id !== action.payload)
      }
    default:
      return state;
  }
};
