import React, { useState } from "react";
import Modal from "./Modal";
import styled from "styled-components";
import Input from "../Input";
import Textarea from "../Textarea";
import Button from "../Button";
import { Formik } from "formik";
import * as Yup from "yup";
import EditImg from "../assets/edit-pen.svg";
import FileUpload from "../FileUpload";
import Avatar from "../../Shared/assets/avatar.svg";
import { toast } from 'react-toastify';

const patientValidationSchema = Yup.object().shape({
  name: Yup.string().required("Imię jest wymagane"),
  surname: Yup.string().required("Nazwisko jest wymagane"),
  pesel: Yup.string()
    .min(11, "Wymagane 11 cyfr")
    .max(11, "Wymagane 11 cyfr")
    .test('test-compare a few values', 'Pesel jest niepoprawny',
      function (value) {
        if (value && value !== '') {
          let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
          let sum = 0;
          let controlNumber = parseInt(value.substring(10, 11));
          for (let i = 0; i < weight.length; i++) {
            sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
          }
          sum = sum % 10;
          return 10 - sum === controlNumber;
        }
        else if (value === '' || !value) {
          return true
        }
      }),
  address: Yup.object().shape({
    houseNumber: Yup.string(),
  }),
  tel: Yup.string()
    .min(9, "Numer musi mieć minimum 9 cyfr")
    .required("Numer telefonu jest wymagany"),
  email: Yup.string().email("Nieprawidłowy email!"),
});

const PatientModal = ({
  modalState,
  setModalState,
  onSubmit,
  currentPatient,
  setPatientAvatar,
  updatePatientWithVisits,
  mergeWithDeletedPatient,
  nextTutorialStep
}) => {
  const [file, setFile] = useState(null),
    [questionModal, setQuestionModal] = useState({ shown: false, question: '', onAccept: null }),
    [infoModal, setInfoModal] = useState({ shown: false, info: null });
  let initialValues = {
    name: "",
    surname: "",
    pesel: "",
    tel: "",
    email: "",
    birthDate: "",
    fileNumber: "",
    address: {
      city: "",
      street: "",
      houseNumber: "",
      apartmentNumber: "",
      postalCode: "",
    },
    additionalInfo: "",
  };
  if (currentPatient) {
    const {
      name,
      surname,
      pesel,
      tel,
      email,
      additionalInfo,
      birthDate,
      address,
      fileNumber,
    } = currentPatient;
    initialValues = {
      name,
      surname,
      pesel,
      tel,
      email,
      birthDate,
      fileNumber,
      address: {
        city: address?.city,
        street: address?.street,
        houseNumber: address?.houseNumber,
        apartmentNumber: address?.apartmentNumber,
        postalCode: address?.postalCode,
      },
      additionalInfo,
    };
  };
  const closeAllModals = () => {
    setQuestionModal({ shown: false, question: '', onAccept: null });
    setInfoModal({ shown: false, info: null });
    setModalState(false);
  };
  return (
    <StyledModal
      title={currentPatient ? "Edycja pacjenta" : "Dodawanie pacjenta"}
      exitButton={true}
      onCancel={() => {
        setFile(null);
        setModalState(false);
        if (nextTutorialStep) {
          nextTutorialStep();
        }
      }}
      show={modalState}
    >
      <Modal
        type="SimpleQuestionModal"
        show={questionModal.shown}
        question={questionModal.question}
        acceptText="Tak"
        cancelText="Nie"
        onAccept={questionModal.onAccept}
        onCancel={() => setQuestionModal({ shown: false, question: '', onAccept: null })}
      />
      <Modal
        type="SimpleInfoModal"
        show={infoModal.shown}
        info={infoModal.info}
        acceptText="Ok"
        onAccept={() => setInfoModal({ shown: false, info: null })}
        onCancel={() => setInfoModal({ shown: false, info: null })}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={patientValidationSchema}
        onSubmit={async (values, { setFieldError }) => {
          if (currentPatient) {
            if (file) {
              const formData = new FormData();
              formData.append("patientAvatar", file);
              await setPatientAvatar(currentPatient._id, formData);
            }
            const res = await onSubmit({ ...values, _id: currentPatient._id });
            if (res?.status === 409) {
              return setFieldError('pesel', 'Pacjent o takim peselu już istnieje!');
            }
            if (res && res.status === 300) {
              return setQuestionModal({
                shown: true,
                question: 'Pacjent o takim peselu już istnieje, ale został usunięty. Czy chcesz go przywrócić?',
                onAccept: () => {
                  mergeWithDeletedPatient(currentPatient._id, { deletedPatientId: res.data.data._id, ...values });
                  setQuestionModal({ shown: false, question: '', onAccept: null });
                  setModalState(false);
                  setFile(null);
                  toast('Pacjent został przywrócony.', { type: 'success' });
                }
              })
            }
            toast('Dane pacjenta zostały zapisane.', { type: 'success' });
          } else {
            const res = await onSubmit(values);
            if (nextTutorialStep) {
              nextTutorialStep();
            }
            if (res?.status === 409) {
              return setFieldError('pesel', 'Pacjent o takim peselu już istnieje!');
            }
            if (res?.status === 300) {
              return setQuestionModal({
                shown: true,
                question: 'Pacjent o takim peselu już istnieje, ale został usunięty. Czy chcesz go przywrócić?',
                onAccept: () => {
                  updatePatientWithVisits({ _id: res.data.data._id, deleted: false });
                  setQuestionModal({ shown: false, question: '', onAccept: null });
                  setModalState(false);
                  setFile(null);
                  toast('Pacjent został przywrócony.', { type: 'success' });
                }
              })
            }
            if (file && res?._id) {
              const formData = new FormData();
              formData.append("patientAvatar", file);
              await setPatientAvatar(res._id, formData);
            }
            toast('Pacjent został dodany.', { type: 'success' });
          }
          setModalState(false);
          setFile(null);
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <StyledFileUpload
              label="Plik dokumentu"
              onDrop={(files) => {
                const filesWithPreview = files.map(file => Object.assign(file, {
                  preview: URL.createObjectURL(file)
                }))
                setFile(filesWithPreview[0]);
              }}
              value={file}
            >
              <AvatarWrapper EditImg={EditImg}>
                <AvatarImg key={file} src={file !== null ? file.preview : !currentPatient?.avatar ? Avatar : `${process.env.REACT_APP_SRV_URL}${currentPatient?.avatar}`} />
              </AvatarWrapper>
            </StyledFileUpload>
            <FlexBox>
              <Column>
                <ColumnLabel>Dane pacjenta</ColumnLabel>
                <InputRow>
                  <Input
                    type="text"
                    name="name"
                    label="Imię"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name}
                  />
                  <Input
                    type="text"
                    name="surname"
                    label="Nazwisko"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.surname}
                    error={errors?.surname}
                  />
                </InputRow>
                <InputRow>
                  <Input
                    type="text"
                    name="tel"
                    label="Numer telefonu"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tel}
                    error={errors.tel}
                  />
                  <Input
                    type="text"
                    name="email"
                    label="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email}
                  />
                </InputRow>
                <InputRow>
                  <Input
                    type="text"
                    name="pesel"
                    label="PESEL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pesel}
                    error={errors.pesel}
                    noWrapError={true}
                  />
                  <Input
                    type="text"
                    name="fileNumber"
                    label="Numer kartoteki"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fileNumber}
                    error={errors.fileNumber}
                  />
                </InputRow>
              </Column>
              <Column>
                <ColumnLabel>Adres</ColumnLabel>
                <Input
                  type="text"
                  name="address.street"
                  label="Ulica"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.address?.street}
                  error={errors?.address?.street}
                />
                <InputRow>
                  <Input
                    type="text"
                    name="address.houseNumber"
                    label="Numer budynku"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.address?.houseNumber}
                    error={errors?.address?.houseNumber}
                  />
                  <Input
                    type="text"
                    name="address.apartmentNumber"
                    label="Numer mieszkania"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.address?.apartmentNumber}
                    error={errors?.address?.apartmentNumber}
                  />
                </InputRow>
                <InputRow>
                  <Input
                    type="text"
                    name="address.city"
                    label="Miasto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.address?.city}
                    error={errors?.address?.city}
                  />
                  <Input
                    type="text"
                    name="address.postalCode"
                    label="Kod pocztowy"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.address?.postalCode}
                    error={errors?.address?.postalCode}
                  />
                </InputRow>
              </Column>
            </FlexBox>
            <Textarea
              name="additionalInfo"
              label="Dodatkowe informacje"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.additionalInfo}
              error={errors.additionalInfo}
            />
            <CenterBox>
              <Button type="submit" variant="primary">
                {currentPatient ? 'Zapisz zmiany' : 'Dodaj pacjenta'}
              </Button>
              {(currentPatient && !currentPatient.deleted) &&
                <Button type="button" variant="danger" onClick={() => setQuestionModal({
                  question: "Czy na pewno chcesz usunąć tego pacjęta?",
                  shown: true,
                  onAccept: async () => {
                    const resp = await onSubmit({ deleted: true, _id: currentPatient._id });
                    console.log(resp);
                    if (!resp) {
                      setQuestionModal({ shown: false, question: '', onAccept: null });
                      setModalState(false);
                    } else if (resp.status === 300) {
                      setQuestionModal({
                        question: "Pacjent ma zaplanowane wizyty. Czy na pewno chcesz go usunąć? Spowoduje to automatyczne odwołanie zaplanowanych wizyt tego pacjenta.",
                        shown: true,
                        onAccept: () => {
                          onSubmit({ deleted: true, _id: currentPatient._id, forceDelete: true });
                          closeAllModals();
                        }
                      })
                    } else if (resp.status === 403) {
                      setInfoModal({ shown: true, info: 'Pacjent ma rozpoczęte wizyty! Dokończ wizytę zanim go usuniesz.' })
                    }
                  }
                })}>
                  Usuń pacjenta
                </Button>
              }
            </CenterBox>
          </Form>
        )}
      </Formik>
    </StyledModal>
  );
};

export default PatientModal;

const Form = styled.form`
  width: 650px;
  overflow-y:auto;
  max-height:83.5vh;
  padding-bottom:10px;
`;

const CenterBox = styled.div`
  text-align: center;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: ${({ childWidth }) => childWidth ? childWidth : '48%;'};
  }
`;

const AvatarImg = styled.img`
  border-radius: 50%;
  min-width: 150px;
  width: 150px;
  height: 150px;
`;

const AvatarWrapper = styled.div`
  position: relative;
  width: 150px;
  margin: 0 auto;
  cursor: pointer;
  ::after {
    content: ${({ EditImg }) => `url(${EditImg})`};
    width: 40px;
    height: 40px;
    position: absolute;
    background-color: #d0d0d0;
    border-radius: 50%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledModal = styled(Modal)`
  max-height:90vh;
`;

const FlexBox = styled.div`
  display:flex;
  justify-content:space-between;
`;

const Column = styled.div`
  width:48%;
`;

const ColumnLabel = styled.p`
  font-weight:600;
  color:${({ theme }) => theme.lightBlack};
  border-bottom: 1px solid #ccc;
  margin-bottom:5px;
  text-align:center;
`;

const StyledFileUpload = styled(FileUpload)`
  width:150px;
  margin:0 auto;
`;