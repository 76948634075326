import React from "react";
import styled from "styled-components";
import Container from '../../Shared/Container';
import StatsHeader from '../../Shared/StatsHeader';
import { theme } from '../../Shared/theme';
import { AreaChart, Cell, XAxis, YAxis, Tooltip, Area, CartesianGrid, ReferenceLine, PieChart, Pie, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const IncomeView = ({
  income,
  incomeData,
  currentDate,
  changeCurrentDateHandler,
  tablePagi,
  tablePagiHandler,
  servicesData,
  visitsData,
  healthcentersData,
  currentView,
  changeViewHandler,
}) => {
  const tableElements = incomeData.filter(({ date }) => parseInt(date.substring(0, 2)) >= tablePagi[0] && parseInt(date.substring(0, 2)) <= tablePagi[1]);
  const usedServices = servicesData.filter(({ count }) => count > 0);
  const pieColors = [theme.secondary, theme.green, theme.yellow];
  return (
    <Wrapper>
      <StatsHeader
        currentView={currentView}
        changeViewHandler={changeViewHandler}
        changeCurrentDateHandler={changeCurrentDateHandler}
        currentDate={currentDate}
      />
      <Content>
        <StyledContainer
          title="Przychody"
        >
          <ChartHeader>
            <Heading>
              <Label>Dzisiaj</Label>
              <Value>{income.today} zł</Value>
            </Heading>
            <Heading>
              <Label>Ten miesiąc</Label>
              <Value>{income.month} zł</Value>
            </Heading>
            <Heading>
              <Label>Liczba wizyt</Label>
              <Value>{visitsData.all}</Value>
            </Heading>
          </ChartHeader>
          <ResponsiveContainer width='100%' height={200}>
            <AreaChart data={incomeData}
              margin={{ top: 5, right: 50, left: 0, bottom: 5 }}>
              <defs>
                <linearGradient id="incomeGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={theme.primary} stopOpacity={.6} />
                  <stop offset="95%" stopColor={theme.primary} stopOpacity={.1} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={(val) => (val.substring(0, 2))} />
              <YAxis domain={[0, Math.round((income.month + 500) / 100) * 100]} />
              <Tooltip
                content={CustomTooltip}
              />
              <ReferenceLine x={moment().format('DD.MM.YYYY')} stroke={theme.danger} />
              <Area type="monotone" dataKey="sum" stroke={theme.primary} fillOpacity={1} fill="url(#incomeGradient)" />
            </AreaChart>
          </ResponsiveContainer>
          <InfoGrid>
            <InfoBox color={theme.primary}>
              <Big>{visitsData.endedVisits.length}</Big>
              <Small>Zakończonych <br />wizyt</Small>
            </InfoBox>
            <InfoBox color={theme.green}>
              <Big>{visitsData.paidVisits.length}</Big>
              <Small>Opłaconych <br />wizyt</Small>
            </InfoBox>
            <InfoBox color={theme.danger}>
              <Big>{visitsData.notPaidVisits.length}</Big>
              <Small>Nieopłaconych <br />wizyt</Small>
            </InfoBox>
          </InfoGrid>
          <Table colsTemplate="1fr 1fr 1fr" minHeight="225px">
            <TRow>
              <THeading>Data</THeading>
              <THeading>Przychód</THeading>
              <THeading>Liczba wizyt</THeading>
            </TRow>
            {tableElements.map(({ date, income, visits }, index) => (
              <TRow key={`${date}_${index}`}>
                <TValue>{date}</TValue>
                <TValue>{income} zł</TValue>
                <TValue>{visits}</TValue>
              </TRow>
            ))}
          </Table>
          <TNav>
            <TNavControl disabled={tablePagi[0] === 1} onClick={() => tablePagiHandler('prev')}>Poprzednie</TNavControl>
            <TNavLabel>{`${tablePagi[0]} - ${tablePagi[1] === 35 ? 31 : tablePagi[1]}`}</TNavLabel>
            <TNavControl disabled={tablePagi[0] === 29} onClick={() => tablePagiHandler('next')}>Następne</TNavControl>
          </TNav>
        </StyledContainer>
        <Col>
          <StyledContainer
            title='Procedury wykonane w tym miesiącu'
          >
            {usedServices.length > 0 ?
              <Table colsTemplate="2fr 1fr 1fr 1fr">
                <TRow>
                  <THeading>Nazwa</THeading>
                  <THeading>Ilość</THeading>
                  <THeading>Cena</THeading>
                  <THeading>Przychód</THeading>
                </TRow>
                {usedServices.map(({ count, name, price }, index) => (
                  <TRow key={`${name}_${index}`}>
                    <TValue align='left'>{name}</TValue>
                    <TValue>{count}</TValue>
                    <TValue>{price} zł</TValue>
                    <TValue>{parseInt(price) * parseInt(count)} zł</TValue>
                  </TRow>
                ))}
              </Table>
              :
              <NoData>
                W tym miesiącu nie wykonano żadnych usług
            </NoData>
            }
          </StyledContainer>
          {currentView.type !== 'healthcenter' && <StyledContainer
            title='Placówki'
          >
            {healthcentersData.length > 0 ?
              <>
                <Table colsTemplate="1fr 1fr 1fr">
                  <TRow>
                    <THeading>Nazwa</THeading>
                    <THeading>Ilość wizyt</THeading>
                    <THeading>Przychód</THeading>
                  </TRow>
                  {healthcentersData.map(({ name, visits, sum }, index) => (
                    <TRow key={`${name}_${index}`}>
                      <TValue align='left'>{name}</TValue>
                      <TValue>{visits.length}</TValue>
                      <TValue>{sum} zł</TValue>
                    </TRow>
                  ))}
                </Table>
                {healthcentersData.filter(el => el.visits.length > 0).length > 0 &&
                  <PieBox>
                    <ResponsiveContainer width='100%' height={250}>
                      <PieChart>
                        <Pie
                          label={(entry) => entry.name}
                          data={healthcentersData}
                          dataKey="sum"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={85}
                          fill={theme.primary}
                        >
                          {
                            healthcentersData.map((entry, index) => (
                              <Cell key={`cell_${index}`} fill={pieColors[index]} strokeWidth={2} />
                            ))
                          }
                        </Pie>
                        <Tooltip
                          formatter={(income) => (`${income} zł`)}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </PieBox>
                }
              </>
              :
              <NoData>
                Lekarz nie jest przypisany do żadnej placówki
            </NoData>
            }
          </StyledContainer>}
        </Col>
      </Content>
    </Wrapper>
  );
};

export default IncomeView;

const CustomTooltip = (props) => {
  const payload = props?.payload ? props?.payload[0]?.payload : {};
  return (
    <ToolTipWrapper>
      <TooltipDate>{payload?.date}</TooltipDate>
      <TooltipInfo>
        <TooltipLabel>Przychód: </TooltipLabel>
        <TooltipValue>{payload?.income} zł</TooltipValue>
      </TooltipInfo>
      <TooltipInfo>
        <TooltipLabel>Miesięczny przychód: </TooltipLabel>
        <TooltipValue>{payload?.sum} zł</TooltipValue>
      </TooltipInfo>
      <TooltipInfo>
        <TooltipLabel>Wizyty: </TooltipLabel>
        <TooltipValue>{payload?.visits}</TooltipValue>
      </TooltipInfo>
    </ToolTipWrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding:10px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content:space-between;
  flex-wrap:wrap;
  max-width:1365px;
  margin: 0 auto;
  >div{
    width: 100%;
    margin:5px 0;
  }
  ${({ theme }) => `${theme.mq.desktop}{
    height: calc(100vh - 220px);
    flex-wrap: nowrap;
    >div{
      width: calc(50% - 7.5px);
    }
  }`}
`;

const StyledContainer = styled(Container)`
  margin:0;
`;

const ChartHeader = styled.ul`
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin: 0 80px 10px;
`;

const Heading = styled.li`
  display:flex;
  flex-direction:column;
`;

const Label = styled.span`
  font-weight:300;
  font-size:12px;
  color:${({ theme }) => theme.lightBlack};
`;

const Value = styled.span`
  font-weight:600;
  font-size:20px;
  margin-left:10px;
  color:${({ theme }) => theme.primary};
`;

const Col = styled.div`
  display:flex;
  flex-direction:column;
  >div:first-child{
    margin-bottom: 10px;
  }
`;

const Table = styled.div`
  min-height:${({ minHeight }) => minHeight ? minHeight : '0'};
  grid-template-columns: ${({ colsTemplate }) => colsTemplate};
`;

const TRow = styled.div`
  display:grid;
  grid-template-columns: inherit;
  padding: 5px;
  &:not(:first-child){
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const THeading = styled.span`
  font-size:14px;
  font-weight:600;
  text-align:center;
  color:${({ theme }) => theme.lightBlack};
`;

const TValue = styled.span`
  font-size:14px;
  text-align:${({ align }) => align ? align : 'center'};
`;

const TNav = styled.div`
  display:flex;
  justify-content:flex-end;
  font-size:12px;
  margin-top:10px;
  margin-right:10px;
`;

const TNavControl = styled.span`
  font-size:12px;
  cursor:pointer;
  transition:300ms;
  color:${({ theme }) => theme.lightBlack};
  user-select:none;
  ${({ disabled }) => disabled && 'opacity:0;pointer-events:none;'}
  &:hover{
    text-decoration:underline;
  }
`;

const TNavLabel = styled.span`
  font-size:12px;
  padding:0 3px;
  text-align:center;
  width:60px;
  font-weight:600;
`;

const ToolTipWrapper = styled.div`
  padding:5px;
  border: 1px solid #ccc;
  border-radius:4px;
  background-color:#fff;
  box-shadow:0 4px 6px #ccc;
`;

const TooltipLabel = styled.span`
  font-size:12px;
`;

const TooltipValue = styled.span`
  font-size:12px;
  font-weight:600;
`;

const TooltipInfo = styled.div``;

const TooltipDate = styled.p`
  font-size:14px;
`;

const InfoGrid = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content:center;
  max-width: 450px;
  margin: 10px auto 30px;
`;

const InfoBox = styled.div`
  color:${({ color }) => color};
  text-align:center;
`;

const Big = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: inherit;
`;

const Small = styled.div`
  font-size: 12px;
  color: inherit;
`;

const PieBox = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-top:20px;
`;

const NoData = styled.p`
  font-weight:600;
  font-size:18px;
  padding:15px 0;
  text-align:center;
`;