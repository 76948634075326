import React from "react";
import styled from "styled-components";
import Container from "../../Shared/Container";
import Button from "../../Shared/Button";
import StatusPill from "../../Shared/StatusPill";
import moment from "moment";

import { ReactComponent as emailIcon } from "../../Shared/assets/mail.svg";
import { ReactComponent as phoneIcon } from "../../Shared/assets/black-phone.svg";
import { ReactComponent as calendarStepIcon } from "../../Shared/assets/calendar-week.svg";
import { ReactComponent as calendarDayIcon } from "../../Shared/assets/calendar-day.svg";
import AvatarImg from "../../Shared/assets/avatar.svg";
import UserModal from "./modals/UserModal";
import SettingsModal from "./modals/SettingsModal";

const ReceptionistProfileView = ({
  user: { name, surname, avatar, email, tel, _id, tutorials },
  userRole,
  doctors,
  userModalState,
  setUserModalState,
  updateAuthenticatedUser,
  updateAuthenticatedUserRole,
  setUserAvatar,
  settingsModal,
  setSettingsModal,
}) => {
  return (
    <Wrapper>
      <UserModal
        modalState={userModalState}
        setModalState={setUserModalState}
        user={{ name, surname, tel, avatar, email, _id }}
        registryId={userRole?._id}
        callendarSettings={{ min: userRole?.callendarSettings.min, max: userRole?.callendarSettings.max, step: userRole?.callendarSettings.step }}
        updateAuthenticatedUser={updateAuthenticatedUser}
        updateAuthenticatedUserRole={updateAuthenticatedUserRole}
        setUserAvatar={setUserAvatar}
      />
      <SettingsModal
        modalState={settingsModal}
        setModalState={setSettingsModal}
        tutorials={tutorials}
        userId={_id}
        updateAuthenticatedUser={updateAuthenticatedUser}
      />
      <Column>
        <StyledContainer title="Profil" main>
          <Button variant="edit" onClick={() => setUserModalState(true)} title='Edytuj profil' />
          <User>
            <Main>
              <Avatar src={!avatar ? AvatarImg : `${process.env.REACT_APP_SRV_URL}${avatar}`} />
              <UserName>{name} {surname}</UserName>
            </Main>
            <Details>
              <DetailItem>
                <BlackEmailIcon />
                {email}
              </DetailItem>
              <DetailItem>
                <BlackPhoneIcon />
                {tel ? tel : "Brak"}
              </DetailItem>
              <DetailItem>
                <BlackCalendarStepIcon />
                {`${userRole?.callendarSettings.step} min`}
              </DetailItem>
              <DetailItem>
                <BlackCalendarDayIcon />
                {`${moment(userRole?.callendarSettings.min).format("HH:mm")} - ${moment(userRole?.callendarSettings.max).format(
                  "HH:mm"
                )}`}
              </DetailItem>
            </Details>
          </User>
        </StyledContainer>
        <StyledContainer
          title="Ustawienia"
          actionButton='edit'
          actionButtonTitle='Edytuj ustawienia'
          actionButtonOnClick={() => setSettingsModal(true)}
        >
          <SettingList>
            <SettingItem>
              Ukończonych samouczków: {tutorials && Object.values(tutorials).filter(value => value).length}/{tutorials && Object.keys(tutorials).length}
            </SettingItem>
          </SettingList>
        </StyledContainer>
      </Column>
      <StyledContainer title="Lekarze">
        <DocList>
          {doctors.length > 0 ? (
            doctors.map(
              ({ visitColor, isActive, userId: { avatar, name, surname, _id } }) => (
                <DocItem key={_id}>
                  <DocMain>
                    <AvatarDoctors size="small" src={!avatar ? AvatarImg : `${process.env.REACT_APP_SRV_URL}${avatar}`} />
                    <Name>{name} {surname}</Name>
                  </DocMain>
                  <DocStatus>
                    {isActive ? (
                      <StatusPill type="positive">Aktywny</StatusPill>
                    ) : (
                        <StatusPill type="negative">Zablokowany</StatusPill>
                      )}
                  </DocStatus>
                  <ColorSquare color={visitColor} />
                </DocItem>
              )
            )
          ) : (
              <p>Brak lekarzy dla tej firmy</p>
            )}
        </DocList>
      </StyledContainer>
    </Wrapper>
  );
};

export default ReceptionistProfileView;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px 40px;
  flex-direction:column;
  ${({ theme }) => `${theme.mq.desktop}{
    flex-direction:row;
    justify-content: space-between;
    padding: 30px 60px;
  }`}
`;

const StyledContainer = styled(Container)`
  position: relative;
  width: 100%;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 48%;
  }`}
`;
const UserName = styled.p`
    font-size: 18px;
    margin-top: 10px;
    font-weight: 600;
`;
const User = styled.div`
  display: flex;
`;

const Details = styled.ul`
  padding-left: 10px;
`;

const DetailItem = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding-left: 25px;
  margin: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
  svg {
    position: absolute;
    left: 0;
  }
`;

const Main = styled.div`
  width: 160px;
  text-align: center;
`;

const Name = styled.p`
  padding-left: 15px;
    margin: 0;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    `;

const Avatar = styled.img`
  border-radius: 50%;
  min-width: 150px;
  width: 150px;
  height: 150px;
`;
const AvatarDoctors = styled.img`
border-radius: 50%;
    width: 40px;
    min-width: 40px;
    height: 40px;
`;

const DocList = styled.div`
  max-height: 600px;
  overflow-y: scroll;
`;

const DocItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 4px;
  margin: 14px 0;
  border: 1px solid ${({ theme }) => theme.grey};
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const DocMain = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  & > p {
    padding-left: 15px;
    margin: 0;
    text-align: left;
    color: ${({ theme }) => theme.lightBlack};
  }
`;

const DocStatus = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
`;

const ColorSquare = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => `${color}`};
  border-radius: 4px;
`;

const BlackEmailIcon = styled(emailIcon)`
  transform: scale(1.1);
  path {
    fill: #000;
  }
`;
const BlackPhoneIcon = styled(phoneIcon)`
  height: 15px;
  margin-left: 2.5px;
`;
const BlackCalendarStepIcon = styled(calendarStepIcon)`
  height: 15px;
  path {
    fill: #000;
  }
`;
const BlackCalendarDayIcon = styled(calendarDayIcon)`
  height: 15px;
  path {
    fill: #000;
  }
`;

const SettingList = styled.ul`
  margin: 0;
`;

const SettingItem = styled.li`
  font-size: 12px;
  text-align: center;
  padding: 3px 0;
`;

const Column = styled.div`
  width:48%;
  >div{
    width:100%
  }
`;