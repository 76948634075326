import React from 'react';
import styled from 'styled-components';
import Container from '../../../../Shared/Container';
import moment from 'moment';
import EditPenIconUnderline from '../../../../Shared/assets/edit-pen-underline.svg';
import HospitalizationsModal from '../../modals/HospitalizationsModal';
import ProceduresModal from '../../modals/ProceduresModal';
import DeleteModal from "../../modals/DeleteModal";

const HospitalizationsTabView = ({
  currentTab,
  hospitalizationModal,
  setHospitalizationModal,
  proceduresModal,
  setProceduresModal,
  patientHospitalizations,
  updatePatientHospitalizationHandler,
  getMedicalDataHandler,
  diagnosis,
  hospitals,
  wards,
  procedures,
  vaccinations,
  deleteModal,
  setDeleteModal,
}) => {
  return (
    <Wrapper>
      <HospitalizationsModal
        modalState={hospitalizationModal}
        setModalState={setHospitalizationModal}
        getMedicalDataHandler={getMedicalDataHandler}
        patientHospitalizations={patientHospitalizations}
        diagnosis={diagnosis}
        hospitals={hospitals}
        wards={wards}
        updatePatientHospitalizationHandler={updatePatientHospitalizationHandler}
        setDeleteModal={setDeleteModal}
      />
      <ProceduresModal
        modalState={proceduresModal}
        setModalState={setProceduresModal}
        procedures={procedures}
        vaccinations={vaccinations}
        getMedicalDataHandler={getMedicalDataHandler}
        patientHospitalizations={patientHospitalizations}
        updatePatientHospitalizationHandler={updatePatientHospitalizationHandler}
      />
      <DeleteModal
        modalState={deleteModal.shown}
        setModalState={setDeleteModal}
        onAccept={deleteModal.onAccept}
        question={deleteModal.question}
      />
      <div>
        <StyledContainer
          actionButton='add'
          actionButtonOnClick={() => setHospitalizationModal({ shown: true, editId: null })}
          actionButtonTitle='Dodaj hospitalizacje'
          title="Lista hospitalizacji">
          <List>
            {patientHospitalizations.hospitalizations?.length > 0 ?
              patientHospitalizations.hospitalizations.map(({ _id, admissionDate, dischargeDate, diagnosis, hospital, ward }) => (
                <HospitalizationItem key={_id}>
                  <EditPenUnderline src={EditPenIconUnderline} onClick={() => setHospitalizationModal({ shown: true, editId: _id })} />
                  <Dates>
                    <Start>od: {moment(admissionDate).format('DD.MM.YYYY')}</Start>
                    <End>do: {moment(dischargeDate).format('DD.MM.YYYY')}</End>
                  </Dates>
                  {diagnosis.length > 0 ?
                    diagnosis.map(({ subcategoryCode, subcategory }, index) =>
                      <Diagnose key={`${subcategoryCode}_${index}`}>{subcategoryCode} {subcategory}</Diagnose>
                    )
                    :
                    <Diagnose>Brak diagnoz</Diagnose>
                  }
                  <Location><span>{hospital?.name}</span> <span>{ward?.name}</span></Location>
                </HospitalizationItem>
              ))
              :
              <NoItems>Brak</NoItems>
            }
          </List>
        </StyledContainer>
      </div>
      <div>
        <StyledContainer
          actionButton='edit'
          actionButtonOnClick={() => setProceduresModal({ shown: true, target: 'icd9s', editId: null })}
          actionButtonTitle='Edytuj zabiegi'
          title="Lista zabiegów">
          <List>
            {patientHospitalizations.medicalProcedures?.length > 0 ?
              patientHospitalizations.medicalProcedures.map(({ code, name, executionDate }) => (
                <ListItem>
                  <Left>
                    <Code>{code}</Code>
                    <Name>{name}</Name>
                  </Left>
                  <Start>{moment(executionDate).format('DD.MM.YYYY')}</Start>
                </ListItem>
              ))
              :
              <NoItems>Brak</NoItems>
            }
          </List>
        </StyledContainer>
        <StyledContainer
          actionButton='edit'
          actionButtonTitle='Edytuj szczepienia'
          actionButtonOnClick={() => setProceduresModal({ shown: true, target: 'vaccinations', editId: null })}
          title="Szczepienia">
          <List>
            {patientHospitalizations.vaccinations?.length > 0 ?
              patientHospitalizations.vaccinations.map(({ name, executionDate }) => (
                <ListItem>
                  <Name>{name}</Name>
                  <Start>{moment(executionDate).format('DD.MM.YYYY')}</Start>
                </ListItem>
              ))
              :
              <NoItems>Brak</NoItems>
            }
          </List>
        </StyledContainer>
      </div>
    </Wrapper>
  )
}

export default HospitalizationsTabView;

const StyledContainer = styled(Container)`
  position:relative;
`;

const Wrapper = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
  height:100%;
  width:100%;
`;

const List = styled.ul`
  margin:0;
  max-height: 235px;
  overflow:auto;
`;

const HospitalizationItem = styled.li`
  position:relative;
  font-size:14px;
  padding: 10px 35px 10px 5px;
  transition:300ms;
  &:not(:last-child){
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
  &:hover{
    background-color:#f9f9f9;
  }
`;

const ListItem = styled.li`
  display: flex;
  justify-content:space-between;
  padding:5px;
  &:not(:last-child){
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const NoItems = styled.p`
  font-size:16px;
  margin:10px;
`;

const Dates = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom:5px;
`;

const Start = styled.p``;

const End = styled.p``;

const Diagnose = styled.p`
  font-weight:600;
`;

const Location = styled.p`
  font-size:12px;
  span{
    display:block;
  }
`;

const EditPenUnderline = styled.img`
  transition:300ms;
  position:absolute;
  top:7.5px;
  right:7.5px;
  width: 17.5px;
  height: 17.5px;
  cursor: pointer;
  filter: invert(81%) sepia(0%) saturate(51%) hue-rotate(195deg) brightness(86%) contrast(81%);
  &:hover {
    filter: invert(98%) sepia(0%) saturate(476%) hue-rotate(144deg) brightness(89%) contrast(81%);
  }
`;

const Name = styled.p``;

const Code = styled.p`
  font-weight:600;
  padding-right:5px;
`;

const Left = styled.span`
    display:flex;
`;