import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { getShortenString } from './utils';

const InfoPill = ({
    variant = 'primary',
    iconPosition = 'center',
    to,
    children,
    className,
    noWrap,
    maxLength = 160,
    collapsedText
}) => (
        to ?
            <PillLink noWrap={noWrap} to={to} variant={variant} className={className} iconPosition={iconPosition}>
                {children}
                {collapsedText ? getShortenString(collapsedText, maxLength, false) : ''}
            </PillLink >
            :
            <Pill noWrap={noWrap} variant={variant} className={className} iconPosition={iconPosition}>
                {children}
                {collapsedText ? getShortenString(collapsedText, maxLength, false) : ''}
            </Pill>
    );

export default InfoPill;

const pillStyles = css`
  background-color: #f0f5ff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 3px 9px;
  margin: 6px;
  font-size: 12px;
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
  transition:300ms;
  ${({ noWrap }) => noWrap && `white-space:nowrap;`};
  ${({ iconPosition }) =>
        iconPosition === 'center' ? `
        align-items:center;
    ` : iconPosition === 'top' ? `
        align-items:flex-start;
    ` : iconPosition === 'bottom' ? `
        align-items:flex-end;
    `: ``
    }
  ${({ variant, theme }) =>
        variant === 'primary' ? `background-color: f0f5ff; color: ${theme.primary};` :
            variant === 'yellow' ? `background-color: ${theme.lightYellow}; color: ${theme.yellow};` :
                variant === 'danger' ? `background-color: ${theme.lightDanger}; color: ${theme.danger};` :
                    ''
    }
`;

const Pill = styled.div`
  ${pillStyles}
`;

const PillLink = styled(Link)`
  text-decoration:none;
  ${pillStyles}
`;