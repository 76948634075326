import React, { useState, useEffect } from 'react';
import ElementsListView from './ElementsListView';
import { connect } from 'react-redux';
import { getDoctorsVisitElements, addVisitElement, deleteVisitElement, updateVisitElement, importVisitElement, exportVisitElement } from '../../../redux/modules/visitElement';
import { getDoctorsElementsSet, addElementsSet, deleteElementsSet, updateElementsSet, importElementsSet, exportElementsSet } from '../../../redux/modules/visitElementsSet';
import { useHistory } from 'react-router-dom';
import { nextTutorialStep } from '../../../redux/modules/tutorial'
import { elementsCategories } from '../../Shared/enums';

const ElementsListContainer = ({
    userRole,
    doctorsElements,
    getDoctorsVisitElements,
    addVisitElement,
    deleteVisitElement,
    updateVisitElement,
    importVisitElement,
    exportVisitElement,
    getDoctorsElementsSet,
    addElementsSet,
    deleteElementsSet,
    updateElementsSet,
    doctorsElementsSets,
    importElementsSet,
    exportElementsSet,
    nextTutorialStep,
    tutorialStep,
}) => {
    const [nameTakenModal, setNameTakenModal] = useState({ shown: false, target: null, onBoth: null, onReplace: null }),
        [warningModal, setWarningModal] = useState({ shown: false, question: null, onAccept: null }),
        [importModal, setImportModal] = useState({ shown: false, target: null }),
        [elements, setElements] = useState([]),
        [elementsSearch, setElementsSearch] = useState(''),
        [elIsDragging, setElIsDragging] = useState(null),
        [currentCollapse, setCurrentCollapse] = useState({ set: null, category: null });

    const history = useHistory();

    const addElementHandler = async () => {
        const newElement = {
            doctor: userRole.userId,
            name: `Nowy element ${doctorsElements.length + 1}`,
            text: "",
            stage: 'WYWIAD',
            category: elementsCategories[0].name,
            subcategory: elementsCategories[0].subcategories[0],
            formControls: [],
            stages: []
        }
        const resp = await addVisitElement(newElement);
        if (resp._id) {
            history.push(`/elements/${resp._id}`);
        }
    },
        findFreeName = (elObj, sourceArr) => {
            let newName = elObj.name;
            let i = 1;
            let nameTakenObj = sourceArr.find(el => el.name === newName && el.stage === elObj.stage);
            while (nameTakenObj) {
                nameTakenObj = sourceArr.find(el => el.name === newName && el.stage === elObj.stage);
                if (nameTakenObj) {
                    i++;
                    newName = `${elObj.name} (${i})`;
                } else {
                    nameTakenObj = null;
                }
            };
            return newName;
        },
        importFileHandler = (file, target) => {
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                let i = 1;
                const importObj = JSON.parse(e.target.result);
                const sourceArr = target === 'element' ? doctorsElements : doctorsElementsSets;
                const freeName = findFreeName(importObj, sourceArr);
                let duplicatedElements = [];
                if (target === 'set') {
                    importObj.name = freeName;
                    duplicatedElements = importObj.elements.filter(el => doctorsElements.findIndex(it => el => it.name.replace(/ /g, '') === el.name.replace(/ /g, '') && it.stage === el.stage) !== -1);
                }
                if (freeName !== importObj.name) {
                    return setNameTakenModal({
                        shown: true,
                        target: importObj.name,
                        onBoth: () => {
                            importObj.doctor = userRole.userId;
                            importObj.name = freeName;
                            const json = JSON.stringify(importObj);
                            const blob = new Blob([json], { type: 'application/json' });
                            const formData = new FormData();
                            formData.append("element", blob);
                            importVisitElement(userRole.userId, formData);
                            setNameTakenModal({ shown: false, target: null, onBoth: null, onReplace: null });
                        },
                        onReplace: () => {
                            let nameTakenObj = sourceArr.find(el => el.name === importObj.name && el.stage === importObj.stage);
                            if (target === 'element') {
                                updateVisitElement(nameTakenObj._id, importObj);
                            } else {
                                importObj.elements = importObj.elements.map(({ _id }) => _id);
                                updateElementsSet(nameTakenObj._id, importObj);
                            }
                            setNameTakenModal({ shown: false, target: null, onBoth: null, onReplace: null });
                        }
                    })
                } else {
                    const json = JSON.stringify(importObj);
                    const blob = new Blob([json], { type: 'application/json' });
                    const formData = new FormData();
                    if (target === 'element') {
                        formData.append("element", blob);
                        importVisitElement(userRole.userId, formData);
                    } else {
                        if (duplicatedElements.length > 0) {
                            return setNameTakenModal(prev => ({
                                ...prev,
                                shown: true,
                                target: duplicatedElements,
                                onFinish: (updatedElements) => {
                                    setNameTakenModal({ shown: false, target: null, onBoth: null, onReplace: null });
                                    const json = JSON.stringify({ ...importObj, elements: updatedElements });
                                    const blob = new Blob([json], { type: 'application/json' });
                                    formData.append("elements-set", blob);
                                    importElementsSet(userRole.userId, formData);
                                }
                            }))
                        }
                        formData.append("elements-set", blob);
                        importElementsSet(userRole.userId, formData);
                    }
                }
            }
            fileReader.readAsText(file);
        },
        addSetHandler = (newSet) => {
            newSet.doctor = userRole.userId;
            addElementsSet(newSet);
        },
        updateSetHandler = (setId, elemId, action, values) => {
            let newSet = doctorsElementsSets.find(el => el._id === setId);
            if (action === 'add') {
                const newElement = doctorsElements.find(el => el._id === elemId);
                newSet.elements = [...newSet.elements, newElement];
            } else if (action === 'remove') {
                const newSet = doctorsElementsSets.find(el => el._id === setId);
                newSet.elements = newSet.elements.filter(el => el !== elemId);
            } else if (action === 'change') {
                newSet = { ...newSet, ...values }
            }
            updateElementsSet(setId, newSet);
        };

    useEffect(() => {
        if (userRole) {
            getDoctorsVisitElements(userRole.userId);
            getDoctorsElementsSet(userRole.userId);
        }
    }, [userRole]);

    useEffect(() => {
        setElements(doctorsElements.filter(({ name }) => name.replace(/ /g, '').toUpperCase().includes(elementsSearch.replace(/ /g, '').toUpperCase())));
    }, [elementsSearch, doctorsElements]);

    //Tutorial handler
    useEffect(() => {
        if (currentCollapse.category === 'Ogólne' && tutorialStep === 1) {
            nextTutorialStep();
        }
        if (currentCollapse.set === doctorsElementsSets[0]?._id && tutorialStep === 7) {
            nextTutorialStep();
        }
    }, [currentCollapse, tutorialStep])

    return (
        <ElementsListView
            doctorsElements={doctorsElements}
            warningModal={warningModal}
            setWarningModal={setWarningModal}
            addElementHandler={addElementHandler}
            deleteVisitElement={deleteVisitElement}
            exportVisitElement={exportVisitElement}
            importModal={importModal}
            setImportModal={setImportModal}
            importFileHandler={importFileHandler}
            addSetHandler={addSetHandler}
            doctorsElementsSets={doctorsElementsSets}
            updateSetHandler={updateSetHandler}
            deleteElementsSet={deleteElementsSet}
            elIsDragging={elIsDragging}
            setElIsDragging={setElIsDragging}
            currentCollapse={currentCollapse}
            setCurrentCollapse={setCurrentCollapse}
            nameTakenModal={nameTakenModal}
            setNameTakenModal={setNameTakenModal}
            exportElementsSet={exportElementsSet}
            elementsSearch={elementsSearch}
            setElementsSearch={setElementsSearch}
            elements={elements}
            nextTutorialStep={nextTutorialStep}
        />
    )
}

const mapStateToProps = state => ({
    userRole: state.authentication.userRole,
    doctorsElements: state.visitElement.doctorsElements,
    doctorsElementsSets: state.visitElementsSet.doctorsElementsSets,
    tutorialStep: state.tutorial.stepIndex,
});


const mapDispatchToProps = dispatch => {
    return {
        getDoctorsVisitElements: (doctorId) => dispatch(getDoctorsVisitElements(doctorId)),
        addVisitElement: (newElement) => dispatch(addVisitElement(newElement)),
        deleteVisitElement: (elementId) => dispatch(deleteVisitElement(elementId)),
        updateVisitElement: (elementId, newElement) => dispatch(updateVisitElement(elementId, newElement)),
        importVisitElement: (doctorId, elementFile) => dispatch(importVisitElement(doctorId, elementFile)),
        exportVisitElement: (elementId) => dispatch(exportVisitElement(elementId)),
        getDoctorsElementsSet: (doctorId) => dispatch(getDoctorsElementsSet(doctorId)),
        addElementsSet: (newSet) => dispatch(addElementsSet(newSet)),
        deleteElementsSet: (setId) => dispatch(deleteElementsSet(setId)),
        updateElementsSet: (setId, newSet) => dispatch(updateElementsSet(setId, newSet)),
        importElementsSet: (doctorId, setFile) => dispatch(importElementsSet(doctorId, setFile)),
        exportElementsSet: (setId) => dispatch(exportElementsSet(setId)),
        nextTutorialStep: (stepValidation) => dispatch(nextTutorialStep(stepValidation)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ElementsListContainer);