const SET_NAVIGATION_BLOCK = 'SET_NAVIGATION_BLOCK';
const SET_SIDEBAR_ACTIVE = 'SET_SIDEBAR_ACTIVE';

export const setNavBlock = (payload = true) => ({
    type: SET_NAVIGATION_BLOCK,
    payload
});

export const setSidebarActive = (payload = true) => ({
    type: SET_SIDEBAR_ACTIVE,
    payload
});

const initialState = {
    navBlock: false,
    sidebarActive: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NAVIGATION_BLOCK':
            return {
                navBlock: action.payload
            }
        case 'SET_SIDEBAR_ACTIVE':
            return {
                sidebarActive: action.payload
            }
        default:
            return state;
    }
};