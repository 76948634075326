import React from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';

export const Tabs = ({
  header,
  children,
  height,
  visitId
}) => {
  const { currentTab } = useParams();
  const renderTab = () => {
    const el = children.find(({ props }) => props.id === currentTab);
    if (el) {
      return <div id={el.props.id}>
        {React.cloneElement(el, { currentTab, ...el.props })}
      </div>
    } else {
      return <p>Current tab not matching any tab id</p>
    }
  }
  return (
    <TabsWrapper height={height}>
      <TabsHeader>
        {header &&
          header.map(({ label, id }) => (
            <TabsHeaderItem key={id} to={`${visitId ? `/visit/${visitId}/${id}` : id}`} active={currentTab === id ? 'true' : 'false'}>
              <span>{label}</span>
            </TabsHeaderItem>
          ))
        }
      </TabsHeader>
      <TabsBody>
        {children[0] ?
          renderTab()
          :
          React.cloneElement(children, { currentTab })
        }
      </TabsBody>
    </TabsWrapper>
  )
};

const TabsWrapper = styled.div`
height: calc(100% - 39px);
  ${({ height }) => height && `height: ${height}`}
`;

const TabsHeader = styled.div`
  background-color:#fff;
  display:flex;
  ${({ theme }) => (
    theme.mq.tablet + `{
      padding:0 10px;
    };` +
    theme.mq.desktop + `{
      padding:0 20px;
    };` +
    theme.mq.large + `{
      padding:0 30px;
    };`
  )}
`;

const TabsBody = styled.div`
  border-top: 2px solid #F6F5F8;
  padding:15px;
  height: 100%;
  max-width: 1140px;
  margin: 0 auto;
`;

const TabsHeaderItem = styled(Link)`
  font-size:10px;
  font-weight:700;
  padding:11.5px 35px;
  white-space:nowrap;
  text-decoration: none;
  display:inline-block;
  position:relative;
  cursor: pointer;
  span {
    color: ${({ theme }) => theme.darkGrey};
    transition: color 0.2s;
  }
  :hover {
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
  ${({ theme }) => (
    theme.mq.tablet + `{
      padding:11.5px 15px;
    };` +
    theme.mq.desktop + `{
      padding:11.5px 10px;
      font-size:12px;
    };` +
    theme.mq.huge + `{
      padding:11.5px 35px;
    };`
  )}
  ${({ active, theme }) => (
    active === 'true' && `
    span {
      color: ${theme.black};
    }
      font-weight: 800;
      background-color:#f2f5f8;
      border-top-left-radius:15px;
      border-top-right-radius:15px;
      border:1px solid #F6F5F8;
      &::before{
        content:'';
        display:block;
        position:absolute;
        width:30px;
        height:2px;
        bottom:0;
        left:0;
        right:0;
        margin:auto;
        background-color: ${theme.primary};
        z-index:2;
      }
      &::after{
        content:'';
        display:block;
        position:absolute;
        width:100%;
        height:10px;
        bottom:-5px;
        left:0;
        background-color:#f2f5f8;
      }
    `
  )}
`;