import React from "react";
import styled from "styled-components";
import Calendar from "../../Shared/Calendar";
import CollapseList from "./CollapseList";
import { withRouter } from "react-router-dom";

const ReceptionistCalendarSidebarView = ({
  calendarDoctors,
  calendarNurses,
  calendarGroups,
  calendarDate,
  updateCalendarDate,
  updateGroupsList,
  updateNursesList,
  updateDoctorsList,
}) => {
  return (
    <SidebarWrapper>
      <StyledCalendar
        value={new Date(calendarDate)}
        onChange={date => updateCalendarDate(date)}
      />
      <CalendarActions>
        <Today
          id="backToToday"
          onClick={() => updateCalendarDate(Date.now())}>Dzisiaj</Today>
      </CalendarActions>
      <CollapseListsWrapper id="calendarFilters">
        <CollapseList
          items={[...calendarDoctors, ...calendarNurses]}
          label="Kalendarze"
          onChange={targetName => {
            const updatedDoctorsList = calendarDoctors.map(doctor =>
              doctor.name === targetName
                ? { ...doctor, checked: !doctor.checked }
                : doctor
            );
            const updatedNursesList = calendarNurses.map(nurse =>
              nurse.name === targetName
                ? { ...nurse, checked: !nurse.checked }
                : nurse
            );
            const updatedDoctorsIds = updatedDoctorsList
              .filter(doctor => doctor.checked)
              .map(doctor => doctor._id);
            const updatedNursesIds = updatedNursesList
              .filter(nurse => nurse.checked)
              .map(nurse => nurse._id);
            const updatedGroupsList = calendarGroups.map(group =>
              (JSON.stringify(group.doctors) ===
                JSON.stringify(updatedDoctorsIds) || JSON.stringify(group.nurses) ===
                JSON.stringify(updatedNursesIds))
                ? { ...group, checked: true }
                : { ...group, checked: false }
            );

            updateDoctorsList(updatedDoctorsList);
            updateNursesList(updatedNursesList);
            updateGroupsList(updatedGroupsList);
          }}
          onSetAll={targetState => {
            const updatedDoctorsList = calendarDoctors.map(doctor => {
              return { ...doctor, checked: targetState };
            });
            const updatedDoctorsIds = updatedDoctorsList
              .filter(doctor => doctor.checked)
              .map(doctor => doctor._id);
            const updatedNursesList = calendarNurses.map(nurse => {
              return { ...nurse, checked: targetState };
            });
            const updatedNursesIds = updatedNursesList
              .filter(doctor => doctor.checked)
              .map(doctor => doctor._id);
            const updatedGroupsList = calendarGroups.map(group =>
              (JSON.stringify(group.doctors) ===
                JSON.stringify(updatedDoctorsIds) || JSON.stringify(group.nurses) ===
                JSON.stringify(updatedNursesIds))
                ? { ...group, checked: true }
                : { ...group, checked: false }
            );
            updateDoctorsList(updatedDoctorsList);
            updateNursesList(updatedNursesList);
            updateGroupsList(updatedGroupsList);
          }}
        />
        <CollapseList
          items={calendarGroups}
          label="Grupy"
          onChange={(targetName, status) => {
            const groupDoctorsIds = calendarGroups.find(
              group => group.name === targetName
            ).doctors;
            const updatedGroupsList = calendarGroups.map(group =>
              JSON.stringify(group.doctors) === JSON.stringify(groupDoctorsIds)
                ? { ...group, checked: !group.checked }
                : { ...group, checked: false }
            );
            const updatedDoctorsList = calendarDoctors.map(doctor =>
              groupDoctorsIds.some(doctorId => doctorId === doctor._id)
                ? { ...doctor, checked: !status }
                : { ...doctor, checked: false }
            );
            updateGroupsList(updatedGroupsList);
            updateDoctorsList(updatedDoctorsList);
          }}
        />
      </CollapseListsWrapper>
    </SidebarWrapper>
  );
};

export default withRouter(ReceptionistCalendarSidebarView);

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CollapseListsWrapper = styled.div`
  max-height: calc(100vh - 335px);
  overflow-y: scroll;
  padding: 10px 20px;
  margin-right: -10px;
`;

const StyledCalendar = styled(Calendar)`
padding: 10px;
margin-bottom: 0;
`

const CalendarActions = styled.div`
  display: flex;
  justify-content:flex-end;
  align-items:center;
  padding: 0 15px 5px;
`;

const Today = styled.p`
  font-size:14px;
  font-weight: 600;
  cursor:pointer;
  color:${({ theme }) => theme.lightBlack};
  transition:300ms;
  &:hover{
    color:${({ theme }) => theme.primary};
  }
`;