import React from "react";
import styled from "styled-components";
import userAvatar from "../Shared/assets/avatar.svg";
import EditPenIconUnderline from "../Shared/assets/edit-pen-underline.svg";
import Select from "../Shared/Select";
import Container from "../Shared/Container";
import Button from "../Shared/Button";
import Tooltip from "../Shared/Tooltip";

const HealthcenterDetails = ({
  AddBtn,
  selectedHealthcenter,
  selectOptions,
  selectValue,
  healthcenterChangeHandler,
  setEmployeesModalState,
  doctorsOfSelectedHealthcenter,
  registriesOfSelectedHealthcenter,
  nursesOfSelectedHealthcenter,
  setGroupsModalState,
  setRoomsModalState,
  setServicesModalState,
  setRemoveModalState,
  removeHandler,
}) => {
  return (
    <StyledContainer details title="Szczegóły placówki">
      <StyledSelect
        options={selectOptions}
        value={selectValue}
        onChange={({ value }) => {
          healthcenterChangeHandler(value);
        }}
        placeholder="Wybierz placówkę..."
        noOptionsMessage={() => "Nie znaleziono placówek"}
      />
      {selectedHealthcenter && (
        <>
          <Employes>
            <List doctors>
              <Button
                variant="edit"
                onClick={() =>
                  setEmployeesModalState({ target: "doctors", shown: true })
                }
              />
              <SectionTitle>Lekarze</SectionTitle>
              <ListBody>
                {doctorsOfSelectedHealthcenter.length > 0
                  ? doctorsOfSelectedHealthcenter.map(
                      ({ userId: { _id, avatar, name, surname } }) => (
                        <ListItem key={_id}>
                          <Avatar
                            src={
                              !avatar
                                ? userAvatar
                                : `${process.env.REACT_APP_SRV_URL}${avatar}`
                            }
                          />
                          <Text>
                            {name} {surname}
                          </Text>
                        </ListItem>
                      )
                    )
                  : <NoDataInfo>Brak przypisanych</NoDataInfo>}
              </ListBody>
            </List>
            <List rejestrators>
              <Button
                variant="edit"
                onClick={() =>
                  setEmployeesModalState({ target: "registries", shown: true })
                }
              />
              <SectionTitle>Rejestratorzy</SectionTitle>
              <ListBody>
                {registriesOfSelectedHealthcenter.length > 0
                  ? registriesOfSelectedHealthcenter.map(
                      ({ userId: { _id, avatar, name, surname } }) => (
                        <ListItem key={_id}>
                          <Avatar
                            src={
                              !avatar
                                ? userAvatar
                                : `${process.env.REACT_APP_SRV_URL}${avatar}`
                            }
                          />
                          <Text>
                            {name} {surname}
                          </Text>
                        </ListItem>
                      )
                    )
                  : <NoDataInfo>Brak przypisanych</NoDataInfo>}
              </ListBody>
            </List>
            <List nurses>
              <Button
                variant="edit"
                onClick={() =>
                  setEmployeesModalState({ target: "nurses", shown: true })
                }
              />
              <SectionTitle>Pielęgniarki</SectionTitle>
              <ListBody>
                {nursesOfSelectedHealthcenter.length > 0
                  ? nursesOfSelectedHealthcenter.map(
                      ({ userId: { _id, avatar, name, surname } }) => (
                        <ListItem key={_id}>
                          <Avatar
                            src={
                              !avatar
                                ? userAvatar
                                : `${process.env.REACT_APP_SRV_URL}${avatar}`
                            }
                          />
                          <Text>
                            {name} {surname}
                          </Text>
                        </ListItem>
                      )
                    )
                  : <NoDataInfo>Brak przypisanych</NoDataInfo>}
              </ListBody>
            </List>
            <List rooms>
              <Button variant="edit" onClick={() => setRoomsModalState(true)} />
              <SectionTitle>Gabinety</SectionTitle>
              <ListBody>
                {selectedHealthcenter.length > 0
                  ? selectedHealthcenter.rooms.map(({ name, _id }) => (
                      <ListItem key={_id}>
                        <Text>{name}</Text>
                      </ListItem>
                    ))
                  : <NoDataInfo>Brak przypisanych</NoDataInfo>}
              </ListBody>
            </List>
          </Employes>
          <Groups>
            <AddBtn
              onClick={() => setGroupsModalState({ edit: false, shown: true })}
            />
            <SectionTitle>Grupy</SectionTitle>
            <GroupsHeader>
              <Text>Nazwa</Text>
              <Text>Zawiera</Text>
              <Text>Akcje</Text>
            </GroupsHeader>
            <GroupsBody>
              {selectedHealthcenter?.groups?.length > 0
                ? selectedHealthcenter?.groups?.map(
                    ({ name, _id, doctors, nurses }) => (
                      <GroupItem key={name}>
                        <GroupName>{name}</GroupName>
                        <GroupUsers>
                          {[...doctors, ...nurses].map((employee, index) => {
                            const { userId: { name, surname, avatar } = {} } =
                              [
                                ...doctorsOfSelectedHealthcenter,
                                ...nursesOfSelectedHealthcenter,
                              ].find(({ _id }) => _id === employee) ?? {};
                            return (
                              <StyledTooltip
                                name={`${employee}_${index}`}
                                content={`${name} ${surname}`}
                              >
                                <User
                                  key={employee}
                                  src={
                                    !avatar
                                      ? userAvatar
                                      : `${process.env.REACT_APP_SRV_URL}${avatar}`
                                  }
                                  alt={`${name} ${surname}`}
                                />
                              </StyledTooltip>
                            );
                          })}
                        </GroupUsers>
                        <Actions>
                          <EditPenUnderline
                            src={EditPenIconUnderline}
                            onClick={() =>
                              setGroupsModalState({
                                shown: true,
                                target: _id,
                              })
                            }
                          />
                          <Remove
                            onClick={() =>
                              setRemoveModalState({
                                shown: true,
                                questionTitle: "Czy jesteś pewien?",
                                question:
                                  "Czy na pewno chcesz usunąć tę grupę?",
                                onAccept: () => removeHandler(name, "groups"),
                                onCancel: () =>
                                  setRemoveModalState({ shown: false }),
                              })
                            }
                          />
                        </Actions>
                      </GroupItem>
                    )
                  )
                : <NoDataInfo>Brak</NoDataInfo>}
            </GroupsBody>
          </Groups>
          <Services>
            <AddBtn
              onClick={() =>
                setServicesModalState({ edit: false, shown: true })
              }
            />
            <SectionTitle>Procedury</SectionTitle>
            <ServicesHeader>
              <ServicesHeaderCol>
                <Text>Nazwa</Text>
              </ServicesHeaderCol>
              <ServicesHeaderCol>
                <Text>Koszt własny</Text>
              </ServicesHeaderCol>
              <ServicesHeaderCol>
                <Text>Cena</Text>
              </ServicesHeaderCol>
              <ServicesHeaderCol>
                <Text>Akcje</Text>
              </ServicesHeaderCol>
            </ServicesHeader>
            <ServicesBody>
              {selectedHealthcenter?.services?.length > 0 ? (
                selectedHealthcenter.services.map(
                  ({ name, cost = "-", price = "-" }, i) => (
                    <ServicesItem key={i}>
                      <ServiceName>{name}</ServiceName>
                      <ServicePrice>{cost}</ServicePrice>
                      <ServicePrice>{price}</ServicePrice>
                      <Actions>
                        <EditPenUnderline
                          onClick={() =>
                            setServicesModalState({
                              edit: true,
                              shown: true,
                              targetName: name,
                            })
                          }
                          src={EditPenIconUnderline}
                        />
                        <Remove
                          onClick={() =>
                            setRemoveModalState({
                              shown: true,
                              questionTitle: "Czy jesteś pewien?",
                              question:
                                "Czy na pewno chcesz usunąć tą procedurę?",
                              onAccept: () => removeHandler(name, "services"),
                              onCancel: () =>
                                setRemoveModalState({ shown: false }),
                            })
                          }
                        />
                      </Actions>
                    </ServicesItem>
                  )
                )
              ) : (
                <NoDataInfo>Brak</NoDataInfo>
              )}
            </ServicesBody>
          </Services>
        </>
      )}
    </StyledContainer>
  );
};

export default HealthcenterDetails;

const StyledContainer = styled(Container)`
  position: relative;
  padding: 20px 10px 10px 10px;
`;

const StyledSelect = styled(Select)`
  position: absolute !important;
  width: 200px;
  right: 10px;
  top: 5px;
`;

const Text = styled.p``;

const EditPenUnderline = styled.img`
  transition: 300ms;
  width: 25px;
  height: 25px;
  cursor: pointer;
  filter: invert(81%) sepia(0%) saturate(51%) hue-rotate(195deg) brightness(86%)
    contrast(81%);
  transition: filter 300ms;
  &:hover {
    filter: invert(40%) sepia(73%) saturate(1173%) hue-rotate(185deg)
      brightness(101%) contrast(105%);
  }
`;

const List = styled.div`
  position: relative;
  width: 50%;
  margin: 0;
  > button {
    top: -5px;
    right: 15px;
  }
`;

const ListBody = styled.div`
  max-height: 105px;
  overflow-y: auto;
`;

const SectionTitle = styled.p`
  font-weight: 700;
  margin-bottom: 5px 5px 10px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

const Avatar = styled.img`
  border-radius: 50%;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;

const Employes = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  > ul {
    width: 50%;
  }
`;

const Groups = styled.div`
  position: relative;
`;

const GroupsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 7.5px 25px;
  border-bottom: 1px solid ${({ theme }) => theme.grey};
  > p {
    font-weight: 600;
    font-size: 12px;
  }
`;

const GroupsBody = styled.ul`
  max-height: 150px;
  overflow-y: auto;
  margin: 0;
`;

const GroupItem = styled.div`
  display: grid;
  grid-template-columns: 3fr 6fr 3fr;
  align-items: center;
  padding: 7.5px 5px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const GroupName = styled.p`
  color: ${({ theme }) => theme.lightBlack};
  font-weight: 600;
`;

const GroupUsers = styled.div`
  text-align: center;
`;

const User = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
`;

const Services = styled.div`
  margin-top: 20px;
  position: relative;
`;

const ServicesBody = styled.ul`
  max-height: 150px;
  overflow-y: auto;
  margin: 0;
`;

const ServicesHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 4fr 1fr 1fr 1fr;
  padding: 7.5px 5px;
  border-bottom: 1px solid ${({ theme }) => theme.grey};
`;

const ServicesHeaderCol = styled.div`
  > p {
    font-weight: 600;
    font-size: 12px;
  }
  &:not(:first-child) > p {
    text-align: center;
  }
`;

const ServicesItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 4fr 1fr 1fr 1fr;
  padding: 7.5px 5px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
  > div {
    font-size: 13px;
    font-weight: 600;
    color: ${({ theme }) => theme.lightBlack};
    &:not(:first-child) {
      text-align: center;
    }
  }
`;

const ServiceName = styled.div``;

const ServicePrice = styled.div``;

const Remove = styled.button`
  position: relative;
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  margin-left: 5px;
  transition: background-color 300ms;
  &::before,
  &::after {
    content: "";
    height: 20px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:after,
    &:before {
      background-color: ${({ theme }) => theme.danger};
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  display: inline-block;
`;

const NoDataInfo = styled.span`
  margin: 10px auto;
  display: block;
  text-align: center;
`;
