import React from 'react';
import styled from 'styled-components';
import Container from '../../../../Shared/Container';
import Input from '../../../../Shared/Input';
import Button from '../../../../Shared/Button';
import { Formik } from 'formik';
import { addictionsEnum, diseaseCausingOrganismsEnum } from '../tabsEnums';
import RiskFactorsTextModal from "../../modals/RiskFactorsTextModal";
import RiskFactorsFamilyCancersModal from "../../modals/RiskFactorsFamilyCancersModal";

const RiskfactorsTabView = ({  riskFactorsCheckboxEdit, setRiskFactorsCheckboxEdit, updatePatientRiskFactorsHandler, riskFactorsTextModal, setRiskFactorsTextModal, riskFactorsFamilyCancersModal, setRiskFactorsFamilyCancersModal, patientRiskFactors, cancers, getCancersHandler, checkboxSubmitHandler }) => {
  return (
    <Wrapper>
      <RiskFactorsTextModal
        modalState={riskFactorsTextModal}
        setModalState={setRiskFactorsTextModal}
        updatePatientRiskFactorsHandler={updatePatientRiskFactorsHandler}
        patientRiskFactors={patientRiskFactors}
      />
      <RiskFactorsFamilyCancersModal
        modalState={riskFactorsFamilyCancersModal}
        setModalState={setRiskFactorsFamilyCancersModal}
        updatePatientRiskFactorsHandler={updatePatientRiskFactorsHandler}
        familyCancers={patientRiskFactors?.familyCancers}
        cancers={cancers}
        getCancersHandler={getCancersHandler}
      />
      <Section>
        <StyledContainer
          actionButton='edit'
          actionButtonOnClick={() => {
            setRiskFactorsTextModal({ shown: true, target: 'factors' });
          }}
          actionButtonTitle="Edytuj czynniki ryzyka"
          title="Czynniki ryzyka"
        >
          <List height='60px'>
            {patientRiskFactors?.factors?.length > 0 ?
              patientRiskFactors?.factors.map(el => (
                <ListItem key={el}>{el}</ListItem>
              ))
              :
              <EmptyInfo>Brak</EmptyInfo>
            }
          </List>
        </StyledContainer>
        <StyledContainer
          title="Nałogi"
        >
          <Formik
            enableReinitialize
            initialValues={patientRiskFactors?.addictions ? addictionsEnum.reduce((a, b) => (a[b] = patientRiskFactors?.addictions.indexOf(b) !== -1 ? true : false, a), {}) : {}}
          >
            {({ values, setFieldValue, resetForm }) => (
              <>
                <Button
                  variant={riskFactorsCheckboxEdit === 'addictions' ? 'close' : 'edit'}
                  title={riskFactorsCheckboxEdit === 'addictions' ? "Zamknij nałogi" : "Edytuj nałogi"}
                  onClick={riskFactorsCheckboxEdit === 'addictions' ? () => { setRiskFactorsCheckboxEdit(null); resetForm(); } : () => setRiskFactorsCheckboxEdit('addictions')}
                />
                {riskFactorsCheckboxEdit === 'addictions' && <Button
                  variant="check"
                  title='Zapisz nałogi'
                  onClick={() => checkboxSubmitHandler(values, 'addictions')}
                />}
                <CheckboxesBox>
                  {addictionsEnum.map(el => (
                    <StyledInput
                      key={el}
                      type="checkbox"
                      variant={riskFactorsCheckboxEdit === 'addictions' && 'primary'}
                      checkboxText={el}
                      value={values[el]}
                      name={el}
                      onChange={() => riskFactorsCheckboxEdit === 'addictions' ? setFieldValue(el, !values[el]) : false}
                    />
                  ))}
                </CheckboxesBox>
              </>
            )}
          </Formik>
        </StyledContainer>
        <StyledContainer
          title="Alergie"
          actionButton='edit'
          actionButtonTitle="Edytuj alergie"
          actionButtonOnClick={() => setRiskFactorsTextModal({ shown: true, target: 'alergies' })}
        >
          <List height='60px'>
            {patientRiskFactors?.alergies?.length > 0 ?
              patientRiskFactors?.alergies.map(el => (
                <ListItem key={el.subtance}>{el.subtance}</ListItem>
              ))
              :
              <EmptyInfo>Brak</EmptyInfo>
            }
          </List>
        </StyledContainer>
      </Section>
      <Section>
        <StyledContainer
          title="Nowotwory w rodzinie"
          actionButton='edit'
          actionButtonTitle="Edytuj nowotwory w rodzinie"
          actionButtonOnClick={() => setRiskFactorsFamilyCancersModal({ shown: true, target: 'familyCancers' })}
        >
          <List height='280px' cancers>
            {patientRiskFactors?.familyCancers?.length > 0 ?
              patientRiskFactors?.familyCancers.map(({ category, subcategory, subcategoryCode, relationshipKind, _id }) => (
                <FamilyCancerItem key={_id}>
                  <Left>
                    <Code>{subcategoryCode}</Code>
                    <Name>{category} - {subcategory}</Name>
                  </Left>
                  <Relationship>{relationshipKind}</Relationship>
                </FamilyCancerItem>
              ))
              :
              <EmptyInfo>Brak</EmptyInfo>
            }
          </List>
        </StyledContainer>
        <StyledContainer
          title="Nosicielstwo"
        >
          <Formik
            enableReinitialize
            initialValues={patientRiskFactors?.diseasesCarrier ? diseaseCausingOrganismsEnum.reduce((a, b) => (a[b] = patientRiskFactors?.diseasesCarrier.indexOf(b) !== -1 ? true : false, a), {}) : {}}
          >
            {({ setFieldValue, values, resetForm }) => (
              <>
                <Button
                  variant={riskFactorsCheckboxEdit === 'diseasesCarrier' ? 'close' : 'edit'}
                  title={riskFactorsCheckboxEdit === 'diseasesCarrier' ? "Zamknij nosicielstwo" : "Edytuj nosicielstwo"}
                  onClick={riskFactorsCheckboxEdit === 'diseasesCarrier' ? () => { setRiskFactorsCheckboxEdit(null); resetForm(); } : () => setRiskFactorsCheckboxEdit('diseasesCarrier')}
                />
                {riskFactorsCheckboxEdit === 'diseasesCarrier' && <Button
                  variant="check"
                  title='Zapisz nosicielstwo'
                  onClick={() => checkboxSubmitHandler(values, 'diseasesCarrier')}
                />}
                <CheckboxesBox>
                  {diseaseCausingOrganismsEnum.map(el => (
                    <StyledInput
                      key={el}
                      type="checkbox"
                      variant={riskFactorsCheckboxEdit === 'diseasesCarrier' && 'primary'}
                      checkboxText={el}
                      value={values[el]}
                      name={el}
                      onChange={() => riskFactorsCheckboxEdit === 'diseasesCarrier' ? setFieldValue(el, !values[el]) : false}
                    />
                  ))}
                </CheckboxesBox>
              </>
            )}
          </Formik>
        </StyledContainer>
      </Section>
    </Wrapper>
  )
}

export default RiskfactorsTabView;

const Wrapper = styled.div`
  display:flex;
  justify-content:space-between;
  height:100%;
`;

const Section = styled.div`
  width:50%;
  height:calc(100vh - 290px);
`;

const StyledContainer = styled(Container)`
  position:relative;
`;

const List = styled.ul`
  margin:0;
  min-height:${({ height }) => height};
  overflow:scroll;
  ${({ cancers }) => cancers && `
  min-height: 100px;
  max-height: 180px;
    `}
`;

const ListItem = styled.li`
  padding:5px;
  font-size:14px;
  &:not(:last-child){
      border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const EmptyInfo = styled.p``;

const CheckboxesBox = styled.div`
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
  max-height:250px;
  max-width: 50%;
`;

const FamilyCancerItem = styled.li`
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  font-size:14px;
  padding:5px;
  &:not(:last-child){
      border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const Left = styled.span`
  display:flex;
`;

const Code = styled.p`
  font-weight:600;
  padding-right:10px;
`;

const Name = styled.p`
`;

const Relationship = styled.p`
  padding-left:10px;
`;

const StyledInput = styled(Input)`
  ~ label{
    font-size:12px;
  }
  ${({ theme }) => `${theme.mq.desktop}{
    ~ label{
      font-size:14px;
    }
  }`}
`;