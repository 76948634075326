import React from "react";
import styled from "styled-components";
import Button from "../Button";

const SimpleQuestionModal = ({
  questionTitle,
  question,
  acceptText,
  cancelText,
  onAccept,
  onCancel,
  reverseButtonColors,
}) => (
  <Container>
    {questionTitle && <QuestionTitle>{questionTitle}</QuestionTitle>}
    <Question>{question}</Question>
    <ButtonsContainer>
      <StyledButton
        variant={reverseButtonColors ? "danger" : "primary"}
        onClick={onAccept}
      >
        {acceptText}
      </StyledButton>
      <StyledButton
        variant={reverseButtonColors ? "primary" : "danger"}
        onClick={onCancel}
      >
        {cancelText}
      </StyledButton>
    </ButtonsContainer>
  </Container>
);

export default SimpleQuestionModal;

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  min-width: 250px;
`;

const Question = styled.p`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const QuestionTitle = styled.h2`
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 0;
`;

const StyledButton = styled(Button)`
  padding: 12px 24px;
  &:first-of-type {
    margin-right: 10px;
  }
`;
