export const stageNameEnum = ['WYWIAD', 'BADANIA FIZYKALNE', 'ZALECENIA'];

export const fullStageNameEnum = ['WYWIAD', 'BADANIA FIZYKALNE', 'BADANIE DODATKOWE', 'ROZPOZNANIE', 'ZALECENIA', 'PODSUMOWANIE'];

export const stagesTextsName = ['medicalIntelligence', 'physicalExaminations', 'additionalExaminations', 'diagnosis', 'recommendations'];

export const formControlTypes = ['select', 'checkbox', 'datepicker', 'input', 'radio'];

export const scheduleWeekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const units = ['ml', 'g', '°C', 'mm Hg', `liczba^n/l`, 'liczba^n/ml', 'sek', 'promil', 'g', 'ng', 'pg', 'fg', 'ml', 'fl', 'mm/h', 'g/g', 'g/dl', 'g/l', 'mg/l', 'mg/dl', 'mg/ml', 'µg/l', 'µg/dl', 'µg/ml', 'ng/l', 'ng/dl', 'ng/ml', 'mol/l', 'mmol/l', 'mmol/dl', 'mmol/ml', 'nmol/l', 'pmol/l', 'µg/24h', 'mmol/24h', 'µmol/24h', 'µmol/mmol', 'U/l', 'mU/l', 'U/ml'];

export const patternControlTypes = [
    {
        type: 'select',
        title: 'Wybór z listy',
        subtitle: 'Wybierz jedną opcje z listy',
    },
    {
        type: 'radio',
        title: 'Pojedyńczy wybór',
        subtitle: 'Wybierz jedną opcje z wielu',
    },
    {
        type: 'checkbox',
        title: 'Wielokrotny wybór',
        subtitle: 'Wybierz więcej opcji',
    },
    {
        type: 'input',
        title: 'Pole numeryczne',
        subtitle: 'Wpisz wartość numeryczną',
    },
    {
        type: 'datepicker',
        title: 'Data',
        subtitle: 'Określ date',
    },
    {
        type: 'timepicker',
        title: 'Czas',
        subtitle: 'Wpisz czas',
    },
];

export const elementsCategories = [
    {
        name: 'Ogólne',
        subcategories: [
            'Osłabienie ogólne',
            'Omdlenie',
            'Duszność',
            'Kaszel',
            'Nudności',
            'Wymioty',
            'Gorączka',
            'Podwyższona ciepłota ciała',
            'Zmiany skórne',
            'Ból',
            'Obrzęk',
            'Zaczerwienienie',
            'Zblednięcenie',
            'Zasinienie',
            'Żółtaczka',
            'Suchość',
            'Martwica',
            'Krwawienie',
            'Wyciek płynu',
            'Guz',
            'Torbiel',
        ]
    },
    {
        name: 'Głowa',
        subcategories: [
            'Okolica czołowa',
            'Okolica skroniowa',
            'Okolica ciemieniowa',
            'Okolica potyliczna',
            'Oko',
            'Powieka',
            'Nos',
            'Kość jarzmowa',
            'Łuk brwiowy',
            'Małżowina uszna',
            'Ucho',
            'Żuchwa',
            'Warga',
            'Język',
            'Jama ustna',
            'Migdałki',
            'Ślinianki',
            'Zęby',
            'Węzły chłonne',
            'Tętnica skroniowa',
        ]
    },
    {
        name: 'Szyja',
        subcategories: [
            'Gardło',
            'Przełyk',
            'Krtań',
            'Tarczyca',
            'Skóra szyi',
            'Węzły chłonne szyjne',
            'Węzły chłonne nadobojczykowe',
            'Węzły chłonne podobojczykowe',
            'Kręgosłup szyjny',
            'Mięśnie szyi',
            'Tętnice szyjne',
            'Żyły szyjne',
            'Tętnice kręgowe',
        ]
    },
    {
        name: 'Kończyny',
        subcategories: [
            'Kość miedniczna',
            'Staw biodrowy',
            'Pośladek',
            'Tętnica udowa',
            'Żyła udowa',
            'Żyła odpiszczelowa',
            'Kość piszczelowa',
            'Kość strzałkowa',
            'Grupa tylna mięśni goleni',
            'Grupa boczna mięśni goleni',
            'Tętnica piszczelowa',
            'Tętnica strzałkowa',
            'Tętnica odpiszczelowa',
            'Tętnica odstrzałkowa',
            'Guz piętowy',
            'Kostka boczna',
            'Kostka przyśrodkowa',
            'Ścięgno Achillesa',
            'Stęp',
            'Śródstopie',
            'Przodostopie',
            'Palce',
        ]
    },
    {
        name: 'Układy',
        subcategories: [
            'Ruchu',
            'Pokarmowy',
            'Narządy zmysłów',
            'Dokrewny',
            'Oddechowy',
            'Moczowo-płciowy',
            'Naczyniowy',
            'Powłoka wspólna',
        ]
    },
    {
        name: 'Narządy wewnętrzne',
        subcategories: [
            'Przełyk',
            'Żołądek',
            'Jelito cienkie',
            'Jelito grube',
            'Wątroba',
            'Pęcherzyk żółciowy',
            'Trzustka',
            'Serce',
            'Tarczyca',
            'Przysadka mózgowa',
            'Nadnercza',
            'Gonady',
            'Przytarczyce',
            'Nerki',
        ]
    },
    {
        name: 'Zaburzenia funkcji',
        subcategories: [
            'Czucie',
            'Ruch',
            'Sen i czuwanie',
            'Widzenie',
            'Powonienie',
            'Smak',
            'Słuch',
            'Połykanie',
            'Oddawanie moczu',
            'Oddawanie stolca',
            'Erekcja',
            'Wydzielanie łez',
            'Wydzielanie śliny',
        ]
    },
];

export const userRolesDisc = {
    Admin: 'Administrator',
    Doctor: 'Lekarz',
    Registry: 'Rejestrator',
    Nurse: 'Pielęgniarka',
}

export const visitModalErrorsDisc = {
    "Patient have already scheduled visits today.": "Ten pacjent ma już dziś zaplanowaną wizytę. Czy na pewno chcesz zaplanować kolejną?",
    "Doctor has a vacation in this period.": "Ten doktor ma zaplanowy urlop w tym czasie. Czy chcesz mimo to kontynuować?",
    "Visit time is not in line with the doctor's schedule.": "Ten doktor nie przyjmuje w tych godzinach. Czy chcesz mimo to kontynuować?",
};

export const employeesColors = [
    "#d50000",
    "#e67c73",
    "#f4511e",
    "#f6bf26",
    "#33b679",
    "#0b8043",
    "#039be5",
    "#2758a6",
    "#7986cb",
    "#8e24aa",
    "#616161",
];