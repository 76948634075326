import React from 'react';
import Modal from '../../Shared/Modals/Modal';
import Input from '../../Shared/Input';
import Button from '../../Shared/Button';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

const EditBasicInfoSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nazwa firmy jest wymagana'),
    address: Yup.object().shape({
        Street: Yup.string(),
        // .required('Ulica jest wymagana!'),
        City: Yup.string(),
        // .required('Miasto jest wymagane!'),
        houseNumber: Yup.string()
        // .required('Numer domu jest wymagany!'),
    }),
    bankAccountNumber: Yup.number()
        .typeError('Numer konta musi być numerem'),
    // .required('Numer konta jest wymagany'),
    bookNumber: Yup.number()
        .typeError('Numer księgi musi być numerem'),
    // .required('Numer księgi jest wymagany'),
    entryNumber: Yup.number()
        .typeError('Numer wpisu musi być numerem'),
    // .required('Numer wpisu jest wymagany'),
    nipNumber: Yup.number()
        .typeError('NIP musi być numerem'),
    // .required('NIP jest wymagany'),
    phoneNumber: Yup.number()
        .typeError('Numer telefonu musi być numerem'),
    // .required('Numer telefonu jest wymagany'),
    regonNumber: Yup.number()
        .typeError('REGON musi być numerem'),
    // .required('REGON jest wymagany'),
    rzozI: Yup.string(),
    // .required('I cz. RZOZ jest wymagany'),
    rzozIII: Yup.string(),
    // .required('III cz. RZOZ jest wymagany'),
    zipCode: Yup.string(),
    // .required('Kod pocztowy jest wymagany'),
});

const EditBasicInfoModal = ({ modalShown, setModalShown, companyData, updateCompany }) => {
    const {
        name,
        address: { Street, houseNumber, City },
        bankAccountNumber,
        bookNumber,
        entryNumber,
        nipNumber = '',
        phoneNumber,
        regonNumber,
        rzozI,
        rzozIII,
        zipCode,
    } = companyData;
    return (
        <Modal
            title='Edycja danych podstawowych'
            exitButton={true}
            onCancel={() => setModalShown(false)}
            show={modalShown}>
            <Formik
                initialValues={{
                    name,
                    address: {
                        Street,
                        houseNumber,
                        City,
                    },
                    bankAccountNumber: bankAccountNumber === null ? '' : bankAccountNumber,
                    bookNumber: bookNumber === null ? '' : bookNumber,
                    entryNumber: entryNumber === null ? '' : entryNumber,
                    nipNumber,
                    phoneNumber,
                    regonNumber,
                    rzozI,
                    rzozIII,
                    zipCode,
                }}
                validationSchema={EditBasicInfoSchema}
                onSubmit={values => {
                    updateCompany(companyData._id, values);
                    setModalShown(false);
                }}>
                {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <InputRow>
                            <Input
                                type="text"
                                name="name"
                                label="Nazwa"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={errors.name}
                            />
                            <Input
                                type="text"
                                name="nipNumber"
                                label="NIP"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.nipNumber}
                                error={errors.nipNumber}
                            />
                        </InputRow>
                        <InputRow>
                            <Input
                                type="text"
                                name="regonNumber"
                                label="REGON"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.regonNumber}
                                error={errors.regonNumber}
                            />
                            <Input
                                type="text"
                                name="phoneNumber"
                                label="Telefon"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phoneNumber}
                                error={errors.phoneNumber}
                            />
                        </InputRow>
                        <InputRow>
                            <Input
                                type="text"
                                name="zipCode"
                                label="Kod pocztowy"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.zipCode}
                                error={errors.zipCode}
                            />
                            <Input
                                type="text"
                                name="address.Street"
                                label="Ulica"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address.Street}
                                error={errors?.address?.Street}
                            />
                        </InputRow>
                        <InputRow>
                            <Input
                                type="text"
                                name="address.houseNumber"
                                label="Numer domu"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address.houseNumber}
                                error={errors?.address?.houseNumber}
                            />
                            <Input
                                type="text"
                                name="address.City"
                                label="Miasto"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address.City}
                                error={errors?.address?.City}
                            />
                        </InputRow>
                        <InputRow>
                            <Input
                                type="text"
                                name="bookNumber"
                                label="Numer księgi"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bookNumber}
                                error={errors.bookNumber}
                            />
                            <Input
                                type="text"
                                name="rzozI"
                                label="I cz. RZOZ"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.rzozI}
                                error={errors.rzozI}
                            />
                        </InputRow>
                        <InputRow>
                            <Input
                                type="text"
                                name="rzozIII"
                                label="III cz. RZOZ"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.rzozIII}
                                error={errors.rzozIII}
                            />
                            <Input
                                type="text"
                                name="entryNumber"
                                label="Numer wpisu"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.entryNumber}
                                error={errors.entryNumber}
                            />
                        </InputRow>
                        <InputRow>
                            <Input
                                type="text"
                                name="bankAccountNumber"
                                label="Konto bankowe"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bankAccountNumber}
                                error={errors.bankAccountNumber}
                            />
                        </InputRow>
                        <CenterBox>
                            <Button type='submit' variant='primary'>Zapisz zmiany</Button>
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default EditBasicInfoModal;

const Form = styled.form`
    padding-top:20px;
    width:500px;
    max-height:600px;
`;
const InputRow = styled.div`
    display:flex;
    justify-content:space-between;
    &>div{
        width:48%;
    }
`;
const CenterBox = styled.div`
    text-align:center;
`;