import {addPatientFromSocket,updatePatientFromSocket,deletePatientFromSocket} from './reduxActions'

export default (socket,storeAPI) => {
    socket.on('patient-add', patient => {
        storeAPI.dispatch(addPatientFromSocket(patient))

    })
    socket.on('patient-edit', patient => {
        const currentState = storeAPI.getState();
        storeAPI.dispatch(updatePatientFromSocket(patient))

    })
}