import axios from "axios";
import { ADD_MESSAGE_FROM_SOCKET, ADD_UNREAD_MESSAGE } from '../../sockets/chat/reduxActions'
const SET_CURRENT_CHANNEL = "SET_CURRENT_CHANNEL";

const GET_MESSAGES_BY_CHANNEL_ID_REQUEST = "GET_MESSAGES_BY_CHANNEL_ID_REQUEST";
const GET_MESSAGES_BY_CHANNEL_ID_SUCCESS = "GET_MESSAGES_BY_CHANNEL_ID_SUCCESS";
const GET_MESSAGES_BY_CHANNEL_ID_FAIL = "GET_MESSAGES_BY_CHANNEL_ID_FAIL";

const GET_MESSAGES_BY_CHANNEL_IDS_REQUEST = "GET_MESSAGES_BY_CHANNEL_IDS_REQUEST";
const GET_MESSAGES_BY_CHANNEL_IDS_SUCCESS = "GET_MESSAGES_BY_CHANNEL_IDS_SUCCESS";
const GET_MESSAGES_BY_CHANNEL_IDS_FAIL = "GET_MESSAGES_BY_CHANNEL_IDS_FAIL";

const CREATE_MESSAGES_REQUEST = "CREATE_MESSAGES_REQUEST";
const CREATE_MESSAGES_SUCCESS = "CREATE_MESSAGES_SUCCESS";
const CREATE_MESSAGES_FAIL = "CREATE_MESSAGES_FAIL";


export const setCurrentChannel = (channelId) => async dispatch => {
  dispatch({
    type: SET_CURRENT_CHANNEL,
    payload: channelId
  });
};

export const getMessagesByChannelId = (channelId, timestamp) => async (dispatch, getState) => {
  dispatch(getMessagesByChannelIdRequest());
  try {
    const response = await axios.get(`message/channel/${channelId}/?beforeDate=${timestamp}`);
    const { data } = response;
    const prevMessages = getState().chat.messages[channelId] ?? [];
    const newChannelMessages = { [channelId]: [...prevMessages, ...data] };
    dispatch(getMessagesByChannelIdSuccess(newChannelMessages));
  } catch (error) {
    dispatch(getMessagesByChannelIdFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const getMessagesByChannelIdRequest = () => ({
  type: GET_MESSAGES_BY_CHANNEL_ID_REQUEST
});

const getMessagesByChannelIdSuccess = messages => ({
  type: GET_MESSAGES_BY_CHANNEL_ID_SUCCESS,
  payload: messages
});

const getMessagesByChannelIdFail = error => ({
  type: GET_MESSAGES_BY_CHANNEL_ID_FAIL,
  payload: error
});

export const getMessagesByChannelIds = (channelIds, timestamp, userId) => async dispatch => {
  dispatch(getMessagesByChannelIdsRequest());
  try {
    const response = await axios.post(`message/channels/?beforeDate=${timestamp}`, { channelIds, userId });
    const { data } = response;
    dispatch(getMessagesByChannelIdsSuccess(data));
  } catch (error) {
    dispatch(getMessagesByChannelIdsFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const getMessagesByChannelIdsRequest = () => ({
  type: GET_MESSAGES_BY_CHANNEL_IDS_REQUEST
});

const getMessagesByChannelIdsSuccess = payload => ({
  type: GET_MESSAGES_BY_CHANNEL_IDS_SUCCESS,
  payload: payload
});

const getMessagesByChannelIdsFail = error => ({
  type: GET_MESSAGES_BY_CHANNEL_IDS_FAIL,
  payload: error
});

export const createMessage = (newMessage) => async (dispatch, getState) => {
  dispatch(createMessageRequest());
  try {
    const response = await axios.post(`message`, newMessage);
    const { data } = response;
    dispatch(newMessageSuccess(data))
    const prevMessages = getState().chat.messages[data.channel] ?? [];
    dispatch(createMessageSuccess({ [data.channel]: [...prevMessages, data] }))
  } catch (error) {
    dispatch(createMessageFail(error?.response?.data?.message));
    throw error?.response?.data?.message;
  }
};
const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS"
const newMessageSuccess = (message) => ({
  type: ADD_MESSAGE_SUCCESS,
  payload: message
})

const createMessageRequest = () => ({
  type: CREATE_MESSAGES_REQUEST
});

const createMessageSuccess = messages => ({
  type: CREATE_MESSAGES_SUCCESS,
  payload: messages
});

const createMessageFail = error => ({
  type: CREATE_MESSAGES_FAIL,
  payload: error
});

const CLEAR_UNREAD_MESSAGES = "CLEAR_UNREAD_MESSAGES"

export const clearUnreadMessages = channelId => ({
  type: CLEAR_UNREAD_MESSAGES,
  payload: channelId
})

const SET_MESSAGE_LIST_HEIGHT = 'SET_MESSAGE_LIST_HEIGHT';

export const setMessageListHeight = height => ({
  type: SET_MESSAGE_LIST_HEIGHT,
  payload: height
})

const initialState = {
  currentChannel: null,
  messages: {},
  loading: false,
  error: null,
  messageListHeight: 0,
  unreadMessages: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: action.payload,
      }
    case GET_MESSAGES_BY_CHANNEL_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_MESSAGES_BY_CHANNEL_ID_SUCCESS:
      return {
        ...state,
        messages: { ...state.messages, ...action.payload },
        loading: false
      };
    case GET_MESSAGES_BY_CHANNEL_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_MESSAGES_BY_CHANNEL_IDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_MESSAGES_BY_CHANNEL_IDS_SUCCESS:
      return {
        ...state,
        messages: action.payload.messages,
        unreadMessages: action.payload.unreadedMessages,
        loading: false
      };
    case GET_MESSAGES_BY_CHANNEL_IDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: { ...state.messages, ...action.payload },
        loading: false,
      };
    case CREATE_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ADD_MESSAGE_FROM_SOCKET:
      if (state.messages[action.payload.channel]) {
        return {
          ...state,
          messages: {
            ...state.messages,
            [action.payload.channel]: [...state.messages[action.payload.channel], action.payload]
          },
          messageListHeight: 0
        }
      } else {
        return {
          ...state,
          messages: {
            ...state.messages,
            [action.payload.channel]: [action.payload]
          },
          messageListHeight: 0
        }
      }
    case ADD_UNREAD_MESSAGE:
      if (state.unreadMessages[action.payload]) {
        return {
          ...state,
          unreadMessages: {
            ...state.unreadMessages,
            [action.payload]: state.unreadMessages[action.payload] + 1
          }
        }
      } else {
        return {
          ...state,
          unreadMessages: {
            ...state.unreadMessages,
            [action.payload]: 1
          }
        }
      }
    case CLEAR_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          [action.payload]: 0
        }
      }
    case SET_MESSAGE_LIST_HEIGHT:
      return {
        ...state,
        messageListHeight: action.payload
      }

    default:
      return state;
  }
};