import React, { useState } from 'react';
import styled, { css } from "styled-components";
import AvatarPlaceholder from './assets/avatar.svg';
import Button from './Button';
import InfoPill from './InfoPill';
import { connect } from "react-redux";
import { ReactComponent as PeselIcon } from './assets/id-card.svg';
import { ReactComponent as SexIcon } from './assets/user.svg';
import { ReactComponent as FileIcon } from './assets/file.svg';
import { ReactComponent as EmailIcon } from './assets/mail.svg';
import { ReactComponent as AddressIcon } from './assets/city.svg';
import { ReactComponent as PhoneIcon } from './assets/phone.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { ReactComponent as InfoIcon } from './assets/info-circle.svg';
import PatientModal from "./Modals/PatientModal";
import { updatePatientWithVisits, setPatientAvatar, mergeWithDeletedPatient } from '../../redux/modules/patients';
import { parseAddress } from './utils'

const PatientInfoHeader = ({
  patientInfo: { avatar, name, surname, pesel, sex, fileNumber, email, address, tel, birthDate, additionalInfo, deleted },
  updatePatientWithVisits,
  currentPatient,
  patientMedsDiagnosis,
  visitId,
  setPatientAvatar,
  className,
  mergeWithDeletedPatient,
}) => {
  const [patientModalState, setPatientModalState] = useState(false);

  return (
    <Wrapper className={className} data-testid="patientInfoHeader">
      <PatientModal
        modalState={patientModalState}
        setModalState={setPatientModalState}
        onSubmit={updatePatientWithVisits}
        currentPatient={currentPatient}
        setPatientAvatar={setPatientAvatar}
        mergeWithDeletedPatient={mergeWithDeletedPatient}
      />
      {!deleted && <Button title='Edytuj pacjenta' variant='edit'
        onClick={() => setPatientModalState(true)}
      />}
      <PatientMainInfo>
        <Avatar src={avatar ? `${process.env.REACT_APP_SRV_URL}${avatar}` : AvatarPlaceholder} />
        <PatientName>
          <>
            <Name>{name}</Name>
            <Surname>{surname}</Surname>
          </>
        </PatientName>
      </PatientMainInfo>
      {patientMedsDiagnosis &&
        <PatientConstants>
          {patientMedsDiagnosis?.constantMeds?.length > 0 &&
            <InfoPill
              to={`/visit/${visitId}/medsdiagnosis?target=constantMeds`}
              variant={'yellow'}>
              <StyledInfoIcon />
              {`Stale przyjmowane leki: ${patientMedsDiagnosis?.constantMeds?.length}`}
            </InfoPill>
          }
          {patientMedsDiagnosis?.constantDiagnosis?.length > 0 &&
            <InfoPill
              to={`/visit/${visitId}/medsdiagnosis?target=constantDiagnosis`}
              variant={'yellow'}
              iconPosition="top">
              <StyledInfoIcon />
              <PillContent>
                <p>{`Choroby przewlekłe: ${patientMedsDiagnosis?.constantDiagnosis?.length}`}</p>
                <p>{patientMedsDiagnosis?.constantDiagnosis?.map(({ subcategoryCode }) => `${subcategoryCode}, `)}</p>
              </PillContent>
            </InfoPill>
          }
        </PatientConstants>
      }
      <PatientData>
        <InfoPill variant={pesel ? 'primary' : 'danger'}><StyledPeselIcon variant={pesel ? 'primary' : 'danger'} /> {pesel ? pesel : 'Brak peselu!'}</InfoPill>
        <InfoPill variant={sex ? 'primary' : 'danger'}><StyledSexIcon variant={sex ? 'primary' : 'danger'} /> {sex === 'm' ? 'Mężyczyzna' : sex === 'f' ? 'Kobieta' : 'Brak płci!'}</InfoPill>
        <InfoPill noWrap={true} variant={fileNumber ? 'primary' : 'danger'}><StyledFileIcon variant={fileNumber ? 'primary' : 'danger'} /> {fileNumber ? fileNumber : 'Brak kartoteki!'}</InfoPill>
        <InfoPill variant={email ? 'primary' : 'danger'}><StyledEmailIcon variant={email ? 'primary' : 'danger'} /> {email ? email : 'Brak emaila!'}</InfoPill>
        <InfoPill variant={address?.street && address?.houseNumber && address?.city ? 'primary' : 'danger'}><StyledAddressIcon variant={address?.street && address?.houseNumber && address?.city ? 'primary' : 'danger'} />{parseAddress(address)}</InfoPill>
        <InfoPill variant={tel ? 'primary' : 'danger'}><StyledPhoneIcon variant={tel ? 'primary' : 'danger'} /> {tel ? tel : 'Brak telefonu!'}</InfoPill>
        <InfoPill variant={birthDate ? 'primary' : 'danger'}><StyledCalendarIcon variant={birthDate ? 'primary' : 'danger'} /> {birthDate ? birthDate : 'Brak daty urodzenia!'}</InfoPill>
        {additionalInfo &&
          <InfoPill
            variant='yellow'
            iconPosition='top'
            collapsedText={additionalInfo}
            maxLength={70}
          >
            <StyledInfoIcon />
          </InfoPill>
        }
      </PatientData>
    </Wrapper>)
}


const mapStateToProps = state => ({
  currentPatient: state.patients.currentPatient
});


const mapDispatchToProps = dispatch => {
  return {
    updatePatientWithVisits: (patientNewData) => dispatch(updatePatientWithVisits(patientNewData)),
    setPatientAvatar: (patientId, data) => dispatch(setPatientAvatar(patientId, data)),
    mergeWithDeletedPatient: (patientId, body) => dispatch(mergeWithDeletedPatient(patientId, body))
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInfoHeader);



const Wrapper = styled.div`
  width:100%;
  position: relative;
  padding: 20px 60px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-height:190px;
`;

const PatientMainInfo = styled.div`
  display:flex;
  align-items: center;
`;

const Avatar = styled.img`
background-color: #ccc;
  border-radius: 4px;
  width: 60px;
  height: 60px;
`;

const PatientName = styled.div`
  margin-left:10px;
`;

const Name = styled.p`
  font-size:18px;
  color: ${({ theme }) => theme.lightBlack};
  line-height: 1;
`;

const Surname = styled.p`
  font-size:36px;
  color: ${({ theme }) => theme.lightBlack};
  line-height: 1;
  font-weight: 600;
`;

const PatientData = styled.div`
  display:flex;
  justify-content:flex-end;
  padding-right:10px;
  flex-wrap: wrap;
  max-width:610px;
`;


const StyledIcon = css`
  display: flex;
  align-items: center;
  height: 100%;
  min-width:16px;
  max-height:17px;
  margin-right: 3px;
  ${({ variant, theme }) =>
    variant === 'primary' ? `path{fill:${theme.primary}};` :
      variant === 'yellow' ? `path{fill:${theme.yellow}};` :
        variant === 'danger' ? `path{fill:${theme.danger}};` :
          variant === 'black' ? `path{fill:${theme.lightBlack}};` :
            ''
  }
`;

const StyledPeselIcon = styled(PeselIcon)`
  ${StyledIcon}
`;
const StyledSexIcon = styled(SexIcon)`
  ${StyledIcon}
`;
const StyledFileIcon = styled(FileIcon)`
  ${StyledIcon}
`;
const StyledEmailIcon = styled(EmailIcon)`
  ${StyledIcon}
`;
const StyledAddressIcon = styled(AddressIcon)`
  ${StyledIcon}
`;
const StyledPhoneIcon = styled(PhoneIcon)`
  ${StyledIcon}
`;
const StyledCalendarIcon = styled(CalendarIcon)`
  ${StyledIcon}
  path{
    /* fill:${({ theme }) => theme.primary} */
  }
`;
const StyledInfoIcon = styled(InfoIcon)`
  ${StyledIcon}
`;

const PatientConstants = styled.div`
  display:grid;
  align-items:start;
  grid-template-columns: 1fr 1fr;
  gap:10px;
  margin-left:10px;
  max-width:400px;
`;

const PillContent = styled.div`
  display:flex;
  flex-direction:column;
  p{
    color:inherit;
    &:first-child{
      margin:2px 0 1px;
    }
  }
`;
