import React from "react";
import styled from "styled-components";
import EmployeeCard from "./EmployeeCard";
import Container from "../Shared/Container";
import Button from "../Shared/Button";


const EmployeesList = ({
  employees,
  title,
  setCreateModal,
  setEditModal,
  setEmployeeType,
  listType,
  setCurrentUser,
  usersVacationsCount
}) => {
  return (
    <StyledContainer title={title}>
      <HeaderList>
        <InfoTitle>Rola</InfoTitle>
        <InfoTitle>Dane</InfoTitle>
        <InfoTitle>Czas wizyty</InfoTitle>
        <InfoTitle>Status</InfoTitle>
        <InfoTitle>Zaplanowane dni urlopu</InfoTitle>
        <InfoTitle>Kolor</InfoTitle>
        <InfoTitle>Akcje</InfoTitle>
      </HeaderList>
      <CardsWrapper>
        {employees.length > 0 ? (
          employees.map(employee => <EmployeeCard key={employee._id} employee={employee} vacationsCount={usersVacationsCount[employee.userId._id]} setEditModal={setEditModal} setCurrentUser={setCurrentUser} />)
        ) : (
            <NoEmployeesInfo>Brak pracowników w tej sekcji</NoEmployeesInfo>
          )}
      </CardsWrapper>
      <StyledButton variant="primary" onClick={() => { setCreateModal(true); setEmployeeType(listType) }}>Dodaj pracownika</StyledButton>

    </StyledContainer>
  );
};


export default EmployeesList;

const StyledContainer = styled(Container)`
  min-width: 90%;
  min-height: 200px;
`;
const CardsWrapper = styled.div`
  max-height: 67vh;
  overflow-y: scroll;
`;

const HeaderList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  width: calc(100% - 12px);
`;

const InfoTitle = styled.div`
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: ${({theme})=> theme.black};
  font-size: 14px;
`;

const NoEmployeesInfo = styled.div`
  font-size: 16px;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
margin-top: 20px;
  margin-left: auto;
  margin-right: 0;
  display: block;
`;
