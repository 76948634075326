export const ADD_VISIT_FROM_SOCKET = "ADD_VISIT_FROM_SOCKET"
export const UPDATE_VISIT_FROM_SOCKET = "UPDATE_VISIT_FROM_SOCKET"
export const DELETE_VISIT_FROM_SOCKET = "DELETE_VISIT_FROM_SOCKET"

export const addVisitFromSocket = visit => ({
    type: ADD_VISIT_FROM_SOCKET,
    payload: visit
})

export const updateVisitFromSocket = visit => ({
    type: UPDATE_VISIT_FROM_SOCKET,
    payload: visit
})

export const deleteVisitFromSocket = visitId => ({
    type: DELETE_VISIT_FROM_SOCKET,
    payload: visitId
})
