import React from 'react';
import { default as RCTimePicker } from 'rc-time-picker';
import styled from 'styled-components';
import 'rc-time-picker/assets/index.css';
import timeIcon from './assets/time-grey.svg';
import Label from "./Label";

const TimePicker = ({
    value,
    label,
    allowEmpty,
    defaultValue,
    onChange,
    onBlur,
    name,
    showSecond,
    minuteStep,
    disabledHours,
    disabledMinutes,
    hideDisabledOptions,
    className,
    error,
    showHour = true,
    showBackgroundImage = true,
    disabled,
}) => {
    return (
        <TimePickerContainer className={className}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <StyledTimePicker
                id={name}
                value={value}
                disabled={disabled}
                allowEmpty={allowEmpty}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                showSecond={showSecond}
                minuteStep={minuteStep}
                showHour={showHour}
                popupStyle={{
                    width: !showHour && !showSecond ? '65px' : '170px',
                }}
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                hideDisabledOptions={hideDisabledOptions}
                showBackgroundImage={showBackgroundImage}
            />
            <ErrorInfo error={error}>{error}</ErrorInfo>
        </TimePickerContainer>
    )
}

export default TimePicker;

const TimePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledTimePicker = styled(RCTimePicker)`
&.rc-time-picker{
    border:none!important;
}
.rc-time-picker-input{
    border-radius: 4px;
    font-size: 14px;
    margin: 0 2px;
    min-height: 38px;
    padding-left: 10px;
    outline: none;
    -webkit-transition: all 100ms ease 0s;
    transition: all 100ms ease 0s;
    width: ${({ showHour, showSecond }) => !showHour && !showSecond ? '65px' : '112px'};
    margin: 2px 0 2px 0;
    border: 1px solid rgb(204,204,204);
    background-size:18px;
    background-position: center right 10px;
    background-repeat:no-repeat;
    ${({ showBackgroundImage }) => showBackgroundImage && `
      background-image: url(${timeIcon});
    `}
}

`

const ErrorInfo = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.danger};
  text-align: right;
  margin-bottom: 5px;
  visibility: hidden;
  ${({ error }) =>
        error
            ? `
    visibility: visible;`
            : `
    height: 13px;
    `}
`;