import React from "react";
import styled from "styled-components";
import Calendar from "../../../Shared/Calendar";
import Button from "../../../Shared/Button";
import Select from "../../../Shared/Select";
import AddEventModal from "../../../CalendarPage/Modals/AddEventModal";
import WarningModal from '../../../CalendarPage/Modals/WarningModal';
import VisitDashboardCard from '../Dashboard/VisitDashboardCard';
import NextVisitTimeIndicator from '../Dashboard/NextVisitTimeIndicator';
import CollapseList from '../../ReceptionistSidebar/CollapseList';

const DoctorCalendarSidebarView = ({
  calendarDate,
  updateCalendarDate,
  isCreateModalOn,
  setCreateModal,
  user,
  userRole,
  doctors,
  patients,
  currentHealthcenter,
  addPatient,
  addVisit,
  visits,
  setWarningModal,
  warningModal,
  getCompanyPatientsHandler,
  listVisits,
  setSelectValue,
  selectOptions,
  selectValue,
  setCurrentVisit,
  currentVisit,
  sidebarSwitch,
  setSidebarSwitch,
  updateDoctorsList,
  updateGroupsList,
  updateNursesList,
  calendarDoctors,
  calendarNurses,
  calendarGroups,
}) => {
  return (
    <SidebarWrapper>
      <WarningModal
        show={warningModal.shown}
        question={warningModal.question}
        onAccept={warningModal.onAccept}
        preventClosing={warningModal.preventClosing}
        setModalState={setWarningModal}
      />
      <AddEventModal
        isCreateModalOn={isCreateModalOn}
        setCreateModal={setCreateModal}
        patients={patients}
        doctors={doctors}
        addPatient={addPatient}
        companyId={userRole?.companyId}
        addVisit={addVisit}
        currentHealthcenter={currentHealthcenter}
        userRole={userRole}
        user={user}
        selectedEvent={{ start: typeof (calendarDate) === "object" ? calendarDate.setMinutes(0, 0, 0) : new Date(calendarDate).setMinutes(0, 0, 0), end: typeof (calendarDate) === "object" ? calendarDate.setMinutes(userRole?.callendarSettings.step ?? 30, 0, 0) : new Date(calendarDate).setMinutes(userRole?.callendarSettings.step ?? 30, 0, 0) }}
        calendarDate={calendarDate}
        visits={visits}
        setWarningModal={setWarningModal}
        getCompanyPatientsHandler={getCompanyPatientsHandler}
      />
      <StyledCalendar
        value={new Date(calendarDate)}
        onChange={date => updateCalendarDate(date)}
      />
      <CalendarActions>
        <Today
          id='backToToday'
          onClick={() => updateCalendarDate(Date.now())}>Dzisiaj</Today>
      </CalendarActions>
      <AddVisitButton
        plusIcon={true}
        variant="primary"
        onClick={() => setCreateModal(true)}
      >
        Dodaj nową wizytę
      </AddVisitButton>
      <SwitchWrapper id="SidebarCalendarSwitch">
        <Switch>
          <SwitchItem active={sidebarSwitch} onClick={() => setSidebarSwitch(true)}>
            Wizyty
        </SwitchItem>
          <SwitchItem
            active={!sidebarSwitch}
            onClick={() => setSidebarSwitch(false)}
          >
            Filtry
        </SwitchItem>
          <SwitchHr active={sidebarSwitch === false} />
        </Switch>
      </SwitchWrapper>
      {sidebarSwitch ? (
        <>
          <UnderCalendarToolbarContainer>
            <VisitCountText>{listVisits.length === 1 ? '1 wizyta' : listVisits.length + ' wizyt'}</VisitCountText>
            <StyledSelect
              options={selectOptions}
              selectValue={selectValue}
              onChange={({ value }) => setSelectValue(value)}
              defaultValue={{ label: "Wszystkie", value: "all" }}
            />
          </UnderCalendarToolbarContainer>
          <ScrollableWrapper id='calendarVisitsList'>
            {listVisits.map(visit =>
              <VisitCardContainer key={visit._id}>
                <VisitDashboardCard
                  visit={visit}
                  currentVisit={currentVisit}
                  setCurrentVisit={setCurrentVisit}
                />
                <NextVisitTimeIndicator timeToNext={visit.timeToNext} />
              </VisitCardContainer>
            )}
          </ScrollableWrapper>
        </>
      ) : (
          <CollapseListsWrapper id="calendarFilters">
            <CollapseList
              items={[...calendarDoctors, ...calendarNurses]}
              label="Kalendarze"
              onChange={targetName => {
                const updatedDoctorsList = calendarDoctors.map(doctor =>
                  doctor.name === targetName
                    ? { ...doctor, checked: !doctor.checked }
                    : doctor
                );
                const updatedNursesList = calendarNurses.map(nurse =>
                  nurse.name === targetName
                    ? { ...nurse, checked: !nurse.checked }
                    : nurse
                );
                const updatedDoctorsIds = updatedDoctorsList
                  .filter(doctor => doctor.checked)
                  .map(doctor => doctor._id);
                const updatedNursesIds = updatedNursesList
                  .filter(nurse => nurse.checked)
                  .map(nurse => nurse._id);
                const updatedGroupsList = calendarGroups.map(group =>
                  (JSON.stringify(group.doctors) ===
                    JSON.stringify(updatedDoctorsIds) || JSON.stringify(group.nurses) ===
                    JSON.stringify(updatedNursesIds))
                    ? { ...group, checked: true }
                    : { ...group, checked: false }
                );
                updateDoctorsList(updatedDoctorsList);
                updateNursesList(updatedNursesList);
                updateGroupsList(updatedGroupsList);
              }}
              onSetAll={targetState => {
                const updatedDoctorsList = calendarDoctors.map(doctor => {
                  return { ...doctor, checked: targetState };
                });
                const updatedDoctorsIds = updatedDoctorsList
                  .filter(doctor => doctor.checked)
                  .map(doctor => doctor._id);

                const updatedNursesList = calendarNurses.map(nurse => {
                  return { ...nurse, checked: targetState };
                });
                const updatedNursesIds = updatedNursesList
                  .filter(doctor => doctor.checked)
                  .map(doctor => doctor._id);

                const updatedGroupsList = calendarGroups.map(group =>
                  (JSON.stringify(group.doctors) ===
                    JSON.stringify(updatedDoctorsIds) || JSON.stringify(group.nurses) ===
                    JSON.stringify(updatedNursesIds))
                    ? { ...group, checked: true }
                    : { ...group, checked: false }
                );
                updateDoctorsList(updatedDoctorsList);
                updateNursesList(updatedNursesList);
                updateGroupsList(updatedGroupsList);
              }}
              onSetOnlyLoggedDoctor={() => {
                const updatedDoctorsList = calendarDoctors.map(doctor => {
                  if (doctor._id === userRole._id) {
                    return { ...doctor, checked: true };
                  } else {
                    return { ...doctor, checked: false };
                  }
                });
                const updatedDoctorsIds = updatedDoctorsList
                  .filter(doctor => doctor.checked)
                  .map(doctor => doctor._id);
                const updatedNursesList = calendarNurses.map(nurse => {
                  if (nurse._id === userRole._id) {
                    return { ...nurse, checked: true };
                  } else {
                    return { ...nurse, checked: false };
                  }
                });
                const updatedNursesIds = updatedNursesList
                  .filter(nurse => nurse.checked)
                  .map(nurse => nurse._id);
                const updatedGroupsList = calendarGroups.map(group =>
                  (JSON.stringify(group.doctors) ===
                    JSON.stringify(updatedDoctorsIds) || JSON.stringify(group.nurses) ===
                    JSON.stringify(updatedNursesIds))
                    ? { ...group, checked: true }
                    : { ...group, checked: false }
                );
                updateDoctorsList(updatedDoctorsList);
                updateNursesList(updatedNursesList);
                updateGroupsList(updatedGroupsList);
              }}
            />
            <CollapseList
              items={calendarGroups}
              label="Grupy"
              onChange={(targetName, status) => {
                const groupDoctorsIds = calendarGroups.find(
                  group => group.name === targetName
                ).doctors;
                const updatedGroupsList = calendarGroups.map(group =>
                  JSON.stringify(group.doctors) === JSON.stringify(groupDoctorsIds)
                    ? { ...group, checked: !group.checked }
                    : { ...group, checked: false }
                );
                const updatedDoctorsList = calendarDoctors.map(doctor =>
                  groupDoctorsIds.some(doctorId => doctorId === doctor._id)
                    ? { ...doctor, checked: !status }
                    : { ...doctor, checked: false }
                );
                updateGroupsList(updatedGroupsList);
                updateDoctorsList(updatedDoctorsList);
              }}
            // onSetAll={(targetState) => {
            //   const updatedList = calendarGroups.map(group => {return {...group, checked: targetState}});
            //   updateGroupsList(updatedList);
            // }}
            />
          </CollapseListsWrapper>
        )
      }
    </SidebarWrapper>
  );
};

export default DoctorCalendarSidebarView;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddVisitButton = styled(Button)`
  margin: 0 10px;
`;

const StyledCalendar = styled(Calendar)`
  padding: 10px;
  margin-bottom: 0;
`

const CalendarActions = styled.div`
  display: flex;
  justify-content:flex-end;
  align-items:center;
  padding: 0 15px 5px;
`;

const Today = styled.p`
  font-size:14px;
  font-weight: 600;
  cursor:pointer;
  color:${({ theme }) => theme.lightBlack};
  transition:300ms;
  &:hover{
    color:${({ theme }) => theme.primary};
  }
`;

const VisitCardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
`;

const ScrollableWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 480px);
`;

const UnderCalendarToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VisitCountText = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
`;

const StyledSelect = styled(Select)`
  width:150px;
  margin-right: 10px;
`;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content:center;
  padding:5px 0;
  margin:5px 0;
  border-bottom:1px solid ${({ theme }) => theme.grey};
`;

const Switch = styled.div`
  display: flex;
  position:relative;
  margin:0 10px;
`;

const SwitchItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  transition: 300ms;
  width:75px;
  text-align:center;
  opacity: 0.5;
  margin: 0 2.5px;
  ${({ active }) => active && `opacity:1;`}
`;

const SwitchHr = styled.div`
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 80px;
  transition: 300ms;
  background: ${({ theme }) => theme.primary};
  ${({ active }) => active && "left:50%;"}
`;

const CollapseListsWrapper = styled.div`
  max-height: calc(100vh - 335px);
  overflow-y: scroll;
  padding: 0 20px 10px;
  margin-right: -10px;
`;