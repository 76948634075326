import axios from "axios";

const GET_PATIENT_HOSPITALIZATIONS_REQUEST = "GET_PATIENT_HOSPITALIZATIONS_REQUEST";
const GET_PATIENT_HOSPITALIZATIONS_SUCCESS = "GET_PATIENT_HOSPITALIZATIONS_SUCCESS";
const GET_PATIENT_HOSPITALIZATIONS_FAIL = "GET_PATIENT_HOSPITALIZATIONS_FAIL";

const UPDATE_PATIENT_HOSPITALIZATIONS_REQUEST = "UPDATE_PATIENT_HOSPITALIZATIONS_REQUEST";
const UPDATE_PATIENT_HOSPITALIZATIONS_SUCCESS = "UPDATE_PATIENT_HOSPITALIZATIONS_SUCCESS";
const UPDATE_PATIENT_HOSPITALIZATIONS_FAIL = "UPDATE_PATIENT_HOSPITALIZATIONS_FAIL";

export const getPatientHospitalizations = (patientId) => async dispatch => {
    dispatch(getPatientHospitalizationsRequest())
    try {
        const response = await axios.get(`hospitalizations/patient/${patientId}`);
        dispatch(getPatientHospitalizationsSuccess(response.data))
    }
    catch (error) {
        dispatch(getPatientHospitalizationsFail(error))
    }
};

const getPatientHospitalizationsRequest = () => ({
    type: GET_PATIENT_HOSPITALIZATIONS_REQUEST,
});

const getPatientHospitalizationsSuccess = (hospitalizations) => ({
    type: GET_PATIENT_HOSPITALIZATIONS_SUCCESS,
    payload: hospitalizations
});

const getPatientHospitalizationsFail = (error) => ({
    type: GET_PATIENT_HOSPITALIZATIONS_FAIL,
    payload: error
});

export const updatePatientHospitalizations = (patientId, newHospitalizations) => async dispatch => {
    dispatch(updatePatientHospitalizationsRequest())
    try {
        const response = await axios.patch(`hospitalizations/patient/${patientId}`, newHospitalizations)
        dispatch(updatePatientHospitalizationsSuccess(response.data))
    }
    catch (error) {
        dispatch(updatePatientHospitalizationsFail(error))
    }
};

const updatePatientHospitalizationsRequest = () => ({
    type: UPDATE_PATIENT_HOSPITALIZATIONS_REQUEST,
});

const updatePatientHospitalizationsSuccess = (hospitalization) => ({
    type: UPDATE_PATIENT_HOSPITALIZATIONS_SUCCESS,
    payload: hospitalization
});

const updatePatientHospitalizationsFail = (error) => ({
    type: UPDATE_PATIENT_HOSPITALIZATIONS_FAIL,
    payload: error
});

const initialState = {
    patientHospitalizations: [],
    loading: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT_HOSPITALIZATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_PATIENT_HOSPITALIZATIONS_SUCCESS:
            return {
                ...state,
                patientHospitalizations: action.payload,
                loading: false
            }
        case GET_PATIENT_HOSPITALIZATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_PATIENT_HOSPITALIZATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_PATIENT_HOSPITALIZATIONS_SUCCESS:
            return {
                ...state,
                patientHospitalizations: action.payload,
                loading: false
            }
        case UPDATE_PATIENT_HOSPITALIZATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};