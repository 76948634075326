import React, { useState } from "react";
import Modal from "../../Shared/Modals/Modal";
import styled from "styled-components";
import Input from "../../Shared/Input";
import Textarea from "../../Shared/Textarea";
import Button from "../../Shared/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Select from "../../Shared/Select";
import DatePicker from "../../Shared/DatePicker";
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Pole wymagane"),
  start: Yup.date().required("Pole wymagane"),
  end: Yup.date().required("Pole wymagane")
});

const EditAllDayEventModal = ({
  isEditAlldayEventModalOn,
  setEditAllDayModal,
  doctors,
  selectedEvent,
  updateAllDayEventById,
  deleteAllDayEventById
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const initialValues = {
    healthcenter: selectedEvent.healthcenter,
    title: selectedEvent.title,
    registeringPerson: selectedEvent.registeringPerson,
    start: moment(selectedEvent.startDate).format("YYYY-MM-DD HH:mm:ss"),
    end: moment(selectedEvent.endDate).format("YYYY-MM-DD HH:mm:ss"),
    members: selectedEvent.members?.map(member => {
      const doc = doctors.find(doc => doc._id === member);
      return {
        label: `${doc?.userId.name} ${doc?.userId.surname}`,
        value: member
      };
    }),
    description: selectedEvent.description
  };
  return (
    <Modal
      title="Edycja wydarzenia długoterminowego"
      show={isEditAlldayEventModalOn}
      exitButton={true}
      onCancel={() => {
        setEditAllDayModal(false);
      }}
    >
      <Modal
        type="SimpleQuestionModal"
        show={deleteModal}
        questionTitle="Jesteś pewny?"
        question="Czy na pewno chcesz usunąć to wydarzenie?"
        acceptText="Tak"
        cancelText="Nie"
        onAccept={() => {
          deleteAllDayEventById(selectedEvent._id);
          setDeleteModal(false);
          setEditAllDayModal(false);
        }}
        onCancel={() => setDeleteModal(false)}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async values => {
          console.log("submit");
          const valuesToUpdate = {
            ...values,
            start: moment(values.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })._d,
            end: moment(values.end).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })._d,
            members: values.members.map(member => member.value)
          };
          updateAllDayEventById(selectedEvent._id, valuesToUpdate);
          setEditAllDayModal(false);
          toast('Wydarzenie długoterminowe zostało zmienione.', {
            type: 'success'
        })
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
            <Form onSubmit={handleSubmit}>
              <Input
                type="text"
                name="title"
                label="Tytuł"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                error={errors.title}
              />
              <InputRow>
                <DatePicker
                  label="Początek"
                  name="Początek"
                  dateFormat="dd.MM.yyyy"
                  selected={new Date(values.start)}
                  error={errors.start}
                  onChange={e =>
                    setFieldValue(
                      "start",
                      moment(e).format("YYYY-MM-DD HH:mm:ss")
                    )
                  }
                />
                <DatePicker
                  label="Koniec"
                  name="Koniec"
                  dateFormat="dd.MM.yyyy"
                  selected={new Date(values.end)}
                  error={errors.end}
                  onChange={e =>
                    setFieldValue("end", moment(e).minute(1).format("YYYY-MM-DD HH:mm:ss"))
                  }
                />
              </InputRow>
              <Select
                label="Członkowie"
                name="members"
                placeholder="Wybierz osoby..."
                value={values.members}
                options={doctors.map(doctor => {
                  return { label: `${doctor.userId.name} ${doctor.userId?.surname}`, value: doctor._id };
                })}
                onChange={value =>
                  value !== null
                    ? setFieldValue("members", value)
                    : setFieldValue("members", [])
                }
                noOptionsMessage={() => "Nie znaleziono"}
                error={errors.members}
                isMulti={true}
              />

              <Textarea
                name="description"
                label="Dodatkowe informacje"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                error={errors.description}
              />
              <CenterBox>
                <Button type="submit" variant="primary">
                  Zapisz zmiany
                </Button>
                <Button variant="danger" onClick={() => setDeleteModal(true)}>
                  Usuń wydarzenie
                </Button>
              </CenterBox>
            </Form>
          )}
      </Formik>
    </Modal>
  );
};
export default EditAllDayEventModal;

const Form = styled.form`
  padding-top: 20px;
  width: 450px;
`;

const CenterBox = styled.div`
  text-align: center;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 48%;
  }
`;
