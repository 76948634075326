export const messages = {
    date: "Data",
    time: "Teraz",
    event: "Wizyta",
    allDay: "",
    next: ">",
    previous: "<",
    today: "Dzisiaj",
    month: "miesiąc",
    week: "tydzień",
    day: "dzień",
    agenda: "terminarz",
    showMore: (e) => `+${e} więcej`
  };
  
  export const minTime = new Date();
  minTime.setHours(8, 0, 0);
  export const maxTime = new Date();
  maxTime.setHours(18, 0, 0);


  export const defaultView = "day";

  export const slotTime = 30;