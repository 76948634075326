import React from "react";
import styled from "styled-components";

const NextVisitTimeIndicator = ({timeToNext}) => {

  return (
    timeToNext !== '' ? 
        timeToNext > 5 ?
            <>
                <LineDiv></LineDiv>
                <StyledText>{timeToNext} min</StyledText>
                <LineDiv></LineDiv>
            </>
        :
        <LineDiv></LineDiv>
    :
    <></>
  );
};

export default NextVisitTimeIndicator;

const LineDiv = styled.div`
border-left: 1px solid #C4C4C4;
height: 8px;
`

const StyledText = styled.span`
 color: #595959;
 font-size: 12px;
`