import { steps, tutorialsNames } from '../../features/Tutorial/tutorialEnums';
const SET_TUTORIAL = 'SET_TUTORIAL';
const NEXT_TUTORIAL_STEP = 'NEXT_TUTORIAL_STEP';

export const setTutorial = ({ page, action, ...payload }) => {
    if (page) {
        payload.steps = steps[page];
        payload.current = tutorialsNames[page];
    }
    return {
        type: SET_TUTORIAL,
        payload
    }
};

export const nextTutorialStep = (stepValidation = null) => async (dispatch, getState) => {
    const state = getState();
    if (state.tutorial.active && (stepValidation === null || stepValidation === state.tutorial.stepIndex)) {
        setTimeout(() => {
            dispatch(setTutorial({ stepIndex: state.tutorial.stepIndex + 1 }))
        }, 100)
    }
}

export const prevTutorialStep = () => async (dispatch, getState) => {
    const state = getState();
    if (state.tutorial.active) {
        setTimeout(() => {
            dispatch(setTutorial({ stepIndex: state.tutorial.stepIndex - 1 }))
        }, 100)
    }
}

const initialState = {
    active: false,
    stepIndex: 0,
    steps: [],
    current: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TUTORIAL':
            return {
                ...state,
                ...action.payload
            }
        case 'NEXT_TUTORIAL_STEP':
            return {
                ...state,
                stepIndex: state.stepIndex + 1
            }
        default:
            return state;
    }
};