import React, { useState } from 'react'
import styled from 'styled-components';
import Input from '../../Shared/Input';
import OutsideAlerter from '../../Shared/OutsideAlerter';
import AvatarImg from "../../Shared/assets/avatar.svg";
import { ReactComponent as dotsIcon } from '../../Shared/assets/dots.svg';
import { ReactComponent as downIcon } from '../../Shared/assets/arrow-down.svg';

const CollapseList = ({
    label,
    items,
    onChange,
    onSetAll,
    onSetOnlyLoggedDoctor
}) => {
    const [collapseState, setCollapseState] = useState(false),
        [dropdownState, setDropdownState] = useState(false);

    return (
        <CollapseWrapper>
            <CollapseHeader>
                <Heading>
                    <Label>
                        {label}
                    </Label>
                    <Length>
                        {items?.length}
                    </Length>
                </Heading>
                <Actions className='collapse-list-actions'>
                    {onSetAll && <MoreBtn onClick={() => setDropdownState(prev => !prev)} />}
                    <CollapseBtn onClick={() => setCollapseState(prev => !prev)} collapsed={collapseState.toString()} />
                    {(dropdownState && (onSetAll || onSetOnlyLoggedDoctor)) &&
                        <OutsideAlerter
                            setModalAlerterOpen={setDropdownState}
                            isModalAlerterOpen={dropdownState}>
                            <Popup>
                                {onSetAll &&
                                    <>
                                        <PopupItem onClick={() => { onSetAll(true); setDropdownState(false); }}>Zaznacz wszystkie</PopupItem>
                                        <PopupItem onClick={() => { onSetAll(false); setDropdownState(false); }}>Odznacz wszystkie</PopupItem>
                                    </>
                                }
                                {onSetOnlyLoggedDoctor && <PopupItem onClick={() => { onSetOnlyLoggedDoctor(); setDropdownState(false); }}>Tylko Twoje</PopupItem>}
                            </Popup>
                        </OutsideAlerter>
                    }
                </Actions>
            </CollapseHeader>
            <CollapseBody collapsed={collapseState.toString()}>
                {items?.length > 0 &&
                    items.map(({ avatar, name, surname, checked, visitColor }) => (
                        <CollapseItem key={name}>
                            <Data>
                                {avatar && <Avatar src={!avatar ? AvatarImg : `${process.env.REACT_APP_SRV_URL}${avatar}`} />}
                                <Name>{name} {surname}</Name>
                            </Data>
                            <Input
                                color={visitColor}
                                value={checked}
                                name='isActive'
                                type="checkbox"
                                size="18px"
                                variant="primary"
                                hideError={true}
                                onChange={() => onChange(name, checked)}
                            />
                        </CollapseItem>
                    ))
                }
            </CollapseBody>
        </CollapseWrapper>
    )
}

export default CollapseList;

const CollapseWrapper = styled.div`
    margin:10px 0;
    position:relative;
`;

const CollapseHeader = styled.div`
    display:flex;
    justify-content: space-between;
    align-items:center;
`;

const Heading = styled.div`
    display:flex;
    align-items:center;
`;

const Label = styled.div`
    font-size:14px;
`;

const Length = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:${({ theme }) => theme.primary};
    border-radius:50%;
    width:20px;
    height:20px;
    font-size:14px;
    color:#fff;
    line-height:0;
    margin-left:10px;
`;

const Actions = styled.div`
    display:flex;
    align-items:center;
`;

const CollapseBody = styled.div`
    padding-top:10px;
    overflow-y:auto;
    overflow-x:hidden;
    max-height:220px;
    transition:300ms;
    ${({ collapsed }) =>
        collapsed === 'true' && `max-height:0; padding-top:0;`
    }
`;

const CollapseBtn = styled(downIcon)`
    cursor:pointer;
    transform: rotate(180deg);
    transition:300ms;
    path{
        fill:#000;
    }
    ${({ collapsed }) =>
        collapsed === 'true' && `transform: rotate(0)`
    }
`;

const MoreBtn = styled(dotsIcon)`
    margin-right:10px;
    cursor:pointer;
    height:20px;
`;

const Popup = styled.ul`
    position: absolute;
    z-index: 999;
    top: 5px;
    right:0;
    width: 100px;
    background-color: #fff;
    color: #343536;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
`;

const PopupItem = styled.li`
    font-size:10px;
    text-align:center;
    padding:5px 0;
    cursor: pointer;
    transition:300ms;
    user-select:none;
    &:hover{
        background-color:#f8f9fa;
    }
`;

const CollapseItem = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:stretch;
    padding:10px 0;
`;

const Data = styled.div`
    display:flex;
    align-items:center;
`;

const Name = styled.div`
    font-size:14px;
`;

const Avatar = styled.img`
  border-radius: 50%;
  width:32px;
  height:32px;
  margin-right: 10px;
`;