import React from "react";
import styled from "styled-components";
import Container from '../Shared/Container';
import StageNav from './StageNav';
import SidePanel from './SidePanel';
import ElementsDrop from './ElementsDrop';
import History from './History';
import ElementsForm from './ElementsForm';
import GeneratedText from './GeneratedText';
import Documents from './Documents';
import SidebarMobileMask from '../Shared/SidebarMobileMask';

import DocumentsModal from './Modals/DocumentsModal';
import WarningModal from './Modals/WarningModal';
import PrescriptionModal from './Modals/PrescriptionModal';

const VisitView = ({
  currentPatient,
  doctorsElements,
  currentStage,
  setCurrentStage,
  elIsDragging,
  setElIsDragging,
  elementsLoading,
  dropElementHandler,
  currentElement,
  setCurrentElement,
  changeElementValueHandler,
  documentsModal,
  setDocumentsModal,
  addDocumentHandler,
  documents,
  warningModal,
  setWarningModal,
  warningModalHandler,
  stagesTexts,
  setStagesTextsSchemas,
  getMedicalData,
  patientMedsDiagnosis,
  patientHospitalizations,
  visitHistory,
  changeCurrentHistoryVisitHandler,
  historyMedicalVisit,
  historyLoading,
  medsLoading,
  diagnosisLoading,
  proceduresLoading,
  diagnosis,
  procedures,
  meds,
  otherDiagnosis,
  otherMeds,
  medicalProcedures,
  services,
  setServices,
  prescriptionModalOn,
  setPrescriptionModal,
  changeMedDataHandler,
  medsSwitch,
  setMedsSwitch,
  readMode,
  updateMedicalVisitHandler,
  doctorsElementsSets,
  changeIndexHandler,
  currentVisit,
  currentMedicalVisit,
  navBlock,
  editMode,
  sidebarActive,
  setSidebarActive,
  swipeHandlers,
  changeTextsSchemasHandler,
  updateVisit
}) => {
  return (
    <Wrapper {...swipeHandlers}>
      <DocumentsModal
        modalState={documentsModal}
        setModalState={setDocumentsModal}
        addDocumentHandler={addDocumentHandler}
      />
      <WarningModal
        modalState={warningModal}
        setModalState={setWarningModal}
      />
      <PrescriptionModal
        modalState={prescriptionModalOn.isOpen}
        setModalState={setPrescriptionModal}
        type={prescriptionModalOn.type}
      />
      <SidebarMobileMask
        active={sidebarActive}
        accept={['element', 'procedure', 'diagnosis', 'med', 'service', 'set']}
        onClick={() => sidebarActive && setSidebarActive(false)}
      />
      <SidePanel
        currentPatient={currentPatient}
        doctorsElements={doctorsElements}
        elIsDragging={elIsDragging}
        currentStageIndex={currentStage.i}
        setElIsDragging={setElIsDragging}
        elementsLoading={elementsLoading}
        currentStageObj={currentStage.obj}
        diagnosis={diagnosis}
        procedures={procedures}
        meds={meds}
        getMedicalData={getMedicalData}
        medsLoading={medsLoading}
        diagnosisLoading={diagnosisLoading}
        proceduresLoading={proceduresLoading}
        services={services}
        medsSwitch={medsSwitch}
        setMedsSwitch={setMedsSwitch}
        readMode={readMode}
        updateMedicalVisitHandler={updateMedicalVisitHandler}
        doctorsElementsSets={doctorsElementsSets}
        currentVisit={currentVisit}
        otherDiagnosis={otherDiagnosis}
        medicalProcedures={medicalProcedures}
        editMode={editMode}
        sidebarActive={sidebarActive}
      />
      <Content>
        <StageNav
          currentStageIndex={currentStage.i}
          setCurrentStage={setCurrentStage}
          warningModalHandler={warningModalHandler}
          navBlock={navBlock}
          updateMedicalVisitHandler={updateMedicalVisitHandler}
          readMode={readMode}
          services={services}
          updateVisit={updateVisit}
          currentVisit={currentVisit}
        />
        <Cols>
          <Col>
            <StyledContainer
              stageIndex={currentStage.i}
              title={currentStage.obj?.stageName ?? 'WYWIAD'}
            >
              <ElementsDrop
                elIsDragging={elIsDragging}
                dropElementHandler={dropElementHandler}
                currentStage={currentStage}
                currentElement={currentElement}
                setCurrentElement={setCurrentElement}
                otherDiagnosis={otherDiagnosis}
                otherMeds={otherMeds}
                medicalProcedures={medicalProcedures}
                warningModalHandler={warningModalHandler}
                changeMedDataHandler={changeMedDataHandler}
                changeIndexHandler={changeIndexHandler}
                currentMedicalVisit={currentMedicalVisit}
                readMode={readMode}
              />
              <GeneratedText
                stagesTexts={stagesTexts}
                currentStageIndex={currentStage.i}
                warningModalHandler={warningModalHandler}
                documents={documents}
                readMode={readMode}
                changeTextsSchemasHandler={changeTextsSchemasHandler}
                changes={currentMedicalVisit?.changes?.stagesTexts}
              />
            </StyledContainer>
            <Documents
              currentStageIndex={currentStage.i}
              readMode={readMode}
              documents={documents}
              setDocumentsModal={setDocumentsModal}
              setPrescriptionModal={setPrescriptionModal}
              warningModalHandler={warningModalHandler}
            />
          </Col>
          <Col>
            <ElementsForm
              currentStage={currentStage}
              currentElement={currentElement}
              changeElementValueHandler={changeElementValueHandler}
              warningModalHandler={warningModalHandler}
              patientMedsDiagnosis={patientMedsDiagnosis}
              patientHospitalizations={patientHospitalizations}
              services={services}
              setServices={setServices}
              medsSwitch={medsSwitch}
              readMode={readMode}
              changes={currentMedicalVisit.changes}
            />
            <History
              visitHistory={visitHistory}
              historyMedicalVisit={historyMedicalVisit}
              currentStageIndex={currentStage.i}
              changeCurrentHistoryVisitHandler={changeCurrentHistoryVisitHandler}
              historyLoading={historyLoading}
            />
          </Col>
        </Cols>
      </Content>
    </Wrapper >
  );
};

export default VisitView;

const Wrapper = styled.div`
  width: 100%;
  display:flex;
`;

const Content = styled.div`
  width:100%;
  max-width:1400px;
  margin:0 auto;
`;

const Cols = styled.div`
  display:flex;
  >div:first-child{
    width:55%;
  }
  >div:last-child{
    width:45%;
  }
  height: calc(100vh - 160px);
  ${({ theme }) => `${theme.mq.desktop}{
    >div:first-child{
      width:66.6%;
    }
    >div:last-child{
      width:33.3%;
    }
  }`}
`;

const StyledContainer = styled(Container)`
  height: ${({ stageIndex }) => stageIndex === 5 ? 'calc(80% - 5px)' : 'calc(70% - 5px)'};
  margin: 0;
  transition:.3s;
`;

const Col = styled.div`
  padding:10px 10px 0;
  &:last-child{
    padding-left:0;
  }
`;