import React from "react";
import styled from "styled-components";
import Container from "../../Shared/Container";
import Button from "../../Shared/Button";
import Input from "../../Shared/Input";
import Select from "../../Shared/Select";
import Spinner from "../../Shared/Spinner";
import moment from "moment";
import { Calendar } from "react-big-calendar";
import ScheduleCalendar from './ScheduleCalendar';

import UserModal from "./modals/UserModal";
import DeviceModal from "./modals/DeviceModal";
import VacationModal from "./modals/VacationModal";
import SettingsModal from "./modals/SettingsModal";
import { default as DeleteModal } from "../../Shared/Modals/Modal";

import { ReactComponent as emailIcon } from "../../Shared/assets/mail.svg";
import { ReactComponent as calendarStepIcon } from "../../Shared/assets/calendar-week.svg";
import { ReactComponent as calendarWeekendIcon } from "../../Shared/assets/calendar-weekend.svg";
import { ReactComponent as calendarDayIcon } from "../../Shared/assets/calendar-day.svg";
import { ReactComponent as roomIcon } from "../../Shared/assets/door.svg";
import { ReactComponent as colorIcon } from "../../Shared/assets/color.svg";
import { ReactComponent as phoneIcon } from "../../Shared/assets/black-phone.svg";
import iphone from "../../Shared/assets/iphone.png";
import AvatarImg from "../../Shared/assets/avatar.svg";

const DoctorProfileView = ({
  userModal,
  setUserModal,
  deviceModal,
  setDeviceModal,
  user: { name, surname, avatar, email, tel, _id, tutorials, activeRole },
  userRole,
  vacations,
  updateAuthenticatedUser,
  updateAuthenticatedUserRole,
  deleteModal,
  setDeleteModal,
  setUserAvatar,
  vacationModal,
  setVacationModal,
  userSchedules,
  updateScheduleHandler,
  userVacationsCount,
  userVacationsHandler,
  nextTutorialStep,
  settingsModal,
  setSettingsModal,
  currentHealthcenter,
  setCurrentHealthcenter,
  healthcenterOptions,
  roomOptions,
}) => {

  return (
    <Wrapper>
      <UserModal
        user={{ name, surname, email, tel, _id, avatar, activeRole }}
        userRole={userRole}
        doctorId={userRole?._id}
        modalState={userModal}
        setModalState={setUserModal}
        callendarSettings={{ min: userRole?.callendarSettings.min, max: userRole?.callendarSettings.max, step: userRole?.callendarSettings.step }}
        updateAuthenticatedUser={updateAuthenticatedUser}
        updateAuthenticatedUserRole={updateAuthenticatedUserRole}
        weekendWorker={userRole?.weekendWorker}
        setUserAvatar={setUserAvatar}
      />
      <SettingsModal
        modalState={settingsModal}
        setModalState={setSettingsModal}
        tutorials={tutorials}
        user={{ _id, activeRole }}
        userRole={userRole}
        updateAuthenticatedUser={updateAuthenticatedUser}
        updateAuthenticatedUserRole={updateAuthenticatedUserRole}
        roomOptions={roomOptions}
        nextTutorialStep={nextTutorialStep}
      />
      <DeviceModal
        modalState={deviceModal}
        setModalState={setDeviceModal}
      />
      <VacationModal
        modalState={vacationModal}
        setModalState={setVacationModal}
        vacations={vacations}
        userVacationsCount={userVacationsCount}
        userVacationsHandler={userVacationsHandler}
        nextTutorialStep={nextTutorialStep}
      />
      <DeleteModal
        show={deleteModal.shown}
        type="SimpleQuestionModal"
        reverseButtonColors={true}
        question={deleteModal.question}
        acceptText="Usuń"
        cancelText="Anuluj"
        onAccept={deleteModal.onAccept}
        onCancel={() =>
          setDeleteModal({ shown: false, question: "", onAccept: () => { } })
        }
      />
      <Section>
        <StyledContainer title="Profil" user>
          <Button variant="edit" title='Edytuj profil' onClick={() => setUserModal(true)} />
          <User>
            <Main>
              <Avatar src={!avatar ? AvatarImg : `${process.env.REACT_APP_SRV_URL}${avatar}`} />
              <Name>{name} {surname}</Name>
            </Main>
            <Details>
              <DetailItem>
                <BlackEmailIcon />
                {email}
              </DetailItem>
              <DetailItem>
                <BlackPhoneIcon />
                {tel ? tel : "Brak"}
              </DetailItem>
              <DetailItem>
                <BlackCalendarStepIcon />
                {`${userRole?.callendarSettings.step} min`}
              </DetailItem>
              <DetailItem>
                <BlackCalendarWeekendIcon />
                {`${userRole?.weekendWorker ? "Przyjmuje" : "Nie przyjmuje"} w weekendy`}
              </DetailItem>
              <DetailItem>
                <BlackCalendarDayIcon />
                {`${moment(userRole?.callendarSettings.min).format("HH:mm")} - ${moment(userRole?.callendarSettings.max).format(
                  "HH:mm"
                )}`}
              </DetailItem>
              <DetailItem>
                <BlackColorIcon />
                <VisitColor color={userRole?.visitColor} />
              </DetailItem>
            </Details>
          </User>
        </StyledContainer>
        <StyledContainer
          title="Ustawienia"
          settings
          actionButton='edit'
          actionButtonTitle='Edytuj ustawienia'
          actionButtonOnClick={() => {
            setSettingsModal(true)
            if (nextTutorialStep) {
              nextTutorialStep();
            }
          }}
        >
          <SettingList>
            <SettingItem>
              <SettingLabel>Ukończonych samouczków:</SettingLabel>
              {tutorials && Object.values(tutorials).filter(value => value).length}/{tutorials && Object.keys(tutorials).length}
            </SettingItem>
            <SettingItem>
              <SettingLabel>Domyślne gabinety:</SettingLabel>
              {(userRole?.defaultRooms?.length > 0 && roomOptions.length > 0) ? userRole?.defaultRooms.map(({ healthcenter, room }) => {
                const { healthcenterName, rooms = [] } = roomOptions.find(({ healthcenterId }) => healthcenterId === healthcenter) ?? {},
                  { label: roomName } = rooms.find(({ value }) => value === room) ?? {};
                if (healthcenterName) {
                  return <p key={healthcenter}>{healthcenterName} - {roomName}</p>
                }
              })
              :
              'Brak'
            }
            </SettingItem>
            <SettingItem>
              <SettingLabel>Typy wizyt:</SettingLabel>
              {userRole?.visitTypes?.length > 0 ?
                userRole?.visitTypes?.map(({ name }) => <p key={name}>{name}</p>)
                :
                <NoItems>Brak</NoItems>
              }
            </SettingItem>
          </SettingList>
        </StyledContainer>
        <Box>
          <StyledContainer
            title="Urządzenie"
            device
          // actionButton='edit'
          // actionButtonTitle='Edytuj ustawienia'
          // actionButtonOnClick={() => setSettingsModal(true)}
          >
            {/* <DevicePreview src={iphone} /> */}
            <DeviceName>Iphone X - Jacob Murphy</DeviceName>
            <DeviceInfo variant="success">
              Możesz archiwizować zdjęcia i dokumenty za pomocą tego urządzenia
          </DeviceInfo>
          </StyledContainer>
          <StyledContainer
            title="Zaplanowane urlopy"
            vacations
            actionButton='edit'
            actionButtonTitle='Edytuj urlopy'
            actionButtonOnClick={() => {
              setVacationModal(true);
              if (nextTutorialStep) {
                nextTutorialStep();
              }
            }}
          >
            {typeof userVacationsCount === "undefined" ? <Spinner size='8em' minHeight='none' /> :
              <>
                <Vacations>
                  {vacations &&
                    vacations.length > 0 ? vacations.map(({ start, end, _id }) => (
                      <VacationItem key={_id}>
                        {moment(start, moment.ISO_8601).format("DD.MM.YYYY")} -{" "}
                        {moment(end, moment.ISO_8601).format("DD.MM.YYYY")}
                      </VacationItem>
                    )) : <NoItems>Brak zaplanowanych urlopów</NoItems>}
                </Vacations>
                <VactaionsDaysCount>Wykorzystane dni urlopu: {userVacationsCount}</VactaionsDaysCount>
              </>
            }
          </StyledContainer>
        </Box>
      </Section>
      <Section>
        <StyledContainer className='scheduleContainer' title="Harmonogram pracy" timetable>
          <Input
            value={userRole?.usingSchedule}
            name="useCalendar"
            type="checkbox"
            size="18px"
            variant="primary"
            hideError={true}
            onChange={() => updateAuthenticatedUserRole(
              { usingSchedule: !userRole?.usingSchedule ?? true },
              userRole._id,
              activeRole.toLowerCase(),
            )}
            checkboxText="Korzystaj z harmonogramu, aby wspomóc rezerwacje wizyt"
          />
          <StyledSelect
            className='scheduleHealthcenterSelect'
            options={healthcenterOptions}
            value={healthcenterOptions.find(({ value }) => value === currentHealthcenter)}
            onChange={({ value }) => setCurrentHealthcenter(value)}
            isDisabled={!userRole?.usingSchedule}
          />
          <ScheduleCalendar
            userRole={userRole}
            currentHealthcenter={currentHealthcenter}
            userSchedules={userSchedules}
            updateScheduleHandler={updateScheduleHandler}
            disabled={!userRole?.usingSchedule}
          />
        </StyledContainer>
      </Section>
    </Wrapper>
  );
};

export default DoctorProfileView;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: space-between;
  gap:30px;
  padding: 20px 40px;
  ${({ theme }) => `${theme.mq.huge}{
    grid-template-columns: 1fr 2fr;
    gap: 50px;
  }`}
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &:first-child {
    min-width: 470px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

const StyledContainer = styled(Container)`
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  ${({ device, user, settings, timetable }) =>
    user ? `margin-bottom:20px;`
      : device ? `opacity: .5; margin-bottom:20px;`
        : settings ? `width:48%; min-height:400px;`
          : ``}
`;

const User = styled.div`
  display: flex;
`;

const Details = styled.ul`
  padding-left: 10px;
  margin: 0;
`;

const DetailItem = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding-left: 25px;
  margin: 5px 0;
  position: relative;
  display: flex;
  align-items: center;
  svg {
    position: absolute;
    left: 0;
  }
`;

const Main = styled.div`
  width: 160px;
  text-align: center;
`;

const Name = styled.p`
  text-align: text;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 600;
`;

const Avatar = styled.img`
  border-radius: 50%;
  min-width: 150px;
  width: 150px;
  height: 150px;
`;

const DevicePreview = styled.img`
  display: block;
  margin: auto;
`;

const DeviceName = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 5px 0;
`;

const DeviceInfo = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: ${({ variant, theme }) =>
    variant === "success"
      ? theme.green
      : variant === "error"
        ? theme.danger
        : theme.lightBlack};
`;

const SettingList = styled.ul`
  margin: 0;
`;

const SettingItem = styled.li`
  font-size: 12px;
  text-align: center;
  padding: 3px 0;
`;

const SettingLabel = styled.p`
  font-weight:600;
  font-size:14px;
`;

const Vacations = styled.ul`
  margin: 0 0 20px;
  max-height: 170px;
  overflow-y: auto;
`;

const StyledSelect = styled(Select)`
  position: absolute !important;
  right: 10px;
  top: 10px;
  width: 240px;
`;

const StyledCalendar = styled(Calendar)``;

const VacationItem = styled.li`
  font-size: 12px;
  text-align: center;
  padding: 3px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const BlackEmailIcon = styled(emailIcon)`
  transform: scale(1.1);
  path {
    fill: #000;
  }
`;
const BlackPhoneIcon = styled(phoneIcon)`
  height: 15px;
  margin-left: 2.5px;
`;
const BlackCalendarStepIcon = styled(calendarStepIcon)`
  height: 15px;
  path {
    fill: #000;
  }
`;
const BlackCalendarDayIcon = styled(calendarDayIcon)`
  height: 15px;
  path {
    fill: #000;
  }
`;
const BlackColorIcon = styled(colorIcon)`
  height: 15px;
  path {
    fill: #000;
  }
`;
const BlackCalendarWeekendIcon = styled(calendarWeekendIcon)`
  height: 17px;
  transform: translate(-1px);
  path {
    fill: #000;
  }
`;

const RoomIcon = styled(roomIcon)`
  height: 17px;
`;

const NoItems = styled.p`
  text-align: center;
  font-size: 14px;
`;

const VactaionsDaysCount = styled.p`
  position:absolute;
  bottom:7.5px;
  right:7.5px;
  font-size:12px;
`;

const VisitColor = styled.div`
background-color: ${({ color }) => color};
border-radius: 5px;
width: 20px;
height: 20px;
`;