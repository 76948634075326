import axios from 'axios';

const GET_PATIENT_DOCUMENTS_REQUEST = "GET_PATIENT_DOCUMENTS_REQUEST";
const GET_PATIENT_DOCUMENTS_SUCCESS = "GET_PATIENT_DOCUMENTS_SUCCESS";
const GET_PATIENT_DOCUMENTS_FAIL = "GET_PATIENT_DOCUMENTS_FAIL";

const ADD_PATIENT_DOCUMENT_REQUEST = "ADD_PATIENT_DOCUMENT_REQUEST";
const ADD_PATIENT_DOCUMENT_SUCCESS = "ADD_PATIENT_DOCUMENT_SUCCESS";
const ADD_PATIENT_DOCUMENT_FAIL = "ADD_PATIENT_DOCUMENT_FAIL";

const UPDATE_PATIENT_DOCUMENT_REQUEST = "UPDATE_PATIENT_DOCUMENT_REQUEST";
const UPDATE_PATIENT_DOCUMENT_SUCCESS = "UPDATE_PATIENT_DOCUMENT_SUCCESS";
const UPDATE_PATIENT_DOCUMENT_FAIL = "UPDATE_PATIENT_DOCUMENT_FAIL";

const DELETE_PATIENT_DOCUMENT_REQUEST = "DELETE_PATIENT_DOCUMENT_REQUEST";
const DELETE_PATIENT_DOCUMENT_SUCCESS = "DELETE_PATIENT_DOCUMENT_SUCCESS";
const DELETE_PATIENT_DOCUMENT_FAIL = "DELETE_PATIENT_DOCUMENT_FAIL";

export const getPatientDocuments = (patientId) => async dispatch => {
    dispatch(getPatientDocumentsRequest())
    try {
        const response = await axios.get(`document/patient/${patientId}`);
        dispatch(getPatientDocumentsSuccess(response.data))
    }
    catch (error) {
        dispatch(getPatientDocumentsFail(error))
    }
}

const getPatientDocumentsRequest = () => ({
    type: GET_PATIENT_DOCUMENTS_REQUEST,
});

const getPatientDocumentsSuccess = (documents) => ({
    type: GET_PATIENT_DOCUMENTS_SUCCESS,
    payload: documents
});

const getPatientDocumentsFail = (error) => ({
    type: GET_PATIENT_DOCUMENTS_FAIL,
    payload: error
});

export const addPatientDocument = (document) => async dispatch => {
    dispatch(addPatientDocumentRequest())
    try {
        const response = await axios.post(`document/`, document);
        dispatch(addPatientDocumentSuccess(response.data))
    }
    catch (error) {
        dispatch(addPatientDocumentFail(error))
    }
}

const addPatientDocumentRequest = () => ({
    type: ADD_PATIENT_DOCUMENT_REQUEST,
});

const addPatientDocumentSuccess = (document) => ({
    type: ADD_PATIENT_DOCUMENT_SUCCESS,
    payload: document
});

const addPatientDocumentFail = (error) => ({
    type: ADD_PATIENT_DOCUMENT_FAIL,
    payload: error
});

export const updatePatientDocument = (document, documentId) => async dispatch => {
    dispatch(updatePatientDocumentRequest())
    try {
        const response = await axios.patch(`document/${documentId}`, document);
        dispatch(updatePatientDocumentSuccess(response.data))
    }
    catch (error) {
        dispatch(updatePatientDocumentFail(error))
    }
}

const updatePatientDocumentRequest = () => ({
    type: UPDATE_PATIENT_DOCUMENT_REQUEST,
});

const updatePatientDocumentSuccess = (document) => ({
    type: UPDATE_PATIENT_DOCUMENT_SUCCESS,
    payload: document
});

const updatePatientDocumentFail = (error) => ({
    type: UPDATE_PATIENT_DOCUMENT_FAIL,
    payload: error
});

export const deletePatientDocument = (documentId) => async dispatch => {
    console.log(documentId);
    dispatch(deletePatientDocumentRequest())
    try {
        const response = await axios.delete(`document/${documentId}`);
        dispatch(deletePatientDocumentSuccess(documentId))
    }
    catch (error) {
        dispatch(deletePatientDocumentFail(error))
    }
}

const deletePatientDocumentRequest = () => ({
    type: DELETE_PATIENT_DOCUMENT_REQUEST,
});

const deletePatientDocumentSuccess = (document) => ({
    type: DELETE_PATIENT_DOCUMENT_SUCCESS,
    payload: document
});

const deletePatientDocumentFail = (error) => ({
    type: DELETE_PATIENT_DOCUMENT_FAIL,
    payload: error
});

const initialState = {
    patientDocuments: [],
    loading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_PATIENT_DOCUMENTS_SUCCESS:
            return {
                ...state,
                patientDocuments: action.payload,
                loading: false
            }
        case GET_PATIENT_DOCUMENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ADD_PATIENT_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADD_PATIENT_DOCUMENT_SUCCESS:
            return {
                ...state,
                patientDocuments: [...state.patientDocuments, action.payload],
                loading: false
            }
        case ADD_PATIENT_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_PATIENT_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_PATIENT_DOCUMENT_SUCCESS:
            return {
                ...state,
                patientDocuments: state.patientDocuments.map(el => el._id === action.payload._id ? action.payload : el),
                loading: false
            }
        case UPDATE_PATIENT_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_PATIENT_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DELETE_PATIENT_DOCUMENT_SUCCESS:
            return {
                ...state,
                patientDocuments: state.patientDocuments.filter(el => el._id !== action.payload),
                loading: false
            }
        case DELETE_PATIENT_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};