import React from "react";
import styled from "styled-components";
import moment from "moment";

const CustomEvent = ({ event }) => {
    return (
        <EventLabel>
            <p>{`${event.userName}, Początek: ${moment(event.start).format('DD.MM.YYYY')}, Koniec: ${moment(event.end).format('DD.MM.YYYY')}`}</p>
            <p>{event.state === 'ACCEPTED' ? 'Zaakceptowana' : 'Nie zaakceptowana'}</p>
        </EventLabel>
    );
};

export default CustomEvent;

const EventLabel = styled.div`
    padding:3px 5px;
    display:flex;
    justify-content:space-between;
    p{
      color: #fff;
      font-size: 12px;
      &:first-child{
          padding-right:5px;
          /* max-width: calc(100% - 110px);
          overflow:hidden; */
      }
    }
`;
