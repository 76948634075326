import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../Shared/Modals/Modal';
import FileUpload from '../../Shared/FileUpload';
import Button from '../../Shared/Button';
import { Formik } from 'formik';
import ColorPicker from '../../Shared/ColorPicker';

const PersonalizationModal = ({
    modalShown,
    setModalShown,
    companyData: { _id: companyId, colors, logo = '' },
    updateCompany,
    updateCompanyLogo,
}) => {
    const defaultColors = {
        primary: '#1890FF',
        secondary: '#3E64FF',
        background: '#f2f5f8',
    }
    const checkIfChanged = ({ colors, logo }) => {
        let isChanged = false;
        if (logo) {
            isChanged = true;
        }
        Object.values(colors).forEach((color, index) => {
            if (color !== Object.values(defaultColors)[index]) {
                isChanged = true;
            }
        })
        return isChanged;
    }
    return (
        <Modal
            title='Personalizacja aplikacji'
            exitButton={true}
            onCancel={() => setModalShown(false)}
            show={modalShown}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    colors,
                    logo: logo,
                }}
                onSubmit={({ colors, logo }) => {
                    setModalShown(false);
                    updateCompany(companyId, { colors });
                    updateCompanyLogo(companyId, { logo });
                }}
            >
                {({ handleSubmit, values, setFieldValue }) => (
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <Label>Logo</Label>
                        {values.logo && <CompanyLogo src={values.logo?.preview ? values.logo?.preview : `${process.env.REACT_APP_SRV_URL}${values.logo}` ?? ''}/>}
                        <FileUpload
                            onDrop={(files) => {
                                const filesWithPreview = files.map(file => Object.assign(file, {
                                    preview: URL.createObjectURL(file)
                                }))
                                setFieldValue('logo', filesWithPreview[0]);
                            }}
                            // value={values.logo}
                        />
                        {/* <Label>Kolory</Label> */}
                        {/* <AppColors>
                            <Color>
                                <ColorLabel>Podstawowy</ColorLabel>
                                <ColorPicker
                                    currentColor={values.colors?.primary}
                                    previewWidth='100px'
                                    onChange={({ hex }) => {
                                        setFieldValue('colors.primary', hex);
                                    }}
                                />
                            </Color>
                            <Color>
                                <ColorLabel>Drugorzędny</ColorLabel>
                                <ColorPicker
                                    currentColor={values.colors?.secondary}
                                    previewWidth='100px'
                                    onChange={({ hex }) => {
                                        setFieldValue('colors.secondary', hex);
                                    }}
                                />
                            </Color>
                            <Color>
                                <ColorLabel>Tło</ColorLabel>
                                <ColorPicker
                                    currentColor={values.colors?.background}
                                    previewWidth='100px'
                                    onChange={({ hex }) => {
                                        setFieldValue('colors.background', hex);
                                    }}
                                />
                            </Color>
                        </AppColors> */}
                        <CenterBox>
                            <Button onClick={handleSubmit} type='submit' variant='primary'>Zapisz zmiany</Button>
                            {checkIfChanged(values) && <Button onClick={() => {
                                setFieldValue('colors', defaultColors);
                                setFieldValue('logo', null);
                            }} variant='secondary'>Przywróć domyślne</Button>}
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal >
    )
}

export default PersonalizationModal;

const Form = styled.form`
    width:350px;
    padding-top:10px;
`;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;

const Label = styled.p`
    font-weight: 600;
    font-size:14px;
`;

const AppColors = styled.div`
    display:flex;
    justify-content:space-between;
    padding:10px 0;
`;

const ColorLabel = styled.p`
    font-size:12px;
    margin-bottom:2px;
    text-align:center;
`;

const Color = styled.div``;

const CompanyLogo = styled.img`
    max-width: 300px;
    max-height: 300px;
    width: auto;
    height: auto;
    margin: 10px auto;
    display: block;
`;