import { combineReducers } from 'redux'
import authentication from './modules/authentication'
import employees from './modules/employees'
import healthcenter from './modules/healthcenter'
import patients from './modules/patients'
import company from './modules/company'
import visits from './modules/visits'
import calendar from './modules/calendar'
import events from './modules/events'
import examinations from './modules/examinations'
import documents from './modules/documents'
import medsdiagnosis from './modules/medsdiagnosis'
import riskfactors from './modules/riskfactors'
import hospitalizations from './modules/hospitalizations'
import medicalRest from './modules/medicalRest'
import surveys from './modules/surveys'
import medicalVisitPattern from './modules/medicalVisitPattern'
import application from './modules/application'
import medicalVisit from './modules/medicalVisit'
import vacations from './modules/vacations'
import chat from './modules/chat'
import schedule from './modules/schedule'
import patternFormControl from './modules/patternFormControl';
import visitElement from './modules/visitElement';
import visitElementsSet from './modules/visitElementsSet';
import navigation from './modules/navigation';
import tutorial from './modules/tutorial';
import errorReport from './modules/errorReport';

export default combineReducers({
  authentication,
  employees,
  company,
  healthcenter,
  patients,
  visits,
  calendar,
  events,
  examinations,
  documents,
  medsdiagnosis,
  riskfactors,
  hospitalizations,
  medicalRest,
  surveys,
  medicalVisitPattern,
  application,
  medicalVisit,
  vacations,
  chat,
  schedule,
  patternFormControl,
  visitElement,
  visitElementsSet,
  navigation,
  tutorial,
  errorReport,
});