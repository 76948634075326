export const ADD_MESSAGE_FROM_SOCKET = "ADD_MESSAGE_FROM_SOCKET"
export const ADD_UNREAD_MESSAGE = "ADD_UNREAD_MESSAGE"

export const addMessageFromSocket = message => ({
    type: ADD_MESSAGE_FROM_SOCKET,
    payload: message
})

export const addUnreadMessage = channelId => ({
    type: ADD_UNREAD_MESSAGE,
    payload: channelId
})