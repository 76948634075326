import axios from "axios";

const GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_REQUEST = "GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_REQUEST";
const GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_SUCCESS = "GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_SUCCESS";
const GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_FAIL = "GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_FAIL";

const ADD_ALL_DAY_EVENT_REQUEST = "ADD_ALL_DAY_EVENT_REQUEST";
const ADD_ALL_DAY_EVENT_SUCCESS = "ADD_ALL_DAY_EVENT_SUCCESS";
const ADD_ALL_DAY_EVENT_FAIL = "ADD_ALL_DAY_EVENT_FAIL";

const UPDATE_ALL_DAY_EVENT_REQUEST = "UPDATE_ALL_DAY_EVENT_REQUEST";
const UPDATE_ALL_DAY_EVENT_SUCCESS = "UPDATE_ALL_DAY_EVENT_SUCCESS";
const UPDATE_ALL_DAY_EVENT_FAIL = "UPDATE_ALL_DAY_EVENT_FAIL";

const DELETE_ALL_DAY_EVENT_REQUEST = "DELETE_ALL_DAY_EVENT_REQUEST";
const DELETE_ALL_DAY_EVENT_SUCCESS = "DELETE_ALL_DAY_EVENT_SUCCESS";
const DELETE_ALL_DAY_EVENT_FAIL = "DELETE_ALL_DAY_EVENT_FAIL";

export const getAllDayEventsByHealthcenterId = (healthcenterId, start, end) => async dispatch => {
  dispatch(getAllDayEventsByHealthcenterIdRequest());
  try {
    const response = await axios.post(`/alldayevent/healthcenter`, {
      healthcenterId: healthcenterId,
      dateRangeBegin: start,
      dateRangeEnd: end
    });
    const { data } = response;
    dispatch(getAllDayEventsByHealthcenterIdSuccess(data));
  } catch (error) {
    dispatch(getAllDayEventsByHealthcenterIdFail(error));
  }
};

const getAllDayEventsByHealthcenterIdRequest = () => ({
  type: GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_REQUEST
});

const getAllDayEventsByHealthcenterIdSuccess = events => ({
  type: GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_SUCCESS,
  payload: events
});

const getAllDayEventsByHealthcenterIdFail = error => ({
  type: GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_FAIL,
  payload: error
});

export const addAllDayEvent = body => async dispatch => {
  dispatch(addAllDayEventRequest());
  try {
    const response = await axios.post(`/alldayevent`, body);
    const { data } = response;
    dispatch(addAllDayEventSuccess(data));
  } catch (error) {
    dispatch(addAllDayEventFail(error));
  }
};

const addAllDayEventRequest = () => ({
  type: ADD_ALL_DAY_EVENT_REQUEST
});

const addAllDayEventSuccess = event => ({
  type: ADD_ALL_DAY_EVENT_SUCCESS,
  payload: event
});

const addAllDayEventFail = error => ({
  type: ADD_ALL_DAY_EVENT_FAIL,
  payload: error
});

export const updateAllDayEventById = (allDayEventId, body) => async dispatch => {
  dispatch(updateAllDayEventByIdRequest());
  try {
    const response = await axios.patch(`/alldayevent/${allDayEventId}`, body);
    const { data } = response;
    dispatch(updateAllDayEventByIdSuccess(data));
  } catch (error) {
    dispatch(updateAllDayEventByIdFail(error));
  }
};

const updateAllDayEventByIdRequest = () => ({
  type: UPDATE_ALL_DAY_EVENT_REQUEST
});

const updateAllDayEventByIdSuccess = event => ({
  type: UPDATE_ALL_DAY_EVENT_SUCCESS,
  payload: event
});

const updateAllDayEventByIdFail = error => ({
  type: UPDATE_ALL_DAY_EVENT_FAIL,
  payload: error
});

export const deleteAllDayEventById = (allDayEventId) => async dispatch => {
  dispatch(deleteAllDayEventByIdRequest());
  try {
    await axios.delete(`/alldayevent/${allDayEventId}`);
    dispatch(deleteAllDayEventByIdSuccess(allDayEventId));
  } catch (error) {
    dispatch(deleteAllDayEventByIdFail(error));
  }
};

const deleteAllDayEventByIdRequest = () => ({
  type: DELETE_ALL_DAY_EVENT_REQUEST
});

const deleteAllDayEventByIdSuccess = eventId => ({
  type: DELETE_ALL_DAY_EVENT_SUCCESS,
  payload: eventId
});

const deleteAllDayEventByIdFail = error => ({
  type: DELETE_ALL_DAY_EVENT_FAIL,
  payload: error
});

const initialState = {
  loading: false,
  data: [],
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_ALL_DAY_EVENTS_BY_HEALTHCENTER_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ADD_ALL_DAY_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_ALL_DAY_EVENT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      };
    case ADD_ALL_DAY_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_ALL_DAY_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_ALL_DAY_EVENT_SUCCESS:
      return {
        ...state,
        data: state.data.map(event => event._id === action.payload._id ? action.payload : event),
        loading: false
      };
    case UPDATE_ALL_DAY_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_ALL_DAY_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_ALL_DAY_EVENT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(({ _id }) => _id !== action.payload),
        loading: false
      };
    case DELETE_ALL_DAY_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
