import React from "react";
import styled from "styled-components";
// import BellImg from "../Shared/assets/notification-bell.svg";
import EnvelopeImg from "../Shared/assets/envelope.svg";
import ArrowImg from "../Shared/assets/arrow-down.svg";
import { theme } from "../Shared/theme";
import { Link } from "react-router-dom";
import OutsideAlerter from "../Shared/OutsideAlerter";
import ChatContainer from './Chat/ChatContainer';
import AvatarImg from "../Shared/assets/avatar.svg";
import moment from "moment";
import Modal from '../Shared/Modals/Modal';
import { userRolesDisc } from '../Shared/enums';
import { Turn as Hamburger } from 'hamburger-react';

const NavigationView = ({
  isProfileModalOpen,
  setIsProfileModalOpen,
  isNotificationOpen,
  setNotificationOpen,
  user,
  activeTab,
  setActiveTab,
  isAuthenticated,
  unreadMessages,
  activeVisits,
  setActiveVisitsOpen,
  activeVisitsOpen,
  navBlock,
  warningModal,
  setWarningModal,
  setNavBlock,
  sidebarActive,
  setSidebarActive,
  isHealthcenterErrorOn,
  healthcenterErrorHandler,
  pathname,
  history,
  companyLogo,
}) => {
  return (
    isAuthenticated && <>
      <TopPlaceholder />
      <NavWrapper>
        <Modal
          type="SimpleQuestionModal"
          show={warningModal.shown}
          questionTitle='Niezapisane zmiany!'
          question='Opuszczenie tej strony może spowodować utratę niezapisanych zmian, czy na pewno chcesz kontynuować?'
          acceptText="Tak"
          cancelText="Nie"
          onAccept={() => { history.push(warningModal.link); setWarningModal({ shown: false, link: null }); setNavBlock(false); }}
          onCancel={() => setWarningModal({ shown: false, link: null })}
        />
        <Modal
          type="SimpleInfoModal"
          show={isHealthcenterErrorOn}
          info="Brak przypisanej placówki, poproś o przypisanie przez admina."
          acceptText="Wyloguj"
          onAccept={() => healthcenterErrorHandler()}
        />
        {(pathname !== '/profile' && pathname !== '/elements') && <Hamburger
          toggled={sidebarActive}
          toggle={setSidebarActive}
          color={theme.lightBlack}
          size={24}
        />}
        <NavContent>
          <span>
            {companyLogo && <Logo>
              <LogoImg src={`${process.env.REACT_APP_SRV_URL}${companyLogo}`} />
            </Logo>}
            <ContentContainer id='menu'>
              {(user.activeRole === "Doctor" || user.activeRole === "Nurse") ? <>
                <StyledLink
                  to={loc => !navBlock && { ...loc, pathname: "/" }}
                  active={activeTab === 0 ? 1 : 0}
                  onClick={() => (navBlock && activeTab !== 0) ? setWarningModal({ shown: true, link: "/" }) : setActiveTab(0)}
                >Panel</StyledLink>
                <StyledLink
                  to={loc => !navBlock && { ...loc, pathname: "/calendar" }}
                  active={activeTab === 1 ? 1 : 0}
                  onClick={() => (navBlock && activeTab !== 1) ? setWarningModal({ shown: true, link: "/calendar" }) : setActiveTab(1)}
                >Kalendarz</StyledLink>
                <StyledLink
                  to={loc => !navBlock && { ...loc, pathname: "/patients" }}
                  active={activeTab === 2 ? 1 : 0}
                  onClick={() => (navBlock && activeTab !== 2) ? setWarningModal({ shown: true, link: "/patients" }) : setActiveTab(2)}
                >Pacjenci</StyledLink>
                {activeVisits?.length === 1 ? <StyledLink
                  to={loc => !navBlock && { ...loc, pathname: `/visit/${activeVisits[0]._id}/` }}
                  active={activeTab === 3 ? 1 : 0}
                  onClick={() => (navBlock && activeTab !== 3) ? setWarningModal({ shown: true, link: `/visit/${activeVisits[0]._id}/` }) : setActiveTab(3)}
                >Aktywna wizyta</StyledLink>
                  :
                  activeVisits?.length > 1 ?
                    <OutsideAlerter
                      setModalAlerterOpen={setActiveVisitsOpen}
                      isModalAlerterOpen={activeVisitsOpen}
                    >
                      <ProfileModalLink
                        onClick={() => setActiveVisitsOpen(!activeVisitsOpen)}
                      >
                        <ActiveVisitsText active={activeTab === 3 ? 1 : 0}>Aktywne wizyty</ActiveVisitsText>
                        {activeVisits?.length > 0 && <ActiveVisitsIndicator><ActiveVisitsCount>{activeVisits?.length > 9 ? `9+` : activeVisits?.length}</ActiveVisitsCount></ActiveVisitsIndicator>}
                        {activeVisitsOpen && (
                          <ActiveVisitsModal>
                            {activeVisits?.map(activeVisit =>
                              <InnerStyledLink
                                to={loc => !navBlock && { ...loc, pathname: `/visit/${activeVisit._id}/` }}
                                onClick={() => (navBlock && activeTab !== 3) ? setWarningModal({ shown: true, link: `/visit/${activeVisit._id}/` }) : setActiveTab(3)}
                              >{activeVisit.patient.name} {activeVisit.patient?.surname} trwa {Math.round(moment.duration(moment(new Date()).diff(moment(activeVisit.timeLogs.find(timeLog => timeLog.type === "STARTED")?.time))).asMinutes())}min</InnerStyledLink>)}
                          </ActiveVisitsModal>
                        )}
                      </ProfileModalLink>
                    </OutsideAlerter> : null}
              </> :
                user.activeRole === "Registry" ? <>
                  <StyledLink
                    to={loc => !navBlock && { ...loc, pathname: "/" }}
                    active={activeTab === 0 ? 1 : 0}
                    onClick={() => (navBlock && activeTab !== 0) ? setWarningModal({ shown: true, link: "/" }) : setActiveTab(0)}
                  >Kalendarz</StyledLink>
                  <StyledLink
                    to={loc => !navBlock && { ...loc, pathname: "/patients" }}
                    active={activeTab === 2 ? 1 : 0}
                    onClick={() => (navBlock && activeTab !== 2) ? setWarningModal({ shown: true, link: "/patients" }) : setActiveTab(2)}
                  >Pacjenci</StyledLink></> : null}
            </ContentContainer>
          </span>
          {isAuthenticated && <ContentContainer>
            <OutsideAlerter
              setModalAlerterOpen={setNotificationOpen}
              isModalAlerterOpen={isNotificationOpen}
            >
              {/* <NotificationBell src={BellImg} onClick={() => setNotificationOpen(!isNotificationOpen)} /> */}
              <RelativeContainer>
                <NotificationEnvelope title='Wiadomości' newMessage={true} src={EnvelopeImg} onClick={() => setNotificationOpen(!isNotificationOpen)} />
                {Object.values(unreadMessages).findIndex(value => value > 0) > -1 && <UnreadMessagesIndicator></UnreadMessagesIndicator>}

                <NotificationModal isOpen={isNotificationOpen}>
                  <ChatContainer isOpen={isNotificationOpen} />
                </NotificationModal>
              </RelativeContainer>
            </OutsideAlerter>

            <UserContainer>
              <UserImage src={!user.avatar ? AvatarImg : `${process.env.REACT_APP_SRV_URL}${user.avatar}`} />
              <UserInfoContainer>
                <UserName>{user.name} {user?.surname}</UserName>
                <UserRole>{userRolesDisc[user.activeRole]}</UserRole>
              </UserInfoContainer>

              <OutsideAlerter
                setModalAlerterOpen={setIsProfileModalOpen}
                isModalAlerterOpen={isProfileModalOpen}
              >
                <ProfileModalLink
                  id='menuDropdown'
                  onClick={() => setIsProfileModalOpen(!isProfileModalOpen)}
                >
                  <PopupArrow src={ArrowImg} isProfileModalOpen={isProfileModalOpen} />
                  {isProfileModalOpen && (
                    <ProfileModal>
                      <InnerStyledLink
                        to={loc => !navBlock && { ...loc, pathname: "/profile" }}
                        onClick={() => navBlock ? setWarningModal({ shown: true, link: "/profile" }) : setActiveTab(null)}
                      >Profil</InnerStyledLink>
                      {(user.activeRole === "Doctor" || user.activeRole === "Nurse") &&
                        < InnerStyledLink
                          to={loc => !navBlock && { ...loc, pathname: "/elements" }}
                          onClick={() => navBlock ? setWarningModal({ shown: true, link: "/elements" }) : setActiveTab(null)}
                        >Elementy wizyt</InnerStyledLink>}
                      {/* <InnerStyledLink
                        to={loc => !navBlock && { ...loc, pathname: "/help" }}
                        onClick={() => navBlock ? setWarningModal({ shown: true, link: "/help" }) : setActiveTab(null)}
                      >Pomoc</InnerStyledLink> */}
                      <InnerStyledLink
                        to={loc => !navBlock && { ...loc, pathname: "/report-error" }}
                        onClick={() => navBlock ? setWarningModal({ shown: true, link: "/report-error" }) : setActiveTab(null)}
                      >Zgłoś błąd</InnerStyledLink>
                      <InnerStyledLink
                        to={loc => !navBlock && { ...loc, pathname: "/logout" }}
                        onClick={() => navBlock ? setWarningModal({ shown: true, link: "/logout" }) : setActiveTab(null)}
                      >Wyloguj</InnerStyledLink>
                    </ProfileModal>
                  )}
                </ProfileModalLink>
              </OutsideAlerter>
            </UserContainer>
          </ContentContainer>}
        </NavContent>
      </NavWrapper >
    </>
  );
};

export default NavigationView;

const ActiveVisitsCount = styled.span`
 font-size: 10px;
 color: #fff;
 font-weight: bold;
`

const ActiveVisitsIndicator = styled.div`
width: 15px;
height: 15px;
border-radius: 50%;
background-color: #C75454;
position: absolute;
top: -4px;
left:110px;
display: flex;
justify-content: center;
align-items: center;
`


const ActiveVisitsText = styled.span`
color: ${({ theme }) => theme.darkGrey};
font-size: 15px;
font-weight: 600;
    &:hover {
    color: ${({ theme }) => theme.primary};
  }
${({ active, theme }) => active && `
  text-shadow: 0 0 .65px ${theme.black}, 0 0 .65px ${theme.black};
  color: ${theme.black};
  :before {
    content: '';
    width: 35px;
    height: 2px;
    background-color: ${theme.primary};
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  `}

`

const RelativeContainer = styled.div`
  position: relative;
`

const UnreadMessagesIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #C75454;
  position: absolute;
  top: -2px;
  left: 31px;
`

const NavContent = styled.div`
  max-width: 1140px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  >span{
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
`;

const InnerStyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.black};
  text-align: center;
  padding: 0.5rem 0.25rem;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.family.openSans};
  transition: 0.25s;
  &:hover {
    background-color: #f8f9fa;
    font-weight: 600;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserImage = styled.img`
  width: 35px;
  height: 35px;
  margin: 0 5px 0 15px;
  border-radius: 50%;
`;
const UserName = styled.p`
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.font.family.openSans};
  font-weight: 600;
  line-height: 1.2;
`;
const UserRole = styled.p`
color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.font.family.openSans};
  font-weight: 500;
  line-height: 1.2;
  font-size: 14px;
`;

const PopupArrow = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  filter: invert(29%) sepia(8%) saturate(0%) hue-rotate(196deg) brightness(89%) contrast(90%);
  transition: 0.2s;
  ${({ isProfileModalOpen }) => isProfileModalOpen && `
  transform: rotate(180deg);
  `}
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grey};
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  background: #fff;
  position:fixed;
  top:0;
  .hamburger-react{
    margin-left:10px;
  }
  ${theme.mq.desktop}{
    position: relative;
    .hamburger-react{
      display:none;
    }
  }
`;

// const NotificationBell = styled.img`
//   width: 40px;
//   height: 30px;
//   border-left: 1px solid ${({ theme }) => theme.grey};
//   border-right: 1px solid ${({ theme }) => theme.grey};
//   box-sizing: border-box;
//   padding: 4px;
//   cursor: pointer;
// `;

const NotificationEnvelope = styled.div`
  width: 50px;
  height: 22px;
  border-left: 1px solid ${({ theme }) => theme.grey};
  border-right: 1px solid ${({ theme }) => theme.grey};
  box-sizing: border-box;
  padding: 4px;
  cursor: pointer;
  position:relative;
  background-image:url(${({ src }) => src});
  background-position:center;
  background-repeat:no-repeat;
  /* &::before{
    content:'';
    display:block;
    border-radius:50%;
    width:7px;
    height:7px;
    background-color: ${({ theme }) => theme.secondary};
    position:absolute;
    top:1px;
    right:8px;
    opacity:1;
    ${({ newMessage }) => !newMessage && `opacity:0;`}
  } */
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.darkGrey};
  margin: 0 12px;
  font-family: ${({ theme }) => theme.font.family.openSans};
  transition: 0.1s;
  position: relative;
  font-size: 15px;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  ${({ active, theme }) => active && `
  text-shadow: 0 0 .65px ${theme.black}, 0 0 .65px ${theme.black};
  color: ${theme.black};
  :before {
    content: '';
    width: 35px;
    height: 2px;
    background-color: ${theme.primary};
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  `}
`;

const ProfileModal = styled.div`
  position: absolute;
  z-index: 9;
  top: 30px;
  right: -10px;
  width: 150px;
  background-color: #fff;
  color: #343536;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 16px;
`;

const ActiveVisitsModal = styled.div`
  position: absolute;
  z-index: 9;
  top: 25px;
  width: 250px;
  background-color: #fff;
  color: #343536;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: rgba(0,0,0,0.12) 0px 2px 16px;
`;

const ProfileModalLink = styled.div`
  position: relative;
  color: ${({ theme }) => theme.grey};
  margin: 0 15px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`;

const TopPlaceholder = styled.div`
  width:100%;
  height:60px;
  display:block;
  ${theme.mq.desktop}{
    display:none;
  }
`;

const NotificationModal = styled.div`
  position: absolute;
  right:-100px;
  top: 35px;
  width: 350px;
  background-color: #fff;
  color: #343536;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 16px;
  transition: .3s;
  ${({ isOpen }) => isOpen ? `
    display:flex;
  ` : `
    display:none;
  `}
`;

const Logo = styled.div`
  width: 100px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `${theme.mq.desktop}{
    height: 45px;
  }`}
`

const LogoImg = styled.img` 
  max-width:100%;
  max-height:100%;
`