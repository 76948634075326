import React from 'react';
import styled from 'styled-components';
import Label from "./Label";

const Textarea = ({
    value,
    name,
    placeholder,
    onChange,
    label,
    variant,
    error,
    disabled,
    hideError,
    onFocus,
    onBlur,
    className
}) => {
    return (
        <TextareaWrapper className={className}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <StyledTextarea
                disabled={disabled}
                name={name}
                id={name}
                value={value}
                variant={error ? "danger" : variant ? variant : "default"}
                placeholder={placeholder}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {!hideError && <ErrorInfo error={error}>{error}</ErrorInfo>}
        </TextareaWrapper>
    )
}

export default Textarea;

const TextareaWrapper = styled.div`
    position:relative;
    display: flex;
    flex-direction: column;
`;

const StyledTextarea = styled.textarea`
    width:100%;
    max-width:100%;
    border-radius: 4px;
    resize: none;
    min-height: 100px;
    padding: 5px 10px;
    margin: 2px 0 2px 0;
    outline: none;
    &:focus {
      border-color: ${({ theme }) => theme.primary};
      border-width: 2px;
      padding-left: 9px;
      padding-top: 4px;
    }
    ${({ variant, theme }) =>
        variant === "default"
            ? `
      border: 1px solid rgb(204, 204, 204);
`
            : variant === "primary"
                ? `
    border: 1px solid ${theme.primary};
`
                : variant === "danger"
                    ? `
    border: 1px solid ${theme.danger};
`
                    : variant === "dark"
                        ? `
    border: 1px solid ${theme.darkGrey};
`
                        : ``}
    ${({ disabled }) => disabled &&
        `
            background-color:#f7f7f7;
            opacity: .8;
            cursor: not-allowed;
        `
    }
`;

const ErrorInfo = styled.p`
 font-size: 10px;
  color: ${({ theme }) => theme.danger};
      text-align: right;
      margin-bottom: 5px;
      visibility: hidden;
  ${({ error }) =>
        error
            ? `
visibility: visible;`
            : `
height: 13px;
`}
      `;