export const theme = {
    primary: '#1890FF',
    lightPrimary: '#f0f5ff',
    secondary: '#3E64FF',
    danger: '#C75454',
    lightDanger: '#ffc4c4',
    green: '#57C754',
    lightGreen: '#F0FFF3',
    black: '#484848',
    lightBlack: '#595959',
    lightBlack2: '#7A7A7A',
    grey: '#f0f0f0',
    darkGrey: "#aeaeae",
    yellow: "#FFCC18",
    lightYellow: "#FFFDF0",
    background: '#f2f5f8',
    font: {
        family: {
            roboto: '"Roboto", sans-serif',
            openSans: '"Open Sans", sans-serif'
        },
        size: {
            xxxs: '.9rem',
            xxs: '1.2rem',
            xs: '1.4rem',
            smin:'1.9rem',
            s: '2.6rem',
            smax: '3.5rem',
            m: '4.2rem',
            l: '5rem',
            xl: '7.2rem',
            xxl: '9.4rem',
            spec: '1.9rem'
          }
    },
    mq: {
        tablet: `@media (min-width: 768px)`,
        desktop: `@media (min-width: 1024px)`,
        medium: `@media (min-width: 1150px)`,
        large: `@media (min-width: 1280px)`,
        huge: `@media(min-width: 1440px)`,
      }
}