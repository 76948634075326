import React, { useEffect } from 'react';
import styled from 'styled-components';
import Input from '../../Shared/Input';
import Select from '../../Shared/Select';
import DatePicker from '../../Shared/DatePicker';
import TimePicker from '../../Shared/TimePicker';
import moment from 'moment';

const FormControl = ({
    data: { name: controlName, formType, selectOptions, checkboxOptions, unit },
    controlIndex,
    active,
    setCurrentControl,
    setCurrentSelectType,
}) => {
    const renderControl = () => {
        switch (formType) {
            case 'select':
                return (
                    <StyledSelect
                        key={`${controlName}_${controlIndex}`}
                        label={controlName}
                        name={`${controlName}_${controlIndex}`}
                        options={selectOptions.map(({ name, ...el }) => ({ value: name, label: name, ...el }))}
                    />
                )
            case 'input':
                return (
                    <StyledInput
                        key={`${controlName}_${controlIndex}`}
                        label={`${controlName} ${unit !== '' ? `(${unit})` : ''}`}
                        name={`${controlName}_${controlIndex} `}
                    />
                )
            case 'checkbox':
                return (
                    <StyledInput
                        type='checkbox'
                        key={`${controlName}_${controlIndex} `}
                        label={checkboxOptions.options.length > 0 && controlName}
                        checkboxText={controlName}
                        wrapperHeight='100px'
                        name={`${controlName}_${controlIndex} `}
                        checkboxOptions={checkboxOptions.options.map(({ name, ...el }) => ({ value: name, label: name, ...el }))}
                        onChange={() => { return }}
                    />
                )
            case 'radio':
                return (
                    <StyledInput
                        type='radio'
                        key={`${controlName}_${controlIndex} `}
                        label={controlName}
                        wrapperHeight='100px'
                        name={`${controlName}_${controlIndex} `}
                        radioOptions={selectOptions.map(({ name, ...el }) => ({ value: name, label: name, ...el }))}
                        onChange={() => false}
                    />
                )
            case 'datepicker':
                return (
                    <StyledDatePicker
                        key={`${controlName}_${controlIndex} `}
                        label={controlName}
                        dateFormat='dd.MM.yyyy'
                        selected={Date.now()}
                    />
                )
            case 'timepicker':
                return (
                    <StyledTimePicker
                        key={`${controlName}_${controlIndex} `}
                        label={controlName}
                        allowEmpty={false}
                        defaultValue={moment(Date.now())}
                        showSecond={false}
                    />
                )
            default:
                return <p>Wrong formType</p>
        }
    }

    return (
        <Wrapper
            onClick={(e) => {
                e.stopPropagation();
                setCurrentControl(prev => ({ ...prev, i: controlIndex }));
                setCurrentSelectType('control');
            }}
            active={active}
            activeText={
                formType === 'select' ? 'Wybór z listy' :
                    formType === 'radio' ? 'Pojedyńczy wybór' :
                        formType === 'checkbox' ? 'Wielokrotny wybór' :
                            formType === 'input' ? 'Pole numeryczne' :
                                formType === 'datepicker' ? 'Data' :
                                    formType === 'timepicker' ? 'Czas' : ''
            }
        >
            {renderControl()}
        </Wrapper >
    );
}

export default FormControl;

const Wrapper = styled.div`
                max-width: 270px;
                padding: 5px 5px 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
                border: 2px solid transparent;
                position: relative;
                cursor: pointer;
                transition: 300ms;
                >div{
                    height:auto;
                }
                label{
                    pointer-events:none;
                }
                &:hover{
                    border-color:#5eb1ff;
                }
                ${
    ({ active, activeText, theme }) => active && `
            border-color: ${theme.primary};  
            &::before{
                content:'${activeText}';
                position:absolute;
                top: -20px;
                left: -2px;
                padding:3px 5px;
                font-size:9px;
                background: ${theme.primary};
                color:#fff;
            }
        `}
                `;

const Inputs = styled.div`
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                padding: 0 20px;
                pointer-events: none;
`;

const StyledSelect = styled(Select)`
                pointer-events: none;
                label{
                    display: block;
                    margin-bottom: 2px;
                }
                opacity: 1!important;
                `;

const StyledInput = styled(Input)`
                pointer-events: none;
                ${
    ({ disabled, type }) => (disabled && type !== 'radio') && `
            opacity:1!important;
            `}
                `;

const StyledDatePicker = styled(DatePicker)`
                pointer-events: none;
                input{
                    ${ ({ disabled }) => disabled && `opacity:1!important;`}
                }
                `;

const StyledTimePicker = styled(TimePicker)`
                pointer-events: none;
`;