import React from 'react';
import styled from 'styled-components';
import useDebouncer from './Hooks/useDebouncer';

const InfiniteScrollList = ({
    onScrollBottom,
    bottomOffset = 0,
    maxHeight,
    children
}) => {
    const [setDebounce] = useDebouncer();
    const scrollHandler = (e) => {
        if ((e.target.scrollTop + e.target.offsetHeight) >= (e.target.scrollHeight - bottomOffset)) {
            setDebounce(() => {
                onScrollBottom();
            }, 100)
        }
    }
    return (
        <List maxHeight={maxHeight} onScroll={scrollHandler}>
            {children}
        </List>
    )
}

export default InfiniteScrollList;

const List = styled.div`
  margin: 0;
  max-height: ${({ maxHeight }) => maxHeight ? maxHeight : '100vh'};
  overflow-y: auto;
  overflow-x: hidden;
`;
