import axios from "axios";
//ACTION TYPES---------------------------------
const AUTHENTICATE_USER_REQUEST = "AUTHENTICATE_USER_REQUEST";
const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
const AUTHENTICATE_USER_FAIL = "AUTHENTICATE_USER_FAIL";

const TOKEN_AUTH_FAILED = "TOKEN_AUTH_FAILED";
const TOKEN_AUTH_REQUEST = "TOKEN_AUTH_REQUEST";
const TOKEN_AUTH_SUCCESS = "TOKEN_AUTH_SUCCESS";

const LOGOUT_USER = "LOGOUT_USER";
const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
const USER_ROLE_FAIL = "USER_ROLE_FAIL";

const UPDATE_AUTHENTICATED_USER_FAILED = "UPDATE_AUTHENTICATED_USER_FAILED";
const UPDATE_AUTHENTICATED_USER_REQUEST = "UPDATE_AUTHENTICATED_USER_REQUEST";
const UPDATE_AUTHENTICATED_USER_SUCCESS = "UPDATE_AUTHENTICATED_USER_SUCCESS";

const UPDATE_LAST_READED_MESSAGES_FAILED = "UPDATE_LAST_READED_MESSAGES_FAILED";
const UPDATE_LAST_READED_MESSAGES_REQUEST = "UPDATE_LAST_READED_MESSAGES_REQUEST";
const UPDATE_LAST_READED_MESSAGES_SUCCESS = "UPDATE_LAST_READED_MESSAGES_SUCCESS";

const UPDATE_AUTHENTICATED_REGISTRY_FAILED = "UPDATE_AUTHENTICATED_REGISTRY_FAILED";
const UPDATE_AUTHENTICATED_REGISTRY_REQUEST = "UPDATE_AUTHENTICATED_REGISTRY_REQUEST";
const UPDATE_AUTHENTICATED_REGISTRY_SUCCESS = "UPDATE_AUTHENTICATED_REGISTRY_SUCCESS";

const UPDATE_AUTHENTICATED_USER_ROLE_FAIL = "UPDATE_AUTHENTICATED_USER_ROLE_FAIL";
const UPDATE_AUTHENTICATED_USER_ROLE_REQUEST = "UPDATE_AUTHENTICATED_USER_ROLE_REQUEST";
const UPDATE_AUTHENTICATED_USER_ROLE_SUCCESS = "UPDATE_AUTHENTICATED_USER_ROLE_SUCCESS";

const SET_USER_AVATAR_REQUEST = "SET_USER_AVATAR_REQUEST";
const SET_USER_AVATAR_SUCCESS = "SET_USER_AVATAR_SUCCESS";
const SET_USER_AVATAR_FAIL = "SET_USER_AVATAR_FAIL";

//ACTIONS-------------------------------------

export const setUserAvatar = (userId, formData) => async dispatch => {
  dispatch(setUserAvatarRequest())
  try {
    const response = await axios.post(`/user/${userId}/avatar`, formData);
    dispatch(setUserAvatarSuccess(response.data))
  } catch (error) {
    dispatch(setUserAvatarFail(error))
  }
}

const setUserAvatarRequest = () => ({
  type: SET_USER_AVATAR_REQUEST
});

const setUserAvatarSuccess = user => ({
  type: SET_USER_AVATAR_SUCCESS,
  payload: user
});

const setUserAvatarFail = (error) => ({
  type: SET_USER_AVATAR_FAIL,
  payload: error
});


export const logoutUser = () => ({
  type: LOGOUT_USER
});

export const checkToken = token => async dispatch => {
  dispatch(tokenAuthRequest());
  if (!token || token === "") {
    //if there is no token, dont bother
    dispatch(tokenAuthFailed());
  }
  try {
    const userResponse = await axios.get("/user/token", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch(tokenAuthSuccess(userResponse.data));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //console.log(userResponse.data.activeRole.toLowerCase(), userResponse.data._id)
    dispatch(getUserRole(userResponse.data.activeRole.toLowerCase(), userResponse.data._id))

  } catch (error) {
    dispatch(tokenAuthFailed(error));
  }
};

export const getUserRole = (roleType, userId) => async dispatch => {
  try {
    const roleResponse = await axios.get(`/${roleType}/user/${userId}`);
    console.log("Tutaj lecą takie dane user roli : ", roleResponse)
    dispatch(getUserRoleSuccess(roleResponse.data))
  } catch (error) {
    console.log(error)
  }
}

const getUserRoleSuccess = (userRoleData) => ({
  type: USER_ROLE_SUCCESS,
  payload: userRoleData
})

const getUserRoleFail = (error) => ({
  type: USER_ROLE_FAIL,
  payload: error
})

const tokenAuthFailed = (error) => ({
  type: TOKEN_AUTH_FAILED,
  payload: error
});

const tokenAuthRequest = () => ({
  type: TOKEN_AUTH_REQUEST
});

const tokenAuthSuccess = user => ({
  type: TOKEN_AUTH_SUCCESS,
  payload: user
});

export const authenticateUser = user => async dispatch => {
  dispatch(requestAuthentication());
  try {
    const response = await axios.post(
      "/user/login",
      user
    );
    dispatch(authenticatedSuccess(response.data.user));
    sessionStorage.setItem("jwtToken", response.data.token);
    // Init axios default config

    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

    dispatch(getUserRole(response.data.user.activeRole.toLowerCase(), response.data.user._id))
  } catch (error) {
    dispatch(authenticationFail(error));
    throw error.response?.data?.message;
  }
};

const requestAuthentication = () => ({
  type: AUTHENTICATE_USER_REQUEST
});

export const authenticatedSuccess = user => ({
  type: AUTHENTICATE_USER_SUCCESS,
  payload: user
});

const authenticationFail = error => ({
  type: AUTHENTICATE_USER_FAIL,
  payload: error
});

export const updateAuthenticatedUser = (userData, id) => async dispatch => {
  dispatch(updateAuthenticatedUserRequest());
  try {
    const response = await axios.patch(`/user/${id}`, userData);
    const { data } = response;
    //console.log("Tutaj lecą takie dane do update user roli : ",  response)
    dispatch(updateAuthenticatedUserSuccess(data))
  } catch (error) {
    console.log(error)
    dispatch(updateAuthenticatedUserFail(error?.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const updateAuthenticatedUserRequest = () => ({
  type: UPDATE_AUTHENTICATED_USER_REQUEST
});

const updateAuthenticatedUserSuccess = user => ({
  type: UPDATE_AUTHENTICATED_USER_SUCCESS,
  payload: user
});

const updateAuthenticatedUserFail = error => ({
  type: UPDATE_AUTHENTICATED_USER_FAILED,
  payload: error
});

export const updateAuthenticatedUserRole = (UserRoleyData, id, roleName) => async dispatch => {
  dispatch(updateAuthenticatedUserRoleRequest());
  try {
    const response = await axios.patch(`/${roleName}/${id}`, UserRoleyData);
    const { data } = response;
    dispatch(updateAuthenticatedUserRoleSuccess(data))
  } catch (error) {
    console.log(error);
    dispatch(updateAuthenticatedUserRoleFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const updateAuthenticatedUserRoleRequest = () => ({
  type: UPDATE_AUTHENTICATED_USER_ROLE_REQUEST
});

const updateAuthenticatedUserRoleSuccess = userRole => ({
  type: UPDATE_AUTHENTICATED_USER_ROLE_SUCCESS,
  payload: userRole
});

const updateAuthenticatedUserRoleFail = error => ({
  type: UPDATE_AUTHENTICATED_USER_ROLE_FAIL,
  payload: error
});

export const updateLastReadedMessages = (userId, body) => async dispatch => {
  dispatch(updateLastReadedMessagesRequest());
  try {
    const response = await axios.patch(`/user/${userId}/lastReadedMessages`, body);
    const { lastReadedMessages } = response;
    dispatch(updateLastReadedMessagesSuccess(lastReadedMessages))
  } catch (error) {
    console.log(error)
    dispatch(updateLastReadedMessagesFail(error?.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const updateLastReadedMessagesRequest = () => ({
  type: UPDATE_LAST_READED_MESSAGES_REQUEST
});

const updateLastReadedMessagesSuccess = lastReadedMessages => ({
  type: UPDATE_LAST_READED_MESSAGES_SUCCESS,
  payload: lastReadedMessages
});

const updateLastReadedMessagesFail = error => ({
  type: UPDATE_LAST_READED_MESSAGES_FAILED,
  payload: error
});

const initialState = {
  isAuthenticated: false,
  user: {
    activeRole: null,
    minCalendarTime: new Date(new Date().setHours(8, 0, 0)),
    maxCalendarTime: new Date(new Date().setHours(18, 0, 0)),
    visitTime: 15,
    lastReadedMessages: {}
  },
  userRole: null,
  loading: true,
  error: null
};
//AUTH REDUCER--------------------------------------------------
export default (state = initialState, action) => {
  switch (action.type) {
    //authenticate user call
    case AUTHENTICATE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false
      };
    case AUTHENTICATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    //get user from token call
    case TOKEN_AUTH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case TOKEN_AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
        error: null
      };
    case TOKEN_AUTH_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isAuthenticated: false,
        user: {
          activeRole: null
        }
      };
    case USER_ROLE_SUCCESS:
      return {
        ...state,
        userRole: action.payload,
        loading: false
      }
    case LOGOUT_USER:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isAuthenticated: false,
        user: {
          activeRole: null
        },
        userRole: null,
        loading: false
      };
    case UPDATE_AUTHENTICATED_USER_REQUEST:
      return {
        ...state,
        // loading: true,
      }
    case UPDATE_AUTHENTICATED_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false
      }
    case UPDATE_AUTHENTICATED_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case UPDATE_AUTHENTICATED_USER_ROLE_REQUEST:
      return {
        ...state,
        // loading: true,
      }
    case UPDATE_AUTHENTICATED_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRole: action.payload,
        loading: false
      }
    case UPDATE_AUTHENTICATED_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SET_USER_AVATAR_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case SET_USER_AVATAR_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case SET_USER_AVATAR_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case UPDATE_LAST_READED_MESSAGES_REQUEST:
      return {
        ...state,
      }
    case UPDATE_LAST_READED_MESSAGES_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          lastReadedMessages: action.payload
        },
      }
    case UPDATE_LAST_READED_MESSAGES_FAILED:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
};
