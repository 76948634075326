import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Container from '../Shared/Container';
import Input from '../Shared/Input';
import { renderControl } from './utils';
import { sortArrayHandler } from '../Shared/utils';
import { ReactComponent as RemoveIcon } from '../Shared/assets/grey-bin.svg';
import * as Yup from 'yup';
import Tooltip from '../Shared/Tooltip';
import { ReactComponent as InfoIcon } from '../Shared/assets/info-circle.svg';

const ElementsForm = ({
    currentStage: { obj: currentStageObj, i: currentStageIndex },
    currentElement,
    changeElementValueHandler,
    warningModalHandler,
    patientMedsDiagnosis,
    patientHospitalizations,
    services,
    setServices,
    medsSwitch,
    readMode,
    changes
}) => {
    const currentElementObj = currentStageObj?.elements && currentStageObj?.elements[currentElement];
    const sectionNames = [currentElementObj?.name, currentElementObj?.name, 'Historia zabiegów', 'Historia chorób', currentElementObj?.name, 'Wykonane usługi'];
    if (medsSwitch) {
        sectionNames[4] = 'Historia leków';
    }
    return (
        <StyledContainer
            title={sectionNames[currentStageIndex]}
        >
            {(currentStageIndex === 0 || currentStageIndex === 1 || (currentStageIndex === 4 && !medsSwitch)) ? <>
                {currentElementObj?._id && <StyledRemoveIcon
                    className='remove-element-btn'
                    title="Usuń element"
                    onClick={() => warningModalHandler('removeEl', currentElementObj._id)}
                />}
                <Inputs id='elementFields'>
                    {currentElementObj?.formControls?.map((control, index) => {
                        let previousValues;
                        try {
                            const targetStageIndex = changes.stagesCollectedData.findIndex(({ stageName }) => stageName === currentStageObj.stageName);
                            previousValues = changes.stagesCollectedData[targetStageIndex].elements[currentElement].previousValues[index];
                        } catch (err) {
                            previousValues = [];
                        }
                        return (
                            <ControlWrapper key={`${control.name}_${index}`}>
                                {(previousValues && previousValues.length > 0) && <StyledTooltip name={control.name} content={
                                    <TooltipContent>
                                        <p>Poprzednie wartości:</p>
                                        <ul>
                                            {previousValues.map(({ value, changeDate }, index) => {
                                                let mapedValue = value;
                                                if (control.formType === 'datepicker' && value !== 'Dodane') {
                                                    mapedValue = moment(mapedValue).format('DD.MM.YYYY')
                                                } else if (control.formType === 'timepicker' && value !== 'Dodane') {
                                                    mapedValue = moment(mapedValue).format('HH:mm')
                                                } else if (value === 'undefined') {
                                                    mapedValue = 'Nie wybrano'
                                                }
                                                return (
                                                    <li key={`${changeDate}_${index}`}>{`${moment(changeDate).format('DD.MM.YYYY HH:mm')} - ${mapedValue}`}</li>
                                                )
                                            })}
                                        </ul>
                                    </TooltipContent>
                                }>
                                    <StyledInfoIcon />
                                </StyledTooltip>}
                                {renderControl(control, index, changeElementValueHandler, readMode)}
                            </ControlWrapper>
                        )
                    })}
                </Inputs>
            </>
                : currentStageIndex === 2 ?
                    <List>
                        {patientHospitalizations?.medicalProcedures?.length > 0 ? sortArrayHandler(patientHospitalizations?.medicalProcedures, 'executionDate', '-date')?.map(({ _id, code, name, executionDate }) => (
                            <ListItem key={_id}>
                                <ItemValue bold>
                                    {moment(executionDate).format('DD.MM.YYYY')}
                                </ItemValue>
                                <ItemValue>
                                    <b>{code}</b> {name}
                                </ItemValue>
                            </ListItem>
                        ))
                            :
                            <NoItems>Brak</NoItems>
                        }
                    </List>
                    : currentStageIndex === 3 ?
                        <>
                            <List>
                                <ListLabel>Przewlekłe:</ListLabel>
                                {patientMedsDiagnosis?.constantDiagnosis?.length > 0 ? sortArrayHandler(patientMedsDiagnosis?.constantDiagnosis, 'diagnosisDate', '-date')?.map(({ _id, category, subcategory, diagnosisDate }) => (
                                    <ListItem key={_id}>
                                        <ItemValue bold>
                                            {moment(diagnosisDate).format('DD.MM.YYYY')}
                                        </ItemValue>
                                        <ItemValue>
                                            {category},
                                        </ItemValue>
                                        <ItemValue>
                                            {subcategory}
                                        </ItemValue>
                                    </ListItem>
                                ))
                                    :
                                    <NoItems>Brak</NoItems>
                                }
                            </List>
                            <List>
                                <ListLabel>Pozostałe:</ListLabel>
                                {patientMedsDiagnosis?.otherDiagnosis?.length > 0 ? sortArrayHandler(patientMedsDiagnosis?.otherDiagnosis, 'diagnosisDate', '-date')?.map(({ _id, category, subcategory, diagnosisDate }) => (
                                    <ListItem key={_id}>
                                        <ItemValue bold>
                                            {moment(diagnosisDate).format('DD.MM.YYYY')}
                                        </ItemValue>
                                        <ItemValue>
                                            {category},
                                        </ItemValue>
                                        <ItemValue>
                                            {subcategory}
                                        </ItemValue>
                                    </ListItem>
                                ))
                                    :
                                    <NoItems>Brak</NoItems>
                                }
                            </List>
                        </>
                        : currentStageIndex === 4 ?
                            <>
                                <List>
                                    <ListLabel>Stale przyjmowane:</ListLabel>
                                    {patientMedsDiagnosis?.constantMeds?.length > 0 ? sortArrayHandler(patientMedsDiagnosis?.constantMeds, 'dataWystawieniaRecepty', '-date')?.map(({ _id, nazwaProduktu, moc, wielkosc, jednostkaWielkosci, dataWystawieniaRecepty }) => (
                                        <ListItem key={_id}>
                                            <ItemValue bold>
                                                {moment(dataWystawieniaRecepty).format('DD.MM.YY')}
                                            </ItemValue>
                                            <ItemValue>{nazwaProduktu},</ItemValue>
                                            <ItemValue>
                                                {`${moc}, ${wielkosc} ${jednostkaWielkosci}`}
                                            </ItemValue>
                                        </ListItem>
                                    )) :
                                        <NoItems>Brak</NoItems>
                                    }
                                </List>
                                <List>
                                    <ListLabel>Pozostałe:</ListLabel>
                                    {patientMedsDiagnosis?.otherMeds?.length > 0 ? sortArrayHandler(patientMedsDiagnosis?.otherMeds, 'dataWystawieniaRecepty', '-date')?.map(({ _id, nazwaProduktu, moc, wielkosc, jednostkaWielkosci, dataWystawieniaRecepty, dataKoncaPrzyjmowania }) => (
                                        <ListItem key={_id}>
                                            <ItemValue bold>
                                                {moment(dataWystawieniaRecepty).format('DD.MM.YY')}
                                                {dataKoncaPrzyjmowania ? ` - ${moment(dataKoncaPrzyjmowania).format('DD.MM.YY')}` : ''}
                                            </ItemValue>
                                            <ItemValue>{nazwaProduktu},</ItemValue>
                                            <ItemValue>
                                                {`${moc}, ${wielkosc} ${jednostkaWielkosci}`}
                                            </ItemValue>
                                        </ListItem>
                                    )) :
                                        <NoItems>Brak</NoItems>
                                    }
                                </List>
                            </> : currentStageIndex === 5 ?
                                <>
                                    <List>
                                        {/* <ListLabel>Pozostałe:</ListLabel> */}
                                        {services?.added?.length > 0 ? services?.added?.map(({ _id, price, name }, index) => (
                                            <ServiceItem key={`${_id}_${index}`}>
                                                <span>
                                                    {name}
                                                </span>
                                                <span>
                                                    {price + ' zł'}
                                                </span>
                                                {!readMode && <RemoveBtn
                                                    onClick={() => setServices(prev => ({
                                                        ...prev,
                                                        added: prev.added.filter((_, i) => i !== index)
                                                    }))}
                                                />}
                                            </ServiceItem>
                                        )) :
                                            <NoItems>Brak</NoItems>
                                        }
                                    </List>
                                    <CostPanel>
                                        <span>
                                            <CostLabel>Razem: </CostLabel>
                                            <CostValue>{services.totalWithDiscount} zł</CostValue>
                                        </span>
                                        <span>
                                            <CostLabel>Zniżka: </CostLabel>
                                            <DiscountInput
                                                name='discount'
                                                value={services.discount}
                                                error={services.discountError}
                                                onChange={async (e) => {
                                                    try {
                                                        let { value } = e.target;
                                                        setServices(prev => ({ ...prev, discount: value, discountError: null, }))
                                                        Yup.number()
                                                            .min(0, 'Zniżka nie może być ujemna')
                                                            .max(100, 'Zniżka nie może być wieksza niż 100%')
                                                            .typeError('Zniżka musi być numerem')
                                                            .validateSync(value);
                                                    }
                                                    catch (err) {
                                                        setServices(prev => ({ ...prev, discountError: err.message, }))
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    let { value } = e.target;
                                                    if (value === '' || services.discountError !== null) {
                                                        setServices(prev => ({ ...prev, discount: 0, discountError: null, }))
                                                    }
                                                }}
                                                disabled={readMode}
                                            />
                                            %
                                        </span>
                                    </CostPanel>
                                </>
                                : <p>Error</p>}

        </StyledContainer >
    )
};

export default ElementsForm;

const StyledContainer = styled(Container)`
    position:relative;
    margin:0 0 10px;
    height: calc(50% - 5px);
    >div:last-child{
        max-height: calc(50vh - 150px);
        overflow-y: auto;
    }
`;

const Inputs = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 0 5px;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
    transition:300ms;
    margin:0 2.5px;
    cursor:pointer;
    height: 25px;
    width: 25px;
    position:absolute;
    right: 9px;
    top: 9px;
    path{
        fill:#ccc;
        transition:300ms;
    }
    &:hover path{
        fill:${({ theme }) => theme.danger}
    }
`;

const ListLabel = styled.p`
    margin: 0 5px;
    font-size:14px;
    font-weight:600;
`;

const ListItem = styled.li`
    font-size:12px;
    padding: 2.5px 10px;
    &:not(:last-child){
        border-bottom:1px solid ${({ theme }) => theme.grey};
    }
    >p{
        font-size:12px;
    }
`;

const ItemValue = styled.p`
    ${({ bold }) => bold && `font-weight:600;`}
`;

const NoItems = styled.p`
    font-size: 12px;
    margin-left: 10px;
`;

const List = styled.ul`
    margin:0;
`;

const ServiceItem = styled.li`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 2.5px 30px 2.5px 10px;
    position:relative;
    &:not(:last-child){
        border-bottom:1px solid ${({ theme }) => theme.grey};
    }
    >span{
        font-size:14px;
    }
`;

const CostPanel = styled.div`
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    >span{
        display:flex;
        align-items: flex-end;
    }
`;

const CostLabel = styled.span`
    font-size:14px;
    margin-right:5px;
`;

const CostValue = styled.span`
    color:${({ theme }) => theme.primary};
    font-size:18px;
    font-weight:600;
`;

const DiscountInput = styled(Input)`
    border:none;
    border-bottom:2px solid #ccc;
    padding:0;
    width:33px;
    min-height:0;
    font-size:18px;
    border-radius:0;
    margin:0;
    color:${({ theme }) => theme.primary};
    font-weight:600;
    + p {
        position:absolute;
        bottom:-20px;
        width:200px;
        right:0;
    }
    &:focus{
        padding:0;
    }
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const StyledInfoIcon = styled(InfoIcon)`
    position:absolute;
    right:-6.5px;
    top:-5px;
    z-index:9;
`;

const StyledTooltip = styled(Tooltip)`
    position:absolute;
    right:5px;
    top:5px;
`;

const ControlWrapper = styled.div`
    position:relative;
`;

const TooltipContent = styled.div`
    p{
        color:#fff;
    }
    ul{
        margin:0;
        >li{
            color:#fff;
        }
    }
`;