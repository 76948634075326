import React, { useState, useEffect, useRef } from 'react';
import ChatView from './ChatView';
import { connect } from 'react-redux';
import { getHealthcenters } from '../../../redux/modules/healthcenter';
import { getCompany, getCompanyUsers } from '../../../redux/modules/company';
import { setCurrentChannel, getMessagesByChannelId, getMessagesByChannelIds, createMessage, setMessageListHeight, clearUnreadMessages } from '../../../redux/modules/chat';
import { setChatChannelOpen } from '../../../redux/modules/application';
import { sortArrayHandler } from '../../Shared/utils';
import { getEmployees } from '../../../redux/modules/employees';
import { updateLastReadedMessages } from '../../../redux/modules/authentication';
import moment from 'moment';

const ChatContainer = ({
    currentChannel,
    messages,
    userRole,
    user,
    getHealthcenters,
    getCompany,
    healthcenters,
    company,
    setCurrentChannel,
    getMessagesByChannelId,
    createMessage,
    companyUsers,
    getCompanyUsers,
    companyLoading,
    chatLoading,
    setMessageListHeight,
    messageListHeight,
    setChatChannelOpen,
    clearUnreadMessages,
    unreadMessages,
    updateLastReadedMessages,
    getMessagesByChannelIds,
    isOpen,
}) => {
    const [channelsList, setChannelsList] = useState([]),
        [detailedMessage, setDetailedMessage] = useState(null),
        [currentChannelMessages, setCurrentChannelMessages] = useState([]);

    const ChatElRef = useRef(null);

    const sendMessageHandler = (message) => {
        const newMessage = {
            user: user._id,
            channel: currentChannel,
            content: message,
            postDate: moment()._d,
        };
        createMessage(newMessage);
        setMessageListHeight(0);
        updateLastReadedMessages(user._id, {
            channelId: currentChannel,
            timestamp: newMessage.postDate
        });
    },
        scrollLoadHandler = (e) => {
            if (e.target.scrollTop === 0 && !chatLoading) {
                getMessagesByChannelId(currentChannel, Date.parse(currentChannelMessages[0][0].postDate));
            }
        },
        scrollChatTo = (to) => {
            if (ChatElRef.current && to === 'bottom') {
                ChatElRef.current.scrollTop = ChatElRef.current.scrollHeight;
            }
            else if (ChatElRef.current) {
                ChatElRef.current.scrollTop = to;
            }
        }

    const detailedMessageChangeHandler = (id) => {
        if (id !== detailedMessage) {
            setDetailedMessage(id);
        } else {
            setDetailedMessage(null);
        }
    };

    useEffect(() => {
        if (ChatElRef.current) {
            scrollChatTo(ChatElRef.current.scrollHeight - messageListHeight);
            setMessageListHeight(ChatElRef.current.scrollHeight);
        }
    }, [currentChannelMessages]);

    useEffect(() => {
        if (userRole?.companyId) {
            if (healthcenters?.length === 0) {
                getHealthcenters(userRole.companyId);
            }
            if (!company) {
                getCompany(userRole.companyId);
            }
        }
    }, [userRole]);

    useEffect(() => {
        if (userRole?.companyId) {
            if (companyUsers?.length === 0) {
                getCompanyUsers(userRole.companyId);
            }
        }
    }, [userRole]);

    useEffect(() => {
        if (messages[currentChannel]) {
            const orderedDates = messages[currentChannel].sort(function (a, b) {
                return Date.parse(b.postDate) - Date.parse(a.postDate);
            });
            if (unreadMessages[currentChannel] === 0) {
                updateLastReadedMessages(user._id, {
                    channelId: currentChannel,
                    timestamp: orderedDates[0]?.postDate ?? moment()._d
                })
            }
        }
    }, [currentChannel, messages]);

    useEffect(() => {
        if (companyUsers?.length > 0) {
            //Filter by channel
            let currentChannelMessages = messages[currentChannel] ?? [];
            currentChannelMessages = sortArrayHandler(currentChannelMessages, 'postDate', 'date');
            //Map to users
            let arrayIndex = -1;
            const array = [];
            currentChannelMessages.map((el, index) => {
                const userData = companyUsers.filter(el => el !== null).find(({ _id }) => _id === el.user) ?? {},
                    prevEl = currentChannelMessages[index - 1];
                if (el.user !== prevEl?.user) {
                    arrayIndex++;
                    array.push([{ ...el, userData }]);
                } else if (user._id) {
                    array[arrayIndex].push({ ...el, userData })
                }
            });
            setCurrentChannelMessages(array);
        }
    }, [currentChannel, messages, companyUsers]);

    useEffect(() => {
        if (healthcenters && company && userRole?._id && user?._id) {
            const userHcs = user.activeRole === 'Admin' ?
                healthcenters :
                healthcenters.filter(({ _id }) => userRole.healthCenters.includes(_id));
            setChannelsList([
                { name: company.name, _id: company._id },
                ...userHcs
                    .map(({ _id, name }) => ({ name, _id }))
            ])
        }
    }, [healthcenters, company, userRole, user._id]);

    useEffect(() => {
        if (channelsList.length > 0) {
            const channelsIds = channelsList.map(({ _id }) => _id);
            getMessagesByChannelIds(channelsIds, Date.now(), user._id);
        }
    }, [channelsList]);

    useEffect(() => {
        if (currentChannel !== null) {
            setTimeout(() => {
                scrollChatTo('bottom');
            }, 50)
            if (!isOpen) {
                setCurrentChannel(null)
            }
        }
    }, [isOpen, currentChannel])

    return (
        <ChatView
            channelsList={channelsList}
            currentChannel={currentChannel}
            messages={messages}
            setCurrentChannel={setCurrentChannel}
            sendMessageHandler={sendMessageHandler}
            userId={user._id}
            detailedMessage={detailedMessage}
            setDetailedMessage={setDetailedMessage}
            currentChannelMessages={currentChannelMessages}
            detailedMessageChangeHandler={detailedMessageChangeHandler}
            ChatElRef={ChatElRef}
            scrollLoadHandler={scrollLoadHandler}
            setChatChannelOpen={setChatChannelOpen}
            clearUnreadMessages={clearUnreadMessages}
            unreadMessages={unreadMessages}
            companyLoading={companyLoading}
        />
    )
}

const mapStateToProps = state => ({
    currentChannel: state.chat.currentChannel,
    messages: state.chat.messages,
    userRole: state.authentication.userRole,
    user: state.authentication.user,
    healthcenters: state.healthcenter.data,
    company: state.company.data,
    chatLoading: state.chat.loading,
    companyUsers: state.company.companyUsers,
    companyLoading: state.company.isLoading,
    messageListHeight: state.chat.messageListHeight,
    unreadMessages: state.chat.unreadMessages,
});

const mapDispatchToProps = dispatch => {
    return {
        getHealthcenters: (companyId) => dispatch(getHealthcenters(companyId)),
        getCompany: (companyId) => dispatch(getCompany(companyId)),
        getCompanyUsers: (companyId) => dispatch(getCompanyUsers(companyId)),
        setCurrentChannel: (channelId) => dispatch(setCurrentChannel(channelId)),
        getMessagesByChannelId: (channelId, timestamp) => dispatch(getMessagesByChannelId(channelId, timestamp)),
        getMessagesByChannelIds: (channelIds, timestamp, userId) => dispatch(getMessagesByChannelIds(channelIds, timestamp, userId)),
        createMessage: (channelId) => dispatch(createMessage(channelId)),
        getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
        setMessageListHeight: height => dispatch(setMessageListHeight(height)),
        setChatChannelOpen: channelId => dispatch(setChatChannelOpen(channelId)),
        clearUnreadMessages: channelId => dispatch(clearUnreadMessages(channelId)),
        updateLastReadedMessages: (userId, body) => dispatch(updateLastReadedMessages(userId, body))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatContainer);