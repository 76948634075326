import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Button from '../../../Shared/Button';
import Select from '../../../Shared/Select';
import DatePicker from '../../../Shared/DatePicker';


const ProceduresModal = ({
    modalState: { shown: modalShown, target: modalTarget, editId: modalEditId },
    setModalState,
    procedures,
    vaccinations,
    getMedicalDataHandler,
    updatePatientHospitalizationHandler,
    patientHospitalizations
}) => {
    const [listItems, setListItems] = useState([]);

    const addItemHandler = ({ _id, ...val }) => {
        setListItems(prev => ([...prev, { id: `${_id}_${Math.random().toString(36).substr(2, 9)}`, ...val, executionDate: Date.now() }]));
    },
        removeItemHandler = (targetIndex) => {
            setListItems(prev => prev.filter((el, index) => index !== targetIndex));
        },
        changeDateHandler = (val, targetId) => {
            setListItems(prev => prev.map(el => {
                if (el.id === targetId) {
                    el.executionDate = val;
                    return el;
                } else {
                    return el;
                }
            }
            ));
        },
        resetModalHandler = () => {
            setListItems([]);
        },
        submitHandler = () => {
            if (modalTarget === 'icd9s') {
                updatePatientHospitalizationHandler({
                    medicalProcedures: listItems
                });
            } else {
                updatePatientHospitalizationHandler({
                    vaccinations: [...listItems]
                });
            }
            setModalState({ shown: false, editId: null, target: null });
            resetModalHandler();
        };

    useEffect(() => {
        if (modalTarget === 'icd9s') {
            patientHospitalizations && setListItems(patientHospitalizations.medicalProcedures);
        } else if (modalTarget === 'vaccinations') {
            patientHospitalizations && setListItems(patientHospitalizations.vaccinations.map(({ _id, ...el }) => ({ id: `${_id}_${Math.random().toString(36).substr(2, 9)}`, ...el })));
        }
    }, [modalTarget]);

    return (
        <Modal
            title={`${modalEditId ? 'Edycja' : 'Dodawanie'} ${modalTarget === 'icd9s' ? 'procedury' : 'szczepienia'}`}
            exitButton={true}
            onCancel={() => { setModalState({ shown: false, target: null, editId: null, }); resetModalHandler(); }}
            show={modalShown}
        >
            <Wrapper>
                <StyledSelect
                    options={modalTarget === 'icd9s' ?
                        procedures?.map(el => ({ ...el, label: el.name, value: el.name })) :
                        vaccinations?.map(el => ({ ...el, label: el.name, value: el.name }))
                    }
                    // isLoading={medsdiagnosisLoading}
                    name='itemSearch'
                    onChange={(val) => addItemHandler(val)}
                    onInputChange={(val) => { getMedicalDataHandler(modalTarget, val) }}
                    value={{ label: `Dodaj ${modalTarget === 'icd9s' ? 'procedurę' : 'szczepienie'} z listy` }}
                    searchInputWithoutFilter={true}
                    noOptionsMessage={() => <p>{`Nie znaleziono ${modalTarget === 'icd9s' ? 'takiej procedury' : 'takiego szczepienia'}`}</p>}
                />
                <List>
                    {listItems.length > 0 ?
                        listItems.map(({ name, code, executionDate, id }, index) => (
                            <ListItem>
                                <Left>
                                    {code && <Code>{code}</Code>}
                                    <Name>{name}</Name>
                                </Left>
                                <StyledDatePicker
                                    dateFormat="dd.MM.yyyy"
                                    selected={new Date(executionDate) ?? Date.now()}
                                    hideError={true}
                                    onChange={(val) => changeDateHandler(val, id)}
                                    showBackgroundImage={false}
                                    preventOverflow={true}
                                />
                                <RemoveBtn type="button" onClick={() => removeItemHandler(index)} />
                            </ListItem>
                        ))
                        :
                        <NoItems>Brak rozpoznań</NoItems>
                    }
                </List>
                <CenterBox>
                    <Button onClick={submitHandler} variant='primary'>Zapisz zmiany</Button>
                </CenterBox>
            </Wrapper>
        </Modal >
    )
};

export default ProceduresModal;

const Wrapper = styled.form`
    width:450px;
    padding-top:10px;
`;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;

const StyledSelect = styled(Select)`
    >div{
        margin: 2px 0;
    }
`;

const List = styled.ul`
    margin:20px 0;
    max-height:335px;
    overflow-y:auto;
`;

const ListItem = styled.li`
    position:relative;
    font-size:14px;
    padding:5px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    &:not(:last-child){
        border-bottom: 1px solid ${({ theme }) => theme.grey};
    }
`;

const NoItems = styled.p`
    font-size:14px;
    text-align:center;
`

const Code = styled.span`
    font-size:12px;
    font-weight:600;
    margin-right:5px;
`;

const Name = styled.span`
    font-size:12px;
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const Left = styled.span`
    display:flex;
`;

const StyledDatePicker = styled(DatePicker)`
    width:auto;
    & input{
        border: none;
        min-height: 0;
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        width: 80px;
        cursor: pointer;
        &:focus{
            padding:0;
        }
    }
`;