import axios from 'axios';

const GET_DOCTORS_ELEMENTS_SETS_REQUEST = "GET_DOCTORS_ELEMENTS_SETS_REQUEST";
const GET_DOCTORS_ELEMENTS_SETS_SUCCESS = "GET_DOCTORS_ELEMENTS_SETS_SUCCESS";
const GET_DOCTORS_ELEMENTS_SETS_FAIL = "GET_DOCTORS_ELEMENTS_SETS_FAIL";

const ADD_ELEMENTS_SET_REQUEST = "ADD_ELEMENTS_SET_REQUEST";
const ADD_ELEMENTS_SET_SUCCESS = "ADD_ELEMENTS_SET_SUCCESS";
const ADD_ELEMENTS_SET_FAIL = "ADD_ELEMENTS_SET_FAIL";

const UPDATE_ELEMENTS_SET_REQUEST = "UPDATE_ELEMENTS_SET_REQUEST";
const UPDATE_ELEMENTS_SET_SUCCESS = "UPDATE_ELEMENTS_SET_SUCCESS";
const UPDATE_ELEMENTS_SET_FAIL = "UPDATE_ELEMENTS_SET_FAIL";

const DELETE_ELEMENTS_SET_REQUEST = "DELETE_ELEMENTS_SET_REQUEST";
const DELETE_ELEMENTS_SET_SUCCESS = "DELETE_ELEMENTS_SET_SUCCESS";
const DELETE_ELEMENTS_SET_FAIL = "DELETE_ELEMENTS_SET_FAIL";

const IMPORT_ELEMENTS_SET_REQUEST = "IMPORT_ELEMENTS_SET_REQUEST";
const IMPORT_ELEMENTS_SET_SUCCESS = "IMPORT_ELEMENTS_SET_SUCCESS";
const IMPORT_ELEMENTS_SET_FAIL = "IMPORT_ELEMENTS_SET_FAIL";

const EXPORT_ELEMENTS_SET_REQUEST = "EXPORT_ELEMENTS_SET_REQUEST";
const EXPORT_ELEMENTS_SET_SUCCESS = "EXPORT_ELEMENTS_SET_SUCCESS";
const EXPORT_ELEMENTS_SET_FAIL = "EXPORT_ELEMENTS_SET_FAIL";

export const getDoctorsElementsSet = (doctorId) => async dispatch => {
    dispatch(getDoctorsElementsSetRequest())
    try {
        const response = await axios.get(`/visit-elements-set/doctor/${doctorId}`);
        const { data } = response;
        dispatch(getDoctorsElementsSetSuccess(data))
    } catch (error) {
        dispatch(getDoctorsElementsSetFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const getDoctorsElementsSetRequest = () => ({
    type: GET_DOCTORS_ELEMENTS_SETS_REQUEST,
});

const getDoctorsElementsSetSuccess = (set) => ({
    type: GET_DOCTORS_ELEMENTS_SETS_SUCCESS,
    payload: set
});

const getDoctorsElementsSetFail = (error) => ({
    type: GET_DOCTORS_ELEMENTS_SETS_FAIL,
    payload: error
});

export const addElementsSet = (newSet) => async dispatch => {
    dispatch(addElementsSetRequest())
    try {
        const response = await axios.post(`/visit-elements-set/`, newSet);
        const { data } = response;
        dispatch(addElementsSetSuccess(data))
    } catch (error) {
        dispatch(addElementsSetFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const addElementsSetRequest = () => ({
    type: ADD_ELEMENTS_SET_REQUEST,
});

const addElementsSetSuccess = (set) => ({
    type: ADD_ELEMENTS_SET_SUCCESS,
    payload: set
});

const addElementsSetFail = (error) => ({
    type: ADD_ELEMENTS_SET_FAIL,
    payload: error
});

export const updateElementsSet = (setId, newSet) => async dispatch => {
    dispatch(updateElementsSetRequest())
    try {
        const response = await axios.patch(`/visit-elements-set/${setId}`, newSet);
        const { data } = response;
        dispatch(updateElementsSetSuccess(data))
    } catch (error) {
        dispatch(updateElementsSetFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const updateElementsSetRequest = () => ({
    type: UPDATE_ELEMENTS_SET_REQUEST,
});

const updateElementsSetSuccess = (set) => ({
    type: UPDATE_ELEMENTS_SET_SUCCESS,
    payload: set
});

const updateElementsSetFail = (error) => ({
    type: UPDATE_ELEMENTS_SET_FAIL,
    payload: error
});

export const deleteElementsSet = (setId) => async dispatch => {
    dispatch(deleteElementsSetRequest())
    try {
        const response = await axios.delete(`/visit-elements-set/${setId}`);
        dispatch(deleteElementsSetSuccess(setId))
    } catch (error) {
        dispatch(deleteElementsSetFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const deleteElementsSetRequest = () => ({
    type: DELETE_ELEMENTS_SET_REQUEST,
});

const deleteElementsSetSuccess = (setId) => ({
    type: DELETE_ELEMENTS_SET_SUCCESS,
    payload: setId
});

const deleteElementsSetFail = (error) => ({
    type: DELETE_ELEMENTS_SET_FAIL,
    payload: error
});

export const importElementsSet = (doctorId, file) => async dispatch => {
    dispatch(importElementsSetRequest())
    console.log(file);
    try {
        const response = await axios.post(`/visit-elements-set/doctor/${doctorId}/import`, file);
        const { data } = response;
        dispatch(importElementsSetSuccess(data));
    } catch (error) {
        console.log(error);
        dispatch(importElementsSetFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const importElementsSetRequest = () => ({
    type: IMPORT_ELEMENTS_SET_REQUEST,
});

const importElementsSetSuccess = (element) => ({
    type: IMPORT_ELEMENTS_SET_SUCCESS,
    payload: element
});

const importElementsSetFail = (error) => ({
    type: IMPORT_ELEMENTS_SET_FAIL,
    payload: error
});

export const exportElementsSet = (setId) => async dispatch => {
    dispatch(exportElementsSetRequest())
    try {
        const response = await axios.get(`/visit-elements-set/${setId}/export`);
        const { data } = response;
        console.log(data);
        const fileName = data.name;
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.log(error);
        dispatch(exportElementsSetFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const exportElementsSetRequest = () => ({
    type: EXPORT_ELEMENTS_SET_REQUEST,
});

const exportElementsSetSuccess = (file) => ({
    type: EXPORT_ELEMENTS_SET_SUCCESS,
    payload: file
});

const exportElementsSetFail = (error) => ({
    type: EXPORT_ELEMENTS_SET_FAIL,
    payload: error
});

const initialState = {
    doctorsElementsSets: [],
    loading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCTORS_ELEMENTS_SETS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_DOCTORS_ELEMENTS_SETS_SUCCESS:
            return {
                ...state,
                doctorsElementsSets: action.payload,
                loading: true,
            }
        case GET_DOCTORS_ELEMENTS_SETS_FAIL:
            return {
                ...state,
                loading: true,
            }
        case ADD_ELEMENTS_SET_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADD_ELEMENTS_SET_SUCCESS:
            return {
                ...state,
                doctorsElementsSets: [...state.doctorsElementsSets, action.payload],
                loading: true,
            }
        case ADD_ELEMENTS_SET_FAIL:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_ELEMENTS_SET_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_ELEMENTS_SET_SUCCESS:
            return {
                ...state,
                doctorsElementsSets: state.doctorsElementsSets.map(el => el._id === action.payload._id ? action.payload : el),
                loading: true,
            }
        case UPDATE_ELEMENTS_SET_FAIL:
            return {
                ...state,
                loading: true,
            }
        case DELETE_ELEMENTS_SET_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DELETE_ELEMENTS_SET_SUCCESS:
            return {
                ...state,
                doctorsElementsSets: state.doctorsElementsSets.filter(el => el._id !== action.payload),
                loading: true,
            }
        case DELETE_ELEMENTS_SET_FAIL:
            return {
                ...state,
                loading: true,
            }
        case IMPORT_ELEMENTS_SET_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case IMPORT_ELEMENTS_SET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                doctorsElementsSets: [...state.doctorsElementsSets, action.payload]
            }
        case IMPORT_ELEMENTS_SET_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        default:
            return state;
    }
};