const UPDATE_CALENDAR_USER = "UPDATE_CALENDAR_USER";
const UPDATE_CALENDAR_DATE = "UPDATE_CALENDAR_DATE";
const UPDATE_CURRENT_VIEW = "UPDATE_CURRENT_VIEW";
const UPDATE_RESOURCE_STATUS = "UPDATE_RESOURCE_STATUS";
const UPDATE_ROOMS_STATUS = "UPDATE_ROOMS_STATUS";
const GET_CALENDAR_LOCAL_STORAGE = "GET_CALENDAR_LOCAL_STORAGE";
const UPDATE_DOCTORS_LIST = "UPDATE_DOCTORS_LIST";
const UPDATE_NURSES_LIST = "UPDATE_NURSES_LIST";
const UPDATE_GROUPS_LIST = "UPDATE_GROUPS_LIST";
const SET_INITIAL_CALENDAR = "SET_INITIAL_CALENDAR";

export const setInitialCalendar = () => ({
  type: SET_INITIAL_CALENDAR
})

export const updateCalendarUser = userId => async dispatch => {
  dispatch({
    type: UPDATE_CALENDAR_USER,
    payload: userId
  });
};
export const updateCalendarDate = date => async dispatch => {
  dispatch({
    type: UPDATE_CALENDAR_DATE,
    payload: date
  });
};
export const updateCurrentView = view => async dispatch => {
  dispatch({
    type: UPDATE_CURRENT_VIEW,
    payload: view
  });
};
export const updateResourceStatus = status => async dispatch => {
  dispatch({
    type: UPDATE_RESOURCE_STATUS,
    payload: status
  });
};
export const updateRoomsStatus = status => async dispatch => {
  dispatch({
    type: UPDATE_ROOMS_STATUS,
    payload: status
  });
};

export const updateDoctorsList = doctors => async dispatch => {
  const mappedDoctors = doctors.map(doctor => doctor.checked !== undefined ? doctor : { ...doctor, checked: false });
  dispatch({
    type: UPDATE_DOCTORS_LIST,
    payload: mappedDoctors
  });
};

export const updateNursesList = nurses => async dispatch => {
  const mappedNurses = nurses.map(nurse => nurse.checked !== undefined ? nurse : { ...nurse, checked: false });
  dispatch({
    type: UPDATE_NURSES_LIST,
    payload: mappedNurses
  });
};

export const updateGroupsList = groups => async dispatch => {
  const mappedGroups = groups.map(group => group.checked !== undefined ? group : { ...group, checked: false })
  dispatch({
    type: UPDATE_GROUPS_LIST,
    payload: mappedGroups
  });
};

export const getCalendarLocalStorage = (userId) => {
  if (userId === getFromLocalStorage('calendar_user') ?? '') {
    return ({
      type: GET_CALENDAR_LOCAL_STORAGE,
    })
  } else {
    removeLocalStorage('currentHealthcenter');
    removeLocalStorage('calendar_date');
    removeLocalStorage('current_view');
    removeLocalStorage('resource_status');
    removeLocalStorage('rooms_status');
    removeLocalStorage('doctors');
    removeLocalStorage('nurses');
    removeLocalStorage('groups');
    return ({
      type: UPDATE_CALENDAR_USER,
      payload: userId
    })
  }
};

const getFromLocalStorage = (itemName) =>
  JSON.parse(localStorage.getItem(itemName))

const setToLocalStorage = (itemName, payload) =>
  localStorage.setItem(itemName, JSON.stringify(payload));

const removeLocalStorage = (itemName) =>
  localStorage.removeItem(itemName);

const initialState = {
  calendarDate: new Date(),
  calendarUser: null,
  currentView: "day",
  resourceStatus: false,
  roomsStatus: false,
  doctors: [],
  nurses: [],
  groups: [],
  localStorageReadFinished: false
};

//CALENDAR REDUCER-----------------------------------------------
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CALENDAR_LOCAL_STORAGE: {
      return {
        ...state,
        calendarDate: getFromLocalStorage("calendar_date") ? getFromLocalStorage("calendar_date") : state.calendarDate,
        currentView: getFromLocalStorage("current_view") ? getFromLocalStorage("current_view") : state.currentView,
        resourceStatus: getFromLocalStorage("resource_status") ? getFromLocalStorage("resource_status") : state.resourceStatus,
        roomsStatus: getFromLocalStorage("rooms_status") ? getFromLocalStorage("rooms_status") : state.roomsStatus,
        doctors: getFromLocalStorage("doctors") ? getFromLocalStorage("doctors") : state.doctors,
        nurses: getFromLocalStorage("nurses") ? getFromLocalStorage("nurses") : state.nurses,
        groups: getFromLocalStorage("groups") ? getFromLocalStorage("groups") : state.groups,
        localStorageReadFinished: true
      }
    }
    case UPDATE_CALENDAR_USER: {
      setToLocalStorage("calendar_user", action.payload);
      return {
        ...state,
        calendarUser: action.payload,
        localStorageReadFinished: true
      };
    }
    case UPDATE_CALENDAR_DATE: {
      setToLocalStorage("calendar_date", action.payload);
      return {
        ...state,
        calendarDate: action.payload
      };
    }
    case UPDATE_CURRENT_VIEW: {
      setToLocalStorage("current_view", action.payload);
      return {
        ...state,
        currentView: action.payload
      };
    }
    case UPDATE_RESOURCE_STATUS: {
      setToLocalStorage("resource_status", action.payload);
      return {
        ...state,
        resourceStatus: action.payload
      };
    }
    case UPDATE_ROOMS_STATUS: {
      setToLocalStorage("rooms_status", action.payload);
      return {
        ...state,
        roomsStatus: action.payload
      };
    }
    case UPDATE_DOCTORS_LIST: {
      setToLocalStorage("doctors", action.payload);
      return {
        ...state,
        doctors: action.payload
      };
    }
    case UPDATE_NURSES_LIST: {
      setToLocalStorage("nurses", action.payload);
      return {
        ...state,
        nurses: action.payload
      };
    }
    case UPDATE_GROUPS_LIST: {
      setToLocalStorage("groups", action.payload);
      return {
        ...state,
        groups: action.payload
      };
    }
    case SET_INITIAL_CALENDAR: {
      return {
        calendarDate: new Date(),
        calendarUser: null,
        currentView: "day",
        resourceStatus: false,
        roomsStatus: false,
        doctors: [],
        nurses: [],
        groups: [],
        localStorageReadFinished: false
      }
    }
    default:
      return state;
  }
};
