import React, { forwardRef, useState, useEffect } from "react";
import Modal from "../../../../Shared/Modals/Modal";
import styled from "styled-components";
import Textarea from "../../../../Shared/Textarea";
import Input from "../../../../Shared/Input";
import Button from "../../../../Shared/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import TimePicker from "../../../../Shared/TimePicker";
import moment from "moment";
import Switch from "../../../../Shared/Switch";
import { mapVisitTypesToOptions } from "../../../../Shared/utils";
import Select from "../../../../Shared/Select";
import DatePicker from "../../../../Shared/DatePicker";
import { calculateDisabledHours } from '../../../../CalendarPage/Modals/utils';

const validationSchema = Yup.object().shape({
  start: Yup.date()
    .max(Yup.ref("end"), "Większa niż końcowa")
    .required("Pole wymagane"),
  end: Yup.date()
    .min(Yup.ref("start"), "Mniejsza niż początkowa")
    .required("Pole wymagane"),
  doctor: Yup.string().required("Pole wymagane"),
  patient: Yup.string().required("Pole wymagane"),
  room: Yup.string().required("Pole wymagane")
});

const EditEventModal = forwardRef(
  ({
    isEditModalOn,
    setEditModal,
    patients,
    currentHealthcenter,
    selectedEvent,
    updateVisit,
    user,
    userRole,
    setWarningModal,
    deleteVisit,
    getCompanyPatientsHandler,
  }) => {
    const [visitTypeOptions, setVisitTypeOptions] = useState([]);

    const disabledHours = calculateDisabledHours(userRole?.callendarSettings.min, userRole?.callendarSettings.max);

    const initialValues = {
      date: moment(selectedEvent?.start)._d,
      visitType: selectedEvent?.visitType,
      patient: selectedEvent?.patient,
      start: moment(selectedEvent?.start).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(selectedEvent?.end).format("YYYY-MM-DD HH:mm:ss"),
      doctor: selectedEvent?.doctor,
      room: selectedEvent?.room,
      description: selectedEvent?.description,
      isConfirmed: selectedEvent?.state === "CONFIRMED" ? true : false
    };
    
    useEffect(() => {
      if (userRole) {
        setVisitTypeOptions(mapVisitTypesToOptions(userRole));
      }
    }, [userRole]);

    return (
      <Modal
        title="Edycja wydarzenia"
        show={isEditModalOn}
        exitButton={true}
        onCancel={() => {
          setEditModal(false);
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async values => {
            const status = values.isConfirmed ? "CONFIRMED" : "PLANNED";
            const resp = await updateVisit(selectedEvent._id, status, values);
            if (resp.status === 200) {
              setEditModal(false);
            } else {
              setWarningModal({
                shown: true,
                questionTitle: "Czy jesteś pewien?",
                question: resp === "Visit time is not in line with the doctor's schedule." ? "Ten doktor nie przyjmuje w tych godzinach. Czy chcesz mimo to kontynuować?" : "Ten doktor ma zaplanowy urlop w tym czasie. Czy chcesz mimo to kontynuować?",
                onAccept: async () => {
                  await updateVisit(
                    selectedEvent._id,
                    status,
                    { ...values, healthcenterId: currentHealthcenter._id },
                    true
                  );
                  setEditModal(false);
                }
              })
            }
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            setFieldError,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
              <Form onSubmit={handleSubmit}>
                <Select
                  label="Typ wizyty"
                  value={visitTypeOptions.find(({ value }) => value === values.visitType?.name) ?? visitTypeOptions[0]}
                  name="visitType"
                  placeholder="Wybierz typ..."
                  options={visitTypeOptions}
                  onChange={({ value, length }) => {
                    setFieldValue("visitType", { name: value, length });
                    setFieldValue("end", moment(values.start).add(length, 'minutes'));
                  }}
                  noOptionsMessage={() => <p>Nie znaleziono takiego typu</p>}
                />
                <InputRow>
                  <StyledDatePicker
                    selected={values.date}
                    dateFormat='dd.MM.yyyy'
                    label="Data"
                    onChange={(value) => {
                      const startTime = moment(values.start).format('HH:mm:ss');
                      const endTime = moment(values.end).format('HH:mm:ss');
                      const newDate = moment(value).format('YYYY-MM-DD');
                      setFieldValue("start", `${newDate} ${startTime}`);
                      setFieldValue("end", `${newDate} ${endTime}`);
                      setFieldValue("date", value);
                    }}
                  />
                  <StyledTimePicker
                    label="Początek"
                    allowEmpty={false}
                    value={moment(values.start)}
                    onChange={e => {
                      if (e !== null) {
                        const lengthToAdd = values.visitType ? visitTypeOptions.find(({ value }) => value === values.visitType.name).length : userRole.callendarSettings.step
                        setFieldValue("start", e.format("YYYY-MM-DD HH:mm:ss"))
                        setFieldValue("end", e.add(lengthToAdd, 'm').format("YYYY-MM-DD HH:mm:ss"))
                      } else {
                        setFieldError("start", "Pole wymagane")
                      }
                    }}
                    onBlur={handleBlur}
                    name="start"
                    showSecond={false}
                    minuteStep={5}
                    disabledMinutes={() => [0]}
                    disabledHours={() => disabledHours}
                    hideDisabledOptions={true}
                    error={errors.start}
                  />
                  <StyledTimePicker
                    label="Koniec"
                    allowEmpty={false}
                    value={moment(values.end)}
                    onChange={e =>
                      e !== null
                        ? setFieldValue("end", e.format("YYYY-MM-DD HH:mm:ss"))
                        : setFieldError("end", "Pole wymagane")
                    }
                    onBlur={handleBlur}
                    name="end"
                    showSecond={false}
                    minuteStep={5}
                    disabledMinutes={() => [0]}
                    disabledHours={() => disabledHours}
                    hideDisabledOptions={true}
                    error={errors.end}
                  />
                </InputRow>
                <InputRow colsNumber={2}>
                  <Select
                    label="Pacjent"
                    value={
                      patients
                        .filter(patient => patient._id === values.patient._id)
                        .map(patient => {
                          return { label: `${patient.name} ${patient?.surname}`, value: patient._id };
                        })[0]
                    }
                    name="patient"
                    placeholder="Wybierz pacjenta..."
                    options={patients.map(patient => {
                      return { label: `${patient.name} ${patient?.surname}`, value: patient._id };
                    })}
                    onChange={({ value }) => setFieldValue("patient", value)}
                    onInputChange={(value) => { getCompanyPatientsHandler(value) }}
                    error={errors.patient}
                  />
                  <Select
                    label="Lekarz"
                    name="doctor"
                    placeholder="Wybierz lekarza..."
                    options={[]}
                    value={{ label: `${user.name} ${user.surname}`, value: userRole._id, color: userRole.visitColor }}
                    onChange={({ value }) => setFieldValue("doctor", value)}
                    noOptionsMessage={() => "Nie znaleziono lekarza"}
                    styles={dotColorStyles}
                    error={errors.doctor}
                    isDisabled={(user?.activeRole === "Doctor" || user?.activeRole === "Nurse") ? true : false}
                  />
                </InputRow>
                <InputRow colsNumber={2}>
                  <Select
                    label="Gabinet"
                    name="room"
                    placeholder="Wybierz gabinet..."
                    options={currentHealthcenter.rooms.map((room) => {
                      return { label: room.name, value: room._id };
                    })}
                    onChange={({ value }) => setFieldValue("room", value)}
                    noOptionsMessage={() => "Nie znaleziono gabinetu"}
                    value={currentHealthcenter.rooms
                      .filter((room) => room._id === values.room)
                      .map((room) => {
                        return {
                          label: room.name,
                          value: room._id,
                        };
                      })
                    }
                    error={errors.room}
                  />
                </InputRow>
                <Input
                  type="checkbox"
                  variant='secondary'
                  name='isConfirmed'
                  value={values.isConfirmed}
                  checkboxText="Status potwierdzony"
                  onChange={() =>
                    setFieldValue("isConfirmed", !values.isConfirmed)
                  }
                />
                <Textarea
                  name="description"
                  label="Dodatkowe informacje"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  error={errors.description}
                />
                <CenterBox>
                  <Button type="submit" variant="primary">
                    Zapisz zmiany
                  </Button>
                  {(selectedEvent.state === "PLANNED" ||
                    selectedEvent.state === "CONFIRMED" ||
                    selectedEvent.state === "REJECTED") &&
                    <Button
                      type="button"
                      variant="danger"
                      onClick={() => setWarningModal({
                        shown: true,
                        question: 'Czy na pewno chcesz usunąć to wydarzenie?',
                        onAccept: () => {
                          deleteVisit(selectedEvent._id);
                          setEditModal(false);
                        }
                      })}
                    >
                      Odwołaj wizytę
                    </Button>
                  }
                </CenterBox>
              </Form>
            )}
        </Formik>
      </Modal >
    );
  }
);

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10
  }
});
const dotColorStyles = {
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  option: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};

export default EditEventModal;

const Form = styled.form`
  padding-top: 20px;
  width: 450px;
`;

const CenterBox = styled.div`
  text-align: center;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: ${({ colsNumber }) => colsNumber === 2 ? '48%;' : '31%;'};
  }
`;

const TodayDate = styled.p`
  font-size: 13px;
  color: #666;
  margin-bottom: 22px;
  width: 48%;
  text-align: center;
  align-self: flex-end;
`;

const StyledSwitch = styled(Switch)`
  margin-top: 5px;
`;

const StyledDatePicker = styled(DatePicker)`
  >label{
    display:block;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  input{
    width:100%!important;
  }
`;