import React, { useState } from 'react';
import Modal from '../../Shared/Modals/Modal';
import Input from '../../Shared/Input';
import Button from '../../Shared/Button';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import Switch from '../../Shared/Switch';

const HealthcentersSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nazwa jest wymagana'),
    address: Yup.object().shape({
        Street: Yup.string()
            .required('Ulica jest wymagana!'),
        City: Yup.string()
            .required('Miasto jest wymagane!'),
        houseNumber: Yup.string()
            .required('Numer domu jest wymagany!'),
    }),
    isActive: Yup.boolean()
});

const HealthcentersModal = ({
    companyId,
    modalState: { edit, shown },
    setModalShown,
    healthcenterData,
    updateHealthcenter,
    addHealthcenter,
    restoreHealthcenter
}) => {
    const [deleteModal, setDeleteModal] = useState(false),
        [restoreModal, setRestoreModal] = useState({ shown: false, hc: null });

    let initialValues = {
        name: '',
        address: {
            Street: '',
            houseNumber: '',
            City: '',
        },
        isActive: true
    };
    if (edit) {
        const {
            name,
            address: { Street, houseNumber, City },
            isActive
        } = healthcenterData;
        initialValues = {
            name,
            address: {
                Street,
                houseNumber,
                City,
            },
            isActive
        };
    }
    const editHandler = (values) => {
        setModalShown({ edit: false, shown: false });
        updateHealthcenter(healthcenterData._id, values);
    };
    const createHandler = async (values) => {
        const resp = await addHealthcenter({ companyId, ...values });
        if (resp) {
            setRestoreModal({ shown: true, hc: { id: resp, ...values, companyId } })
        } else {
            setModalShown({ edit: false, shown: false });
        }
    };
    return (
        <Modal
            title={edit ? 'Edycja placówek' : 'Dodawanie placówki'}
            exitButton={true}
            onCancel={() => setModalShown({ edit: false, shown: false })}
            show={shown}>
            <Modal
                type="SimpleQuestionModal"
                show={restoreModal.shown}
                testId='healthcenter_restore_modal'
                question={"Placówka o takiej nazwie istnieje już w tej firmie ale została usunięta. Czy chcesz ją przywrócić?"}
                acceptText="Tak"
                cancelText="Nie"
                onAccept={() => {
                    restoreHealthcenter(restoreModal.hc);
                    setRestoreModal({ shown: false, hc: null });
                    setModalShown({ edit: false, shown: false });
                }}
                onCancel={() => setRestoreModal({ shown: false, hc: null })}
            />
            <Modal
                type="SimpleQuestionModal"
                show={deleteModal}
                question="Czy na pewno chcesz usunąć tą placówkę?"
                acceptText="Tak"
                cancelText="Nie"
                onAccept={() => {
                    editHandler({ deleted: true });
                    setDeleteModal(false);
                    setModalShown({ edit: false, shown: false });
                }}
                onCancel={() => setDeleteModal(false)}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={HealthcentersSchema}
                onSubmit={(values) => edit ? editHandler(values) : createHandler(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <InputRow colsTemplate="4fr 1fr">
                            <Input
                                type="text"
                                name="name"
                                label="Nazwa"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={errors.name}
                            />
                            <Switch
                                label='Status'
                                checked={values.isActive}
                                onChange={() => setFieldValue('isActive', !values.isActive)}
                            />

                        </InputRow>
                        <InputRow colsTemplate="3fr 2fr 3fr">
                            <Input
                                type="text"
                                name="address.Street"
                                label="Ulica"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address.Street}
                                error={errors?.address?.Street}
                            />
                            <Input
                                type="text"
                                name="address.houseNumber"
                                label="Numer domu"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address.houseNumber}
                                error={errors?.address?.houseNumber}
                            />
                            <Input
                                type="text"
                                name="address.City"
                                label="Miasto"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address.City}
                                error={errors?.address?.City}
                            />

                        </InputRow>
                        <CenterBox>
                            <Button type='submit' variant='primary'>{edit ? 'Zapisz zmiany' : 'Dodaj placówkę'}</Button>
                            {edit && <Button type='button' variant='danger' onClick={() => setDeleteModal(true)}>Usuń placówkę</Button>}
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default HealthcentersModal;

const Form = styled.form`
    padding-top:20px;
    width:500px;
    max-height:600px;
`;
const InputRow = styled.div`
    display:grid;
    grid-template-columns: ${({ colsTemplate }) => colsTemplate};
    justify-content:space-between;
    &>div{
        padding: 0 5px;
    }
`;
const CenterBox = styled.div`
    text-align:center;
`;
