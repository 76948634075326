import axios from "axios";

const GET_PATIENT_MEDSDIAGNOSIS_REQUEST = "GET_PATIENT_MEDSDIAGNOSIS_REQUEST";
const GET_PATIENT_MEDSDIAGNOSIS_SUCCESS = "GET_PATIENT_MEDSDIAGNOSIS_SUCCESS";
const GET_PATIENT_MEDSDIAGNOSIS_FAIL = "GET_PATIENT_MEDSDIAGNOSIS_FAIL";

const UPDATE_PATIENT_MEDSDIAGNOSIS_REQUEST = "UPDATE_PATIENT_MEDSDIAGNOSIS_REQUEST";
const UPDATE_PATIENT_MEDSDIAGNOSIS_SUCCESS = "UPDATE_PATIENT_MEDSDIAGNOSIS_SUCCESS";
const UPDATE_PATIENT_MEDSDIAGNOSIS_FAIL = "UPDATE_PATIENT_MEDSDIAGNOSIS_FAIL";

const GET_MEDSDIAGNOSIS_REQUEST = "GET_MEDSDIAGNOSIS_REQUEST";
const GET_MEDS_SUCCESS = "GET_MEDS_SUCCESS";
const GET_DIAGNOSIS_SUCCESS = "GET_DIAGNOSIS_SUCCESS";
const GET_MEDSDIAGNOSIS_FAIL = "GET_MEDSDIAGNOSIS_FAIL";

export const getPatientMedsDiagnosis = (patientId) => async dispatch => {
    dispatch(getPatientMedsDiagnosisRequest())
    try {
        const response = await axios.get(`medsdiagnosis/patient/${patientId}`)
        dispatch(getPatientMedsDiagnosisSuccess(response.data))
    }
    catch (error) {
        dispatch(getPatientMedsDiagnosisFail(error))
    }
};

const getPatientMedsDiagnosisRequest = () => ({
    type: GET_PATIENT_MEDSDIAGNOSIS_REQUEST,
});

const getPatientMedsDiagnosisSuccess = (medsdiagnosis) => ({
    type: GET_PATIENT_MEDSDIAGNOSIS_SUCCESS,
    payload: medsdiagnosis
});

const getPatientMedsDiagnosisFail = (error) => ({
    type: GET_PATIENT_MEDSDIAGNOSIS_FAIL,
    payload: error
});

export const updatePatientMedsDiagnosis = (patientId, newMedsdiagnosis) => async dispatch => {
    dispatch(updatePatientMedsDiagnosisRequest())
    try {
        const response = await axios.patch(`medsdiagnosis/patient/${patientId}`, newMedsdiagnosis)
        dispatch(updatePatientMedsDiagnosisSuccess(response.data))
    }
    catch (error) {
        dispatch(updatePatientMedsDiagnosisFail(error))
    }
};

const updatePatientMedsDiagnosisRequest = () => ({
    type: UPDATE_PATIENT_MEDSDIAGNOSIS_REQUEST,
});

const updatePatientMedsDiagnosisSuccess = (medsdiagnosis) => ({
    type: UPDATE_PATIENT_MEDSDIAGNOSIS_SUCCESS,
    payload: medsdiagnosis
});

const updatePatientMedsDiagnosisFail = (error) => ({
    type: UPDATE_PATIENT_MEDSDIAGNOSIS_FAIL,
    payload: error
});

export const getMedsDiagnosis = (target, query) => async dispatch => {
    dispatch(getMedsDiagnosisRequest())
    try {
        const response = await axios.get(`${process.env.REACT_APP_MED_REGISTER_URL}${target}?${query}`);
        switch (target) {
            case 'icd10s':
                const filtered = response.data.data.slice(0, 30);
                dispatch(getDiagnosisSuccess(filtered));
                break;
            case 'medicalProducts':
                const filtered2 = response.data.data.slice(0, 30);
                dispatch(getMedsSuccess(filtered2));
                break;
            default:
                dispatch(getMedsDiagnosisFail(new Error('Target missing')))
                break;
        }
    }
    catch (error) {
        dispatch(getMedsDiagnosisFail(error))
    }
};

const getMedsDiagnosisRequest = () => ({
    type: GET_MEDSDIAGNOSIS_REQUEST,
});

const getMedsSuccess = (meds) => ({
    type: GET_MEDS_SUCCESS,
    payload: meds
});

const getDiagnosisSuccess = (diagnosis) => ({
    type: GET_DIAGNOSIS_SUCCESS,
    payload: diagnosis
});

const getMedsDiagnosisFail = (error) => ({
    type: GET_MEDSDIAGNOSIS_FAIL,
    payload: error
});

const initialState = {
    patientMedsDiagnosis: {},
    meds: [],
    diagnosis: [],
    loading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT_MEDSDIAGNOSIS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_PATIENT_MEDSDIAGNOSIS_SUCCESS:
            return {
                ...state,
                patientMedsDiagnosis: action.payload,
                loading: false
            }
        case GET_PATIENT_MEDSDIAGNOSIS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_PATIENT_MEDSDIAGNOSIS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_PATIENT_MEDSDIAGNOSIS_SUCCESS:
            return {
                ...state,
                patientMedsDiagnosis: action.payload,
                loading: false
            }
        case UPDATE_PATIENT_MEDSDIAGNOSIS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_MEDSDIAGNOSIS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_MEDS_SUCCESS:
            return {
                ...state,
                meds: action.payload,
                loading: false
            }
        case GET_DIAGNOSIS_SUCCESS:
            return {
                ...state,
                diagnosis: action.payload,
                loading: false
            }
        case GET_MEDSDIAGNOSIS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};