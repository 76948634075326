import React from "react";
import styled from "styled-components";
import plusIcon from "./assets/add-plus.svg";
import editIcon from "./assets/edit-pen.svg";
import { ReactComponent as CloseIcon } from "./assets/reject.svg";

const Container = ({ title, actionButton, actionButtonOnClick, actionButtonTitle, children, className }) => {
  return (
    <StyledContainer
      title={title}
      className={className}
    >
      <ContainerHeader>
        <Title>{title}</Title>
        {actionButton === "edit" ? (
          <ActionButton title={actionButtonTitle} onClick={actionButtonOnClick}>
            <Icon src={editIcon} />
          </ActionButton>
        ) : actionButton === "add" ? (
          <ActionButton title={actionButtonTitle} onClick={actionButtonOnClick}>
            <Icon src={plusIcon} />
          </ActionButton>
        ) : actionButton === "close" ? (
          <ActionButton title={actionButtonTitle} onClick={actionButtonOnClick}>
            <BlackCloseIcon />
          </ActionButton>
        ) : null}
      </ContainerHeader>
      <ContentWrapper>{children}</ContentWrapper>
    </StyledContainer>
  )
};

export default Container;

const ContentWrapper = styled.div`
  padding: 10px;
  height: calc(100% - 20px);
`;

const StyledContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.grey};
  padding: 10px;
  margin: 10px;
`;

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.p`
  margin-bottom: 10px;
  margin-left: 5px;
  color: ${({ theme }) => theme.black};
  font-weight: 700;
  font-size: 16px;
`;

const ActionButton = styled.button`
  background-color: #ededed;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #e8e8e8;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const BlackCloseIcon = styled(CloseIcon)`
  path{
    fill:#000;
  }
`;