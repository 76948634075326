import React from 'react';
import styled from 'styled-components';
import Container from '../Shared/Container';
import Gallery from '../Shared/Gallery';
import Button from '../Shared/Button';
import fileIcon from '../Shared/assets/add-file.svg';
import photoIcon from '../Shared/assets/add-photo.svg';

const Documents = ({
    currentStageIndex,
    readMode,
    documents,
    setDocumentsModal,
    setPrescriptionModal,
    warningModalHandler,
}) => {
    return (
        <DocsWrapper stageIndex={currentStageIndex}>
            <StyledContainer
                title='DOKUMENTY'
            >
                <Files>
                    <FileBtn
                        disabled={readMode}
                        variant='secondary'
                        title="Dodaj dokument"
                        onClick={() => setDocumentsModal(true)}
                        icon={fileIcon}
                    />
                    {/* <FileBtn
                        disabled={readMode}
                        variant='secondary'
                        right={true}
                        title="Dodaj receptę"
                        onClick={() => setPrescriptionModal({ type: 'recepta', isOpen: true })}
                        icon={fileIcon}
                    /> */}
                    <FilesItems>
                        {documents.documents.length > 0 ? documents.documents?.map(({ originalName, path, _id }) => (
                            <DocItem key={_id}>
                                <DocLink
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={`${process.env.REACT_APP_SRV_URL}${path}`}
                                >
                                    {originalName}
                                </DocLink>
                                {!readMode && <RemoveBtn
                                    onClick={() => warningModalHandler('removeDoc', _id)}
                                    title="Usuń dokument"
                                />}
                            </DocItem>
                        ))
                            :
                            <NoItems>Nie dodano żadnych dokumentów</NoItems>
                        }
                    </FilesItems>
                </Files>
            </StyledContainer>
            <StyledContainer
                title='ZDJĘCIA'
            >
                <Files>
                    <FileBtn
                        disabled={readMode}
                        variant='secondary'
                        title="Dodaj zdjęcie"
                        onClick={() => setDocumentsModal(true)}
                        icon={photoIcon}
                    />
                    <FilesItems>
                        {documents.photos.length > 0 ? <Gallery
                            images={documents.photos?.map(({ path, _id, originalName }) => ({ name: originalName, src: `${process.env.REACT_APP_SRV_URL}${path}`, _id }))}
                            previewSize="93px"
                            previewBorder={true}
                            deleteModalHandler={(_id) => warningModalHandler('removeDoc', _id)}
                            hideDelete={readMode}
                        /> :
                            <NoItems>Nie dodano żadnych zdjęć</NoItems>}
                    </FilesItems>
                </Files>
            </StyledContainer>
        </DocsWrapper>
    )
}

export default Documents;

const StyledContainer = styled(Container)`
  position:relative;
  height:100%;
  margin: 0;
`;

const DocsWrapper = styled.div`
  display:grid;
  grid-template-rows: 100%;
  grid-template-columns: 1fr 1fr;
  gap:10px;
  margin: 10px 0;
  transition:.3s;
  height:${({ stageIndex }) => stageIndex === 5 ? 'calc(20% - 5px)' : 'calc(30% - 5px)'};
`;

const Files = styled.div`
  display:flex;
  justify-content:space-between;
  padding:10px 0;
  height: 100%;
  max-height: 100%;
  overflow-y:auto;
`;

const DocItem = styled.div`
  position:relative;
  padding-right:25px;
`;

const DocLink = styled.a`
  font-size:12px;
  color:#000;
  text-decoration:none;
  transition:300ms;
  &:hover{
    color:${({ theme }) => theme.primary};
  }
`;

const FileBtn = styled(Button)`
  width:35px;
  height:32px;
  background-image: url(${({ icon }) => icon});
  background-size: 20px;
  background-repeat:no-repeat;
  background-position:center;
  position: absolute;
  top: 7.5px;
  right:${({ right }) => right ? '47.5px' : '7.5px'};
  ${({ theme, right }) => `${theme.mq.desktop}{
    width: 40px;
    height: 40px;
    background-size: 18px;
    right:${right ? '57.5px' : '7.5px'};
  }`}
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const NoItems = styled.p`
  font-size: 13px;
`;

const FilesItems = styled.div`
  width: 100%;
  overflow-y:auto;
`;