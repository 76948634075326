import React from "react";
import Modal from "../../../Shared/Modals/Modal";
import Input from "../../../Shared/Input";
import Button from "../../../Shared/Button";
import { Formik } from "formik";
import styled from "styled-components";

const tutorialsLocalization = {
  calendar: 'Kalendarz',
  patients: 'Pacjenci',
  profile: 'Profil',
}

const SettingsModal = ({
  modalState,
  setModalState,
  tutorials,
  userId,
  updateAuthenticatedUser,
}) => {
  const setAllHandler = (bool = true) => {
    const newObj = {};
    for (const key of Object.keys(tutorialsLocalization)) {
      newObj[key] = bool;
    }
    return newObj;
  },
    getAllValueHandler = (arr) => {
      let result = true;
      arr.forEach(value => {
        if (!value) {
          result = false;
        }
      })
      return result;
    };

  return (
    <Modal
      title="Edycja ustawień"
      show={modalState}
      exitButton={true}
      onCancel={() => setModalState(false)}
    >
      <Formik
        initialValues={{
          tutorials,
          checkAll: getAllValueHandler(Object.values(tutorials)),
        }}
        enableReinitialize
        onSubmit={async ({ tutorials }) => {
          updateAuthenticatedUser({ tutorials }, userId);
          setModalState(false);
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setValues,
          values,
        }) => (
            <Form onSubmit={handleSubmit}>
              <Column>
                <ColumnLabel>Samouczki <span>(jeśli chcesz powtórzyć samouczek odznacz pole)</span></ColumnLabel>
                <StyledCheckbox
                  type='checkbox'
                  variant='primary'
                  checkboxText='Wszystkie'
                  name='checkAll'
                  value={values.checkAll}
                  hideError={true}
                  onChange={() => setValues({
                    'checkAll': !values.checkAll,
                    'tutorials': setAllHandler(!values.checkAll)
                  })}
                />
                <Tutorials>
                  {Object.entries(tutorials).map(([tutorialName], tutorialIndex) => (
                    <StyledCheckbox
                      key={`tutorial_${tutorialName}_${tutorialIndex}`}
                      type='checkbox'
                      variant='primary'
                      checkboxText={tutorialsLocalization[tutorialName]}
                      name={`tutorial_${tutorialName}_${tutorialIndex}`}
                      value={values.tutorials[tutorialName]}
                      hideError={true}
                      onChange={() => setFieldValue(`tutorials[${tutorialName}]`, !values.tutorials[tutorialName])}
                    />
                  ))}
                </Tutorials>
              </Column>
              <CenterBox>
                <Button type="submit" variant="primary">
                  Zapisz zmiany
              </Button>
              </CenterBox>
            </Form>
          )}
      </Formik>
    </Modal>
  );
};

export default SettingsModal;

const Form = styled.form`
  width: 400px;
`;

const CenterBox = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const Column = styled.div``;

const ColumnLabel = styled.span`
  font-weight:600;
  margin-bottom: 5px;
  display: block;
  span{
    font-size:12px;
    font-weight:400;
  }
`;

const Tutorials = styled.div`
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
  max-height:175px;
`;

const StyledCheckbox = styled(Input)`
  margin-bottom:5px;
`;