import React from "react";
import Modal from "../../../Shared/Modals/Modal";
import Input from "../../../Shared/Input";
import Button from "../../../Shared/Button";
import Select from "../../../Shared/Select";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import styled from "styled-components";
import moment from 'moment';

import rejectIcon from '../../../Shared/assets/rejected-icon.svg';
import { ReactComponent as AddIcon } from '../../../Shared/assets/plus-circle-filled.svg';

const tutorialsDisc = {
  dashboard: 'Panel',
  calendar: 'Kalendarz',
  patients: 'Pacjenci',
  examinations: 'Badania',
  documents: 'Dokumenty',
  medsdiagnosis: 'Rozpoznania i leki',
  riskfactors: 'Czynniki ryzyka',
  hospitalizations: 'Hospitalizacje',
  surveys: 'Wywiady',
  profile: 'Profil',
  elements: 'Tworzenie elementu',
  elementsList: 'Lista elementów',
  visit: 'Wizyta',
};

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    if (list) {
      list.forEach(({ name }, index) => {
        const subList = list.filter((_, i) => i !== index);
        if (subList.findIndex(el => el.name === name) !== -1) {
          throw this.createError({
            path: `visitTypes[${index}].name`,
            message,
          });
        }
      })
    }
    return true;
  });
});

const validationSchema = Yup.object().shape({
  visitTypes: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Nazwa jest wymagana'),
        length: Yup.number()
          .required('Czas trawania jest wymagany')
          .min(5, 'Wizyta musi trwać minimum 5 minut.')
          .max(1440, 'Wizyta może trwać maksymalnie 1440 minut.'),
      })
    )
    .unique('Nazwa musi być unikalna', a => a.name)
});

const roundTo5 = (value, max) => {
  let rounded = Math.round(value / 5) * 5;
  if (rounded <= 0) {
    rounded = 5;
  } else if (rounded > max) {
    rounded = max;
  }
  return rounded;
}

const SettingsModal = ({
  modalState,
  setModalState,
  tutorials,
  user,
  updateAuthenticatedUser,
  updateAuthenticatedUserRole,
  roomOptions,
  userRole,
  nextTutorialStep,
}) => {
  const handleSetAll = (bool = true) => {
    const newObj = {};
    for (const key of Object.keys(tutorialsDisc)) {
      newObj[key] = bool;
    }
    return newObj;
  },
    handleGetAllValue = (arr) => {
      let result = true;
      arr.forEach(value => {
        if (!value) {
          result = false;
        }
      })
      return result;
    };

  const handleChangeRoom = (defaultRooms, healthcenter, room) => {
    let updatedDefaultRooms = defaultRooms;
    const changeIndex = defaultRooms.findIndex((el) => el.healthcenter === healthcenter);
    const newItem = {
      healthcenter,
      room
    }
    if (changeIndex !== -1) {
      updatedDefaultRooms[changeIndex] = newItem;
    } else {
      updatedDefaultRooms = [...updatedDefaultRooms, newItem]
    }
    return updatedDefaultRooms;
  };
  return (
    <Modal
      title="Edycja ustawień"
      show={modalState}
      exitButton={true}
      onCancel={() => {
        setModalState(false)
        if (nextTutorialStep) {
          nextTutorialStep();
        }
      }}
    >
      <Formik
        initialValues={{
          tutorials,
          checkAll: handleGetAllValue(Object.values(tutorials)),
          defaultRooms: userRole?.defaultRooms ?? [],
          visitTypes: userRole?.visitTypes,
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async ({ tutorials, defaultRooms, visitTypes }) => {
          updateAuthenticatedUser({ tutorials }, user._id);
          updateAuthenticatedUserRole({ defaultRooms, visitTypes }, userRole._id, user.activeRole)
          setModalState(false);
          toast('Ustawienia zostały zapisane', { type: 'success' });
          if (nextTutorialStep) {
            nextTutorialStep();
          }
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setValues,
          handleChange,
          values,
          errors,
        }) => (
            <Form onSubmit={handleSubmit}>
              <Content>
                <Section id='tutorialsCheckboxes'>
                  <SectionLabel>Samouczki <span>(jeśli chcesz powtórzyć samouczek odznacz pole)</span></SectionLabel>
                  <StyledCheckbox
                    type='checkbox'
                    variant='secondary'
                    checkboxText='Wszystkie'
                    name='checkAll'
                    value={values.checkAll}
                    hideError={true}
                    onChange={() => setValues({
                      ...values,
                      checkAll: !values.checkAll,
                      tutorials: handleSetAll(!values.checkAll)
                    })}
                  />
                  <Tutorials>
                    {Object.entries(tutorials).map(([tutorialName], tutorialIndex) => (
                      <StyledCheckbox
                        key={`tutorial_${tutorialName}_${tutorialIndex}`}
                        type='checkbox'
                        variant='secondary'
                        checkboxText={tutorialsDisc[tutorialName]}
                        name={`tutorial_${tutorialName}_${tutorialIndex}`}
                        value={values.tutorials[tutorialName]}
                        hideError={true}
                        onChange={() => setFieldValue(`tutorials[${tutorialName}]`, !values.tutorials[tutorialName])}
                      />
                    ))}
                  </Tutorials>
                </Section>
                <Section id='defaultRoomsSelects'>
                  <SectionLabel>Domyślne gabinety</SectionLabel>
                  <DefaultRooms>
                    {
                      roomOptions.map(({ healthcenterName, healthcenterId, rooms }) => (
                        <Select
                          label={healthcenterName}
                          name={`defaultRoom_${healthcenterId}`}
                          hideError={true}
                          onChange={({ value }) => setFieldValue(
                            "defaultRooms",
                            handleChangeRoom(values.defaultRooms, healthcenterId, value)
                          )}
                          menuPosition='fixed'
                          options={rooms}
                          value={rooms.find(el => el.value === values?.defaultRooms.find(r => r.healthcenter === healthcenterId)?.room)}
                        />
                      ))
                    }
                  </DefaultRooms>
                </Section>
                <Section id='visitTypes'>
                  <SectionLabel>Typy wizyt</SectionLabel>
                  {values.visitTypes?.length > 0 ?
                    values.visitTypes?.map((type, index) => (
                      <VisitType>
                        <Input
                          type="text"
                          name={`visitTypes[${index}].name`}
                          label="Nazwa"
                          onChange={handleChange}
                          value={values?.visitTypes[index]?.name}
                          error={errors?.visitTypes && errors?.visitTypes[index]?.name}
                        />
                        <Input
                          type="number"
                          name={`visitTypes[${index}].length`}
                          label="Czas trwania (minuty)"
                          onChange={handleChange}
                          onBlur={({ target: { value } }) => {
                            setFieldValue(
                              `visitTypes[${index}].length`,
                              roundTo5(value, 1440)
                            )
                          }}
                          value={values?.visitTypes[index]?.length}
                        />
                        <Actions>
                          <RoundDeleteBtn
                            type='button'
                            title='Usuń typ wizyty'
                            onClick={() => {
                              setFieldValue(
                                `visitTypes`,
                                [...values.visitTypes].filter((_, i) => i !== index)
                              );
                            }}
                          />
                          {index + 1 === values.visitTypes.length && <AddBtn
                            type='button'
                            title='Dodaj typ wizyty'
                            className='addTypeBtn'
                            onClick={() => {
                              setFieldValue('visitTypes', [...values.visitTypes, {
                                name: `Typ ${values.visitTypes.length + 1}`,
                                length: 30,
                              }]);
                            }}
                          />}
                        </Actions>
                      </VisitType>
                    ))
                    :
                    <NoTypesBox>
                      <p>Brak typów wizyt</p>
                      <Button
                        variant='secondary'
                        onClick={() => {
                          setFieldValue('visitTypes', [{
                            name: `Typ 1`,
                            length: 30,
                          }]);
                        }}
                      >Dodaj</Button>
                    </NoTypesBox>
                  }
                </Section>
              </Content>
              <CenterBox>
                <Button type="submit" variant="primary">
                  Zapisz zmiany
                </Button>
              </CenterBox>
            </Form>
          )}
      </Formik>
    </Modal>
  );
};

export default SettingsModal;

const Form = styled.form`
  width: 550px;
  div[class$='-control'] + div{
    top:inherit!important;
    left:inherit!important;
  }
`;

const Content = styled.div`
  max-height:calc(90vh - 200px);
  overflow-y:auto;
  overflow-X:hidden;
`;

const CenterBox = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const GridWrapper = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
`;

const Section = styled.div`
  margin-bottom:10px;
`;

const SectionLabel = styled.span`
  font-weight:600;
  margin-bottom: 5px;
  display: block;
  span{
    font-size:12px;
    /* display: block; */
    font-weight:400;
  }
`;

const Tutorials = styled.div`
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
  max-height:120px;
`;

const StyledCheckbox = styled(Input)`
  margin-bottom:5px;
  label{
    max-width:160px
  }
`;

const VisitType = styled.div`
  display:grid;
  grid-template-columns: 1fr 150px 60px;
  gap: 10px; 
`;

const NoTypesBox = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  padding: 15px 0;
  button{
    margin-top:10px;
  }
`;

const RoundDeleteBtn = styled.button`
    height:20px;
    width:20px;
    background-color:#d9d9d9;
    background-image: url(${rejectIcon});
    background-position:center;
    background-size:10px;
    background-repeat:no-repeat;
    border:none;
    border-radius:50%;
    cursor:pointer;
    transition:300ms;
    &:hover{
        background-color:${({ theme }) => theme.danger};
    }
`;

const AddBtn = styled(AddIcon)`
    cursor:pointer;
    path{
        fill:${({ theme }) => theme.secondary};
    }
`;

const Actions = styled.div`
  display:flex;
  align-items:center;
`;

const DefaultRooms = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;