import React, { useState, useEffect } from 'react';
import Modal from '../../Shared/Modals/Modal';
import Select from '../../Shared/Select';
import Button from '../../Shared/Button';
import styled from 'styled-components';
import AvatarPlaceholder from "../../Shared/assets/avatar.svg";

const EmployeesModal = ({
    employeesModalState: { target, shown },
    setEmployeesModalState,
    selectedHealthcenter,
    updateHealthcenter,
    doctors,
    doctorsOfSelectedHealthcenter,
    nurses,
    nursesOfSelectedHealthcenter,
    registries,
    registriesOfSelectedHealthcenter,
    groups,
}) => {
    const [listItems, setListItems] = useState([]),
        [selectOptions, setSelectOptions] = useState([]),
        [allElements, setAllElements] = useState([]);

    const checkIfAlready = (id) => {
        const index = listItems.findIndex((el) => {
            return el._id === id;
        });
        if (index === -1) {
            return false;
        } else {
            return true;
        }
    },
        mapSelectOptions = (array) => {
            setSelectOptions(array.map(({ _id, userId: { name, surname } }) => {
                return { label: `${name} ${surname}`, value: _id, isDisabled: checkIfAlready(_id) }
            }));
        },
        addToListHandler = (id) => {
            if (!checkIfAlready(id)) {
                const newList = allElements.find((el) => {
                    return el._id === id
                });
                setListItems((prev) => (
                    [...prev,
                        newList]
                ));
            }
        },
        removeFromListHandler = (id) => {
            setListItems((prev) => (
                prev.filter(el => (
                    el._id !== id
                ))
            ));
        },
        submitHandler = () => {
            const newData = listItems.map(el => {
                return el._id
            })
            let updatedGroups = groups;
            if (target === 'nurses' || target === 'doctors') {
                updatedGroups = groups.map((group) => ({
                    ...group,
                    [target]: group[target].filter(id => newData.includes(id))
                }));
            }
            updateHealthcenter(selectedHealthcenter._id, { [target]: [...newData], groups: updatedGroups });
            setEmployeesModalState({ target: null, shown: false });
        };

    useEffect(() => {
        switch (target) {
            case 'doctors':
                setAllElements(doctors);
                setListItems(doctorsOfSelectedHealthcenter);
                break;
            case 'registries':
                setAllElements(registries);
                setListItems(registriesOfSelectedHealthcenter);
                break;
            case 'nurses':
                setAllElements(nurses);
                setListItems(nursesOfSelectedHealthcenter);
                break;
            default:
                return setListItems([]);
        }
    }, [doctorsOfSelectedHealthcenter, registriesOfSelectedHealthcenter, nursesOfSelectedHealthcenter, target]);

    useEffect(() => {
        mapSelectOptions(allElements);
    }, [allElements, listItems])

    return (
        <Modal
            show={shown}
            exitButton={true}
            onCancel={() => setEmployeesModalState({ target: null, shown: false })}
            title={
                target === 'doctors' ?
                    'Edycja lekarzy'
                    :
                    target === 'rejestrators' ?
                        'Edycja rejestratorów'
                        :
                        target === 'nurses' ?
                            'Edycja pielęgniarek'
                            :
                            target === 'rooms' ?
                                'Edycja pokoi'
                                :
                                'Edycja'
            }>
            <Wrapper>
                <Select center value='' placeholder='Dodaj pracownika z listy...' options={selectOptions} onChange={({ value }) => { addToListHandler(value) }} />
                <List>
                    {target !== null &&
                        listItems.map(({ _id, userId: { name, surname, avatar } }) => {
                            return <ListItem key={_id}>
                                <Avatar src={!avatar ? AvatarPlaceholder : `${process.env.REACT_APP_SRV_URL}${avatar}`} />
                                <Text>{name} {surname}</Text>
                                <Remove onClick={() => removeFromListHandler(_id)} />
                            </ListItem>
                        })
                    }
                </List>
                <CenterBox>
                    <Button onClick={submitHandler} type='submit' variant='primary'>Zapisz zmiany</Button>
                </CenterBox>
            </Wrapper>
        </Modal>
    )
}

export default EmployeesModal;

const Wrapper = styled.div`
    width:300px;
`;
const CenterBox = styled.div`
    margin-top:40px;
    text-align:center;
`;

const List = styled.ul`
    margin:0;
`;

const ListItem = styled.li`
    display: flex;
    align-items:center;
    max-width:240px;
    position:relative;
    border: 1px solid ${({ theme }) => theme.grey};
    padding: 5px 10px;
    border-radius:4px;
    margin: 5px auto;
`;

const Avatar = styled.img`
    width:30px;
    height:30px;
    margin-right:5px;
    border-radius: 50%;
`;

const Text = styled.p``;

const Remove = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 20px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;