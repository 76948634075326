import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Button from "../../Shared/Button";
import Modal from "../../Shared/Modals/Modal";
import Input from "../../Shared/Input";
import styled from "styled-components";
const AddUserModal = ({
  isCreateModalOn,
  setCreateModal,
  userRole,
  registerEmployee,
  restoreUser,
}) => {
  const [employeeType, setEmployeeType] = useState("doctor"),
    [restoreModal, setRestoreModal] = useState({ shown: false, user: null });

  return (
    <Modal
      show={isCreateModalOn}
      exitButton={true}
      onCancel={() => setCreateModal(false)}
      title="Dodawanie użytkownika"
    >
      <Modal
        testId='user_restore_modal'
        type="SimpleQuestionModal"
        show={restoreModal.shown}
        question={"Użytkownik o takim emailu już istnieje ale został usunięty. Czy chcesz go przywrócić?"}
        acceptText="Tak"
        cancelText="Nie"
        onAccept={() => {
          restoreUser(restoreModal.user);
          setRestoreModal({ shown: false, user: null });
          setCreateModal(false);
        }}
        onCancel={() => setRestoreModal({ shown: false, user: null })}
      />
      <Formik
        initialValues={{
          name: "",
          surname: "",
          email: "",
          tel: "",
          password: "",
          companyId: userRole?.companyId,
        }}
        validationSchema={AddUserSchema}
        onSubmit={async (values, actions) => {
          try {
            const userId = await registerEmployee(values, employeeType);
            if (userId) {
              return setRestoreModal({
                shown: true, user: {
                  id: userId,
                  name: values.name,
                  surname: values.surname,
                  password: values.password,
                }
              });
            }
            setCreateModal(false);
            actions.setSubmitting(false);
          } catch (error) {
            actions.setErrors({ email: error });
            actions.setSubmitting(false);
          }
        }}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <FormContent>
              <Input
                type="radio"
                label="Typ pracownika"
                wrapperHeight="100px"
                name="employeeType"
                value={employeeType}
                radioOptions={[
                  { value: "doctor", label: "Doktor" },
                  { value: "registry", label: "Rejestrator" },
                  { value: "nurse", label: "Pielęgniarka" },
                ]}
                onChange={(field, value) => setEmployeeType(value)}
              />
              <DataInputs>
                <Input
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.name}
                  name="name"
                  label="Imię"
                  error={props.errors.name}
                />
                <Input
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values?.surname}
                  name="surname"
                  label="Nazwisko"
                  error={props.errors?.surname}
                />
                <Input
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                  name="email"
                  label="Email"
                  error={props.errors.email}
                />
                <Input
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.tel}
                  name="tel"
                  label="Numer telefonu"
                  error={props.errors.tel}
                />
                <Input
                  type="password"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.password}
                  name="password"
                  label="Hasło"
                  error={props.errors.password}
                />
              </DataInputs>
            </FormContent>
            <SubmitButton type="submit" variant="primary">
              Dodaj użytkownika
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const AddUserSchema = Yup.object().shape({
  name: Yup.string().required("Imię jest wymagane"),
  surname: Yup.string().required("Nazwisko jest wymagane"),
  email: Yup.string()
    .email("Nieprawidłowy email!")
    .required("Email jest wymagany"),
  tel: Yup.string()
    .min(9, "Numer musi mieć minimum 9 cyfr"),
  password: Yup.string()
    .min(2, "Hasło za krótkie!")
    .max(50, "Hasło za długie!")
    .required("Hasło jest wymagane"),
});

export default AddUserModal;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const DataInputs = styled.div`
  display:grid;
  width: 400px;
  column-gap:20px;
  grid-template-columns: 1fr 1fr;
`;

const FormContent = styled.div`
  display: flex;
  >div:first-child{
    width:200px;
  }
`;

const SubmitButton = styled(Button)`
  margin-right: 0;
  margin-left: auto;
`;
