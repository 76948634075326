import React from "react";
import styled, { css } from "styled-components";
import moment from 'moment';
import Button from "../../Shared/Button";
import Container from "../../Shared/Container";
import { Link } from "react-router-dom";
import PatientInfoHeader from "../../Shared/PatientInfoHeader";
import { ReactComponent as VisitsTodayIcon } from "../../Shared/assets/visits-today.svg";
import { ReactComponent as VisitsLeftIcon } from "../../Shared/assets/visits-left.svg";
import { ReactComponent as VisitsRejectedIcon } from "../../Shared/assets/visits-rejected.svg";
import DocumentItem from "../../Shared/DocumentItem";
import Gallery from "../../Shared/Gallery";
import Modal from "../../Shared/Modals/Modal";
import EditVisitDoctorDashboardModal from '../../../features/Sidebar/DoctorSidebar/Dashboard/modals/EditVisitDoctorDashboardModal';
import NoAppointmentImg from "../../Shared/assets/no-appointment.svg";
import NoDataImg from "../../Shared/assets/no-data.svg";
import HealthcenterIcon from "../../Shared/assets/hospital.svg";
import CalendarIcon from "../../Shared/assets/calendar.svg";
import ClockIcon from "../../Shared/assets/clock.svg";
import RoomIcon from "../../Shared/assets/room.svg";
import StatusIcon from "../../Shared/assets/status.svg";
import { truncateText } from "../../Shared/utils";

const DoctorDashboardView = ({
  currentPatient = {},
  currentVisit,
  updateVisit,
  visits,
  healthcenters,
  currentPatientVisits,
  selectedHistoryVisit,
  getHistoryVisitDetails,
  historyMedicalVisit,
  questionModal,
  setQuestionModal,
  history,
  addActiveVisit,
  removeActiveVisit,
  patients,
  editVisitModalState,
  setEditVisitModalState,
  user,
  userRole,
  deleteVisit,
  getCompanyPatientsHandler
}) => {
  const {
    name,
    surname,
    companyName,
    pesel,
    sex,
    tel,
    email,
    additionalInfo,
    birthDate,
    address,
    avatar,
    deleted,
    _id,
  } = currentPatient;

  const pdfs = historyMedicalVisit?.documents?.filter(
    ({ path }) => !path.includes("photos")
  ),
    imgs = historyMedicalVisit?.documents?.filter(({ path }) =>
      path.includes("photos")
    ),
    galleryImages = imgs?.map(({ path, _id, originalName }) => ({
      name: originalName,
      src: `${process.env.REACT_APP_SRV_URL}${path}`,
      _id,
    }));

  const visitStatePolishDict = (visitState) => {
    switch (visitState) {
      case "PLANNED":
        return "Zaplanowana";
      case "CONFIRMED":
        return "Potwierdzona";
      case "WAITING":
        return "W poczekalni";
      case "STARTED":
        return "W trakcie";
      case "ENDED":
        return "Zakończona";
      case "REJECTED":
        return "Odwołana";
    }
  };
  const currentHealthcenter = currentVisit?.healthcenterId?._id ?
    healthcenters.find(
      (healthcenter) => healthcenter._id === currentVisit?.healthcenterId?._id
    ) ?? {}
    :
    healthcenters.find(
      (healthcenter) => healthcenter._id === currentVisit?.healthcenterId
    ) ?? {};

  const startMoment = moment(currentVisit?.start),
    endMoment = moment(currentVisit?.end);
  return (
    <Wrapper>
      {visits.length === 0 ? (
        <NoVisitsText>
          <NoAppointmentPlaceholder src={NoAppointmentImg} />
          <NoAppointmentText>
            Brak zaplanowanych wizyt na ten dzień
          </NoAppointmentText>
        </NoVisitsText>
      ) : (
          <>
            <Modal
              type="SimpleQuestionModal"
              show={questionModal.shown}
              question={questionModal.question}
              acceptText="Tak"
              cancelText="Nie"
              onAccept={() => {
                questionModal.onAccept();
                setQuestionModal({ shown: false, question: '', onAccept: null })
              }}
              onCancel={() => setQuestionModal({ shown: false, question: '', onAccept: null })}
            />
            <EditVisitDoctorDashboardModal
              patients={patients}
              isEditModalOn={editVisitModalState}
              setEditModal={setEditVisitModalState}
              currentHealthcenter={currentHealthcenter}
              selectedEvent={currentVisit}
              updateVisit={updateVisit}
              user={user}
              userRole={userRole}
              setWarningModal={setQuestionModal}
              deleteVisit={deleteVisit}
              getCompanyPatientsHandler={getCompanyPatientsHandler}
            />
            <RowWrapper>
              <StyledContainer title="Wybrana wizyta" width="65%">
                <StyledPatientInfoHeader
                  patientInfo={currentPatient}
                />
                {currentVisit && (
                  <FlexContainer id='visitDataContainer'>
                    <ContainerVisitData>
                      <VisitPart>
                        <VisitDataIcon src={CalendarIcon} />
                        <VisitData>{startMoment.format('DD.MM.YYYY')}</VisitData>
                      </VisitPart>
                      <VisitPart>
                        <VisitDataIcon src={ClockIcon} />
                        <VisitData>
                          {`${startMoment.format('HH:mm')} - ${endMoment.format('HH:mm')}`}
                        </VisitData>
                      </VisitPart>
                      <VisitPart>
                        <VisitDataIcon src={HealthcenterIcon} />
                        <VisitData>
                          {currentVisit.healthcenterId?._id ? currentVisit.healthcenterId.name : healthcenters &&
                            healthcenters.find(
                              (healthcenter) =>
                                healthcenter._id === currentVisit.healthcenterId
                            )?.name}
                        </VisitData>
                      </VisitPart>
                      <VisitPart>
                        <VisitDataIcon src={RoomIcon} />
                        <VisitData>
                          {currentVisit.room ? currentVisit.room?.name ?
                            currentVisit.room?.name : currentHealthcenter.rooms?.find(
                              room => room._id === currentVisit.room
                            )?.name : 'Brak przypisanego gabinetu'}
                        </VisitData>
                      </VisitPart>
                      <VisitPart>
                        <VisitDataIcon src={StatusIcon} />
                        <VisitData>
                          {visitStatePolishDict(currentVisit.state)}
                        </VisitData>
                      </VisitPart>
                    </ContainerVisitData>
                    <ContainerVisitData>
                      <EditVisitButton
                        variant='edit'
                        title='Edytuj wizytę'
                        onClick={() => setEditVisitModalState(true)}
                      />
                      <VisitPart>
                        <VisitPartTitle>Typ wizyty: </VisitPartTitle>
                        <VisitData>{currentVisit.visitType?.name ?? 'Domyślna'}</VisitData>
                      </VisitPart>
                      <VisitDescriptionPart>
                        <VisitPartTitle>Dodatkowy opis:</VisitPartTitle>
                        <VisitData>
                          {currentVisit.description
                            ? truncateText(currentVisit.description, 140)
                            : "Brak opisu"}
                        </VisitData>
                      </VisitDescriptionPart>
                    </ContainerVisitData>
                  </FlexContainer>
                )}
                {currentVisit && (
                  <VisitItemDetailsActions id="visitActions">
                    {currentVisit.state === "STARTED" && (
                      <StyledLink to={`visit/${currentVisit._id}/`}>
                        <Button variant="secondary">Kontynuuj wizytę</Button>
                      </StyledLink>
                    )}
                    {currentVisit.state !== "STARTED" &&
                      currentVisit.state !== "ENDED" && (
                        <StyledLink
                          to={
                            Object.keys(currentPatient).length < 10
                              ? `/`
                              : `visit/${currentVisit._id}/`
                          }
                          onClick={() =>
                            Object.keys(currentPatient).length < 10 &&
                            setQuestionModal({
                              shown: true, question: 'Rozpoczynasz wizytę pacjenta, który nie ma wypełnionych wszystkich podstawowych informacji, czy na pewno chcesz ją rozpocząć?', onAccept: () => {
                                updateVisit(currentVisit._id, "started");
                                addActiveVisit(currentVisit);
                                history.push(`visit/${currentVisit._id}/`);
                              }
                            })
                          }
                        >
                          <Button
                            onClick={() =>
                              !(Object.keys(currentPatient).length < 10) &&
                              updateVisit(currentVisit._id, "started") &&
                              addActiveVisit(currentVisit)
                            }
                            variant="secondary"
                          >
                            Rozpocznij wizytę
                        </Button>
                        </StyledLink>
                      )}
                    {currentVisit.state === "STARTED" && (
                      <Button
                        onClick={() =>
                          setQuestionModal({
                            shown: true,
                            question: 'Czy na pewno chcesz zakończyć tą wizytę?',
                            onAccept: () => {
                              updateVisit(currentVisit._id, "ended");
                              removeActiveVisit(currentVisit._id);
                            }
                          })
                        }

                        variant="danger"
                      >
                        Zakończ wizytę
                      </Button>
                    )}
                    {currentVisit.state !== "REJECTED" &&
                      currentVisit.state !== "STARTED" &&
                      currentVisit.state !== "ENDED" && (
                        <Button
                          onClick={() =>
                            setQuestionModal({
                              shown: true,
                              question: 'Czy na pewno chcesz odwołać tą wizytę?',
                              onAccept: () => updateVisit(currentVisit._id, "rejected")
                            })
                          }
                          variant="danger"
                        >
                          Odwołaj wizytę
                        </Button>
                      )}
                    {currentVisit.state === "ENDED" && (
                      <>
                        <Button
                          variant="primary"
                          onClick={() => history.push(`visit/${currentVisit._id}/`)}
                        >
                          Zobacz wizytę
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => history.push(`visit/${currentVisit._id}/?edit`)}
                        >
                          Edytuj wizytę
                      </Button>
                      </>
                    )}
                  </VisitItemDetailsActions>
                )}
              </StyledContainer>

              <StyledHalfContainer title="Dzisiejsze statystyki">
                <StatsContainer id='statsContainer'>
                  <StatsItem>
                    <StyledVisitsTodayIcon />
                    <TodayNumber>{visits.length}</TodayNumber>
                    <TodayText>Dzisiejsze wizyty</TodayText>
                  </StatsItem>
                  <StatsItem>
                    <StyledVisitsLeftIcon />
                    <LeftNumber>
                      {
                        visits.filter(
                          (visit) =>
                            visit.state !== "ENDED" &&
                            visit.state !== "REJECTED"
                        ).length
                      }
                    </LeftNumber>
                    <LeftText>Pozostało wizyt</LeftText>
                  </StatsItem>
                  <StatsItem>
                    <StyledVisitsRejectedIcon />
                    <RejectedNumber>
                      {
                        visits.filter((visit) => visit.state === "REJECTED")
                          .length
                      }
                    </RejectedNumber>
                    <RejectedText>Odwołane wizyty</RejectedText>
                  </StatsItem>
                </StatsContainer>
              </StyledHalfContainer>
            </RowWrapper>
            <RowWrapper id="visitsHistoryContainer">
              <StyledContainer width="100%" title="Historia wizyt">
                <FlexContainer>
                  <TimelineContainer id="visitsHistoryTimelineContainer">
                    {currentPatientVisits
                      ?.sort((a, b) => new Date(b.start) - new Date(a.start))
                      .map((visit) => (
                        <VisitHistoryTimelineItem
                          key={visit._id}
                          onClick={() => getHistoryVisitDetails(visit)}
                        >
                          <VisitData
                            active={
                              selectedHistoryVisit === visit._id ? true : false
                            }
                          >{moment(visit.start).format('DD.MM.YYYY')}</VisitData>
                          <VisitTimelineCircle
                            active={
                              selectedHistoryVisit === visit._id ? true : false
                            }
                          ></VisitTimelineCircle>
                          <VisitTimeline></VisitTimeline>
                          <VisitTimelineState
                            active={
                              selectedHistoryVisit === visit._id ? true : false
                            }
                          >
                            <VisitTimelineStateText
                              active={
                                selectedHistoryVisit === visit._id ? true : false
                              }
                            >
                              {visitStatePolishDict(visit.state)}
                            </VisitTimelineStateText>
                          </VisitTimelineState>
                        </VisitHistoryTimelineItem>
                      ))}
                  </TimelineContainer>
                  <MedicalDataContainer id="visitsHistoryMedicalDataContainer">
                    {historyMedicalVisit ? (
                      <FlexInnerContainer>
                        <PreviousVisitContainer>
                          <div>
                            <VisitPartTitle>Wywiady</VisitPartTitle>
                            <SummaryText>
                              {
                                historyMedicalVisit.stagesTexts
                                  ?.medicalIntelligence
                              }
                            </SummaryText>
                          </div>
                          <div>
                            <VisitPartTitle>Badanie fizykalne</VisitPartTitle>
                            <SummaryText>
                              {
                                historyMedicalVisit.stagesTexts
                                  ?.physicalExaminations
                              }
                            </SummaryText>
                          </div>
                          <div>
                            <VisitPartTitle>Badanie Dodatkowe</VisitPartTitle>
                            <SummaryText>
                              {
                                historyMedicalVisit.stagesTexts
                                  ?.additionalExaminations
                              }
                            </SummaryText>
                          </div>
                          <div>
                            <VisitPartTitle>Rozpoznanie</VisitPartTitle>
                            <SummaryText>
                              {historyMedicalVisit.stagesTexts?.diagnosis}
                            </SummaryText>
                          </div>
                          <div>
                            <VisitPartTitle>Zalecenia i leki</VisitPartTitle>
                            <SummaryText>
                              {historyMedicalVisit.stagesTexts?.recommendations}
                            </SummaryText>
                          </div>
                        </PreviousVisitContainer>
                        <DocsContainer>
                          <DocsSection>
                            <VisitPartTitle>Dokumenty</VisitPartTitle>
                            {pdfs?.length > 0 ? (
                              <List>
                                {pdfs.map((doc) => (
                                  <DocumentItem
                                    key={doc._id}
                                    documentData={doc}
                                    padding="none"
                                    variant="dashboard"
                                  />
                                ))}
                              </List>
                            ) : (
                                <SummaryText>
                                  Brak dokumentów dla tej wizyty
                                </SummaryText>
                              )}
                          </DocsSection>
                          <DocsSection>
                            <VisitPartTitle>Multimedia</VisitPartTitle>
                            <List>
                              {imgs?.length > 0 ? (
                                <Gallery
                                  images={galleryImages}
                                  variant="dashboard"
                                />
                              ) : (
                                  <SummaryText>
                                    Brak multimediów dla tego pacjenta lub wizyty
                                  </SummaryText>
                                )}
                            </List>
                          </DocsSection>
                        </DocsContainer>
                      </FlexInnerContainer>
                    ) : (
                        <NoDataContainer>
                          <NoDataPlaceholder src={NoDataImg} />
                          <span>Ta wizyta nie zawiera danych medycznych</span>
                        </NoDataContainer>
                      )}
                  </MedicalDataContainer>
                </FlexContainer>
              </StyledContainer>
            </RowWrapper>
          </>
        )}
    </Wrapper>
  );
};

export default DoctorDashboardView;

const VisitDataIcon = styled.img`
  width: 25px;
  height: 25px;
  filter: invert(27%) sepia(0%) saturate(8%) hue-rotate(207deg) brightness(92%)
    contrast(87%);
  margin-right: 10px;
`;

const StyledPatientInfoHeader = styled(PatientInfoHeader)`
  padding: 0 10px 10px 0;
  flex-direction: column;
  ${({ theme }) => `${theme.mq.large}{
    padding: 0 30px 10px 0;
    flex-direction: row;
  }`}
  >button{
    top:0;
    right:0;
  }
`;

const DocsSection = styled.div``;
const NoAppointmentPlaceholder = styled.img`
  max-width: 500px;
  width: 80%;
`;
const NoAppointmentText = styled.p`
font-size: 22px;
    margin-top: 20px;
`;
const NoDataPlaceholder = styled.img`
  max-width: 200px;
`;

const List = styled.ul`
  overflow: scroll;
  min-height: 80px;
  margin: 0;
`;

const DocsContainer = styled.div`
  padding: 0 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const VisitPart = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const VisitDescriptionPart = styled.div`
  display: flex;
  flex-direction: column;
`;

const MedicalDataContainer = styled.div`
  width: 100%;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const VisitTimeline = styled.div`
  width: 3px;
  height: 50px;
  position: absolute;
  background-color: #f0f5ff;
  top: 0px;
  left: 89px;
  z-index: 1;
`;

const VisitTimelineStateText = styled.span`
  color: #1890ff;
  ${({ active }) => active && `
  color: #fff;
  `}
`;

const VisitTimelineCircle = styled.div`
  min-width: 11px;
  min-height: 11px;
  background-color: #f0f5ff;
  border-radius: 50%;
  border: 1px solid #1890ff;
  margin-left: 5px;
  position: relative;
  z-index: 2;
  ${({ active }) => active && `
  background-color: #1890FF;
  `}
`;

const VisitTimelineState = styled.div`
  height: 45px;
  background-color: #f0f5ff;
  padding: 5px 10px;
  min-width: 125px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: relative;
  transition: background-color 0.2s, border-right 0.2s;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 200px;
  }`}
  &:hover {
    background-color: #f3f7ff;
    &:before {
      border-right: 14px solid #f3f7ff;
    }
    ${({ active }) =>
    active &&
    `background-color: #2898ff;
    &:before {
      border-right:14px solid #2898ff;
    }`}
  }
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 14px solid #f0f5ff;
    position: absolute;
    top: 11;
    left: -12px;
    transition: background-color 0.2s, border-right 0.2s;
  }
  ${({ active }) =>
    active &&
    `background-color: #1890FF;
    &:before {
      border-right:14px solid #1890FF;
    }`}
`;
const VisitHistoryTimelineItem = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const Timeline = styled.div`
  height: 100%;
  border-right: 3px solid #f0f5ff;
  width: 2px;
`;

const TimelineContainer = styled.div`
  border-right: 1px solid #e6e6e6;
  padding: 0 20px 0 0;
  overflow: scroll;
  min-width: 250px;
  ${({ theme }) => `${theme.mq.desktop}{
    min-width: 330px;
  }`}
`;

const RowWrapper = styled.div`
  display: flex;
  max-height: calc(50vh - 33px);
`;
const FlexInnerContainer = styled.div`
  display: flex;
  height: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  max-height: 95%;
`;

const ContainerVisitData = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  position:relative;
`;

const VisitData = styled.span`
  font-size: 14px;
  word-break: break-word;
  ${({ active }) =>
    active &&
    `
font-weight: 600;
`}
`;

const NoVisitsText = styled.div`
  font-size: 40px;
  margin: 100px auto;
  text-align: center;
`;

const TodayNumber = styled.span`
  font-size: 36px;
  color: #1890ff;
  margin-left: 5px;
  ${({ theme }) => `${theme.mq.huge}{
    flex-direction:40px;
  }`}
`;

const TodayText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;
`;

const LeftNumber = styled.span`
  font-size: 36px;
  color: #57c754;
  margin-left: 5px;
  ${({ theme }) => `${theme.mq.huge}{
    flex-direction:40px;
  }`}
`;

const LeftText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #57c754;
`;

const RejectedNumber = styled.span`
  font-size: 36px;
  color: #c75454;
  margin-left: 5px;
  ${({ theme }) => `${theme.mq.huge}{
    flex-direction:40px;
  }`}
`;

const RejectedText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #c75454;
`;

const SummaryText = styled.p`
  white-space: break-spaces;
  font-size: 12px;
`;

const VisitPartTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  padding-right:5px;
`;

const StatsItem = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #f0f5ff;
  margin: 10px;
  ${({ theme }) => `${theme.mq.huge}{
    width: 33%;
  }`}
`;
const Wrapper = styled.div`
  width: 100%;
  max-width:1240px;
  margin:0 auto;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const PreviousVisitContainer = styled.div`
  padding: 0 15px;
  width: 50%;
  border-right: 1px solid #e6e6e6;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledContainer = styled(Container)`
  ${({ width }) => width && `width: ${width};`}
  position:relative;
  max-height: calc(50vh - 56px);
`;

const StyledHalfContainer = styled(Container)`
  position: relative;
  width: 35%;
`;
const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  ${({ theme }) => `${theme.mq.huge}{
    flex-direction:row;
  }`}
`;

const VisitItemDetailsActions = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 10px;
  button:last-child {
    margin-left: 6px;
  }
  ${({ theme }) => `${theme.mq.desktop}{
    right: 20px;
    button:last-child {
      margin-left: 12px;
    }
  }`}
`;

const iconStatsStyles = css`
    height: 35px;
    width: 35px;
    margin-right:10px;
`;

const StyledVisitsTodayIcon = styled(VisitsTodayIcon)`
${iconStatsStyles};
path {
  fill: ${({ theme }) => theme.primary};
}
`;

const StyledVisitsLeftIcon = styled(VisitsLeftIcon)`
${iconStatsStyles};
path {
  fill: #57c754;
}
`;

const StyledVisitsRejectedIcon = styled(VisitsRejectedIcon)`
${iconStatsStyles};
path {
  fill: ${({ theme }) => theme.danger};
}
`;

const EditVisitButton = styled(Button)`
  top:-5px;
  right:0;
`;