import React, { useState, useEffect } from "react";
import ReceptionistPatientInfoView from "./ReceptionistPatientInfoView";
import { connect } from "react-redux";
import { getCompany } from '../../../redux/modules/company';
import { getCompanyPatients } from "../../../redux/modules/patients";
import { withRouter } from 'react-router-dom';

const ReceptionistPatientInfoContainer = ({
  currentPatient,
  company,
  getCompanyPatients,
  userRole,
}) => {
  const [deleteModalState, setDeleteModalState] = useState(false);

  useEffect(() => {
    if (userRole?.companyId) {
      getCompanyPatients(userRole.companyId, 10, 1, "");
    }
  }, [])

  useEffect(() => {
    if (userRole?.companyId) {
      getCompanyPatients(userRole.companyId, 10, 1, "");
    }
  }, [userRole])

  return <ReceptionistPatientInfoView
    currentPatient={{ ...currentPatient, companyName: company?.name }}
    deleteModalState={deleteModalState}
    setDeleteModalState={setDeleteModalState}
  />;
};

const mapStateToProps = state => ({
  currentPatient: state.patients.currentPatient,
  company: state.company.data,
  currentPatientVisits: state.visits.data,
  userRole: state.authentication.userRole,
});

const mapDispatchToProps = dispatch => {
  return {
    getCompanyPatients: (companyId, pageSize, pageNumber, searchTerm) =>
      dispatch(getCompanyPatients(companyId, pageSize, pageNumber, searchTerm)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReceptionistPatientInfoContainer));
