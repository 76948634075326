import axios from "axios";

const REGISTER_EMPLOYEE_REQUEST = "REGISTER_EMPLOYEE_REQUEST";
const REGISTER_EMPLOYEE_FAIL = "REGISTER_EMPLOYEE_FAIL";
const REGISTER_DOCTOR_SUCCESS = "REGISTER_DOCTOR_SUCCESS";
const REGISTER_REGISTRY_SUCCESS = "REGISTER_REGISTRY_SUCCESS";
const REGISTER_NURSE_SUCCESS = "REGISTER_NURSE_SUCCESS";

const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST";
const GET_DOCTORS_SUCCESS = "GET_DOCTORS_SUCCESS";
const GET_REGISTRIES_SUCCESS = "GET_REGISTRIES_SUCCESS";
const GET_NURSES_SUCCESS = "GET_NURSES_SUCCESS";
const GET_EMPLOYEES_FAIL = "GET_EMPLOYEES_FAIL";

const GET_EMPLOYEE_VACATIONS_REQUEST = "GET_EMPLOYEE_VACATIONS_REQUEST";
const GET_EMPLOYEE_VACATIONS_FAIL = "GET_EMPLOYEE_VACATIONS_FAIL";
const GET_EMPLOYEE_VACATIONS_SUCCESS = "GET_EMPLOYEE_VACATIONS_SUCCESS";

const UPDATE_DOCTOR_DETAIL_REQUEST = "UPDATE_DOCTOR_DETAIL_REQUEST";
const UPDATE_DOCTOR_DETAIL_SUCCESS = "UPDATE_DOCTOR_DETAIL_SUCCESS";
const UPDATE_DOCTOR_DETAIL_FAIL = "UPDATE_DOCTOR_DETAIL_FAIL";

const UPDATE_NURSE_DETAIL_REQUEST = "UPDATE_NURSE_DETAIL_REQUEST";
const UPDATE_NURSE_DETAIL_SUCCESS = "UPDATE_NURSE_DETAIL_SUCCESS";
const UPDATE_NURSE_DETAIL_FAIL = "UPDATE_NURSE_DETAIL_FAIL";

const UPDATE_REGISTRY_DETAIL_REQUEST = "UPDATE_REGISTRY_DETAIL_REQUEST";
const UPDATE_REGISTRY_DETAIL_SUCCESS = "UPDATE_REGISTRY_DETAIL_SUCCESS";
const UPDATE_REGISTRY_DETAIL_FAIL = "UPDATE_REGISTRY_DETAIL_FAIL";

const UPDATE_USER_DETAIL_REQUEST = "UPDATE_USER_DETAIL_REQUEST";
const UPDATE_USER_DETAIL_SUCCESS = "UPDATE_USER_DETAIL_SUCCESS";
const UPDATE_USER_DETAIL_FAIL = "UPDATE_USER_DETAIL_FAIL";

const DELETE_USER_VACATIONS_REQUEST = "DELETE_USER_VACATIONS_REQUEST";
const DELETE_USER_VACATIONS_SUCCESS = "DELETE_USER_VACATIONS_SUCCESS";
const DELETE_USER_VACATIONS_FAIL = "DELETE_USER_VACATIONS_FAIL";

const ADD_USER_VACATIONS_REQUEST = "ADD_USER_VACATIONS_REQUEST";
const ADD_USER_VACATIONS_SUCCESS = "ADD_USER_VACATIONS_SUCCESS";
const ADD_USER_VACATIONS_FAIL = "ADD_USER_VACATIONS_FAIL";

const ADD_USER_VACATION_ID = "ADD_USER_VACATION_ID";

const GET_USER_VACATIONS_USED_DAYS_REQUEST =
  "GET_USER_VACATIONS_USED_DAYS_REQUEST";
const GET_USER_VACATIONS_USED_DAYS_SUCCESS =
  "GET_USER_VACATIONS_USED_DAYS_SUCCESS";
const GET_USER_VACATIONS_USED_DAYS_FAIL = "GET_USER_VACATIONS_USED_DAYS_FAIL";

const SET_INITIAL_EMPLOYEE = "SET_INITIAL_EMPLOYEE";
const RESET_EMPLOYEES_ERROR = "RESET_EMPLOYEES_ERROR";

const UPLOAD_USER_CERTIFICATES_REQUEST = "UPLOAD_USER_CERTIFICATES_REQUEST";
const UPLOAD_USER_CERTIFICATES_SUCCESS = "UPLOAD_USER_CERTIFICATES_SUCCESS";
const UPLOAD_USER_CERTIFICATES_FAIL = "UPLOAD_USER_CERTIFICATES_FAIL";

const DELETE_USER_CERTIFICATES_REQUEST = "DELETE_USER_CERTIFICATES_REQUEST";
const DELETE_USER_CERTIFICATES_SUCCESS = "DELETE_USER_CERTIFICATES_SUCCESS";
const DELETE_USER_CERTIFICATES_FAIL = "DELETE_USER_CERTIFICATES_FAIL";

const RESTORE_USER_REQUEST = "RESTORE_USER_REQUEST";
const RESTORE_USER_FAIL = "RESTORE_USER_FAIL";

export const setInitialEmployee = () => ({
  type: SET_INITIAL_EMPLOYEE,
});

export const getEmployees = (employeeType, companyId) => async (dispatch) => {
  dispatch(getEmployeesRequest());
  try {
    const response = await axios.get(`/${employeeType}/companyId/${companyId}`);
    switch (employeeType) {
      case "doctor":
        dispatch(getDoctorsSuccess(response.data));
        break;
      case "registry":
        dispatch(getRegistriesSuccess(response.data));
        break;
      case "nurse":
        dispatch(getNursesSuccess(response.data));
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch(getEmployeesFail(error));
  }
};

const getEmployeesRequest = () => ({
  type: GET_EMPLOYEES_REQUEST,
});

const getDoctorsSuccess = (doctors) => ({
  type: GET_DOCTORS_SUCCESS,
  payload: doctors,
});

const getRegistriesSuccess = (registries) => ({
  type: GET_REGISTRIES_SUCCESS,
  payload: registries,
});

const getNursesSuccess = (nurses) => ({
  type: GET_NURSES_SUCCESS,
  payload: nurses,
});

const getEmployeesFail = (error) => ({
  type: GET_EMPLOYEES_FAIL,
  payload: error,
});

export const registerEmployee = (employee, employeeType) => async (
  dispatch
) => {
  dispatch(registerEmployeeRequest());
  try {
    const response = await axios.post(
      `/user/register/${employeeType}`,
      employee,
      {
        validateStatus: function (status) {
          return status <= 300;
        }
      }
    );
    const { data } = response.data;
    if (response.status === 300) {
      return data;
    }
    switch (employeeType) {
      case "doctor":
        dispatch(registerDoctorSuccess(data));
        break;
      case "nurse":
        dispatch(registerNurseSuccess(data));
        break;
      case "registry":
        dispatch(registerRegistrySuccess(data));
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch(registerEmployeeFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const registerEmployeeRequest = () => ({
  type: REGISTER_EMPLOYEE_REQUEST,
});

const registerEmployeeFail = (error) => ({
  type: REGISTER_EMPLOYEE_FAIL,
  payload: error,
});

const registerDoctorSuccess = (doctor) => ({
  type: REGISTER_DOCTOR_SUCCESS,
  payload: doctor,
});

const registerNurseSuccess = (nurse) => ({
  type: REGISTER_NURSE_SUCCESS,
  payload: nurse,
});

const registerRegistrySuccess = (registry) => ({
  type: REGISTER_REGISTRY_SUCCESS,
  payload: registry,
});

export const getEmployeeVacations = (vacationsIdsArr) => async (dispatch) => {
  dispatch(getEmployeeVacationsRequest());
  try {
    const response = await axios.post(`/vacation/list`, {
      vacationIds: vacationsIdsArr,
    });
    const { data } = response;
    dispatch(getEmployeeVacationsSuccess(data));
  } catch (error) {
    dispatch(getEmployeeVacationsFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const getEmployeeVacationsRequest = () => ({
  type: GET_EMPLOYEE_VACATIONS_REQUEST,
});

const getEmployeeVacationsSuccess = (vacations) => ({
  type: GET_EMPLOYEE_VACATIONS_SUCCESS,
  payload: vacations,
});

const getEmployeeVacationsFail = (error) => ({
  type: GET_EMPLOYEE_VACATIONS_FAIL,
  payload: error,
});

export const updateDoctorDetailData = (userData, id) => async (dispatch) => {
  dispatch(updateDoctorDetailDataRequest());
  try {
    const response = await axios.patch(`/doctor/${id}`, userData);
    const { data } = response;
    dispatch(updateDoctorDetailDataSuccess(data));
  } catch (error) {
    dispatch(updateDoctorDetailDataFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const updateDoctorDetailDataRequest = () => ({
  type: UPDATE_DOCTOR_DETAIL_REQUEST,
});

const updateDoctorDetailDataSuccess = (data) => ({
  type: UPDATE_DOCTOR_DETAIL_SUCCESS,
  payload: data,
});

const updateDoctorDetailDataFail = (error) => ({
  type: UPDATE_DOCTOR_DETAIL_FAIL,
  payload: error,
});

export const updateNurseDetailData = (userData, id) => async (dispatch) => {
  dispatch(updateNurseDetailDataRequest());
  try {
    const response = await axios.patch(`/nurse/${id}`, userData);
    const { data } = response;
    dispatch(updateNurseDetailDataSuccess(data));
  } catch (error) {
    dispatch(updateNurseDetailDataFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const updateNurseDetailDataRequest = () => ({
  type: UPDATE_NURSE_DETAIL_REQUEST,
});

const updateNurseDetailDataSuccess = (data) => ({
  type: UPDATE_NURSE_DETAIL_SUCCESS,
  payload: data,
});

const updateNurseDetailDataFail = (error) => ({
  type: UPDATE_NURSE_DETAIL_FAIL,
  payload: error,
});

export const updateRegistryDetailData = (userData, id) => async (dispatch) => {
  dispatch(updateRegistryDetailDataRequest());
  try {
    const response = await axios.patch(`/registry/${id}`, userData);
    const { data } = response;
    dispatch(updateRegistryDetailDataSuccess(data));
  } catch (error) {
    dispatch(updateRegistryDetailDataFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const updateRegistryDetailDataRequest = () => ({
  type: UPDATE_REGISTRY_DETAIL_REQUEST,
});

const updateRegistryDetailDataSuccess = (data) => ({
  type: UPDATE_REGISTRY_DETAIL_SUCCESS,
  payload: data,
});

const updateRegistryDetailDataFail = (error) => ({
  type: UPDATE_REGISTRY_DETAIL_FAIL,
  payload: error,
});

export const updateUserBasicData = (userData, id) => async (dispatch) => {
  dispatch(updateUserBasicDataRequest());
  //console.log(userData, id);
  try {
    const response = await axios.patch(`/user/${id}`, userData);
    const { data } = response;
    dispatch(updateUserBasicDataSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(updateUserBasicDataFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const updateUserBasicDataRequest = () => ({
  type: UPDATE_USER_DETAIL_REQUEST,
});

const updateUserBasicDataSuccess = (data) => ({
  type: UPDATE_USER_DETAIL_SUCCESS,
  payload: data,
});

const updateUserBasicDataFail = (error) => ({
  type: UPDATE_USER_DETAIL_FAIL,
  payload: error,
});

export const restoreUser = ({ id, ...user }) => async (dispatch) => {
  dispatch(restoreUserRequest());
  try {
    const response = await axios.post(`/user/restore/${id}`, user);
    const { data } = response;
    if (data.userId.activeRole === 'Doctor') {
      dispatch(registerDoctorSuccess(data));
    } else if (data.userId.activeRole === 'Registry') {
      dispatch(registerRegistrySuccess(data));
    }
  } catch (error) {
    dispatch(restoreUserFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const restoreUserRequest = () => ({
  type: RESTORE_USER_REQUEST,
});

const restoreUserFail = (error) => ({
  type: RESTORE_USER_FAIL,
  payload: error,
});

export const deleteUserVacations = (vacations, userId) => async dispatch => {
  dispatch(deleteUserVacationsRequest())
  try {
    const responses = await Promise.all(
      vacations.map(vacation => axios.delete(`/vacation/${vacation._id}`, { data: { userId: userId } }))
    )
    // response powinien zwracać zdelejtowany obiekt? wtedy do dispatcha wleciałby response
    dispatch(deleteUserVacationsSuccess(vacations));
  } catch (error) {
    dispatch(deleteUserVacationsFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const deleteUserVacationsRequest = () => ({
  type: DELETE_USER_VACATIONS_REQUEST,
});

const deleteUserVacationsSuccess = (vacations) => ({
  type: DELETE_USER_VACATIONS_SUCCESS,
  payload: vacations,
});

const deleteUserVacationsFail = (error) => ({
  type: DELETE_USER_VACATIONS_FAIL,
  payload: error,
});

export const addUserVacations = (vacations, userId, notAdmin = false) => async dispatch => {
  dispatch(addUserVacationsRequest());
  const url = notAdmin ? `/vacation/employee` : `/vacation/`
  try {
    const responses = await Promise.all(
      vacations.map(vacation => axios.post(url, { ...vacation, userId: userId }))
    )
    const [...data] = responses.map(response => response.data);
    dispatch(addUserVacationsSuccess(data))
    data.forEach(vac => dispatch(addUserVacationId(vac._id, userId)))
  } catch (error) {
    console.log(error);
    dispatch(addUserVacationsFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const addUserVacationId = (vacationId, userId) => ({
  type: ADD_USER_VACATION_ID,
  payload: { vacationId, userId },
});

const addUserVacationsRequest = () => ({
  type: ADD_USER_VACATIONS_REQUEST,
});

const addUserVacationsSuccess = (vacations) => ({
  type: ADD_USER_VACATIONS_SUCCESS,
  payload: vacations,
});

const addUserVacationsFail = (error) => ({
  type: ADD_USER_VACATIONS_FAIL,
  payload: error,
});

export const getUserVacationsUsedDays = (users) => async (dispatch) => {
  const userIdVacationsArr = users.map((user) => {
    return { userId: user.userId._id, vacationIds: user.userId.vacations };
  });
  dispatch(getUserVacationsUsedDaysRequest());

  try {
    const response = await axios.post(
      `/vacation/sumVacationDays`,
      userIdVacationsArr
    );
    const { data } = response;
    dispatch(getUserVacationsUsedDaysSuccess(data));
  } catch (error) {
    dispatch(getUserVacationsUsedDaysFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
};

const getUserVacationsUsedDaysRequest = () => ({
  type: GET_USER_VACATIONS_USED_DAYS_REQUEST,
});

const getUserVacationsUsedDaysSuccess = (vacations) => ({
  type: GET_USER_VACATIONS_USED_DAYS_SUCCESS,
  payload: vacations,
});

const getUserVacationsUsedDaysFail = (error) => ({
  type: GET_USER_VACATIONS_USED_DAYS_FAIL,
  payload: error,
});


export const resetEmployeesError = () => async dispatch => {
  dispatch({
    type: RESET_EMPLOYEES_ERROR,
  });
};

export const uploadUserCertificates = (doctorId, certificates) => async (
  dispatch
) => {
  dispatch(uploadUserCertificatesRequest());
  try {
    const responses = await Promise.all(
      certificates.forEach((cert) =>
        axios.post(`/doctor/${doctorId}/certificate`, cert
        )
      )
    );
    const [...data] = responses.map((response) => response.data);
    data.forEach(el => dispatch(uploadUserCertificatesSuccess(el)))
  } catch (error) {
    dispatch(uploadUserCertificatesFail(error));
  }
};

const uploadUserCertificatesRequest = () => ({
  type: UPLOAD_USER_CERTIFICATES_REQUEST,
});

const uploadUserCertificatesSuccess = (certificate) => ({
  type: UPLOAD_USER_CERTIFICATES_SUCCESS,
  payload: certificate,
});

const uploadUserCertificatesFail = (error) => ({
  type: UPLOAD_USER_CERTIFICATES_FAIL,
  payload: error,
});

export const deleteUserCertificates = (doctorId, certificates) => async (
  dispatch
) => {
  dispatch(deleteUserCertificatesRequest());
  try {
    const responses = await Promise.all(
      certificates.forEach((cert) =>
        axios.delete(`/doctor/${doctorId}/certificate/${cert._id}`)
      )
    );
    const [...data] = responses.map((response) => response.data);
    data.forEach(el => dispatch(deleteUserCertificatesSuccess(el)))
  } catch (error) {
    dispatch(deleteUserCertificatesFail(error));
  }
};

const deleteUserCertificatesRequest = () => ({
  type: DELETE_USER_CERTIFICATES_REQUEST,
});

const deleteUserCertificatesSuccess = (certificate) => ({
  type: DELETE_USER_CERTIFICATES_SUCCESS,
  payload: certificate,
});

const deleteUserCertificatesFail = (error) => ({
  type: DELETE_USER_CERTIFICATES_FAIL,
  payload: error,
});

const initialState = {
  doctors: [],
  nurses: [],
  registries: [],
  loading: false,
  employeeVacations: [],
  usersVacationsCount: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //authenticate user call
    case GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYEES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_REGISTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        registries: action.payload,
      };
    case GET_DOCTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        doctors: action.payload,
      };
    case GET_NURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        nurses: action.payload,
      }
    case REGISTER_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REGISTER_DOCTOR_SUCCESS:
      return {
        ...state,
        doctors: [...state.doctors, action.payload],
        loading: false,
        error: null,
      };
    case REGISTER_REGISTRY_SUCCESS:
      return {
        ...state,
        registries: [...state.registries, action.payload],
        loading: false,
        error: null,
      };
    case REGISTER_NURSE_SUCCESS:
      return {
        ...state,
        nurses: [...state.nurses, action.payload],
        loading: false,
        error: null,
      };
    case GET_EMPLOYEE_VACATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYEE_VACATIONS_SUCCESS:
      return {
        ...state,
        employeeVacations: action.payload,
        loading: false,
      };
    case GET_EMPLOYEE_VACATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_DOCTOR_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DOCTOR_DETAIL_SUCCESS:
      return {
        ...state,
        doctors: state.doctors.map((doctor) =>
          doctor._id === action.payload._id
            ? {
              ...doctor,
              isActive: action.payload.isActive,
              visitColor: action.payload.visitColor,
              academicTitle: action.payload.academicTitle,
              pwz: action.payload.pwz,
              defaultRoom: action.payload.defaultRoom,
              prescriptionsNumbers: action.payload.prescriptionsNumbers,
              callendarSettings: action.payload.callendarSettings,
            }
            : doctor
        ),
        loading: false,
      };
    case UPDATE_DOCTOR_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_NURSE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_NURSE_DETAIL_SUCCESS:
      return {
        ...state,
        nurses: state.nurses.map((nurse) =>
          nurse._id === action.payload._id
            ? {
              ...nurse,
              isActive: action.payload.isActive,
              visitColor: action.payload.visitColor,
              academicTitle: action.payload.academicTitle,
              pwz: action.payload.pwz,
              defaultRoom: action.payload.defaultRoom,
              prescriptionsNumbers: action.payload.prescriptionsNumbers,
              callendarSettings: action.payload.callendarSettings,
            }
            : nurse
        ),
        loading: false,
      };
    case UPDATE_NURSE_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_REGISTRY_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_REGISTRY_DETAIL_SUCCESS:
      return {
        ...state,
        registries: state.registries.map((registry) =>
          registry._id === action.payload._id
            ? {
              ...registry,
              isActive: action.payload.isActive,
            }
            : registry
        ),
        loading: false,
      };
    case UPDATE_REGISTRY_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_USER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_DETAIL_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        registries: state.registries
          .filter((registry) => {
            if (action.payload.deleted && registry.userId._id === action.payload._id) {
              return false;
            }
            return true;
          })
          .map((registry) =>
            registry.userId._id === action.payload._id
              ? { ...registry, userId: action.payload }
              : registry
          ),
        doctors: state.doctors
          .filter((doctor) => {
            if (action.payload.deleted && doctor.userId._id === action.payload._id) {
              return false;
            }
            return true;
          }
          )
          .map((doctor) =>
            doctor.userId._id === action.payload._id
              ? { ...doctor, userId: action.payload }
              : doctor
          ),
        nurses: state.nurses
          .filter((nurse) => {
            if (action.payload.deleted && nurse.userId._id === action.payload._id) {
              return false;
            }
            return true;
          }
          )
          .map((nurse) =>
            nurse.userId._id === action.payload._id
              ? { ...nurse, userId: action.payload }
              : nurse
          ),
        loading: false,
      };
    case UPDATE_USER_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_USER_VACATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_VACATIONS_SUCCESS:
      return {
        ...state,
        employeeVacations: state.employeeVacations.filter(
          (vacation) => !action.payload.find((vac) => vacation._id === vac._id)
        ),
        loading: false,
      };
    case DELETE_USER_VACATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_USER_VACATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_USER_VACATIONS_SUCCESS:
      return {
        ...state,
        employeeVacations: [...state.employeeVacations, ...action.payload],
        // usersVacations: 
        loading: false,
      };
    case ADD_USER_VACATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_USER_VACATION_ID:
      return {
        ...state,
        doctors: state.doctors.map((doctor) =>
          doctor.userId._id === action.payload.userId
            ? {
              ...doctor,
              userId: {
                ...doctor.userId,
                vacations: [
                  ...doctor.userId.vacations,
                  action.payload.vacationId,
                ],
              },
            }
            : doctor
        ),
        nurses: state.nurses.map((nurse) =>
          nurse.userId._id === action.payload.userId
            ? {
              ...nurse,
              userId: {
                ...nurse.userId,
                vacations: [
                  ...nurse.userId.vacations,
                  action.payload.vacationId,
                ],
              },
            }
            : nurse
        ),
        registries: state.registries.map((registry) =>
          registry.userId._id === action.payload.userId
            ? {
              ...registry,
              userId: {
                ...registry.userId,
                vacations: [
                  ...registry.userId.vacations,
                  action.payload.vacationId,
                ],
              },
            }
            : registry
        ),
      };
    case GET_USER_VACATIONS_USED_DAYS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_VACATIONS_USED_DAYS_SUCCESS:
      return {
        ...state,
        usersVacationsCount: action.payload,
        loading: false,
      };
    case GET_USER_VACATIONS_USED_DAYS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SET_INITIAL_EMPLOYEE:
      return {
        doctors: [],
        nurses: [],
        registries: [],
        loading: false,
        employeeVacations: [],
        usersVacationsCount: [],
        error: null,
      };
    case RESET_EMPLOYEES_ERROR:
      return {
        ...state,
        error: null,
      };
    case UPLOAD_USER_CERTIFICATES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UPLOAD_USER_CERTIFICATES_SUCCESS:
      return {
        ...state,
        doctors: state.doctors.map((doctor) =>
          doctor.userId._id === action.payload.userId
            ? {
              ...doctor,
              certificates: action.payload.certificates
            }
            : doctor
        )
      };
    case UPLOAD_USER_CERTIFICATES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case DELETE_USER_CERTIFICATES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case DELETE_USER_CERTIFICATES_SUCCESS:
      return {
        ...state,
        doctors: state.doctors.map((doctor) =>
          doctor.userId._id === action.payload.userId
            ? {
              ...doctor,
              certificates: action.payload.certificates
            }
            : doctor
        )
      }
    case DELETE_USER_VACATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state;
  }
};
