import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import moment from 'moment';
import { useDrop, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import dragIcon from '../Shared/assets/drag.svg';
import Select from '../Shared/Select';
import DatePicker from '../Shared/DatePicker';
import Input from '../Shared/Input';
import { ReactComponent as InfoIcon } from '../Shared/assets/info-circle.svg';
import Tooltip from '../Shared/Tooltip';

const ElementsDrop = ({
    elIsDragging,
    dropElementHandler,
    currentStage: { obj: currentStageObj, i: currentStageIndex },
    currentElement,
    setCurrentElement,
    otherDiagnosis,
    otherMeds,
    medicalProcedures,
    warningModalHandler,
    changeMedDataHandler,
    changeIndexHandler,
    currentMedicalVisit,
    readMode,
}) => {
    const [highlight, setHighlight] = useState(null),
        [{ canDrop, isOver, dragItem }, drop] = useDrop({
            accept: ['element', 'procedure', 'diagnosis', 'med', 'service', 'set'],
            drop: (item) => dropElementHandler(item),
            collect: mon => ({
                isOver: !!mon.isOver(),
                canDrop: !!mon.canDrop(),
                dragItem: mon.getItem(),
            }),
        });

    useEffect(() => {

        if (canDrop && isOver && highlight !== 'accept') {
            setHighlight('accept');
        } else if (canDrop && isOver && highlight !== 'decline') {
            setHighlight('decline');
        } else if (highlight !== '') {
            setHighlight('');
        }
    }, [canDrop, isOver, dragItem]);

    return (
        <Wrapper stageIndex={currentStageIndex}>
            <>
                <DropZone
                    id="dropzone"
                    active={elIsDragging}
                    highlight={highlight}
                    ref={drop}
                />
                <Items>
                    {currentStageObj?.elements?.length > 0 && <Elements>
                        {currentStageObj?.elements && currentStageObj?.elements.map((el, index) => (
                            <ElementItem
                                key={el._id}
                                variant='elementItem'
                                index={index}
                                onClick={() => setCurrentElement(index)}
                                active={currentElement === index}
                                itemData={el}
                                swapHandler={(dragIndex, hoverIndex) => changeIndexHandler(dragIndex, hoverIndex, 'elements')}
                                currentStageIndex={currentStageIndex}
                                readMode={readMode}
                            />
                        ))}
                    </Elements>}
                    {currentStageIndex === 2 && <List>
                        {(currentMedicalVisit?.changes?.medicalProcedures && currentMedicalVisit?.changes?.medicalProcedures.length > 0) &&
                            <StyledTooltip name={'medicalProceduresHistory'} content={
                                <TooltipContent>
                                    <p>Poprzednia zawartość:</p>
                                    {currentMedicalVisit?.changes?.medicalProcedures.map(({ items, changeDate }, changeIndex) => (
                                        <TooltipItem key={`${changeDate}_${changeIndex}`}>
                                            <span>{moment(changeDate).format('DD.MM.YYYY HH:mm')}</span>
                                            <ul>
                                                {items.length > 0 ? items.map(({ code, name }, index) => (
                                                    <li key={`${name}_${index}`}>{`${code} ${name}`}</li>
                                                )) : <span>Brak</span>}
                                            </ul>
                                        </TooltipItem>
                                    ))}
                                </TooltipContent>
                            }>
                                <StyledInfoIcon />
                            </StyledTooltip>
                        }
                        {medicalProcedures && medicalProcedures.map((el, index) => (
                            <ElementItem
                                key={el._id}
                                variant='listItem'
                                index={index}
                                onRemoveClick={() => warningModalHandler('removeItem', {
                                    itemIndex: index,
                                    targetList: 'medicalProcedures'
                                })}
                                active={currentElement === index}
                                itemData={el}
                                swapHandler={(dragIndex, hoverIndex) => changeIndexHandler(dragIndex, hoverIndex, 'medicalProcedures')}
                                currentStageIndex={currentStageIndex}
                                readMode={readMode}
                            />
                        ))}
                    </List>}
                    {currentStageIndex === 3 && <List>
                        {(currentMedicalVisit?.changes?.otherDiagnosis && currentMedicalVisit?.changes?.otherDiagnosis.length > 0) &&
                            <StyledTooltip name={'otherDiagnosisHistory'} content={
                                <TooltipContent>
                                    <p>Poprzednia zawartość:</p>
                                    {currentMedicalVisit?.changes?.otherDiagnosis.map(({ items, changeDate }, changeIndex) => (
                                        <TooltipItem key={`${changeDate}_${changeIndex}`}>
                                            <span>{moment(changeDate).format('DD.MM.YYYY HH:mm')}</span>
                                            <ul>
                                                {items.length > 0 ? items.map(({ subcategoryCode, subcategory }, index) => (
                                                    <li key={`${subcategoryCode}_${index}`}>{`${subcategoryCode} ${subcategory}`}</li>
                                                )) : <span>Brak</span>}
                                            </ul>
                                        </TooltipItem>
                                    ))}
                                </TooltipContent>
                            }>
                                <StyledInfoIcon />
                            </StyledTooltip>
                        }
                        {otherDiagnosis && otherDiagnosis.map((el, index) => (
                            <ElementItem
                                key={el._id}
                                variant='listItem'
                                index={index}
                                onRemoveClick={() => warningModalHandler('removeItem', {
                                    itemIndex: index,
                                    targetList: 'otherDiagnosis'
                                })}
                                active={currentElement === index}
                                itemData={el}
                                swapHandler={(dragIndex, hoverIndex) => changeIndexHandler(dragIndex, hoverIndex, 'otherDiagnosis')}
                                currentStageIndex={currentStageIndex}
                                readMode={readMode}
                            />
                        ))}
                    </List>}
                    {currentStageIndex === 4 && <List>
                        {(currentMedicalVisit?.changes?.otherMeds && currentMedicalVisit?.changes?.otherMeds.length > 0) &&
                            <StyledTooltip name={'otherMedsHistory'} content={
                                <TooltipContent>
                                    <p>Poprzednia zawartość:</p>
                                    {currentMedicalVisit?.changes?.otherMeds.map(({ items, changeDate }, changeIndex) => (
                                        <TooltipItem key={`${changeDate}_${changeIndex}`}>
                                            <span>{moment(changeDate).format('DD.MM.YYYY HH:mm')}</span>
                                            <ul>
                                                {items.length > 0 ? items.map(({ ilosc, nazwaProduktu, moc, wielkosc, jednostkaWielkosci }, index) => (
                                                    <li key={`${nazwaProduktu}_${index}`}>{`${nazwaProduktu}, ${moc}, ${ilosc} x ${wielkosc} ${jednostkaWielkosci}`}</li>
                                                )) : <span>Brak</span>}
                                            </ul>
                                        </TooltipItem>
                                    ))}
                                </TooltipContent>
                            }>
                                <StyledInfoIcon />
                            </StyledTooltip>
                        }
                        {otherMeds && otherMeds.map((el, index) => {
                            return (
                                <ElementItem
                                    key={el._id}
                                    variant='listItem'
                                    index={index}
                                    onRemoveClick={() => warningModalHandler('removeItem', {
                                        itemIndex: index,
                                        targetList: 'otherMeds'
                                    })}
                                    active={currentElement === index}
                                    itemData={el}
                                    swapHandler={(dragIndex, hoverIndex) => changeIndexHandler(dragIndex, hoverIndex, 'otherMeds')}
                                    currentStageIndex={currentStageIndex}
                                    changeMedDataHandler={changeMedDataHandler}
                                    readMode={readMode}
                                />
                            )
                        })}
                    </List>}
                </Items>
            </>
        </Wrapper >
    )
};

export default ElementsDrop;

const ElementItem = ({ variant, onClick, index, active, itemData = {}, swapHandler, currentStageIndex, onRemoveClick, changeMedDataHandler, readMode }) => {
    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: 'dropedElement', index, item: itemData },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }),
        [, dropParam] = useDrop({
            accept: ['dropedElement'],
            hover: (item, monitor) => {
                if (item.type === 'dropedElement') {
                    if (!ref.current) {
                        return
                    }
                    const dragIndex = item.index;
                    const hoverIndex = index;
                    if (dragIndex === hoverIndex) {
                        return
                    }
                    const hoverBoundingRect = ref.current.getBoundingClientRect();
                    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                    const clientOffset = monitor.getClientOffset();
                    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                        return
                    }
                    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                        return
                    }
                    swapHandler(dragIndex, hoverIndex);
                    item.index = hoverIndex;
                }
            },
        }),
        ref = useRef(null);
    drag(dropParam(ref));
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, []);
    const options = itemData.opakowania ? itemData.opakowania.map((values, index) => ({ value: index, label: `${values.wielkosc} ${values.jednostkaWielkosci}` })) : [];
    return (
        <Element
            variant={variant}
            isDragging={isDragging}
            ref={ref}
            key={itemData._id}
            onClick={onClick}
            active={active}
            display={currentStageIndex === 4 ? 'flex' : 'block'}
            disabled={variant === 'listItem' && readMode}
        >
            {(currentStageIndex === 2 || currentStageIndex === 3) && <Code>{currentStageIndex === 2 ? itemData.code : itemData.subcategoryCode}</Code>}
            <Name width={currentStageIndex === 4 && variant === 'listItem' ? '45%' : '100%'}>
                {currentStageIndex === 4 && variant === 'listItem' ? `${itemData.nazwaProduktu}, ${itemData.moc}` : itemData.name}
            </Name>
            {(variant === 'listItem' && !readMode) && <RemoveBtn onClick={onRemoveClick} />}
            {(currentStageIndex === 4 && variant === 'listItem') &&
                <>
                    <QuantityInput
                        value={itemData.ilosc}
                        type='number'
                        onChange={e => changeMedDataHandler(index, e.target.value, 'quantity')}
                        onBlur={() => {
                            if (itemData.ilosc < 1 || itemData.ilosc === 0 || itemData.ilosc === '') {
                                changeMedDataHandler(index, 1, 'quantity')
                            }
                        }}
                        hideError={true}
                    />
                    x
                    <PackageSelect
                        menuPosition="fixed"
                        hideError={true}
                        options={options}
                        value={options.find(({ label }) => label === `${itemData.wielkosc} ${itemData.jednostkaWielkosci}`)}
                        onChange={({ value }) => changeMedDataHandler(index, value, 'package')}
                    />
                    do
                    <MedsDate
                        selected={Date.parse(itemData?.dataKoncaPrzyjmowania)}
                        onChange={(value) => changeMedDataHandler(index, value, 'date')}
                        preventOverflow={true}
                        showBackgroundImage={false}
                        hideError={true}
                        dateFormat="dd.MM.yyyy"
                    />
                </>
            }
        </Element>
    )
}

const Wrapper = styled.div`
    height:${({ stageIndex }) => stageIndex !== 5 ? '55%' : '20%'};
    transition:300ms;
`;

const blinkParent = keyframes`
  from {
      background-color:#f6f5f8;
      border-color:#D9D9D9;
    }
  to {
      background-color: #b9ddff;
      border-color:#1890FF;
  }
`;
const blinkAfter = keyframes`
  from {
      color:#D9D9D9;
    }
  to {
      color:#1890FF;
  }
`;

const animationParent = () => css`${blinkParent} .8s infinite alternate;`;
const animationAfter = () => css`${blinkAfter} .8s infinite alternate;`;

const DropZone = styled.div`
    background-color:${({ theme }) => theme.grey};
    border:2px dashed #D9D9D9;
    border-radius: 5px;
    height:85px;
    display:flex;
    justify-content:center;
    align-items:center;
    transition:300ms;
    animation:${animationParent};
    &::after{
        content:'Przeciągnij obiekty z lewego panelu, aby dodać elementy';
        color:#D9D9D9;
        font-weight:600;
        transition:300ms;
        text-align:center;
        animation:${animationAfter};
        ${({ active }) => !active && `
            animation: null;
        `}
    }
    ${({ active }) => !active && `
        animation: null;
    `}
    ${({ highlight, theme }) => highlight === 'accept' ? `
        background-color:${theme.lightGreen}!important;
        border-color:${theme.green}!important;
        &::after{
            color:${theme.green}!important;
        }`
        : highlight === 'decline' ? `
            background-color:${theme.lightDanger}!important;
            border-color:${theme.danger}!important;
            &::after{
                color:${theme.danger}!important;
            }
        `
            : `
        
        `
    }
`;

const Elements = styled.ul`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:10px;
`;

const Element = styled.li`
    position:relative;
    display:${({ display }) => display ? display : 'block'};
    align-items:center;
    cursor:pointer;
    width:100%;
    min-height:35px;
    padding:5px 25px 5px 25px;
    border: 2px solid transparent;
    border-radius:5px;
    font-size:12px!important;
    transition:300ms;
    flex-wrap:wrap;
    &::before{
        content: url(${dragIcon});
        height:20px;
        position:absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 5px;
    }
    ${({ active, variant, theme }) => (active && variant === 'elementItem') && `border-color:${theme.primary}!important;`}
    ${({ isDragging }) => isDragging && `opacity:.3;`}
    ${({ variant, theme }) => variant === 'listItem' && `
        font-size:14px;
        border-radius:0;
        border:none;
        border-bottom:1px solid ${({ theme }) => theme.grey};
        &:not(:last-child){
            border-bottom:1px solid ${theme.grey};
        }
    `}
    ${({ theme }) => `${theme.mq.desktop}{
        font-size:inherit;
        flex-wrap:nowrap;
    }`}
    &:hover{
        border-color:#1890ff66;
    }
    ${({ disabled }) => disabled && 'pointer-events:none;'}
`;

const List = styled.ul`
    position:relative;
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const Items = styled.div`
    max-height:calc(100% - 90px);
    overflow-y:auto;
    overflow-x:hidden;
`;

const Code = styled.span`
    font-weight:600;
    margin-right:5px;
    display:block;
    min-width:25px;
`;

const Name = styled.span`
    max-width:100%;
    width:100%;
    ${({ theme }) => `${theme.mq.desktop}{
        max-width:${({ width }) => width};
        width:auto;
    }`}
`;

const PackageSelect = styled(Select)`
    height:24px;
    margin:0 5px;
    >div{
        border:none;
        border-bottom:1px solid #ccc;
        border-radius:0;
        width:85px;
        min-height:0;
        margin:0;
        ${({ theme }) => `${theme.mq.desktop}{
            width:150px;
        }`}
    }
    div{
        padding:0!important;
    }
`;

const QuantityInput = styled(Input)`
    height:24px;
    margin: 0 5px 0 5px;
    border:none;
    border-bottom:1px solid #ccc;
    border-radius:0;
    width:40px;
    min-height:0;
    padding:0;
    color: #aeaeae;
    font-weight: 600;
    ${({ theme }) => `${theme.mq.desktop}{
        margin: 0 5px 0 15px;
    }`}
    &:focus{
        padding-left:0;
        border-width:1px
    }
`;

const MedsDate = styled(DatePicker)`
    width:100px;
    input{
        height:24px;
        margin: 0 5px;
        border:none;
        border-bottom:1px solid #ccc;
        border-radius:0;
        min-height: 0;
        &:focus{
            border-width:1px
        }
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
    position:absolute;
    right:-7px;
    top:-7px;
    z-index:9;
`;

const StyledTooltip = styled(Tooltip)`
    position:absolute;
    right:7px;
    top:0;
`;

const TooltipContent = styled.div`
    p, span{
        color:#fff;
    }
    ul{
        margin:0;
        >li{
            color:#fff;
        }
    }
`;

const TooltipItem = styled.div`
    padding: 2.5px 0;
    &:not(:last-child){
        border-bottom: 1px solid #dedede;
    }
`;