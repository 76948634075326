import React from 'react';
import styled from 'styled-components';
import { findAllTags } from '../Shared/utils';
import { fullStageNameEnum } from '../Shared/enums';
import moment from 'moment';
import Input from '../Shared/Input';
import Select from '../Shared/Select';
import DatePicker from '../Shared/DatePicker';
import TimePicker from '../Shared/TimePicker';

const stagesInitalTexts = [
    'Wywiad nie wykazał żadnych objawów u pacjenta.',
    'Badania fizykalne nie wykazały żadnych objawów u pacjenta.',
    'Na pacjencie nie wykonano żadnych dodatkowych badań.',
    'U pacjenta nie rozpoznano żadnej choroby.',
    'Pacjentowi nie zapisano żadnych leków.',
];
const listHeadings = {
    BADANIEDODATKOWE: 'Na pacjencie dodatkowo wykonano:',
    ROZPOZNANIE: 'U pacjenta zdiagnozowano:',
    ZALECENIA: 'Pacjentowi zapisano:'
};

export const findAllTextValues = (string, type = 'string') => {
    const startSelector = type === 'list' ? '- <li ' : '<b ',
        endSelector = type === 'list' ? '</li>' : '</b>',
        startLength = type === 'list' ? 12 : 3;

    let startIndexes = [], i = -1;
    while ((i = string.indexOf(startSelector, i + 1)) != -1) {
        startIndexes.push(i);
    }
    let endIndexes = [], j = -1;
    while ((j = string.indexOf(endSelector, j + 1)) != -1) {
        endIndexes.push(j);
    }
    let arr = [];
    for (const index in startIndexes) {
        const finded = string.substring(startIndexes[index] + startLength, endIndexes[index]),
            firstQuoteId = finded.indexOf('id="'),
            firstQuoteElId = finded.indexOf('elid="'),
            tagName = finded.substring(firstQuoteId + 4, finded.indexOf('"', firstQuoteId + 5)),
            elId = finded.substring(firstQuoteElId + 6, finded.indexOf('"', firstQuoteElId + 7)),
            value = finded.substring(finded.indexOf('>') + 1, finded.length)
        console.log(finded);
        console.log(tagName);
        console.log(elId);
        arr.push({
            start: startIndexes[index],
            end: endIndexes[index],
            tagName: tagName,
            elId,
            value,
        })
    }
    return arr;
};

export const convertTextToSchema = (text, elements = [], list = []) => {
    let parsedValuesToChange = [];
    if (text && text !== '') {
        const tags = findAllTextValues(text);
        const listTags = findAllTextValues(text, 'list');
        let newSchema = text;
        for (const { tagName, value } of tags) {
            let control, elIndex, controlIndex;
            for (const [index, { formControls = [] }] of elements.entries()) {
                elIndex = index;
                const findedControl = formControls?.find(({ name }) => name === tagName);
                controlIndex = formControls?.findIndex(({ name }) => name === tagName);
                if (findedControl) {
                    control = findedControl;
                }
            };
            if (value !== control.parsedValue) {
                parsedValuesToChange.push({
                    newParsedValue: value,
                    controlName: tagName,
                    type: 'element',
                    controlIndex,
                    elIndex,
                })
            };
            newSchema = newSchema.replace(`<b id="${tagName}">${value}</b>`, `{${tagName}}|${elements[elIndex]._id}|`);
        }
        for (const { tagName: tagIndex, value } of listTags) {
            if (value !== list[tagIndex]?.name) {
                parsedValuesToChange.push({
                    newParsedValue: value,
                    type: 'listItem',
                    tagIndex,
                })
            };
            newSchema = newSchema.replace(`- <li index="${tagIndex}">${value}</li>,`, `{list[${tagIndex}]}`);
            const reg = new RegExp(`\n`, "g");
            newSchema = newSchema.replace(reg, '');
        }
        return { newSchema, parsedValuesToChange };
    }
}

export const convertSchemaToText = (schema, { elements = [], stageName }, listSource = [], clear = false) => {
    if (schema && schema !== '') {
        const tags = findAllTags(schema);
        let newText = schema,
            replaceTo = '';
        for (const { _id } of elements) {
            newText = newText.replace(`|${_id}|`, '');
        }
        for (const { tagName, elemId } of tags) {
            if (tagName.includes('list[') && stageName) {
                const index = tagName.replace('list[', '').replace(']', '');
                const listItem = listSource[index];
                replaceTo = `- <li index="${index}">${listItem?.generatedTextName}</li>,`;
                if (clear) {
                    replaceTo = `- ${listItem?.generatedTextName},\n` ?? '';
                }
                replaceTo = `\n${replaceTo}`;
            } else if (elements.length > 0 && tagName !== 'defaultListHeading') {
                let control;
                const { formControls = [] } = elements.find(({ _id }) => _id.toString() === elemId.toString()) ?? {};
                const findedControl = formControls?.find(({ name }) => name === tagName);
                if (findedControl) {
                    control = findedControl;
                }
                replaceTo = `<b id="${tagName}" elid="${elemId}">${control?.parsedValue ?? ''}</b>`;
                if (clear) {
                    replaceTo = control?.parsedValue ?? '';
                }
            } else if (tagName === 'defaultListHeading') {
                const listHeading = listHeadings[stageName.replace(' ', '')];
                replaceTo = listHeading;
            }
            newText = newText.replace(`{${tagName}}`, replaceTo);
        }
        return newText;
    } else if (schema === '' && elements.length === 0 && listSource.length === 0) {
        const stageIndex = fullStageNameEnum.indexOf(stageName);
        const newText = stagesInitalTexts[stageIndex];
        return newText;
    }
    return '';
};

export const parseValue = (value, formControl) => {
    let { formType, displayFormat, selectOptions, checkboxOptions, rangeDictionary, unit, emptyFieldFormat, emptyFieldText, name } = formControl;
    if (!value) {
        value = '';
    }
    switch (formType) {
        case 'input':
            if (value !== '' && unit) {
                value = `${value} ${unit}`;
            }
            if (rangeDictionary.length > 0) {
                for (const condition of rangeDictionary) {
                    if (parseFloat(value.replace(',', '.')) >= condition.lowerRange.replace(',', '.') && parseFloat(value.replace(',', '.')) < condition.upperRange.replace(',', '.')) {
                        value = condition.text.replace('{pole}', `${value}`);
                    }
                }
            }
            break;
        case 'checkbox':
            if (checkboxOptions.options.length === 0) {
                if (displayFormat === 'custom') {
                    if (value) {
                        value = checkboxOptions.ifTrue;
                    } else {
                        value = checkboxOptions.ifFalse;
                    }
                } else {
                    if (value) {
                        value = name;
                    } else {
                        value = '';
                    }
                }
            } else {
                for (const option of checkboxOptions.options) {
                    if (option.value) {
                        if (displayFormat === 'custom') {
                            if (value === '') {
                                value = `${option.text}`;
                            } else {
                                value = `${value}, ${option.text}`;
                            }
                        } else {
                            if (value === '') {
                                value = `${option.name}`;
                            } else {
                                value = `${value}, ${option.name}`;
                            }
                        }
                    }
                }
            }
            break;
        case 'select':
            if (displayFormat === 'custom') {
                const options = selectOptions.find(el => el.name === value);
                if (options) {
                    value = options.text;
                }
            }
            break;
        case 'radio':
            if (displayFormat === 'custom') {
                const options = selectOptions.find(el => el.name === value);
                if (options) {
                    value = options.text;
                }
            }
            break;
        case 'datepicker':
            if (value === '' && emptyFieldFormat === 'normal') {
                value = Date.now();
            } else if (value === '' && emptyFieldFormat === 'custom') {
                value = emptyFieldText;
            }
            if (value !== emptyFieldText) {
                if (displayFormat === 'daysFromNow') {
                    if (new Date(value) > Date.now()) {
                        value = 'Data musi być przeszła';
                    } else {
                        const diffTime = Math.abs(new Date(value) - Date.now());
                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        diffDays = diffDays > 0 ? diffDays - 1 : diffDays;
                        value = diffDays === 0 ? `od dzisiaj` : diffDays === 1 ? `od wczoraj` : `od ${diffDays} dni`;
                    }
                } else if (displayFormat === 'weeks') {
                    if (new Date(value) > Date.now()) {
                        value = 'Data musi być przeszła';
                    } else {
                        const diffTime = Math.abs(new Date(value) - Date.now());
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
                        if (diffDays < 7) {
                            value = 'od niecałego tygodnia';
                        } else if (diffDays >= 7 && diffDays < 14) {
                            value = 'od tygodnia';
                        } else if (diffDays >= 14) {
                            let weeks = Math.floor(diffDays / 7);
                            const months = Math.floor(diffDays / 30);
                            weeks -= (months * 4);
                            if (months < 1) {
                                value = `od ${weeks} tygodnii`;
                            } else if (months >= 1) {
                                const weekText = weeks === 0 ? '' : weeks < 2 ? 'i tygodnia' : `i ${weeks} tygodnii`;
                                const monthText = months < 2 ? 'miesiąca' : `${months} miesięcy`;
                                value = `od ${monthText}  ${weekText}`;
                            }
                        }
                    }
                } else {
                    value = moment(value).format('DD.MM.YYYY');
                }
            }
            break;
        case 'timepicker':
            if (value === '') {
                value = Date.now();
            }
            if (displayFormat === 'timeFromNow') {
                value = moment(value).fromNow();
            } else {
                if (moment(value).format('HH') === '00') {
                    value = `${moment(value).format('mm')} min`;
                } else {
                    value = `${moment(value).format('HH:mm')}`;
                }
            }
            break;
        default:
            value = '{Coś poszło nie tak z szablonem, sprawdź czy nazwy pól się zgadzają we wzorze}'
    }
    return value;
}

export const renderControl = (control, controlIndex, onChange, disabled) => {
    const { formType, name: controlName, selectOptions, checkboxOptions, unit, value: controlValue } = control;
    const optionsSelect = selectOptions.map(({ name, ...el }) => ({ value: name, label: name, ...el }));
    const optionsCheckbox = checkboxOptions.options.map(({ name, value, ...el }) => ({ value, label: name, ...el }));
    switch (formType) {
        case 'select':
            return (
                <StyledSelect
                    isDisabled={disabled}
                    key={`${controlName}_${controlIndex}`}
                    name={`${controlName}_${controlIndex}`}
                    menuPosition='fixed'
                    label={controlName}
                    options={optionsSelect}
                    value={optionsSelect.find(({ value }) => value === controlValue)}
                    onChange={({ value }) => onChange(controlIndex, control, value)}
                />
            )
        case 'input':
            return (
                <StyledInput
                    disabled={disabled}
                    key={`${controlName}_${controlIndex}`}
                    name={`${controlName}_${controlIndex}`}
                    label={`${controlName} ${(unit !== '') ? `(${unit})` : ''}`}
                    value={controlValue}
                    onChange={(e) => onChange(controlIndex, control, e.target.value)}
                />
            )
        case 'checkbox':
            return (
                <StyledInput
                    disabled={disabled}
                    type='checkbox'
                    variant='primary'
                    key={`${controlName}_${controlIndex} `}
                    name={`${controlName}_${controlIndex} `}
                    label={optionsCheckbox.length > 0 ? controlName : undefined}
                    checkboxText={`${controlName}`}
                    value={controlValue}
                    checkboxOptions={optionsCheckbox}
                    wrapperHeight='auto'
                    onChange={
                        optionsCheckbox.length > 0 ?
                            (index) => {
                                const value = optionsCheckbox[index].value;
                                onChange(controlIndex, control, !value, index)
                            }
                            :
                            () => onChange(controlIndex, control, !controlValue)
                    }
                />
            )
        case 'radio':
            return (
                <StyledInput
                    disabled={disabled}
                    type='radio'
                    key={`${controlName}_${controlIndex}`}
                    name={`${controlName}_${controlIndex}`}
                    label={controlName}
                    variant='primary'
                    // wrapperHeight='100px'
                    radioOptions={optionsSelect}
                    value={controlValue}
                    onChange={(field, value) => onChange(controlIndex, control, value)}
                />
            )
        case 'datepicker':
            return (
                <StyledDatePicker
                    disabled={disabled}
                    key={`${controlName}_${controlIndex}`}
                    name={`${controlName}_${controlIndex}`}
                    label={controlName}
                    dateFormat='dd.MM.yyyy'
                    selected={Date.parse(controlValue)}
                    onChange={(value) => onChange(controlIndex, control, value)}
                    popperPlacement='bottom-end'
                    preventOverflow={true}
                />
            )
        case 'timepicker':
            return (
                <StyledTimePicker
                    disabled={disabled}
                    key={`${controlName}_${controlIndex}`}
                    name={`${controlName}_${controlIndex}`}
                    label={controlName}
                    onChange={(value) => onChange(controlIndex, control, value)}
                    allowEmpty={false}
                    defaultValue={moment(Date.now())}
                    value={moment(controlValue)}
                    showSecond={false}
                />
            )
        default:
            return <p>Wrong formType</p>
    }
};

const StyledSelect = styled(Select)`
    >label{
        margin-bottom: 2px;
        display: block;
    }
`;
const StyledInput = styled(Input)`
    >label{
        display: block;
    }
`;
const StyledDatePicker = styled(DatePicker)`
    >label{
        display: block;
    }
`;
const StyledTimePicker = styled(TimePicker)`
    >label{
        display: block;
    }
`;