import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

const SidebarMobileMask = ({ active, onClick, accept }) => {
    const [monitor, drop] = useDrop({
        accept,
        hover: onClick,
    });
    return (
        <Mask
            ref={drop}
            onClick={onClick}
            active={active}
        />
    )
}

export default SidebarMobileMask;

const Mask = styled.div`
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color:#000;
  opacity:0;
  z-index: -1;
  transition:300ms opacity;
  ${({ active }) => active && `
    opacity:.3;
    z-index: 9;
  `}
  ${({ theme }) => `${theme.mq.desktop}{
    opacity:0;
    z-index:-1;
  }`}
`;