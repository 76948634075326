import React, { useState } from 'react'
import Modal from '../../../Shared/Modals/Modal';
import Input from '../../../Shared/Input';
import Button from '../../../Shared/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import TimePicker from '../../../Shared/TimePicker';
import FileUpload from "../../../Shared/FileUpload";
import EditImg from "../../../Shared/assets/edit-pen.svg";
import AvatarPlaceholder from "../../../Shared/assets/avatar.svg";
import moment from 'moment';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Imię jest wymagane'),
    surname: Yup.string()
        .required('Nazwisko jest wymagane'),
    email: Yup.string()
        .email('Nieprawidłowy email!')
        .required('Email jest wymagany'),
    tel: Yup.string()
        .min(9, "Numer musi mieć minimum 9 cyfr"),
});

const UserModal = ({
    modalState,
    setModalState,
    user: { name, surname, email, tel, _id, avatar },
    registryId,
    callendarSettings: { min, max, step },
    updateAuthenticatedUser,
    updateAuthenticatedUserRole,
    setUserAvatar
}) => {
    const initialValues = {
        name,
        surname,
        email,
        tel,
        step,
        min,
        max
    };
    const [file, setFile] = useState(null);
    return (
        <Modal
            title='Edycja danych użytkownika'
            show={modalState}
            exitButton={true}
            onCancel={() => { setFile(null); setModalState(false); }}
        >
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={async ({ name, surname, email, tel, min, max, step }) => {
                    updateAuthenticatedUser({ name, surname, email, tel }, _id);
                    updateAuthenticatedUserRole({ callendarSettings: { step, min, max, } }, registryId, 'registry');
                    if (file) {
                        const formData = new FormData();
                        formData.append("userAvatar", file);
                        await setUserAvatar(_id, formData);
                    }
                    setModalState(false);
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, setFieldValue, setFieldError, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <UploadWrapper>
                            <FileUpload
                                label="Plik dokumentu"
                                onDrop={(files) => {
                                    const filesWithPreview = files.map(file => Object.assign(file, {
                                        preview: URL.createObjectURL(file)
                                    }))
                                    setFile(filesWithPreview[0]);
                                }}
                                value={file}
                            >
                                <AvatarWrapper EditImg={EditImg}>
                                    <AvatarImage src={file !== null ? file.preview : !avatar ? AvatarPlaceholder : `${process.env.REACT_APP_SRV_URL}${avatar}`} />
                                </AvatarWrapper>
                            </FileUpload>
                        </UploadWrapper>
                        <InputRow colsTemplate="1fr 1fr">
                            <Input
                                type="text"
                                name="name"
                                label="Imię"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={errors.name}
                            />
                            <Input
                                type="text"
                                name="surname"
                                label="Nazwisko"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.surname}
                                error={errors?.surname}
                            />
                        </InputRow>
                        <InputRow colsTemplate="1fr 1fr">
                            <Input
                                type="text"
                                name="tel"
                                label="Numer telefonu"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.tel}
                                error={errors.tel}
                            />
                            <Input
                                type="text"
                                name="email"
                                label="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={errors.email}
                            />
                        </InputRow>
                        <InputRow colsTemplate="1fr 1fr 1fr">
                            <TimePicker
                                label="Krok"
                                allowEmpty={false}
                                value={moment(values.step, 'mm')}
                                onChange={e => e !== null ? setFieldValue('step', parseInt(e.format("mm"))) : setFieldError('step', 'Pole wymagane')}
                                onBlur={handleBlur}
                                name="step"
                                showSecond={false}
                                showHour={false}
                                minuteStep={5}
                                disabledMinutes={() => [0]}
                                error={errors.min}
                            />
                            <TimePicker
                                label="Początek pracy"
                                allowEmpty={false}
                                value={moment(values.min, moment.ISO_8601)}
                                onChange={e => e !== null ? setFieldValue('min', e.format("YYYY-MM-DD HH:mm:ss")) : setFieldError('min', 'Pole wymagane')}
                                onBlur={handleBlur}
                                name="min"
                                showSecond={false}
                                minuteStep={5}
                                disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 22, 23]}
                                hideDisabledOptions={true}
                                error={errors.min}
                            />
                            <TimePicker
                                label="Koniec pracy"
                                allowEmpty={false}
                                value={moment(values.max, moment.ISO_8601)}
                                onChange={e => e !== null ? setFieldValue('max', e.format("YYYY-MM-DD HH:mm:ss")) : setFieldError('max', 'Pole wymagane')}
                                onBlur={handleBlur}
                                name="max"
                                showSecond={false}
                                minuteStep={5}
                                disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 22, 23]}
                                hideDisabledOptions={true}
                                error={errors.min}
                            />
                        </InputRow>
                        <CenterBox>
                            <Button type='submit' variant='primary'>Zapisz zmiany</Button>
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default UserModal;

const Form = styled.form`
    width:400px;
    margin-top:20px;
`;

const CenterBox = styled.div`
    text-align:center;
`;

const InputRow = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: ${({ colsTemplate }) => colsTemplate};
`;


const AvatarImage = styled.img`
  border-radius: 50%;
  min-width: 150px;
  width: 150px;
  height: 150px;
`;

const AvatarWrapper = styled.div`
  position: relative;
  width: 150px;
  margin: 0 auto;
  cursor: pointer;
  ::after {
    content: ${({ EditImg }) => `url(${EditImg})`};
    width: 40px;
    height: 40px;
    position: absolute;
    background-color: #d0d0d0;
    border-radius: 50%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const UploadWrapper = styled.div`
  display:flex;
  justify-content:center;
`;