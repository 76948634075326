import React, { useEffect, useState } from "react";
import ReceptionistProfileView from "./ReceptionistProfileView";
import { connect } from "react-redux";
import { getEmployees } from '../../../redux/modules/employees';
import { updateAuthenticatedUser, updateAuthenticatedUserRole, setUserAvatar } from '../../../redux/modules/authentication';

const ReceptionistProfileContainer = ({
  user,
  userRole,
  companyId,
  getEmployees,
  doctors,
  updateAuthenticatedUser,
  updateAuthenticatedUserRole,
  setUserAvatar
}) => {
  const [userModalState, setUserModalState] = useState(false),
    [settingsModal, setSettingsModal] = useState(false);

  useEffect(() => {
    if (userRole !== null) {
      getEmployees('doctor', userRole.companyId);
    }
  }, [userRole]);

  return <ReceptionistProfileView
    user={user}
    userRole={userRole}
    doctors={doctors}
    userModalState={userModalState}
    setUserModalState={setUserModalState}
    updateAuthenticatedUser={updateAuthenticatedUser}
    updateAuthenticatedUserRole={updateAuthenticatedUserRole}
    setUserAvatar={setUserAvatar}
    settingsModal={settingsModal}
    setSettingsModal={setSettingsModal}
  />;
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  userRole: state.authentication.userRole,
  doctors: state.employees.doctors,
  // companyId: state.authentication.userRole.companyId,
});

const mapDispatchToProps = dispatch => {
  return {
    getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
    setUserAvatar: (userId, avatarData) => dispatch(setUserAvatar(userId, avatarData)),
    updateAuthenticatedUser: (userData, id) => dispatch(updateAuthenticatedUser(userData, id)),
    updateAuthenticatedUserRole: (userRoleData, id, roleName) => dispatch(updateAuthenticatedUserRole(userRoleData, id, roleName)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceptionistProfileContainer);