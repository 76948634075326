import { addMessageFromSocket, addUnreadMessage } from './reduxActions'

export default (socket, storeAPI) => {
    socket.on('message-add', message => {
        const currentState = storeAPI.getState()
        const currentOpenChannel = currentState.application.chatChannelOpen
        if (!currentOpenChannel || currentOpenChannel !== message.channel) {
            storeAPI.dispatch(addUnreadMessage(message.channel))
        }
        storeAPI.dispatch(addMessageFromSocket(message))
    })
}