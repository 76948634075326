import React from 'react';
import moment from "moment";
import styled from 'styled-components';
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { ReactComponent as removeIcon } from '../../Shared/assets/reject.svg'

const localizer = momentLocalizer(moment);
const formats = {
  timeGutterFormat: date => {
    return localizer.format(date, "HH:mm");
  },
  eventTimeRangeFormat: date => {
    let timeRangeFormat = `${localizer.format(
      date.start,
      "HH:mm"
    )} - ${localizer.format(date.end, "HH:mm")}`;
    return timeRangeFormat;
  },
  dayFormat: (date, culture) => {
    return localizer.format(date, "ddd", culture).toUpperCase();
  }
};

const DragAndDropCalendar = withDragAndDrop(Calendar);

const Toolbar = ({ }) => {
  return (
    <ToolbarContainer>
    </ToolbarContainer>
  )
};

const CustomEvent = ({ event, updateScheduleHandler }) => {
  return (
    <EventLabel>
      {event.active && <RemoveIcon
        onClick={() => updateScheduleHandler(event, 'remove')}
      />}
    </EventLabel>
  );
};


const ScheduleCalendar = ({
  userRole,
  currentHealthcenter,
  userSchedules,
  updateScheduleHandler,
  disabled,
}) => {
  const allEvents = [];
  if (userSchedules) {
    for (const schedule of userSchedules) {
      Object.values(schedule)
        .filter(({ scheduleEvents }) => scheduleEvents)
        .map(({ scheduleEvents }) =>
          allEvents.push(...scheduleEvents.map(({ start, end, ...el }) => ({
            ...el,
            start: new Date(start),
            end: new Date(end),
            active: currentHealthcenter === schedule.healthcenter
          })))
        );
    }
  }

  return (
    <StyledCalendar
      disabled={disabled}
      key={Math.random().toString(36).substr(2, 9)}
      localizer={localizer}
      startAccessor="start"
      endAccessor="end"
      events={allEvents}
      defaultDate={allEvents[0]?.start ? new Date(allEvents[0]?.start) : Date.now()}
      min={userRole ? new Date(userRole.callendarSettings.min) : new Date()}
      max={userRole ? new Date(userRole.callendarSettings.max) : new Date()}
      selectable
      resizable
      step={30}
      popup={true}
      defaultView={userRole?.weekendWorker ? "week" : "work_week"}
      views={["week", "work_week"]}
      formats={formats}
      messages={{ showMore: (e) => `+${e} więcej` }}
      draggableAccessor={({ active }) => active}
      eventPropGetter={({ active }) => ({
        style: {
          backgroundColor: '#1890FF',
          opacity: active ? '' : '.2',
          cursor: active ? 'pointer' : 'default'
        },
      })}
      components={{
        event: ({ event }) => {
          return <CustomEvent
            updateScheduleHandler={updateScheduleHandler}
            event={event}
          />;
        },
        toolbar: (props) => {
          return (
            <Toolbar
              {...props}
            />
          )
        }
      }}
      onSelectSlot={(event) => updateScheduleHandler(event, 'create')}
      onEventResize={(event) => updateScheduleHandler(event, 'resize')}
      onEventDrop={(event) => updateScheduleHandler(event, 'move')}
    />
  )
}

export default ScheduleCalendar;

const StyledCalendar = styled(DragAndDropCalendar)`
  transition: transform 0.5s;
  width: 100%;
  min-height: 100%;
  opacity:1;
  transition: 300ms;
  ${({ disabled }) => disabled && `
    opacity:.5;
    pointer-events:none;
  `}
  .rbc-calendar {
    background-color: ${({ theme }) => theme.background};
  }
  .rbc-show-more {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #fafafa;
    border-radius: 5px;
  }
  
  .rbc-allday-cell {
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD;
    ${({ view }) =>
    view === "week" || view === "work_week"
      ? `
      display: block;
      border-right: none;
      margin-top: 15px;
      `
      : `display: none;`}
  }
  .rbc-time-header-content{
    border-left: none;
  }
  .rbc-events-container{
      margin-right: 0;
  }
  .rbc-time-header-cell-single-day {
    display: flex;
  }
  .rbc-time-view,
  .rbc-month-view {
    border: none;
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: none;
  }
  .rbc-row-resource > .rbc-header {
    border-bottom: none;
    min-height: auto;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd0;
}
  .rbc-row-content {
    z-index: 0;
  }
  .rbc-time-content,
  .rbc-month-view {
    overflow-x:hidden;
    background: #fff;
  }
  .rbc-allday-cell > .rbc-row-content {
    margin-bottom: -25px;
}
  .rbc-time-content > .rbc-time-gutter {
    background-color: #fff;
  }
 .rbc-day-slot .pastTimeSlot {
    background: repeating-linear-gradient(
      -14deg,
      #eceef2,
      #eceef2 10px,
      #f5f7f9 10px,
      #f5f7f9 20px
    );
  }
  .rbc-today{
    background-color:transparent;
  }
  .rbc-label {
    background-color: #fff;
    color: ${({ theme }) => theme.lightBlack};
    top: 5px;
    position: relative;
    z-index: 5;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rbc-time-header-gutter {
    margin-right: 0;
    border-right: none;
  }
  .rbc-current-time-indicator {
    display:none;
  }
  .rbc-day-slot {
    .rbc-event {
      background-color: #4564dd;
      border: 1px solid #6780e6;
      border-radius: 4px;
    }
    .rbc-addons-dnd-dragged-event {
      opacity: 0.5;
    }
    .rbc-addons-dnd-resize-ns-anchor{
      z-index:100;
    }
  }
  /* .rbc-time-column .rbc-timeslot-group:nth-last-child(2){
    background-color:#eee!important;
  } */
  .rbc-time-column .rbc-timeslot-group:last-of-type {
    border-bottom: none;
  }
  .rbc-row-resource > .rbc-header {
    margin: 0 0 5px;
  }
`;

const CurrentMonth = styled.p`
    text-transform:capitalize;
    font-weight:600;
    color:${({ theme }) => theme.lightBlack};
`;

const ToolbarContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    padding:10px 0;
`;

const EventLabel = styled.div`
    position:absolute;
    left:0;
    top:0;
    width:100%;
    padding:3px 5px;
    display:flex;
    justify-content:space-between;
    height:100%;
    &:hover{
      >svg{
        opacity:1;
      }
    }
    span{
        color: #fff;
        font-size: 12px;
    }
`;

const RemoveIcon = styled(removeIcon)`
  position:absolute;
  right:0;
  top:2px;
  cursor:pointer;
  opacity:0;
  transition:300ms;
  path{
    fill:${({ theme }) => theme.danger}
  }
`;