import React, { useState, useEffect } from 'react';
import VisitsStatsView from './VisitsStatsView';
import { connect } from 'react-redux';
import { getVisitsByCompanyId } from '../../../redux/modules/visits';
import { getCompany } from '../../../redux/modules/company';
import { getHealthcenters } from '../../../redux/modules/healthcenter';
import { getEmployees } from '../../../redux/modules/employees';
import moment from 'moment';

const VisitsStatsContainer = ({
    getVisitsByCompanyId,
    getHealthcenters,
    getEmployees,
    getCompany,
    userRole,
    companyVisits,
    healthcenters,
    company,
    doctors,
    nurses,
    registries,
}) => {
    const [currentDate, setCurrentDate] = useState(null),
        [visitsData, setVisitsData] = useState({
            pieData: [],
            all: 0,
            future: 0,
            ended: 0,
            planned: 0,
            confirmed: 0,
            waiting: 0,
            started: 0,
            rejected: 0,
        }),
        [currentView, setCurrentView] = useState({ type: 'company', value: 'company', label: '', selectOptions: [], userData: {} }),
        [visitTimeData, setVisitTimeData] = useState({ all: [], perLength: [] }),
        [waitingTimeData, setWaitingTimeData] = useState({ all: [], perLength: [] }),
        [registryData, setRegistryData] = useState([]);

    const getVisitsTime = (visits, from = 'STARTED', to = 'ENDED') => {
        if (!currentDate) {
            return false;
        }
        let all = [], perLength = {};
        for (const { timeLogs, _id } of visits) {
            const start = timeLogs.find(({ type }) => type === from)?.time;
            const end = timeLogs.find(({ type }) => type === to)?.time;
            if (start && end) {
                const visitLength = moment(end).diff(moment(start), "seconds");
                const minutesTime = toMinutes(visitLength);
                const item = { ...minutesTime, _id, raw: visitLength };
                const roundedTime = (Math.round(minutesTime.minutes / 5) * 5) + 5;
                if (!perLength[roundedTime]) {
                    perLength[roundedTime] = [];
                }
                perLength[roundedTime].push(item);
                all.push(item)
            }
        };
        all = getMinMaxAverage(all);
        perLength = Object.entries(perLength).map(([label, visits]) => ({ label: `<${label} min`, visits: visits.length }))
        return { all, perLength }
    },
        toMinutes = (val, returnAs = 'object') => {
            let minutes = Math.floor(val / 60);
            let seconds = Math.floor(val - minutes * 60);
            if (returnAs === 'object') {
                return { minutes, seconds }
            } else if (returnAs === 'string') {
                minutes = ('0' + minutes).slice(-2)
                seconds = ('0' + seconds).slice(-2)
                return `${minutes}:${seconds}`
            }
        },
        getMinMaxAverage = (arr, itemsAreArrays) => {
            if (itemsAreArrays) {
                const newArr = [];
                for (const key of arr) {
                    let sum = 0;
                    const onlyRaw = key.map(({ raw }) => (raw));
                    for (const i of onlyRaw) {
                        sum += i;
                    }
                    let lowest = Math.min.apply(Math, onlyRaw);
                    let highest = Math.max.apply(Math, onlyRaw);
                    let average = sum / onlyRaw.length;
                    lowest = lowest === Infinity ? 0 : lowest;
                    highest = highest === -Infinity ? 0 : highest;
                    average = isNaN(average) ? 0 : average;
                    newArr.push({ average, lowest, highest })
                }
                return newArr;
            } else {
                let sum = 0;
                const onlyRaw = arr.map(({ raw }) => (raw));
                for (const i of onlyRaw) {
                    sum += i;
                }
                let lowest = Math.min.apply(Math, onlyRaw);
                let highest = Math.max.apply(Math, onlyRaw);
                let average = sum / onlyRaw.length;
                lowest = lowest === Infinity ? 0 : lowest;
                highest = highest === -Infinity ? 0 : highest;
                average = isNaN(average) ? 0 : average;
                return ({ lowest, highest, average })
            }

        },

        changeCurrentDateHandler = (direction) => {
            let newDate;
            if (direction === 'next') {
                newDate = moment(currentDate).add(1, 'months')._d;
            } else if (direction === 'prev') {
                newDate = moment(currentDate).subtract(1, 'months')._d;
            } else if (direction === 'today') {
                newDate = moment()._d;
            }
            localStorage.setItem('statsCurrentDate', newDate);
            setCurrentDate(newDate);
        },
        changeViewHandler = ({ label, value, type, userData }) => {
            localStorage.setItem('statsCurrentView', JSON.stringify({ label, value, type }));
            setCurrentView(prev => ({ ...prev, value, type, label, userData }))
        };

    useEffect(() => {
        if (userRole) {
            const currentDateFromLocalStorage = localStorage.getItem('statsCurrentDate') ?? moment()._d;
            setCurrentDate(currentDateFromLocalStorage);
            getHealthcenters(userRole.companyId);
            getEmployees('doctor', userRole.companyId);
            getEmployees('registry', userRole.companyId);
            getEmployees('nurse', userRole.companyId);
            if (!company) {
                getCompany(userRole.companyId);
            }
        }
    }, [userRole]);
    useEffect(() => {
        if (userRole && currentDate) {
            getVisitsByCompanyId(
                userRole.companyId,
                moment(currentDate).date(1).hour(0).minute(0).second(0)._d,
                moment(currentDate).date(moment(currentDate).daysInMonth()).hour(23).minute(59).second(59)._d,
            );
        }
    }, [currentDate]);
    useEffect(() => {
        if (companyVisits && healthcenters.length > 0) {
            let visits = companyVisits;
            if (currentView.type === 'employee') {
                visits = visits.filter(({ doctor }) => doctor === currentView.value);
            }
            if (currentView.type === 'healthcenter') {
                visits = visits.filter(({ healthcenterId }) => healthcenterId === currentView.value);
            }
            const endedVisits = visits.filter(({ state }) => state === 'ENDED');
            const futureVisits = visits.filter(({ state }) => state !== 'ENDED');
            const plannedVisits = visits.filter(({ state }) => state === 'PLANNED');
            const confirmedVisits = visits.filter(({ state }) => state === 'CONFIRMED');
            const waitingVisits = visits.filter(({ state }) => state === 'WAITING');
            const startedVisits = visits.filter(({ state }) => state === 'STARTED');
            const rejectedVisits = visits.filter(({ state }) => state === 'REJECTED');
            const registryObj = {};
            const employees = [...doctors, ...registries];
            for (const { registeringPerson } of visits) {
                const { userId } = employees.find(({ userId }) => userId._id === registeringPerson) ?? { userId: { _id: 'Missing' } };
                if (!registryObj[userId._id]) {
                    registryObj[userId._id] = { count: 0, userData: userId };
                }
                registryObj[userId._id].count += 1;
            }
            setRegistryData(Object.values(registryObj));
            setVisitTimeData(getVisitsTime(endedVisits));
            setWaitingTimeData(getVisitsTime(endedVisits, 'WAITING', 'STARTED'));
            setVisitsData({
                all: visits.length,
                future: futureVisits.length,
                ended: endedVisits.length,
                planned: plannedVisits.length,
                confirmed: confirmedVisits.length,
                waiting: waitingVisits.length,
                started: startedVisits.length,
                rejected: rejectedVisits.length,
            })
        }
    }, [companyVisits, healthcenters, currentView]);
    useEffect(() => {
        if (company) {
            const currentViewFromLocalStorage = JSON.parse(localStorage.getItem('statsCurrentView')) ?? { label: company.name, type: 'company' };
            const selectOptions = [
                { label: company.name, value: 'company', type: 'company' },
                { label: 'Placówka', options: healthcenters.map(({ name, _id }) => ({ label: name, value: _id, type: 'healthcenter' })) },
                { label: 'Pracownik', options: [...doctors, ...nurses].map(({ _id, userId: { name, surname, activeRole, avatar } }) => ({ value: _id, label: `${name} ${surname}`, type: 'employee', userData: { avatar, activeRole } })) },
            ]
            const userData = currentViewFromLocalStorage.type === 'employee' ? [...doctors, ...nurses].find(({ _id }) => _id === currentViewFromLocalStorage.value)?.userId ?? {} : {};
            setCurrentView(prev => ({ ...prev, ...currentViewFromLocalStorage, selectOptions, userData }));
        }
    }, [company, healthcenters, doctors, nurses]);

    return (
        <VisitsStatsView
            currentView={currentView}
            currentDate={currentDate}
            visitTimeData={visitTimeData}
            waitingTimeData={waitingTimeData}
            changeCurrentDateHandler={changeCurrentDateHandler}
            changeViewHandler={changeViewHandler}
            toMinutes={toMinutes}
            visitsData={visitsData}
            registryData={registryData}
        />
    )
}


const mapStateToProps = state => ({
    userRole: state.authentication.userRole,
    company: state.company.data,
    companyVisits: state.visits.companyVisits,
    healthcenters: state.healthcenter.data,
    doctors: state.employees.doctors,
    nurses: state.employees.nurses,
    registries: state.employees.registries,
});

const mapDispatchToProps = dispatch => {
    return {
        getVisitsByCompanyId: (companyId, start, end) => dispatch(getVisitsByCompanyId(companyId, start, end)),
        getHealthcenters: (companyId) => dispatch(getHealthcenters(companyId)),
        getCompany: (companyId) => dispatch(getCompany(companyId)),
        getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisitsStatsContainer);