import React from "react";
import Container from "../../Shared/Container";
import Button from "../../Shared/Button";
import Select from '../../Shared/Select';
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { theme } from '../../Shared/theme';

// import moneyIcon from '../../Shared/assets/money.svg';
// import timeIcon from '../../Shared/assets/time.svg';
// import visitsTodayIcon from '../../Shared/assets/visits-today.svg';
import doctorIcon from "../../Shared/assets/doctor.svg";
import personIcon from "../../Shared/assets/person.svg";
import { ReactComponent as VisitsTodayIcon } from "../../Shared/assets/visits-today.svg";
import { ReactComponent as MoneyIcon } from '../../Shared/assets/money.svg';
import { ReactComponent as TimeIcon } from '../../Shared/assets/time.svg';

const AdminDashboardView = ({
  doctors,
  registries,
  nurses,
  companyVisits,
  statsData,
  healthcenters,
  currentHealthcenter,
  setCurrentHealthcenter,
  servicesData,
}) => {
  const selectOptions = healthcenters.map(({ _id, name }) => ({ label: name, value: _id }));

  return (
    <Wrapper>
      <Subwrapper>
        <div className='line-1'>
          <StyledContainer workers title="Pracownicy">
            <Text>W Twojej firmie pracuje:</Text>
            <WorkerWrapper>
              <Icon size='20px' src={doctorIcon} />
              <Text>{doctors.length === 1 ? `${doctors.length} lekarz` : `${doctors.length} lekarzy`}</Text>
            </WorkerWrapper>
            <WorkerWrapper>
              <Icon size='20px' src={personIcon} />
              <Text>{nurses.length === 1 ? `${nurses.length} pielęgniarka` : `${nurses.length} pielęgniarek`}</Text>
            </WorkerWrapper>
            <WorkerWrapper>
              <Icon size='20px' src={personIcon} />
              <Text>{registries.length === 1 ? `${registries.length} rejestrator` : `${registries.length} rejestratorzy`}</Text>
            </WorkerWrapper>
            <Text>łącznie {doctors.length + registries.length + nurses.length} osób.</Text>
            <StyledLink to="/employees"><StyledButton>Zarządzaj</StyledButton></StyledLink>
          </StyledContainer>
          <StyledContainer stats title="Miesięczne statystyki">
            <StatsBox>
              <StatsItem color={theme.primary}>
                <StatsNumber><StyledVisitsTodayIcon /> {companyVisits?.length}</StatsNumber>
                <StatsDesc>Wszystkie wizyty</StatsDesc>
              </StatsItem>
              <StatsItem color={theme.green}>
                <StatsNumber><StyledMoneyIcon /> {statsData.income} PLN</StatsNumber>
                <StatsDesc>Zainkasowane pieniądze</StatsDesc>
              </StatsItem>
              <StatsItem color={theme.lightBlack}>
                <StatsNumber><StyledTimeIcon /> {statsData.averageTime}</StatsNumber>
                <StatsDesc>Średni czas wizyt</StatsDesc>
              </StatsItem>
            </StatsBox>
            <StyledLink to="/income"><StyledButton>Więcej...</StyledButton></StyledLink>
          </StyledContainer>
        </div>
        <div className='line-2'>
          <StyledContainer payment title="Płatności">
            <Text>System opłacony do dnia <PayedTo>02.03.2020</PayedTo></Text>
            <List colsTemplate="33% 33% 33%" procedures>
              <ListItem>
                <ListItemCol>
                  <Text bold>Nazwa</Text>
                </ListItemCol>
                <ListItemCol>
                  <Text bold>Data</Text>
                </ListItemCol>
                <ListItemCol>
                  <Text bold>Status</Text>
                </ListItemCol>
              </ListItem>
              <ListItem>
                <ListItemCol>
                  <Text>Faktura nr. 00000/00</Text>
                </ListItemCol>
                <ListItemCol>
                  <Text>30.04.2020</Text>
                </ListItemCol>
                <ListItemCol>
                  <InvoiceStatus isPaid={true}>Opłacona</InvoiceStatus>
                </ListItemCol>
              </ListItem>
              <ListItem>
                <ListItemCol>
                  <Text>Faktura nr. 00000/00</Text>
                </ListItemCol>
                <ListItemCol>
                  <Text>30.04.2020</Text>
                </ListItemCol>
                <ListItemCol>
                  <InvoiceStatus isPaid={false}>Nieopłacona</InvoiceStatus>
                </ListItemCol>
              </ListItem>
              <ListItem>
                <ListItemCol>
                  <Text>Faktura nr. 00000/00</Text>
                </ListItemCol>
                <ListItemCol>
                  <Text>30.04.2020</Text>
                </ListItemCol>
                <ListItemCol>
                  <InvoiceStatus isPaid={false}>Nieopłacona</InvoiceStatus>
                </ListItemCol>
              </ListItem>
              <ListItem>
                <ListItemCol>
                  <Text>Faktura nr. 00000/00</Text>
                </ListItemCol>
                <ListItemCol>
                  <Text>30.04.2020</Text>
                </ListItemCol>
                <ListItemCol>
                  <InvoiceStatus isPaid={false}>Nieopłacona</InvoiceStatus>
                </ListItemCol>
              </ListItem>
            </List>
            <StyledLink to="/company"><StyledButton>Więcej...</StyledButton></StyledLink>
          </StyledContainer>
          <StyledContainer healthCenter title="Placówka">
            <StyledSelect
              center
              name="healthCenterSelect"
              options={selectOptions}
              value={selectOptions.find(({ value }) => value === currentHealthcenter?._id)}
              onChange={({ value }) => setCurrentHealthcenter(healthcenters.find(({ _id }) => _id === value))}
            />
            <Text bold center>Najczęściej wykonywane procedury w tym miesiącu</Text>
            {servicesData.length > 0 ? <List colsTemplate="85% 15%" healthCenter>
              <ListItem>
                <ListItemCol>
                  <Text bold>
                    Nazwa
                </Text>
                </ListItemCol>
                <ListItemCol>
                  <Text bold>
                    Ilość
                </Text>
                </ListItemCol>
              </ListItem>
              {servicesData.map(({ name, count, _id }) => (
                <ListItem key={_id}>
                  <ListItemCol>
                    <Text>{name}</Text>
                  </ListItemCol>
                  <ListItemCol>
                    <Text>{count}</Text>
                  </ListItemCol>
                </ListItem>
              ))}
            </List>
              :
              <NoData>W tym miesiącu nie wykonano jeszcze żadnych procedur.</NoData>
            }
            <StyledLink to={`/healthcenters?id=${currentHealthcenter?._id}`}><StyledButton>Więcej...</StyledButton></StyledLink>
          </StyledContainer>
        </div>
      </Subwrapper>
    </Wrapper>
  );
};

export default AdminDashboardView;

const Text = styled.p`
  font-size:${({ fontSize, theme }) => fontSize ? fontSize : theme.font.size.xxxs};
  font-weight: ${({ bold }) =>
    bold ? 600 : 'normal'
  };
  text-align: ${({ center }) =>
    center && 'center'
  };
`;

const PayedTo = styled.span`
  font-weight:600;
  color: ${({ theme }) => theme.green};
`;

const InvoiceStatus = styled.p`
  font-weight:600;
  font-size: ${({ theme }) => theme.font.size.xxxs};
  >p{
    width:33%;
  }
  ${({ isPaid, theme }) =>
    isPaid
      ? `
      color: ${theme.green}
    `: `
      color ${theme.danger}
    `
  }
`;

const List = styled.ul`
  margin-top:5px;
  grid-template-columns:${({ colsTemplate }) => colsTemplate};
  ${({ healthCenter }) =>
    healthCenter ? '& li:not(:first-child) span:first-child p{font-size:12px;}' : ''
  };
`

const ListItem = styled.li`
  display:grid;
  grid-template-columns:inherit;
  padding:10px 0;
  &:not(:last-child){
    border-bottom:1px solid ${({ theme }) => theme.grey}
  };
`;

const ListItemCol = styled.span`
  text-align:center;
  &:first-child{
    padding-left: 15px;
    width:85%;
    text-align:left;
  }
`;

const Wrapper = styled.div`
  padding: 25px 0;
  width: 100%;
`;

const Subwrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  .line-1{
    display:grid;
    grid-template-columns: 1fr 2fr;
  }
  .line-2{
    display:grid;
    grid-template-columns: 3fr 2fr;
  }
`;

const StyledContainer = styled(Container)`
  position:relative;
  padding-bottom:50px;
  ${({payment}) => payment && `
  opacity: 30%;
  pointer-events: none;
  `}
`;

const Icon = styled.img`
  margin-right: 15px;
  ${({ size }) => size && `
    width: ${size};
    height: ${size};
  `}
`;

const WorkerWrapper = styled.div`
  display: flex;
  align-items:center;
  margin: 10px 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  position:absolute;
  right:20px;
  bottom:15px;
`;

const StyledButton = styled(Button)`
    margin-left: auto;
    margin-right: 0;
    display: block;
`;

const StatsBox = styled.div`
  align-items:center;
  justify-content:center;
  display:grid;
  grid-template-columns:1fr 1.5fr 1fr;
  margin-top: 12px;
`;

const StatsItem = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  color:${({ color }) => color ?? '#000'};
  padding:10px 5px;
  &:nth-child(2){
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  p{
    color:inherit;
    >img{
      margin-right:0;
    }
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom:10px;
`;

const NoData = styled.p`
  font-size:14px;
  text-align:center;
  padding:20px 0;
`;

const iconStatsStyles = css`
    height: 30px;
    width: 30px;
    margin-right:10px;
    @media (min-width: 1135px){
      height: 35px;
      width: 35px;
    }
`;

const StyledVisitsTodayIcon = styled(VisitsTodayIcon)`
${iconStatsStyles};
path {
  fill: ${({ theme }) => theme.primary};
  stroke: ${({ theme }) => theme.primary};
  stroke-width: 20px;
}
`;

const StyledMoneyIcon = styled(MoneyIcon)`
${iconStatsStyles};
path {
  fill: #57c754;
}
`;

const StyledTimeIcon = styled(TimeIcon)`
${iconStatsStyles};
path {
  fill: ${({ theme }) => theme.lightBlack};
}
`;

const StatsNumber = styled.div`
  display: flex;
  align-items: center;
  font-size:20px;
  font-weight: 600;
  @media (min-width: 1135px){
    font-size: 24px;
  }
`;

const StatsDesc = styled.p`
  font-weight: 600;
  font-size: 12px;
  margin-top: 5px;
  @media (min-width: 1135px){
    font-size: 16px;
    margin-top: 10px;
  }
`;