import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import EditPenIconUnderline from './assets/edit-pen-underline.svg';
import CollapseIcon from './assets/angle-down.svg';

const DocumentItem = ({
  documentData: { originalName, uploadDate, description, path, _id },
  setModalState,
  padding,
  variant
}) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Wrapper key={_id}>
      <Top padding={padding} variant={variant}>
        <Name>
          {originalName?.length > 30 ? `${originalName.substring(0, 25)}...${originalName.substring(originalName?.length - 3, originalName?.length)}` : originalName}
        </Name>
        {variant !== 'dashboard' &&
        <Date>
          {moment(uploadDate).format('DD.MM.YYYY HH:mm')}
        </Date>
        }

        <Actions>
          <StyledLink rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_SRV_URL}${path}`}>Zobacz</StyledLink>
          {variant !== 'dashboard' &&
          <EditPenUnderline src={EditPenIconUnderline} onClick={() => setModalState({ shown: true, editId: _id })} />
          }
          {variant !== 'dashboard' &&
          <CollapseBtn collapsed={collapsed} onClick={() => setCollapsed(prev => !prev)} />
          }

        </Actions>
      </Top>
      <Bot collapsed={collapsed}>
        <Heading>Opis</Heading>
        <Description>
          {description ? description : 'Brak'}
        </Description>
      </Bot>
    </Wrapper>
  )
}

export default DocumentItem;

const Wrapper = styled.li`
  &:not(:last-child){
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }
`;

const Heading = styled.p`
  font-weight:600;
`;

const Name = styled.p`
font-size: 12px;
`;

const Date = styled.p`
  text-align:center;
`;

const Description = styled.p`
  padding: 10px;
`;

const Actions = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
`;

const StyledLink = styled.a`
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 12px;
  cursor: pointer;
  margin: 0 2px;
  background-color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  color: #fff;
  text-decoration:none;
  transition: 200ms;
  &:hover {
    background-color: #2898ff;
    border: 1px solid #2898ff;
  }
`;

const EditPenUnderline = styled.img`
  transition:300ms;
  width: 25px;
  height: 25px;
  margin: 0 15px;
  cursor: pointer;
  filter: invert(81%) sepia(0%) saturate(51%) hue-rotate(195deg) brightness(86%) contrast(81%);
  &:hover {
    filter: invert(98%) sepia(0%) saturate(476%) hue-rotate(144deg) brightness(89%) contrast(81%);
  }
`;

const Top = styled.div`
  display:grid;
  align-items:center;
  padding:20px 10px 10px;
  grid-template-columns: 1fr 1fr 1fr;
  ${({ variant }) =>
    variant === 'dashboard' && `
      padding:0;
      grid-template-columns: 2fr 1fr;
    `
  }
`;

const Bot = styled.div`
  padding: 0 10px;
  max-height:0;
  overflow:hidden;
  transition:600ms;
  ${({ collapsed }) =>
    !collapsed && `
        border-color: ${({ theme }) => theme.grey};
        max-height:559px;
        padding: 0 10px 10px;
    `
  }
`;

const CollapseBtn = styled.button`
  background-color:#f0f5ff;
  border: none;
  border-radius: 50%;
  width:33px;
  height:33px;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  transition:600ms;
  ${({ collapsed }) =>
    !collapsed && `transform:rotate(180deg);`
  }
  &:hover{
    background-color: #e6ebf5;
  }
  &::before{
    content: url(${CollapseIcon});
    width:16px;
    line-height: 0;
    height:14px;
    filter: invert(46%) sepia(76%) saturate(2961%) hue-rotate(190deg) brightness(100%) contrast(102%);
  }
`;