import React from "react";
import styled from "styled-components";
import Container from "../Shared/Container";
import Textarea from "../Shared/Textarea";
import Input from "../Shared/Input";
import FileUpload from "../Shared/FileUpload";
import Button from "../Shared/Button";
import Gallery from "../Shared/Gallery";
import Spinner from "../Shared/Spinner";
import { Formik } from "formik";
import * as Yup from "yup";
import { ReactComponent as MessageSendedImg } from "../Shared/assets/message-sended.svg";

const validationSchema = Yup.object().shape({
  text: Yup.string()
    .required("Opis błędu jest wymagany")
    .min(3, "Opis powinien mieć minimum 3 znaków"),
  title: Yup.string()
    .required("Tytuł jest wymagany")
    .min(3, "Tytuł powinien mieć minimum 3 znaków"),
});

const ReportErrorView = ({
  reportError,
  reportLoading,
  handleSend,
  reportSended,
  setReportSended,
}) => {
  return (
    <Wrapper>
      <StyledContainer title="Zgłoś błąd">
        {reportLoading ? (
          <Spinner />
        ) : reportSended ? (
          <SuccessWrapper>
            <StyledMessageSendedImg />
            <SuccessMessage>
              Dziękujemy, zgłoszenie zostało wysłane pomyślnie.
            </SuccessMessage>
            <Button
              variant="primary"
              type="button"
              onClick={() => setReportSended(false)}
            >
              Utwórz kolejne zgłoszenie
            </Button>
          </SuccessWrapper>
        ) : (
          <Formik
            initialValues={{
              files: [],
              title: "",
              text: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSend(values);
            }}
          >
            {({
              handleChange,
              setFieldValue,
              handleSubmit,
              values,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Input
                  label="Tytuł"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  error={errors.title}
                />
                <StyledTextarea
                  label="Opis"
                  name="text"
                  onChange={handleChange}
                  value={values.text}
                  error={errors.text}
                />
                <FileUpload
                  onDrop={(files) => {
                    const filesWithPreview = files.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    );
                    setFieldValue("files", [
                      ...values.files,
                      ...filesWithPreview,
                    ]);
                  }}
                  placeholder="Załącz pliki do zgłoszenia"
                />
                {values.files.length > 0 && (
                  <>
                    <Label>Załączone pliki</Label>
                    <Gallery
                      images={values.files?.map(
                        ({ preview, originalName }, index) => ({
                          name: originalName,
                          src: preview,
                          _id: index,
                        })
                      )}
                      showSmallDeleteBtns={true}
                      variant="dashboard"
                      deleteModalHandler={(index) => {
                        setFieldValue(
                          "files",
                          values.files?.filter((_, i) => i !== index)
                        );
                      }}
                    />
                  </>
                )}
                <CenterBox>
                  <Button variant="primary" type="submit">
                    Wyślij zgłoszenie
                  </Button>
                </CenterBox>
              </Form>
            )}
          </Formik>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

export default ReportErrorView;

const Wrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
`;

const StyledContainer = styled(Container)`
  margin: 10px auto;
  max-width: 500px;
`;

const Form = styled.form``;

const StyledTextarea = styled(Textarea)`
  textarea {
    min-height: 200px;
  }
`;

const CenterBox = styled.div`
  text-align: center;
`;

const Label = styled.p`
  margin-left: 2px;
  display: block;
  color: #484848;
  font-size: 14px;
`;

const SuccessMessage = styled.h1`
  color: #737373;
  text-align: center;
  margin: 40px 0 50px;
`;

const StyledMessageSendedImg = styled(MessageSendedImg)`
  width: 100%;
  height: 250px;
`;

const SuccessWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`;