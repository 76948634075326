import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../Shared/Modals/Modal';
import Input from '../../Shared/Input';
import DatePicker from '../../Shared/DatePicker';
import Button from '../../Shared/Button';
import { Formik } from 'formik';
// import * as Yup from 'yup';
import Select from "../../Shared/Select";
import { connect } from "react-redux";
import { getCompany } from '../../../redux/modules/company';
import { getMedicalData } from "../../../redux/modules/medicalRest";
import useDebouncer from "../../Shared/Hooks/useDebouncer";
import { medPriceOptions, additionalPowersOptions } from "../constants";

const PrescriptionModal = ({
    modalState,
    setModalState,
    company,
    user,
    userRole,
    getCompany,
    currentPatient,
    meds,
    medsdiagnosisLoading,
    getMedicalData,
    type
}) => {
    const [selectMedsOptions, setSelectMedsOptions] = useState([]);
    const [
        setMedsDiagnosisDebouncer,
        medsDiagnosisDebouncerLoading,
    ] = useDebouncer();
    const initalValues = {
        prescriptionNumber: "",
        additionalPower: '',
        prescriptionMeds: [],
        realizationDateFrom: new Date()
    };

    // const validationSchema = Yup.object().shape({
    //     originalName: Yup.string().required('Nazwa dokumentu jest wymagana'),
    //     uploadDate: Yup.string(),
    //     description: Yup.string(),
    // });


    useEffect(() => {
        if (meds.length === 0) {
            getMedicalData("medicalProducts");
        }
    }, []);

    useEffect(() => {
        setSelectMedsOptions(meds.map(el => ({
            label: `${el.nazwaProduktu}, ${el.moc}`,
            options: el.opakowania.map(({ _attributes }) => (
                {
                    label: `${_attributes.wielkosc} ${_attributes.jednostkaWielkosci}`,
                    value: _attributes.id,
                    data: { ...el, jednostkaWielkosci: _attributes.jednostkaWielkosci, wielkosc: _attributes.wielkosc, kategoriaDostepnosci: _attributes.kategoriaDostepnosci },
                }
            )),
        })))
    }, [meds]);

    const getMedicalDataHandler = (target, searchValue) => {
        if (searchValue !== "") {
            setMedsDiagnosisDebouncer(() => getMedicalData(target, { value: searchValue }));
        } else if (
            (searchValue === "") ||
            meds.length === 0
        ) {
            setMedsDiagnosisDebouncer(() => getMedicalData(target, { value: searchValue }));
        }
    };
    return (
        <Modal
            title="Wystawianie recepty"
            exitButton={true}
            onCancel={() => { setModalState(false) }}
            show={modalState}
        >
            <Formik
                // validationSchema={validationSchema}
                initialValues={initalValues}
                // enableReinitialize
                onSubmit={(values) => {
                    setModalState(false);
                }}
            >
                {({ handleSubmit, values, setFieldValue, handleChange, errors }) => (
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <DocumentContent>
                            <Section padding>
                                <TransparentInput
                                    name="prescriptionNumber"
                                    value={values.prescriptionNumber}
                                    label="Recepta"
                                    onChange={handleChange}
                                    direction="row"
                                    placeholder="uzupełnij numer"
                                />
                                <Text>{company?.name}</Text>
                                <Text>{company?.zipCode} {company?.address?.City}, {company?.address?.Street} {company?.address?.houseNumber}</Text>
                                <Text>REGON: {company?.regonNumber}</Text>
                                <Text marginTop>Świadczeniodawca</Text>
                            </Section>
                            <Section flex>
                                <PatientInfo>
                                    <Text>Pacjent</Text>
                                    <div>
                                        <Text>{currentPatient?.name}</Text>
                                        <Text>{currentPatient?.address?.street} {currentPatient?.address?.houseNumber}, {currentPatient?.address?.city}</Text>
                                    </div>
                                    <Text>PESEL</Text>
                                    <Text>{currentPatient?.pesel}</Text></PatientInfo>
                                <RightSection>
                                    <Department>
                                        <Text>Oddział NFZ</Text>
                                        <Text>06</Text>
                                    </Department>
                                    <AdditionalPowers>
                                        <Text center>Uprawnienia dodatkowe</Text>
                                        <Select
                                            name="additionalPower"
                                            options={additionalPowersOptions}
                                            value={values.additionalPower?.value}
                                            onChange={({ value }) => setFieldValue("additionalPower", value)}
                                            placeholder="wybierz"
                                            styles={{ control: (styles, { isFocused }) => ({ ...styles, border: isFocused ? 'none' : 'none' }) }}
                                        />
                                    </AdditionalPowers>
                                </RightSection>
                            </Section>
                            <Section flex>
                                <PrescriptionType>
                                    <Text bold center>{type === "recepta" ? 'Rp' : 'Rpw'}</Text>
                                </PrescriptionType>
                                <Repayment>
                                    <Text bold center>Odpłatność</Text>
                                </Repayment>
                            </Section>
                            <Section meds>
                                {values.prescriptionMeds.map(med => <MedContainer>
                                    <MedName>{med.name}</MedName>
                                    <MedPrice>
                                        <Select
                                            value={{ label: med.price, value: med.price }}
                                            onChange={({ value }) => setFieldValue("prescriptionMeds", values.prescriptionMeds.map(choosedMed => choosedMed.name === med.name ? { name: choosedMed.name, price: value } : choosedMed))}
                                            placeholder="wybierz"
                                            options={medPriceOptions}
                                            name='medPrice'
                                            styles={{ control: (styles, { isFocused }) => ({ ...styles, border: isFocused ? 'none' : 'none' }) }}
                                        />
                                    </MedPrice>
                                </MedContainer>)}
                                <Select
                                    value={{ label: 'uzupełnij nazwę leku' }}
                                    onChange={({ data }) => setFieldValue("prescriptionMeds", [...values.prescriptionMeds, { name: `${data.nazwaProduktu}, ${data.moc}`, price: "100%" }])}
                                    onInputChange={(val) => { getMedicalDataHandler('medicalProducts', val) }}
                                    options={selectMedsOptions}
                                    isLoading={medsdiagnosisLoading}
                                    name='prescriptionMeds'
                                    noOptionsMessage={() => <p>Nie znaleziono takiego leku</p>}
                                    searchInputWithoutFilter={true}
                                    styles={{ control: (styles, { isFocused }) => ({ ...styles, border: isFocused ? 'none' : 'none' }) }}
                                />
                            </Section>
                            <Section bottom>
                                <DateOfIssue>
                                    <Text>Data wystawienia</Text>
                                    <Text>{new Date().toLocaleDateString()}</Text>
                                </DateOfIssue>

                                <DateIfRealization>
                                    <Text>Data realizacji "od dnia"</Text>
                                    <TransparentDatePicker
                                        dateFormat="dd.MM.yyyy"
                                        selected={values.realizationDateFrom}
                                        onChange={(value) => setFieldValue('realizationDateFrom', value)}
                                    />
                                </DateIfRealization>
                                <DoctorSign>
                                    <Text>Dane i podpis lekarza</Text>
                                    <Text>{user.name}</Text>
                                </DoctorSign>
                            </Section>
                        </DocumentContent>
                        <CenterBox>
                            <Button onClick={handleSubmit} type='submit' variant='primary'>Wystaw</Button>
                            {<Button onClick={() => { setModalState(false); }} type='button' variant='danger'>Porzuć</Button>}
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}
const mapStateToProps = state => ({
    currentPatient: state.patients.currentPatient,
    company: state.company.data,
    user: state.authentication.user,
    userRole: state.authentication.userRole,
    medsdiagnosisLoading: state.medsdiagnosis.loading,
    meds: state.medicalRest.meds,
    // healthcenters: state.healthcenter.data,
    // doctors: state.employees.doctors,
    // registries: state.employees.registries,
});


const mapDispatchToProps = dispatch => {
    return {
        getCompany: (companyId) => dispatch(getCompany(companyId)),
        getMedicalData: (target, query) => dispatch(getMedicalData(target, query)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrescriptionModal);

const Form = styled.form`
    width: 500px;
    margin-top: 20px;
`;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;

const DocumentContent = styled.div`
    border: 1px solid #cccccc;
`;

const TransparentDatePicker = styled(DatePicker)`
.react-datepicker__input-container input {
    border: 1px solid transparent;
    text-align: center;
}

`;
const MedContainer = styled.div`
display: flex;
`;
const MedName = styled.div`
    font-size: 14px;
    padding: 10px;
    width: 80%;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
`;
const MedPrice = styled.div`
border-bottom: 1px solid #cccccc;
width: 20%;
display: flex;
    justify-content: center;
    align-items: center;
`
const Section = styled.div`
    border-bottom: 1px solid #ccc;
    width: 100%;
    ${({ padding }) => padding && `
    padding: 20px;`};
    ${({ flex }) => flex && `
    display: flex;`};
    ${({ bottom }) => bottom && `
    display: grid;
    grid-template-columns: 2fr 2fr;
    border-bottom: none;
    `};
    ${({ meds }) => meds && `
        min-height: 100px;
        `};
`;
const Text = styled.p`
    font-size: 14px;
    ${({ center }) => center && `
    text-align: center;`}
      ${({ marginTop }) => marginTop && `
    margin-top: 10px;`}
    ${({ bold }) => bold && `
    font-weight: 800;`}
`;

const PatientInfo = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr;
    width: 80%;
    border-right: 1px solid #cccccc;
    padding: 20px;
`;

const RightSection = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
`;
const Department = styled.div`
    border-bottom: 1px solid #cccccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
`;
const AdditionalPowers = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    `;

const PrescriptionType = styled.div`
    width: 80%;
    border-right: 1px solid #cccccc;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    align-items: center;
`;

const Repayment = styled.div`
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DateOfIssue = styled.div`
display: flex;
flex-direction: column;
align-items: center;
border-bottom: 1px solid #cccccc;
padding: 10px;
`;

const DateIfRealization = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 10px;
`;

const DoctorSign = styled.div`
padding-top: 12px;
display: flex;
flex-direction: column;
align-items: center;
grid-row: 1 / 3;
grid-column: 2;
border-left: 1px solid #cccccc;
`;

const InputRow = styled.div`
    display:grid;
    gap:10px;
    grid-template-columns: 1fr 1fr;
`;

const StyledInput = styled(Input)`
    width:100%;
`;

const TransparentInput = styled(Input)`
    border: 1px solid transparent;
&:focus{
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    border-bottom: 2px solid ${({ theme }) => theme.primary};
}
`;