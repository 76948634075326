const CALENDAR_PAGE_LOADED = "CALENDAR_PAGE_LOADED";
const PATIENTS_PAGE_LOADED = "PATIENTS_PAGE_LOADED";
const DASHBOARD_PAGE_LOADED = "DASHBOARD_PAGE_LOADED";
const SET_CALENDAR_SOCKET = "SET_CALENDAR_SOCKET";
export const calendarPageLoaded = () => ({
    type: CALENDAR_PAGE_LOADED
})

export const patientsPageLoaded = () => ({
    type: PATIENTS_PAGE_LOADED
})

export const dashboardPageLoaded = () => ({
    type : DASHBOARD_PAGE_LOADED
})

export const setCalendarSocket = (socketId) => ({
  type: SET_CALENDAR_SOCKET,
  payload: socketId
})

const SET_CHAT_CHANNEL_OPEN = "SET_CHAT_CHANNEL_OPEN"

export const setChatChannelOpen = channelId => ({
  type: SET_CHAT_CHANNEL_OPEN,
  payload: channelId
})

const initialState = {
    currentPage : '',
    calendarSocket: '',
    chatChannelOpen: ''
  };
  
  //CALENDAR REDUCER-----------------------------------------------
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_CHAT_CHANNEL_OPEN:{
          return{
            ...state,
            chatChannelOpen: action.payload
          }
      }
      case PATIENTS_PAGE_LOADED: {
        return {
          ...state,
          currentPage: "PATIENTS"
        }
      }
      case CALENDAR_PAGE_LOADED: {
        return {
          ...state,
          currentPage: "CALENDAR"
        };
      }
      case DASHBOARD_PAGE_LOADED: {
        return {
          ...state,
          currentPage: "DASHBOARD"
        };
      }
      case SET_CALENDAR_SOCKET : {
        return {
          ...state,
          calendarSocket: action.payload
        };
      }
      default:
        return state;
    }
  };