import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../Shared/Modals/Modal';
import Button from '../../Shared/Button';
import FileUpload from '../../Shared/FileUpload';

const ImportModal = ({
    modalState: { target, shown },
    setModalState,
    importFileHandler,
}) => {
    const [file, setFile] = useState(null),
        [error, setError] = useState(null);

    const submitHandler = () => {
        importFileHandler(file, target);
        setModalState(false);
        setFile(null);
    },
        resetModal = () => {
            setFile(null);
            setError(null);
            setModalState({ shown: false, target: null });
        };
    return (
        <Modal
            title={`Importowanie ${target === 'element' ? 'elementu' : 'Zestawu'}`}
            show={shown}
            exitButton={true}
            onCancel={resetModal}
        >
            <Form>
                <FileUpload
                    onDrop={(files) => {
                        if (files[0] !== null && files[0]?.type !== 'application/json') {
                            setFile(null)
                            setError('Złe rozszerzenie pliku, tylko pliki JSON są akceptowane!');
                        } else if (files[0] !== null && files[0]?.type === 'application/json') {
                            setError(null);
                            setFile(files[0]);
                        }
                    }}
                    value={file}
                    error={error}
                />
                <CenterBox>
                    <Button
                        onClick={submitHandler}
                        variant='primary'
                        disabled={file === null}
                    >
                        Dodaj szablon
                    </Button>
                </CenterBox>
            </Form>
        </Modal>
    )
}

export default ImportModal;

const Form = styled.div`
    width:400px;
    padding-top:20px;
`;

const CenterBox = styled.div`
    text-align:center;
`;