import React, { useState, useEffect } from "react";
import DoctorProfileView from "./DoctorProfileView";
import { connect } from 'react-redux';
import { updateAuthenticatedUser, updateAuthenticatedUserRole, setUserAvatar } from '../../../redux/modules/authentication';
import { getEmployeeVacations, getUserVacationsUsedDays, addUserVacations, deleteUserVacations } from '../../../redux/modules/employees';
import { getUserSchedule, createSchedule, updateSchedule } from '../../../redux/modules/schedule';
import { getHealthcenters } from '../../../redux/modules/healthcenter';
import moment from 'moment';
import { scheduleWeekDays } from '../../Shared/enums';
import useDebouncer from '../../Shared/Hooks/useDebouncer';
import { nextTutorialStep } from '../../../redux/modules/tutorial';
import { toast } from 'react-toastify';
import Loader from '../../Shared/Loader';

const DoctorProfileContainer = ({
  user,
  userRole,
  vacations,
  updateAuthenticatedUser,
  updateAuthenticatedUserRole,
  getEmployeeVacations,
  setUserAvatar,
  userSchedules,
  getUserSchedule,
  getUserVacationsUsedDays,
  getHealthcenters,
  healthcenters,
  createSchedule,
  updateSchedule,
  usersVacationsCount: { [user._id]: userVacationsCount },
  deleteUserVacations,
  addUserVacations,
  nextTutorialStep,
  scheduleError,
}) => {
  const
    [userModal, setUserModal] = useState(false),
    [deviceModal, setDeviceModal] = useState(false),
    [vacationModal, setVacationModal] = useState(false),
    [settingsModal, setSettingsModal] = useState(false),
    [currentHealthcenter, setCurrentHealthcenter] = useState(null),
    [healthcenterOptions, setHealthcenterOptions] = useState([]),
    [roomOptions, setRoomOptions] = useState([]),
    [setDebounce] = useDebouncer(),
    [deleteModal, setDeleteModal] = useState({
      shown: false,
      question: '',
      onAccept: () => { }
    });

  const userVacationsHandler = (vacationsToAdd, vacationsToDelete) => {
    addUserVacations(vacationsToAdd, user._id, true);
    deleteUserVacations(vacationsToDelete, user._id);
  },
    updateScheduleHandler = ({ start, end, event, _id }, action) => {
      let targetDay = scheduleWeekDays[moment(start).day()];
      let currentDay = (action === 'create' || action === 'remove') ? '' : scheduleWeekDays[moment(event.start).day()];
      const currentSchedule = userSchedules.find(({ healthcenter }) => healthcenter === currentHealthcenter);
      let updatedSchedule;
      const updateData = {
        targetDay,
        event: {
          start,
          end,
          _id: event?._id ?? 'new',
          healthcenter: currentHealthcenter,
        }
      };
      switch (action) {
        case 'create':
          updatedSchedule = {
            ...currentSchedule,
            [targetDay]: {
              scheduleEvents: [
                ...currentSchedule[targetDay].scheduleEvents,
                { start, end }
              ]
            },
            updateData
          }
          break;
        case 'resize':
          updatedSchedule = {
            ...currentSchedule,
            [targetDay]: {
              scheduleEvents:
                currentSchedule[targetDay].scheduleEvents.map(e => e._id === event._id ? { start, end, _id: event._id } : e)
            },
            updateData
          }
          break;
        case 'move':
          if (targetDay !== currentDay) {
            updatedSchedule = {
              ...currentSchedule,
              [targetDay]: {
                scheduleEvents: [
                  ...currentSchedule[targetDay].scheduleEvents,
                  { start, end, _id: event._id }
                ]
              },
              [currentDay]: {
                scheduleEvents: [
                  ...currentSchedule[currentDay].scheduleEvents.filter(e => e._id !== event._id),
                ]
              },
              updateData
            }
          } else {
            updatedSchedule = {
              ...currentSchedule,
              [targetDay]: {
                scheduleEvents:
                  currentSchedule[targetDay].scheduleEvents.map(e => e._id === event._id ? { start, end, _id: event._id } : e)
              },
              updateData
            }
          }
          break;
        case 'remove':
          updatedSchedule = {
            ...currentSchedule,
            [targetDay]: {
              scheduleEvents: [
                ...currentSchedule[targetDay].scheduleEvents.filter(e => e._id !== _id),
              ]
            },
          }
          break;
      }
      updateSchedule(currentSchedule._id, updatedSchedule);
    };

  useEffect(() => {
    if (user && userRole) {
      getEmployeeVacations(user.vacations);
      getUserSchedule(user._id);
      getHealthcenters(userRole.companyId);
    }
  }, [userRole?._id]);

  useEffect(() => {
    setDebounce(() => {
      if (userRole && user) {
        getUserVacationsUsedDays([{ ...userRole, userId: { _id: user._id, vacations: vacations.map(({ _id }) => _id) } }]);
      }
    }, 500)
  }, [vacations]);

  useEffect(() => {
    if (healthcenters && healthcenters?.length > 0) {
      const userHcs = healthcenters
        .filter(({ doctors, nurses }) =>
          [...doctors, ...nurses].includes(userRole._id)
        );
      const newHcOptions = userHcs
        .map(({ name, _id }) => ({ value: _id, label: name }));
      const newRoomOptions = [
        ...userHcs.map(({ _id, name, rooms }) => ({
          healthcenterId: _id,
          healthcenterName: name,
          rooms: [{ label: 'Nie przypisany', value: null }, ...rooms.map(({ name, _id }) => ({ value: _id, label: name }))]
        }))
      ];
      setHealthcenterOptions(newHcOptions);
      setCurrentHealthcenter(newHcOptions[0].value);
      setRoomOptions(newRoomOptions);
    }
  }, [healthcenters]);

  useEffect(() => {
    if (currentHealthcenter && userSchedules) {
      const scheduleIndex = userSchedules.findIndex(({ healthcenter }) => healthcenter === currentHealthcenter);
      if (scheduleIndex === -1) {
        createSchedule({
          user: user._id,
          healthcenter: currentHealthcenter,
        })
      }
    }
  }, [currentHealthcenter, userSchedules])

  useEffect(() => {
    if (scheduleError === 'Schedules conflict') {
      toast('Harmonogramy z różnych placówek nie mogą nachodzić na siebie!', {
        type: 'error'
      })
    }
  }, [scheduleError])

  return <>
    <Loader hide={user && userRole && userSchedules} />
    <DoctorProfileView
      user={user}
      userRole={userRole}
      vacations={vacations}
      updateAuthenticatedUser={updateAuthenticatedUser}
      updateAuthenticatedUserRole={updateAuthenticatedUserRole}
      userModal={userModal}
      setUserModal={setUserModal}
      deviceModal={deviceModal}
      setDeviceModal={setDeviceModal}
      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      setUserAvatar={setUserAvatar}
      vacationModal={vacationModal}
      setVacationModal={setVacationModal}
      userSchedules={userSchedules}
      updateScheduleHandler={updateScheduleHandler}
      userVacationsCount={userVacationsCount}
      userVacationsHandler={userVacationsHandler}
      nextTutorialStep={nextTutorialStep}
      settingsModal={settingsModal}
      setSettingsModal={setSettingsModal}
      currentHealthcenter={currentHealthcenter}
      setCurrentHealthcenter={setCurrentHealthcenter}
      healthcenterOptions={healthcenterOptions}
      roomOptions={roomOptions}
    />;
  </>
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  userRole: state.authentication.userRole,
  vacations: state.employees.employeeVacations,
  doctorsPatterns: state.medicalVisitPattern.doctorsPatterns,
  userSchedules: state.schedule.userSchedules,
  healthcenters: state.healthcenter.data,
  usersVacationsCount: state.employees.usersVacationsCount,
  scheduleError: state.schedule.error,
});

const mapDispatchToProps = dispatch => {
  return {
    getEmployeeVacations: (vacationsIdsArr) => dispatch(getEmployeeVacations(vacationsIdsArr)),
    updateAuthenticatedUser: (userData, id) => dispatch(updateAuthenticatedUser(userData, id)),
    updateAuthenticatedUserRole: (userRoleData, id, roleName) => dispatch(updateAuthenticatedUserRole(userRoleData, id, roleName)),
    setUserAvatar: (userId, avatarData) => dispatch(setUserAvatar(userId, avatarData)),
    getUserVacationsUsedDays: (users) => dispatch(getUserVacationsUsedDays(users)),
    getHealthcenters: (companyId) => dispatch(getHealthcenters(companyId)),
    getUserSchedule: (userId) => dispatch(getUserSchedule(userId)),
    createSchedule: (newSchedule) => dispatch(createSchedule(newSchedule)),
    updateSchedule: (scheduleId, updatedSchedule) => dispatch(updateSchedule(scheduleId, updatedSchedule)),
    deleteUserVacations: (vacations, userId) => dispatch(deleteUserVacations(vacations, userId)),
    addUserVacations: (vacations, userId, notAdmin) => dispatch(addUserVacations(vacations, userId, notAdmin)),
    nextTutorialStep: () => dispatch(nextTutorialStep()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorProfileContainer);