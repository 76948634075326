import React from "react";
import styled from "styled-components";

const VisitDashboardCard = ({ actionsOpen, setActionsOpen, visit, currentVisit, setCurrentVisit, setCurrentVisitPatient }) => {
  const startHours = new Date(visit.start).getHours()
  const startMinutes = new Date(visit.start).getMinutes()
  const endHours = new Date(visit.end).getHours()
  const endMinutes = new Date(visit.end).getMinutes()

  const visitStatePolishDict = (visitState) => {
    switch (visitState) {
      case 'PLANNED':
        return 'Zaplanowana'
      case 'CONFIRMED':
        return 'Potwierdzona'
      case 'WAITING':
        return 'W poczekalni'
      case 'STARTED':
        return 'W trakcie'
      case 'ENDED':
        return 'Zakończona'
      case 'REJECTED':
        return 'Odwołana'
    }
  }
  return (
    <CardContainer active={visit?._id === currentVisit?._id ? visit.state : false}
      onClick={() => {
        setCurrentVisit(visit);
        if (setCurrentVisitPatient) {
          setCurrentVisitPatient(visit.patient);
          if (actionsOpen && actionsOpen !== visit._id) {
            setActionsOpen(false)
          }
        }
      }}>
      <VisitStateColor state={visit.state} />
      <LeftInsideContainer>
        <BorderContainer>
          <VisitTime>{startHours}:{startMinutes < 10 ? `0${startMinutes}` : startMinutes} - {endHours}:{endMinutes < 10 ? `0${endMinutes}` : endMinutes}</VisitTime>
          <VisitStateText state={visit.state}>{visitStatePolishDict(visit.state)}</VisitStateText>
        </BorderContainer>
      </LeftInsideContainer>
      <RightInsideContainer>
        <PatientNameText>{`${visit.patient?.name} ${visit.patient?.surname}`}</PatientNameText>
        <PatientTelNum>{visit.patient?.tel}</PatientTelNum>
      </RightInsideContainer>
    </CardContainer>
  );
};

export default VisitDashboardCard;

const PatientNameText = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.black};
`;

const PatientTelNum = styled.div`
  color: ${({ theme }) => theme.lightBlack};
  font-size: 12px;
`;

const VisitStateText = styled.span`
  font-weight: 600;
  font-size: 14px;
    ${({ state }) =>
    state === "CONFIRMED" ?
      `color: #57C754`
      :
      state === "PLANNED" ?
        `color: #1890FF`
        :
        state === "STARTED" ?
          `color: #3E64FF`
          :
          state === "WAITING" ?
            `color: #FF9C41`
            :
            state === "REJECTED" ?
              `color: #C75454`
              :
              state === "ENDED" ?
                `color: #c5c5c5`
                : ``}
`
const VisitTime = styled.span`
${({ theme }) =>
    `color : ${theme.black};
  font-weight : 500;
  font-size:  13px;`
  }
`

const CardContainer = styled.div`
width: 100%;
  display:flex;
  flex-direction:row;
  align-items: center;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  height: 80px;
  margin: 3px 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  ${({ active }) =>
    active === "CONFIRMED" ?
      `border: 2px solid #57C754`
      :
      active === "PLANNED" ?
        `border: 2px solid #1890FF`
        :
        active === "STARTED" ?
          `border: 2px solid #3E64FF`
          :
          active === "WAITING" ?
            `border: 2px solid #FF9C41`
            :
            active === "REJECTED" ?
              `border: 2px solid #C75454`
              :
              active === "ENDED" ?
                `border: 2px solid #c5c5c5`
                : ``}
`;

const VisitStateColor = styled.div`
  height: 78px;
  width: 8px;
  background-color: blue;
  ${({ state }) =>
    state === "CONFIRMED" ?
      `background-color: #57C754`
      :
      state === "PLANNED" ?
        `background-color: #1890FF`
        :
        state === "STARTED" ?
          `background-color: #3E64FF`
          :
          state === "WAITING" ?
            `background-color: #FF9C41`
            :
            state === "REJECTED" ?
              `background-color: #C75454`
              :
              state === 'ENDED' ?
                `background-color: #c5c5c5`
                : ``}

`;

const LeftInsideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  width: 40%;
`;

const RightInsideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  padding: 0 5px;
  width: 60%;
`;
const BorderContainer = styled.div`
  border-right: 1px solid #C4C4C4;
  padding: 10px 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

`