import React, { useEffect,useState } from "react";
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/modules/authentication';
import {setInitialHealthcenter} from '../../redux/modules/healthcenter';
import {setInitialCalendar} from '../../redux/modules/calendar';
import {setInitialCompany} from '../../redux/modules/company';
import {setInitialEmployee} from '../../redux/modules/employees';
import {setInitialPatient} from '../../redux/modules/patients';
import {setInitialVisits} from '../../redux/modules/visits';
import LogoutImg from '../Shared/assets/leaving-logout.svg';
import styled from 'styled-components';
import Button from '../Shared/Button';
import { Link } from "react-router-dom";

const LogoutContainer = ({ setInitialVisits,setInitialPatient,setInitialEmployee,setInitialCompany,logoutUser,setInitialHealthcenter,setInitialCalendar }) => {
  useEffect(() => {
    sessionStorage.removeItem("jwtToken")
    logoutUser();
    setInitialHealthcenter()
    setInitialCalendar()
    setInitialCompany()
    setInitialEmployee()
    setInitialPatient()
    setInitialVisits()
  }, [])

  return (
    <Wrapper>
      <Image src={LogoutImg} />
      <h1>Miło było Cię widzieć! :)</h1>
      <StyledLink to="/">
        <Button variant="primary">Wróć do strony głównej</Button>
      </StyledLink>
    </Wrapper>
  );
};

const mapStateToProps = dispatch => {

}

const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    setInitialHealthcenter: () => dispatch(setInitialHealthcenter()),
    setInitialCalendar: () => dispatch(setInitialCalendar()),
    setInitialCompany: () => dispatch(setInitialCompany()),
    setInitialEmployee: () => dispatch(setInitialEmployee()),
    setInitialPatient: () => dispatch(setInitialPatient()),
    setInitialVisits: () => dispatch(setInitialVisits())
  }
}

export default connect(
  null,
  mapDispatchToProps
)(LogoutContainer)


const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const Image = styled.img`
width: 25%;
max-width: 600px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;