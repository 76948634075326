import axios from "axios";

const GET_USER_SCHEDULE_REQUEST = "GET_USER_SCHEDULE_REQUEST";
const GET_USER_SCHEDULE_SUCCESS = "GET_USER_SCHEDULE_SUCCESS";
const GET_USER_SCHEDULE_FAIL = "GET_USER_SCHEDULE_FAIL";

const CREATE_SCHEDULE_REQUEST = "CREATE_SCHEDULE_REQUEST";
const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
const CREATE_SCHEDULE_FAIL = "CREATE_SCHEDULE_FAIL";

const UPDATE_SCHEDULE_REQUEST = "UPDATE_SCHEDULE_REQUEST";
const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";
const UPDATE_SCHEDULE_FAIL = "UPDATE_SCHEDULE_FAIL";

export const getUserSchedule = userId => async dispatch => {
  dispatch(getUserScheduleRequest());
  try {
    const response = await axios.get(`schedule/user/${userId}`);
    const { data } = response;
    dispatch(getUserScheduleSuccess(data));
  } catch (error) {
    dispatch(getUserScheduleFail(error.response?.data?.message));
  }
};

const getUserScheduleRequest = () => ({
  type: GET_USER_SCHEDULE_REQUEST
});

const getUserScheduleSuccess = schedule => ({
  type: GET_USER_SCHEDULE_SUCCESS,
  payload: schedule
});

const getUserScheduleFail = error => ({
  type: GET_USER_SCHEDULE_FAIL,
  payload: error
});

export const createSchedule = newSchedule => async dispatch => {
  dispatch(createScheduleRequest());
  try {
    const response = await axios.post(`schedule/`, newSchedule);
    const { data } = response;
    dispatch(createScheduleSuccess(data));
  } catch (error) {
    dispatch(createScheduleFail(error.response?.data?.message));
  }
};

const createScheduleRequest = () => ({
  type: CREATE_SCHEDULE_REQUEST
});

const createScheduleSuccess = schedule => ({
  type: CREATE_SCHEDULE_SUCCESS,
  payload: schedule
});

const createScheduleFail = error => ({
  type: CREATE_SCHEDULE_FAIL,
  payload: error
});

export const updateSchedule = (scheduleId, newSchedule) => async dispatch => {
  dispatch(updateScheduleRequest());
  try {
    const response = await axios.patch(`schedule/${scheduleId}`, newSchedule);
    const { data } = response;
    dispatch(updateScheduleSuccess(data));
  } catch (error) {
    dispatch(updateScheduleFail(error.response?.data?.message));
  }
};

const updateScheduleRequest = () => ({
  type: UPDATE_SCHEDULE_REQUEST
});

const updateScheduleSuccess = schedule => ({
  type: UPDATE_SCHEDULE_SUCCESS,
  payload: schedule
});

const updateScheduleFail = error => ({
  type: UPDATE_SCHEDULE_FAIL,
  payload: error
});

const initialState = {
  userSchedules: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_USER_SCHEDULE_SUCCESS:
      return {
        ...state,
        userSchedules: action.payload,
        loading: false
      };
    case GET_USER_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        userSchedules: [...state.userSchedules, action.payload],
        loading: false
      };
    case CREATE_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        userSchedules: state.userSchedules.map(el => el._id === action.payload._id ? action.payload : el),
        loading: false,
      };
    case UPDATE_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};