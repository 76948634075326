import React from 'react';
import styled from 'styled-components';
import Container from "../Shared/Container";
import VacationsCalendar from './VacationsCalendar';
import VacationModal from './Modals/VacationModal';
import Modal from '../Shared/Modals/Modal';

const VacationsView = ({
    vacationModal,
    setVacationModal,
    doctors,
    registries,
    nurses,
    currentCalendarDates,
    setCurrentCalendarDates,
    events,
    setCurrentDate,
    addVacationHandler,
    updateVacationHandler,
    deleteVacationHandler,
    questionModal,
    setQuestionModal,
    userFilters,
    usersFiltersHandler,
    errorModal,
    resetVacationsError,
    acceptVacation,
}) => {
    return (
        <Wrapper>
            <StyledContainer
                title="Urlopy"
                actionButton='add'
                actionButtonOnClick={() => setVacationModal({ shown: true, editId: null })}
            >
                <VacationModal
                    modalState={vacationModal}
                    setModalState={setVacationModal}
                    doctors={doctors}
                    registries={registries}
                    nurses={nurses}
                    currentCalendarDates={currentCalendarDates}
                    setCurrentCalendarDates={setCurrentCalendarDates}
                    addVacationHandler={addVacationHandler}
                    updateVacationHandler={updateVacationHandler}
                    deleteVacationHandler={deleteVacationHandler}
                    setQuestionModal={setQuestionModal}
                    events={events}
                    acceptVacation={acceptVacation}
                />
                <Modal
                    type='SimpleQuestionModal'
                    reverseButtonColors={questionModal.acceptText === 'Usuń'}
                    show={questionModal.shown}
                    question={questionModal.question}
                    acceptText={questionModal.acceptText}
                    cancelText='Anuluj'
                    onAccept={questionModal.onAccept}
                    onCancel={() => setQuestionModal({ shown: false, data: null, question: '', onAccept: null })}
                />
                <Modal
                    type="SimpleInfoModal"
                    show={errorModal.shown}
                    info={errorModal.info}
                    acceptText="Ok"
                    onAccept={resetVacationsError}
                />
                <VacationsCalendar
                    setVacationModal={setVacationModal}
                    setCurrentCalendarDates={setCurrentCalendarDates}
                    doctors={doctors}
                    registries={registries}
                    nurses={nurses}
                    events={events}
                    setCurrentDate={setCurrentDate}
                    updateVacationHandler={updateVacationHandler}
                    setQuestionModal={setQuestionModal}
                    userFilters={userFilters}
                    usersFiltersHandler={usersFiltersHandler}
                />
            </StyledContainer>
        </Wrapper>
    )
}

export default VacationsView;

const Wrapper = styled.div`
  width: 100%;
`;

const StyledContainer = styled(Container)`
  min-width: 90%;
  min-height: 200px;
  position:relative;
`;