import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import Select from './Select';
import moment from 'moment';
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg';
import AvatarPlaceholder from "./assets/avatar.svg";
import { userRolesDisc } from './enums';

const StatsHeader = ({
    currentView: { label, value, type, selectOptions, userData },
    changeViewHandler,
    changeCurrentDateHandler,
    currentDate,
}) => {
    const currentMonth = moment(currentDate).format('MMMM YYYY');
    return (
        <StyledContainer>
            {type !== 'employee' && <CurrentView>
                {label}
            </CurrentView>}
            {type === 'employee' &&
                <EmployeeLabel>
                    <Avatar src={!userData.avatar ? AvatarPlaceholder : `${process.env.REACT_APP_SRV_URL}${userData.avatar}`} />
                    <div>
                        <Name>{label}</Name>
                        <Role>{userRolesDisc[userData.activeRole]}</Role>
                    </div>
                </EmployeeLabel>
            }
            <MonthNav>
                <NavArrow onClick={() => changeCurrentDateHandler('prev')} />
                <CurrentMonth>{currentMonth}</CurrentMonth>
                <Today
                    disabled={currentMonth === 'Invalid date' || moment().format('MMMM YYYY') === currentMonth}
                    onClick={() => changeCurrentDateHandler('today')}
                >Wróć do dziś</Today>
                <NavArrow
                    direction='right'
                    onClick={() => changeCurrentDateHandler('next')}
                    disabled={currentMonth === 'Invalid date' || moment().format('MMMM YYYY') === currentMonth}
                />
            </MonthNav>
            <StyledSelect
                value={{ value, label }}
                options={selectOptions}
                onChange={changeViewHandler}
            />
        </StyledContainer>
    )
}

export default StatsHeader;

const StyledContainer = styled(Container)`
    margin:0 auto 10px;
    max-width: 1365px;
    >div:first-child{
        display:none;
    }
    >div:last-child{
        padding:0 15px;
        width:100%;
        align-items:center;
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const CurrentView = styled.span`
    font-size: 18px;
    font-weight: 700;
    color:${({ theme }) => theme.primary};
`;

const StyledSelect = styled(Select)`
`;

const MonthNav = styled.div`
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const CurrentMonth = styled.span`
  min-width: 170px;
  font-size: 18px;
  font-weight:600;
  text-transform:capitalize;
  color:${({ theme }) => theme.lightBlack};
  padding:0 10px;
  text-align:center;
  user-select:none;
`;

const Today = styled.span`
    position:absolute;
    color:#ccc;
    bottom:-14px;
    font-size:12px;
    left:0;
    right:0;
    transition:300ms;
    user-select:none;
    cursor:pointer;
    text-align:center;
    z-index:1;
    ${({ disabled }) => disabled && `opacity:0;z-index:-1`}
    &:hover{
        color:${({ theme }) => theme.primary}
    }
`;

const NavArrow = styled(ArrowDown)`
  transform:rotate(90deg);
  transition:300ms;
  cursor: pointer;
  ${({ direction }) => direction === 'right' && 'transform:rotate(-90deg);'}
  ${({ disabled }) => disabled && 'opacity:0;pointer-events:none;'}
  path{
    fill:${({ theme }) => theme.lightBlack};
  }
`;

const Avatar = styled.img`
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 50%;
`;

const EmployeeLabel = styled.div`
    display:flex;
    align-items:center;
    width:200px;
    >div{
        display:flex;
        flex-direction:column;
    }
`;

const Name = styled.span`
    font-size:16px;
    font-weight:600;
    color:${({ theme }) => theme.primary};
`;

const Role = styled.span`
    color:${({ theme }) => theme.lightBlack};
    font-size:14px;
`;

