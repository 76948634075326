import React, { useState, useEffect } from "react";
import IncomeView from "./IncomeView";
import { connect } from 'react-redux';
import { getVisitsByCompanyId } from '../../../redux/modules/visits';
import { getCompany } from '../../../redux/modules/company';
import { getHealthcenters } from '../../../redux/modules/healthcenter';
import { getEmployees } from '../../../redux/modules/employees';
import moment from 'moment';

const IncomeContainer = ({
  userRole,
  getVisitsByCompanyId,
  getHealthcenters,
  companyVisits,
  healthcenters,
  getCompany,
  company,
  getEmployees,
  doctors,
  nurses,
}) => {
  const [income, setIncome] = useState({ month: 0, today: 0 }),
    [visitsData, setVisitsData] = useState({ endedVisits: 0, paidVisits: 0, notPaidVisits: 0, all: 0 }),
    [incomeData, setIncomeData] = useState([]),
    [currentDate, setCurrentDate] = useState(null),
    [tablePagi, setTablePagi] = useState([1, 7]),
    [servicesData, setServicesData] = useState([]),
    [healthcentersData, setHealthcentersData] = useState([]),
    [currentView, setCurrentView] = useState({ type: 'company', value: 'company', label: '', selectOptions: [], userData: {} });

  const mapVisitsData = (visits) => {
    if (!currentDate) {
      return false;
    }
    const daysNumber = moment(currentDate).daysInMonth();
    let serviceCount = {}, chartData = {}, hcData = {};
    for (const { _id, name, price } of [].concat.apply([], healthcenters.map(({ services }) => (services)))) {
      serviceCount[_id] = { count: 0, name, price };
    };
    for (const { _id, name, doctors } of healthcenters) {
      if (currentView.type === 'company') {
        hcData[_id] = { _id, name, visits: [], sum: 0 };
      } else if (doctors.findIndex((id) => id === currentView.value) !== -1) {
        hcData[_id] = { _id, name, visits: [], sum: 0 };
      }
    };
    for (let i = 1; i <= daysNumber; i++) {
      const date = moment(currentDate).date(i).format('DD.MM.YYYY');
      chartData[date] = { income: 0, sum: 0, date: date, visits: 0 }
    };
    let sum = 0;
    let todaySum = 0;
    visits.sort((a, b) => (a.end > b.end) ? 1 : ((b.end > a.end) ? -1 : 0));
    for (const { cost, paid, end, services, healthcenterId, ...rest } of visits) {
      sum += paid ? cost : 0;
      const endDate = moment(end).format('DD.MM.YYYY');
      if (moment().format('DD.MM.YYYY') === endDate && paid) {
        todaySum += cost;
      }
      if (chartData[endDate]) {
        chartData[endDate].income += paid ? cost : 0;
        chartData[endDate].sum = sum;
        chartData[endDate].visits += 1;
      }
      if (hcData[healthcenterId]) {
        hcData[healthcenterId].visits.push({ cost, paid, end, services, healthcenterId, rest });
        hcData[healthcenterId].sum += paid ? cost : 0;
      }
      for (const service of services) {
        if (serviceCount[service]) {
          serviceCount[service].count += 1;
        }
      }
    }
    chartData = Object.values(chartData);
    for (const i in chartData) {
      if (chartData[i].sum === 0 && i > 0) {
        chartData[i].sum = chartData[i - 1].sum;
      }
    }
    setIncome({ month: sum, today: todaySum });
    setIncomeData(Object.values(chartData));
    setHealthcentersData(Object.values(hcData));
    setServicesData(Object.values(serviceCount));
  },

    changeCurrentDateHandler = (direction) => {
      let newDate;
      if (direction === 'next') {
        newDate = moment(currentDate).add(1, 'months')._d;
      } else if (direction === 'prev') {
        newDate = moment(currentDate).subtract(1, 'months')._d;
      } else if (direction === 'today') {
        newDate = moment()._d;
      };
      localStorage.setItem('statsCurrentDate', newDate);
      setCurrentDate(newDate);
    },
    tablePagiHandler = (direction) => {
      if (direction === 'next' && tablePagi[0] < 29) {
        const newPagi = [tablePagi[0] + 7, tablePagi[1] + 7];
        setTablePagi(newPagi);
      } else if (direction === 'prev' && tablePagi[0] > 1) {
        const newPagi = [tablePagi[0] - 7, tablePagi[1] - 7];
        setTablePagi(newPagi);
      }
    },
    changeViewHandler = ({ label, value, type, userData }) => {
      localStorage.setItem('statsCurrentView', JSON.stringify({ label, value, type }));
      setCurrentView(prev => ({ ...prev, value, type, label, userData }))
    }
  useEffect(() => {
    if (userRole) {
      const currentDateFromLocalStorage = localStorage.getItem('statsCurrentDate') ?? moment()._d;
      setCurrentDate(currentDateFromLocalStorage);
      getHealthcenters(userRole.companyId);
      getEmployees('doctor', userRole.companyId);
      getEmployees('nurse', userRole.companyId);
      if (!company) {
        getCompany(userRole.companyId);
      }
    }
  }, [userRole]);
  useEffect(() => {
    if (userRole && currentDate) {
      getVisitsByCompanyId(
        userRole.companyId,
        moment(currentDate).date(1).hour(0).minute(0).second(0)._d,
        moment(currentDate).date(moment(currentDate).daysInMonth()).hour(23).minute(59).second(59)._d,
      );
    }
  }, [currentDate]);
  useEffect(() => {
    if (companyVisits && healthcenters.length > 0) {
      let visits = companyVisits;
      if (currentView.type === 'employee') {
        visits = visits.filter(({ doctor }) => doctor === currentView.value);
      }
      if (currentView.type === 'healthcenter') {
        visits = visits.filter(({ healthcenterId }) => healthcenterId === currentView.value);
      }
      const endedVisits = visits.filter(({ state }) => state === 'ENDED');
      const paidVisits = endedVisits.filter(({ paid }) => paid);
      const notPaidVisits = endedVisits.filter(({ paid }) => !paid);
      setVisitsData({ endedVisits, paidVisits, notPaidVisits, all: visits.length });
      mapVisitsData(endedVisits);
    }
  }, [companyVisits, healthcenters, currentView]);
  useEffect(() => {
    if (company) {
      const currentViewFromLocalStorage = JSON.parse(localStorage.getItem('statsCurrentView')) ?? { label: company.name, type: 'company' };
      const selectOptions = [
        { label: company.name, value: 'company', type: 'company' },
        { label: 'Placówka', options: healthcenters.map(({ name, _id }) => ({ label: name, value: _id, type: 'healthcenter' })) },
        { label: 'Pracownik', options: [...doctors, ...nurses].map(({ _id, userId: { name, surname, activeRole, avatar } }) => ({ value: _id, label: `${name} ${surname}`, type: 'employee', userData: { avatar, activeRole } })) },
      ]
      const userData = currentViewFromLocalStorage.type === 'employee' ? [...doctors, ...nurses].find(({ _id }) => _id === currentViewFromLocalStorage.value)?.userId ?? {} : {};
      setCurrentView(prev => ({ ...prev, ...currentViewFromLocalStorage, selectOptions, userData }));
    }
  }, [company, healthcenters, doctors, nurses]);

  return <IncomeView
    income={income}
    incomeData={incomeData}
    currentDate={currentDate}
    changeCurrentDateHandler={changeCurrentDateHandler}
    tablePagi={tablePagi}
    tablePagiHandler={tablePagiHandler}
    servicesData={servicesData}
    visitsData={visitsData}
    healthcentersData={healthcentersData}
    currentView={currentView}
    changeViewHandler={changeViewHandler}
  />;
};

const mapStateToProps = state => ({
  userRole: state.authentication.userRole,
  company: state.company.data,
  companyVisits: state.visits.companyVisits,
  healthcenters: state.healthcenter.data,
  doctors: state.employees.doctors,
  nurses: state.employees.nurses,
});

const mapDispatchToProps = dispatch => {
  return {
    getVisitsByCompanyId: (companyId, start, end) => dispatch(getVisitsByCompanyId(companyId, start, end)),
    getHealthcenters: (companyId) => dispatch(getHealthcenters(companyId)),
    getCompany: (companyId) => dispatch(getCompany(companyId)),
    getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncomeContainer);
