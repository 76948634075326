import axios from "axios";

const GET_MEDICAL_DATA_REQUEST = "GET_MEDICAL_DATA_REQUEST";
const GET_MEDS_SUCCESS = "GET_MEDS_SUCCESS";
const GET_DIAGNOSIS_SUCCESS = "GET_DIAGNOSIS_SUCCESS";
const GET_WARDS_SUCCESS = "GET_WARDS_SUCCESS";
const GET_HOSPITALS_SUCCESS = "GET_HOSPITALS_SUCCESS";
const GET_PROCEDURES_SUCCESS = "GET_PROCEDURES_SUCCESS";
const GET_VACCINATIONS_SUCCESS = "GET_VACCINATIONS_SUCCESS";
const GET_MEDICAL_DATA_FAIL = "GET_MEDICAL_DATA_FAIL";

export const getMedicalData = (target, settings = {}, loadMore = false) => async dispatch => {
    const loader = config[target]?.loader;
    const callback = config[target]?.callback;
    let query = ``;
    for (const [setting, settingValue] of Object.entries(settings)) {
        query += `${setting}=${settingValue}&`;
    };
    query = query.substring(0, query.length - 1);
    dispatch(getMedicalDataRequest(loader, loadMore));
    try {
        const response = await axios.get(`${process.env.REACT_APP_MED_REGISTER_URL}${target}?${query}`);
        dispatch(callback(response.data.data ?? [], loadMore));
        return response.data.data;
    }
    catch (error) {
        dispatch(getMedicalDataFail({ error, loader }))
    }
};

const getMedicalDataRequest = (loader, loadMore) => ({
    type: GET_MEDICAL_DATA_REQUEST,
    payload: loadMore ? null : loader
});

const getMedsSuccess = (meds, loadMore) => ({
    type: GET_MEDS_SUCCESS,
    payload: { meds, loadMore }
});

const getDiagnosisSuccess = (diagnosis, loadMore) => ({
    type: GET_DIAGNOSIS_SUCCESS,
    payload: { diagnosis, loadMore }
});

const getWardsSuccess = (wards) => ({
    type: GET_WARDS_SUCCESS,
    payload: wards
});

const getHospitalsSuccess = (hospitals) => ({
    type: GET_HOSPITALS_SUCCESS,
    payload: hospitals
});

const getProceduresSuccess = (procedures, loadMore) => ({
    type: GET_PROCEDURES_SUCCESS,
    payload: { procedures, loadMore }
});

const getVaccinationsSuccess = (vaccinations) => ({
    type: GET_VACCINATIONS_SUCCESS,
    payload: vaccinations
});

const getMedicalDataFail = (error) => ({
    type: GET_MEDICAL_DATA_FAIL,
    payload: error
});

const config = {
    'icd10s': {
        callback: getDiagnosisSuccess,
        loader: 'diagnosisLoading'
    },
    'icd9s': {
        callback: getProceduresSuccess,
        loader: 'proceduresLoading'
    },
    'medicalProducts': {
        callback: getMedsSuccess,
        loader: 'medicalProducts'
    },
    'wards': {
        callback: getWardsSuccess,
        loader: 'loading'
    },
    'hospitals': {
        callback: getHospitalsSuccess,
        loader: 'loading'
    },
    'vaccinations': {
        callback: getVaccinationsSuccess,
        loader: 'loading'
    }
}

const initialState = {
    meds: [],
    diagnosis: [],
    wards: [],
    hospitals: [],
    procedures: [],
    vaccinations: [],
    medsLoading: true,
    diagnosisLoading: true,
    proceduresLoading: true,
    loading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MEDICAL_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                [action.payload]: true
            }
        case GET_MEDS_SUCCESS:
            return {
                ...state,
                meds: action.payload.loadMore ? [...state.meds, ...action.payload.meds] : action.payload.meds,
                medsLoading: false,
                loading: false,
            }
        case GET_DIAGNOSIS_SUCCESS:
            return {
                ...state,
                diagnosis: action.payload.loadMore ? [...state.diagnosis, ...action.payload.diagnosis] : action.payload.diagnosis,
                loading: false,
                diagnosisLoading: false,
            }
        case GET_WARDS_SUCCESS:
            return {
                ...state,
                wards: action.payload,
                loading: false
            }
        case GET_HOSPITALS_SUCCESS:
            return {
                ...state,
                hospitals: action.payload,
                loading: false
            }
        case GET_PROCEDURES_SUCCESS:
            return {
                ...state,
                procedures: action.payload.loadMore ? [...state.procedures, ...action.payload.procedures] : action.payload.procedures,
                loading: false,
                proceduresLoading: false,
            }
        case GET_VACCINATIONS_SUCCESS:
            return {
                ...state,
                vaccinations: action.payload,
                loading: false
            }
        case GET_MEDICAL_DATA_FAIL:
            return {
                ...state,
                medsLoading: false,
                diagnosisLoading: false,
                proceduresLoading: false,
                loading: false,
                [action.payload.loader]: false,
                error: action.payload.error
            }
        default:
            return state;
    }
};