import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Input from '../../../Shared/Input';
import Button from '../../../Shared/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';

const RiskFactorsTextModal = ({
    modalState: { shown: modalShown, target: modalTarget },
    setModalState,
    updatePatientRiskFactorsHandler,
    patientRiskFactors,
}) => {
    const [listItems, setListItems] = useState([]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().test({
            message: 'Taki pokój już istnieje',
            test: val => listItems.indexOf(val) === -1,
        }),
    }),
        addItemHandler = (item) => {
            setListItems((prev = []) => [...prev, item]);
        },
        removeItemHandler = (item) => {
            setListItems(prev => prev.filter(el => el !== item));
        },
        resetModalHandler = () => {
            setListItems([]);
        };

    useEffect(() => {
        if (modalTarget === 'factors') {
            setListItems(patientRiskFactors?.factors);
        } else if (modalTarget === 'alergies') {
            setListItems(patientRiskFactors?.alergies?.map(({ subtance }) => subtance));
        }
    }, [modalTarget])

    return (
        <Modal
            title={modalTarget === 'factors' ? 'Edycja czynników ryzyka' : modalTarget === 'alergies' ? 'Edycja alergii' : 'Zarządzanie'}
            exitButton={true}
            onCancel={() => {
                setModalState({ shown: false, target: null, }); resetModalHandler();
            }}
            show={modalShown}
        >
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                    name: ''
                }}
                enableReinitialize
                onSubmit={(values) => {
                    console.log(values);
                    const newRiskFactors = modalTarget === 'factors' ? { factors: listItems } : { alergies: listItems.map(el => ({ subtance: el })) };
                    updatePatientRiskFactorsHandler(newRiskFactors);
                    setModalState({ shown: false, target: null, });
                }}
            >
                {({ handleSubmit, values, setFieldValue, handleChange, errors }) => (
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <InputRow>
                            <StyledInput
                                name="name"
                                type="text"
                                label={modalTarget === 'alergies' ? 'Alergen' : 'Czynnik'}
                                onChange={handleChange}
                                value={values.name}
                                error={errors.name}
                            />
                            <StyledButton type='button' onClick={() => { addItemHandler(values.name); setFieldValue('name', '') }} variant='secondary' disabled={errors.name || values.name === ""}>Dodaj</StyledButton>
                        </InputRow>
                        <List>
                            {listItems?.length > 0 ? listItems.map(el => (
                                <ListItem key={el}>
                                    {el}
                                    <RemoveBtn onClick={() => removeItemHandler(el)} />
                                </ListItem>
                            ))
                                :
                                <NoItems>Brak</NoItems>
                            }
                        </List>
                        <CenterBox>
                            <Button onClick={handleSubmit} type='submit' variant='primary'>Zapisz zmiany</Button>
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
};

export default RiskFactorsTextModal;

const Form = styled.form`
    width:350px;
    padding-top:10px;
`;

const CenterBox = styled.div`
    margin-top:40px;
    text-align:center;
`;

const InputRow = styled.div`
    display:flex;
    align-items:center;
    &>div{
        width:100%;
    }
`;

const StyledInput = styled(Input)`
    width:100%;
    border-top-right-radius:0;
    border-bottom-right-radius:0;
`;

const StyledButton = styled(Button)`
    height:38px;
    padding:7px 15px;
    margin:0;
    border-top-left-radius:0;
    border-bottom-left-radius:0;
`;

const List = styled.ul`
    min-height:50px;
    margin:0;
`;

const ListItem = styled.li`
    position:relative;
    padding:5px;
    &:not(:last-child){
        border-bottom: 1px solid ${({ theme }) => theme.grey};
    }
`;

const NoItems = styled.p`
    text-align:center;
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:0;
  bottom:0;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;