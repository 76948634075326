import React, { useEffect, useState, useCallback } from 'react';
import ElementEditorPageView from './ElementEditorPageView';
import { useParams, useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { connect } from 'react-redux';
import { getDoctorsVisitElements, updateVisitElement } from '../../../redux/modules/visitElement'
import { getDoctorsFormControls, addDoctorsFormControls, deleteDoctorsFormControls } from '../../../redux/modules/patternFormControl'
import { setSidebarActive } from '../../../redux/modules/navigation';
import { nextTutorialStep, prevTutorialStep } from '../../../redux/modules/tutorial';

const newFormControlSchema = {
    selectOptions: [{ name: 'Opcja 1', text: '' }],
    rangeDictionary: [],
    checkboxOptions: {
        ifTrue: '',
        ifFalse: '',
        options: [],
    },
    name: 'Nowe pole',
    formType: 'select',
    defaultValue: '',
    unit: '',
    displayFormat: 'normal',
    emptyFieldFormat: 'normal',
    emptyFieldText: '',
};

const ElementEditorPageContainer = ({
    userRole,
    doctorsElements,
    getDoctorsVisitElements,
    updateVisitElement,
    getDoctorsFormControls,
    addDoctorsFormControls,
    doctorsFormControls,
    deleteDoctorsFormControls,
    sidebarActive,
    setSidebarActive,
    nextTutorialStep,
    prevTutorialStep,
    currentTutorialStep,
}) => {
    const
        [currentElement, setCurrentElement] = useState({}),
        [currentTab, setCurrentTab] = useState(0),
        [currentControl, setCurrentControl] = useState({ i: 0, obj: null }),
        [currentSelectType, setCurrentSelectType] = useState('element'),
        [elIsDragging, setElIsDragging] = useState({ drag: false, el: null }),
        [didChanges, setDidChanges] = useState(false),
        [warningModal, setWarningModal] = useState({ shown: false, question: null, onAccept: null, onCancel: null }),
        [generatedText, setGeneratedText] = useState(''),
        [elementError, setElementError] = useState(null),
        [preventSwipe, setPreventSwipe] = useState(false);

    const { elementId } = useParams();
    const history = useHistory();
    const swipeHandlers = useSwipeable({
        onSwiping: () => {
            if (!preventSwipe && elIsDragging.drag) {
                setPreventSwipe(true);
            }
        },
        onSwipedRight: ({ event }) => {
            event.stopPropagation();
            if (!elIsDragging.drag && !preventSwipe && !sidebarActive) {
                setSidebarActive(true);
            } else if (preventSwipe) {
                setPreventSwipe(false);
            };
        },
        onSwipedLeft: ({ event }) => {
            event.stopPropagation();
            if (!elIsDragging.drag && !preventSwipe && sidebarActive) {
                setSidebarActive(false);
            } else if (preventSwipe) {
                setPreventSwipe(false);
            };
        }
    });
    const cloneElement = (el) => {
        return JSON.parse(JSON.stringify(el));
    },
        addControlHandler = ({ _id: targetId, type, sourceParamIndex = null, sourceControlIndex = null }, targetIndex) => {
            if (!didChanges) {
                setDidChanges(true);
            }
            const newCurrentElement = { ...currentElement };
            let newControl = {};
            if (targetId) {
                newControl = doctorsFormControls.find(({ _id }) => _id === targetId)
            } else if (sourceParamIndex !== null && sourceControlIndex !== null) {
                if (targetIndex !== sourceParamIndex) {
                    newControl = cloneElement(newCurrentElement.formControls[sourceControlIndex]);
                    newCurrentElement.formControls.splice(sourceControlIndex, 1);
                } else {
                    return null;
                }
            } else {
                newControl = cloneElement(newFormControlSchema);
            }
            const newFieldIndex = newCurrentElement.formControls.length + 1;
            newCurrentElement.formControls.push({
                ...newControl,
                formType: type,
                name: targetId ? `${newControl.name} ${newFieldIndex}` : `Pole ${newFieldIndex}`
            });
            setCurrentElement(newCurrentElement);
        },
        cloneControlHandler = (targetIndex) => {
            if (!didChanges) {
                setDidChanges(true);
            }
            const newCurrentElement = { ...currentElement };
            const newControl = cloneElement(newCurrentElement.formControls[targetIndex]);
            newCurrentElement.formControls.push({ ...newControl, name: `${newControl.name} - kopia` });
            setCurrentElement(newCurrentElement);
        },
        removeControlHandler = (targetIndex) => {
            if (!didChanges) {
                setDidChanges(true);
            }
            const newCurrentElement = { ...currentElement };
            const targetName = newCurrentElement.formControls[targetIndex].name;
            if (newCurrentElement.text.includes(`{${targetName}}`)) {
                const reg = new RegExp(`{${targetName}}`, "g");
                newCurrentElement.text = newCurrentElement.text.replace(reg, '');
            }
            newCurrentElement.formControls.splice(targetIndex, 1);
            setCurrentElement(newCurrentElement);
            setCurrentControl(prev => ({ i: 0, obj: null }));
        },
        changeValuesHandler = (newValues, targetType) => {
            if (!didChanges) {
                setDidChanges(true);
            }
            if (targetType === 'control') {
                const newCurrentElement = { ...currentElement };
                if (newCurrentElement.formControls.findIndex(({ name }) => name === newValues.name) !== -1 && newCurrentElement.formControls[currentControl.i].name !== newValues.name) {
                    return setElementError('Pole o tej nazwie już istnieje!');
                }
                const oldName = newCurrentElement.formControls[currentControl.i].name;
                if (oldName !== newValues.name && newCurrentElement.text.includes(`{${oldName}}`)) {
                    const reg = new RegExp(`{${oldName}}`, "g");
                    newCurrentElement.text = newCurrentElement.text.replace(reg, `{${newValues.name}}`);
                };
                newCurrentElement.formControls[currentControl.i] = newValues;
                setCurrentControl(prev => ({ ...prev, obj: newValues }));
                setCurrentElement(newCurrentElement);
            } else if (targetType === 'element') {
                if (newValues.name && newValues.stage) {
                    const nameTakenEl = doctorsElements.find(({ name, _id }) => name?.replace(/ /g, '') === newValues?.name?.replace(/ /g, '') && _id !== currentElement._id);
                    if (nameTakenEl && newValues.stage === nameTakenEl.stage) {
                        return setElementError('Nazwa elementu jest już zajęta w tym etapie!')
                    } else {
                        setElementError(null)
                    }
                }
                const newCurrentElement = { ...currentElement, ...newValues };
                setCurrentElement(newCurrentElement);
            }
        },
        updateElementHandler = useCallback(() => {
            setDidChanges(false);
            updateVisitElement(currentElement._id, currentElement);
        }, [updateVisitElement, currentElement]),
        saveFormControlHandler = (controlIndex) => {
            const { _id, ...newSavedControl } = { ...currentElement.formControls[controlIndex], doctorId: userRole.userId };
            addDoctorsFormControls(newSavedControl);
        };

    useEffect(() => {
        if (userRole) {
            getDoctorsFormControls(userRole.userId);
            if (doctorsElements.length === 0) {
                getDoctorsVisitElements(userRole.userId);
            }
        }
    }, [userRole]);

    useEffect(() => {
        if (doctorsElements.length > 0) {
            const newObj = cloneElement(doctorsElements.find(({ _id }) => _id === elementId));
            setCurrentElement(newObj);
        }
    }, [doctorsElements]);

    useEffect(() => {
        if (currentControl.i || currentControl.i === 0 && Object.keys(currentElement).length > 0) {
            const newObj = currentElement.formControls.find((el, index) => index === currentControl.i);
            setElementError(null);
            setCurrentControl(prev => ({ ...prev, obj: newObj }));
        }
    }, [currentControl.i, currentElement]);

    //Tutorial handler
    useEffect(() => {
        if (currentTutorialStep > 1) {
            if (elIsDragging?.drag) {
                nextTutorialStep();
            } else if (!elIsDragging?.drag) {
                if (currentElement.formControls?.length < 1) {
                    prevTutorialStep();
                } else if (currentElement.formControls?.length > 0) {
                    nextTutorialStep();
                }
            }
        }
    }, [elIsDragging]);
    useEffect(() => {
        if (currentTab === 1) {
            nextTutorialStep();
        }
    }, [currentTab]);
    useEffect(() => {
        if (currentSelectType === 'control') {
            nextTutorialStep();
        }
    }, [currentSelectType]);

    return (
        <ElementEditorPageView
            currentElement={currentElement}
            setCurrentElement={setCurrentElement}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            history={history}
            addControlHandler={addControlHandler}
            removeControlHandler={removeControlHandler}
            changeValuesHandler={changeValuesHandler}
            currentControl={currentControl}
            setCurrentControl={setCurrentControl}
            currentSelectType={currentSelectType}
            setCurrentSelectType={setCurrentSelectType}
            elIsDragging={elIsDragging}
            setElIsDragging={setElIsDragging}
            updateElementHandler={updateElementHandler}
            didChanges={didChanges}
            warningModal={warningModal}
            setWarningModal={setWarningModal}
            generatedText={generatedText}
            setGeneratedText={setGeneratedText}
            cloneControlHandler={cloneControlHandler}
            saveFormControlHandler={saveFormControlHandler}
            doctorsFormControls={doctorsFormControls}
            deleteDoctorsFormControls={deleteDoctorsFormControls}
            elementError={elementError}
            setElementError={setElementError}
            doctorsElements={doctorsElements}
            sidebarActive={sidebarActive}
            setSidebarActive={setSidebarActive}
            swipeHandlers={swipeHandlers}
        />
    )
}

const mapStateToProps = state => ({
    userRole: state.authentication.userRole,
    doctorsElements: state.visitElement.doctorsElements,
    doctorsFormControls: state.patternFormControl.doctorsFormControls,
    sidebarActive: state.navigation.sidebarActive,
    currentTutorialStep: state.tutorial.stepIndex,
});

const mapDispatchToProps = dispatch => {
    return {
        getDoctorsVisitElements: (doctorId) => dispatch(getDoctorsVisitElements(doctorId)),
        updateVisitElement: (elementId, newElement) => dispatch(updateVisitElement(elementId, newElement)),
        getDoctorsFormControls: (doctorId) => dispatch(getDoctorsFormControls(doctorId)),
        addDoctorsFormControls: (newFormControl) => dispatch(addDoctorsFormControls(newFormControl)),
        deleteDoctorsFormControls: (formControlId) => dispatch(deleteDoctorsFormControls(formControlId)),
        setSidebarActive: (payload) => dispatch(setSidebarActive(payload)),
        nextTutorialStep: () => dispatch(nextTutorialStep()),
        prevTutorialStep: () => dispatch(prevTutorialStep()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElementEditorPageContainer);