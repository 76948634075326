import React from "react";
import Modal from "../../Shared/Modals/Modal";
import styled from "styled-components";
import Input from "../../Shared/Input";
import Textarea from "../../Shared/Textarea";
import Button from "../../Shared/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Select from "../../Shared/Select";
import DatePicker from "../../Shared/DatePicker";
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Pole wymagane")
    .min(4, "Pole wymaga minimum 4 znaków")
    .max(50, "Liczba znaków została przekroczona"),
  start: Yup.date().required("Pole wymagane"),
  end: Yup.date().required("Pole wymagane")
});

const AddAllDayEventModal = ({
  isAddAlldayEventModalOn,
  setAddAllDayModal,
  doctors,
  currentHealthcenter,
  selectedEvent,
  addAllDayEvent,
  user
}) => {
  const initialValues = {
    healthcenter: currentHealthcenter?._id,
    title: "",
    registeringPerson: user._id,
    start: moment(selectedEvent.startDate).format("YYYY-MM-DD HH:mm:ss"),
    end: moment(selectedEvent.endDate).format("YYYY-MM-DD HH:mm:ss"),
    members: [],
    description: ""
  };
  return (
    <Modal
      title="Dodawanie wydarzenia długoterminowego"
      show={isAddAlldayEventModalOn}
      exitButton={true}
      onCancel={() => {
        setAddAllDayModal(false);
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async values => {
          const valuesToUpdate = {
            ...values,
            members: values.members.map(member => member.value),
            start:  moment(new Date(values.start).setMinutes(1)).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(new Date(values.end).setHours(24)).format("YYYY-MM-DD HH:mm:ss")
          };
          addAllDayEvent(valuesToUpdate);
          setAddAllDayModal(false);
          toast('Wydarzenie długoterminowe zostało dodane.', {
            type: 'success'
        })
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="title"
              label="Tytuł"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              error={errors.title}
            />
            <InputRow>
              <DatePicker
                label="Początek"
                dateFormat="dd.MM.yyyy"
                selected={Date.parse(values.start)}
                error={errors.start}
                onChange={e =>
                  setFieldValue(
                    "start",
                    moment(e).format("YYYY-MM-DD HH:mm:ss")
                  )
                }
              />
              <DatePicker
                label="Koniec"
                dateFormat="dd.MM.yyyy"
                selected={Date.parse(values.end)}
                error={errors.end}
                onChange={e =>
                  setFieldValue("end", moment(e).format("YYYY-MM-DD HH:mm:ss"))
                }
              />
            </InputRow>
            <Select
              label="Członkowie"
              name="members"
              placeholder="Wybierz osoby..."
              options={doctors.map(doctor => {
                return { label: `${doctor.userId.name} ${doctor.userId.surname}`, value: doctor._id };
              })}
              onChange={value =>
                value !== null
                  ? setFieldValue("members", value)
                  : setFieldValue("members", [])
              }
              noOptionsMessage={() => "Nie znaleziono"}
              error={errors.members}
              isMulti={true}
            />

            <Textarea
              name="description"
              label="Dodatkowe informacje"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              error={errors.description}
            />
            <CenterBox>
              <Button type="submit" variant="primary">
                Dodaj wydarzenie
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddAllDayEventModal;

const Form = styled.form`
  padding-top: 20px;
  width: 450px;
`;

const CenterBox = styled.div`
  text-align: center;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 48%;
  }
`;
