import React from 'react';
import styled from 'styled-components';
import arrowIcon from '../../Shared/assets/arrow-down.svg';
import avatarPlaceholder from '../../Shared/assets/avatar.svg';
import Input from '../../Shared/Input';
import Button from '../../Shared/Button';
import Spinner from '../../Shared/Spinner';
import { Formik } from 'formik';
import moment from 'moment';

const ChatView = ({
    channelsList,
    currentChannel,
    setCurrentChannel,
    sendMessageHandler,
    currentChannelMessages,
    userId,
    detailedMessage,
    detailedMessageChangeHandler,
    ChatElRef,
    scrollLoadHandler,
    setChatChannelOpen,
    clearUnreadMessages,
    unreadMessages,
    companyLoading
}) => {
    return (
        <ChatWrapper data-testid='chatWrapper'>
            {currentChannel === null ?
                <ChannelsList>
                    <Heading>Czaty</Heading>
                    {(!companyLoading && channelsList.length > 0) ? channelsList.map(({ name, _id }, index) => (
                        <ChannelsItem
                            onClick={() => { setCurrentChannel(_id); setChatChannelOpen(_id); clearUnreadMessages(_id) }}
                            key={`${_id}_${index}`}
                        >
                            {name}
                            {unreadMessages[_id] > 0 && <UnreadMessagesIndicator className='unreaded-message-indicator'>
                                <UnreadMessagesCount>{unreadMessages[_id] > 9 ? `9+` : unreadMessages[_id]}</UnreadMessagesCount>
                            </UnreadMessagesIndicator>}

                        </ChannelsItem>
                    ))
                        :
                        <Spinner size='9em' />
                    }
                </ChannelsList>
                :
                <ChannelWrapper>
                    <Heading>
                        <ReturnBtn
                            title='Powrót'
                            onClick={() => { setCurrentChannel(null); setChatChannelOpen('') }}
                        />
                        {channelsList.find(el => el._id === currentChannel)?.name}
                    </Heading>
                    <Messages onScroll={scrollLoadHandler} ref={ChatElRef}>
                        {currentChannelMessages.map((messageGroup, index) => (
                            <MessageGroup key={index}>
                                {
                                    messageGroup.map(({ _id, userData: { _id: messageUserId, avatar, name, surname }, content, postDate }, index) => {
                                        const side = messageUserId === userId ? 'right' : 'left',
                                            detailed = _id === detailedMessage ? true : false;

                                        return (
                                            <Message key={_id}>
                                                <Top side={side}>
                                                    <Avatar
                                                        show={messageGroup.length === index + 1}
                                                        onClick={() => detailedMessageChangeHandler(_id)}
                                                        src={avatar ? `${process.env.REACT_APP_SRV_URL}${avatar}` : avatarPlaceholder}
                                                    />
                                                    <Text side={side} onClick={() => detailedMessageChangeHandler(_id)}>
                                                        {content}
                                                    </Text>
                                                </Top>
                                                <Details side={side} detailed={detailed}>
                                                    <Name>{name} {surname}</Name>
                                                    <Date>{moment(postDate).format('DD.MM.YYYY HH:mm')}</Date>
                                                </Details>
                                            </Message>
                                        )
                                    })
                                }
                            </MessageGroup>
                        ))}
                    </Messages>
                    <Formik
                        initialValues={{
                            message: '',
                        }}
                        onSubmit={({ message }, { resetForm }) => {
                            sendMessageHandler(message);
                            resetForm();
                        }}
                    >
                        {({ handleChange, handleSubmit, values }) => (
                            <MessageForm
                                onSubmit={handleSubmit}
                            >
                                <MessageInput
                                    hideError={true}
                                    onChange={handleChange}
                                    name="message"
                                    value={values.message}
                                    autoComplete="off"
                                />
                                <Button type='submit' variant='primary'>Wyślij</Button>
                            </MessageForm>
                        )}
                    </Formik>
                </ChannelWrapper>
            }
        </ChatWrapper >
    )
}

export default ChatView;

const UnreadMessagesCount = styled.span`
 font-size: 10px;
 color: #fff;
 font-weight: bold;
`

const UnreadMessagesIndicator = styled.div`
width: 15px;
height: 15px;
border-radius: 50%;
background-color: #C75454;
position: absolute;
top: -4px;
left:-4px;
display: flex;
justify-content: center;
align-items: center;
`

const ChatWrapper = styled.div`
    padding:5px 5px 0;
    position: relative;
`;
const ChannelsList = styled.ul`
    margin:0;
`;

const ChannelsItem = styled.li`
    position: relative;
    font-size:14px;
    padding: 10px;
    cursor:pointer;
    margin: 5px 0;
    border: 1px solid ${({ theme }) => theme.grey};
    transition:300ms;
    &:hover{
        background-color:${({ theme }) => theme.background};
    }
`;

const ChannelWrapper = styled.div``;

const Heading = styled.p`
    margin: 5px 0 5px 10px;
    color: ${({ theme }) => theme.black};
    display:flex;
    align-items:center;
    font-weight: 700;
    font-size: 16px;
`;

const ReturnBtn = styled.button`
    background-image: url(${arrowIcon});
    background-color: #fff;
    transform:rotate(90deg);
    cursor:pointer;
    height: 20px;
    width: 20px;
    border: none;
    background-position: center;
    background-size: cover;
    margin-right:5px;
`;

const Messages = styled.ul`
    height:270px;
    overflow-y:scroll;
    overflow-x:hidden;
    margin:0 0 55px 0;
    padding:0 5px 5px;
    
`;

const MessageGroup = styled.div`
    margin:15px 0;
`

const Message = styled.li`
    margin:5px 0;
`;

const Avatar = styled.img`
    width:20px;
    min-width:20px;
    height:20px;
    min-height:20px;
    border-radius:50%;
    cursor:pointer;
    opacity:0;
    ${({ show }) => show && 'opacity:1;'}
`;

const Text = styled.p`
    padding:10px;
    border-radius:10px;
    font-size:14px;
    cursor:pointer;
    box-shadow: 0 0 4px #bfbfbf;
    ${({ side, theme }) => side === 'right' ?
        `
            border-bottom-right-radius:0;
            background-color:${theme.primary};
            color:#fff;
            margin:0 5px 0 10px;
        `
        :
        `
            border-bottom-left-radius:0;
            background-color:#fff;
            color:#000;
            margin:0 10px 0 5px;
        `
    }
`;

const Top = styled.div`
    display:flex;
    align-items:flex-end;
    ${({ side }) => side === 'right' ?
        `
            flex-direction:row-reverse;
        `
        :
        `
            justify-content:flex-start;
        `
    }
`;

const Details = styled.div`
    display:flex;
    max-height:0;
    transition:300ms;
    overflow:hidden;
    margin: 0 25px;
    ${({ side }) => side === 'right' &&
        `
            justify-content:flex-end;
        `
    }
    ${({ detailed }) => detailed &&
        `
            margin-top:3px;
            max-height:15px;
        `
    }
`;

const Name = styled.p`
    font-size:12px;
    color:#939393;
    margin-right:5px;
`;

const Date = styled.p`
    font-size:12px;
    color:#939393;
`;

const MessageForm = styled.form`
    display:grid;
    grid-template-columns: 4fr 1fr;
    padding:10px;
    margin: 0 -10px;
    border-top:1px solid ${({ theme }) => theme.grey};
    box-shadow: 0 0 4px #bfbfbf;
    position: absolute;
    bottom: -4px;
    background-color: #fff;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    left: 10px;
    height: 57px;
    input{
        margin:0;
        border-bottom-right-radius:0;
        border-top-right-radius:0;
    }
    button{
        padding: 8px 15px;
        margin:0;
        border-bottom-left-radius:0;
        border-top-left-radius:0;
        height: 38px;
    }
`;

const MessageInput = styled(Input)`
`;