import React, { Children } from "react";
import styled from "styled-components";
import { Calendar } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import * as calendarConfig from "./calendarConfig";
import Toolbar from "./Toolbar";
import Header from "./Header";
import CustomEvent from "./CustomEvent";
import ResourceHeader from "./ResourceHeader";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pl";
const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const formats = {
  timeGutterFormat: (date) => {
    return localizer.format(date, "HH:mm");
  },
  eventTimeRangeFormat: (date) => {
    let timeRangeFormat = `${localizer.format(
      date.start,
      "HH:mm"
    )} - ${localizer.format(date.end, "HH:mm")}`;
    return timeRangeFormat;
  },
  dayFormat: (date, culture) => {
    const day = localizer.format(date, "dddd DD", culture)
    return day.charAt(0).toUpperCase() + day.slice(1);
  },
};

const RBCalendar = ({
  user,
  key,
  date,
  currentHealthcenter,
  healthcenters,
  setCurrentView,
  setCurrentHealthcenter,
  updateResourceStatus,
  updateRoomsStatus,
  roomsStatus,
  resourceStatus,
  getCalendarLocalStorage,
  onNavigate,
  setSelectedEvent,
  setDragModal,
  setEditAllDayModal,
  setEditModal,
  createNewEvent,
  currentView,
  userRole,
  visits,
  allDayEvents,
  calendarDoctors,
  setSlotStyleGetter,
  setEventStyleGetter,
  calendarDate,
}) => {
  const returnView = (currentView, userRole) => {
    if (currentView === "week" && user.activeRole === "Doctor") {
      if (!userRole.weekendWorker) {
        return "work_week";
      } else {
        return "week";
      }
    } else if (currentView === "work_week" && user.activeRole === "Doctor") {
      if (userRole.weekendWorker) {
        return "week";
      } else {
        return "work_week";
      }
    } else {
      return currentView;
    }
  };
  const MyTimeSlotWrapper = ({ children, value }) => {
    const isCurrentDay = moment(value).isSame(calendarDate, 'day')
    if (!children.props.children?.props?.className.includes('rbc-label') && children.props.children !== null) {
      return React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          backgroundColor: isCurrentDay && '#d9edff',
        },
      });
    } else if (children.props.children?.props?.className.includes('rbc-label') && children.props.children?.props?.children.includes(':00') && children.props.children !== null) {
      return React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          fontWeight: '600',
        },
      });
    } else {
      return React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
        },
      });
    }
  }

  const ColoredDateCellWrapper = ({ children, value }) => {
    const isCurrentDay = moment(value).isSame(calendarDate, 'day')
    const isBefore = moment(value).isBefore(new Date(), 'day')
    if (isBefore) {
      return React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          background: 'repeating-linear-gradient( -14deg, #eceef2, #eceef2 10px, #f5f7f9 10px, #f5f7f9 20px )',
        },
      });
    } else {
      return React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          backgroundColor: isCurrentDay && '#d9edff',
        },
      });
    }
  }

  return (
    <CalendarWrapper>
      <StyledCalendar
        //stupid fix to update event component
        key={key}
        selectable
        defaultDate={new Date()}
        date={date}
        calendarDate={calendarDate}
        draggableAccessor={event => {
          if (event.isAllDay) {
            return true
          } else {
            if ((user.activeRole === 'Doctor' || user.activeRole === 'Nurse') && userRole._id !== event.doctor) {
              return false
            } else {
              return true
            }
          }
        }}
        components={{
          event: ({ event }) => {
            return <CustomEvent event={event} />;
          },
          toolbar: (props) => {
            return (
              <Toolbar
                {...props}
                currentHealthcenter={currentHealthcenter}
                healthcenters={healthcenters}
                setCurrentView={setCurrentView}
                setCurrentHealthcenter={setCurrentHealthcenter}
                updateResourceStatus={updateResourceStatus}
                updateRoomsStatus={updateRoomsStatus}
                roomsStatus={roomsStatus}
                resourceStatus={resourceStatus}
                getCalendarLocalStorage={getCalendarLocalStorage}
                user={user}
                userRole={userRole}
              />
            );
          },
          header: Header,
          resourceHeader: ({ resource }) => {
            return <ResourceHeader resource={resource} />;
          },
          dateCellWrapper: ColoredDateCellWrapper,
          timeSlotWrapper: MyTimeSlotWrapper
        }}
        onNavigate={onNavigate}
        onEventResize={(event) => {
          if (event.event.isAllDay) {
            const mapedEvent = {
              startDate: event.start,
              endDate: event.end,
              event: event.event,
            }
            setSelectedEvent(mapedEvent);
          } else {
            setSelectedEvent(event);
          }
          setDragModal(true);
        }}
        onEventDrop={(event) => {
          if (event.event.isAllDay) {
            const mapedEvent = {
              startDate: event.start,
              endDate: event.end,
              event: event.event,
            }
            setSelectedEvent(mapedEvent);
          } else {
            setSelectedEvent(event);
          }
          setDragModal(true);
        }}
        onSelectEvent={(event) => {
          if (event?.isAllDay) {
            console.log("allday event");
            const mapedEvent = {
              ...event,
              startDate: event.start,
              endDate: event.end,
            }
            setSelectedEvent(mapedEvent);
            setEditAllDayModal(true);
          } else {
            console.log("simple visit");
            setSelectedEvent(event);
            setEditModal(true);
          }
        }}
        onSelectSlot={(event) => createNewEvent(event)}
        localizer={localizer}
        events={[
          ...visits.map((visit) => {
            return {
              ...visit,
              start: new Date(visit.start),
              end: new Date(visit.end),
              isAllDay: false,
            };
          }),
          ...allDayEvents.map((event) => {
            return {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
              isAllDay: true,
            };
          }),
        ]}
        culture="pl-PL"
        views={["month", "work_week", "week", "day"]}
        view={returnView(currentView, userRole)}
        defaultView="day"
        formats={formats}
        messages={calendarConfig.messages}
        min={
          userRole?.callendarSettings
            ? new Date(userRole?.callendarSettings?.min)
            : calendarConfig.minTime
        }
        max={
          userRole?.callendarSettings
            ? new Date(userRole?.callendarSettings?.max)
            : calendarConfig.maxTime
        }
        step={
          userRole?.callendarSettings
            ? userRole.callendarSettings.step
            : calendarConfig.slotTime
        }
        startAccessor="start"
        endAccessor="end"
        resources={
          resourceStatus
            ? roomsStatus
              ? currentHealthcenter?.rooms
              : calendarDoctors.filter((doc) => doc.checked)
            : null
        }
        resourceAccessor={roomsStatus ? "room" : "doctor"}
        resourceIdAccessor="_id"
        resourceTitleAccessor="name"
        // resizable={false}
        slotPropGetter={(e) => setSlotStyleGetter(e)}
        eventPropGetter={setEventStyleGetter}
        resourceStatus={resourceStatus}
      />
    </CalendarWrapper>
  );
};

export default RBCalendar;

const CalendarWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow: hidden;
`;
const StyledCalendar = styled(DragAndDropCalendar)`
  transition: transform 0.5s;
  width: 100%;
  min-height: calc(100vh - 200px);
  ${({ theme }) => `${theme.mq.desktop}{
      width: calc(100vw - 300px);
  }`}
.rbc-calendar {
    background-color: ${({ theme }) => theme.background};
  }
  .rbc-show-more {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #fafafa;
    border-radius: 5px;
  }

  .rbc-allday-cell {
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD;
    ${({ view }) =>
    view === "week" || view === "work_week"
      ? `
      display: block;
      border-right: none;
      margin-top: 15px;
      `
      : `display: none;`}
  }
  .rbc-time-header-content{
    border-left: none;
  }
  .rbc-time-header-cell-single-day {
    display: flex;
  }
  .rbc-time-view,
  .rbc-month-view {
    border: none;
  }
  .rbc-header {
    border-left: none;
    background-color: ${({ theme }) => theme.background};
    margin: 0 auto;
    min-height: 70px;
    z-index: 1;
    ${({ view }) =>
    view === "day" &&
    `
     border-bottom: none;
     span {
       margin-bottom: 0;
     }
    `}
    ${({ view }) =>
    view === "month" &&
    `
        border-bottom: none;
        min-height: 50px;
    `}
    ${({ view }) =>
    (view === "week" || view === "work_week") &&
    `
    // padding-bottom: 20px;
    margin-top: -10px;
    `}
  span:first-child {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 10px 0 10px;
  }
    span:nth-child(2) {
    border-radius: 50%;
    font-weight: normal;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.25s;
    &:hover {
        background-color: #f1f3f4;
    }
    }
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: none;
  }
  .rbc-row-resource > .rbc-header {
    border-bottom: none;
    min-height: auto;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd0;
}
  .rbc-row-content {
    z-index: 0;
  }
  .rbc-time-content,
  .rbc-month-view {
    background: #fff;
  }
  .rbc-allday-cell > .rbc-row-content {
    margin-bottom: -25px;
}
  .rbc-time-content > .rbc-time-gutter {
    background-color: ${({ theme }) => theme.background};
  }
 .rbc-day-slot .pastTimeSlot {
    background: repeating-linear-gradient(
      -14deg,
      #eceef2,
      #eceef2 10px,
      #f5f7f9 10px,
      #f5f7f9 20px
    );
  }
  .rbc-label {
    color: ${({ theme }) => theme.lightBlack};
    position: relative;
    z-index: 5;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    background-color: #f2f5f8;
    top: -15px;
  }
  .rbc-time-header-gutter {
    margin-right: 0;
    border-right: none;
    background-color: transparent;
  }
  .rbc-current-time-indicator {
    background-color: #ff6565;
    height: 2px;
    z-index: 0;
    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: #ff6565;
      border-radius: 50%;
      top: -7px;
      left: -8px;
    }
  }
  .rbc-day-slot {
    .rbc-event {
      background-color: #4564dd;
      border: 1px solid #6780e6;
      border-radius: 4px;
    }
    .rbc-addons-dnd-dragged-event {
      opacity: 0.5;
    }
    .rbc-event-label{
      display: none;
      ${({ view }) =>
    (view === "day") && `
      display: inline-block;
      margin-right: 20px;
    `
  }
    }
    .rbc-event-content{
      width: auto;
      line-height: 14px;
    }
  }
  .rbc-time-column .rbc-timeslot-group:last-of-type {
    border-bottom: none;
  }
  .rbc-row-resource > .rbc-header {
    margin: 0 0 5px;
  }
  .rbc-addons-dnd-resizable{
    display: flex;
    flex-direction: row-reverse;
  }
  .rbc-selected-cell{
    background:rgba(0, 0, 0, 0.1)!important;
  }
`;