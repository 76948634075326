import React from "react";
import styled from "styled-components";
import Container from "../Shared/Container";
import Button from "../Shared/Button";
import EditBasicInfoModal from "./modals/EditBasicInfoModal";
import PersonalizationModal from "./modals/PersonalizationModal";
import ColorPicker from "../Shared/ColorPicker";

const CompanyView = ({
  company,
  updateCompany,
  editBasicInfoModal,
  setEditBasicInfoModal,
  personalizationModal,
  setPersonalizationModal,
  updateCompanyLogo,
}) => {
  return (
    <Wrapper>
      {company && (
        <>
          <EditBasicInfoModal
            modalShown={editBasicInfoModal}
            setModalShown={setEditBasicInfoModal}
            companyData={company}
            updateCompany={updateCompany}
          />
          <PersonalizationModal
            modalShown={personalizationModal}
            setModalShown={setPersonalizationModal}
            companyData={company}
            updateCompany={updateCompany}
            updateCompanyLogo={updateCompanyLogo}
          />
          <StyledContainer height="265px" title="Dane podstawowe">
            <InfoList>
              <InfoItem>
                <InfoLabel>Nazwa</InfoLabel>
                <InfoValue>{company.name}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>NIP</InfoLabel>
                <InfoValue>
                  {company.nipNumber ? company.nipNumber : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>REGON</InfoLabel>
                <InfoValue>
                  {company.regonNumber ? company.regonNumber : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Telefon</InfoLabel>
                <InfoValue>
                  {company.phoneNumber ? company.phoneNumber : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Kod pocztowy</InfoLabel>
                <InfoValue>
                  {company.zipCode ? company.zipCode : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Adres</InfoLabel>
                <InfoValue>
                  {company.address.Street !== "" &&
                  company.address.houseNumber !== "" &&
                  company.address.City !== ""
                    ? `${company.address.Street} ${company.address.houseNumber}, ${company.address.City}`
                    : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Numer księgi</InfoLabel>
                <InfoValue>
                  {company.bookNumber ? company.bookNumber : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>I cz. RZOZ</InfoLabel>
                <InfoValue>
                  {company.rzozI ? company.rzozI : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>III cz. RZOZ</InfoLabel>
                <InfoValue>
                  {company.rzozIII ? company.rzozIII : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Numer wpisu</InfoLabel>
                <InfoValue>
                  {company.entryNumber ? company.entryNumber : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Konto bankowe</InfoLabel>
                <InfoValue>
                  {company.bankAccountNumber
                    ? company.bankAccountNumber
                    : "Nie podano"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Dokumenty</InfoLabel>
                <InfoValue>
                  {company.address.City ? company.address.City : "Nie podano"}
                </InfoValue>
              </InfoItem>
            </InfoList>
            <Button
              variant="edit"
              onClick={() => {
                setEditBasicInfoModal(true);
              }}
            />
          </StyledContainer>
          <StyledContainer title="Personalizacja aplikacji">
            <Label>Logo firmy</Label>
            {company.logo ? (
              <CompanyLogo
                src={`${process.env.REACT_APP_SRV_URL}${company.logo}`}
              />
            ) : (
              <NoData>Brak</NoData>
            )}
            {/* <Label>
                        Kolory
                    </Label> */}
            {/* <AppColors>
                        <Color>
                            <ColorLabel>Podstawowy</ColorLabel>
                            <ColorPicker
                                disabled={true}
                                currentColor={company.colors?.primary}
                                width='100px'
                                onChange={() => {
                                    
                                }}
                            />
                        </Color>
                        <Color>
                            <ColorLabel>Drugorzędny</ColorLabel>
                            <ColorPicker
                                disabled={true}
                                currentColor={company.colors?.secondary}
                                width='100px'
                                onChange={() => {
                                    
                                }}
                            />
                        </Color>
                        <Color>
                            <ColorLabel>Tło</ColorLabel>
                            <ColorPicker
                                disabled={true}
                                currentColor={company.colors?.background}
                                width='100px'
                                onChange={() => {
                                    
                                }}
                            />
                        </Color>
                    </AppColors> */}
            <Button
              variant="edit"
              onClick={() => setPersonalizationModal(true)}
            />
          </StyledContainer>
        </>
      )}
    </Wrapper>
  );
};

export default CompanyView;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1140px;
  margin: 0 auto;
  ${({ theme }) => `
      ${theme.mq.large}{
        flex-direction:row;
        justify-content:space-between;
        >div:nth-child(1){
        width:calc(60% - 20px);
        }
        >div:nth-child(2){
            width:calc(40% - 20px);
        }
      }`}
  >div {
    width: calc(100% - 20px);
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  height: ${({ height }) => (height ? height : "auto")};
`;

const InfoList = styled.ul`
  margin: 0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const InfoItem = styled.li`
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 0;
`;

const InfoLabel = styled.span`
  width: 40%;
  font-size: 12px;
  font-weight: 600;
`;

const InfoValue = styled.span`
  width: 60%;
  font-size: 12px;
  color: #595959;
`;

const Label = styled.p`
  font-weight: 600;
  font-size: 14px;
`;

const AppColors = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
`;

const ColorLabel = styled.p`
  font-size: 12px;
  margin-bottom: 2px;
  text-align: center;
`;

const Color = styled.div``;

const CompanyLogo = styled.img`
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
  margin: 10px auto;
  display: block;
`;

const NoData = styled.p`
  text-align: center;
  font-size: 12px;
  padding: 15px 0;
`;
