import React from "react";
import styled from "styled-components";
import moment from "moment";
import { parseAddress } from "../Shared/utils";

class VisitPrint extends React.Component {
  render() {
    const {
      doctor,
      doctorRole,
      patient,
      visitText,
      visitData,
      healthcenter,
      documents,
      companyLogo,
    } = this.props;
    const recommendationsArray =
      visitText?.recommendations?.split("-").slice(1) ?? [];
    const additionalExaminationsArray =
      visitText?.additionalExaminations?.split("-").slice(1) ?? [];
    const diagnosisArray = visitText?.diagnosis?.split("-").slice(1) ?? [];
    const documentsArray = [...documents.photos, ...documents.documents];
    return (
      <MainContainer>
        <FlexRow>
          <LogoContainer>
            <Logo>
              {companyLogo ? (
                <LogoImg
                  src={`${process.env.REACT_APP_SRV_URL}${companyLogo}`}
                />
              ) : (
                <span>Miejsce na logo</span>
              )}
            </Logo>
          </LogoContainer>
          <VisitInfoContainer>
            <VisitTextRow>
              <VisitBoldText>Data Wystawienia:</VisitBoldText>
              <VisitText>
                {moment(new Date()).format("DD MMMM YYYYr.")}
              </VisitText>
            </VisitTextRow>
            <VisitTextRow>
              <VisitBoldText>Data Wykonania:</VisitBoldText>
              <VisitText>
                {moment(visitData?.start).format("DD MMMM YYYYr.")}
              </VisitText>
            </VisitTextRow>
            <VisitTextRow>
              <VisitBoldText>Poradnia:</VisitBoldText>
              <VisitText>
                {healthcenter?.name} {healthcenter?.address?.City},{" "}
                {healthcenter?.address?.Street}{" "}
                {healthcenter?.address?.houseNumber}
              </VisitText>
            </VisitTextRow>
          </VisitInfoContainer>
        </FlexRow>
        <Title>Wizyta Lekarska</Title>
        <FlexRow>
          <PatientInfoContainer>
            <SubTitle>Pacjent</SubTitle>
            <VisitTextRow>
              <VisitBoldText>Imie i nazwisko:</VisitBoldText>
              <VisitText>
                {patient?.name} {patient?.surname ?? ""}
              </VisitText>
            </VisitTextRow>
            <VisitTextRow>
              <VisitBoldText>PESEL:</VisitBoldText>
              <VisitText>
                {(patient?.pesel && patient?.pesel) !== ""
                  ? patient?.pesel
                  : "Brak"}
              </VisitText>
            </VisitTextRow>
            <VisitTextRow>
              <VisitBoldText>Numer kartoteki:</VisitBoldText>
              <VisitText>
                {(patient?.fileNumber && patient?.fileNumber) !== ""
                  ? patient?.fileNumber
                  : "Brak"}
              </VisitText>
            </VisitTextRow>
            <VisitTextRow>
              <VisitBoldText>Data urodzenia:</VisitBoldText>
              <VisitText>
                {(patient?.birthDate && patient?.birthDate) !== ""
                  ? patient?.birthDate
                  : "Brak"}
              </VisitText>
            </VisitTextRow>
            <VisitTextRow>
              <VisitBoldText>Adres:</VisitBoldText>
              <VisitText>{parseAddress(patient?.address, "Brak")}</VisitText>
            </VisitTextRow>
            <VisitTextRow>
              <VisitBoldText>Telefon:</VisitBoldText>
              <VisitText>
                {(patient?.tel && patient?.tel) !== "" ? patient?.tel : "Brak"}
              </VisitText>
            </VisitTextRow>
            <VisitTextRow>
              <VisitBoldText>Email:</VisitBoldText>
              <VisitText>
                {(patient?.email && patient?.email) !== ""
                  ? patient?.email
                  : "Brak"}
              </VisitText>
            </VisitTextRow>
          </PatientInfoContainer>
          <DoctorInfoContainer>
            <SubTitle>Wystawca Dokumentu</SubTitle>
            <VisitTextRow>
              <VisitBoldText>
                {doctorRole?.academicTitle} {doctor.name} {doctor.surname ?? ""}
              </VisitBoldText>
            </VisitTextRow>
            {/* <VisitTextRow>
              <VisitBoldText>Specjalizacja:</VisitBoldText>
              <VisitText>Laryngolog</VisitText>
            </VisitTextRow> */}
          </DoctorInfoContainer>
        </FlexRow>
        <VisitTextContainer>
          <SubTitle>Przebieg Wizyty</SubTitle>
          <VisitTextRow>
            <VisitBoldText>Wywiad:</VisitBoldText>
          </VisitTextRow>
          <VisitTextRow>
            <VisitText>{visitText?.medicalIntelligence}</VisitText>
          </VisitTextRow>
          <VisitTextRow>
            <VisitBoldText>Badania Fizykalne:</VisitBoldText>
          </VisitTextRow>
          <VisitTextRow>
            <VisitText>{visitText?.physicalExaminations}</VisitText>
          </VisitTextRow>
          <VisitTextRow>
            <VisitBoldText>Badania Dodatkowe:</VisitBoldText>
          </VisitTextRow>
          {additionalExaminationsArray.length > 0 ? (
            additionalExaminationsArray?.map((additionalExamination, index) => (
              <VisitTextRow key={`additionalExamination_${index}`}>
                <VisitText>- {additionalExamination}</VisitText>
              </VisitTextRow>
            ))
          ) : (
            <VisitTextRow>
              <VisitText>{visitText?.additionalExaminations}</VisitText>
            </VisitTextRow>
          )}
          <VisitTextRow>
            <VisitBoldText>Rozpoznanie:</VisitBoldText>
          </VisitTextRow>
          {diagnosisArray.length > 0 ? (
            diagnosisArray?.map((diagnosis, index) => (
              <VisitTextRow key={`diagnosis_${index}`}>
                <VisitText>- {diagnosis}</VisitText>
              </VisitTextRow>
            ))
          ) : (
            <VisitTextRow>
              <VisitText>{visitText?.diagnosis}</VisitText>
            </VisitTextRow>
          )}
          <VisitTextRow>
            <VisitBoldText>Zalecenia:</VisitBoldText>
          </VisitTextRow>
          {recommendationsArray.length > 0 ? (
            recommendationsArray?.map((recommendation, index) => (
              <VisitTextRow key={`recommendation_${index}`}>
                <VisitText>- {recommendation}</VisitText>
              </VisitTextRow>
            ))
          ) : (
            <VisitTextRow>
              <VisitText>{visitText?.recommendations}</VisitText>
            </VisitTextRow>
          )}
          <VisitTextRow>
            <VisitBoldText>Załączone dokumenty:</VisitBoldText>
          </VisitTextRow>
          {documentsArray.length > 0 ? (
            documentsArray?.map(({ originalName }, index) => (
              <VisitTextRow key={`document_${index}`}>
                <VisitText>- {originalName}</VisitText>
              </VisitTextRow>
            ))
          ) : (
            <VisitTextRow>
              <VisitText>Brak</VisitText>
            </VisitTextRow>
          )}
        </VisitTextContainer>
      </MainContainer>
    );
  }
}

export default VisitPrint;

const VisitTextRow = styled.div`
  margin-bottom: 5px;
`;

const Logo = styled.div`
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const FlexRow = styled.div`
  display: flex;
`;

const PatientInfoContainer = styled.div`
  width: 50%;
`;

const DoctorInfoContainer = styled.div`
  width: 50%;
`;

const LogoContainer = styled.div`
  width: 50%;
  margin-top: 20px;
`;

const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const VisitInfoContainer = styled.div`
  width: 50%;
  margin-top: 20px;
`;

const VisitTextContainer = styled.div``;

const VisitText = styled.span``;

const VisitBoldText = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const Title = styled.h2`
  color: black;
  text-align: center;
`;

const SubTitle = styled.h3`
  color: black;
  text-align: center;
`;
