export const ADD_PATIENT_FROM_SOCKET = "ADD_PATIENT_FROM_SOCKET"
export const UPDATE_PATIENT_FROM_SOCKET = "UPDATE_PATIENT_FROM_SOCKET"
export const DELETE_PATIENT_FROM_SOCKET = "DELETE_PATIENT_FROM_SOCKET"

export const addPatientFromSocket = patient => ({
    type: ADD_PATIENT_FROM_SOCKET,
    payload: patient
})

export const updatePatientFromSocket = patient => ({
    type: UPDATE_PATIENT_FROM_SOCKET,
    payload: patient
})

export const deletePatientFromSocket = patientId => ({
    type: DELETE_PATIENT_FROM_SOCKET,
    payload: patientId
})