import React, { useState, useEffect } from "react";
import DoctorDashboardView from "./DoctorDashboardView";
import { connect } from "react-redux";
import { updatePatientWithVisits, getPatientVisitHistory, getMedicalVisitByVisitId, getCompanyPatients } from "../../../redux/modules/patients";
import { updateVisit, addActiveVisit, removeActiveVisit, deleteVisit } from "../../../redux/modules/visits";
import { getEmployeeHealthcenters } from "../../../redux/modules/healthcenter";
import { withRouter } from "react-router";

const DoctorDashboardContainer = ({
  currentPatient,
  currentVisit,
  updatePatientWithVisits,
  currentPatientPreviousVisit,
  currentPatientPreviousMedicalVisit,
  updateVisit,
  visits,
  getEmployeeHealthcenters,
  userRole,
  healthcenters,
  getPatientVisitHistory,
  currentPatientVisits,
  getMedicalVisitByVisitId,
  historyMedicalVisit,
  history,
  getCompanyPatients,
  addActiveVisit,
  removeActiveVisit,
  patients,
  user,
  deleteVisit,
}) => {
  const [patientModalState, setPatientModalState] = useState(false);
  const [selectedHistoryVisit, setSelectedHistoryVisit] = useState(null);
  const [questionModal, setQuestionModal] = useState({ shown: false, question: '', onAccept: null });
  const [editVisitModalState, setEditVisitModalState] = useState(false);

  useEffect(() => {
    if (userRole) {
      getEmployeeHealthcenters(userRole.healthCenters)
      getCompanyPatients(userRole.companyId, 20, 1, "");
    }
  }, [userRole])

  useEffect(() => {
    if (currentPatient && userRole) {
      getPatientVisitHistory(currentPatient._id)
    }
  }, [currentPatient])

  useEffect(() => {
    if (currentPatientVisits && currentPatientVisits[0]) {
      setSelectedHistoryVisit(currentPatientVisits[0]._id)
      getMedicalVisitByVisitId(currentPatientVisits[0]._id)
    }
  }, [currentPatientVisits])

  const getHistoryVisitDetails = (visit) => {
    if (selectedHistoryVisit !== visit._id) {
      setSelectedHistoryVisit(visit._id)
      getMedicalVisitByVisitId(visit._id)
    }
  }

  const getCompanyPatientsHandler = (searchTerm) => {
    getCompanyPatients(userRole.companyId, 20, 1, searchTerm);
  }

  return (
    <DoctorDashboardView
      updateVisit={updateVisit}
      currentPatientPreviousMedicalVisit={currentPatientPreviousMedicalVisit}
      currentPatientPreviousVisit={currentPatientPreviousVisit}
      updatePatientWithVisits={updatePatientWithVisits}
      patientModalState={patientModalState}
      setPatientModalState={setPatientModalState}
      currentVisit={currentVisit}
      currentPatient={currentPatient}
      visits={visits}
      healthcenters={healthcenters}
      currentPatientVisits={currentPatientVisits}
      selectedHistoryVisit={selectedHistoryVisit}
      getHistoryVisitDetails={getHistoryVisitDetails}
      historyMedicalVisit={historyMedicalVisit}
      questionModal={questionModal}
      setQuestionModal={setQuestionModal}
      history={history}
      addActiveVisit={addActiveVisit}
      removeActiveVisit={removeActiveVisit}
      patients={patients}
      editVisitModalState={editVisitModalState}
      setEditVisitModalState={setEditVisitModalState}
      user={user}
      userRole={userRole}
      deleteVisit={deleteVisit}
      getCompanyPatientsHandler={getCompanyPatientsHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  currentPatient: state.patients.currentPatient,
  currentVisit: state.visits.currentVisit,
  currentPatientPreviousVisit: state.patients.currentPatientPreviousVisit,
  currentPatientPreviousMedicalVisit: state.patients.currentPatientPreviousMedicalVisit,
  visits: state.visits.data,
  healthcenters: state.healthcenter.data,
  userRole: state.authentication.userRole,
  currentPatientVisits: state.patients.currentPatientVisitsHistory,
  historyMedicalVisit: state.patients.currentPatientPreviousMedicalVisit,
  patients: state.patients.data,
  user: state.authentication.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updatePatientWithVisits: (patientNewData) => dispatch(updatePatientWithVisits(patientNewData)),
    updateVisit: (visitId, newState, body) => dispatch(updateVisit(visitId, newState, body)),
    getEmployeeHealthcenters: healthcentersId => dispatch(getEmployeeHealthcenters(healthcentersId)),
    getPatientVisitHistory: patientId => dispatch(getPatientVisitHistory(patientId)),
    getMedicalVisitByVisitId: (visitId) => dispatch(getMedicalVisitByVisitId(visitId)),
    getCompanyPatients: (companyId, pageSize, pageNumber, searchTerm) =>
      dispatch(getCompanyPatients(companyId, pageSize, pageNumber, searchTerm)),
    addActiveVisit: visit => dispatch(addActiveVisit(visit)),
    removeActiveVisit: visitId => dispatch(removeActiveVisit(visitId)),
    deleteVisit: (visitId) => dispatch(deleteVisit(visitId)),
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorDashboardContainer));
