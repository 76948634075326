import axios from 'axios';

const GET_MEDICAL_VISIT_BY_VISIT_ID_REQUEST = "GET_MEDICAL_VISIT_BY_VISIT_ID_REQUEST";
const GET_MEDICAL_VISIT_BY_VISIT_ID_SUCCESS = "GET_MEDICAL_VISIT_BY_VISIT_ID_SUCCESS";
const GET_MEDICAL_VISIT_BY_VISIT_ID_FAIL = "GET_MEDICAL_VISIT_BY_VISIT_ID_FAIL";

const GET_HISTORY_MEDICAL_VISIT_REQUEST = "GET_HISTORY_MEDICAL_VISIT_REQUEST";
const GET_HISTORY_MEDICAL_VISIT_SUCCESS = "GET_HISTORY_MEDICAL_VISIT_SUCCESS";
const GET_HISTORY_MEDICAL_VISIT_FAIL = "GET_HISTORY_MEDICAL_VISIT_FAIL";

const GET_PATIENT_MEDICAL_VISITS_REQUEST = "GET_PATIENT_MEDICAL_VISITS_REQUEST";
const GET_PATIENT_MEDICAL_VISITS_SUCCESS = "GET_PATIENT_MEDICAL_VISITS_SUCCESS";
const GET_PATIENT_MEDICAL_VISITS_FAIL = "GET_PATIENT_MEDICAL_VISITS_FAIL";

const ADD_MEDICAL_VISIT_REQUEST = "ADD_MEDICAL_VISIT_REQUEST";
const ADD_MEDICAL_VISIT_SUCCESS = "ADD_MEDICAL_VISIT_SUCCESS";
const ADD_MEDICAL_VISIT_FAIL = "ADD_MEDICAL_VISIT_FAIL";

const UPDATE_MEDICAL_VISIT_REQUEST = "UPDATE_MEDICAL_VISIT_REQUEST";
const UPDATE_MEDICAL_VISIT_SUCCESS = "UPDATE_MEDICAL_VISIT_SUCCESS";
const UPDATE_MEDICAL_VISIT_FAIL = "UPDATE_MEDICAL_VISIT_FAIL";


export const getMedicalVisitByVisitId = (visitId) => async dispatch => {
    dispatch(getMedicalVisitByIdRequest())
    try {
        const response = await axios.get(`/medvisit/visit/${visitId}`);
        dispatch(getMedicalVisitByIdSuccess(response.data))
        return response.data;
    } catch (error) {
        dispatch(getMedicalVisitByIdFail(error.response?.data?.message));
        if (error.response.status !== 404) {
            throw error.response?.data?.message;
        } else {
            return '404';
        }
    }
}

const getMedicalVisitByIdRequest = () => ({
    type: GET_MEDICAL_VISIT_BY_VISIT_ID_REQUEST,
});

const getMedicalVisitByIdSuccess = (medVisit) => ({
    type: GET_MEDICAL_VISIT_BY_VISIT_ID_SUCCESS,
    payload: medVisit
});

const getMedicalVisitByIdFail = (error) => ({
    type: GET_MEDICAL_VISIT_BY_VISIT_ID_FAIL,
    payload: error
});

export const getHistoryMedicalVisit = (medicalVisitId) => async dispatch => {
    dispatch(getHistoryMedicalVisitRequest())
    try {
        const response = await axios.get(`/medvisit/${medicalVisitId}`);
        dispatch(getHistoryMedicalVisitSuccess(response.data))
        return response.data;
    } catch (error) {
        dispatch(getHistoryMedicalVisitFail(error.response?.data?.message));
        if (error.response.status !== 404) {
            throw error.response?.data?.message;
        } else {
            return '404';
        }
    }
}

const getHistoryMedicalVisitRequest = () => ({
    type: GET_HISTORY_MEDICAL_VISIT_REQUEST,
});

const getHistoryMedicalVisitSuccess = (medVisit) => ({
    type: GET_HISTORY_MEDICAL_VISIT_SUCCESS,
    payload: medVisit
});

const getHistoryMedicalVisitFail = (error) => ({
    type: GET_HISTORY_MEDICAL_VISIT_FAIL,
    payload: error
});

export const getPatientMedicalVisits = (patientId) => async dispatch => {
    dispatch(getPatientMedicalVisitsRequest())
    try {
        const response = await axios.get(`/medvisit/patient/${patientId}`);
        dispatch(getPatientMedicalVisitsSuccess(response.data))
    } catch (error) {
        dispatch(getPatientMedicalVisitsFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const getPatientMedicalVisitsRequest = () => ({
    type: GET_PATIENT_MEDICAL_VISITS_REQUEST,
});

const getPatientMedicalVisitsSuccess = (medVisits) => ({
    type: GET_PATIENT_MEDICAL_VISITS_SUCCESS,
    payload: medVisits
});

const getPatientMedicalVisitsFail = (error) => ({
    type: GET_PATIENT_MEDICAL_VISITS_FAIL,
    payload: error
});

export const addMedicalVisit = (newMedicalVisit) => async dispatch => {
    dispatch(addMedicalVisitRequest())
    try {
        const response = await axios.post(`/medvisit/`, newMedicalVisit);
        dispatch(addMedicalVisitSuccess(response.data))
    } catch (error) {
        dispatch(addMedicalVisitFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const addMedicalVisitRequest = () => ({
    type: UPDATE_MEDICAL_VISIT_REQUEST,
});

const addMedicalVisitSuccess = (medVisit) => ({
    type: UPDATE_MEDICAL_VISIT_SUCCESS,
    payload: medVisit
});

const addMedicalVisitFail = (error) => ({
    type: UPDATE_MEDICAL_VISIT_FAIL,
    payload: error
});

export const updateMedicalVisit = (medicalVisitId, body, patientId, target) => async dispatch => {
    dispatch(updateMedicalVisitRequest())
    try {
        let response;
        if (target && patientId) {
            response = await axios.patch(`/medvisit/${medicalVisitId}/patient/${patientId}/${target}`, body);
        } else {
            response = await axios.patch(`/medvisit/${medicalVisitId}`, body);
        }
        dispatch(updateMedicalVisitSuccess(response.data))
    } catch (error) {
        dispatch(updateMedicalVisitFail(error.response?.data?.message));
        throw error.response?.data?.message;
    }
}

const updateMedicalVisitRequest = () => ({
    type: UPDATE_MEDICAL_VISIT_REQUEST,
});

const updateMedicalVisitSuccess = (medVisit) => ({
    type: UPDATE_MEDICAL_VISIT_SUCCESS,
    payload: medVisit
});

const updateMedicalVisitFail = (error) => ({
    type: UPDATE_MEDICAL_VISIT_FAIL,
    payload: error
});

const initialState = {
    currentMedicalVisit: {},
    historyMedicalVisit: {},
    patientMedicalVisits: [],
    loading: false,
    historyLoading: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MEDICAL_VISIT_BY_VISIT_ID_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_MEDICAL_VISIT_BY_VISIT_ID_SUCCESS:
            return {
                ...state,
                currentMedicalVisit: action.payload,
                loading: false,
            }
        case GET_MEDICAL_VISIT_BY_VISIT_ID_FAIL:
            return {
                ...state,
                currentMedicalVisit: {},
                loading: false,
            }
        case GET_HISTORY_MEDICAL_VISIT_REQUEST:
            return {
                ...state,
                historyLoading: true,
            }
        case GET_HISTORY_MEDICAL_VISIT_SUCCESS:
            return {
                ...state,
                historyMedicalVisit: action.payload,
                historyLoading: false,
            }
        case GET_HISTORY_MEDICAL_VISIT_FAIL:
            return {
                ...state,
                historyMedicalVisit: {},
                historyLoading: false,
            }
        case GET_PATIENT_MEDICAL_VISITS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_PATIENT_MEDICAL_VISITS_SUCCESS:
            return {
                ...state,
                patientMedicalVisits: action.payload,
                loading: false,
            }
        case GET_PATIENT_MEDICAL_VISITS_FAIL:
            return {
                ...state,
                currentMedicalVisit: {},
                loading: false,
            }
        case ADD_MEDICAL_VISIT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ADD_MEDICAL_VISIT_SUCCESS:
            return {
                ...state,
                currentMedicalVisit: action.payload,
                loading: false,
            }
        case ADD_MEDICAL_VISIT_FAIL:
            return {
                ...state,
                loading: false,
            }
        case UPDATE_MEDICAL_VISIT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_MEDICAL_VISIT_SUCCESS:
            return {
                ...state,
                currentMedicalVisit: action.payload,
                loading: false,
            }
        case UPDATE_MEDICAL_VISIT_FAIL:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
};