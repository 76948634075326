import React from "react";
import AddEventModal from "../Modals/AddEventModal";
import EditEventModal from "../Modals/EditEventModal";
import Modal from "../../Shared/Modals/Modal";
import WarningModal from '../Modals/WarningModal';
import AddAllDayEventModal from "../Modals/AddAllDayEventModal";
import EditAllDayEventModal from "../Modals/EditAllDayEventModal";
import RBCalendar from "../../Shared/Calendar/RBCalendar";

const ReceptionistCalendarView = ({
  setSlotStyleGetter,
  setEventStyleGetter,
  user,
  createNewEvent,
  isCreateModalOn,
  setCreateModal,
  patients,
  addPatient,
  userRole,
  addVisit,
  currentHealthcenter,
  healthcenters,
  visits,
  selectedEvent,
  updateCalendarDate,
  setCurrentView,
  isEditModalOn,
  setEditModal,
  setSelectedEvent,
  updateVisit,
  setDragModal,
  isDragModalOn,
  setCurrentHealthcenter,
  updateResourceStatus,
  resourceStatus,
  updateRoomsStatus,
  roomsStatus,
  calendarDate,
  currentView,
  calendarDoctors,
  calendarNurses,
  getCalendarLocalStorage,
  isAddAlldayEventModalOn,
  setAddAllDayModal,
  addAllDayEvent,
  allDayEvents,
  isEditAlldayEventModalOn,
  setEditAllDayModal,
  updateAllDayEventById,
  editEventDateTime,
  warningModal,
  setWarningModal,
  deleteVisit,
  getCompanyPatientsHandler,
}) => {
  return (
    <div style={{ width: '100%' }}>
      <AddEventModal
        isCreateModalOn={isCreateModalOn}
        setCreateModal={setCreateModal}
        patients={patients}
        doctors={[...calendarDoctors, ...calendarNurses]}
        addPatient={addPatient}
        companyId={userRole?.companyId}
        addVisit={addVisit}
        currentHealthcenter={currentHealthcenter}
        user={user}
        selectedEvent={selectedEvent}
        userRole={userRole}
        setWarningModal={setWarningModal}
        visits={visits}
        getCompanyPatientsHandler={getCompanyPatientsHandler}
      />
      <EditEventModal
        isEditModalOn={isEditModalOn}
        setEditModal={setEditModal}
        selectedEvent={selectedEvent}
        patients={patients}
        doctors={[...calendarDoctors, ...calendarNurses]}
        updateVisit={updateVisit}
        currentHealthcenter={currentHealthcenter}
        userRole={userRole}
        user={user}
        setWarningModal={setWarningModal}
        deleteVisit={deleteVisit}
        getCompanyPatientsHandler={getCompanyPatientsHandler}
      />
      <AddAllDayEventModal
        isAddAlldayEventModalOn={isAddAlldayEventModalOn}
        setAddAllDayModal={setAddAllDayModal}
        doctors={calendarDoctors}
        currentHealthcenter={currentHealthcenter}
        user={user}
        selectedEvent={selectedEvent}
        addAllDayEvent={addAllDayEvent}
      />
      <EditAllDayEventModal
        isEditAlldayEventModalOn={isEditAlldayEventModalOn}
        setEditAllDayModal={setEditAllDayModal}
        doctors={calendarDoctors}
        currentHealthcenter={currentHealthcenter}
        user={user}
        selectedEvent={selectedEvent}
        updateAllDayEventById={updateAllDayEventById}
      />
      <WarningModal
        show={warningModal.shown}
        question={warningModal.question}
        preventClosing={warningModal.preventClosing}
        questionTitle={warningModal.questionTitle}
        onAccept={warningModal.onAccept}
        setModalState={setWarningModal}
      />
      <Modal
        type="SimpleQuestionModal"
        show={isDragModalOn}
        questionTitle="Jesteś pewny?"
        question={selectedEvent.isAllDay ? "Czy chcesz zmienić czas wydarzenia?" : "Czy chcesz zmienić czas wizyty?"}
        acceptText="Tak"
        cancelText="Nie"
        onAccept={() => editEventDateTime()}
        onCancel={() => setDragModal(false)}
      />
      <RBCalendar
        key={patients.length + resourceStatus + currentHealthcenter}
        date={new Date(calendarDate)}
        currentHealthcenter={currentHealthcenter}
        healthcenters={healthcenters}
        setCurrentView={setCurrentView}
        setCurrentHealthcenter={setCurrentHealthcenter}
        updateResourceStatus={updateResourceStatus}
        updateRoomsStatus={updateRoomsStatus}
        roomsStatus={roomsStatus}
        resourceStatus={resourceStatus}
        getCalendarLocalStorage={getCalendarLocalStorage}
        onNavigate={date => updateCalendarDate(date)}
        setSelectedEvent={setSelectedEvent}
        setDragModal={setDragModal}
        setEditAllDayModal={setEditAllDayModal}
        setEditModal={setEditModal}
        createNewEvent={createNewEvent}
        currentView={currentView}
        userRole={userRole}
        visits={visits}
        allDayEvents={allDayEvents}
        calendarDoctors={calendarDoctors}
        setSlotStyleGetter={setSlotStyleGetter}
        setEventStyleGetter={setEventStyleGetter}
        user={user}
      />
    </div>
  );
};

export default ReceptionistCalendarView;
