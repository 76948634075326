import React from 'react';
import styled from 'styled-components';
import VisitListItem from '../../../../Shared/VisitListItem';
import Container from '../../../../Shared/Container';
import Select from '../../../../Shared/Select';
import DatePicker from '../../../../Shared/DatePicker';
import NoDataImg from '../../../../Shared/assets/no-data.svg';
import PaginationContainer from "../../../../Shared/PaginationContainer"

const VisitsTabView = ({ visitList, sortValue, setSortValue, doctors, updateVisit, visitViewValue, setVisitViewValue, healthcenters, history, user, paginationMetadata, onPatientVisitsPageChange,addActiveVisit }) => {

    const sortSelectOptions = [
        { value: 'start desc', label: 'Data - malejąco', field: 'start' },
        { value: 'start', label: 'Data - rosnąco', field: 'start' },
        { value: 'paid desc', label: 'Opłacone', field: 'paid' },
        { value: 'paid', label: 'Nieopłacone', field: 'paid' },
    ],
        viewSelectOptions = [
            { value: 'all', label: 'Wszystkie' },
            { value: 'month', label: 'Ten miesiąc' },
            { value: 'range', label: 'Przedział', range: true, rangeStart: Date.now() - 2592000000, rangeEnd: Date.now() }
        ];

    return (
        <Wrapper>
            <StyledContainer title="Lista wizyt">
                <SelectsWrapper>
                    {visitViewValue.value === 'range' && <RangeBox>
                        <DatePicker
                            hideError={true}
                            showTimeSelect
                            timeFormat="HH:mm"
                            dateFormat="dd.MM.yyyy HH:mm"
                            selected={visitViewValue.rangeStart}
                            timeCaption="Godzina"
                            startDate={visitViewValue.rangeStart}
                            endDate={visitViewValue.rangeEnd}
                            selectsStart
                            onChange={(value) => setVisitViewValue(prev => ({ value: 'range', label: 'Przedział', rangeEnd: prev.rangeEnd, rangeStart: value }))}
                        />
                        <DatePicker
                            hideError={true}
                            showTimeSelect
                            timeFormat="HH:mm"
                            dateFormat="dd.MM.yyyy HH:mm"
                            selected={visitViewValue.rangeEnd}
                            startDate={visitViewValue.rangeStart}
                            endDate={visitViewValue.rangeEnd}
                            timeCaption="Godzina"
                            selectsEnd
                            onChange={(value) => setVisitViewValue(prev => ({ value: 'range', label: 'Przedział', rangeStart: prev.rangeStart, rangeEnd: value }))}
                        />
                    </RangeBox>
                    }
                    <ViewSelect options={viewSelectOptions} value={visitViewValue} onChange={setVisitViewValue} />
                    <SortSelect options={sortSelectOptions} value={sortValue} onChange={setSortValue} />
                </SelectsWrapper>
                <PaginationContainer 
                  isVisitsPagination={true}
                  currentPage={paginationMetadata?.currentPage}
                  totalPages={paginationMetadata?.totalPages}
                  onPageChange={onPatientVisitsPageChange}
                />
                <VisitList>
                    {(visitList && visitList.length > 0 && healthcenters) ?
                        visitList.map((visitData) => {
                            const doctor = doctors.find(el => el._id === visitData.doctor) || {}
                            let newHealthcenterName
                            if (typeof (visitData.healthcenterId) === 'string') {
                                newHealthcenterName = healthcenters.find(healthcenter => healthcenter._id == visitData.healthcenterId)?.name
                            }
                            return <VisitListItem addActiveVisit={addActiveVisit} key={visitData._id} newHealthcenterName={newHealthcenterName} visitData={visitData} doctor={doctor} updateVisit={updateVisit} user={user} history={history} />
                        })
                        :
                        <NoDataContainer>
                            <StyledNoDataImg src={NoDataImg} />
                            <NoDataInfo>Brak wizyt dla tego pacjenta</NoDataInfo>
                        </NoDataContainer>
                    }
                </VisitList >
            </StyledContainer >
        </Wrapper>
    )
}

export default VisitsTabView;

const Wrapper = styled.div`
  height:100%;
`;

const StyledContainer = styled(Container)`
  position:relative;
`;

const VisitList = styled.ul`
    margin-top: 0px;
    height:calc(100vh - 420px);
    overflow:scroll;
`;

const SortSelect = styled(Select)`
  width:155px;
  z-index:8;
`;

const ViewSelect = styled(Select)`
  width:155px;
  z-index:8;
`;

const RangeBox = styled.div`
  display:flex;
  justify-content:space-between;
  width:330px;
  > div{
      width: 48%;
  }
`;

const SelectsWrapper = styled.div`
    background-color:#fff;
    position:absolute;
    right:10px;
    top:10px;
    height:42px;
    width:80%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    > div{
      margin: 0 5px;
    }
`;

const StyledNoDataImg = styled.img`
margin: 0 auto;
width: 200px;
display: block;
`

const NoDataInfo = styled.p`
text-align: center;
`
const NoDataContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
`
