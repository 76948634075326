import React, { useState } from 'react';
import ReportErrorView from './ReportErrorView';
import { connect } from "react-redux";
import { sendErrorReport } from '../../redux/modules/errorReport';

const ReportErrorContainer = ({
    sendErrorReport,
    reportError,
    reportLoading,
}) => {
    const [reportSended, setReportSended] = useState(false);

    const handleSend = (values) => {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('text', values.text);
        for (var i = 0; i < values.files.length; i++) {
            formData.append(`attachedFiles[]`, values.files[i]);
        }
        sendErrorReport(formData);
        setReportSended(true);
    }

    return (
        <ReportErrorView
            reportLoading={reportLoading}
            reportError={reportError}
            reportSended={reportSended}
            setReportSended={setReportSended}
            handleSend={handleSend}
        />
    )
}

const mapStateToProps = state => ({
    reportLoading: state.errorReport.loading,
    reportError: state.errorReport.error,
});

const mapDispatchToProps = dispatch => {
    return {
        sendErrorReport: (reportBody) => dispatch(sendErrorReport(reportBody)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportErrorContainer);