import React, { useEffect, useState } from "react";
import AdminDashboardView from "./AdminDashboardView";
import { connect } from "react-redux";
import moment from "moment";
import { getEmployees } from "../../../redux/modules/employees";
import { getHealthcenters } from "../../../redux/modules/healthcenter";
import { getVisitsByCompanyId } from "../../../redux/modules/visits";

const AdminDashboardContainer = ({
  doctors,
  registries,
  nurses,
  userRole,
  getEmployees,
  getVisitsByCompanyId,
  companyVisits,
  getHealthcenters,
  healthcenters,
}) => {
  const [statsData, setStatsData] = useState({ income: 0, averageTime: '0' }),
    [servicesData, setServicesData] = useState([]),
    [currentHealthcenter, setCurrentHealthcenter] = useState();

  const getIncome = (visits) => {
    let sum = 0;
    for (const { cost, paid } of visits) {
      if (paid) {
        sum += cost;
      }
    }
    return sum;
  },
    getAverageTime = (visits) => {
      let sum = 0;
      for (const { timeLogs } of visits) {
        const start = timeLogs.find(({ type }) => type === 'STARTED')?.time;
        const end = timeLogs.find(({ type }) => type === 'ENDED')?.time;
        if (start && end) {
          const visitLength = moment(end).diff(moment(start), "seconds");
          sum += visitLength;
        }
      };
      const average = visits.length > 0 ? sum / visits.length : 0;
      let minutes = Math.floor(average / 60);
      let seconds = Math.floor(average - minutes * 60);
      return `${minutes}m ${seconds}s`;
    },
    getServicesUsage = (visits, maxItems) => {
      let obj = {};
      for (const { name, _id } of currentHealthcenter.services) {
        obj[_id] = { count: 0, name, _id };
      }
      for (const { services } of visits) {
        for (const serviceId of services) {
          if (obj[serviceId]) {
            obj[serviceId].count += 1;
          }
        }
      }
      const arr = Object.values(obj).sort((a, b) => a.count > b.count ? -1 : 1).filter(({ count }, index) => (count !== 0 && index < maxItems));
      return arr;
    };

  useEffect(() => {
    if (userRole) {
      if (doctors.length === 0) {
        getEmployees('doctor', userRole.companyId);
      }
      if (registries.length === 0) {
        getEmployees('registry', userRole.companyId);
      }
      if (nurses.length === 0) {
        getEmployees('nurse', userRole.companyId);
      }
      if (healthcenters.length === 0) {
        getHealthcenters(userRole.companyId);
      }
      getVisitsByCompanyId(
        userRole.companyId,
        moment().date(1).hour(0).minute(0).second(0)._d,
        moment().date(moment().daysInMonth()).hour(23).minute(59).second(59)._d,
      );
    }
  }, [userRole]);

  useEffect(() => {
    if (companyVisits) {
      const endedVisits = companyVisits.filter(({ state }) => state === 'ENDED');
      const income = getIncome(endedVisits);
      const averageTime = getAverageTime(endedVisits);

      setStatsData({ income, averageTime })
    }
  }, [companyVisits]);

  useEffect(() => {
    if (healthcenters.length > 0) {
      setCurrentHealthcenter(healthcenters[0]);
    }
  }, [healthcenters]);

  useEffect(() => {
    if (currentHealthcenter?._id && companyVisits) {
      const endedVisits = companyVisits.filter(({ state }) => state === 'ENDED');
      setServicesData(getServicesUsage(endedVisits, 4));
    }
  }, [currentHealthcenter, companyVisits]);

  return <AdminDashboardView
    doctors={doctors}
    registries={registries}
    nurses={nurses}
    companyVisits={companyVisits}
    statsData={statsData}
    healthcenters={healthcenters}
    currentHealthcenter={currentHealthcenter}
    setCurrentHealthcenter={setCurrentHealthcenter}
    servicesData={servicesData}
  />;
};

const mapStateToProps = state => ({
  doctors: state.employees.doctors,
  registries: state.employees.registries,
  nurses: state.employees.nurses,
  userRole: state.authentication.userRole,
  companyVisits: state.visits.companyVisits,
  healthcenters: state.healthcenter.data,
});


const mapDispatchToProps = dispatch => {
  return {
    getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
    getVisitsByCompanyId: (companyId, start, end) => dispatch(getVisitsByCompanyId(companyId, start, end)),
    getHealthcenters: (companyId) => dispatch(getHealthcenters(companyId)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardContainer);