import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import SimpleInfoModal from "./SimpleInfoModal";
import SimpleQuestionModal from "./SimpleQuestionModal";
import Draggable from 'react-draggable';

const Modal = ({
  show,
  exitButton,
  type,
  info,
  questionTitle,
  question,
  acceptText,
  onAccept,
  cancelText,
  onCancel,
  title,
  children,
  reverseButtonColors,
  className,
  testId,
  disableCancelBtn,
  draggable = true,
}) => {
  const [isShown, setShown] = useState(show);
  const [isBackgroundLoaded, setBackgroundLoaded] = useState(show);
  const [isContentLoaded, setContentLoaded] = useState(show);

  useEffect(() => {
    if (show === true && !isShown) {
      setShown(show);
      setTimeout(() => {
        setBackgroundLoaded(show);
        setContentLoaded(show)
      }, 100);
    } else if (isShown) {
      setBackgroundLoaded(show);
      setContentLoaded(show);
      setTimeout(() => {
        setShown(show);
      }, 200);
    }
  }, [show]);

  return isShown
    ? ReactDOM.createPortal(
      <ModalBackground isBackgroundLoaded={isBackgroundLoaded}>
        <Draggable
          handle='.drag-handle'
          bounds="parent"
        >
          <ModalContainer
            title={title}
            isContentLoaded={isContentLoaded}
            className={className}
            data-testid={testId}
          >
            {draggable && <DragTopBar className='drag-handle' />}
            {title && <Title>{title}</Title>}
            {exitButton && (
              <ExitButton
                disabled={disableCancelBtn}
                onClick={() => {
                  onCancel(show);
                  setShown(show);
                }}
              />
            )}
            {type === "SimpleInfoModal" ? (
              <SimpleInfoModal
                info={info}
                acceptText={acceptText}
                onAccept={onAccept}
              />
            ) : type === "SimpleQuestionModal" ? (
              <SimpleQuestionModal
                reverseButtonColors={reverseButtonColors}
                questionTitle={questionTitle}
                question={question}
                acceptText={acceptText}
                cancelText={cancelText}
                onAccept={onAccept}
                onCancel={onCancel}
              />
            ) : (
                  children
                )}
          </ModalContainer>
        </Draggable>
      </ModalBackground >,
      document.getElementById("modal-root")
    )
    : null;
};

export default Modal;

const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.0);
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content:center;
  align-items:center;
  left: 0;
  top: 0;
  z-index: 999;
  transition: background-color 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  ${({ isBackgroundLoaded }) => isBackgroundLoaded && `
  background-color: rgba(0, 0, 0, 0.1);
  `}
`;

const ModalContainer = styled.div`
  padding: 2rem;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.grey};
  border-radius: 8px;
  box-shadow: rgba(0,0,0,0.12) 0px 2px 16px;
  max-height: 90%;
  opacity: 0;
  ${({ title }) => title && `
  padding: 3rem 2rem 2rem;
  ` }
  ${({ isContentLoaded }) => isContentLoaded && `
  opacity: 1;
  `}
  &.react-draggable-dragging .drag-handle{
    cursor: grabbing;
  }
`;

const ExitButton = styled.button`
  border: none;
  background: none;
  padding: 14px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index:10;
  transition: 0.25s;
  &::before,
  &::after {
    content: "";
    height: 20px;
    width: 2px;
    background-color: ${({ theme }) => theme.black};
    position: absolute;
    top: 50%;
    border-radius: 5px;
  }
  ${({ disabled }) => disabled && `
    opacity:.1;
    pointer-events:none;
  `}
  &::before {
    transform: translateY(-50%) rotate(45deg);
  }
  &::after {
    transform: translateY(-50%) rotate(-45deg);
  }
  &:hover {
    ::before, ::after {
      background-color:  ${({ theme }) => theme.lightBlack};
    }
    }
`;

const Title = styled.p`
  font-weight: 700;
  position: absolute;
  top: 10px;
  left: 13px;
  font-size: 18px;
`;

const DragTopBar = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:50px;
  cursor: grab;
  z-index:9;
`;