import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import arrowDown from './assets/arrow-down.svg';

const useOutsideAlerter = (ref, cb) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                cb();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const Dropdown = ({ label, children, headerComponent = false, className, width = '195px', closeOnClick = false }) => {
    const [collapsed, setCollapsed] = useState(true);
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, () => setCollapsed(true));
    return (
        <DropdownWrapper ref={wrapperRef} className={className} width={width}>
            {!headerComponent && <DropdownHeader onClick={() => setCollapsed(prev => !prev)}>{label}</DropdownHeader>}
            {headerComponent && headerComponent(() => setCollapsed(prev => !prev))}
            {!collapsed && <DropdownBody onClick={() => closeOnClick && setCollapsed(true)}>
                {children}
            </DropdownBody>}
        </DropdownWrapper>
    )
}

export default Dropdown;

const DropdownWrapper = styled.div`
    position:relative;
    min-width:${({ width }) => width};
`;

const IconWrapper = styled.div`
    width:100%;
    display:flex;
    width:20px;
    margin-left: auto;
`;

const IconHeader = styled.img`
    position:relative;
    width:20px;
    height:30px;
    cursor:pointer;
`;

const DropdownHeader = styled.div`
    position:relative;
    transition: all 100ms;
    box-sizing: border-box;
    border: 1px solid #ccc;
    background: #fff;
    font-size: 14px;
    margin: 0 auto 2px;
    border-radius: 4px;
    cursor:pointer;
    padding: 9.5px;
    background-image:url(${arrowDown});
    background-position: right 10px center;
    background-repeat: no-repeat;
    width:100%;
    user-select:none;
`;

const DropdownBody = styled.div`
    background-color:#fff;
    position:absolute;
    top:100%;
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index:100;
    width:100%;
`;