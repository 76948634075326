import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Select from '../../../Shared/Select';
import DatePicker from '../../../Shared/DatePicker';
import Button from '../../../Shared/Button';
import useDebouncer from "../../../Shared/Hooks/useDebouncer";
import moment from 'moment';

const MedsDiagnosisModal = ({
    modalState: { shown: modalShown, target: modalTarget },
    setModalState,
    getMedicalData,
    diagnosis,
    meds,
    patientMedsDiagnosis: { constantDiagnosis, constantMeds, otherMeds, otherDiagnosis },
    updatePatientMedsDiagnosisHandler,
    medsdiagnosisLoading,
    nextTutorialStep
}) => {
    const [listItemsConstant, setListItemsConstant] = useState([]),
        [listItemsOthers, setListItemsOthers] = useState([]),
        [selectMedsOptions, setSelectMedsOptions] = useState([]),
        [constantsInputValue, setConstantsInputValue] = useState(''),
        [othersInputValue, setOthersInputValue] = useState(''),
        [setDebounce] = useDebouncer();

    const addItemHandler = (val, targetArr) => {
        if (modalTarget === 'medicalProducts') {
            const { data: { nazwaProduktu, nazwaPowszechnieStosowana, substancjeCzynne, moc, jednostkaWielkosci, wielkosc, kategoriaDostepnosci, podmiotOdpowiedzialny, dataWystawieniaRecepty, } } = val;
            const newItem = {
                nazwaProduktu,
                nazwaPowszechnieStosowana,
                substancjeCzynne,
                moc,
                jednostkaWielkosci,
                wielkosc,
                kategoriaDostepnosci,
                podmiotOdpowiedzialny,
                dataWystawieniaRecepty,
            }
            if (targetArr === 'constant') {
                setListItemsConstant(prev => ([...prev, newItem]))
            } else if (targetArr === 'others') {
                setListItemsOthers(prev => ([...prev, newItem]))
            }
        } else {
            if (targetArr === 'constant') {
                setListItemsConstant(prev => ([...prev, { ...val }]))
            } else if (targetArr === 'others') {
                setListItemsOthers(prev => ([...prev, { ...val }]))
            }
        }
    },
        changeDateHandler = (value, targetId, target, targetArray = 'others') => {
            if (targetArray === 'others') {
                setListItemsOthers(prev => (
                    prev.map(el => {
                        if (el._id === targetId) {
                            if (modalTarget === 'medicalProducts') {
                                if (target === 'start') {
                                    el.dataWystawieniaRecepty = value;
                                } else if (target === 'end') {
                                    el.dataKoncaPrzyjmowania = value;
                                }
                            } else {
                                el.diagnosisDate = value;
                            }
                            return el;
                        } else {
                            return el;
                        }
                    })
                ))
            }
            else if (targetArray === 'constants') {
                setListItemsConstant(prev => (
                    prev.map(el => {
                        if (el._id === targetId) {
                            el.dataRozpoczeciaPrzyjmowania = value;
                            return el;
                        } else {
                            return el;
                        }
                    })
                ))
            }
        },
        removeItemHandler = (targetArr, targetItemIndex) => {
            if (targetArr === 'constant') {
                setListItemsConstant(prev => prev.filter((el, index) => index !== targetItemIndex))
            } else if (targetArr === 'others') {
                setListItemsOthers(prev => prev.filter((el, index) => index !== targetItemIndex))
            }
        },
        resetModalHandler = () => {
            setListItemsConstant([]);
            setListItemsOthers([]);
        },
        submitHandler = () => {
            let newMedsDiagnosis;
            if (modalTarget === 'medicalProducts') {
                newMedsDiagnosis = {
                    constantMeds: listItemsConstant,
                    otherMeds: listItemsOthers,
                }
            } else {
                newMedsDiagnosis = {
                    constantDiagnosis: listItemsConstant,
                    otherDiagnosis: listItemsOthers,
                }
            }
            updatePatientMedsDiagnosisHandler(newMedsDiagnosis);
            setModalState({ shown: false, target: null, });
            resetModalHandler();
            if (nextTutorialStep) {
                nextTutorialStep();
            }
        }

    useEffect(() => {
        if (modalTarget === 'medicalProducts' && constantMeds && otherMeds) {
            setListItemsConstant(constantMeds)
            setListItemsOthers(otherMeds)
        }
        else if (modalTarget === 'icd10s' && constantDiagnosis && otherDiagnosis) {
            setListItemsConstant(constantDiagnosis)
            setListItemsOthers(otherDiagnosis)
        }
    }, [modalTarget]);

    useEffect(() => {
        setSelectMedsOptions(meds.map(el => ({
            label: `${el.nazwaProduktu}, ${el.moc}`,
            options: el.opakowania.map(({ _attributes }) => (
                {
                    label: `${_attributes.wielkosc} ${_attributes.jednostkaWielkosci}`,
                    value: _attributes.id,
                    data: { ...el, jednostkaWielkosci: _attributes.jednostkaWielkosci, wielkosc: _attributes.wielkosc, kategoriaDostepnosci: _attributes.kategoriaDostepnosci },
                }
            )),
        })))
    }, [meds])

    const diagnosisFormatOptionLabel = ({ category, subcategory, subcategoryCode, label }) => (
        <Option>
            <OptionPlaceholder>{label}</OptionPlaceholder>
            <OptionCode>{subcategoryCode}</OptionCode>
            <OptionLabel>{category} - {subcategory}</OptionLabel>
        </Option>
    );

    useEffect(() => {
        getMedicalDataHandler(constantsInputValue);
    }, [constantsInputValue]);

    useEffect(() => {
        getMedicalDataHandler(othersInputValue);
    }, [othersInputValue]);

    const getMedicalDataHandler = (val = "") => {
        if (modalTarget) {
            setDebounce(() => {
                getMedicalData(modalTarget, { value: val })
            })
        }
    }
    return (
        <Modal
            title={modalTarget === 'medicalProducts' ? 'Edycja leków' : 'Edycja rozpoznań'}
            exitButton={true}
            onCancel={() => {
                setModalState({ shown: false, target: null, });
                resetModalHandler();
                if (nextTutorialStep) {
                    nextTutorialStep();
                }
            }}
            show={modalShown}
        >
            <Lists>
                <SearchSelect
                    value={{ label: modalTarget === 'medicalProducts' ? 'Dodaj lek z listy' : 'Dodaj rozpoznanie z listy' }}
                    inputValue={constantsInputValue}
                    onChange={(val) => { addItemHandler(val, 'constant') }}
                    onInputChange={setConstantsInputValue}
                    options={modalTarget === 'medicalProducts' ? selectMedsOptions : diagnosis}
                    isLoading={medsdiagnosisLoading}
                    name='chooseSelectConstant'
                    noOptionsMessage={() => <p>{modalTarget === 'medicalProducts' ? 'Nie znaleziono takiego leku' : 'Nie znaleziono takiego rozpoznania'}</p>}
                    searchInputWithoutFilter={true}
                    customFormatOptionLabel={modalTarget === 'icd10s' && diagnosisFormatOptionLabel}
                />
                <List>
                    <ListLabel>{modalTarget === 'medicalProducts' ? 'Stale przyjmowane' : 'Przewlekłe'}</ListLabel>
                    {listItemsConstant.length > 0 ?
                        modalTarget === 'medicalProducts' ?
                            listItemsConstant.map(({ nazwaProduktu, moc, wielkosc, jednostkaWielkosci, _id, dataRozpoczeciaPrzyjmowania }, index) => (
                                <ListItem key={`${_id}_${index}`}>
                                    <RemoveBtn onClick={() => removeItemHandler('constant', index)} />
                                    <ItemLabel flexWrap='wrap'>
                                        <Name>{`${nazwaProduktu}, ${moc}, ${wielkosc} ${jednostkaWielkosci}`}</Name>
                                        <Dates>
                                            <span>
                                                Od:
                                                <StyledDatePicker
                                                    hideError={true}
                                                    preventOverflow={true}
                                                    dateFormat="dd.MM.yyyy"
                                                    showBackgroundImage={false}
                                                    selected={dataRozpoczeciaPrzyjmowania ? new Date(dataRozpoczeciaPrzyjmowania) : Date.now()}
                                                    onChange={(value) => changeDateHandler(value, _id, 'end', 'constants')}
                                                />
                                            </span>
                                        </Dates>
                                    </ItemLabel>
                                </ListItem>
                            ))
                            :
                            listItemsConstant.map(({ subcategory, subcategoryCode, _id }, index) => (
                                <ListItem key={`${_id}_${index}`}>
                                    <ItemLabel>
                                        <span>
                                            <Code>{subcategoryCode}</Code>
                                            <Name>{subcategory}</Name>
                                        </span>
                                    </ItemLabel>
                                    <RemoveBtn onClick={() => removeItemHandler('constant', index)} />
                                </ListItem>
                            ))
                        :
                        <NoItems>{`Brak ${modalTarget === 'medicalProducts' ? 'leków' : 'rozpoznań'}, dodaj z listy powyżej`}</NoItems>
                    }
                </List>
                <SearchSelect
                    value={{ label: modalTarget === 'medicalProducts' ? 'Dodaj lek z listy' : 'Dodaj rozpoznanie z listy' }}
                    onChange={(val) => { addItemHandler(val, 'others') }}
                    inputValue={othersInputValue}
                    onInputChange={setOthersInputValue}
                    options={modalTarget === 'medicalProducts' ? selectMedsOptions : diagnosis}
                    isLoading={medsdiagnosisLoading}
                    name='chooseSelectOthers'
                    noOptionsMessage={() => <p>{modalTarget === 'medicalProducts' ? 'Nie znaleziono takiego leku' : 'Nie znaleziono takiego rozpoznania'}</p>}
                    searchInputWithoutFilter={true}
                    customFormatOptionLabel={modalTarget === 'icd10s' && diagnosisFormatOptionLabel}
                />
                <List>
                    <ListLabel>Pozostałe</ListLabel>
                    {listItemsOthers.length > 0 ?
                        modalTarget === 'medicalProducts' ?
                            listItemsOthers.map(({ nazwaProduktu, moc, wielkosc, jednostkaWielkosci, _id, dataWystawieniaRecepty, dataKoncaPrzyjmowania }, index) => (
                                <ListItem key={`${_id}_${index}`}>
                                    <ItemLabel flexWrap='wrap' >
                                        <Name>{`${nazwaProduktu}, ${moc}, ${wielkosc} ${jednostkaWielkosci}`}</Name>
                                        <Dates>
                                            <span>
                                                Od:
                                                <StyledDatePicker
                                                    hideError={true}
                                                    preventOverflow={true}
                                                    dateFormat="dd.MM.yyyy"
                                                    showBackgroundImage={false}
                                                    selected={dataWystawieniaRecepty ? new Date(dataWystawieniaRecepty) : Date.now()}
                                                    onChange={(value) => changeDateHandler(value, _id, 'start')}
                                                    startDate={dataWystawieniaRecepty ? new Date(dataWystawieniaRecepty) : Date.now()}
                                                    endDate={dataKoncaPrzyjmowania ? new Date(dataKoncaPrzyjmowania) : moment().add(14, 'days')._d}
                                                    selectsStart
                                                />
                                            </span>
                                            <span>
                                                Do:
                                                <StyledDatePicker
                                                    hideError={true}
                                                    preventOverflow={true}
                                                    dateFormat="dd.MM.yyyy"
                                                    showBackgroundImage={false}
                                                    selected={dataKoncaPrzyjmowania ? new Date(dataKoncaPrzyjmowania) : moment().add(14, 'days')._d}
                                                    onChange={(value) => changeDateHandler(value, _id, 'end')}
                                                    startDate={dataWystawieniaRecepty ? new Date(dataWystawieniaRecepty) : Date.now()}
                                                    endDate={dataKoncaPrzyjmowania ? new Date(dataKoncaPrzyjmowania) : moment().add(14, 'days')._d}
                                                    selectsEnd
                                                />
                                            </span>
                                        </Dates>
                                    </ItemLabel>
                                    <RemoveBtn onClick={() => removeItemHandler('others', index)} />
                                </ListItem>
                            ))
                            :
                            listItemsOthers.map(({ subcategory, subcategoryCode, _id, diagnosisDate }, index) => (
                                <ListItem key={`${_id}_${index}`}>
                                    <ItemLabel flexWrap='wrap'>
                                        <span>
                                            <Code>{subcategoryCode}</Code>
                                            <Name>{subcategory}</Name>
                                        </span>
                                        <Dates>
                                            <span>
                                                Od:
                                                <StyledDatePicker
                                                    hideError={true}
                                                    preventOverflow={true}
                                                    showBackgroundImage={false}
                                                    dateFormat="dd.MM.yyyy"
                                                    selected={diagnosisDate ? new Date(diagnosisDate) : Date.now()}
                                                    onChange={(value) => changeDateHandler(value, _id)}
                                                />
                                            </span>
                                        </Dates>
                                    </ItemLabel>
                                    <RemoveBtn onClick={() => removeItemHandler('others', index)} />
                                </ListItem>
                            ))
                        :
                        <NoItems>{`Brak ${modalTarget === 'medicalProducts' ? 'leków' : 'rozpoznań'}, dodaj z listy powyżej`}</NoItems>
                    }
                </List>
            </Lists>
            <CenterBox>
                <Button onClick={submitHandler} type='submit' variant='primary'>Zapisz zmiany</Button>
            </CenterBox>
        </Modal >
    )
}

export default MedsDiagnosisModal;

const SearchSelect = styled(Select)`
    margin-bottom:10px;
`;

const CenterBox = styled.div`
    margin-top:40px;
    text-align:center;
`;

const Lists = styled.div``;

const List = styled.ul`
    width:400px;
    max-height:250px;
    overflow:auto;
    margin: 10px 0;
    overflow-x:hidden;
    &:not(:last-child){
        padding-bottom:20px;
        margin-bottom:20px;
        border-bottom: 1px solid ${({ theme }) => theme.primary};
    }
`;

const ListLabel = styled.p`
    font-weight: 600;
    margin: 0 0 2px 5px;
    font-size: 14px;
`;

const ListItem = styled.li`
    position:relative;
    font-size:12px;
    padding:5px 15px 5px 5px;
    &:not(:last-child){
        border-bottom: 1px solid ${({ theme }) => theme.grey};
    }
`;

const Option = styled.div`
    display:flex;
`;

const OptionCode = styled.span`
    font-weight:600;
    padding-right:5px;
`;

const OptionLabel = styled.span`
   font-weight:normal;
`;

const OptionPlaceholder = styled.span`
   color:#ccc;
`;

const NoItems = styled.p`
    text-align:center;
`;

const ItemLabel = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    ${({ flexWrap }) => flexWrap && `
        flex-wrap:wrap;
    `}
`;

const Code = styled.span`
    font-size:12px;
    font-weight:600;
    margin-right:5px;
`;

const Name = styled.span`
    font-size:12px;
    padding-right:10px;
`;

const RemoveBtn = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:2px;
  &::before,
  &::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const StyledDatePicker = styled(DatePicker)`
    width:auto;
    & input{
        border: none;
        min-height: 0;
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        width: 70px;
        cursor: pointer;
        &:focus{
            padding:0;
        }
    }
`;

const Dates = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    span{
        display:flex;
        align-items:center;
    }
    input{
        margin-left: 5px;
    }
`;