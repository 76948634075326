import axios from 'axios';
import { updateVisitsFromPatient } from './visits';
import { ADD_PATIENT_FROM_SOCKET, UPDATE_PATIENT_FROM_SOCKET, DELETE_PATIENT_FROM_SOCKET } from '../../sockets/patients/reduxActions';

const GET_COMPANY_PATIENTS_REQUEST = "GET_COMPANY_PATIENTS_REQUEST";
const GET_COMPANY_PATIENTS_SUCCESS = "GET_COMPANY_PATIENTS_SUCCESS";
const GET_COMPANY_PATIENTS_FAIL = "GET_COMPANY_PATIENTS_FAIL";

const ADD_PATIENT_REQUEST = "ADD_PATIENT_REQUEST";
const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS";
const ADD_PATIENT_FAIL = "ADD_PATIENT_FAIL";

const UPDATE_PATIENT_REQUEST = "UPDATE_PATIENT_REQUEST";
const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL";

const DELETE_PATIENT_REQUEST = "DELETE_PATIENT_REQUEST";
const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";
const DELETE_PATIENT_FAIL = "DELETE_PATIENT_FAIL";

const SET_CURRENT_PATIENT = "SET_CURRENT_PATIENT";
const SET_INITIAL_PATIENT = "SET_INITIAL_PATIENT";

const SET_CURRENT_VISIT_PATIENT = "SET_CURRENT_VISIT_PATIENT";
const GET_PREVIOUS_PATIENT_MEDICAL_VISIT_FAIL = "GET_PREVIOUS_PATIENT_MEDICAL_VISIT_FAIL"
const GET_PREVIOUS_PATIENT_MEDICAL_VISIT_SUCCESS = "GET_PREVIOUS_PATIENT_MEDICAL_VISIT_SUCCESS"
const GET_PREVIOUS_PATIENT_MEDICAL_VISIT_REQUEST = "GET_PREVIOUS_PATIENT_MEDICAL_VISIT_REQUEST"

const GET_PREVIOUS_PATIENT_VISIT_FAIL = "GET_PREVIOUS_PATIENT_VISIT_FAIL"
const GET_PREVIOUS_PATIENT_VISIT_SUCCESS = "GET_PREVIOUS_PATIENT_VISIT_SUCCESS"
const GET_PREVIOUS_PATIENT_VISIT_REQUEST = "GET_PREVIOUS_PATIENT_VISIT_REQUEST"

const GET_PATIENT_BY_ID_REQUEST = "GET_PATIENT_BY_ID_REQUEST"
const GET_PATIENT_BY_ID_SUCCESS = "GET_PATIENT_BY_ID_SUCCESS"
const GET_PATIENT_BY_ID_AND_SET_CURRENT_SUCCESS = "GET_PATIENT_BY_ID_AND_SET_CURRENT_SUCCESS"
const GET_PATIENT_BY_ID_FAIL = "GET_PATIENT_BY_ID_FAIL"

const SET_PATIENT_AVATAR_REQUEST = "SET_PATIENT_AVATAR_REQUEST";
const SET_PATIENT_AVATAR_SUCCESS = "SET_PATIENT_AVATAR_SUCCESS";
const SET_PATIENT_AVATAR_FAIL = "SET_PATIENT_AVATAR_FAIL";

const GET_PATIENT_VISIT_HISTORY_REQUEST = "GET_PATIENT_VISIT_HISTORY_REQUEST"
const GET_PATIENT_VISIT_HISTORY_SUCCESS = "GET_PATIENT_VISIT_HISTORY_SUCCESS"
const GET_PATIENT_VISIT_HISTORY_FAIL = "GET_PATIENT_VISIT_HISTORY_FAIL"

const MERGE_WITH_DELETED_PATIENT_REQUEST = "MERGE_WITH_DELETED_PATIENT_REQUEST"
const MERGE_WITH_DELETED_PATIENT_SUCCESS = "MERGE_WITH_DELETED_PATIENT_SUCCESS"
const MERGE_WITH_DELETED_PATIENT_FAIL = "MERGE_WITH_DELETED_PATIENT_FAIL"

export const setPatientAvatar = (patientId, formData) => async dispatch => {
  dispatch(setPatientAvatarRequest())
  try {
    const response = await axios.post(`/patient/${patientId}/avatar`, formData);
    dispatch(setPatientAvatarSuccess(response.data))
  } catch (error) {
    dispatch(setPatientAvatarFail(error))
  }
}

const setPatientAvatarRequest = () => ({
  type: SET_PATIENT_AVATAR_REQUEST
});

const setPatientAvatarSuccess = user => ({
  type: SET_PATIENT_AVATAR_SUCCESS,
  payload: user
});

const setPatientAvatarFail = (error) => ({
  type: SET_PATIENT_AVATAR_FAIL,
  payload: error
});


export const setCurrentVisitPatient = (patient) => ({
  type: SET_CURRENT_VISIT_PATIENT,
  payload: patient ? patient : {}
})

export const setInitialPatient = () => ({
  type: SET_INITIAL_PATIENT
})

export const getPatients = () => async dispatch => {

}

export const deletePatient = (patientId) => async dispatch => {
  dispatch(deletePatientRequest());
  try {
    const response = await axios.delete(`/patient/${patientId}`);
    dispatch(deletePatientSuccess(patientId))
  } catch (error) {
    dispatch(deletePatientFail(error))
  }
}

const deletePatientRequest = () => ({
  type: DELETE_PATIENT_REQUEST
})

const deletePatientSuccess = (patientId) => ({
  type: DELETE_PATIENT_SUCCESS,
  payload: patientId
})

const deletePatientFail = (error) => ({
  type: DELETE_PATIENT_FAIL,
  payload: error
})

export const updatePatientWithVisits = (patientNewData) => async dispatch => {
  dispatch(updatePatientRequest());
  try {
    const response = await axios.patch(`/patient/${patientNewData._id}`, patientNewData, {
      validateStatus: function (status) {
        return status <= 300;
      }
    });
    if (response.data?.updatedVisits?.length > 0) {
      dispatch(updateVisitsFromPatient(response.data.updatedVisits))
    }
    if (response.status === 300 || response.status === 403) {
      dispatch(updatePatientFail(response.data.message))
      return response;
    }
    dispatch(updatePatientSuccess(response.data));
  } catch (error) {
    if (error?.response?.status === 403 || error?.response?.status === 409) {
      return error.response;
    }
    dispatch(updatePatientFail(error))
  }
}

export const updatePatient = (patientNewData) => async dispatch => {
  dispatch(updatePatientRequest());
  try {
    const response = await axios.patch(`/patient/${patientNewData._id}`, patientNewData, {
      validateStatus: function (status) {
        return status <= 300;
      }
    })
    if (response.status === 300) {
      dispatch(updatePatientFail(response.data.message))
      return response;
    }
    dispatch(updatePatientSuccess(response.data))
  } catch (error) {
    if (error?.response?.status === 403 || error?.response?.status === 409) {
      return error.response;
    }
    dispatch(updatePatientFail(error))
  }
}

const updatePatientRequest = () => ({
  type: UPDATE_PATIENT_REQUEST
})

const updatePatientSuccess = (patient) => ({
  type: UPDATE_PATIENT_SUCCESS,
  payload: patient
})

const updatePatientFail = (error) => ({
  type: UPDATE_PATIENT_FAIL,
  payload: error
})

export const addPatient = (companyId, patient) => async dispatch => {
  dispatch(addPatientRequest())
  try {
    const requestBody = { ...patient, companyId };
    const response = await axios.post(`/patient/`, requestBody, {
      validateStatus: function (status) {
        return status <= 300;
      }
    });
    if (response.status === 300) {
      return response;
    }
    dispatch(addPatientSuccess(response.data))
    return response.data
  } catch (error) {
    dispatch(addPatientFail(error))
    if(error?.response?.status === 409){
      return error.response;
    }
  }
}

const addPatientRequest = () => ({
  type: ADD_PATIENT_REQUEST
})

const addPatientSuccess = (patient) => ({
  type: ADD_PATIENT_SUCCESS,
  payload: patient
})

const addPatientFail = (error) => ({
  type: ADD_PATIENT_FAIL,
  payload: error
})

export const getCompanyPatients = (companyId, pageSize = 20, pageNumber, searchTerm = "") => async dispatch => {
  dispatch(getCompanyPatientsRequest());
  try {
    const response = await axios.get(`/patient/company/${companyId}?pageSize=${pageSize}&pageNumber=${pageNumber}&searchTerm=${searchTerm}`)
    let paginationMetadata
    if (response?.headers?.pagination) {
      paginationMetadata = JSON.parse(response.headers.pagination)
    }
    dispatch(getCompanyPatientsSuccess(response.data, paginationMetadata))
  } catch (error) {
    dispatch(getCompanyPatientsFail(error))
  }
}

const getCompanyPatientsRequest = () => ({
  type: GET_COMPANY_PATIENTS_REQUEST
})

const getCompanyPatientsSuccess = (patients, paginationMetadata) => ({
  type: GET_COMPANY_PATIENTS_SUCCESS,
  payload: patients,
  paginationMetadata: paginationMetadata,
})

const getCompanyPatientsFail = (error) => ({
  type: GET_COMPANY_PATIENTS_FAIL,
  payload: error
})

export const getPatientVisitHistory = (patientId) => async dispatch => {
  dispatch(getPatientVisitHistoryRequest());
  try {
    const response = await axios.post(`/visit/patient`,
      {
        patientId,
        dateRangeBegin: new Date(1, 1, 1, 1, 0, 0).toISOString(),
        dateRangeEnd: new Date(9999, 1, 1, 1, 0, 0).toISOString()
      });
    const { data } = response;
    dispatch(getPatientVisitHistorySuccess(data));
  } catch (error) {
    dispatch(getPatientVisitHistoryFail(error.response?.data?.message));
    throw error.response?.data?.message;
  }
}

const getPatientVisitHistoryRequest = () => ({
  type: GET_PATIENT_VISIT_HISTORY_REQUEST
})

const getPatientVisitHistorySuccess = (visits) => ({
  type: GET_PATIENT_VISIT_HISTORY_SUCCESS,
  payload: visits
})

const getPatientVisitHistoryFail = (err) => ({
  type: GET_PATIENT_VISIT_HISTORY_FAIL,
  payload: err
})

export const getPreviousPatientVisit = (patientId) => async dispatch => {
  dispatch(getPreviousPatientVisitRequest())
  try {
    const response = await axios.get(`/visit/patient/end/${patientId}`)
    dispatch(getPreviousPatientVisitSuccess(response.data))
  } catch (error) {
    dispatch(getPreviousPatientVisitFail(error))
  }
}
const getPreviousPatientVisitRequest = () => ({
  type: GET_PREVIOUS_PATIENT_VISIT_REQUEST
})

const getPreviousPatientVisitSuccess = (visit) => ({
  type: GET_PREVIOUS_PATIENT_VISIT_SUCCESS,
  payload: visit
})

const getPreviousPatientVisitFail = (error) => ({
  type: GET_PREVIOUS_PATIENT_VISIT_FAIL,
  payload: error
})


export const getMedicalVisitByVisitId = (visitId) => async dispatch => {
  if (visitId !== undefined) {
    dispatch(getMedicalVisitByVisitIdRequest())
    try {
      const response = await axios.get(`/medvisit/visit/${visitId}`)
      dispatch(getMedicalVisitByVisitIdSuccess(response.data))
    } catch (error) {
      dispatch(getMedicalVisitByVisitIdFail(error))
    }
  } else {
    dispatch(getMedicalVisitByVisitIdSuccess())
  }

};

const getMedicalVisitByVisitIdRequest = () => ({
  type: GET_PREVIOUS_PATIENT_MEDICAL_VISIT_REQUEST
})

const getMedicalVisitByVisitIdSuccess = (medicalvisit) => ({
  type: GET_PREVIOUS_PATIENT_MEDICAL_VISIT_SUCCESS,
  payload: medicalvisit
})

const getMedicalVisitByVisitIdFail = (error) => ({
  type: GET_PREVIOUS_PATIENT_MEDICAL_VISIT_FAIL,
  payload: error
})

export const getPatientById = (patientId, setAsCurrent) => async dispatch => {
  dispatch(getPatientByIdRequest())
  try {
    const response = await axios.get(`/patient/${patientId}`)
    dispatch(getPatientByIdSuccess(response.data, setAsCurrent))
  } catch (error) {
    dispatch(getPatientByIdFail(error))
  }
};

const getPatientByIdRequest = () => ({
  type: GET_PATIENT_BY_ID_REQUEST
})

const getPatientByIdSuccess = (patient, setAsCurrent) => ({
  type: setAsCurrent ? GET_PATIENT_BY_ID_AND_SET_CURRENT_SUCCESS : GET_PATIENT_BY_ID_SUCCESS,
  payload: patient
})

const getPatientByIdFail = (error) => ({
  type: GET_PATIENT_BY_ID_FAIL,
  payload: error
})

export const mergeWithDeletedPatient = (patientId, body) => async dispatch => {
  dispatch(mergeWithDeletedPatientRequest())
  try {
    const response = await axios.post(`/patient/merge/${patientId}`, { body })
    dispatch(mergeWithDeletedPatientSuccess(response.data))
  } catch (error) {
    dispatch(mergeWithDeletedPatientFail(error))
  }
}

const mergeWithDeletedPatientRequest = () => ({
  type: MERGE_WITH_DELETED_PATIENT_REQUEST
})

const mergeWithDeletedPatientSuccess = (patient) => ({
  type: MERGE_WITH_DELETED_PATIENT_SUCCESS,
  payload: patient
})

const mergeWithDeletedPatientFail = (error) => ({
  type: MERGE_WITH_DELETED_PATIENT_FAIL,
  payload: error
})

export const setCurrentPatient = (patientId) => ({
  type: SET_CURRENT_PATIENT,
  payload: patientId
})

const initialState = {
  data: [],
  currentPatient: {
    address: {}
  },
  loading: false,
  error: null,
  currentPatientPreviousVisit: null,
  currentPatientPreviousMedicalVisit: null,
  currentPatientVisitsHistory: null,
  paginationMetadata: null
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_VISIT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_PATIENT_VISIT_HISTORY_SUCCESS:
      return {
        ...state,
        currentPatientVisitsHistory: action.payload,
        loading: false
      }
    case GET_PATIENT_VISIT_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case GET_COMPANY_PATIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_COMPANY_PATIENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paginationMetadata: action.paginationMetadata,
        currentPatient: !state.currentPatient?._id ? action.payload.length > 0 ? action.payload[0] : action.payload : state.currentPatient,
        loading: false
      }
    case GET_COMPANY_PATIENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case ADD_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_PATIENT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      }
    case ADD_PATIENT_FROM_SOCKET:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      }
    case UPDATE_PATIENT_FROM_SOCKET:
      return {
        ...state,
        data: state.data.map(el => el._id === action.payload._id ? action.payload : el),
        currentPatient: state.currentPatient._id === action.payload._id ? action.payload : state.currentPatient,
        loading: false
      }
    case ADD_PATIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case UPDATE_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_PATIENT_SUCCESS:
      if (state.data.findIndex(el => el._id === action.payload._id) === -1 && action.payload._id) {
        return {
          ...state,
          data: [...state.data, action.payload],
          loading: false
        }
      } else {
        return {
          ...state,
          data: state.data.filter(el => { if (action.payload.deleted && action.payload._id === el._id) { return false; } return true; }).map(el => el._id === action.payload._id ? action.payload : el),
          currentPatient: action.payload.deleted ? state.data[0] ?? {} : action.payload,
          loading: false
        }
      }
    case UPDATE_PATIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case DELETE_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case DELETE_PATIENT_SUCCESS: {
      return {
        ...state,
        data: state.data.filter(el => el._id !== action.payload),
        currentPatient: state.data.length > 1 ? state.data[0] : [],
        loading: false
      }
    }
    case DELETE_PATIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SET_CURRENT_PATIENT: {
      let targetIndex = state.data.findIndex((el) => el._id === action.payload);
      if (targetIndex === -1) {
        targetIndex = 0;
      }
      if (state.data.length > 0) {
        return {
          ...state,
          currentPatient: state.data[targetIndex]
        }
      } else {
        return {
          ...state
        }
      }
    }
    case SET_INITIAL_PATIENT:
      return {
        data: [],
        currentPatient: {
          address: {}
        },
        loading: false,
        error: null
      }
    case SET_CURRENT_VISIT_PATIENT:
      return {
        ...state,
        currentPatient: action.payload
      }
    case GET_PREVIOUS_PATIENT_MEDICAL_VISIT_REQUEST:
      return {
        ...state,
        loading: false
      }
    case GET_PREVIOUS_PATIENT_MEDICAL_VISIT_SUCCESS:
      return {
        ...state,
        currentPatientPreviousMedicalVisit: action.payload,
        loading: false
      }
    case GET_PREVIOUS_PATIENT_MEDICAL_VISIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        currentPatientPreviousMedicalVisit: ''
      }
    case GET_PREVIOUS_PATIENT_VISIT_REQUEST:
      return {
        ...state,
        loading: false
      }
    case GET_PREVIOUS_PATIENT_VISIT_SUCCESS:
      return {
        ...state,
        currentPatientPreviousVisit: action.payload,
        loading: false
      }
    case GET_PREVIOUS_PATIENT_VISIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case GET_PATIENT_BY_ID_REQUEST:
      return {
        ...state,
        loading: false
      }
    case GET_PATIENT_BY_ID_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      }
    case GET_PATIENT_BY_ID_AND_SET_CURRENT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        currentPatient: action.payload,
        loading: false
      }
    case GET_PATIENT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SET_PATIENT_AVATAR_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case SET_PATIENT_AVATAR_SUCCESS:
      return {
        ...state,
        currentPatient: action.payload,
        data: state.data.map(el => el._id === action.payload._id ? action.payload : el),
        loading: false
      };
    case SET_PATIENT_AVATAR_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case MERGE_WITH_DELETED_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case MERGE_WITH_DELETED_PATIENT_SUCCESS:
      return {
        ...state,
        data: state.data.map(el => el._id === action.payload._id ? action.payload : el),
        loading: false
      }
    case MERGE_WITH_DELETED_PATIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }

};