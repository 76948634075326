import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import VacationsView from './VacationsView';
import { getEmployees, deleteUserVacations } from '../../redux/modules/employees';
import { getUsersVacationsByCompanyId, addVacation, updateVacation, acceptVacation, deleteVacation, resetVacationsError } from '../../redux/modules/vacations';
import moment from 'moment';

const VacationsContainer = ({
    userRole,
    doctors,
    registries,
    nurses,
    getEmployees,
    usersVacations,
    getUsersVacationsByCompanyId,
    addVacation,
    updateVacation,
    deleteVacation,
    vacationsError,
    resetVacationsError,
    acceptVacation,
}) => {
    const [vacationModal, setVacationModal] = useState({ shown: false, editId: null }),
        [questionModal, setQuestionModal] = useState({ shown: false, data: null, question: '', onAccept: null }),
        [currentCalendarDates, setCurrentCalendarDates] = useState({ start: null, end: null }),
        [currentDate, setCurrentDate] = useState(Date.now()),
        [errorModal, setErrorModal] = useState({ shown: false, info: '' }),
        [events, setEvents] = useState([]),
        [userFilters, setUsersFilters] = useState({ doctors: [], registries: [], nurses: [] });

    const mapVacationsToEvents = (vacationsArray) => {
        const newEvents = [];
        vacationsArray.map(user => (
            user.vacations.forEach(vac => {
                let color = '#1890FF';
                if (user.activeRole === 'Doctor' || user.activeRole === 'Nurse') {
                    const emp = [...doctors, ...nurses].find(el => el.userId._id === user._id);
                    color = emp?.visitColor;
                }
                newEvents.push({
                    ...vac,
                    userId: user._id,
                    userName: `${user.name}${user?.surname ? ` ${user?.surname}` : ''}`,
                    color,
                    start: new Date(vac.start),
                    end: new Date(vac.end),
                })
            })
        ));
        setEvents(newEvents);
    },
        addVacationHandler = (newVacation) => {
            newVacation.end = moment(newVacation.end).hour(23).minute(59)._d;
            const diffTime = Math.abs(newVacation.start - newVacation.end);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            newVacation.workDaysCount = diffDays;
            addVacation(newVacation);
        },
        updateVacationHandler = (newVacation, targetId = vacationModal.editId) => {
            newVacation.end = moment(newVacation.end).hour(23).minute(59)._d;
            newVacation.start = moment(newVacation.start).hour(0).minute(0)._d;
            const diffTime = Math.abs(newVacation.start - newVacation.end);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            newVacation.workDaysCount = diffDays;
            updateVacation(targetId, newVacation)
            setQuestionModal({ shown: false, data: null, question: '', onAccept: null });
        },
        deleteVacationHandler = (userId) => {
            deleteVacation(vacationModal.editId, userId);
            setVacationModal({ shown: false, editId: null });
            setQuestionModal({ shown: false, data: null, question: '', onAccept: null });
        },
        usersFiltersHandler = (userId, targetArr) => {
            if (userId === 'all') {
                const targetValue = !userFilters[targetArr].find(el => el._id === userId).checked;
                setUsersFilters(prev => ({
                    ...prev,
                    [targetArr]: prev[targetArr].map(
                        user => ({ ...user, checked: targetValue })
                    )
                }));
            } else {
                setUsersFilters(prev => ({
                    ...prev,
                    [targetArr]: prev[targetArr].map(
                        user => user._id === userId ? { ...user, checked: !user.checked } : user
                    )
                }));
            }
        }

    useEffect(() => {
        if (userRole?.companyId) {
            getEmployees('doctor', userRole.companyId);
            getEmployees('registry', userRole.companyId);
            getEmployees('nurse', userRole.companyId);
        }
    }, [userRole]);

    useEffect(() => {
        if (usersVacations && doctors && nurses) {
            let disabledUsersIds = [...userFilters.doctors, ...userFilters.registries, ...userFilters.nurses];
            disabledUsersIds = disabledUsersIds.filter(user => !user.checked).map(({ _id }) => _id);
            let vacationsArray = usersVacations;
            for (const id of disabledUsersIds) {
                vacationsArray = vacationsArray.filter(({ _id }) => _id !== id);
            };
            mapVacationsToEvents(vacationsArray);
        }
    }, [usersVacations, doctors, nurses, userFilters]);

    useEffect(() => {
        if (currentDate && userRole?.companyId) {
            const { _d: startDate } = moment(currentDate).subtract(2, 'M');
            const { _d: endDate } = moment(currentDate).add(2, 'M');
            getUsersVacationsByCompanyId(userRole.companyId, startDate, endDate);
        }
    }, [currentDate, userRole]);

    useEffect(() => {
        setUsersFilters({
            doctors: [{
                checked: true,
                _id: 'all',
                name: 'Wszyscy',
            }, ...doctors.map(({ userId }) => ({
                checked: true,
                _id: userId._id,
                name: `${userId.name} ${userId.surname}`,
                avatar: userId.avatar,
            }))],
            registries: [{
                checked: true,
                _id: 'all',
                name: 'Wszyscy',
            }, ...registries.map(({ userId }) => ({
                checked: true,
                _id: userId._id,
                name: `${userId.name} ${userId.surname}`,
                avatar: userId.avatar,
            }))],
            nurses: [{
                checked: true,
                _id: 'all',
                name: 'Wszyscy',
            }, ...nurses.map(({ userId }) => ({
                checked: true,
                _id: userId._id,
                name: `${userId.name} ${userId.surname}`,
                avatar: userId.avatar,
            }))],
        });
    }, [doctors, registries, nurses]);

    useEffect(() => {
        if (vacationsError !== null) {
            let message;
            switch (vacationsError) {
                case 'Doctor has scheduled visits in this time range.':
                    message = 'Ten lekarz ma zaplanowaną wizytyę w tym czasie!'
                    break;
                default:
                    message = vacationsError
                    break;
            }
            setErrorModal({ shown: true, info: message })
        } else {
            setErrorModal({ shown: false, info: '' })
        }
    }, [vacationsError]);

    return (
        <VacationsView
            vacationModal={vacationModal}
            setVacationModal={setVacationModal}
            doctors={doctors}
            registries={registries}
            nurses={nurses}
            currentCalendarDates={currentCalendarDates}
            setCurrentCalendarDates={setCurrentCalendarDates}
            events={events}
            setCurrentDate={setCurrentDate}
            addVacationHandler={addVacationHandler}
            updateVacationHandler={updateVacationHandler}
            deleteVacationHandler={deleteVacationHandler}
            questionModal={questionModal}
            setQuestionModal={setQuestionModal}
            userFilters={userFilters}
            usersFiltersHandler={usersFiltersHandler}
            errorModal={errorModal}
            resetVacationsError={resetVacationsError}
            acceptVacation={acceptVacation}
        />
    )
}

const mapStateToProps = state => ({
    userRole: state.authentication.userRole,
    doctors: state.employees.doctors,
    registries: state.employees.registries,
    nurses: state.employees.nurses,
    usersVacations: state.vacations.usersVacations,
    vacationsError: state.vacations.error,
});

const mapDispatchToProps = dispatch => {
    return {
        deleteUserVacations: (vacations) => dispatch(deleteUserVacations(vacations)),
        addVacation: (vacation) => dispatch(addVacation(vacation)),
        updateVacation: (vacationId, newVacation) => dispatch(updateVacation(vacationId, newVacation)),
        acceptVacation: (vacationId, body) => dispatch(acceptVacation(vacationId, body)),
        deleteVacation: (vacationId, userId) => dispatch(deleteVacation(vacationId, userId)),
        getEmployees: (employeeType, companyId) => dispatch(getEmployees(employeeType, companyId)),
        getUsersVacationsByCompanyId: (companyId, start, end) => dispatch(getUsersVacationsByCompanyId(companyId, start, end)),
        resetVacationsError: () => dispatch(resetVacationsError()),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VacationsContainer);