import React, { useState, useEffect, useRef } from 'react';
import Button from '../Shared/Button';
import styled from 'styled-components';
import moment from 'moment';
import { stagesTextsName } from '../Shared/enums';
import Tooltip from '../Shared/Tooltip';
import { ReactComponent as InfoIcon } from '../Shared/assets/info-circle.svg';
import { useReactToPrint } from 'react-to-print';
import VisitPrint from './VisitPrint';
import { connect } from "react-redux";
import useDebouncer from '../Shared/Hooks/useDebouncer';

const GeneratedText = ({
    currentStageIndex,
    stagesTexts,
    warningModalHandler,
    documents,
    readMode,
    changeTextsSchemasHandler,
    changes = {},
    visitText,
    patient,
    doctor,
    doctorRole,
    visitData,
    healthcenter,
    companyLogo
}) => {
    const [text, setText] = useState('');
    const [setDebounce] = useDebouncer();
    const stageName = stagesTextsName[currentStageIndex];
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (currentStageIndex !== 5) {
            setText(stagesTexts[stageName])
        } else {
            const mapedAllStagesTexts = `<span>Wywiad:</span>
${stagesTexts?.medicalIntelligence}
<span>Badania fizykalne:</span>
${stagesTexts?.physicalExaminations}
<span>Badania dodatkowe:</span>
${stagesTexts?.additionalExaminations}
<span>Rozpoznanie:</span>
${stagesTexts?.diagnosis}
<span>Zalecenia:</span>
${stagesTexts?.recommendations}
<span>Dodane pliki:</span>${[...documents.photos, ...documents.documents].length > 0 ?
                    [...documents.photos, ...documents.documents].map(({ originalName }) => (
                        `\n- ${originalName}`
                    ))
                    :
                    '\nBrak'
                }
            `
            setText(mapedAllStagesTexts);
        }
    }, [currentStageIndex, stagesTexts, documents]);
    const previousValues = changes[stageName] ?? [];
    return (
        <Wrapper stageIndex={currentStageIndex}>
            <Label>Wygenerowane sprawozdanie</Label>
            {(previousValues && previousValues.length > 0) && <StyledTooltip name='generatedText' content={
                <TooltipContent>
                    <p>Poprzednie wartości:</p>
                    <ul>
                        {previousValues.map(({ value, changeDate }, index) => {
                            const mapedValue = value.replace(/(^[ \t]*\n)/gm, "");
                            return (
                                <li key={`${changeDate}_${index}`}>{`${moment(changeDate).format('DD.MM.YYYY HH:mm')} - ${mapedValue}`}</li>
                            )
                        })}
                    </ul>
                </TooltipContent>
            }>
                <StyledInfoIcon />
            </StyledTooltip>}
            {(currentStageIndex !== 5 && !readMode) && <Button title='Odśwież tekst' variant='refresh' onClick={() => warningModalHandler('refreshText')} />}
            {(currentStageIndex === 5 && !readMode) && <Button variant='print' onClick={handlePrint} />}
            <div style={{ display: "none" }}>
                <VisitPrint
                    ref={componentRef}
                    visitText={visitText}
                    patient={patient}
                    doctor={doctor}
                    doctorRole={doctorRole}
                    visitData={visitData}
                    healthcenter={healthcenter}
                    documents={documents}
                    companyLogo={companyLogo}
                />
            </div>
            <Editable
                suppressContentEditableWarning={true}
                contentEditable={(currentStageIndex !== 5 && !readMode)}
                onInput={(e) => {
                    const value = e.currentTarget.innerHTML;
                    setDebounce(() => {
                        changeTextsSchemasHandler(value);
                    })
                }}
                dangerouslySetInnerHTML={{ __html: text }}
                stageIndex={currentStageIndex}
            />
        </Wrapper>
    )
};

const mapStateToProps = (state) => ({
    visitText: state.medicalVisit.currentMedicalVisit.stagesTexts,
    patient: state.patients.currentPatient,
    doctor: state.authentication.user,
    doctorRole: state.authentication.userRole,
    visitData: state.visits.currentVisit,
    healthcenter: state.healthcenter.currentHealthcenter,
    companyLogo: state.company.data?.logo,
});

export default connect(mapStateToProps, null)(GeneratedText);


const Wrapper = styled.div`
    height:${({ stageIndex }) => stageIndex !== 5 ? '45%' : '80%'};
    transition:300ms;
    position:relative;
    padding:${({ stageIndex }) => stageIndex !== 5 ? '20px' : '10px'} 0;
    border-top:1px solid ${({ theme }) => theme.grey};
    >button{
        top: ${({ stageIndex }) => stageIndex === 5 ? '5px' : '16px'};
        right: 10px;
    }
`;

const Label = styled.p`
    font-size:14px;
    font-weight:600;
    text-transform:uppercase;
    margin: 0 0 10px 5px;
    color:${({ theme }) => theme.black};
`;

const Editable = styled.div`
    padding:9px;
    border: 1px solid #d9d9d9;
    border-radius:4px;
    min-height:100px;
    height:${({ stageIndex }) => stageIndex !== 5 ? 'calc(100% - 60px)' : 'calc(100% - 30px)'};
    font-size:14px;
    white-space: pre-wrap;
    overflow-y: auto;
    background-color: ${({ stageIndex }) => stageIndex !== 5 ? '#fff' : '#f2f5f8'};
    b{
        color:${({ theme }) => theme.primary};
    }
    li{
        display:inline;
    }
    >span{
        font-weight:600;
    }
    &:focus{
        outline-color:${({ theme }) => theme.primary};
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
    position:absolute;
    right:-6.5px;
    top:-5px;
    z-index:9;
`;

const StyledTooltip = styled(Tooltip)`
    position:absolute;
    right: 60px;
    top: 25px;
`;

const TooltipContent = styled.div`
    max-width:500px;
    p{
        color:#fff;
    }
    ul{
        margin:0;
        >li{
            color:#fff;
            white-space: pre-wrap;
        }
    }
`;