import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  addPatient,
  getCompanyPatients,
  setCurrentPatient,
  updatePatientWithVisits,
  setPatientAvatar,
} from "../../../../redux/modules/patients";
import { patientsPageLoaded } from "../../../../redux/modules/application";
import DoctorPatientsSidebarView from './DoctorPatientsSidebarView';
import { useHistory, useParams, withRouter } from "react-router-dom";
import useDebouncer from '../../../Shared/Hooks/useDebouncer';
import { getEmployees } from '../../../../redux/modules/employees';
import { setNavBlock } from '../../../../redux/modules/navigation';
import { nextTutorialStep } from '../../../../redux/modules/tutorial';

const DoctorPatientsSidebarContainer = ({
  patients,
  userRole,
  currentPatientId,
  addPatient,
  getCompanyPatients,
  setCurrentPatient,
  location: { pathname },
  patientsPageLoaded,
  getEmployees,
  currentPage,
  updatePatientWithVisits,
  navBlock,
  setNavBlock,
  paginationMetadata,
  setPatientAvatar,
  nextTutorialStep
}) => {
  const [patientModalState, setPatientModalState] = useState(false),
    [sidepanelFilter, setSidepanelFilter] = useState(""),
    [sidepanelPatients, setSidepanelPatients] = useState(patients ? patients : []),
    [setDebounce, sidebarLoading] = useDebouncer(true),
    [warningModal, setWarningModal] = useState({ shown: false, link: null });

  useEffect(() => {
    setSidepanelPatients(patients);
  }, [patients]);

  useEffect(() => {
    if (userRole) {
      getEmployees('doctor', userRole.companyId);
      getEmployees('nurse', userRole.companyId);
    }
  }, [userRole]);

  useEffect(() => {
    if (currentPatientId && currentPage !== "PATIENTS") {
      patientsPageLoaded()
    }
  }, [currentPatientId]);

  useEffect(() => {
    if (userRole) {
      setDebounce(() =>
        getCompanyPatients(userRole?.companyId, 10, 1, sidepanelFilter)
      );
    }
  }, [sidepanelFilter, userRole]);

  const { patientId: urlPatientId, currentTab: urlCurrentTab } = useParams(),
    history = useHistory();

  useEffect(() => {
    if (patients && currentPatientId) {
      if (urlPatientId) {
        const patientIndex = patients.findIndex(el => el._id === urlPatientId);
        if (patientIndex !== -1 && currentPatientId !== urlPatientId) {
          setCurrentPatient(urlPatientId);
          if (!urlCurrentTab) {
            history.push(`/patients/${urlPatientId}/visits`);
          }
        } else if (patientIndex === -1) {
          history.push(`/patients/${currentPatientId}/${urlCurrentTab ? urlCurrentTab : 'visits'}`);
        } else if (!urlCurrentTab && currentPatientId === urlPatientId) {
          history.push(`/patients/${urlPatientId}/${urlCurrentTab ? urlCurrentTab : 'visits'}`);
        }
      }
      else if (!urlPatientId) {
        history.push(`/patients/${currentPatientId}/${urlCurrentTab ? urlCurrentTab : 'visits'}`);
      }
    }
  }, [patients, pathname])

  const addPatientHandler = useCallback(async patient => {
    const res = await addPatient(userRole.companyId, patient);
    return res;
  }, [userRole]);

  const onPatientPageChange = useCallback((pageChangeDirection) => {
    let pageNumber = 1;
    if (pageChangeDirection === "PREV_PAGE") {
      pageNumber = paginationMetadata.currentPage - 1
    } else if (pageChangeDirection === "NEXT_PAGE") {
      pageNumber = paginationMetadata.currentPage + 1
    }
    getCompanyPatients(userRole.companyId, 10, pageNumber, sidepanelFilter);
  }, [userRole, paginationMetadata, sidepanelFilter]);

  return <DoctorPatientsSidebarView
    patientModalState={patientModalState}
    setPatientModalState={setPatientModalState}
    sidepanelPatients={sidepanelPatients}
    sidepanelFilter={sidepanelFilter}
    setSidepanelFilter={setSidepanelFilter}
    addPatientHandler={addPatientHandler}
    setCurrentPatient={setCurrentPatient}
    currentPatientId={currentPatientId}
    urlCurrentTab={urlCurrentTab}
    sidebarLoading={sidebarLoading}
    updatePatientWithVisits={updatePatientWithVisits}
    warningModal={warningModal}
    setWarningModal={setWarningModal}
    navBlock={navBlock}
    setNavBlock={setNavBlock}
    history={history}
    paginationMetadata={paginationMetadata}
    onPatientPageChange={onPatientPageChange}
    setPatientAvatar={setPatientAvatar}
    nextTutorialStep={nextTutorialStep}
  />
};

const mapStateToProps = state => ({
  userRole: state.authentication.userRole,
  healthcenters: state.healthcenter.data,
  patients: state.patients.data,
  paginationMetadata: state.patients.paginationMetadata,
  currentPatientId: state.patients.currentPatient._id,
  currentPage: state.application.currentPage,
  navBlock: state.navigation.navBlock,
});

const mapDispatchToProps = dispatch => {
  return {
    addPatient: (companyId, patient) => dispatch(addPatient(companyId, patient)),
    getCompanyPatients: (companyId, pageSize, pageNumber, searchTerm) => dispatch(getCompanyPatients(companyId, pageSize, pageNumber, searchTerm)),
    setCurrentPatient: patientId => dispatch(setCurrentPatient(patientId)),
    patientsPageLoaded: () => dispatch(patientsPageLoaded()),
    getEmployees: (type, id) => dispatch(getEmployees(type, id)),
    updatePatientWithVisits: (patientNewData) => dispatch(updatePatientWithVisits(patientNewData)),
    setNavBlock: payload => dispatch(setNavBlock(payload)),
    setPatientAvatar: (patientId, data) => dispatch(setPatientAvatar(patientId, data)),
    nextTutorialStep: () => dispatch(nextTutorialStep()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DoctorPatientsSidebarContainer));