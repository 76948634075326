import moment from "moment";

export const calculateDisabledHours = (startDate, endDate) => {
  const disabledHoursCount = calculateDisabledHoursRange(startDate, endDate);
  let time = [];
  let formattedTime;
  for (let i = 1; i < disabledHoursCount + 1; i++) {
    formattedTime = moment(startDate)
      .subtract(i, "hours")
      .format("H");
    time.unshift(parseInt(formattedTime));
  }
  return time.sort((a, b) => a - b);
};

const calculateDisabledHoursRange = (startDate, endDate) => {
  return 24 + moment(startDate).diff(moment(endDate), "hours");
};
