export const addictionsEnum = [
    "Tytoń",
    "Alkohol",
    "Substancje stymulujące",
    "Substancje uspokajające/nasenne",
    "Kanabinole",
    "Opioidy",
    "Kokaina",
    "Halucynogeny",
    "Lotne rozpuszczalniki organiczne",
    "Wiele narkotyków i innych substancji psychoaktywnych"
];
export const relationshipKindsEnum = [
    "Mama",
    "Tata",
    "Babcia",
    "Dziadek",
    "Wujek",
    "Ciocia",
    "Brat",
    "Siostra",
    "Dalsza rodzina"
];
export const diseaseCausingOrganismsEnum = [
    "HIV",
    "HBV",
    "Salmonella Typhi",
    "Corynebacterium diphtheriae",
    "HTLV-1",
    "Meningokoki",
    "Gronkowce",
    "Paciorkowce",
    "Rzeżączka",
    "Kiła",
    "Inne zakaźne choroby jelitowe",
    "Inne choroby zakaźne"
];