import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Button from "../../Shared/Button";
import Modal from "../../Shared/Modals/Modal";
import Input from "../../Shared/Input";
import styled from "styled-components";
import RejectIcon from "../../Shared/assets/reject.svg";
import Switch from "../../Shared/Switch";
import Select from "../../Shared/Select";
import { academicTitles } from "../utils";
import { getShortenString } from "../../Shared/utils";
import { employeesColors } from "../../Shared/enums";
import FileUpload from "../../Shared/FileUpload";
import moment from 'moment';
import TimePicker from '../../Shared/TimePicker';
import ColorPicker from '../../Shared/ColorPicker';

const EditUserModal = ({
  isEditModalOn,
  setEditModal,
  currentUser,
  getEmployeeVacations,
  employeeVacations,
  updateDoctorDetailData,
  updateRegistryDetailData,
  updateNurseDetailData,
  updateUserBasicData,
  healthcenters,
  uploadUserCertificates,
  deleteUserCertificates
}) => {
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);

  const addCertificatesHandler = () => {
    let formDatas = [];
    filesToUpload.forEach(file => {
      let formData = new FormData();
      formData.append("userId", currentUser.userId._id);
      formData.append("name", file.name);
      formData.append("certificate", file);
      formDatas.push(formData);
    })
    uploadUserCertificates(currentUser._id, formDatas);
  };

  useEffect(() => {
    setFilesToDelete([])
    setFilesToUpload([])
  }, [])

  useEffect(() => {
    currentUser?.userId && getEmployeeVacations(currentUser.userId.vacations);
    currentUser?.userId?.activeRole === "Doctor" && setFiles(currentUser.certificates);
  }, [currentUser]);

  const handleChangeRoom = (defaultRooms, healthcenter, room) => {
    let updatedDefaultRooms = defaultRooms;
    const changeIndex = defaultRooms.findIndex((el) => el.healthcenter === healthcenter);
    const newItem = {
      healthcenter,
      room
    }
    if (changeIndex !== -1) {
      updatedDefaultRooms[changeIndex] = newItem;
    } else {
      updatedDefaultRooms = [...updatedDefaultRooms, newItem]
    }
    return updatedDefaultRooms;
  };

  useEffect(() => {
    if (healthcenters && currentUser) {
      const userHcs = healthcenters.filter(({ _id }) => currentUser.healthCenters?.includes(_id));
      const newRoomOptions = userHcs.map(({ _id, name, rooms }) => ({
        healthcenterId: _id,
        healthcenterName: name,
        rooms: [{ label: 'Nie przypisany', value: null }, ...rooms.map(({ name, _id }) => ({ value: _id, label: name }))]
      }))
      setRoomOptions(newRoomOptions);
    }
  }, [healthcenters, currentUser]);

  return (
    <Modal
      show={isEditModalOn}
      exitButton={true}
      onCancel={() => {
        setEditModal(false);
      }}
      title="Edycja użytkownika"
    >
      <Formik
        initialValues={{
          name: currentUser.userId?.name,
          surname: currentUser.userId?.surname,
          email: currentUser.userId?.email,
          tel: currentUser.userId?.tel,
          visitTime: currentUser.callendarSettings?.step,
          isActive: currentUser.isActive,
          vacations: employeeVacations,
          visitColor: currentUser.visitColor,
          academicTitle: currentUser.academicTitle,
          pwz: currentUser.pwz,
          defaultRooms: currentUser.defaultRooms,
          prescriptionsNumbers: currentUser.prescriptionsNumbers,
          certificates: currentUser.certificates,
        }}
        enableReinitialize
        validationSchema={EditUserSchema}
        onSubmit={(values, actions) => {
          let detailData, basicData;
          if (currentUser.userId.activeRole === "Doctor" || currentUser?.userId?.activeRole === "Nurse") {
            detailData = {
              callendarSettings: { step: values.visitTime },
              visitColor: values.visitColor,
              isActive: values.isActive,
              academicTitle: values.academicTitle,
              pwz: values.pwz,
              defaultRooms: values.defaultRooms,
              prescriptionsNumbers: values.prescriptionsNumbers,
              certificates: values.certificates,
            };
            basicData = {
              name: values.name,
              surname: values.surname,
              email: values.email,
              tel: values.tel
            };
            if (currentUser.userId.activeRole === "Doctor") {
              updateDoctorDetailData(detailData, currentUser._id);
            } else {
              updateNurseDetailData(detailData, currentUser._id);
            }
            filesToUpload.length > 0 && addCertificatesHandler();
            filesToDelete.length > 0 && deleteUserCertificates(currentUser._id, filesToDelete);
          } else {
            detailData = {
              isActive: values.isActive,
            };
            basicData = {
              name: values.name,
              surname: values?.surname,
              email: values.email,
              tel: values.tel,
            };
            updateRegistryDetailData(detailData, currentUser._id);
          }
          updateUserBasicData(basicData, currentUser.userId._id);
          setEditModal(false);
          actions.setSubmitting(false);
        }}>
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldError,
          values,
          errors,
        }) => (
            <Form onSubmit={handleSubmit}>
              <Modal
                show={deleteUserModal}
                type="SimpleQuestionModal"
                onCancel={() => setDeleteUserModal(false)}
                onAccept={() => {
                  const basicData = {
                    name: values.name,
                    surname: values?.surname,
                    email: values.email,
                    deleted: true,
                  };
                  setDeleteUserModal(false);
                  setEditModal(false);
                  updateUserBasicData(basicData, currentUser.userId._id);
                }}
                acceptText="Potwierdzam"
                cancelText="Anuluj"
                question="Czy jesteś pewien, że chcesz usunąć tego pracownika? Tego działania nie można odwrócić."
              />
              <FormContent>
                <Column>
                  <InputsGrid>
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      name="name"
                      label="Imię"
                      error={errors.name}
                    />
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.surname}
                      name="surname"
                      label="Nazwisko"
                      error={errors?.surname}
                    />
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel}
                      name="tel"
                      label="Numer telefonu"
                      error={errors.tel}
                    />
                    <Input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                      label="Email"
                      error={errors.email}
                    />
                    {(currentUser?.userId?.activeRole === "Doctor" || currentUser?.userId?.activeRole === "Nurse") &&
                      <>
                        <StyledSelect
                          label="Tytuł"
                          onChange={({ value }) =>
                            setFieldValue("academicTitle", value)
                          }
                          onBlur={handleBlur}
                          name="academicTitle"
                          error={errors.academicTitle}
                          options={academicTitles}
                          defaultValue={{
                            values: values.academicTitle,
                            label: values.academicTitle,
                          }}
                        />
                        <Input
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pwz}
                          name="pwz"
                          label="Nr. prawa wyk. zawodu"
                          error={errors.pwz}
                        />
                        <Input
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prescriptionsNumbers}
                          name="prescriptionsNumbers"
                          label="Zakres numerów recepty"
                          error={errors.prescriptionsNumbers}
                        />
                        <ColorPicker
                          label='Kolor'
                          width='160px'
                          onChange={({ hex }) => setFieldValue("visitColor", hex)}
                          currentColor={values.visitColor}
                          colors={employeesColors}
                        />
                      </>
                    }
                  </InputsGrid>
                  {(currentUser.userId.activeRole === "Doctor" || currentUser.userId.activeRole === "Nurse") &&
                    <>
                      <FileUpload
                        label="Certyfikaty"
                        onDrop={(file) => {
                          setFilesToUpload([...filesToUpload, file[0]]);
                        }}
                      />
                      {[...files, ...filesToUpload].length > 0 && (
                        <>
                          <Label>Dodane certyfikaty</Label>
                          {[...files, ...filesToUpload].map((file) => (
                            <DocItem>{getShortenString(file.name, 25)} <VacationDeleteIcon
                              src={RejectIcon}
                              onClick={() => {
                                setFilesToDelete([...filesToDelete, file]);
                                setFiles(files.filter(el => el._id !== file._id))
                                setFilesToUpload(filesToUpload.filter(el => el._id !== file._id))
                              }}
                            /></DocItem>
                          ))}
                        </>
                      )}
                    </>
                  }
                </Column>
                <Column>
                  {(currentUser.userId.activeRole === "Doctor" || currentUser?.userId?.activeRole === "Nurse") && (
                    <TimePicker
                      label="Czas trwania wizyty"
                      allowEmpty={false}
                      value={moment(values.visitTime, "mm")}
                      onChange={(e) =>
                        e !== null
                          ? setFieldValue("visitTime", parseInt(e.format("mm")))
                          : setFieldError("visitTime", "Pole wymagane")
                      }
                      onBlur={handleBlur}
                      name="visitTime"
                      showSecond={false}
                      showHour={false}
                      minuteStep={5}
                      disabledMinutes={() => [0, 25, 35, 40, 50, 55]}
                      hideDisabledOptions={true}
                      error={errors.visitTime}
                    />
                  )}
                  {(currentUser.userId.activeRole === "Doctor" || currentUser?.userId?.activeRole === "Nurse") && 
                    roomOptions.map(({ healthcenterName, healthcenterId, rooms }) => (
                      <Select
                        label={healthcenterName}
                        name={`defaultRoom_${healthcenterId}`}
                        onChange={({ value }) => setFieldValue(
                          "defaultRooms",
                          handleChangeRoom(values.defaultRooms, healthcenterId, value)
                        )}
                        options={rooms}
                        value={rooms?.find(el => el.value === values.defaultRooms.find(r => r.healthcenter === healthcenterId)?.room)}
                      />
                    ))
                  }
                </Column>
              </FormContent>
              <ButtonsContainer>
                <Input
                  type='checkbox'
                  checkboxText='Użytkownik aktywny'
                  value={values.isActive}
                  variant='secondary'
                  hideError
                  onChange={() => setFieldValue("isActive", !values.isActive)}
                />
                <span>
                  <SubmitButton type="submit" variant="primary">
                    Zapisz zmiany
                  </SubmitButton>
                  <DeleteButton
                    variant="danger"
                    onClick={() => setDeleteUserModal(true)}
                  >
                    Usuń pracownika
                  </DeleteButton>
                </span>
              </ButtonsContainer>
            </Form>
          )}
      </Formik>
    </Modal>
  );
};

const EditUserSchema = Yup.object().shape({
  name: Yup.string().required("Imię jest wymagane"),
  surname: Yup.string().required("Nazwisko jest wymagane"),
  email: Yup.string()
    .email("Nieprawidłowy email!")
    .required("Email jest wymagany"),
  tel: Yup.string()
    .min(9, "Numer musi mieć minimum 9 cyfr"),
  visitTime: Yup.number(),
  status: Yup.boolean(),
});

export default EditUserModal;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const VacationDeleteIcon = styled.img`
  height: 10px;
  width: 10px;
  margin-left: 10px;
  filter: invert(43%) sepia(11%) saturate(5697%) hue-rotate(322deg)
    brightness(87%) contrast(75%);
  cursor: pointer;
  &:hover {
    filter: invert(23%) sepia(9%) saturate(7151%) hue-rotate(323deg)
      brightness(114%) contrast(81%);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:center;
`;

const DeleteButton = styled(Button)``;

const StyledSwitch = styled(Switch)`
  margin: 5px 0 10px 5px;
`;
const Column = styled.div`
  min-width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

const InputsGrid = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  column-gap:20px;
  width:100%;
  ${({ theme }) => `${theme.mq.desktop}{
      width:365px;
  }`}
`;

const FormContent = styled.div`
  display: flex;
  flex-wrap:wrap;
  min-width:500px;
  ${({ theme }) => `${theme.mq.desktop}{
    flex-wrap:nowrap;
  }`}
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

const StyledSelect = styled(Select)`
  >div{
    margin-top:2px;
  }
`;

const Label = styled.p`
  display: block;
  font-size: 14px;
`;

const DocItem = styled.p`
  font-size: 14px;
`;