import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::-moz-selection { background: #1890FF; }
::selection { background: #1890FF; }
::-webkit-scrollbar {
    background-color: #fff0;
    width: 12px;
}
::-webkit-scrollbar-button {
    display: none;
}
::-webkit-scrollbar-thumb {
    background-color: #dadce0;
    border-radius: 16px;
}
::-webkit-scrollbar-track {
    background-color: #fff0;
}
html {
    scroll-behavior: smooth;
}
body{
    background: white;
    /* font-family: 'Roboto', sans-serif; */
    font-family: "Open Sans", sans-serif;
    margin: 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
h1,h2,h3,h4,h5 {
    font-family: "Open Sans", sans-serif;
    color: #737373;
}
input,textarea {
    font-family: "Open Sans",sans-serif;
}
button {
    font-family: "Open Sans", sans-serif;
    outline: none;
}
p, span, li {
    /* font-family: "Roboto", sans-serif; */
    font-family: "Open Sans", sans-serif;
    color: #484848;
    margin: 0;
}
`;
export default GlobalStyle;
