import React from 'react';
import Modal from '../../Shared/Modals/Modal';

const WarningModal = ({
    onAccept,
    onCancel,
    question,
    show,
    setModalState,
    reverseButtonColors = false,
}) => {
    return (
        <Modal
            type="SimpleQuestionModal"
            show={show}
            question={question}
            acceptText="Tak"
            cancelText="Nie"
            reverseButtonColors={reverseButtonColors}
            onAccept={() => {
                onAccept();
                setModalState({ shown: false, question: null, onAccept: null });
            }}
            onCancel={() => {
                if(onCancel){
                    onCancel();
                }else{
                    setModalState({ shown: false, question: null, onAccept: null })            
                }
            }}
        />
    )
}

export default WarningModal;