import React from 'react';
import styled from 'styled-components';
import SidePanel from './SidePanel';
import CreationTab from './CreationTab';
import PreviewTab from './PreviewTab';
import SettingsPanel from './SettingsPanel';
import Button from '../../Shared/Button';
import SidebarMobileMask from '../../Shared/SidebarMobileMask';

import saveIcon from '../../Shared/assets/save.svg';
import { ReactComponent as InfoIcon } from '../../Shared/assets/info-circle.svg';

import WarningModal from '../Modals/WarningModal';

const dragItemTypes = {
    select: 'select',
    radio: 'radio',
    checkbox: 'checkbox',
    input: 'input',
    datepicker: 'datepicker',
    timepicker: 'timepicker',
    parameter: 'parameter',
};

const ElementEditorPageView = ({
    currentElement,
    currentTab,
    setCurrentTab,
    history,
    currentParameter,
    setCurrentParameter,
    currentStage,
    addControlHandler,
    removeControlHandler,
    addParameterHandler,
    removeParameterHandler,
    changeValuesHandler,
    currentControl,
    setCurrentControl,
    currentSelectType,
    setCurrentSelectType,
    elIsDragging,
    setElIsDragging,
    updateElementHandler,
    didChanges,
    warningModal,
    setWarningModal,
    generatedText,
    setGeneratedText,
    changeParameterIndexHandler,
    cloneControlHandler,
    saveFormControlHandler,
    doctorsFormControls,
    deleteDoctorsFormControls,
    elementError,
    setElementError,
    doctorsElements,
    sidebarActive,
    setSidebarActive,
    swipeHandlers,
}) => {
    return (
        <Wrapper {...swipeHandlers}>
            <WarningModal
                show={warningModal.shown}
                question={warningModal.question}
                onAccept={warningModal.onAccept}
                onCancel={warningModal.onCancel}
                setModalState={setWarningModal}
            />
            <SidebarMobileMask
                active={sidebarActive}
                accept={Object.values(dragItemTypes)}
                onClick={() => sidebarActive && setSidebarActive(false)}
            />
            <SidePanel
                currentElement={currentElement}
                history={history}
                dragItemTypes={dragItemTypes}
                setElIsDragging={setElIsDragging}
                updateElementHandler={updateElementHandler}
                didChanges={didChanges}
                setWarningModal={setWarningModal}
                setCurrentSelectType={setCurrentSelectType}
                changeValuesHandler={changeValuesHandler}
                currentSelectType={currentSelectType}
                doctorsFormControls={doctorsFormControls}
                deleteDoctorsFormControls={deleteDoctorsFormControls}
                sidebarActive={sidebarActive}
            />
            <Content>
                <SaveBox>
                    <SaveBtn
                        id='saveBtn'
                        variant='primary'
                        disabled={!didChanges}
                        onClick={updateElementHandler}
                    >
                        Zapisz zmiany
                        </SaveBtn>
                    {didChanges && <Info><BlueInfoIcon /> Masz niezapisane zmiany!</Info>}
                </SaveBox>
                <Main>
                    <Tabs>
                        <TabsHeader>
                            <TabHeading
                                id='creationTab'
                                active={currentTab === 0}
                                onClick={() => setCurrentTab(0)}
                            >
                                Tworzenie
                                </TabHeading>
                            <TabHeading
                                id='previewTab'
                                active={currentTab === 1}
                                onClick={() => setCurrentTab(1)}
                            >
                                Podgląd
                                </TabHeading>
                        </TabsHeader>
                        <TabsBody>
                            {currentTab === 0 ?
                                <CreationTab
                                    dragItemTypes={dragItemTypes}
                                    currentElement={currentElement}
                                    setCurrentParameter={setCurrentParameter}
                                    addControlHandler={addControlHandler}
                                    addParameterHandler={addParameterHandler}
                                    currentControl={currentControl}
                                    setCurrentControl={setCurrentControl}
                                    currentSelectType={currentSelectType}
                                    setCurrentSelectType={setCurrentSelectType}
                                    setElIsDragging={setElIsDragging}
                                    elIsDragging={elIsDragging}
                                    changeParameterIndexHandler={changeParameterIndexHandler}
                                />
                                :
                                <PreviewTab
                                    currentElement={currentElement}
                                    setGeneratedText={setGeneratedText}
                                />
                            }
                        </TabsBody>
                    </Tabs>
                    <SettingsPanel
                        currentElement={currentElement}
                        currentParameter={currentParameter}
                        removeControlHandler={removeControlHandler}
                        removeParameterHandler={removeParameterHandler}
                        changeValuesHandler={changeValuesHandler}
                        currentControl={currentControl}
                        setCurrentControl={setCurrentControl}
                        currentSelectType={currentSelectType}
                        setCurrentSelectType={setCurrentSelectType}
                        currentTab={currentTab}
                        generatedText={generatedText}
                        currentStage={currentStage}
                        cloneControlHandler={cloneControlHandler}
                        saveFormControlHandler={saveFormControlHandler}
                        doctorsFormControls={doctorsFormControls}
                        elementError={elementError}
                        setElementError={setElementError}
                        doctorsElements={doctorsElements}
                    />
                </Main>
            </Content>
        </Wrapper>
    )
}

export default ElementEditorPageView;

const Wrapper = styled.div`
    display:flex;
`;

const Content = styled.div`
    padding:15px;
    width:100vw;
    position:relative;
    ${({ theme }) => `${theme.mq.desktop}{
        width:calc(100vw - 250px);
    }`}
    ${({ theme }) => `${theme.mq.large}{
        width:calc(100vw - 300px);
    }`}
`;

const Main = styled.div`
    display:grid;
    grid-template-columns: 3fr 2fr; 
    align-items:flex-start;
    gap:10px;
    margin:50px auto 0;
    max-width:1400px;
    width:100%;
    ${({ theme }) => `${theme.mq.desktop}{
        grid-template-columns: 3fr 1fr; 
    }`}
`;

const Tabs = styled.div`
    width:100%;
`;

const TabsHeader = styled.div`
    display:flex;
    align-items:center;
    position:relative;
    z-index:1;
`;

const TabHeading = styled.div`
    border: 2px solid ${({ theme }) => theme.grey};
    background-color:#F8F7FA;
    padding: 6px 25px 21px 15px;
    margin-bottom: -15px;
    cursor:pointer;
    ${({ active }) => active && `
        background-color:#fff;
        font-weight: 600;
    `}
`;

const TabsBody = styled.div`
    border: 2px solid ${({ theme }) => theme.grey};
    border-radius:10px;
    height:calc(100vh - 260px);
    max-height: 100%;
    overflow-y:auto;
    background-color:#fff;
    position:relative;
    z-index:2;
    padding-bottom:10px;
`;

const SaveBtn = styled(Button)`
    display:flex;
    align-items:center;
    justify-content:space-between;
    font-size:14px; 
    font-weight:600;
    padding:6px 8px;
    box-shadow: 0 4px 6px ${({ theme }) => theme.primary};
    ${({ disabled }) => disabled && `
        opacity:1;
        background-color:#D9D9D9;
        box-shadow: 0 4px 6px #eee;
    `}
    &::before{
        content:url(${saveIcon});
        height:17px;
        width:17px;
        margin-right:5px;
        display:inline-block;
    }
`;

const SaveBox = styled.div`
    position:absolute;
    top:25px;
    left:0;
    right:0;
    display:flex;
    align-items:flex-end;
    justify-content:flex-start;
    flex-direction:column;
    max-width:1400px;
    margin:auto;
    min-height:61px;
    padding:0 5px;
`;

const Info = styled.p`
    font-size:14px;
    font-weight:600;
    display:flex;
    align-items:center;
    margin-left:10px;
    margin-top:6px;
    color:${({ theme }) => theme.primary};
`;

const BlueInfoIcon = styled(InfoIcon)`
    margin-right:3px;
    margin-bottom:1px;
    path{
        fill:${({ theme }) => theme.primary}
    }
`;