import React from "react";
import styled from "styled-components";

const Header = ({ label }) => {
  const splitedLabel = label.split(" ");
  return (
    <HeaderContainer className="rbc-header">
      <DayName>{splitedLabel[0]}</DayName>
      {splitedLabel[1] && <DayNumber>{splitedLabel[1]}</DayNumber>}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
`;

const DayName = styled.span`
  font-size: 12px;
  color: #70757a;
  margin: 10px;
  font-family: ${({ theme }) => theme.font.family.openSans};
`;

const DayNumber = styled.span`
  font-size: 26px;
  color: #70757a;
  margin-bottom: 25px;
`;
