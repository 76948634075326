import React from "react";
import styled, { css } from "styled-components";
import arrowImg from "./assets/arrow-down.svg";

const PaginationContainer = ({
  isVisitsPagination,
  currentPage,
  totalPages,
  onPageChange,
}) => {
  return (
    <Container isVisitsPagination>
      {currentPage === 1 ? (
        <ArrowImage left src={arrowImg} />
      ) : (
        <ToolbarArrowButton left onClick={() => onPageChange("PREV_PAGE")}>
          <ArrowImage left src={arrowImg} />
        </ToolbarArrowButton>
      )}
      <Number>
        <>
          {totalPages === 0 ? <>{totalPages}</> : <>{currentPage}</>} /{" "}
          {totalPages}
        </>
      </Number>
      {currentPage === totalPages || totalPages === 0 ? (
        <ArrowImage src={arrowImg} />
      ) : (
        <ToolbarArrowButton right onClick={() => onPageChange("NEXT_PAGE")}>
          <ArrowImage src={arrowImg} />
        </ToolbarArrowButton>
      )}
    </Container>
  );
};

export default PaginationContainer;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  ${({ isVisitsPagination }) =>
    isVisitsPagination &&
    css`
      margin: 15px auto 0;
      width: 230px;
    `}
`;

const ToolbarArrowButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0;
  &:hover {
    background-color: #e3e3e3;
    border-radius: 50%;
  }
`;

const ArrowImage = styled.img`
  display: block;
  padding: 7px;
  width: 30px;
  height: 30px;
  transition: 0.25s;
  transform: rotate(-90deg);
  ${({ left }) =>
    left &&
    `
  transform: rotate(90deg);
  `}
`;

const Number = styled.p`
  color: #939393;
  font-size: 14px;
`;
