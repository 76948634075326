import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../Shared/Modals/Modal';
import Textarea from '../../Shared/Textarea';
import FileUpload from '../../Shared/FileUpload';
import Button from '../../Shared/Button';
import { Formik } from 'formik';

const DocumentsModal = ({
    modalState,
    setModalState,
    addDocumentHandler,
}) => {
    const [file, setFile] = useState(null);

    return (
        <Modal
            title={'Dodawanie pliku'}
            exitButton={true}
            onCancel={() => { setModalState(false); setFile(null); }}
            show={modalState}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    description:'',
                }}
                onSubmit={(values) => {
                    addDocumentHandler({ ...values, file });
                    setFile(null);
                    setModalState(false);
                }}
            >
                {({ handleSubmit, values, handleChange, errors }) => (
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <FileUpload
                            label="Plik"
                            onDrop={(files) => {
                                setFile(files[0]);
                            }}
                            value={file}
                        />
                        <Textarea
                            name="description"
                            label="Opis pliku"
                            value={values.description}
                            error={errors.description}
                            onChange={handleChange}
                        />
                        <CenterBox>
                            <Button onClick={handleSubmit} type='submit' variant='primary'>Dodaj plik</Button>
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default DocumentsModal;

const Form = styled.form`
    width:350px;
    padding-top:10px;
`;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;