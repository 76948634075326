import React from "react";
import styled from "styled-components";
import VisitDashboardCard from "./VisitDashboardCard";
import NextVisitTimeIndicator from './NextVisitTimeIndicator';
import Select from "../../../Shared/Select";
import Calendar from "../../../Shared/Calendar";
import Button from "../../../Shared/Button";
import AddEventModal from "./modals/AddVisitDoctorDashboardModal";
import WarningModal from "../../../CalendarPage/Modals/WarningModal";

const DoctorDashboardSidebarView = ({ actionsOpen, setActionsOpen, healthcenters, userRole, user, patients, doctors, addVisit, addPatient, isCreateModalOn, setCreateModal, calendarDate, updateCalendarDate, selectValue, selectOptions, filterVisits, visits, currentVisit, setCurrentVisitPatient, setCurrentVisit, warningModal, setWarningModal, getCompanyPatientsHandler, nextTutorialStep }) => {
  const userHealthcenters = healthcenters.filter(({ _id }) => userRole?.healthCenters?.includes(_id)) ?? [];

  return (
    <div>
      <AddEventModal
        isCreateModalOn={isCreateModalOn}
        setCreateModal={setCreateModal}
        patients={patients}
        doctors={doctors}
        addPatient={addPatient}
        companyId={userRole?.companyId}
        addVisit={addVisit}
        healthcenters={userHealthcenters}
        userRole={userRole}
        user={user}
        selectedEvent={{ start: typeof (calendarDate) === "object" ? calendarDate.setMinutes(0, 0, 0) : new Date(calendarDate).setMinutes(0, 0, 0), end: typeof (calendarDate) === "object" ? calendarDate.setMinutes(userRole?.callendarSettings.step ?? 30, 0, 0) : new Date(calendarDate).setMinutes(userRole?.callendarSettings.step ?? 30, 0, 0) }}
        calendarDate={calendarDate}
        setWarningModal={setWarningModal}
        visits={visits}
        getCompanyPatientsHandler={getCompanyPatientsHandler}
        nextTutorialStep={nextTutorialStep}
      />
      <WarningModal
        title={warningModal.title}
        show={warningModal.shown}
        question={warningModal.question}
        preventClosing={warningModal.preventClosing}
        onAccept={warningModal.onAccept}
        setModalState={setWarningModal}
      />
      <StyledCalendar
        value={new Date(calendarDate)}
        onChange={(date) => updateCalendarDate(date)}
      />
      <CalendarActions>
        <Today
          id='backToToday'
          onClick={() => updateCalendarDate(Date.now())}>Dzisiaj</Today>
      </CalendarActions>
      <FlexContainer>
        <AddVisitButton
          id='dashboardAddNewVisit'
          plusIcon={true}
          variant="primary"
          onClick={() => {
            setCreateModal(true);
            if (nextTutorialStep) {
              nextTutorialStep();
            }
          }}
        >Dodaj nową wizytę</AddVisitButton>
      </FlexContainer>
      <UnderCalendarToolbarContainer>
        <VisitCountText>{visits.length === 1 ? '1 wizyta' : visits.length + ' wizyt'}</VisitCountText>
        <StyledSelect options={selectOptions} selectValue={selectValue} onChange={({ value }) => { filterVisits(value) }} defaultValue={{ label: "Wszystkie", value: "all" }} />
      </UnderCalendarToolbarContainer>
      <ScrollableWrapper id='dashboardVisitsList'>
        {visits.map(visit => <VisitCardContainer key={visit._id}><VisitDashboardCard actionsOpen={actionsOpen} setActionsOpen={setActionsOpen} visit={visit} currentVisit={currentVisit} setCurrentVisitPatient={setCurrentVisitPatient} setCurrentVisit={setCurrentVisit} /><NextVisitTimeIndicator timeToNext={visit.timeToNext} /></VisitCardContainer>)}
      </ScrollableWrapper>

    </div>
  );
};

export default DoctorDashboardSidebarView;

const FlexContainer = styled.div`
display: flex;
justify-content: center;
`

const AddVisitButton = styled(Button)`
margin: 0 10px 10px 10px;
width: 100%;
`;

const VisitCardContainer = styled.div`
display: flex;
align-items: center;
flex-direction: column;
margin-left: 10px;
`;

const ScrollableWrapper = styled.div`
overflow-y: scroll;
height: calc(100vh - 450px);
`;

const StyledSelect = styled(Select)`
  width:150px;
  margin-right: 10px;
`;


const UnderCalendarToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VisitCountText = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
`;

const StyledCalendar = styled(Calendar)`
padding: 10px;
margin-bottom: 0;
`;

const CalendarActions = styled.div`
  display: flex;
  justify-content:flex-end;
  align-items:center;
  padding: 0 15px 5px;
`;

const Today = styled.p`
  font-size:14px;
  font-weight: 600;
  cursor:pointer;
  color:${({ theme }) => theme.lightBlack};
  transition:300ms;
  &:hover{
    color:${({ theme }) => theme.primary};
  }
`;