import React from 'react';
import styled from 'styled-components';
import Modal from '../../../Shared/Modals/Modal';
import Input from '../../../Shared/Input';
import DatePicker from '../../../Shared/DatePicker';
import Button from '../../../Shared/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';

const AnthropometryModal = ({
    modalState: { shown: modalShown, editId: modalEditId },
    setModalState,
    updatePatientSurveysHandler,
    patientSurveys,
    deleteModalHandler
}) => {
    const validationSchema = Yup.object().shape({
        date: Yup.string(),
        height: Yup.number()
            .required('Wzrost jest wymagana')
            .typeError('Wzrost musi być numerem')
            .max(300, 'Wzrost jest za duży'),
        weight: Yup.number()
            .required('Waga jest wymagana')
            .typeError('Waga musi być numerem')
            .max(1000, 'Waga jest za duża'),
        pressure: Yup.string()
            .test({
                test: (val) => val?.includes('/'),
                message: 'Ciśnienie musi odzielać znak /'
            })
            .required('Ciśnienie jest wymagane'),
        pulse: Yup.number()
            .required('Tętno jest wymagane')
            .typeError('Tętno musi być numerem')
            .max(350, 'Tętno jest za wysokie'),
    });
    const currentItem = modalEditId ? patientSurveys?.anthropometry.find(el => el._id === modalEditId) : {};
    return (
        <Modal
            title={modalEditId ? 'Edycja antropometrii' : 'Dodawanie antropometrii'}
            exitButton={true}
            onCancel={() => { setModalState({ shown: false, editId: null }) }}
            show={modalShown === 'anthropometry'}
        >
            <Formik
                initialValues={{
                    date: Date.now(),
                }}
                onSubmit={(values) => {
                    if (modalEditId) {
                        updatePatientSurveysHandler({
                            anthropometry: [
                                ...patientSurveys.anthropometry.map(el => el._id === modalEditId ? { ...el, ...values } : el)
                            ]
                        })
                    } else {
                        console.log(patientSurveys)
                        updatePatientSurveysHandler({ anthropometry: [...patientSurveys.anthropometry, values] })
                    }
                    setModalState({ shown: false, editId: null })
                }}
                validationSchema={validationSchema}
                initialValues={{
                    date: modalEditId ? currentItem.date : Date.now(),
                    height: modalEditId ? currentItem.height : '',
                    weight: modalEditId ? currentItem.weight : '',
                    pressure: modalEditId ? currentItem.pressure : '',
                    pulse: modalEditId ? currentItem.pulse : '',
                }}
            >
                {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <DatePicker
                            label="Data"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={true}
                            timeCaption="Godzina"
                            selected={values.date ?
                                new Date(values.date)
                                :
                                Date.now()
                            }
                            error={errors.date}
                            onChange={val => setFieldValue("date", val)}
                        />
                        <InputRow>
                            <Input
                                type="text"
                                name="height"
                                label="Wzrost (cm)"
                                onChange={handleChange}
                                value={values.height}
                                error={errors.height}
                            />
                            <Input
                                type="text"
                                name="weight"
                                label="Waga (kg)"
                                onChange={handleChange}
                                value={values.weight}
                                error={errors.weight}
                            />
                        </InputRow>
                        <InputRow>
                            <Input
                                type="text"
                                name="pressure"
                                label="Ciśnienie (xxx/xx)"
                                onChange={handleChange}
                                value={values.pressure}
                                error={errors.pressure}
                            />
                            <Input
                                type="text"
                                name="pulse"
                                label="Tętno (BMP)"
                                onChange={handleChange}
                                value={values.pulse}
                                error={errors.pulse}
                            />
                        </InputRow>
                        <CenterBox>
                            <Button type='submit' variant='primary'>{modalEditId ? 'Zapisz zmiany' : 'Dodaj'}</Button>
                            {modalEditId && <Button onClick={() => { deleteModalHandler('survey'); setModalState({ shown: false, editId: null }) }} type='button' variant='danger'>Usuń antropometrie</Button>}
                        </CenterBox>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default AnthropometryModal;

const Form = styled.form`
    width:400px;
`;

const InputRow = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`;

const CenterBox = styled.div`
    margin-top:20px;
    text-align:center;
`;